import { GlobalProductTranslation } from './global-product-translation.model';
import { GlobalConfigurationTranslation } from './global-configuration-translation.model';
import { Template, ITemplate } from './template';
import { assign as _assign } from 'lodash-es';
import { IBaseAuditModel, BaseAuditModel } from './base-audit.model';
import { GlobalProduct, IGlobalProduct } from './global-product.model';
import { IGlobalProductOfferingLink, GlobalProductOfferingLink } from './global-product-offering-link.model';
import { GlobalProductOfferingCertification, IGlobalProductOfferingCertification } from './global-product-offering-certification.model';
import { IGlobalProductOfferingConfiguration, GlobalProductOfferingConfiguration } from './global-product-offering-configuration.model';
import { GlobalProductOfferingMediaLink, IGlobalProductOfferingMediaLink } from './global-product-offering-media-link.model';
export interface IGlobalProductOffering extends IBaseAuditModel {
  globalProductId: number;
  globalProduct: IGlobalProduct;
  templateId: number;
  template: ITemplate;
  name: string;
  isConfiguratorEnabled: boolean;
  excludeFromPG: boolean;
  globalProductOfferingLinks: Array<IGlobalProductOfferingLink>;
  globalProductOfferingCertifications: Array<IGlobalProductOfferingCertification>;
  globalProductOfferingConfigurations: Array<IGlobalProductOfferingConfiguration>;
  globalProductOfferingMediaLinks: Array<IGlobalProductOfferingMediaLink>;
  globalProductTranslations: Array<GlobalProductTranslation>;
  globalConfigurationTranslations: Array<GlobalConfigurationTranslation>;
}

export class GlobalProductOffering extends BaseAuditModel implements IGlobalProductOffering {
  globalProductId: number;
  globalProduct: GlobalProduct;
  templateId: number;
  template: Template;
  name: string;
  isConfiguratorEnabled: boolean;
  excludeFromPG: boolean;
  globalProductOfferingLinks: Array<GlobalProductOfferingLink> = [];
  globalProductOfferingCertifications: Array<GlobalProductOfferingCertification> = [];
  globalProductOfferingConfigurations: Array<GlobalProductOfferingConfiguration> = [];
  globalProductOfferingMediaLinks: Array<GlobalProductOfferingMediaLink> = [];
  globalProductTranslations: Array<GlobalProductTranslation> = [];
  globalConfigurationTranslations: Array<GlobalConfigurationTranslation> = [];

  constructor(data?: IGlobalProductOffering) {
    super(data);

    if (!data) {
      return;
    }

    _assign(this, data);
  }
}
