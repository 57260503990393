import { EndpointProductOfferingTagRelationshipDelete, EndpointProductOfferingTagRelationshipPost } from './../../../core/models/endpoint-product-offering-tag.model';
import { EndpointProductOfferingSummary } from './../../../core/models/endpoint-product-offering-summary.model';
import { EndpointProductOfferingsService } from './../../../core/services/endpoint-product-offerings.service';
import { IEndpointProductOfferingsPageRequest } from './../../../core/models/endpoint-product-offerings-page-request.model';
import { PagedResults } from 'src/app/core/models/page-results.model';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { EndpointProductOfferingsApiActions, EndpointProductOfferingsActions } from '../actions';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class EndpointProductOfferingsEffects {

  @Effect()
  removeEndpointProductOffering$ = this.actions$.pipe(
    ofType(EndpointProductOfferingsActions.EndpointProductOfferingsActionTypes.RemoveEndpointProductOffering),
    map((action: EndpointProductOfferingsActions.RemoveEndpointProductOffering) => action.payload),
    switchMap(payload => {
      return this.endpointProductOfferingsService.removeEndpointProductOffering$(payload.endpointId, payload.globalProductOfferingId).pipe(
        map(() => {
          return new EndpointProductOfferingsApiActions.RemoveSuccess({ globalProductOfferingIds: [payload.globalProductOfferingId] });
        }),
        catchError((error: any) => {
          return of(new EndpointProductOfferingsApiActions.RemoveFailure(error));
        })
      );
    })
  );

  @Effect()
  removeEndpointProductOfferings$ = this.actions$.pipe(
    ofType(EndpointProductOfferingsActions.EndpointProductOfferingsActionTypes.RemoveEndpointProductOfferings),
    map((action: EndpointProductOfferingsActions.RemoveEndpointProductOfferings) => action.payload),
    switchMap(payload => {
      return this.endpointProductOfferingsService.removeEndpointProductOfferings$({ endpointId: payload.endpointId, globalProductOfferingIds: payload.globalProductOfferingIds}).pipe(
        map(() => {
          return new EndpointProductOfferingsApiActions.RemoveSuccess({ globalProductOfferingIds: [...payload.globalProductOfferingIds] });
        }),
        catchError((error: any) => {
          return of(new EndpointProductOfferingsApiActions.RemoveFailure(error));
        })
      );
    })
  );

  @Effect()
  loadEndpointProductOfferingsForEndpoint$ = this.actions$.pipe(
    ofType(EndpointProductOfferingsActions.EndpointProductOfferingsActionTypes.LoadEndpointProductOfferingsPageByEndpointId),
    map((action: EndpointProductOfferingsActions.LoadEndpointProductOfferingsPageByEndpointId) => action.payload),
    switchMap((request: IEndpointProductOfferingsPageRequest) =>
      this.endpointProductOfferingsService.getEndpointProductOfferingSummaries$(request).pipe(
        map((page: PagedResults<EndpointProductOfferingSummary>) =>
          new EndpointProductOfferingsApiActions.LoadPageByEndpointSuccess(page)
        ),
        catchError(error =>
          of(new EndpointProductOfferingsApiActions.LoadPageByEndpointFailure(error))
        )
      )
    )
  );

  @Effect()
  updateEndpointProductOfferings$ = this.actions$.pipe(
    ofType(EndpointProductOfferingsActions.EndpointProductOfferingsActionTypes.UpdateEndpointProductOfferings),
    map((action: EndpointProductOfferingsActions.UpdateEndpointProductOfferings) => action.payload),
    switchMap((payload) =>
      this.endpointProductOfferingsService.updateEndpointProductOfferings$({ endpointId: payload.endpointId, globalProductOfferingIds: payload.globalProductOfferingIds}).pipe(
        map(() =>
          new EndpointProductOfferingsApiActions.UpdateSuccess()
        ),
        catchError((error: any) =>
          of(new EndpointProductOfferingsApiActions.UpdateFailure(error))
        )
      )
    )
  );

  @Effect()
  applyEndpointProductOfferingTags$ = this.actions$.pipe(
    ofType(EndpointProductOfferingsActions.EndpointProductOfferingsActionTypes.ApplyOfferingTags),
    map((action: EndpointProductOfferingsActions.ApplyOfferingTags) => action.payload),
    switchMap((payload: EndpointProductOfferingTagRelationshipPost) =>
      this.endpointProductOfferingsService.applyTags$(payload.endpointId, payload).pipe(
        map(() =>
          new EndpointProductOfferingsApiActions.ApplyOfferingTagsSuccess()
        ),
        catchError((error: any) =>
          of(new EndpointProductOfferingsApiActions.ApplyOfferingTagsFailure(error))
        )
      )
    )
  );

  @Effect()
  removeEndpointProductOfferingTags$ = this.actions$.pipe(
    ofType(EndpointProductOfferingsActions.EndpointProductOfferingsActionTypes.RemoveOfferingTags),
    map((action: EndpointProductOfferingsActions.RemoveOfferingTags) => action.payload),
    switchMap((payload: EndpointProductOfferingTagRelationshipDelete) =>
      this.endpointProductOfferingsService.removeTags$(payload.endpointId, payload).pipe(
        map(() =>
          new EndpointProductOfferingsApiActions.RemoveOfferingTagsSuccess()
        ),
        catchError((error: any) =>
          of(new EndpointProductOfferingsApiActions.RemoveOfferingTagsFailure(error))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private endpointProductOfferingsService: EndpointProductOfferingsService) { }
}
