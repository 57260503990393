import { assign as _assign } from 'lodash-es';
import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';

export interface IGlobalProductSummary extends IBaseAuditModel {
  name: string;
  brandName: string;
  thumbnailImageLink1: string;
  productStatus: string;
  globalProductOfferings: GlobalProductSummaryOffering[];
}

export class GlobalProductSummary extends BaseAuditModel {
  public name: string;
  public brandName: string;
  public thumbnailImageLink1: string;
  public productStatus: string;
  public globalProductOfferings: GlobalProductSummaryOffering[] = [];

  constructor(data?: IGlobalProductSummary) {
    super(data);

    if (!data) {
      return;
    }

    _assign(this, data);
  }
}

// The models below should only be used in conjunction with the model above, which is why they're all in this file.
export class GlobalProductSummaryOffering {
  public id: number;
  public name: string;
  public template: GlobalProductSummaryTemplate;
  public globalProductOfferingConfigurationsCount: number;
  public endpointsCount: number;
}

export class GlobalProductSummaryTemplate {
  public name: string;
  public integration: GlobalProductSummaryIntegration;
}

export class GlobalProductSummaryIntegration {
  public name: string;
  public iconLink: string;
}
