import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { CadLinksModalDataSource } from '../cad-links-modal.datasource';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';


@Component({
	selector: 'app-cad-links-search',
	templateUrl: './cad-links-search.component.html',
	styleUrls: ['./cad-links-search.component.scss']
})
export class CadLinksSearchComponent implements OnInit, AfterViewInit, OnDestroy {
	
	@Input() datasource: CadLinksModalDataSource;
	@Input() relatedProductNumbers: string[];
	
	@ViewChild('search') searchInput: ElementRef;
	@ViewChild(MatAutocompleteTrigger) autoCompleteTrigger: MatAutocompleteTrigger;

	autoCompleteOptions: string[];

	private subscriptions = new SubSink();

	ngOnInit() {
		// run an initial search if there is a search term present
		if (this.datasource.searchTerm && this.datasource.searchTerm.length > 0) {
			this.datasource.beginSearch();
		}

		this.autoCompleteOptions = this.relatedProductNumbers;
	}

	ngAfterViewInit() {

		// subscribe to event stream from the search term input change events
		this.subscriptions.add(
			fromEvent(this.searchInput.nativeElement, 'change').pipe(
				debounceTime(500),
				distinctUntilChanged(),
				tap(() => {
					// remove any leading or trailing spaces that may have been accidentally typed by user
					this.searchInput.nativeElement.value = this.searchInput.nativeElement.value.trim();

					// make sure the auto complete element gets closed
					this.autoCompleteTrigger.closePanel();

					this.datasource.searchTerm = this.searchInput.nativeElement.value;
					this.datasource.beginSearch();
				})
			).subscribe()
		);

		// subscribe to event stream from the search term input keyup events
		this.subscriptions.add(
			fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
				debounceTime(200),
				distinctUntilChanged(),
				tap(() => {
					// filter the autocomplete list
					this.filterOptions(this.searchInput.nativeElement.value);
				})
			).subscribe()
		);

	}

	onToggleSortOrder() {
		this.datasource.sortAscending = !this.datasource.sortAscending;
		this.datasource.beginSearch();
	}

	onOrderByChanged() {
		this.datasource.beginSearch();
	}

	onScrollDown() {
		this.datasource.loadNext();
	}

	ngOnDestroy() {
		this.datasource.clearPreviousData();
		this.datasource.unsubscribeAll();
		this.subscriptions.unsubscribe();
	}

	onAutoCompleteOptionSelected(event: MatAutocompleteSelectedEvent) {
		if (event.option.value) {
			this.datasource.searchTerm = event.option.value;
			this.datasource.beginSearch();
		}
	}

	filterOptions(searchTerm: string) {
		this.autoCompleteOptions = this.relatedProductNumbers.filter(
				partNumber => 
					(partNumber.toLowerCase().includes(searchTerm.trim().toLowerCase()) && partNumber.trim().length > 0)
		);
	}
}