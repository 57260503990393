import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Technology, ITechnology } from '../models/technology.model';

@Injectable({
  providedIn: 'root'
})
export class TechnologiesService {
  basePath = 'technologies';
  constructor(private http: HttpClient) {
  }

  getTechnologies$(): Observable<Array<Technology>> {
    return this.http
      .get(environment.apiUrl + `${this.basePath}`).pipe(
        map(res => res),
        catchError((err: Response) => {
          const obj = err;
          return observableThrowError(obj);
        })
        , map((list: Array<ITechnology>) => list.map(b => new Technology(b)))
      );
  }
}
