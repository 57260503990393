import { Observable, throwError } from 'rxjs';
import { IEndpointProductOfferingsPageRequest } from './../models/endpoint-product-offerings-page-request.model';
import { EndpointProductOfferingSummary } from './../models/endpoint-product-offering-summary.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { PagedResults } from '../models/page-results.model';
import { EndpointProductOfferingTagRelationshipDelete, EndpointProductOfferingTagRelationshipPost } from '../models/endpoint-product-offering-tag.model';

@Injectable({
  providedIn: 'root'
})
export class EndpointProductOfferingsService {
  resource = 'endpointproductofferings';

  constructor(private http: HttpClient) { }

  getEndpointProductOfferingSummaries$(request: IEndpointProductOfferingsPageRequest): Observable<PagedResults<EndpointProductOfferingSummary>> {
    if (!request.orderBy || request.orderBy === '') {
      request.orderBy = 'name';
      request.sortOrder = 'asc';
    }

    let params = new HttpParams();
    params = params.append('search', request.searchTerm);
    params = params.append('pageNumber', request.pageNumber.toString());
    params = params.append('pageSize', request.pageSize.toString());
    params = params.append('orderBy', request.orderBy);
    params = params.append('sortOrder', request.sortOrder);

    return this.http.get(`${environment.apiUrl}endpoints/${request.endpointId}/${this.resource}`, { params }).pipe(
      map((response: PagedResults<EndpointProductOfferingSummary>) => response)
    );
  }

  removeEndpointProductOffering$(endpointId: number, globalProductOfferingId: number): Observable<any> {
    return this.http.delete(
      `${environment.apiUrl}endpoints/${endpointId}/${this.resource}/${globalProductOfferingId}`
      );
  }

  removeEndpointProductOfferings$(request: { endpointId: number, globalProductOfferingIds: number[] }): Observable<any> {
    console.log('request check');
    console.log(request);
    return this.http.put(
      `${environment.apiUrl}endpoints/${request.endpointId}/${this.resource}/remove`,
      request.globalProductOfferingIds,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
    );
  }

  updateEndpointProductOfferings$(request: { endpointId: number, globalProductOfferingIds: number[] }): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}endpoints/${request.endpointId}/${this.resource}`,
      request.globalProductOfferingIds,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
    );
  }

  applyTags$(endpointId: number, request: EndpointProductOfferingTagRelationshipPost): Observable<any> {
    return this.http.post(`${environment.apiUrl}endpoints/${endpointId}/${this.resource}/tags`, request)
    .pipe(
      map(response => response),
      catchError((error: Response) => {
        return throwError(error);
      })
    );
  }

  removeTags$(endpointId: number, request: EndpointProductOfferingTagRelationshipDelete): Observable<any> {
    return this.http.request(
      'delete',
      `${environment.apiUrl}endpoints/${endpointId}/${this.resource}/tags`,
      { body: request }
      );
  }
}
