import { IPageRequestBuilder } from './page-request-builder.service';
import { GlobalProductQueryPageRequest } from './../models/global-product-query-page-request.model';

export class GlobalProductQueryPageRequestBuilder implements IPageRequestBuilder<GlobalProductQueryPageRequestBuilder, GlobalProductQueryPageRequest> {
  private request: GlobalProductQueryPageRequest = new GlobalProductQueryPageRequest();

  public setPageNumber(pageNumber: number): GlobalProductQueryPageRequestBuilder {
    this.request.pageNumber = pageNumber;
    return this;
  }

  public setPageSize(pageSize: number): GlobalProductQueryPageRequestBuilder {
    this.request.pageSize = pageSize;
    return this;
  }

  public setOrderBy(orderBy: string): GlobalProductQueryPageRequestBuilder {
    this.request.orderBy = orderBy;
    return this;
  }

  public setSortOrder(sortOrder: string): GlobalProductQueryPageRequestBuilder {
    this.request.sortOrder = sortOrder;
    return this;
  }

  public setSearchTerm(searchTerm: string): GlobalProductQueryPageRequestBuilder {
    this.request.searchTerm = searchTerm;
    return this;
  }

  public setBrandIds(brandIds: Array<number>): GlobalProductQueryPageRequestBuilder {
    this.request.brandIds = [...brandIds];
    return this;
  }

  public setDesignerIds(designerIds: Array<number>): GlobalProductQueryPageRequestBuilder {
    this.request.designerIds = [...designerIds];
    return this;
  }

  public setCategoryIds(categoryIds: Array<number>): GlobalProductQueryPageRequestBuilder {
    this.request.categoryIds = [...categoryIds];
    return this;
  }

  public setContactIds(contactIds: Array<number>): GlobalProductQueryPageRequestBuilder {
    this.request.contactIds = [...contactIds];
    return this;
  }

  public setFamilyIds(familyIds: Array<number>): GlobalProductQueryPageRequestBuilder {
    this.request.familyIds = [...familyIds];
    return this;
  }

  public setGlobalCategoryIds(globalCategoryIds: Array<number>): GlobalProductQueryPageRequestBuilder {
    this.request.globalCategoryIds = [...globalCategoryIds];
    return this;
  }

  public setGlobalSubcategoryIds(globalSubcategoryIds: Array<number>): GlobalProductQueryPageRequestBuilder {
    this.request.globalSubcategoryIds = [...globalSubcategoryIds];
    return this;
  }

  public setPortfolioIds(portfolioIds: Array<number>): GlobalProductQueryPageRequestBuilder {
    this.request.portfolioIds = [...portfolioIds];
    return this;
  }

  public setSubBrandIds(subBrandIds: Array<number>): GlobalProductQueryPageRequestBuilder {
    this.request.subbrandIds = [...subBrandIds];
    return this;
  }

  public setSubcategoryIds(subcategoryIds: Array<number>): GlobalProductQueryPageRequestBuilder {
    this.request.subcategoryIds = [...subcategoryIds];
    return this;
  }

  public setWarrantyIds(warrantyIds: Array<number>): GlobalProductQueryPageRequestBuilder {
    this.request.warrantyIds = [...warrantyIds];
    return this;
  }

  public build(): GlobalProductQueryPageRequest {
    return this.request;
  }
}
