import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import * as _ from 'lodash';

export interface ITemplatePost extends IBaseAuditModel {
  id: number;
  name: string;
  integrationId: number;

}

export class TemplatePost extends BaseAuditModel {
  id: number;
  name: string;
  integrationId: number;

  constructor(data?: ITemplatePost) {
    super(data);

    if (!data) {
      return;
    }
    _.assign(this, data);
  }
}