import { IntegrationsActions } from 'src/app/app-store/integrations-store';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { IntegrationSummary } from './../../../core/models/integration-summary.model';
import { SubSink } from 'subsink';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import * as fromStore from '../../../app-store/reducers';

@Component({
  selector: 'app-integration-selection-dropdown',
  templateUrl: './integration-selection-dropdown.component.html',
  styleUrls: ['./integration-selection-dropdown.component.scss']
})
export class IntegrationSelectionDropdownComponent implements OnInit, OnDestroy {

  @Input() label: string = null;

  // initial integrations ids that are selected.
  @Input() integrationIds: Array<number> = [];

  // initially selected integration ids.
  @Input() initialSelectedIntegrationIds: Array<number> = [];

  // this option filters the list of integrations shown in the dropdown from all, to the ids specified.
  @Input() integrationIdFilter: Array<number> = [];

  // this option turns on checkboxes for multi-selection.
  @Input() allowMultipleSelection = false;

  // If true, the dropdown will be disabled.
  @Input() disabled = false;

  // If true, special styles are applied to make this dropdown 'compact'
  @Input() compact = false;

  // If true, we apply rules to make the form required.
  @Input() required = false;

  @Output() selectionChanged: EventEmitter<Array<IntegrationSummary>> = new EventEmitter<Array<IntegrationSummary>>();

  private initialized = false;

  // this is the curated list of integrations shown in the dropdown list.
  get integrations(): Array<IntegrationSummary> {
    let values = [...this.allIntegrations];

    // filter out any integrations without ids present in the integration filter.
    if (this.integrationIdFilter && this.integrationIdFilter.length > 0) {
      values = [...values.filter(x => this.integrationIdFilter.some(y => y === x.id))];
    }

    // order by name.
    values.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
    return values;
  }

  // this is the list of integrations currently selected.
  get selectedIntegrations(): Array<IntegrationSummary> {
    return [...this.integrations.filter(x => this.selectedIntegrationIds.some(y => y === x.id))];
  }

  get selectedIntegrationIds(): Array<number> {
    // single selection.
    if (!this.allowMultipleSelection) {
      const value = (this.form.controls.integrations.value as number);
      return [value];
    }

    // multiple selection.
    return (this.form.controls.integrations.value as Array<number>);
  }

  set selectedIntegrationIds(value: Array<number>) {
    // single selection
    if (!this.allowMultipleSelection) {
      this.form.controls.integrations.setValue(value[0]);
      this.form.controls.integrations.markAsDirty();
      return;
    }

    // multiple selection.
    this.form.controls.integrations.setValue(value);
    this.form.controls.integrations.markAsDirty();
  }

  public form: FormGroup;
  public allIntegrations: Array<IntegrationSummary> = [];

  private integrations$: Observable<Array<IntegrationSummary>>;
  private subscriptions: SubSink = new SubSink();

  constructor(
    private fb: FormBuilder,
    private store: Store<fromStore.State>) {

    this.store.dispatch(new IntegrationsActions.LoadIntegrations());
    this.integrations$ = this.store.select(fromStore.getAllIntegrations);
  }

  public ngOnInit(): void {
    this.form = this.buildForm();
    if (this.disabled) {
      this.form.controls.integrations.disable();
    }

    this.subscribeToFormChanges();
    this.subscribeToIntegrations();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public getIntegrationsTooltip(): string {
    let tooltip = 'No Integrations Selected.';

    if (this.selectedIntegrations?.length > 0) {
      tooltip = 'Integrations Selected:';
      tooltip = `${tooltip} \n`;
      this.selectedIntegrations.forEach((x, i) => {
        tooltip = `${tooltip} \n${this.selectedIntegrations[i].name}`;
      });
    }

    return tooltip;
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form?.controls[controlName].hasError(errorName);
  }

  private buildForm(): FormGroup {
    let integrationsFormControl: FormControl;

    if (this.allowMultipleSelection) {
      // multiple selection form.
      integrationsFormControl = new FormControl(this.integrationIds);
    }
    else {
      // single selection form.
      const integrationId = (this.integrationIds.length > 0) ? this.integrationIds[0] : null;
      integrationsFormControl = new FormControl(integrationId);
    }

    // Set required field validator if requested.
    if (this.required) {
      integrationsFormControl.setValidators(Validators.required);
    }

    const formGroup = this.fb.group({integrations: integrationsFormControl});
    return formGroup;
  }

  private subscribeToIntegrations(): void {
    this.subscriptions.add(
      this.integrations$.subscribe((integrationSummaries: Array<IntegrationSummary>) => {
        this.allIntegrations = integrationSummaries;

        if (!this.initialized && this.allIntegrations?.length > 0) {
          // Initially set ids
          if (this.initialSelectedIntegrationIds && this.initialSelectedIntegrationIds.length > 0) {
            this.selectedIntegrationIds = this.initialSelectedIntegrationIds;
          }
          this.initialized = true;
        }
      })
    );
  }

  private subscribeToFormChanges(): void {
    this.subscriptions.add(
      this.form.valueChanges.subscribe((values) => {
        // debugging
        // console.log(this.form);

        // Prevent circular reference of set/emit updates.
        // Second condition is to account for the new configuration modal. For integration roles we
        //    auto-set and disable the dropdown for integration roles with only one integration.
        if (this.initialized || (this.disabled === true && this.allowMultipleSelection === false && this.selectedIntegrationIds.length === 1)) {
          // surface changes to parent controls.
          this.selectionChanged.emit(this.selectedIntegrations);
        }
      })
    );
  }

}
