import { Locale } from '../../../core/models/locale.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-available-in',
  templateUrl: './available-in.component.html',
  styleUrls: ['./available-in.component.scss']
})
export class AvailableInComponent {
  @Input() locales: Array<Locale> = [];
}
