import { IGlobalConfigurationAssociation } from './../../../core/models/global-configuration-association.model';
import { GlobalConfigurationDimensionsPut } from './../../../core/models/global-configurations-dimensions-put.model';
import { PagedResults } from 'src/app/core/models/page-results.model';
import { Router } from '@angular/router';
import { GlobalConfigurationsService } from 'src/app/core/services/global-configurations.service';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ConfigurationsApiActions, ConfigurationsActions } from '../actions';
import { switchMap, map, catchError, mergeMap, tap } from 'rxjs/operators';
import { GlobalConfiguration } from 'src/app/core/models/global-configuration.model';
import { of } from 'rxjs';
import { GlobalConfigurationPut, ProductNumberReprocessRequestPut } from 'src/app/core/models/global-configuration-put.model';
import { IConfigurationsPageRequest } from 'src/app/core/models/configurations-page-request.model';
import { GlobalConfigurationModelFilesProfilesPut } from 'src/app/core/models/global-configurations-model-files-profile-put.model';
import { GlobalConfigurationLeadtimesPut } from 'src/app/core/models/global-configurations-leadtimes-put.model';

@Injectable()
export class ConfigurationsEffects {

  @Effect()
  createConfiguration$ = this.actions$.pipe(
    ofType(ConfigurationsActions.ConfigurationsActionTypes.CreateConfiguration),
    map((action: ConfigurationsActions.CreateConfiguration) => action.payload.globalConfiguration),
    mergeMap(request =>
      this.globalConfigurationsService.createGlobalConfiguration$(request).pipe(
        tap((globalConfiguration: GlobalConfiguration) => {
          // Navigate to the page in edit mode.
          this.router.navigate([`products/configuration-detail/${globalConfiguration.id}/classification`], { queryParams: { edit: true } });
        }),
        map((globalConfiguration: GlobalConfiguration) =>
          new ConfigurationsApiActions.CreateSuccess(globalConfiguration)
        ),
        catchError((error: any) =>
          of(new ConfigurationsApiActions.CreateFailure(error))
        ),
      )
    )
  );


  @Effect()
  deleteConfiguration$ = this.actions$.pipe(
    ofType(ConfigurationsActions.ConfigurationsActionTypes.DeleteConfiguration),
    map((action: ConfigurationsActions.DeleteConfiguration) => action.payload),
    switchMap(payload => {
      return this.globalConfigurationsService.deleteConfiguration$(payload.globalConfiguration).pipe(
        map(() => {
          return new ConfigurationsApiActions.DeleteSuccess({ globalConfiguration: payload.globalConfiguration });
        }),
        catchError((error: any) => {
          return of(new ConfigurationsApiActions.DeleteFailure(error));
        }),
        tap(() => {
          // Navigate to the configuration's product configuration list.
          this.router.navigate([`products/product-detail/${payload.globalConfiguration.globalProductId}/pricing-configurations`]);
        }),
      );
    })
  );

  @Effect()
  duplicateConfiguration$ = this.actions$.pipe(
    ofType(ConfigurationsActions.ConfigurationsActionTypes.DuplicateConfiguration),
    map((action: ConfigurationsActions.DuplicateConfiguration) => action.payload),
    switchMap(payload => {
      return this.globalConfigurationsService.duplicateConfiguration$(payload.globalConfiguration, payload.newName).pipe(
        tap((duplicatedConfiguration: GlobalConfiguration) => {
          // Navigate to the configuration's product configuration list.
          this.router.navigate([`products/configuration-detail/${duplicatedConfiguration.id}/classification`], { queryParams: { edit: true } });
        }),
        map((duplicatedConfiguration: GlobalConfiguration) => {
            return new ConfigurationsApiActions.DuplicateSuccess({ globalConfiguration: duplicatedConfiguration, newName: payload.newName });
          }
        ),
        catchError((error: any) => {
          return of(new ConfigurationsApiActions.DuplicateFailure(error));
        }),
      );
    }
  ));

  @Effect()
  loadConfiguration$ = this.actions$.pipe(
    ofType(ConfigurationsActions.ConfigurationsActionTypes.LoadConfiguration),
    map((action: ConfigurationsActions.LoadConfiguration) => action.payload),
    switchMap((configurationId: number) =>
      this.globalConfigurationsService.getGlobalConfiguration$(configurationId).pipe(
        map((globalConfiguration: GlobalConfiguration) =>
          new ConfigurationsApiActions.LoadSuccess(globalConfiguration)
        ),
        catchError((error: any) =>
          of(new ConfigurationsApiActions.LoadFailure(error))
        )
      )
    )
  );

  @Effect()
  loadConfigurationsForProduct$ = this.actions$.pipe(
    ofType(ConfigurationsActions.ConfigurationsActionTypes.LoadConfigurationsByProductId),
    map((action: ConfigurationsActions.LoadConfigurationsByProductId) => action.payload),
    switchMap((request: IConfigurationsPageRequest) =>
      this.globalConfigurationsService.getGlobalConfigurations$(request).pipe(
        map((page: PagedResults<GlobalConfiguration>) =>
          new ConfigurationsApiActions.LoadByProductSuccess(page)
        ),
        catchError(error =>
          of(new ConfigurationsApiActions.LoadByProductFailure(error))
        )
      )
    )
  );

  @Effect()
  updateConfiguration$ = this.actions$.pipe(
    ofType(ConfigurationsActions.ConfigurationsActionTypes.UpdateConfiguration),
    map((action: ConfigurationsActions.UpdateConfiguration) => action.payload.globalConfiguration),
    mergeMap((globalConfiguration: GlobalConfigurationPut) =>
      this.globalConfigurationsService.updateGlobalConfiguration$(globalConfiguration).pipe(
        map(() =>
          new ConfigurationsApiActions.UpdateSuccess(globalConfiguration)
        ),
        catchError((error: any) =>
          of(new ConfigurationsApiActions.UpdateFailure(error))
        )
      )
    )
  );

  @Effect()
  updateConfigurationSuccess$ = this.actions$.pipe(
    ofType(ConfigurationsApiActions.ConfigurationsApiActionTypes.UpdateSuccess),
    map((action: ConfigurationsApiActions.UpdateSuccess) => action.payload),
    map((globalConfiguration: GlobalConfigurationPut) =>
      // Reload the product from the API if the save was successful.
      new ConfigurationsActions.LoadConfiguration(globalConfiguration.id)
    )
  );

  @Effect()
  updateConfigurationDimensions$ = this.actions$.pipe(
    ofType(ConfigurationsActions.ConfigurationsActionTypes.UpdateConfigurationDimensions),
    map((action: ConfigurationsActions.UpdateConfigurationDimensions) => action.payload),
    mergeMap((globalConfigurationDimensionsPut: GlobalConfigurationDimensionsPut) =>
      this.globalConfigurationsService.updateConfigurationDimensions$(globalConfigurationDimensionsPut).pipe(
        map(() =>
          new ConfigurationsApiActions.UpdateDimensionsSuccess(globalConfigurationDimensionsPut)
        ),
        catchError((error: any) =>
          of(new ConfigurationsApiActions.UpdateDimensionsFailure(error))
        )
      )
    )
  );

  @Effect()
  updateConfigurationModelFilesProfiles$ = this.actions$.pipe(
    ofType(ConfigurationsActions.ConfigurationsActionTypes.UpdateModelFilesProfiles),
    map((action: ConfigurationsActions.UpdateConfigurationModelFilesProfiles) => action.payload),
    mergeMap((globalConfigurationModelFilesProfilesPut: GlobalConfigurationModelFilesProfilesPut) =>
      this.globalConfigurationsService.updateConfigurationModelFilesProfiles$(globalConfigurationModelFilesProfilesPut).pipe(
        map(() =>
          new ConfigurationsApiActions.UpdateModelFilesProfilesSuccess(globalConfigurationModelFilesProfilesPut)
        ),
        catchError((error: any) =>
          of(new ConfigurationsApiActions.UpdateModelFilesProfilesFailure(error))
        )
      )
    )
  );

  @Effect()        
  updateConfigurationLeadtimes$ = this.actions$.pipe(
    ofType(ConfigurationsActions.ConfigurationsActionTypes.UpdateConfigurationLeadTimes),
    map((action: ConfigurationsActions.UpdateConfigurationLeadTimes) => action.payload),
    mergeMap((globalConfigurationLeadTimesPut: GlobalConfigurationLeadtimesPut) =>
      this.globalConfigurationsService.updateConfigurationLeadtimes$(globalConfigurationLeadTimesPut).pipe(
        map(() =>
          new ConfigurationsApiActions.UpdateLeadTimesSuccess(globalConfigurationLeadTimesPut)
        ),
        catchError((error: any) =>
          of(new ConfigurationsApiActions.UpdateLeadTimesFailure(error))
        )
      )
    )
  );

  @Effect()
  renameConfiguration$ = this.actions$.pipe(
    ofType(ConfigurationsActions.ConfigurationsActionTypes.RenameConfiguration),
    map((action: ConfigurationsActions.RenameConfiguration) => action.payload),
    switchMap(request => {
      return this.globalConfigurationsService.renameConfiguration$(request.globalConfiguration, request.newName).pipe(
        map((renamedConfiguration: GlobalConfiguration) => {
            return new ConfigurationsApiActions.RenameSuccess({ globalConfiguration: request.globalConfiguration, newName: request.newName });
        }),
        catchError((error: any) => {
          return of(new ConfigurationsApiActions.RenameFailure({ configuration: request.globalConfiguration }));
        })
      );
    }
  ));

  @Effect()
  renameConfigurationSuccess$ = this.actions$.pipe(
    ofType(ConfigurationsApiActions.ConfigurationsApiActionTypes.RenameSuccess),
    map((action: ConfigurationsApiActions.RenameSuccess) => action.payload),
    map(response =>
      // Reload the product from the API if the save was successful.
      new ConfigurationsActions.LoadConfiguration(response.globalConfiguration.id)
    )
  );

  @Effect()
  loadConfigurationAssociations$ = this.actions$.pipe(
    ofType(ConfigurationsActions.ConfigurationsActionTypes.LoadConfigurationAssociations),
    map((action: ConfigurationsActions.LoadConfigurationAssociations) => action.payload),
    switchMap((configurationId: number) =>
      this.globalConfigurationsService.getGlobalConfigurationAssociations$(configurationId).pipe(
        map((associations: Array<IGlobalConfigurationAssociation>) =>
          new ConfigurationsApiActions.LoadAssociationsSuccess(associations)
        ),
        catchError((error: any) =>
          of(new ConfigurationsApiActions.LoadAssociationsFailure(error))
        )
      )
    )
  );

  @Effect()
  reprocessSKUsForRepricing$ = this.actions$.pipe(
    ofType(ConfigurationsActions.ConfigurationsActionTypes.UpdateSKUs),
    map((action: ConfigurationsActions.UpdateSKUs) => action.payload.productNumberReprocessRequest), 
    mergeMap((productNumberReprocessRequest: ProductNumberReprocessRequestPut) => 
      this.globalConfigurationsService.reprocessInvalidProductNumbers$(productNumberReprocessRequest).pipe(
        map(() =>
          new ConfigurationsApiActions.UpdateSKUSuccess(productNumberReprocessRequest)
        ),
        catchError((error: any) =>
          of(new ConfigurationsApiActions.UpdateSKUFailure(error))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private globalConfigurationsService: GlobalConfigurationsService) { }
}
