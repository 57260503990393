import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import { assign as _assign } from 'lodash-es';
import { IImageLink, ImageLink } from './image-link.model';
import { GlobalSubcategoryAttributeType, IGlobalSubcategoryAttributeType } from './global-subcategory-attribute-type.model';

export interface IGlobalSubcategory extends IBaseAuditModel {
  name: string;
  description: string;
  globalSubcategoryImages: Array<IImageLink>;
  globalCategoryId: number;
  globalCategoryName: string;
  globalCategoryIndustryType?: string;
  globalSubcategoryAttributeTypes: Array<IGlobalSubcategoryAttributeType>;
  iconUpload: File;
  iconName: string;
}

export class GlobalSubcategory extends BaseAuditModel implements IGlobalSubcategory {
  name: string;
  description: string;
  globalSubcategoryImages: Array<ImageLink> = [];
  globalCategoryId: number;
  globalCategoryName: string;
  globalCategoryIndustryType?: string;
  globalSubcategoryAttributeTypes: Array<GlobalSubcategoryAttributeType> = [];
  iconUpload: File;
  iconName: string;

  constructor(data?: IGlobalSubcategory) {
    super(data);
    let link = data.globalSubcategoryImages[0]?.imageLink;
    this.iconName = link && link.lastIndexOf('/') !== -1 && link.substring(link.lastIndexOf('/') + 1, link.length);

    if (!data) {
      return;
    }
    _assign(this, data);
  }
}
