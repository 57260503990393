import { EndpointProductOfferingStatus } from './../../../shared/constants';
import { EndpointProductOfferingSummary } from './../../../core/models/endpoint-product-offering-summary.model';
import { PagedResults } from './../../../core/models/page-results.model';
import { EndpointProductOfferingsActions, EndpointProductOfferingsApiActions } from '../actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  endpointProductOfferings: PagedResults<EndpointProductOfferingSummary>;
  status: EndpointProductOfferingStatus;
  error: string;
  success: string;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  endpointProductOfferings: new PagedResults<EndpointProductOfferingSummary>(),
  status: EndpointProductOfferingStatus.None,
  error: '',
  success: ''
};

export function reducer(state = initialState, action: EndpointProductOfferingsActions.EndpointProductOfferingsActionsUnion | EndpointProductOfferingsApiActions.EndpointProductOfferingsApiActionsUnion): State {
  switch (action.type) {

    case EndpointProductOfferingsActions.EndpointProductOfferingsActionTypes.LoadEndpointProductOfferingsPageByEndpointId: {
      return {
        ...state,
        loading: true,
        loaded: false,
        endpointProductOfferings: new PagedResults<EndpointProductOfferingSummary>()
      };
    }

    case EndpointProductOfferingsApiActions.EndpointProductOfferingsApiActionTypes.LoadPageByEndpointSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        endpointProductOfferings: action.payload
      };
    }

    case EndpointProductOfferingsApiActions.EndpointProductOfferingsApiActionTypes.LoadPageByEndpointFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        endpointProductOfferings: new PagedResults<EndpointProductOfferingSummary>(),
        error: action.payload
      };
    }

    case EndpointProductOfferingsActions.EndpointProductOfferingsActionTypes.ClearMessages: {
      return {
        ...state,
        error: null,
        success: null,
        status: EndpointProductOfferingStatus.None,
      };
    }

    case EndpointProductOfferingsActions.EndpointProductOfferingsActionTypes.RemoveEndpointProductOffering: {
      return {
        ...state,
        loading: true,
      };
    }

    case EndpointProductOfferingsApiActions.EndpointProductOfferingsApiActionTypes.RemoveSuccess: {
      const count = action.payload?.globalProductOfferingIds?.length;
      return {
        ...state,
        error: null,
        success: count > 1 ? `${count} Offerings successfully removed.` : 'Offering successfully removed.',
        status: EndpointProductOfferingStatus.RemoveSuccess,
      };
    }

    case EndpointProductOfferingsApiActions.EndpointProductOfferingsApiActionTypes.RemoveFailure: {
      return {
        ...state,
        error: `Failed to remove the offering.`,
        success: null,
        status: EndpointProductOfferingStatus.RemoveFailure,
      };
    }

    case EndpointProductOfferingsActions.EndpointProductOfferingsActionTypes.UpdateEndpointProductOfferings: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case EndpointProductOfferingsApiActions.EndpointProductOfferingsApiActionTypes.UpdateSuccess: {
      return {
        ...state,
        error: null,
        success: `Offerings added successfully.`,
        status: EndpointProductOfferingStatus.UpdateSuccess
      };
    }

    case EndpointProductOfferingsApiActions.EndpointProductOfferingsApiActionTypes.UpdateFailure: {
      return {
        ...state,
        error: action.payload,
        success: null,
        status: EndpointProductOfferingStatus.UpdateFailure
      };
    }

    case EndpointProductOfferingsApiActions.EndpointProductOfferingsApiActionTypes.ApplyOfferingTagsSuccess: {
      return {
        ...state,
        error: null,
        success: `Tags applied successfully.`,
        status: EndpointProductOfferingStatus.ApplyTagsSuccess
      };
    }

    case EndpointProductOfferingsApiActions.EndpointProductOfferingsApiActionTypes.ApplyOfferingTagsFailure: {
      return {
        ...state,
        error: `Failed to apply tags.`,
        success: null,
        status: EndpointProductOfferingStatus.ApplyTagsFailure
      };
    }

    case EndpointProductOfferingsApiActions.EndpointProductOfferingsApiActionTypes.RemoveOfferingTagsSuccess: {
      return {
        ...state,
        error: null,
        success: `Tags removed successfully.`,
        status: EndpointProductOfferingStatus.ApplyTagsSuccess
      };
    }

    case EndpointProductOfferingsApiActions.EndpointProductOfferingsApiActionTypes.RemoveOfferingTagsFailure: {
      return {
        ...state,
        error: `Failed to remove tags.`,
        success: null,
        status: EndpointProductOfferingStatus.ApplyTagsFailure
      };
    }

    default:
      return state;
  }
}

export const isLoading = (state: State) => state.loading;
export const isLoaded = (state: State) => state.loaded;
export const getEndpointProductOfferings = (state: State) => state.endpointProductOfferings;
export const getErrorMessage = (state: State) => state.error;
export const getSuccessMessage = (state: State) => state.success;
export const getStatus = (state: State) => state.status;
