import { IPageRequestBuilder } from './page-request-builder.service';
import { EndpointProductOfferingsPageRequest } from '../models/endpoint-product-offerings-page-request.model';

export class EndpointOfferingsPageRequestBuilder implements IPageRequestBuilder<EndpointOfferingsPageRequestBuilder, EndpointProductOfferingsPageRequest> {

  private request: EndpointProductOfferingsPageRequest = new EndpointProductOfferingsPageRequest();

  constructor() {
  }

  public setEndpointId(endpointId: number): EndpointOfferingsPageRequestBuilder {
    this.request.endpointId = endpointId;
    return this;
  }

  public setPageNumber(pageNumber: number): EndpointOfferingsPageRequestBuilder {
    this.request.pageNumber = pageNumber;
    return this;
  }

  public setPageSize(pageSize: number): EndpointOfferingsPageRequestBuilder {
    this.request.pageSize = pageSize;
    return this;
  }

  public setOrderBy(orderBy: string): EndpointOfferingsPageRequestBuilder {
    this.request.orderBy = orderBy;
    return this;
  }

  public setSortOrder(sortOrder: string): EndpointOfferingsPageRequestBuilder {
    this.request.sortOrder = sortOrder;
    return this;
  }

  public setSearchTerm(searchTerm: string): EndpointOfferingsPageRequestBuilder {
    this.request.searchTerm = searchTerm;
    return this;
  }

  public build(): EndpointProductOfferingsPageRequest {
    return this.request;
  }
}
