import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private injector: Injector,
              private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // intercept all requests
    const auth = this.injector.get(AuthService);
    const token = auth.getToken();
    if (token) {
      // place bearer token in header
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response if you want
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            // tried to do an unauthorized action
            auth.logout(true);
          } else if (err.status === 403) {
            this.goToError('accessDenied');
          } else {
            if (err.status === 500 && err.error && err.error.exceptionMessage && err.error.exceptionMessage !== 'AmazonS3Exception') {
              this.goToError();
            }
          }
        }
      }));
  }

  goToError(errorType?: string) {
    if (errorType) {
      this.router.navigate(['/error'], { queryParams: { type: errorType } });
    } else {
      this.router.navigate(['/error']);
    }
  }
}
