import * as _ from 'lodash';
import { IPageRequest, PageRequest } from './page-request.model';

export interface IEndpointProductOfferingsPageRequest extends IPageRequest {
  endpointId: number;
  searchTerm: string;
}

export class EndpointProductOfferingsPageRequest extends PageRequest implements IEndpointProductOfferingsPageRequest {
  endpointId: number;
  searchTerm: string;

  constructor(data?: IEndpointProductOfferingsPageRequest) {
    super(data);

    if (!data) {
      return;
    }
    _.assign(this, data);
  }
}
