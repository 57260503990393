import { DamProviderIds } from './../../../../constants';
import { IDamMediaLink } from 'src/app/core/models/dam-media-link.model';
import { ImagesService } from './../../../../../core/services/images.service';
import { DigitalAssetManagerModalDataSource } from './../../digital-asset-manager-modal.datasource';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { transition, trigger, state, animate, style } from '@angular/animations';
import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit, OnDestroy, Output, EventEmitter, HostListener } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import * as fromStore from '../../../../../app-store/reducers';
import { Store } from '@ngrx/store';
import { DamAssetMetapropertyOption } from 'src/app/core/models/dam-asset-metaproperty-option.model';
import { DamAssetMetapropertyConstants, DamProviders, ToggleImages } from 'src/app/shared/constants';
import { ImageAsset } from 'src/app/core/models/image-asset.model';
import { RolesService } from 'src/app/core/auth/roles.service';
import { SubSink } from 'subsink';
import { IDamAssetMetapropertyQuery } from 'src/app/core/models/dam-asset-metaproperty-query.model';
import { AuthService } from 'src/app/core/auth/auth.service';
import { MediaSubType } from 'src/app/core/models/media-subtype.model';

@Component({
  selector: 'app-digital-asset-library-image-grid',
  templateUrl: './digital-asset-library-image-grid.component.html',
  styleUrls: ['./digital-asset-library-image-grid.component.scss'],
  animations: [
    trigger('selectedAnimation', [
      state('unselected', style({
        opacity: 0,
        transform: 'translateX(-100%) translateY(-100%) rotate(360deg)'
      })),
      state('selected', style({
        opacity: 1
      })),
      transition('unselected => selected', [
        animate('0.25s'),
      ]),
      transition('selected => unselected', [
        animate('0.1s')
      ])
    ])
  ]
})
export class DigitalAssetLibraryImageGridComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('search') input: ElementRef;
  @Input() datasource: DigitalAssetManagerModalDataSource;
  @Output() viewMediaLinkDetail: EventEmitter<IDamMediaLink> = new EventEmitter<IDamMediaLink>();
  formGroup: FormGroup;
  subscriptions: Array<Subscription> = [];
  hoverIndex: number = null;
  flexSearchText: number = 65;
  mediaSubTypes: Array<MediaSubType> = [];
  public damProviders = DamProviders;
  public selectedDamProviderId: number = DamProviders.Bynder;
  previousValue: number = -99;
  subs = new SubSink();
  public toggleImages = ToggleImages;
  public selectedToggleImagesId: number;
  imageSelected: boolean = false;
  omniUser: boolean;
  public showFilters = false;
  public assetPurposeMetapropertyId: any;
  showName: boolean = true;
  loadAssetPurpose$: Observable<DamAssetMetapropertyOption[]>;
  loadAssetPurposeOmni$: Observable<DamAssetMetapropertyOption[]>;
  loadMediaSubType$: Observable<DamAssetMetapropertyOption[]>;

  constructor(public imagesService: ImagesService,
    private rolesService: RolesService,
    private authService: AuthService,
    private store: Store<fromStore.State>) { }

  ngOnInit() {
    this.checkIfOmniUser();
    this.formGroup = new FormGroup({
      searchTerm: new FormControl(this.datasource.searchTerm),
      mediaType: new FormControl(this.datasource?.selectedMediaSubType ? this.datasource.selectedMediaSubType : 0)
    });
    this.mediaSubTypes = this.datasource.mediaSubTypes;
    this.loadAssetPurpose$ = this.store.select(fromStore.getLoadedDamAssetPurposeMetapropertyOptions);
    this.loadMediaSubType$ = this.store.select(fromStore.getLoadedDamMediaSubtypeMetapropertyOptions);
    this.loadAssetPurposeOmni$ = this.store.select(fromStore.getLoadedDamAssetPurposeMetapropertyOmniOptions);
    this.subs.add(this.store.select(fromStore.getLoadedDamAssetPurposeMetaproperty).subscribe(assetPurposeMetaproperty => {
      if (assetPurposeMetaproperty) {
        this.assetPurposeMetapropertyId = assetPurposeMetaproperty?.id;
      }
    }
    ));

    if (!this.selectedToggleImagesId || this.selectedDamProviderId !== DamProviders.Bynder)
      this.selectedToggleImagesId = this.omniUser ? ToggleImages.Omni : ToggleImages.Harbour;
    this.searchImage(this.datasource.searchTerm, this.datasource.selectedMediaSubType, this.selectedDamProviderId, this.selectedToggleImagesId);
    this.screenResize();
  }

  checkIfOmniUser() {
    if (this.rolesService.isIntegrationRole()) {
      var userIntegration = this.authService.getIdentity().userRoles.find(x => x.userRoleIntegrations).userRoleIntegrations;
      var integrationName = userIntegration.filter(x => x.integration.name.toLocaleLowerCase() == 'omni');
      if (integrationName.length > 0)
        this.omniUser = true;
      else
        this.omniUser = false;
    }
    else if (this.rolesService.isAdmin()) {
      this.omniUser = true;
      this.selectedToggleImagesId = ToggleImages.Harbour;
    }
    else {
      this.omniUser = false;
    }
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngAfterViewInit() {
    // search
    this.subscriptions.push(
      fromEvent(this.input.nativeElement, 'keyup').pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.datasource.selectedMediaSubType = 0;
          this.formGroup.get("mediaType").setValue(this.datasource.selectedMediaSubType);
          if (!this.selectedToggleImagesId || this.selectedDamProviderId !== DamProviders.Bynder)
            this.selectedToggleImagesId = this.omniUser ? ToggleImages.Omni : ToggleImages.Harbour;
          this.searchImage(this.input?.nativeElement?.value, this.datasource.selectedMediaSubType, this.selectedDamProviderId, this.selectedToggleImagesId);
        })
      ).subscribe()
    );
  }

  selectionChange(option: any) {
    if (!this.selectedToggleImagesId || this.selectedDamProviderId !== DamProviders.Bynder)
      this.selectedToggleImagesId = this.omniUser ? ToggleImages.Omni : ToggleImages.Harbour;
    this.searchImage(this.input?.nativeElement?.value, option?.value, this.selectedDamProviderId, this.selectedToggleImagesId);
    this.clearSelection();
  }

  clearSelection() {
    this.datasource.clearSelection();
  }

  public getDamProviders(damProviderIds: Array<{ index: number, name: string }>) {
    return damProviderIds.filter(x =>
      x.index === DamProviderIds.Bynder ||
      x.index === DamProviderIds.Aem);
  }

  public viewImageDetail(imageAsset: ImageAsset): void {
    //this.datasource.viewImage(imageAsset);
    const mediaLink: IDamMediaLink = {
      id: 0,
      ordinal: 0,
      title: imageAsset.title,
      altText: imageAsset.altText,
      bynderId: imageAsset.bynderId,
      mediaLink: imageAsset.url,
      damProviderId: imageAsset.damProviderId,
      mediaTypeId: null,
      mediaType: null,
      mediaSubTypeId: null,
      mediaSubType: {
        id: 0,
        name: null,
        bynderId: null,
        bynderName: imageAsset.mediaSubType
      }
    };


    this.viewMediaLinkDetail.emit(mediaLink);
  }

  public onDamProviderSelectionChanged(value: number) {
    this.datasource.selectedMediaSubType = 0;
    this.formGroup.get("mediaType").setValue(this.datasource.selectedMediaSubType);
    this.selectedDamProviderId = value;
    this.flexSearchText = (this.selectedDamProviderId == this.damProviders.Bynder) ? 65 : 87;


    if (this.selectedDamProviderId !== DamProviders.Bynder)
      this.selectedToggleImagesId = ToggleImages.Harbour;
    else if ((!this.selectedToggleImagesId || this.selectedDamProviderId === DamProviders.Bynder) && (!this.rolesService.isAdmin()) && !this.imageSelected)
      this.selectedToggleImagesId = this.omniUser ? ToggleImages.Omni : ToggleImages.Harbour;
    else if ((!this.selectedToggleImagesId || this.selectedDamProviderId === DamProviders.Bynder) && (this.rolesService.isAdmin()) && !this.imageSelected)
      this.selectedToggleImagesId = ToggleImages.Harbour;

    if (this.imageSelected && this.selectedDamProviderId === DamProviders.Bynder && this.previousValue !== -99)
      this.selectedToggleImagesId = this.previousValue;

    this.searchImage(this.input?.nativeElement?.value, this.datasource.selectedMediaSubType, this.selectedDamProviderId, this.selectedToggleImagesId);
    this.clearSelection();
  }

  public onImageToggleSelectionChanged(value: number) {
    this.selectedToggleImagesId = value;
    this.previousValue = value;
    this.imageSelected = true;
    this.searchImage(this.input?.nativeElement?.value, this.datasource.selectedMediaSubType, this.selectedDamProviderId, this.selectedToggleImagesId);
  }

  searchImage(searchValue: any, selectedMediaSubType: number, selectedDamProviderId: number, toggleImageValue: number) {
    this.datasource.searchImages(searchValue, selectedMediaSubType, selectedDamProviderId, toggleImageValue);
  }

  areImagesAvailable(mediaSubtype: MediaSubType) {
    if (this.datasource?.availableMediaSubTypes && this.datasource.availableMediaSubTypes.length > 0)
      return this.datasource.availableMediaSubTypes.some(ast => ast.toLowerCase() == mediaSubtype.bynderName?.toLowerCase());

    return true;
  }

  onMouseEnter(index: number) {
    this.hoverIndex = index;
  }

  onMouseLeave() {
    this.hoverIndex = null;
  }

  onScrolled() {
    this.datasource.getNextPage();
  }

  public toggleFilters(): void {
    this.showFilters = !this.showFilters;
  }

  public getFiltersTooltip(): string {
    if (!this.showFilters) {
      return 'Show additional search filters';
    }
    return 'Hide additional search filters';
  }

  @HostListener('window:resize', ['$event'])
  public onResize() {
    this.screenResize();
  }

  public screenResize() {
    const innerWidth = window.innerWidth;
    const totalWidth = window.screen.availWidth;
    if (innerWidth !== totalWidth)
      this.showName = false;
    else
      this.showName = true;
  }
}
