import { Action } from '@ngrx/store';
import { Application } from 'src/app/core/models/application.model';
import { ApplicationPut } from 'src/app/core/models/application-put.model';

export enum ApplicationApiActionTypes {
  LoadSuccess = '[Application/API] Load Success',
  LoadFailure = '[Application/API] Load Failure',
  CreateSuccess = '[Application/API] Create Success',
  CreateFailure = '[Application/API] Create Failure',
  UpdateSuccess = '[Application/API] Update Success',
  UpdateFailure = '[Application/API] Update Failure',
  DeleteSuccess = '[Applications/API] Delete Success',
  DeleteFailure = '[Applications/API] Delete Failure',
  DeleteOptionSuccess = '[Applications/API] Delete Option Success',
  DeleteOptionFailure = '[Applications/API] Delete Option Failure',
}

export class LoadSuccess implements Action {
  readonly type = ApplicationApiActionTypes.LoadSuccess;

  constructor(public payload: Application) { }
}

export class LoadFailure implements Action {
  readonly type = ApplicationApiActionTypes.LoadFailure;

  constructor(public payload: any) { }
}

export class CreateSuccess implements Action {
  readonly type = ApplicationApiActionTypes.CreateSuccess;

  constructor(public payload: Application) { }
}

export class CreateFailure implements Action {
  readonly type = ApplicationApiActionTypes.CreateFailure;

  constructor(public payload: any) { }
}


export class UpdateSuccess implements Action {
  readonly type = ApplicationApiActionTypes.UpdateSuccess;

  constructor(public payload: { application: ApplicationPut, applicationName: string }) { }
}

export class UpdateFailure implements Action {
  readonly type = ApplicationApiActionTypes.UpdateFailure;

  constructor(public payload: any) { }
}

export class DeleteSuccess implements Action {
  readonly type = ApplicationApiActionTypes.DeleteSuccess;

  constructor(public payload: { applicationId: number, applicationName: string }) { }
}

export class DeleteFailure implements Action {
  readonly type = ApplicationApiActionTypes.DeleteFailure;

  constructor(public payload: any) { }
}

export class DeleteOptionSuccess implements Action {
  readonly type = ApplicationApiActionTypes.DeleteOptionSuccess;

  constructor(public payload: { applicationOptionId: number }) { }
}

export class DeleteOptionFailure implements Action {
  readonly type = ApplicationApiActionTypes.DeleteOptionFailure;

  constructor(public payload: any) { }
}

export type ApplicationApiActionsUnion =
  LoadSuccess
  | LoadFailure
  | CreateSuccess
  | CreateFailure
  | UpdateSuccess
  | UpdateFailure
  | DeleteSuccess
  | DeleteFailure
  | DeleteOptionSuccess
  | DeleteOptionFailure;
