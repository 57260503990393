import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import { ISubBrand, SubBrand } from './sub-brand.model';
import { assign as _assign } from 'lodash-es';
import { BrandRelationshipType, IBrandRelationshipType } from './brand-relationship-type.model';
export interface IBrand extends IBaseAuditModel {
  name: string;
  description?: string;
  brandUrl?: string;
  imageLink?: string;
  logoLink?: string;
  relationshipId?: number;
  relationshipType?: IBrandRelationshipType;
  subBrands?: ISubBrand[];
}

export class Brand extends BaseAuditModel {
  name: string;
  description?: string;
  brandUrl?: string;
  imageLink?: string;
  logoLink?: string;
  relationshipId?: number;
  relationshipType?: BrandRelationshipType;
  subBrands?: SubBrand[] = [];

  constructor(data?: IBrand) {
    super(data);
    if (!data) {
      return;
    }
    _assign(this, data);
  }
}
