import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import * as _ from 'lodash';

export interface IApplicationTag extends IBaseAuditModel {
  tag: string;
}

export class ApplicationTag extends BaseAuditModel implements IApplicationTag {
  tag: string;

  constructor(data: IApplicationTag) {
    super(data);

    if (!data) {
      return;
    }

    _.assign(this, data);
  }
}
