import { Locale } from './../../../core/models/locale.model';
import { Region } from './../../../core/models/region.model';
import { FlagsService } from './../../../core/services/flags-service.service';
import { Component, Input, EventEmitter, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-locales-dropdown',
  templateUrl: './locales-dropdown.component.html',
  styleUrls: ['./locales-dropdown.component.scss']
})
export class LocalesDropdownComponent {
  @Input() regions: Array<Region>;
  @Input() selectedLocale: Locale;

  @Output() selectionChange: EventEmitter<Locale> = new EventEmitter();

  constructor(
    private flagsService: FlagsService
  ) { }

  public getLocaleFlagImage(localeName: string): string {
    return this.flagsService.getFlagFileName(localeName);
  }

  public onSelectionChange($event: MatSelectChange): void {
    this.selectionChange.emit($event.value);
  }

  // This function is used by the select element in the HTML, it tells the select element to use the function below
  // to determine if a value passed into the selection matches one of the options, instead of comparing the default
  // JavaScript object identifiers.  When using complex objects as options, this is required to get a match.
  compareFn(l1: Locale, l2: Locale): boolean {
    return l1 && l2 ? l1.id === l2.id : l1 === l2;
  }
}
