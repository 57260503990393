import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import * as _ from 'lodash';

export interface IGlobalProductDuplicateCheck extends IBaseAuditModel {
  name: string;
}

export class GlobalProductDuplicateCheck extends BaseAuditModel implements IGlobalProductDuplicateCheck {
  name: string;

  constructor(data?: IGlobalProductDuplicateCheck) {
    super(data);

    if (!data) {
      return;
    }
    _.assign(this, data);
  }
}
