import { IBaseAuditModel, BaseAuditModel } from './base-audit.model';
import * as _ from 'lodash';

export interface IProductNumberPut extends IBaseAuditModel {
  fullProductNumber: string;
}

export class ProductNumberPut extends BaseAuditModel implements IProductNumberPut {
  fullProductNumber: string;

  constructor(data: IProductNumberPut) {
    super(data);

    if (!data) {
      return;
    }

    _.assign(this, data);
  }
}
