import { Locale } from './locale.model';
import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import * as _ from 'lodash';

export interface IGlobalConfigurationAssociation {
  name: string;

  endpointId?: number;
  endpoint: IGlobalConfigurationAssociationEndpoint;

  integrationId?: number;
  integration: IGlobalConfigurationAssociationIntegration;

  globalProductOfferingId?: number;
  globalProductOffering: IGlobalConfigurationAssociationOffering;

  localeId?: number;
  locale: Locale;
}

export class GlobalConfigurationAssociation implements IGlobalConfigurationAssociation {
  name: string;

  endpointId?: number;
  endpoint: IGlobalConfigurationAssociationEndpoint;

  integrationId?: number;
  integration: IGlobalConfigurationAssociationIntegration;

  globalProductOfferingId?: number;
  globalProductOffering: IGlobalConfigurationAssociationOffering;

  localeId?: number;
  locale: Locale;

  constructor(data?: IGlobalConfigurationAssociation) {
    if (!data) {
      return;
    }

    _.assign(this, data);
  }
}

export interface IGlobalConfigurationAssociationIntegration extends IBaseAuditModel {
  name: string;
  iconLink: string;
}

export class GlobalConfigurationAssociationIntegration extends BaseAuditModel implements IGlobalConfigurationAssociationIntegration {
  name: string;
  iconLink: string;

  constructor(data?: IGlobalConfigurationAssociationIntegration) {
    super(data);

    if (!data) {
      return;
    }

    _.assign(this, data);
  }
}

export interface IGlobalConfigurationAssociationEndpoint extends IBaseAuditModel {
  name: string;
  localeId: number;
}

export class GlobalConfigurationAssociationEndpoint extends BaseAuditModel implements IGlobalConfigurationAssociationEndpoint {
  name: string;
  localeId: number;

  constructor(data?: IGlobalConfigurationAssociationEndpoint) {
    super(data);

    if (!data) {
      return;
    }

    _.assign(this, data);
  }
}


export interface IGlobalConfigurationAssociationOffering extends IBaseAuditModel {
  name: string;
}

export class GlobalConfigurationAssociationOffering extends BaseAuditModel implements IGlobalConfigurationAssociationOffering {
  name: string;

  constructor(data?: IGlobalConfigurationAssociationOffering) {
    super(data);

    if (!data) {
      return;
    }

    _.assign(this, data);
  }
}
