import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { PagedResults } from '../models/page-results.model';
import { GlobalCategorySummary } from '../models/global-category-summary.model';
import { GlobalCategory, IGlobalCategory } from '../models/global-category.model';
import { GlobalCategoryPost } from '../models/global-category-post.model';
import { GlobalCategoryPut } from '../models/global-category-put.model';
import { RenamePut } from '../models/rename-put.model';

@Injectable({
  providedIn: 'root'
})
export class GlobalCategoriesService {
  basePath = 'globalcategories';
  constructor(private http: HttpClient) {
  }

  getGlobalCategories$(searchTerm: string = '', pageNumber: number, pageSize: number, sortActive: string, sortOrder: string): Observable<PagedResults<GlobalCategorySummary>> {
    return this.http.get(`${environment.apiUrl}${this.basePath}`, {
      params: new HttpParams()
        .set('search', searchTerm)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
        .set('orderBy', sortActive.toString())
        .set('sortOrder', sortOrder.toString())
    }).pipe(
      map((response: PagedResults<GlobalCategorySummary>) => response)
    );
  }

  getGlobalCategory$(id: number): Observable<GlobalCategory> {
    return this.http
      .get(environment.apiUrl + `${this.basePath}/${id}`)
      .pipe(map(response => response)
        , map((x: IGlobalCategory) => new GlobalCategory(x)));
  }

  createGlobalCategory$(globalCategory: GlobalCategoryPost): Observable<GlobalCategory> {
    return this.http
      .post(environment.apiUrl + `${this.basePath}`, globalCategory)
      .pipe(map(res => res)
        , catchError((err: Response) => {
          const obj = err;
          return observableThrowError(() => err);
        })
        , map((x: IGlobalCategory) => new GlobalCategory(x)));
  }

  updateGlobalCategory$(globalCategory: GlobalCategoryPut): Observable<GlobalCategoryPut> {
    console.log(`updateGlobalCategory(${globalCategory})`);
    const formData: FormData = new FormData();
    const globalCatgegoryId: any = globalCategory.id;

    if (globalCategory.iconUpload != null) {
      formData.append('iconFile', globalCategory.iconUpload, globalCategory.iconName);
    }
    formData.append('globalCategory', JSON.stringify(globalCategory));

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');


    const params = globalCatgegoryId;
    return this.http
      .put<GlobalCategoryPut>(`${environment.apiUrl + this.basePath}/${globalCatgegoryId}`, formData, { headers, params })
      .pipe(map(response => response),
        catchError((err: Response) => {
          console.log('Error on global category update:');
          console.log(err);
          return observableThrowError(() => err);
        })
      );
  }

  renameGlobalCategory$(request: { globalCategoryId: number, globalCategoryName: string }): Observable<any> {
    const renamePut: RenamePut = new RenamePut({ name: request.globalCategoryName });
    return this.http
      .put(environment.apiUrl + `${this.basePath}/${request.globalCategoryId}/rename`,
        renamePut,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
      );
  }

  deleteGlobalCategory$(globalCategoryId: number): Observable<any> {
    return this.http.delete(environment.apiUrl + `${this.basePath}/${globalCategoryId}`)
      .pipe(
        map(res => res),
        catchError((err: Response) => {
          const obj = err;
          return observableThrowError(() => err);
        })
      );
  }

  getDuplicates$(globalCategoryName: string, industryId: number): Observable<Array<GlobalCategorySummary>> {
    const request: GlobalCategoryPost = new GlobalCategoryPost();
    request.name = globalCategoryName;
    request.industryId = industryId;

    return this.http
      .post(`${environment.apiUrl}${this.basePath}/duplicates`, request)
      .pipe(
        map(response => response),
        catchError((error: Response) => {
          return observableThrowError(() => error);
        }),
        map((response: Array<GlobalCategorySummary>) => response)
      );
  }
}
