import { Locale } from './../models/locale.model';
import { LocaleSpecifier } from './../models/locale-specifier.model';
import { LocaleIds, CurrencyIds } from './../../shared/constants';
import { IApplicationSifPrice } from './../models/application-sif-price.model';
import { IApplicationSif, ApplicationSif } from 'src/app/core/models/application-sif.model';
import { SifResults, ISifResults } from '../models/sif-results.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError as observableThrowError } from 'rxjs';
import * as _ from 'lodash';
import { ContractIds } from 'src/app/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class ApplicationOptionsService {
  basePath = 'applicationoptions';

  constructor(
    private http: HttpClient
  ) { }

  public getBestContractId(locale: Locale): number {
    // Easy implementation to select contract ids based on locale for Canadian pricing story.
    // This will need a better implementation if we add more contracts.
    if (locale && locale.id === LocaleIds.CANADA) {
      return ContractIds.CANADIAN_PRIME;
    }

    return ContractIds.US_PRIME;
  }

  public getBestCurrencyId(localSpecifier: LocaleSpecifier): number {
    if (localSpecifier) {
      return localSpecifier.currencyId;
    }

    return CurrencyIds.USD;
  }

  deleteApplicationOption$(applicationOptionId: number): Observable<any> {
    return this.http.delete(environment.apiUrl + `${this.basePath}/${applicationOptionId}`)
      .pipe(
        map(res => res),
        catchError((err: Response) => {
          const obj = err;
          return observableThrowError(obj);
        })
      );
  }

  processSif$(sifFileUrl: string): Observable<SifResults> {
    return this.http
      .post(`${environment.apiUrl}${this.basePath}/sif`,
        `\"${sifFileUrl}\"`, // this notation is required for sending raw strings in the body.
        { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
      .pipe(
        map(response => response),
        catchError((err: Response) => {
          console.log('Error on processing sif file:');
          console.log(err);
          return observableThrowError(err);
        }),
        map((response: ISifResults) => new SifResults(response))
      );
  }

  public getSifBySifTypeId(sifs: Array<ApplicationSif>, sifTypeId: number): ApplicationSif {
    if (!sifs) {
      return null;
    }
    let sif = sifs.filter(x => x.sifTypeId === sifTypeId);
    if (sif?.length > 0)
      return sif[0];
    return null;
  }

  public getSifPrice(applicationSif: IApplicationSif, currencyId: number, contractId: number): IApplicationSifPrice | null {
    if (!applicationSif) {
      return null;
    }

    let price = applicationSif?.applicationSifPrices?.filter(x =>
      x.currencyId === currencyId &&
      x.contractId === contractId)[0];

    if (!price) {
      price = applicationSif?.applicationSifPrices?.filter(x =>
        x.currencyId === currencyId &&
        x.contractId === null)[0];
    }

    return price;
  }
}
