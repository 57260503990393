import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ghost-accordion',
  templateUrl: './ghost-accordion.component.html',
  styleUrls: ['./ghost-accordion.component.scss']
})
export class GhostAccordionComponent {
  @Input() rows = 5;
  public getRows() {
    return Array.from(Array(this.rows), (x, i) => i);
  }
}
