import { Action } from '@ngrx/store';
import { UserPermissionsQuery } from 'src/app/core/models/user-permissions-query.model';
import { UserPut } from 'src/app/core/models/user-put.model';
import { User } from 'src/app/core/models/user.model';

export enum UserActionTypes {
  LoadUsers = '[Users] Load Users',
  LoadUser = '[Users] Load User',
  CreateUser = '[User] Create User',
  UpdateUser = '[User] Update User',
  DeleteUser = '[User] Delete User',
  ClearMessages = '[User] Clear Messages'
}

export class LoadUsers implements Action {
  readonly type = UserActionTypes.LoadUsers;

  constructor(public payload: UserPermissionsQuery) { }
}

export class LoadUser implements Action {
  readonly type = UserActionTypes.LoadUser;

  constructor(public payload: number) { }
}

export class CreateUser implements Action {
  readonly type = UserActionTypes.CreateUser;

  constructor(public payload: User) { }
}

export class UpdateUser implements Action {
  readonly type = UserActionTypes.UpdateUser;

  constructor(public payload: { user: UserPut, userName: string, roleName: string }) { }
}

export class DeleteUser implements Action {
  readonly type = UserActionTypes.DeleteUser;

  constructor(public payload: { userId: number, userName: string }) { }
}

export class ClearMessages implements Action {
  readonly type = UserActionTypes.ClearMessages;

  constructor() { }
}

export type UserActionsUnion =
    LoadUsers
  | LoadUser
  | CreateUser
  | UpdateUser
  | DeleteUser
  | ClearMessages;
