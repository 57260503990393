import {
  UserApiActions,
  UserActions
} from '../actions';

import { UserPermissionStatus } from 'src/app/shared/constants';
import { User } from 'src/app/core/models/user.model';
import { PagedResults } from 'src/app/core/models/page-results.model';

export interface State {
  users: PagedResults<User>;
  user: User;
  loading: boolean;
  loaded: boolean;
  status: UserPermissionStatus;
  error: string;
  success: string;
}

export const initialState: State = {
  users: null,
  user: null,
  loading: false,
  loaded: false,
  status: UserPermissionStatus.None,
  error: null,
  success: null
};

export function reducer(state = initialState, action: UserActions.UserActionsUnion | UserApiActions.UserApiActionsUnion): State {
  switch (action.type) {

    case UserActions.UserActionTypes.LoadUsers: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case UserApiActions.UserApiActionTypes.LoadPageSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        users: action.payload
      };
    }

    case UserApiActions.UserApiActionTypes.LoadPageFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        users: null,
        error: action.payload
      };
    }

    case UserActions.UserActionTypes.LoadUser: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case UserApiActions.UserApiActionTypes.LoadSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        user: action.payload
      };
    }

    case UserApiActions.UserApiActionTypes.LoadFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        user: null,
        error: action.payload
      };
    }

    case UserActions.UserActionTypes.CreateUser: {
      return {
        ...state,
      };
    }

    case UserApiActions.UserApiActionTypes.CreateSuccess: {
      return {
        ...state,
        status: UserPermissionStatus.CreateSuccess,
        error: null,
        success: `Successfully Created User: ${action.payload.fullName}`
      };
    }

    case UserApiActions.UserApiActionTypes.CreateFailure: {
      return {
        ...state,
        status: UserPermissionStatus.CreateFailure,
        error: 'Failed to Create User',
        success: null
      };
    }

    case UserActions.UserActionTypes.UpdateUser: {
      return {
        ...state,
        loading: true,
        loaded: false,
        status: UserPermissionStatus.None
      };
    }

    case UserApiActions.UserApiActionTypes.UpdateSuccess: {
      return {
        ...state,
        status: UserPermissionStatus.UpdateSuccess,
        error: null,
        success: `${state.user.fullName} was saved successfully.`
      };
    }

    case UserApiActions.UserApiActionTypes.UpdateFailure: {
      return {
        ...state,
        loading: false,
        loaded: true,
        status: UserPermissionStatus.UpdateFailure,
        error: 'Failed to Update User',
        success: null
      };
    }

    case UserActions.UserActionTypes.ClearMessages: {
      return {
        ...state,
        status: UserPermissionStatus.None,
        error: null,
        success: null
      };
    }

    case UserActions.UserActionTypes.DeleteUser: {
      return {
        ...state,
      };
    }

    case UserApiActions.UserApiActionTypes.DeleteSuccess: {
      return {
        ...state,
        status: UserPermissionStatus.DeleteSuccess,
        error: null,
        success: `Successfully Removed User: ${action.payload.userName}`
      };
    }

    case UserApiActions.UserApiActionTypes.DeleteFailure: {
      return {
        ...state,
        status: UserPermissionStatus.DeleteFailure,
        error: 'Failed to Remove User',
        success: null
      };
    }

    default:
      return state;
  }
}

export const getUsers = (state: State) => state.users;
export const getUser = (state: State) => state.user;
export const getErrorMessage = (state: State) => state.error;
export const getSuccessMessage = (state: State) => state.success;
export const getStatus = (state: State) => state.status;
export const isLoading = (state: State) => state.loading;
export const isLoaded = (state: State) => state.loaded;
