<div>
  <div mat-dialog-title>
    <h6 class="h6">
      <strong>{{ data.title }}</strong>
    </h6>
  </div>

  <form [formGroup]="nameForm" autocomplete="off" novalidate (ngSubmit)="applyName()">
    <div mat-dialog-content>
      <mat-form-field appearance="outline">
        <mat-label>{{ data.fieldName }}</mat-label>
        <input autocomplete="off"
          matInput
          (focus)="$event.target.select();"
          id="name"
          formControlName="name"
          [placeholder]="data.placeholder">
          <mat-error *ngIf="hasError('name', 'required')">Template Name is required</mat-error>
          <mat-error *ngIf="hasError('name', 'hasDuplicates')">Template Name already exists</mat-error>
      </mat-form-field>
    </div>

    <div mat-dialog-actions>
      <button class="mat-raised-button button-spacing" [mat-dialog-close]="result">Cancel</button>
      <button class="mat-raised-button primary-button" [disabled]="!nameForm.valid">{{ data.buttonText }}</button>
    </div>
  </form>
</div>
