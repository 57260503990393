import { DesignerPost } from '../../../core/models/designer-post.model';
import { Action } from '@ngrx/store';
import { DesignerPut } from 'src/app/core/models/designer-put.model';

export enum DesignerActionTypes {
  LoadDesigner = '[Designer] Load Designer',
  CreateDesigner = '[Designer] Create Designer',
  UpdateDesigner = '[Designer] Update Designer',
  RenameDesigner = '[Designer] Rename Designer',
  DeleteDesigner = '[Designer] Delete Designer',
  ClearMessages = '[Designer] Clear Messages',
}

export class LoadDesigner implements Action {
  readonly type = DesignerActionTypes.LoadDesigner;

  constructor(public payload: number) { }
}

export class CreateDesigner implements Action {
  readonly type = DesignerActionTypes.CreateDesigner;

  constructor(public payload: DesignerPost) { }
}

export class UpdateDesigner implements Action {
  readonly type = DesignerActionTypes.UpdateDesigner;

  constructor(public payload: { designer: DesignerPut, designerName: string }) { }
}

export class RenameDesigner implements Action {
  readonly type = DesignerActionTypes.RenameDesigner;

  constructor(public payload: { designerId: number, designerName: string }) { }
}

export class DeleteDesigner implements Action {
  readonly type = DesignerActionTypes.DeleteDesigner;

  constructor(public payload: { designerId: number, designerName: string }) { }
}

export class ClearMessages implements Action {
  readonly type = DesignerActionTypes.ClearMessages;

  constructor() { }
}

export type DesignerActionsUnion =
  LoadDesigner
  | CreateDesigner
  | UpdateDesigner
  | RenameDesigner
  | DeleteDesigner
  | ClearMessages;
