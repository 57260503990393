import { Action } from '@ngrx/store';
import { EndpointProductOfferingTagRelationshipDelete, EndpointProductOfferingTagRelationshipPost } from 'src/app/core/models/endpoint-product-offering-tag.model';
import { EndpointProductOfferingsPageRequest } from 'src/app/core/models/endpoint-product-offerings-page-request.model';

export enum EndpointProductOfferingsActionTypes {
  LoadEndpointProductOfferingsPageByEndpointId = '[EndpointProductOfferings] Load EndpointProductOfferings Page',
  RemoveEndpointProductOffering = '[EndpointProductOfferings] Remove EndpointProductOffering',
  RemoveEndpointProductOfferings = '[EndpointProductOfferings] Remove EndpointProductOfferings',
  UpdateEndpointProductOfferings = '[EndpointProductOfferings] Update EndpointProductOfferings',
  ClearMessages = '[EndpointProductOfferings] Clear Messages',

  ApplyOfferingTags = '[EndpointProductOfferings] Apply Tags to EndpointProductOfferings',
  RemoveOfferingTags = '[EndpointProductOfferings] Remove Tags from EndpointProductOfferings',
}

export class LoadEndpointProductOfferingsPageByEndpointId implements Action {
  readonly type = EndpointProductOfferingsActionTypes.LoadEndpointProductOfferingsPageByEndpointId;

  constructor(public payload: EndpointProductOfferingsPageRequest) { }
}

export class RemoveEndpointProductOffering implements Action {
  readonly type = EndpointProductOfferingsActionTypes.RemoveEndpointProductOffering;

  constructor(public payload: { endpointId: number, globalProductOfferingId: number }) { }
}

export class RemoveEndpointProductOfferings implements Action {
  readonly type = EndpointProductOfferingsActionTypes.RemoveEndpointProductOfferings;

  constructor(public payload: { endpointId: number, globalProductOfferingIds: number[] }) { }
}

export class UpdateEndpointProductOfferings implements Action {
  readonly type = EndpointProductOfferingsActionTypes.UpdateEndpointProductOfferings;

  constructor(public payload: { endpointId: number, globalProductOfferingIds: number[] }) { }
}

export class ClearMessages implements Action {
  readonly type = EndpointProductOfferingsActionTypes.ClearMessages;

  constructor() { }
}

export class ApplyOfferingTags implements Action {
  readonly type = EndpointProductOfferingsActionTypes.ApplyOfferingTags;

  constructor(public payload: EndpointProductOfferingTagRelationshipPost) { }
}

export class RemoveOfferingTags implements Action {
  readonly type = EndpointProductOfferingsActionTypes.RemoveOfferingTags;

  constructor(public payload: EndpointProductOfferingTagRelationshipDelete) { }
}

export type EndpointProductOfferingsActionsUnion =
    LoadEndpointProductOfferingsPageByEndpointId
  | RemoveEndpointProductOffering
  | UpdateEndpointProductOfferings
  | ClearMessages
  | ApplyOfferingTags
  | RemoveOfferingTags;
