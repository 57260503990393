import { CategoryPost } from '../../../core/models/category-post.model';
import { Action } from '@ngrx/store';
import { CategoryPut } from 'src/app/core/models/category-put.model';

export enum CategoryActionTypes {
  LoadCategory = '[Category] Load Category',
  CreateCategory = '[Category] Create Category',
  UpdateCategory = '[Category] Update Category',
  RenameCategory = '[Category] Rename Category',
  DeleteCategory = '[Category] Delete Category',
  ClearMessages = '[Category] Clear Messages',
}

export class LoadCategory implements Action {
  readonly type = CategoryActionTypes.LoadCategory;

  constructor(public payload: number) { }
}

export class CreateCategory implements Action {
  readonly type = CategoryActionTypes.CreateCategory;

  constructor(public payload: CategoryPost) { }
}

export class UpdateCategory implements Action {
  readonly type = CategoryActionTypes.UpdateCategory;

  constructor(public payload: { category: CategoryPut, categoryName: string }) { }
}

export class RenameCategory implements Action {
  readonly type = CategoryActionTypes.RenameCategory;

  constructor(public payload: { categoryId: number, categoryName: string }) { }
}

export class DeleteCategory implements Action {
  readonly type = CategoryActionTypes.DeleteCategory;

  constructor(public payload: { categoryId: number, categoryName: string }) { }
}

export class ClearMessages implements Action {
  readonly type = CategoryActionTypes.ClearMessages;

  constructor() { }
}

export type CategoryActionsUnion =
  LoadCategory
  | CreateCategory
  | UpdateCategory
  | RenameCategory
  | DeleteCategory
  | ClearMessages;
