import { GlobalTranslations } from './../../../core/models/global-translations.model';
import { TranslationsService } from './../../../core/services/translations.service';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { TranslationsActions, TranslationsApiActions } from '../actions';
import { of } from 'rxjs';


@Injectable()
export class TranslationsEffects {
  @Effect()
  loadTranslations$ = this.actions$.pipe(
    ofType(TranslationsActions.TranslationsActionTypes.LoadTranslations),
    switchMap(() =>
      this.translationsService.getTranslations$().pipe(
        map((translations: GlobalTranslations) => new TranslationsApiActions.LoadSuccess(translations)),
        catchError(error =>
          of(new TranslationsApiActions.LoadFailure(error))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private translationsService: TranslationsService) {}
}
