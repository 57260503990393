<span [formGroup]="parentForm">
  <mat-form-field appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <mat-select multiple formControlName="locales" id="locales">
      <mat-optgroup *ngFor="let region of regions$ | async" [label]="region.name">
        <mat-option *ngFor="let locale of region.locales" [value]="locale">
          <img
            class="flag"
            src="../../../../../assets/images/flags/{{getLocaleFlagImage(locale.name)}}.png"
            >
          {{locale.name}}
        </mat-option>
      </mat-optgroup>
    </mat-select>
  </mat-form-field>
</span>
