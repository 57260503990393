import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import {
  BrandApiActions,
  BrandActions
} from '../actions';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { SelectOptionsService } from 'src/app/core/services/select-options.service';
import * as fromStore from '../../reducers';
import { Router } from '@angular/router';
import { BrandsService } from 'src/app/core/services/brands.service';

@Injectable()
export class BrandEffects {
  @Effect()
  loadBrandCollection$: Observable<Action> = this.actions$.pipe(
    ofType<BrandActions.LoadBrands>(BrandActions.BrandActionTypes.LoadBrands),
    map((action: BrandActions.LoadBrands) => action.payload),
    switchMap((options) =>
      this.selectOptionsService.getSelectOptions$(options.split(',')).pipe(
        map((selectOptions) => new BrandApiActions.FetchSuccess(selectOptions.brands)
        ),
        catchError(error =>
          of(new BrandApiActions.FetchFailure(error))
        )
      )
    )
  );

  @Effect()
  loadBrand$ = this.actions$.pipe(
    ofType(BrandActions.BrandActionTypes.LoadBrand),
    map((action: BrandActions.LoadBrand) => action.payload),
    switchMap(brandId =>
      this.brandsService.getBrand$(brandId).pipe(
        map(
          (brand) => new BrandApiActions.LoadSuccess(brand)
        ),
        catchError(error =>
          of(new BrandApiActions.LoadFailure(error))
        )
      )
    )
  );

  @Effect()
  createBrand$ = this.actions$.pipe(
    ofType<BrandActions.CreateBrand>(BrandActions.BrandActionTypes.CreateBrand),
    switchMap((request) =>
      this.brandsService.createBrand$(request.payload).pipe(
        map((brand) =>
          new BrandApiActions.CreateSuccess(brand)
        ),
        tap((response) => {
          // Navigate to the page in edit mode.
          this.router.navigate([`products/organizational-data/brand-detail/${response.payload.id}`], { queryParams: { edit: true } });
        }),
        catchError(error =>
          of(new BrandApiActions.CreateFailure(error))
        )
      )
    )
  );

  @Effect()
  updateBrand$: Observable<Action> = this.actions$.pipe(
    ofType<BrandActions.UpdateBrand>(BrandActions.BrandActionTypes.UpdateBrand),
    switchMap(request =>
      this.brandsService.updateBrand$(request.payload.brand).pipe(
        map((response) => new BrandApiActions.UpdateSuccess({ brand: request.payload.brand, brandName: request.payload.brandName })),
        catchError(error => of(new BrandApiActions.UpdateFailure(error)))
      )
    )
  );

  @Effect()
  updateBrandSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(BrandApiActions.BrandApiActionTypes.UpdateSuccess),
    map((action: BrandApiActions.UpdateSuccess) => action.payload),
    map(payload =>
      // Reload the Brand from the API if the save was successful.
      new BrandActions.LoadBrand(payload.brand.id)
    )
  );

  @Effect()
  renameBrand$: Observable<Action> = this.actions$.pipe(
    ofType<BrandActions.RenameBrand>(BrandActions.BrandActionTypes.RenameBrand),
    switchMap((request) =>
      this.brandsService.renameBrand$({
        brandId: request.payload.brandId,
        brandName: request.payload.brandName
      }).pipe(
        map(_ => new BrandApiActions.RenameSuccess({
          brandId: request.payload.brandId,
          brandName: request.payload.brandName
        })
        ),
        tap(res => this.store.dispatch(new BrandActions.LoadBrand(request.payload.brandId)),
          catchError(error =>
            of(new BrandApiActions.RenameFailure(error)),
          )
        )
      )
    )
  );

  @Effect()
  deleteBrand$ = this.actions$.pipe(
    ofType<BrandActions.DeleteBrand>(BrandActions.BrandActionTypes.DeleteBrand),
    switchMap((request) =>
      this.brandsService.deleteBrand$(request.payload.brandId).pipe(
        map((response) =>
          new BrandApiActions.DeleteSuccess({ brandId: request.payload.brandId, brandName: request.payload.brandName })
        ),
        tap((response) => {
          // Navigate to all Brands page
          this.router.navigate([`products/organizational-data/brands`]);
        }),
        catchError(error =>
          of(new BrandApiActions.DeleteFailure(error))
        ),
        tap(res => this.store.dispatch(new BrandActions.LoadBrand(request.payload.brandId)))
      )
    )
  );

  constructor(private actions$: Actions,
    private selectOptionsService: SelectOptionsService,
    private router: Router,
    private store: Store<fromStore.State>,
    private brandsService: BrandsService) { }

}
