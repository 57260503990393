import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import * as _ from 'lodash';

export interface IPortfolioPost extends IBaseAuditModel {
  name: string;
  environmentId: number;
}

export class PortfolioPost extends BaseAuditModel {
  name: string;
  environmentId: number;

  constructor(data?: IPortfolioPost) {
    super(data);

    if (!data) {
      return;
    }

    _.assign(this, data);
  }
}
