<div *ngIf="isOpen"[ngClass]="cssClasses">
  <div class="alert-message-container">
    <div class="alert-message-content-container">
      <strong>{{title}}:&nbsp;</strong>{{message}}
    </div>
    <div class="alert-message-close-button-container">
        <mat-icon class="close-button" (click)="closeAlertMessage()">close</mat-icon>
    </div>
  </div>
</div>
