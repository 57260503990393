import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { SettingSummary, ISettingSummary } from '../models/setting-summary.model';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  basePath = 'settings';
  constructor(private http: HttpClient) {
  }

  getSettings$(): Observable<Array<SettingSummary>> {
    return this.http
      .get(environment.apiUrl + `${this.basePath}`).pipe(
        map(res => res),
        catchError((err: Response) => {
          const obj = err;
          return observableThrowError(obj);
        })
        , map((list: Array<ISettingSummary>) => list.map(b => new SettingSummary(b)))
      );
  }
}
