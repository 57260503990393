import { ProductGuidanceAttribute } from '../../../core/models/product-guidance-attribute.model';
import { Action } from '@ngrx/store';

export enum ProductGuidanceAttributesApiActionTypes {
  LoadSuccess = '[ProductGuidanceAttributes/API] Fetch Success',
  LoadFailure = '[ProductGuidanceAttributes/API] Fetch Failure',
}

export class LoadSuccess implements Action {
  readonly type = ProductGuidanceAttributesApiActionTypes.LoadSuccess;

  constructor(public payload: Array<ProductGuidanceAttribute>) { }
}

export class LoadFailure implements Action {
  readonly type = ProductGuidanceAttributesApiActionTypes.LoadFailure;

  constructor(public payload: any) { }
}

export type ProductGuidanceAttributesApiActionsUnion =
  LoadSuccess
  | LoadFailure;
