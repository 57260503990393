import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import * as fromStore from '../../reducers';

import {
  SubcategoryApiActions,
  SubcategoryActions
} from '../actions';

import { switchMap, map, catchError, tap, mergeMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { SubcategoriesService } from 'src/app/core/services/subcategories.service';

@Injectable()
export class SubcategoryEffects {

  @Effect()
  loadSubcategory$ = this.actions$.pipe(
    ofType(SubcategoryActions.SubcategoryActionTypes.LoadSubcategory),
    map((action: SubcategoryActions.LoadSubcategory) => action.payload),
    switchMap(subcategoryId =>
      this.subcategoriesService.getSubcategory$(subcategoryId).pipe(
        map(
          (subcategory) => new SubcategoryApiActions.LoadSuccess(subcategory)
        ),
        catchError(error =>
          of(new SubcategoryApiActions.LoadFailure(error))
        )
      )
    )
  );

  @Effect()
  createSubcategory$ = this.actions$.pipe(
    ofType<SubcategoryActions.CreateSubcategory>(SubcategoryActions.SubcategoryActionTypes.CreateSubcategory),
    switchMap((request) =>
      this.subcategoriesService.createSubcategory$(request.payload).pipe(
        map((subcategory) =>
          new SubcategoryApiActions.CreateSuccess(subcategory)
        ),
        tap((response) => {
          // Navigate to the page in edit mode.
          this.router.navigate([`products/organizational-data/subcategory-detail/${response.payload.id}`], { queryParams: { edit: true } });
        }),
        catchError(error =>
          of(new SubcategoryApiActions.CreateFailure(error))
        )
      )
    )
  );

  @Effect()
  updateSubcategory$: Observable<Action> = this.actions$.pipe(
    ofType<SubcategoryActions.UpdateSubcategory>(SubcategoryActions.SubcategoryActionTypes.UpdateSubcategory),
    switchMap(request =>
      this.subcategoriesService.updateSubcategory$(request.payload.subcategory).pipe(
        map((response) => new SubcategoryApiActions.UpdateSuccess({ subcategory: request.payload.subcategory, subcategoryName: request.payload.subcategoryName })),
        catchError(error => of(new SubcategoryApiActions.UpdateFailure(error)))
      )
    )
  );

  @Effect()
  updateSubcategorySuccess$: Observable<Action> = this.actions$.pipe(
    ofType(SubcategoryApiActions.SubcategoryApiActionTypes.UpdateSuccess),
    map((action: SubcategoryApiActions.UpdateSuccess) => action.payload),
    map(payload =>
      // Reload the subcategory from the API if the save was successful.
      new SubcategoryActions.LoadSubcategory(payload.subcategory.id)
    )
  );

  @Effect()
  renameSubcategory$: Observable<Action> = this.actions$.pipe(
    ofType<SubcategoryActions.RenameSubcategory>(SubcategoryActions.SubcategoryActionTypes.RenameSubcategory),
    switchMap((request) =>
      this.subcategoriesService.renameSubcategory$({
        subcategoryId: request.payload.subcategoryId,
        subcategoryName: request.payload.subcategoryName
      }).pipe(
        map(_ => new SubcategoryApiActions.RenameSuccess({
          subcategoryId: request.payload.subcategoryId,
          subcategoryName: request.payload.subcategoryName
        })
        ),
        tap(res => this.store.dispatch(new SubcategoryActions.LoadSubcategory(request.payload.subcategoryId)),
          catchError(error =>
            of(new SubcategoryApiActions.RenameFailure(error)),
          )
        )
      )
    )
  );

  @Effect()
  deleteSubcategory$ = this.actions$.pipe(
    ofType<SubcategoryActions.DeleteSubcategory>(SubcategoryActions.SubcategoryActionTypes.DeleteSubcategory),
    switchMap((request) =>
      this.subcategoriesService.deleteSubcategory$(request.payload.subcategoryId).pipe(
        map((response) =>
          new SubcategoryApiActions.DeleteSuccess({ subcategoryId: request.payload.subcategoryId, subcategoryName: request.payload.subcategoryName })
        ),
        tap((response) => {
          // Navigate to all subcategories page
          this.router.navigate([`products/organizational-data/subcategories`]);
        }),
        catchError(error =>
          of(new SubcategoryApiActions.DeleteFailure(error))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<fromStore.State>,
    private subcategoriesService: SubcategoriesService) { }

}
