import { DeleteOfferingTagSuccess, DeleteOfferingTagFailure } from './../actions/endpoint-api.actions';
import { EndpointProductOfferingTag, EndpointProductOfferingTagPost, EndpointProductOfferingTagPut } from './../../../core/models/endpoint-product-offering-tag.model';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import {
  EndpointApiActions,
  EndpointActions
} from '../actions';

import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { EndpointsService } from 'src/app/core/services/endpoints.service';
import { EndpointProductOfferingTagsService } from 'src/app/core/services/endpoint-product-offering-tags.service';

@Injectable()
export class EndpointEffects {
  constructor(
    private actions$: Actions,
    private endpointsService: EndpointsService,
    private endpointOfferingTagsService: EndpointProductOfferingTagsService) {}

  @Effect()
  loadEndpoint$: Observable<Action> = this.actions$.pipe(
    ofType<EndpointActions.LoadEndpoint>(EndpointActions.EndpointActionTypes.LoadEndpoint),
    switchMap((request) =>
      this.endpointsService.getEndpoint$(request.payload.endpointId).pipe(
        map((endpoint) => new EndpointApiActions.LoadSuccess(endpoint)),
        catchError(error =>
          of(new EndpointApiActions.LoadFailure(error))
        )
      )
    )
  );

  @Effect()
  loadEndpointRemaps$: Observable<Action> = this.actions$.pipe(
    ofType<EndpointActions.LoadEndpointRemaps>(EndpointActions.EndpointActionTypes.LoadEndpointRemaps),
    switchMap((request) =>
      this.endpointsService.getRemaps$(request.payload.endpointId).pipe(
        map((remaps) => new EndpointApiActions.LoadEndpointRemapsSuccess(remaps)),
        catchError(error =>
          of(new EndpointApiActions.LoadEndpointRemapsFailure(error))
        )
      )
    )
  );

  @Effect()
  loadCategories: Observable<Action> = this.actions$.pipe(
    ofType<EndpointActions.LoadCategories>(EndpointActions.EndpointActionTypes.LoadCategories),
    switchMap((request) =>
      this.endpointsService.getCategories$(request.payload.endpointId).pipe(
        map((categories) => new EndpointApiActions.LoadCategoriesSuccess(categories)),
        catchError(error =>
          of(new EndpointApiActions.LoadCategoriesFailure(error))
        )
      )
    )
  );

  @Effect()
  updateRemaps: Observable<Action> = this.actions$.pipe(
    ofType<EndpointActions.UpdateRemaps>(EndpointActions.EndpointActionTypes.UpdateRemaps),
    switchMap((request) =>
      this.endpointsService.updateRemaps$(request.payload).pipe(
        switchMap(() => [
          new EndpointApiActions.UpdateRemapsSuccess(),
          new EndpointActions.LoadEndpointRemaps({ endpointId: request.payload.endpointId })
          //new EndpointActions.LoadEndpoint({ endpointId: request.payload.endpointId })
        ]),
        catchError(error =>
          of(new EndpointApiActions.UpdateRemapsFailure(error))
        )
      )
    )
  );

  @Effect()
  loadEndpointProductOfferingTags$: Observable<Action> = this.actions$.pipe(
    ofType<EndpointActions.LoadOfferingTags>(EndpointActions.EndpointActionTypes.LoadOfferingTags),
    switchMap((request) =>
      this.endpointOfferingTagsService.getAll$(request.payload).pipe(
        map((tags) => new EndpointApiActions.LoadOfferingTagsSuccess(tags)),
        catchError(error =>
          of(new EndpointApiActions.LoadOfferingTagsFailure(error))
        )
      )
    )
  );

  @Effect()
  createEndpointProductOfferingTag$ = this.actions$.pipe(
    ofType(EndpointActions.EndpointActionTypes.CreateOfferingTag),
    map((action: EndpointActions.CreateOfferingTag) => action.payload),
    mergeMap((request: EndpointProductOfferingTagPost) =>
        this.endpointOfferingTagsService.create$(request.endpointId, request).pipe(
        map((tag: EndpointProductOfferingTag) =>
          new EndpointApiActions.CreateOfferingTagSuccess(tag)
        ),
        catchError((error: any) =>
          of(new EndpointApiActions.CreateOfferingTagFailure(error))
        ),
      )
    )
  );

  @Effect()
  updateEndpointProductOfferingTag$ = this.actions$.pipe(
    ofType(EndpointActions.EndpointActionTypes.UpdateOfferingTag),
    map((action: EndpointActions.UpdateOfferingTag) => action.payload),
    mergeMap((request: EndpointProductOfferingTagPut) =>
      this.endpointOfferingTagsService.update$(request.endpointId, request.id, request).pipe(
        map(() =>
          new EndpointApiActions.UpdateOfferingTagSuccess()
        ),
        catchError((error: any) =>
          of(new EndpointApiActions.UpdateOfferingTagFailure(error))
        )
      )
    )
  );

  @Effect()
  deleteEndpointProductOfferingTag$ = this.actions$.pipe(
    ofType(EndpointActions.EndpointActionTypes.DeleteOfferingTag),
    map((action: EndpointActions.DeleteOfferingTag) => action.payload),
    switchMap((payload) => {
      return this.endpointOfferingTagsService.delete$(payload.endpointId, payload.endpointProductOfferingTagId).pipe(
        map(() => {
          return new EndpointApiActions.DeleteOfferingTagSuccess();
        }),
        catchError((error: any) => {
          return of(new EndpointApiActions.DeleteOfferingTagFailure(error));
        })
      );
    })
  );
}
