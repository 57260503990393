<div class="digital-asset-library-container" infiniteScroll
  [infiniteScrollContainer]="'app-add-product-literature-modal .mat-tab-body-content'" [fromRoot]="true"
  [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" (scrolled)="onScrolled()" [scrollWindow]="false">
  <div class="search-container" [ngClass]="{'disable-till-load': (loadAssetPurpose$ | async).length === 0 && (loadMediaSubType$ | async).length === 0 && (loadAssetPurposeOmni$ | async).length === 0}">
    <mat-form-field class="wrapper-none" appearance="outline">
      <input matInput value="{{ datasource.searchTerm }}" #search placeholder="Search...">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    <div fxFlex="nogrow" fxLayoutAlign="end center" class="mat-button-toggle-collapsed toggle-alignment">
      <mat-button-toggle-group [value]="selectedDamProviderId">
        <mat-button-toggle *ngFor="let damProvider of getDamProviders((damProviders | enumToArray))"
          [value]="damProvider.index" (change)="onDamProviderSelectionChanged($event.value)">
          {{damProvider.name}}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <div [ngClass]="{'disable-till-load': (loadAssetPurpose$ | async).length === 0 && (loadMediaSubType$ | async).length === 0 && (loadAssetPurposeOmni$ | async).length === 0}">
    <table class="vp-table" mat-table [dataSource]="datasource">

      <ng-container matColumnDef="{{columnDefSelect}}">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? datasource.toggleSelection() : null"
            [checked]="datasource.selection.hasValue() && datasource.isAllSelected()"
            [indeterminate]="datasource.selection.hasValue() && !datasource.isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? datasource.selection.toggle(row) : null"
            [checked]="datasource.selection.isSelected(row)" [disabled]="isCheckBoxDisabled(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="{{columnDefName}}">
        <th mat-header-cell *matHeaderCellDef> Document Title </th>
        <td mat-cell *matCellDef="let document" class="name-cell">
          {{ getDocumentTitle(document) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="{{columnDefDocumentTypeName}}">
        <th mat-header-cell *matHeaderCellDef> Document Type </th>
        <td mat-cell *matCellDef="let document">
          <span *ngIf="document.documentType; else noDocumentType">{{ document.documentType.name }}</span>
          <ng-template #noDocumentType>None</ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="{{columnDefLocales}}">
        <th mat-header-cell *matHeaderCellDef> Locales </th>
        <td mat-cell *matCellDef="let document">

          <div *ngIf="document.locales && document.locales.length > 0; else noLocales">
            <div *ngIf="document.locales.length > 1; else singleLocale">
              <div class="multiple-locales" [matTooltip]="getLocaleTooltip(document)" matTooltipPosition="below"
                matTooltipClass="tooltip-multiline">
                <span><em>Multiple</em></span>
                <mat-icon>info</mat-icon>
              </div>
            </div>
            <ng-template #singleLocale>
              {{ document.locales[0].code }}
            </ng-template>
          </div>
          <ng-template #noLocales><em>None</em></ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="{{columnDefOpenDocument}}">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let document">
          <div *ngIf="document.url && document.url.length > 0">
            <button matTooltip="View Document" mat-icon-button disableRipple (click)="showDocument($event, document)">
              <mat-icon class="subdue-icon">launch</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <!-- Default functionality for Headers and Rows -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let document; columns: displayedColumns;" class="table-row"
        (click)="!isCheckBoxDisabled(document) ? datasource.selection.toggle(document) : null">
      </tr>
    </table>

    <div class="loading-message"
      *ngIf="!(datasource.loading$ | async) && (datasource.assets$ | async) && (datasource.assets$ | async).length === 0">
      <span>No assets found.</span>
    </div>

    

    <div *ngIf="(datasource.loading$ | async)">
      <div class="spinner-container">
        <span class="spinner">
          <mat-spinner color='accent' diameter="15"></mat-spinner>
        </span>
        <span class="spinner-message">Retrieving Digital Assets...</span>
      </div>
    </div>

    <div class="loading-message" *ngIf="datasource.allRecordsLoaded">
      <span>All digital assets have been loaded.</span>
    </div>
  </div>

  <div *ngIf="((loadAssetPurpose$ | async).length === 0 && (loadMediaSubType$ | async).length === 0 && (loadAssetPurposeOmni$ | async).length === 0)">
    <div class="bynder-spinner">
      <span>
        <mat-spinner color='accent' diameter="25"></mat-spinner>
      </span>
      <span class="bynder-spinner-text">Loading bynder assets...</span>
    </div>
  </div>

</div>
