import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import * as _ from 'lodash';

export interface IWarranty extends IBaseAuditModel {
  name: string;
}

export class Warranty extends BaseAuditModel implements IWarranty {
  name: string;

  constructor(data: IWarranty) {
    super(data);

    if (!data) {
      return;
    }

    _.assign(this, data);
  }
}
