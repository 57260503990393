import { GlobalProductPut, GlobalProductSubcategoryPut, GlobalProductLocalePut, GlobalProductTagPut, GlobalProductWebLinkPut, GlobalProductLinkPut, GlobalProductCertificationPut, ModelFilesProfilePut, GlobalProductMediaLinkPut, GlobalProductGlobalSubcategoryPut, GlobalProductAttributeOptionPut, AttributeOptionOfferingExclusionsPut, GlobalProductConfiguratorPut } from './../models/global-product-put.model';
import { GlobalProductCertification } from './../models/global-product-certification.model';
import { GlobalProductLink } from './../models/global-product-link.model';
import { ProductDetailFormSchemaService } from './product-detail-form-schema.service';
import { GlobalProductTag } from './../models/global-product-tag.model';
import { Locale } from 'src/app/core/models/locale.model';
import { Subcategory } from 'src/app/core/models/subcategory.model';
import { GlobalProduct } from './../models/global-product.model';
import { FormGroup, FormControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import { assign as _assign } from 'lodash-es';
import { ModelFilesProfile } from '../models/model-files-profile.model';
import { ContactGlobalProductLocale } from '../models/contact-global-product-locale.model';
import { ContactGlobalProductLocalePut } from '../models/contact-global-product-local-put.model';
import { GlobalProductMediaLink } from '../models/global-product-media-link.model';
import { GlobalSubcategory } from '../models/global-subcategory.model';
import { GlobalProductAttributeOption } from '../models/global-product-attribute-option.model';
import { AttributeOption } from '../models/attribute-option.model';
import { AttributeOptionOfferingExclusion } from '../models/attribute-option-offering-exclusion.model';
import { AttributeOptionLite } from '../models/attribute-option-lite.model';

// Object Purpose: Data retrieval from the product detail form.
@Injectable({
  providedIn: 'root'
})
export class ProductDetailFormDataService {

  constructor(
    private schema: ProductDetailFormSchemaService
  ) { }

  // Form Values
  public brandId(form: FormGroup): number {
    return (this.schema.brandIdFormControl(form).value as number);
  }

  public subBrandId(form: FormGroup): number {
    return (this.schema.subBrandIdFormControl(form).value as number);
  }

  public includeInCoBranding(form: FormGroup): boolean {
    return (this.schema.includeInCoBrandingFormControl(form).value as boolean);
  }

  public designers(form: FormGroup): Array<number> {
    return (this.schema.designersFormControl(form).value as Array<number>);
  }

  public families(form: FormGroup): Array<number> {
    return (this.schema.familiesFormControl(form).value as Array<number>);
  }

  public portfolios(form: FormGroup): Array<number> {
    return (this.schema.portfoliosFormControl(form).value as Array<number>);
  }

  public productStatusId(form: FormGroup): number {
    return (this.schema.productStatusIdFormControl(form).value as number);
  }

  public subcategories(form: FormGroup): Array<Subcategory> {
    return (this.schema.subCategoriesFormControl(form).value as Array<Subcategory>);
  }

  public primarySubcategoryId(form: FormGroup): number {
    return (this.schema.primarySubcategoryIdFormControl(form).value as number);
  }

  public pageTitleKeyword(form: FormGroup): string {
    return (this.schema.pageTitleKeywordFormControl(form).value as string);
  }

  public globalSubcategories(form: FormGroup): Array<GlobalSubcategory> {
    return (this.schema.globalSubcategoriesFormControl(form).value as Array<GlobalSubcategory>);
  }

  public primaryGlobalSubcategoryId(form: FormGroup): number {
    return (this.schema.primaryGlobalSubcategoryIdFormControl(form).value as number);
  }

  public locales(form: FormGroup): Array<Locale> {
    return (this.schema.localesFormArray(form).controls.map((control: FormControl) =>
      (control.value as Locale)) as Array<Locale>
    );
  }

  public primaryLocaleId(form: FormGroup): number {
    return (this.schema.primaryLocaleIdFormControl(form).value as number);
  }

  public productTags(form: FormGroup, globalProductId: number): Array<GlobalProductTag> {
    const globalProductTags: Array<GlobalProductTag> = [];
    this.schema.productTagsFormArray(form).controls.forEach((group: FormGroup) => {

      const productTag = new GlobalProductTag({
        id: (group.controls.tagId.value as number),
        globalProductId,
        tag: (group.controls.tag.value as string)
      });

      globalProductTags.push(productTag);
    });

    return globalProductTags;
  }

  public getParentAttributeOptionByAttributeOption(globalProductAttributeOptions: GlobalProductAttributeOption[], attributeOption: AttributeOption): AttributeOptionLite[] {
    const attributeOptionDetails = this.getCurrentAttributeOption(globalProductAttributeOptions, attributeOption);
    var parentAttributeOption = this.getAttributeOptionLite(attributeOptionDetails);
    return parentAttributeOption;
  }

  public getAttributeOptionLite(globalProductAttributeOption: GlobalProductAttributeOption): AttributeOptionLite[] {
    return globalProductAttributeOption?.globalProductParentAttributeOptions?.map(x => x.parentAttributeOption);
  }

  public getCurrentAttributeOption(globalProductAttributeOptions: GlobalProductAttributeOption[], attributeOption: AttributeOption): GlobalProductAttributeOption {
    return globalProductAttributeOptions?.find(x => x.attributeOptionId == attributeOption.id);
  }

  public getParentAttributeOptionValue(parentAttributeOptions: Array<AttributeOptionLite>): string {
    if (parentAttributeOptions && parentAttributeOptions.length > 0) {
      const resultArray: string[] = [];
      parentAttributeOptions.forEach((parentAttributeOption) => {
        const name = parentAttributeOption.name || '';
        const subcategoryName = parentAttributeOption.globalSubcategoryAttributeType?.attributeType.name || '';
        if (name || subcategoryName) {
          resultArray.push(`${name}${name && subcategoryName ? ' - ' : ''}${subcategoryName}`);
        }
      });
      return resultArray.join(', ');
    }
    return null;
  }

  public checkOfferingCount(attributeOption: AttributeOption, allProductOfferingIds: Array<number>, offeringCount: number): string {
    const existingOfferingCount = allProductOfferingIds?.filter(id =>
      attributeOption?.offeringExclusions?.some(exclusion => exclusion?.globalProductOfferingId === id)).length;
    return (offeringCount - existingOfferingCount).toString();
  }

  public warrantyId(form: FormGroup): number {
    return (this.schema.warrantyIdFormControl(form).value as number);
  }

  public isSpecialDelivery(form: FormGroup): boolean {
    return (this.schema.isSpecialDeliveryFormControl(form).value as boolean);
  }

  public isEssentials(form: FormGroup): boolean {
    return (this.schema.isEssentialsFormControl(form).value as boolean);
  }

  public configurator(form: FormGroup): string {
    return (this.schema.configuratorFormControl(form).value as string);
  }

  public brandSiteProductLink(form: FormGroup): string {
    return (this.schema.brandSiteProductLinkFormControl(form).value as string);
  }

  public materialsLink(form: FormGroup): string {
    return (this.schema.materialsLinkFormControl(form).value as string);
  }

  public scoutLink(form: FormGroup): string {
    return (this.schema.scoutLinkFormControl(form).value as string);
  }

  public omniLink(form: FormGroup): string {
    return (this.schema.omniLinkFormControl(form).value as string);
  }

  public literature(form: FormGroup): Array<GlobalProductLink> {
    return (this.schema.literatureFormArray(form).controls.map((control: FormControl) => (control.value as GlobalProductLink)) as Array<GlobalProductLink>);
  }

  public certifications(form: FormGroup): Array<GlobalProductCertification> {
    return (this.schema.certificationsFormArray(form).controls.map((control: FormControl) => (control.value as GlobalProductCertification)) as Array<GlobalProductCertification>);
  }

  public attributeOptions(form: FormGroup): Array<GlobalProductAttributeOption> {
    return (this.schema.attributeOptionsFormArray(form).controls.map((control: FormControl) => (control.value as GlobalProductAttributeOption)) as Array<GlobalProductAttributeOption>);
  }

  public attributeOptionsOfferingExclusions(form: FormGroup): Array<AttributeOptionOfferingExclusion> {
    return (this.schema.globalProductAttributeOptionsOfferingExclusionsFormArray(form).controls.map((control: FormControl) => (control.value as AttributeOptionOfferingExclusion)) as Array<AttributeOptionOfferingExclusion>);
  }

  public modelFilesProfiles(form: FormGroup): Array<ModelFilesProfile> {
    return (this.schema.modelFilesProfilesFormArray(form).controls.map((control: FormControl) => (control.value as ModelFilesProfile)) as Array<ModelFilesProfile>);
  }

  public contacts(form: FormGroup): Array<ContactGlobalProductLocale> {
    return (this.schema.contactsFormArray(form).controls.map((control: FormControl) => (control.value as ContactGlobalProductLocale)) as Array<ContactGlobalProductLocale>);
  }

  public globalProductMediaLinks(form: FormGroup): Array<GlobalProductMediaLink> {
    return (this.schema.globalProductMediaLinksFormArray(form).controls.map((control: FormControl) => (control.value as GlobalProductMediaLink)) as Array<GlobalProductMediaLink>);
  }

  // This is a basic product filling method.  When doing the save story, evaluate if a ProductUpdate model makes more sense.
  // Additionally, this is just setup code for the save method.  It will likely have to be altered a great deal once the save story is being done.
  public updateProduct(globalProduct: GlobalProduct, form: FormGroup): GlobalProductPut {
    const product: GlobalProductPut = new GlobalProductPut();
    product.id = globalProduct.id;

    this.setProductBrandValues(product, form);
    this.setProductOrganizationValues(product, form);
    this.setProductCategoriesValues(product, form);
    this.setProductGlobalCategoriesValues(product, form);
    this.setProductLocaleValues(product, form);
    this.setProductProductTagValues(product, form);
    this.setProductAttributesValues(product, form, globalProduct);
    this.setProductWebLinksValues(product, form);
    this.setProductLiteratureValues(product, form);
    this.setProductCertificationsValue(product, form);
    this.setProductAttributeOptionsValue(product, form);
    this.setModelFilesProfilesValue(product, form);
    this.setcontactsValue(product, form);
    this.setGlobalProductMediaLinkValue(product, form);
    this.setGlobalProductAttributeOptionOfferingExclusions(product, form);
    this.setProductStatusValues(product, form);

    return product;
  }

  private setProductBrandValues(product: GlobalProductPut, form: FormGroup): void {
    product.brandId = this.brandId(form);
    product.subBrandId = this.subBrandId(form);
    product.includeInCoBranding = this.includeInCoBranding(form);
  }

  private setProductOrganizationValues(product: GlobalProductPut, form: FormGroup): void {
    product.designerIds = this.designers(form);
    product.familyIds = this.families(form);
    product.portfolioIds = this.portfolios(form);
  }

  private setProductCategoriesValues(product: GlobalProductPut, form: FormGroup): void {
    product.globalProductSubcategories = [];
    this.subcategories(form).forEach(subcategory => {
      let isPrimary = false;
      if (this.primarySubcategoryId(form) && this.primarySubcategoryId(form) === subcategory.id) {
        isPrimary = true;
      }

      const model = new GlobalProductSubcategoryPut({ subcategoryId: subcategory.id, isPrimary });
      product.globalProductSubcategories.push(model);
    });

    product.pageTitleKeyword = this.pageTitleKeyword(form);
  }

  private setProductGlobalCategoriesValues(product: GlobalProductPut, form: FormGroup): void {
    product.globalProductGlobalSubcategories = [];
    this.globalSubcategories(form).forEach(globalSubcategory => {
      let isPrimary = false;
      if (this.primaryGlobalSubcategoryId(form) && this.primaryGlobalSubcategoryId(form) === globalSubcategory.id) {
        isPrimary = true;
      }

      const model = new GlobalProductGlobalSubcategoryPut({ globalSubcategoryId: globalSubcategory.id, isPrimary });
      product.globalProductGlobalSubcategories.push(model);
    });
  }

  private setProductLocaleValues(product: GlobalProductPut, form: FormGroup): void {
    product.globalProductLocales = [];
    this.locales(form).forEach(locale => {
      let isPrimaryLocale = false;
      if (this.primaryLocaleId && this.primaryLocaleId(form) === locale.id) {
        isPrimaryLocale = true;
      }
      const model: GlobalProductLocalePut = { localeId: locale.id, isPrimaryLocale };
      product.globalProductLocales.push(model);
    });
  }

  private setProductProductTagValues(product: GlobalProductPut, form: FormGroup): void {
    product.globalProductTags = [];
    const tags = [...this.productTags(form, product.id)];
    if (tags && tags.length > 0) {
      product.globalProductTags.push(...tags.map(t => _assign(new GlobalProductTagPut(), t)));
    }
  }

  private setProductAttributesValues(product: GlobalProductPut, form: FormGroup, globalProduct: GlobalProduct): void {
    product.warrantyId = this.warrantyId(form);
    product.isSpecialDelivery = this.isSpecialDelivery(form);
    product.isEssentials = this.isEssentials(form);
    // Set product configurator value
    product.globalProductConfigurators = [];
    const newConfigurator = this.configurator(form)?.trim();
    const existingConfigurator = globalProduct.globalProductConfigurators.find(gpc => gpc.isPrimary);
    let globalProductConfiguratorPut: GlobalProductConfiguratorPut;
    if (newConfigurator) {
      if (newConfigurator == existingConfigurator?.configurator) {
        globalProductConfiguratorPut = new GlobalProductConfiguratorPut({ configurator: newConfigurator, isPrimary: true, id: existingConfigurator.id });
      }
      else {
        globalProductConfiguratorPut = new GlobalProductConfiguratorPut({ configurator: newConfigurator, isPrimary: true, id: 0 });
      }
      product.globalProductConfigurators.push(...[globalProductConfiguratorPut]);
    }
  }

  private setProductWebLinksValues(product: GlobalProductPut, form: FormGroup): void {

    product.globalProductWebLinks = [];
    this.schema.productLinksFormArray(form).controls.forEach((group: FormGroup) => {
      const webLink = this.getGlobalProductWebLinkFromFormGroup(group, 0);
      if (webLink) {
        product.globalProductWebLinks.push(webLink);
      }
    });

    this.schema.storeLinksFormArray(form).controls.forEach((group: FormGroup) => {
      const webLink = this.getGlobalProductWebLinkFromFormGroup(group, 1);
      if (webLink) {
        product.globalProductWebLinks.push(webLink);
      }
    });

    product.brandSiteProductLink = this.brandSiteProductLink(form);
    product.materialsLink = this.materialsLink(form);
    product.scoutLink = this.scoutLink(form);
    product.omniLink = this.omniLink(form);
  }

  private getGlobalProductWebLinkFromFormGroup(group: FormGroup, webLinkTypeId: number): GlobalProductWebLinkPut {
    // omit any web links where the url wasn't set.
    if (!group.controls.webLinkUrl.value) {
      return null;
    }

    return new GlobalProductWebLinkPut({
      id: (group.controls.id.value as number),
      localeId: (group.controls.localeId.value as number),
      webLinkTypeId,
      webLinkUrl: (group.controls.webLinkUrl.value as string)
    });
  }

  private setProductLiteratureValues(product: GlobalProductPut, form: FormGroup): void {
    product.globalProductLinks = [];
    this.schema.literatureFormArray(form).controls.forEach((control: FormControl) => {
      const literature = (control.value as GlobalProductLink);
      if (literature) {
        const document: GlobalProductLinkPut = _assign(new GlobalProductLinkPut(), literature);
        document.localeIds = literature.globalProductLinkLocales.map(l => l.localeId);
        product.globalProductLinks.push(document);
      }
    });
  }

  private setProductCertificationsValue(product: GlobalProductPut, form: FormGroup): void {
    product.globalProductCertifications = [];
    this.schema.certificationsFormArray(form).controls.forEach((control: FormControl) => {
      const certification = (control.value as GlobalProductCertification);
      if (certification) {
        const document: GlobalProductCertificationPut = _assign(new GlobalProductCertificationPut(), certification);
        document.localeIds = certification.globalProductCertificationLocales.map(l => l.localeId);
        product.globalProductCertifications.push(document);
      }
    });
  }

  private setProductAttributeOptionsValue(product: GlobalProductPut, form: FormGroup): void {
    product.globalProductAttributeOptions = [];
    this.schema.attributeOptionsFormArray(form).controls.forEach((control: FormControl) => {
      const globalProductAttributeOption = (control.value as GlobalProductAttributeOption);
      if (globalProductAttributeOption) {
        const globalProductAttributeOptionPut: GlobalProductAttributeOptionPut = _assign(new GlobalProductAttributeOptionPut(), globalProductAttributeOption);
        globalProductAttributeOptionPut.globalProductId = product.id;
        product.globalProductAttributeOptions.push(globalProductAttributeOptionPut);
      }
    });
  }

  private setModelFilesProfilesValue(product: GlobalProductPut, form: FormGroup): void {
    product.modelFilesProfiles = [];
    this.schema.modelFilesProfilesFormArray(form).controls.forEach(
      (control: FormControl) => {
        const modelFilesProfile = (control.value as ModelFilesProfilePut);
        if (modelFilesProfile) {
          modelFilesProfile.globalProductId = product.id;
          product.modelFilesProfiles.push(modelFilesProfile);
        }
      }
    );
  }

  private setcontactsValue(product: GlobalProductPut, form: FormGroup): void {
    product.contacts = [];
    this.schema.contactsFormArray(form).controls.forEach(
      (control: FormControl) => {
        const contact = (control.value as ContactGlobalProductLocale);
        if (contact) {
          const contactPut = new ContactGlobalProductLocalePut();
          contactPut.contactId = contact.contact.id;
          contactPut.globalProductId = product.id;
          contactPut.localeId = contact.locale.id;

          product.contacts.push(contactPut);
        }
      }
    );
  }

  private setGlobalProductMediaLinkValue(product: GlobalProductPut, form: FormGroup): void {
    product.globalProductMediaLinks = [];
    this.schema.globalProductMediaLinksFormArray(form).controls.forEach(
      (control: FormControl) => {
        const globalProductMedia = (control.value as GlobalProductMediaLinkPut);
        if (globalProductMedia) {
          globalProductMedia.globalProductId = product.id;
          globalProductMedia.ordinal = 0;
          product.globalProductMediaLinks.push(globalProductMedia);
        }
      }
    );
  }

  setGlobalProductAttributeOptionOfferingExclusions(product: GlobalProductPut, form: FormGroup<any>) {
    const stateString = sessionStorage.getItem('saveExclusions');
    if (stateString) {
      if (stateString == 'true') {
        this.setGlobalProductAttributeOptionOfferingExclusionsValue(product, form);
        sessionStorage.setItem('saveExclusions', JSON.stringify(false));
      }
    }
  }

  private setGlobalProductAttributeOptionOfferingExclusionsValue(product: GlobalProductPut, form: FormGroup): void {
    product.globalProductAttributeOptionsOfferingExclusions = [];
    this.schema.globalProductAttributeOptionsOfferingExclusionsFormArray(form).controls.forEach(
      (control: FormControl) => {
        const globalProductAttributeOption = (control.value as AttributeOptionOfferingExclusion);
        const offeringExclusionsPut: AttributeOptionOfferingExclusionsPut = _assign(new AttributeOptionOfferingExclusionsPut(), globalProductAttributeOption);
        product.globalProductAttributeOptionsOfferingExclusions.push(offeringExclusionsPut);
      }
    );
  }

  private setProductStatusValues(product: GlobalProductPut, form: FormGroup): void {
    product.status = this.productStatusId(form);
  }

}
