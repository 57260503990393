import { DamProviderIds } from './../../constants';
import { catchError, finalize } from 'rxjs/operators';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { ImageAsset } from './../../../core/models/image-asset.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DigitalAssetManagerService } from 'src/app/core/services/digital-asset-manager.service';
import { DamImageAssetResponse } from 'src/app/core/models/dam-image-asset-response.model';
import { MediaSubType } from 'src/app/core/models/media-subtype.model';

export enum Views {
  ImageGrid,
  Image,
  ImageUrl
}

export class DigitalAssetManagerModalDataSource {
  searchTerm: string;
  selectedMediaSubType: number = 0;
  availableMediaSubTypes: Array<string> = [];
  selectedLocale: string;
  damProviderId = 1;
  pageNumber = 1;
  pageSize = 30;
  totalRecordCount = 0;
  totalPageCount = 0;
  assets: Array<ImageAsset> = [];
  loading$ = new BehaviorSubject<boolean>(false);
  allRecordsLoaded: boolean;
  subscriptions: Array<Subscription> = [];
  toggleImageValue: number;
  mediaSubTypes: Array<MediaSubType> = [];

  addImageUrlFormGroup: FormGroup = new FormGroup({
    imageUrl: new FormControl('', [Validators.required]),
    mediaSubType: new FormControl(),
    altText: new FormControl(),
    title: new FormControl()
  });

  visibleView: Views;
  isMultiple: boolean;
  selectedImages: Array<ImageAsset> = [];
  selectedImage: ImageAsset;
  get addImageUrl(): string { return this.addImageUrlFormGroup.controls.imageUrl.value; }
  set addImageUrl(value: string) { this.addImageUrlFormGroup.controls.imageUrl.setValue(value); }

  constructor(private digitalAssetManagerService: DigitalAssetManagerService) { }

  searchImages(searchTerm: string, mediaSubType: number, damProviderId: number = DamProviderIds.Bynder, toggleImageValue?: number) {
    this.reset();
    this.clearSelection();
    this.searchTerm = searchTerm;
    this.selectedMediaSubType = mediaSubType;
    this.damProviderId = damProviderId;
    if (!this.searchTerm) {
      this.availableMediaSubTypes = [];
      return;
    }
    this.toggleImageValue = toggleImageValue;
    this.load();
  }

  getNextPage() {
    // The maximum number of pages for this search has been met.  Don't call the api.
    if (this.totalPageCount <= this.pageNumber) {
      this.allRecordsLoaded = true;
      return;
    }

    this.pageNumber++;
    this.load();
  }

  disconnect() {
    // prevent memory leak when component destroyed
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private load() {
    this.loading$.next(true);
    this.availableMediaSubTypes = [];
    this.subscriptions.push(
      this.digitalAssetManagerService.getImageAssets$(this.searchTerm, this.selectedMediaSubType, this.selectedLocale, this.pageNumber, this.pageSize, this.damProviderId, this.toggleImageValue)
        .pipe(
          catchError(() => of([])),
          finalize(() => this.loading$.next(false))
        )
        .subscribe((response: DamImageAssetResponse) => {
          if (response?.imageAssets?.results) {
            this.assets.push(...response.imageAssets.results);
            this.totalRecordCount = response.imageAssets.totalRecordCount;
            this.totalPageCount = response.imageAssets.totalPageCount;
            this.availableMediaSubTypes.push(...response.mediaSubTypes);
          }
        })
    );
  }

  checkSelected(image: ImageAsset): boolean {
    const index = this.getSelectedImageIndex(image);

    if (index > -1) {
      return true;
    } else {
      return false;
    }
  }

  clearSelection() {
    this.selectedImages = [];
  }

  selectedImageAltText(image: ImageAsset) {
    return this.selectedImage?.altText;
  }

  getSelectedImageFileName() {
    return this.selectedImage.url
      .substring(this.selectedImage.url.lastIndexOf('/') + 1);
  }

  getImageUrlFileName() {
    return this.addImageUrl
      .substring(this.addImageUrl.lastIndexOf('/') + 1);
  }

  viewImage(image: ImageAsset) {
    this.visibleView = Views.Image;
    this.selectedImage = image;
  }

  viewImages() {
    this.visibleView = Views.ImageGrid;
    this.selectedImage = null;
  }

  selectGridImage(image: ImageAsset) {
    if (!this.isMultiple) {
      this.selectSingleImage(image);
    } else {
      this.selectMultipleImage(image);
    }
  }

  selectImage() {
    const index = this.getSelectedImageIndex(this.selectedImage);

    if (index === -1) {
      this.selectedImages.push(this.selectedImage);
    }

    this.viewImages();
  }

  clearData() {
    this.visibleView = Views.ImageGrid;
    this.searchTerm = '';
    this.selectedMediaSubType = 0;
    this.selectedLocale = '';
    this.isMultiple = false;
    this.selectedImages = [];
    this.addImageUrl = '';
    this.mediaSubTypes = [];

    this.reset();
  }

  private selectSingleImage(image: ImageAsset) {
    const index = this.getSelectedImageIndex(image);

    this.selectedImages.splice(0, 1);

    if (index === -1) {
      this.selectedImages.push(image);
    }
  }

  private selectMultipleImage(image: ImageAsset) {
    const index = this.getSelectedImageIndex(image);

    if (index > -1) {
      this.selectedImages.splice(index, 1);
    } else {
      this.selectedImages.push(image);
    }
  }

  private getSelectedImageIndex(image: ImageAsset): number {
    const index = this.selectedImages.findIndex(
      element => {
        return (element.largeImageUrl === image.largeImageUrl && element.thumbnailUrl === image.thumbnailUrl && element.url === image.url);
      }
    );

    return index;
  }

  private reset() {
    this.assets = [];
    this.pageNumber = 1;
    this.totalRecordCount = 0;
    this.totalPageCount = 0;
    this.allRecordsLoaded = false;
  }
}
