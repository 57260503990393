import { GlobalCategoryPost } from '../../../core/models/global-category-post.model';
import { Action } from '@ngrx/store';
import { GlobalCategoryPut } from 'src/app/core/models/global-category-put.model';

export enum GlobalCategoryActionTypes {
  LoadGlobalCategory = '[GlobalCategory] Load Global Category',
  CreateGlobalCategory = '[GlobalCategory] Create Global Category',
  UpdateGlobalCategory = '[GlobalCategory] Update Global Category',
  RenameGlobalCategory = '[GlobalCategory] Rename Global Category',
  DeleteGlobalCategory = '[GlobalCategory] Delete Global Category',
  ClearMessages = '[GlobalCategory] Clear Messages',
}

export class LoadGlobalCategory implements Action {
  readonly type = GlobalCategoryActionTypes.LoadGlobalCategory;

  constructor(public payload: number) { }
}

export class CreateGlobalCategory implements Action {
  readonly type = GlobalCategoryActionTypes.CreateGlobalCategory;

  constructor(public payload: GlobalCategoryPost) { }
}

export class UpdateGlobalCategory implements Action {
  readonly type = GlobalCategoryActionTypes.UpdateGlobalCategory;

  constructor(public payload: { globalCategory: GlobalCategoryPut, globalCategoryName: string }) { }
}

export class RenameGlobalCategory implements Action {
  readonly type = GlobalCategoryActionTypes.RenameGlobalCategory;

  constructor(public payload: { globalCategoryId: number, globalCategoryName: string }) { }
}

export class DeleteGlobalCategory implements Action {
  readonly type = GlobalCategoryActionTypes.DeleteGlobalCategory;

  constructor(public payload: { globalCategoryId: number, globalCategoryName: string }) { }
}

export class ClearMessages implements Action {
  readonly type = GlobalCategoryActionTypes.ClearMessages;

  constructor() { }
}

export type GlobalCategoryActionsUnion =
  LoadGlobalCategory
  | CreateGlobalCategory
  | UpdateGlobalCategory
  | RenameGlobalCategory
  | DeleteGlobalCategory
  | ClearMessages;
