<div class="card-section">
  <div class="card-header">
    <h6 class="h6 section-title card-header-title">
      <strong>Materials</strong>
    </h6>
    <span class="card-header-spinner" *ngIf="isLoading">
      <mat-spinner diameter="20"></mat-spinner>
      <em class="loading">loading...</em>
    </span>
  </div>

  <mat-card class="app-card detail-card-readonly">
    <mat-card-content>

      <ng-container *ngIf="materials && materials.length > 0; else materialsElse">
        <ng-container *ngFor="let material of materials; index as materialIndex">
          <ng-container *ngFor="let color of material.colors; index as colorIndex">
            <div fxLayout="row" class="card-content card-content-image">

              <div fxFlex class="card-image-container">
                <img
                  class="card-image"
                  src="{{color.imageThumbUrl}}"
                  alt="{{color.name}}"
                  (click)="showLightbox(materialIndex, colorIndex)"
                  onerror="this.src='../../../../../../../assets/images/placeholders/image_placeholder.png'">
              </div>
              <div fxFlex fxLayout="column" class="align-center-vertical">
                <div fxFlex>{{material.name}}</div>
                <div fxFlex class="card-data-name">{{color.name}} ({{color.optionId}})</div>
              </div>

              <div fxFlex class="card-details align-center-vertical align-right">
                <button mat-button disableRipple (click)="showMaterialDetail(material, material.colors[colorIndex])">
                  View
                </button>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-template #materialsElse>
        <div fxLayout="row" class="card-content">
          <div fxFlex class="card-title materials-none">No materials found</div>
        </div>
      </ng-template>

    </mat-card-content>
  </mat-card>
</div>
