import { ImagesService } from './../../../core/services/images.service';
import { DigitalAssetManagerService } from 'src/app/core/services/digital-asset-manager.service';
import { DocumentAsset } from '../../../core/models/document-asset.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { AddProductLiteratureModalDataSource, Views } from './add-product-literature-modal.datasource';
@Component({
  selector: 'app-add-product-literature-modal',
  templateUrl: './add-product-literature-modal.component.html',
  styleUrls: ['./add-product-literature-modal.component.scss']
})
export class AddProductLiteratureModalComponent implements OnInit {
  result: AddProductLiteratureResult = new AddProductLiteratureResult(false, []);
  Views = Views;
  datasource: AddProductLiteratureModalDataSource = new AddProductLiteratureModalDataSource(this.digitalAssetManagerService);

  constructor(
    public dialogRef: MatDialogRef<AddProductLiteratureModalComponent>,
    private digitalAssetManagerService: DigitalAssetManagerService,
    private imagesService: ImagesService,
    @Inject(MAT_DIALOG_DATA) public data: {
      search: string,
      preSelectedDocumentUrls: Array<string>
    }
  ) { }
  ngOnInit() {
    this.datasource.searchTerm = this.data.search;
    this.datasource.visibleView = Views.Selection;

    if (this.data.preSelectedDocumentUrls) {
      this.datasource.preSelectedDocumentUrls = this.data.preSelectedDocumentUrls;
    }

    if (this.datasource.searchTerm && this.datasource.searchTerm.length > 0) {
      this.datasource.search(this.datasource.searchTerm);
    }
    this.result = new AddProductLiteratureResult(false, []);
  }

  ngOnDestroy() {
    this.datasource.disconnect();
  }

  confirmSelection() {
    this.result.isValid = true;
    this.result.documents = this.datasource.selection.selected;

    // Correct any titles that might not be filled in.
    this.result.documents.forEach(d => d.title = this.getCorrectedDocumentTitle(d));

    this.datasource.clearData();
    this.dialogRef.close(this.result);
  }

  confirmEntry() {
    this.result.isValid = true;
    this.result.documents.push(this.datasource.getCustomEntry());

    // Correct any titles that might not be filled in.
    this.result.documents.forEach(d => d.title = this.getCorrectedDocumentTitle(d));

    this.datasource.clearData();
    this.dialogRef.close(this.result);
  }

  clearSelection() {
    this.datasource.clearSelection();
  }

  private getCorrectedDocumentTitle(document: DocumentAsset): string {
    if (document.title) {
      return document.title;
    }

    if (document.url) {
      return this.imagesService.getName(document.url);
    }

    return 'None';
  }
}

export class AddProductLiteratureResult {
  constructor(public isValid: boolean, public documents: Array<DocumentAsset>) { }
}
