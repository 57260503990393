import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IAlbum } from 'ngx-lightbox';
import { AttributeOption } from 'src/app/core/models/attribute-option.model';
import { ImagesService } from 'src/app/core/services/images.service';
import { LightboxesService } from 'src/app/core/services/lightboxes.service';
import { Dimensions } from '../../constants';
import { AttributeOptionDetailReadonlyModalComponent } from '../attribute-option-detail-readonly-modal/attribute-option-detail-readonly-modal.component';

@Component({
  selector: 'app-attribute-option-readonly',
  templateUrl: './attribute-option-readonly.component.html',
  styleUrls: ['./attribute-option-readonly.component.scss']
})
export class AttributeOptionReadonlyComponent {
  @Input() attributeOption: AttributeOption;
  public get attributeImage() { return this.attributeOption?.imageUrl; }

  constructor(private lightboxesService: LightboxesService,
    private imagesService: ImagesService,
    private matDialog: MatDialog) { }

  showLightbox(index: number) {
    this.lightboxesService.open(this.getAlbum(), index);
  }

  getImageFileName(url: string): string {
    return this.imagesService.getName(url);
  }

  openAttributeOptionModal(): void {
    const dialogRef = this.matDialog.open(AttributeOptionDetailReadonlyModalComponent, {
      width: Dimensions.MODAL_STANDARD_X_WIDTH,
      restoreFocus: false,
      data: {
        attributeOption: this.attributeOption
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  private getAlbum(): IAlbum[] {
    const album: IAlbum[] = [];

    if (!this.attributeImage) {
      return album;
    }

    if (this.attributeImage?.length > 0) {
      album.push({
        src: this.attributeImage,
        caption: this.getImageFileName(this.attributeImage),
        thumb: this.attributeImage,
        downloadUrl: this.attributeImage
      });
    }
    return album;
  }

}
