import {
  PortfolioApiActions,
  PortfolioActions
} from '../actions';

import { Portfolio } from 'src/app/core/models/portfolio.model';
import { PortfolioStatus } from 'src/app/shared/constants';

export interface State {
  portfolio: Portfolio;
  loading: boolean;
  loaded: boolean;
  status: PortfolioStatus;
  error: string;
  success: string;
}

export const initialState: State = {
  portfolio: null,
  loading: false,
  loaded: false,
  status: PortfolioStatus.None,
  error: null,
  success: null
};

export function reducer(state = initialState, action: PortfolioActions.PortfolioActionsUnion | PortfolioApiActions.PortfolioApiActionsUnion): State {
  switch (action.type) {

    case PortfolioActions.PortfolioActionTypes.LoadPortfolio: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case PortfolioApiActions.PortfolioApiActionTypes.LoadSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        portfolio: action.payload
      };
    }

    case PortfolioApiActions.PortfolioApiActionTypes.LoadFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        portfolio: null,
        error: action.payload
      };
    }

    case PortfolioActions.PortfolioActionTypes.ClearMessages: {
      return {
        ...state,
        status: PortfolioStatus.None,
        error: null,
        success: null
      };
    }

    case PortfolioActions.PortfolioActionTypes.CreatePortfolio: {
      return {
        ...state,
      };
    }

    case PortfolioApiActions.PortfolioApiActionTypes.CreateSuccess: {
      return {
        ...state,
        portfolio: action.payload,
        status: PortfolioStatus.CreateSuccess,
      };
    }

    case PortfolioApiActions.PortfolioApiActionTypes.CreateFailure: {
      return {
        ...state,
        portfolio: null,
        status: PortfolioStatus.CreateFailure,
        error: 'Failed to Create Portfolio',
        success: null
      };
    }

    case PortfolioActions.PortfolioActionTypes.UpdatePortfolio: {
      return {
        ...state,
        loading: true,
        loaded: false,
        status: PortfolioStatus.None
      };
    }

    case PortfolioApiActions.PortfolioApiActionTypes.UpdateSuccess: {
      return {
        ...state,
        status: PortfolioStatus.UpdateSuccess,
        error: null,
        success: `Successfully Updated Portfolio: ${action.payload.portfolioName}`
      };
    }

    case PortfolioApiActions.PortfolioApiActionTypes.UpdateFailure: {
      return {
        ...state,
        loading: false,
        loaded: true,
        status: PortfolioStatus.UpdateFailure,
        error: 'Failed to Update Portfolio',
        success: null
      };
    }

    case PortfolioActions.PortfolioActionTypes.RenamePortfolio: {
      return {
        ...state
      };
    }

    case PortfolioApiActions.PortfolioApiActionTypes.RenameSuccess: {
      const portfolio = { ...state.portfolio };
      portfolio.name = action.payload.portfolioName;

      return {
        ...state,
        status: PortfolioStatus.RenameSuccess,
        error: null,
        success: `Successfully renamed Portfolio: ${action.payload.portfolioName}`
      };
    }

    case PortfolioApiActions.PortfolioApiActionTypes.RenameFailure: {
      return {
        ...state,
        portfolio: null,
        status: PortfolioStatus.RenameFailure,
        error: 'Failed to rename Portfolio',
        success: null
      };
    }

    case PortfolioActions.PortfolioActionTypes.DeletePortfolio: {
      return {
        ...state,
      };
    }

    case PortfolioApiActions.PortfolioApiActionTypes.DeleteSuccess: {
      return {
        ...state,
        status: PortfolioStatus.DeleteSuccess,
        error: null,
        success: `Successfully Deleted Portfolio: ${action.payload.portfolioName}`
      };
    }

    case PortfolioApiActions.PortfolioApiActionTypes.DeleteFailure: {
      return {
        ...state,
        portfolio: null,
        status: PortfolioStatus.DeleteFailure,
        error: 'Failed to Delete Portfolio',
        success: null
      };
    }

    default:
      return state;
   }
}

export const getPortfolio = (state: State) => state.portfolio;
export const getErrorMessage = (state: State) => state.error;
export const getSuccessMessage = (state: State) => state.success;
export const getStatus = (state: State) => state.status;
export const isLoading = (state: State) => state.loading;
export const isLoaded = (state: State) => state.loaded;
