import { ConfigurationsPageRequestBuilder } from './../../../core/services/configurations-page-request-builder.service';
import { IConfigurationsPageRequest, ConfigurationsPageRequest } from './../../../core/models/configurations-page-request.model';
import { PagedResults } from 'src/app/core/models/page-results.model';
import { tap, finalize, debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { GlobalConfigurationsService } from 'src/app/core/services/global-configurations.service';
import { SubSink } from 'subsink';
import { Router } from '@angular/router';
import { GlobalConfiguration } from 'src/app/core/models/global-configuration.model';
import { Component, Input, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-sidebar-product-configurations',
  templateUrl: './sidebar-product-configurations.component.html',
  styleUrls: ['./sidebar-product-configurations.component.scss']
})
export class SidebarProductConfigurationsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('input') input: ElementRef;

  @Input() globalProductId: number;
  @Input() isSelectionEnabled = false;
  @Input() selectedGlobalConfigurationId: number;

  public page: PagedResults<GlobalConfiguration> = new PagedResults();
  public globalConfigurationsLoading: boolean;
  public globalConfigurationsLoaded: boolean;
  public searchTerm: string;

  private subscriptions: SubSink = new SubSink();

  constructor(
    private router: Router,
    private globalConfigurationsService: GlobalConfigurationsService) { }

  ngOnInit(): void {
    this.search('');
  }

  ngAfterViewInit(): void {
    this.subscribeToSearchKeyPress();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public navigateToConfiguration(globalConfigurationId: number): void {
    this.router.navigate([`products/configuration-detail/${globalConfigurationId}`]);
  }

  private subscribeToSearchKeyPress(): void {
    const keypress$ = fromEvent(this.input.nativeElement, 'keyup').pipe(
      map(x => this.input.nativeElement.value),
      debounceTime(500),
      // Prevents keys like 'enter' and arrow keys from re-running the query.
      distinctUntilChanged((x, y) => x === y),
      tap(() => {
        this.search(this.input.nativeElement.value);
      })
    ).subscribe();

    this.subscriptions.add(keypress$);
  }

  private search(searchTerm: string): void {
    this.searchTerm = searchTerm;

    this.globalConfigurationsLoading = true;
    this.globalConfigurationsLoaded = false;

    // Build request
    const builder = new ConfigurationsPageRequestBuilder();
    const request = builder
      .setGlobalProductId(this.globalProductId)
      .setSearchTerm(this.searchTerm)
      .setPageNumber(1)
      .setPageSize(50)
      .setOrderBy('name')
      .setSortOrder('asc')
      .setIntegrationIds([])
      .build();

    const globalConfigurations$ = this.globalConfigurationsService.getGlobalConfigurations$(request)
    .pipe(
      tap((page: PagedResults<GlobalConfiguration>) => {
        if (page) {
          this.globalConfigurationsLoaded = true;
        }
      }),
      finalize(() => {
        this.globalConfigurationsLoading = false;
      })
    )
    .subscribe((page: PagedResults<GlobalConfiguration>) => {
      this.page = page;
    });

    this.subscriptions.add(globalConfigurations$);
  }

}
