import { EndpointsSummary } from './../../../core/models/endpoints-summary.model';
import { Endpoint } from './../../../core/models/endpoint.model';
import { Action } from '@ngrx/store';
import { EndpointSummary } from 'src/app/core/models/endpoint-summary.model';

export enum EndpointsApiActionTypes {
  LoadSuccess = '[Endpoints/API] Load Success',
  LoadFailure = '[Endpoints/API] Load Failure',
  CreateSuccess = '[Endpoints/API] Create Success',
  CreateFailure = '[Endpoints/API] Create Failure',
  RenameSuccess = '[Endpoints/API] Rename Success',
  RenameFailure = '[Endpoints/API] Rename Failure',
  DuplicateSuccess = '[Endpoints/API] Duplicate Success',
  DuplicateFailure = '[Endpoints/API] Duplicate Failure',
  DeleteSuccess = '[Endpoints/API] Delete Success',
  DeleteFailure = '[Endpoints/API] Delete Failure'
}

export class LoadSuccess implements Action {
  readonly type = EndpointsApiActionTypes.LoadSuccess;

  constructor(public payload: EndpointsSummary[]) { }
}

export class LoadFailure implements Action {
  readonly type = EndpointsApiActionTypes.LoadFailure;

  constructor(public payload: any) { }
}

export class CreateSuccess implements Action {
  readonly type = EndpointsApiActionTypes.CreateSuccess;

  constructor(public payload: Endpoint) { }
}

export class CreateFailure implements Action {
  readonly type = EndpointsApiActionTypes.CreateFailure;

  constructor(public payload: any) { }
}

export class RenameSuccess implements Action {
  readonly type = EndpointsApiActionTypes.RenameSuccess;

  constructor(public payload: { integrationId: number, endpointId: number, endpointName: string }) { }
}

export class RenameFailure implements Action {
  readonly type = EndpointsApiActionTypes.RenameFailure;

  constructor(public payload: any) { }
}

export class DuplicateSuccess implements Action {
  readonly type = EndpointsApiActionTypes.DuplicateSuccess;

  constructor(public payload: EndpointSummary) { }
}

export class DuplicateFailure implements Action {
  readonly type = EndpointsApiActionTypes.DuplicateFailure;

  constructor(public payload: any) { }
}

export class DeleteSuccess implements Action {
  readonly type = EndpointsApiActionTypes.DeleteSuccess;

  constructor(public payload: { endpointId: number, endpointName: string }) { }
}

export class DeleteFailure implements Action {
  readonly type = EndpointsApiActionTypes.DeleteFailure;

  constructor(public payload: any) { }
}

export type EndpointsApiActionsUnion =
    LoadSuccess
  | LoadFailure
  | CreateSuccess
  | CreateFailure
  | RenameSuccess
  | RenameFailure
  | DuplicateSuccess
  | DuplicateFailure
  | DeleteSuccess
  | DeleteFailure;
