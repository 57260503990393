import { EndpointProductOfferingTag } from './../../../core/models/endpoint-product-offering-tag.model';
import { SubcategoryRemap } from 'src/app/core/models/subcategory-remap.model';
import { CategoryRemap } from 'src/app/core/models/category-remap.model';
import { IEndpoint } from 'src/app/core/models/endpoint.model';
import {
  EndpointApiActions,
  EndpointActions
} from '../actions';
import { ICategory } from 'src/app/core/models/category.model';
import { EndpointStatus } from 'src/app/shared/constants';


export interface State {
  endpoint: IEndpoint;
  categories: ICategory[];
  categoryRemaps: CategoryRemap[];
  subcategoryRemaps: SubcategoryRemap[];
  tags: EndpointProductOfferingTag[];
  loading: boolean;
  loaded: boolean;
  loadingCategories: boolean;
  loadedCategories: boolean;
  loadingRemaps: boolean;
  loadedRemaps: boolean;
  loadingTags: boolean;
  loadedTags: boolean;
  error: string;
  status: EndpointStatus;
  success: string;
}

export const initialState: State = {
  endpoint: null,
  categories: [],
  categoryRemaps: [],
  subcategoryRemaps: [],
  tags: [],
  loading: false,
  loaded: false,
  loadingCategories: false,
  loadedCategories: false,
  loadingRemaps: false,
  loadedRemaps: false,
  loadingTags: false,
  loadedTags: false,
  error: '',
  status: EndpointStatus.None,
  success: null
};

export function reducer(
  state = initialState,
  action: EndpointActions.EndpointActionsUnion | EndpointApiActions.EndpointApiActionsUnion): State {

  switch (action.type) {

    case EndpointActions.EndpointActionTypes.LoadEndpoint: {
      return {
        ...state,
        endpoint: null,
        loading: true,
        loaded: false,
      };
    }
    case EndpointApiActions.EndpointApiActionTypes.LoadSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        endpoint: action.payload
      };
    }
    case EndpointApiActions.EndpointApiActionTypes.LoadFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        endpoint: null,
        error: action.payload
      };
    }
    case EndpointActions.EndpointActionTypes.LoadEndpointRemaps: {
      return {
        ...state,
        categoryRemaps: [],
        subcategoryRemaps: [],
        loadingRemaps: true,
        loadedRemaps: false
      };
    }
    case EndpointApiActions.EndpointApiActionTypes.LoadEndpointRemapsSuccess: {
      return {
        ...state,
        loadingRemaps: false,
        loadedRemaps: true,
        categoryRemaps: action.payload.categoryRemaps,
        subcategoryRemaps: action.payload.subcategoryRemaps
      };
    }
    case EndpointApiActions.EndpointApiActionTypes.LoadEndpointRemapsFailure: {
      return {
        ...state,
        loadingRemaps: false,
        loadedRemaps: false,
        categoryRemaps: [],
        subcategoryRemaps: [],
        error: action.payload
      };
    }
    case EndpointActions.EndpointActionTypes.LoadCategories: {
      return {
        ...state,
        categories: [],
        loadingCategories: true,
        loadedCategories: false
      };
    }
    case EndpointApiActions.EndpointApiActionTypes.LoadCategoriesSuccess: {
      return {
        ...state,
        loadingCategories: false,
        loadedCategories: true,
        categories: action.payload
      };
    }
    case EndpointApiActions.EndpointApiActionTypes.LoadFailure: {
      return {
        ...state,
        loadingCategories: false,
        loadedCategories: false,
        categories: [],
        error: action.payload
      };
    }
    case EndpointActions.EndpointActionTypes.UpdateRemaps: {
      return {
        ...state,
        loadingCategories: true,
        loadedCategories: false,
      };
    }
    case EndpointApiActions.EndpointApiActionTypes.UpdateRemapsSuccess: {
      return {
        ...state,
        loadingCategories: false,
        loadedCategories: true,
      };
    }
    case EndpointApiActions.EndpointApiActionTypes.UpdateRemapsFailure: {
      return {
        ...state,
        loadingCategories: false,
        loadedCategories: false,
      };
    }
    case EndpointActions.EndpointActionTypes.LoadOfferingTags: {
      return {
        ...state,
        tags: [],
        loadingTags: true,
        loadedTags: false,
      };
    }
    case EndpointApiActions.EndpointApiActionTypes.LoadOfferingTagsSuccess: {
      return {
        ...state,
        tags: action.payload,
        loadingTags: false,
        loadedTags: true,
      };
    }
    case EndpointApiActions.EndpointApiActionTypes.LoadOfferingTagsFailure: {
      return {
        ...state,
        tags: [],
        loadingTags: false,
        loadedTags: false,
        error: action.payload
      };
    }
    case EndpointActions.EndpointActionTypes.CreateOfferingTag: {
      return {
        ...state,
      };
    }
    case EndpointApiActions.EndpointApiActionTypes.CreateOfferingTagSuccess: {
      return {
        ...state,
        success: `New tag ${action.payload.tag} created.`,
        status: EndpointStatus.CreateEndpointProductOfferingTagSuccess
      };
    }
    case EndpointApiActions.EndpointApiActionTypes.CreateOfferingTagFailure: {
      return {
        ...state,
        error: action.payload,
        status: EndpointStatus.CreateEndpointProductOfferingTagFailure
      };
    }
    case EndpointActions.EndpointActionTypes.UpdateOfferingTag: {
      return {
        ...state
      };
    }
    case EndpointApiActions.EndpointApiActionTypes.UpdateOfferingTagSuccess: {
      return {
        ...state,
        success: `Tag update successfully.`,
        status: EndpointStatus.UpdateEndpointProductOfferingTagSuccess
      };
    }
    case EndpointApiActions.EndpointApiActionTypes.UpdateOfferingTagFailure: {
      return {
        ...state,
        error: action.payload,
        status: EndpointStatus.UpdateEndpointProductOfferingTagFailure
      };
    }
    case EndpointActions.EndpointActionTypes.DeleteOfferingTag: {
      return {
        ...state,
      };
    }
    case EndpointApiActions.EndpointApiActionTypes.DeleteOfferingTagSuccess: {
      return {
        ...state,
        success: `Tag deleted successfully.`,
        status: EndpointStatus.DeleteEndpointProductOfferingTagSuccess
      };
    }
    case EndpointApiActions.EndpointApiActionTypes.DeleteOfferingTagFailure: {
      return {
        ...state,
        error: action.payload,
        status: EndpointStatus.DeleteEndpointProductOfferingTagFailure
      };
    }
    case EndpointActions.EndpointActionTypes.ClearMessages: {
      return {
        ...state,
        success: null,
        error: null,
        status: EndpointStatus.None
      };
    }
    default:
      return state;
  }
}

export const getEndpoint = (state: State) => state.endpoint;
export const getCategories = (state: State) => state.categories;
export const isLoading = (state: State) => state.loading;
export const isLoaded = (state: State) => state.loaded;
export const isEndpointRemapsLoading = (state: State) => state.loadingRemaps;
export const isEndpointRemapsLoaded = (state: State) => state.loadedRemaps;
export const getCategoryRemaps = (state: State) => state.categoryRemaps;
export const getSubcategoryRemaps = (state: State) => state.subcategoryRemaps;
export const isCategoriesLoading = (state: State) => state.loadingCategories;
export const isCategoriesLoaded = (state: State) => state.loadedCategories;
export const getTags = (state: State) => state.tags;
export const isTagsLoading = (state: State) => state.loadingTags;
export const isTagsLoaded = (state: State) => state.loadedTags;

export const getErrorMessage = (state: State) => state.error;
export const getSuccessMessage = (state: State) => state.success;
export const getStatus = (state: State) => state.status;
