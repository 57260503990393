<mat-card class="cad-links-search-result-card">
    <mat-card-content class="card-content">
        <div fxLayout="column">
            <div fxLayout="row" class="title-row">
                <div fxFlex="70" class="align-center-vertical align-left">
                    <div fxLayout="column">
                        <div class="data-label">
                            base product number:
                        </div>
                        <div class="data-value">
                            {{ cadLinks.baseProductNumber }}
                        </div>
                    </div>
                </div>
                <div fxFlex class="card-image-container align-center-vertical align-right">
                    <img class="card-image" [src]="cadLinkTypeImageSource" [alt]="cadLinkTypeImageAltText" [matTooltip]="cadLinkTypeFlavorText">
                </div>
            </div>
            <div fxLayout="row" class="align-center-vertical align-left data-row">
                <div fxLayout="column">
                    <div class="data-label">
                        family name:
                    </div>
                    <div>
                        <span class="data-value">{{ cadLinks.familyName }}</span>
                    </div>
                </div>
            </div>
            <div fxLayout="row" class="align-center-vertical align-left data-row">
                <div fxLayout="column">
                    <div class="data-label">
                        type name:
                    </div>
                    <div>
                        <span class="data-value">{{ cadLinks.typeName }}</span>
                    </div>
                </div>
            </div>
            <div fxLayout="row" class="align-center-vertical align-left data-row">
                <div fxLayout="column">
                    <div class="data-label">
                        legacy name:
                    </div>
                    <div>
                        <span class="data-value">{{ cadLinks.legacyName }}</span>
                    </div>
                </div>
            </div>
            <div fxLayout="row" class="align-center-vertical align-left data-row">
                <div fxLayout="column">
                    <div class="data-label">
                        model file download link:
                    </div>
                    <div>
                        <span class="data-value">{{ cadLinkUrl }}</span>
                    </div>
                </div>
            </div>
            <div fxLaout="row" class="align-center-vertical result-actions">
                <div fxFlex class="align-center-vertical">
                    <a [href]="cadLinkUrl" target="_blank" mat-icon-button disableRipple matTooltip="Download the file(s)">
                        <mat-icon class="subdue-icon">cloud_download</mat-icon>
                    </a>
                </div>
                <div class="align-center-vertical align-right">
                    <button mat-raised-button disableRipple [mat-dialog-close]="cadLinkUrl">Select</button>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>