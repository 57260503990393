import { Action } from '@ngrx/store';
import { DamAssetMetapropertyQuery } from 'src/app/core/models/dam-asset-metaproperty-query.model';

export enum DamAssetsMetapropertyActionTypes {
  LoadDamAssetsMetaproperty = '[DamAssetsMetaproperty] Load DamAssetsMetaproperty',  
  LoadDamAssetsMetapropertyOptions = '[DamAssetsMetapropertyOptions] Load DamAssetsMetapropertyOptions',
  LoadDamAssetsMetapropertyOmniOptions = '[DamAssetsMetapropertyOmniOptions] Load DamAssetsMetapropertyOmniOptions',  
  LoadMediaSubTypes = '[MediaSubTypes] Load MediaSubTypes'
}

export class LoadDamAssetsMetaproperty implements Action {
  readonly type = DamAssetsMetapropertyActionTypes.LoadDamAssetsMetaproperty;

  constructor(public payload: string) { }
}


export class LoadDamAssetsMetapropertyOptions implements Action {
  readonly type = DamAssetsMetapropertyActionTypes.LoadDamAssetsMetapropertyOptions;

  constructor(public payload: DamAssetMetapropertyQuery) { }
}
export class LoadDamAssetsMetapropertyOmniOptions implements Action {
  readonly type = DamAssetsMetapropertyActionTypes.LoadDamAssetsMetapropertyOmniOptions;

  constructor(public payload: DamAssetMetapropertyQuery) { }
}


export class LoadMediaSubTypes implements Action {
  readonly type = DamAssetsMetapropertyActionTypes.LoadMediaSubTypes;

  constructor() { }
}

export type DamAssetsMetapropertyActionsUnion =
  LoadDamAssetsMetaproperty
  | LoadDamAssetsMetapropertyOptions
  | LoadDamAssetsMetapropertyOmniOptions
  | LoadMediaSubTypes
  ;
