import { Action } from '@ngrx/store';
import { WarrantyPost } from 'src/app/core/models/warranty-post.model';
import { WarrantyPut } from 'src/app/core/models/warranty-put.model';


export enum WarrantyActionTypes {
  LoadWarranty = '[Warranty] Load Warranty',
  CreateWarranty = '[Warranty] Create Warranty',
  UpdateWarranty = '[Warranty] Update Warranty',
  RenameWarranty = '[Warranty] Rename Warranty',
  DeleteWarranty = '[Warranty] Delete Warranty',
  ClearMessages = '[Warranty] Clear Messages',
}

export class LoadWarranty implements Action {
  readonly type = WarrantyActionTypes.LoadWarranty;

  constructor(public payload: number) { }
}

export class CreateWarranty implements Action {
  readonly type = WarrantyActionTypes.CreateWarranty;

  constructor(public payload: WarrantyPost) { }
}

export class UpdateWarranty implements Action {
  readonly type = WarrantyActionTypes.UpdateWarranty;

  constructor(public payload: { warranty: WarrantyPut, warrantyName: string }) { }
}

export class RenameWarranty implements Action {
  readonly type = WarrantyActionTypes.RenameWarranty;

  constructor(public payload: { warrantyId: number, warrantyName: string }) { }
}

export class DeleteWarranty implements Action {
  readonly type = WarrantyActionTypes.DeleteWarranty;

  constructor(public payload: { warrantyId: number, warrantyName: string }) { }
}

export class ClearMessages implements Action {
  readonly type = WarrantyActionTypes.ClearMessages;

  constructor() { }
}

export type WarrantyActionsUnion =
  LoadWarranty
  | CreateWarranty
  | UpdateWarranty
  | RenameWarranty
  | DeleteWarranty
  | ClearMessages;
