import { Action, Store } from '@ngrx/store';
import { IntegrationProductOfferingSummary } from './../../../core/models/integration-product-offering-summary.model';
import { map, catchError, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { ProductOfferingActions, ProductOfferingApiActions } from '../actions';
import { GlobalProductOfferingsService } from 'src/app/core/services/global-product-offerings.service';
import { of, Observable } from 'rxjs';
import * as fromStore from '../../../app-store/reducers';

@Injectable()
export class ProductOfferingEffects {
  @Effect()
  loadProductOffering$ = this.actions$.pipe(
    ofType(ProductOfferingActions.ProductOfferingActionTypes.LoadProductOffering),
    map((action: ProductOfferingActions.LoadProductOffering) => action.payload),
    switchMap(productOfferingId =>
      this.globalProductOfferingsService.getGlobalProductOffering$(productOfferingId).pipe(
        map(
          (project) => new ProductOfferingApiActions.LoadSuccess(project)
        ),
        catchError(error =>
          of(new ProductOfferingApiActions.LoadFailure(error))
        )
      )
    )
  );

  @Effect()
  loadProductOfferingsForProduct$ = this.actions$.pipe(
    ofType(ProductOfferingActions.ProductOfferingActionTypes.LoadProductOfferingByProductId),
    map((action: ProductOfferingActions.LoadProductOfferingByProductId) => action.payload),
    switchMap(productId =>
      this.globalProductOfferingsService.getGlobalProductOfferings$(productId).pipe(
        map((project: IntegrationProductOfferingSummary[]) =>
          new ProductOfferingApiActions.LoadForProductOfferingSuccess(project)
        ),
        catchError(error =>
          of(new ProductOfferingApiActions.LoadForProductOfferingFailure(error))
        )
      )
    )
  );

  @Effect()
  createProductOffering$: Observable<Action> = this.actions$.pipe(
    ofType<ProductOfferingActions.CreateProductOffering>(ProductOfferingActions.ProductOfferingActionTypes.CreateProductOffering),
    switchMap((request) =>
      this.globalProductOfferingsService.createProductOffering$(request.payload).pipe(
        map((createdProductOFfering) =>
          new ProductOfferingApiActions.CreateSuccess(createdProductOFfering)
        ),
        tap((response) => {
          // Navigate to the page in edit mode.
          this.router.navigate([`products/product-detail-offering/${response.payload.id}`], { queryParams: { edit: true } });
        }),
        catchError(error =>
          of(new ProductOfferingApiActions.CreateFailure(error))
        )
      )
    )
  );

  @Effect()
  UpdateProductOffering$: Observable<Action> = this.actions$.pipe(
    ofType<ProductOfferingActions.UpdateProductOffering>(ProductOfferingActions.ProductOfferingActionTypes.UpdateProductOffering),
    switchMap((request) =>
      this.globalProductOfferingsService.UpdateProductOffering$(request.payload).pipe(
        map((response) =>
          new ProductOfferingApiActions.UpdateSuccess(request.payload)
        ),
        catchError(error =>
          of(new ProductOfferingApiActions.UpdateFailure(error))
        ),
        tap((response) => {
          // Reload product offering from API if update is successful/failed
          this.store.dispatch(new ProductOfferingActions.LoadProductOffering(request.payload.id));

        })
      )
    )
  );
  // Used to update the sidebar after a product offering is created.  Will be necessary for the product offering detail page.
  @Effect()
  reloadProductOfferingsAfterCreateSuccess$ = this.actions$.pipe(
    ofType(ProductOfferingApiActions.ProductOfferingApiActionTypes.CreateSuccess),
    map((action: ProductOfferingApiActions.CreateSuccess) => action.payload),
    switchMap(createdOffering =>
      this.globalProductOfferingsService.getGlobalProductOfferings$(createdOffering.globalProductId).pipe(
        map((project: IntegrationProductOfferingSummary[]) =>
          new ProductOfferingApiActions.LoadForProductOfferingSuccess(project)
        ),
        catchError(error =>
          of(new ProductOfferingApiActions.LoadForProductOfferingFailure(error))
        )
      )
    )
  );

  @Effect()
  deleteProductOffering$: Observable<Action> = this.actions$.pipe(
    ofType(ProductOfferingActions.ProductOfferingActionTypes.DeleteProductOffering),
    map((action: ProductOfferingActions.DeleteProductOffering) => action.payload.productOffering),
    switchMap(productOffering =>
      this.globalProductOfferingsService.deleteProductOffering$(productOffering).pipe(
        map((response) => {
          return new ProductOfferingApiActions.DeleteSuccess(productOffering);
        }),
        catchError((error) => {
          return of(new ProductOfferingApiActions.DeleteFailure(productOffering, error));
        }),
        tap((response) => {
          // Navigate to product detail page on offering delete
          this.router.navigate([`products/product-detail/${productOffering.globalProduct.id}`]);
        }),
      ))
  );

  @Effect()
  renameProductOffering$: Observable<Action> = this.actions$.pipe(
    ofType(ProductOfferingActions.ProductOfferingActionTypes.RenameProductOffering),
    map((action: ProductOfferingActions.RenameProductOffering) => action.payload),
    switchMap(request =>
      this.globalProductOfferingsService.renameProductOffering$(request).pipe(
        map((response) => {
          return new ProductOfferingApiActions.RenameSuccess(request);
        }
        ),
        tap(res => this.store.dispatch(new ProductOfferingActions.LoadProductOfferingByProductId(request.productId))),
        catchError((error) => {
          return of(new ProductOfferingApiActions.RenameFailure(error));
        }),
        tap((response) => {
          // Reload product offering from API if rename is successful/failed
          this.store.dispatch(new ProductOfferingActions.LoadProductOffering(request.offeringId));
        })
      ))
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private globalProductOfferingsService: GlobalProductOfferingsService,
    private store: Store<fromStore.State>) { }
}
