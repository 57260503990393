import { Action } from '@ngrx/store';
import { IntegrationSummary } from 'src/app/core/models/integration-summary.model';

export enum IntegrationsApiActionTypes {
  LoadSuccess = '[Integrations/API] Load Success',
  LoadFailure = '[Integrations/API] Load Failure'
}

export class LoadSuccess implements Action {
  readonly type = IntegrationsApiActionTypes.LoadSuccess;

  constructor(public payload: IntegrationSummary[]) { }
}

export class LoadFailure implements Action {
  readonly type = IntegrationsApiActionTypes.LoadFailure;

  constructor(public payload: any) { }
}

export type IntegrationsApiActionsUnion = LoadSuccess | LoadFailure;
