import { Action } from '@ngrx/store';
import { SubBrand } from 'src/app/core/models/sub-brand.model';
import { SubBrandPut } from 'src/app/core/models/sub-brand-put.model';

export enum SubBrandApiActionTypes {
  LoadSuccess = '[SubBrand/API] Load Success',
  LoadFailure = '[SubBrand/API] Load Failure',
  CreateSuccess = '[SubBrand/API] Create Success',
  CreateFailure = '[SubBrand/API] Create Failure',
  UpdateSuccess = '[SubBrand/API] Update Success',
  UpdateFailure = '[SubBrand/API] Update Failure',
  RenameSuccess = '[SubBrand/API] Rename Success',
  RenameFailure = '[SubBrand/API] Rename Failure',
  DeleteSuccess = '[SubBrand/API] Delete Success',
  DeleteFailure = '[SubBrand/API] Delete Failure'
}

export class LoadSuccess implements Action {
  readonly type = SubBrandApiActionTypes.LoadSuccess;

  constructor(public payload: SubBrand) { }
}

export class LoadFailure implements Action {
  readonly type = SubBrandApiActionTypes.LoadFailure;

  constructor(public payload: any) { }
}

export class CreateSuccess implements Action {
  readonly type = SubBrandApiActionTypes.CreateSuccess;

  constructor(public payload: SubBrand) { }
}

export class CreateFailure implements Action {
  readonly type = SubBrandApiActionTypes.CreateFailure;

  constructor(public payload: any) { }
}

export class UpdateSuccess implements Action {
  readonly type = SubBrandApiActionTypes.UpdateSuccess;

  constructor(public payload: { subBrand: SubBrandPut, subBrandName: string }) { }
}

export class UpdateFailure implements Action {
  readonly type = SubBrandApiActionTypes.UpdateFailure;

  constructor(public payload: any) { }
}

export class RenameSuccess implements Action {
  readonly type = SubBrandApiActionTypes.RenameSuccess;

  constructor(public payload: { subBrandId: number, subBrandName: string }) { }
}

export class RenameFailure implements Action {
  readonly type = SubBrandApiActionTypes.RenameFailure;

  constructor(public payload: any) { }
}

export class DeleteSuccess implements Action {
  readonly type = SubBrandApiActionTypes.DeleteSuccess;

  constructor(public payload: { subBrandId: number, subBrandName: string }) { }
}

export class DeleteFailure implements Action {
  readonly type = SubBrandApiActionTypes.DeleteFailure;

  constructor(public payload: any) { }
}

export type SubBrandApiActionsUnion =
  LoadSuccess
  | LoadFailure
  | CreateSuccess
  | CreateFailure
  | UpdateSuccess
  | UpdateFailure
  | RenameSuccess
  | RenameFailure
  | DeleteSuccess
  | DeleteFailure;
