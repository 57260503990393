<form [formGroup]="datasource.addImageUrlFormGroup" autocomplete="off" novalidate>
  <div class="image-url-container scrollbar">
    <div class="search-container  left-arrange">
      <mat-form-field class="wrapper-none" appearance="outline">
        <input autocomplete="off"
          matInput
          id="imageUrl"
          formControlName="imageUrl"
          placeholder="Image URL">
      </mat-form-field>

      <mat-form-field class="wrapper-none gapfill" appearance="outline" *ngIf="productImages">
        <mat-label>Media Subtype</mat-label>
        <mat-select placeholder="Media Types" formControlName="mediaSubType">
          <mat-option [value]="0">
            <em>&lt;None&gt;</em>
          </mat-option>

          <div *ngIf="mediaSubTypes">
            <ng-container *ngFor="let mediaSubtype of mediaSubTypes">
              <mat-option [value]="mediaSubtype.bynderName">
                {{mediaSubtype.bynderName}}
              </mat-option>
            </ng-container>
          </div>

        </mat-select>
      </mat-form-field>

      <mat-form-field class="wrapper-none gapfill" appearance="outline" *ngIf="productImages">
        <mat-label>Alt Text</mat-label>
        <input autocomplete="off"
          matInput
          id="altText"
          formControlName="altText"
          placeholder="Alt Text">
      </mat-form-field>

      <mat-form-field class="wrapper-none gapfill" appearance="outline" *ngIf="productImages">
        <mat-label>Title</mat-label>
        <input autocomplete="off"
          matInput
          id="title"
          formControlName="title"
          placeholder="Title">
      </mat-form-field>
    </div>

    <div class="image-container align-center-horizontal right-arrange" *ngIf="productImages">
      <img
        src="{{ datasource.addImageUrl }}"
        layout-fill
        class="selected-image image-center"
        onerror="this.src='../../../../../assets/images/placeholders/product_placeholder.svg'">
    </div>
    <div class="image-container align-center-horizontal right-arrange-config" *ngIf="!productImages">
      <img
        src="{{ datasource.addImageUrl }}"
        layout-fill
        class="selected-image image-center"
        onerror="this.src='../../../../../assets/images/placeholders/product_placeholder.svg'">
    </div>
  </div>
</form>
