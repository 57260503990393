<mat-form-field class="locales-select" appearance="fill">
  <mat-select
    [(value)]="selectedLocale"
    placeholder="None"
    (selectionChange)="onSelectionChange($event)"
    [compareWith]="compareFn">
    <mat-select-trigger class="trigger">
      <span *ngIf="selectedLocale; else noLocaleSelected">
        <img class="flag" src="../../../../../assets/images/flags/{{getLocaleFlagImage(selectedLocale.name)}}.png">
        {{selectedLocale.name}}
      </span>
      <ng-template #noLocaleSelected>
        None
      </ng-template>
    </mat-select-trigger>
    <mat-optgroup
      *ngFor="let region of regions"
      [label]="region.name" >
      <mat-option
        *ngFor="let locale of region.locales"
        [value]="locale">
        <img class="flag" src="../../../../../assets/images/flags/{{getLocaleFlagImage(locale.name)}}.png">
        {{ locale.name }}
      </mat-option>
    </mat-optgroup>
  </mat-select>
</mat-form-field>
