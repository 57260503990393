import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { PagedResults } from '../models/page-results.model';
import { CategorySummary } from '../models/category-summary.model';
import { Category, ICategory } from '../models/category.model';
import { CategoryPost } from '../models/category-post.model';
import { CategoryPut } from '../models/category-put.model';
import { RenamePut } from '../models/rename-put.model';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  basePath = 'categories';
  constructor(private http: HttpClient) {
  }

  getCategories$(searchTerm: string = '', pageNumber: number, pageSize: number, sortActive: string, sortOrder: string): Observable<PagedResults<CategorySummary>> {
    return this.http.get(`${environment.apiUrl}${this.basePath}`, {
      params: new HttpParams()
        .set('search', searchTerm)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
        .set('orderBy', sortActive.toString())
        .set('sortOrder', sortOrder.toString())
    }).pipe(
      map((response: PagedResults<CategorySummary>) => response)
    );
  }

  getCategory$(id: number): Observable<Category> {
    console.log(`getCategory$(${id})`);
    return this.http
      .get(environment.apiUrl + `${this.basePath}/${id}`)
      .pipe(map(response => response)
        , map((x: ICategory) => new Category(x)));
  }

  createCategory$(category: CategoryPost): Observable<Category> {
    console.log(`createCategory(${category})`);

    return this.http
      .post(environment.apiUrl + `${this.basePath}`, category)
      .pipe(map(res => res)
        , catchError((err: Response) => {
          const obj = err;
          return observableThrowError(obj);
        })
        , map((x: ICategory) => new Category(x)));
  }

  updateCategory$(category: CategoryPut): Observable<any> {
    console.log(`updateCategory(${category})`);

    return this.http
      .put(`${environment.apiUrl}${this.basePath}/${category.id}`, category)
      .pipe(map(response => response),
        catchError((err: Response) => {
          console.log('Error on category update:');
          console.log(err);
          return observableThrowError(err);
        })
      );
  }

  renameCategory$(request: { categoryId: number, categoryName: string }): Observable<any> {
    const renamePut: RenamePut = new RenamePut({ name: request.categoryName });
    return this.http
      .put(environment.apiUrl + `${this.basePath}/${request.categoryId}/rename`,
        renamePut,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
      );
  }

  deleteCategory$(categoryId: number): Observable<any> {
    return this.http.delete(environment.apiUrl + `${this.basePath}/${categoryId}`)
      .pipe(
        map(res => res),
        catchError((err: Response) => {
          const obj = err;
          return observableThrowError(obj);
        })
      );
  }

  getDuplicates$(categoryName: string): Observable<Array<CategorySummary>> {
    const request: CategoryPost = new CategoryPost();
    request.name = categoryName;

    return this.http
      .post(`${environment.apiUrl}${this.basePath}/duplicates`, request)
      .pipe(
        map(response => response),
        catchError((error: Response) => {
          return observableThrowError(error);
        }),
        map((response: Array<CategorySummary>) => response)
      );
  }
}
