import { Action } from '@ngrx/store';
import { SubBrandPost } from 'src/app/core/models/sub-brand-post.model';
import { SubBrandPut } from 'src/app/core/models/sub-brand-put.model';

export enum SubBrandActionTypes {
  LoadSubBrand = '[SubBrand] Load SubBrand',
  CreateSubBrand = '[SubBrand] Create SubBrand',
  UpdateSubBrand = '[SubBrand] Update SubBrand',
  RenameSubBrand = '[SubBrand] Rename SubBrand',
  DeleteSubBrand = '[SubBrand] Delete SubBrand',
  ClearMessages = '[SubBrand] Clear Messages',
}

export class LoadSubBrand implements Action {
  readonly type = SubBrandActionTypes.LoadSubBrand;

  constructor(public payload: number) { }
}

export class CreateSubBrand implements Action {
  readonly type = SubBrandActionTypes.CreateSubBrand;

  constructor(public payload: SubBrandPost) { }
}

export class UpdateSubBrand implements Action {
  readonly type = SubBrandActionTypes.UpdateSubBrand;

  constructor(public payload: { subBrand: SubBrandPut, subBrandName: string }) { }
}

export class RenameSubBrand implements Action {
  readonly type = SubBrandActionTypes.RenameSubBrand;

  constructor(public payload: { subBrandId: number, subBrandName: string }) { }
}

export class DeleteSubBrand implements Action {
  readonly type = SubBrandActionTypes.DeleteSubBrand;

  constructor(public payload: { subBrandId: number, subBrandName: string }) { }
}

export class ClearMessages implements Action {
  readonly type = SubBrandActionTypes.ClearMessages;

  constructor() { }
}

export type SubBrandActionsUnion =
  LoadSubBrand
  | CreateSubBrand
  | UpdateSubBrand
  | RenameSubBrand
  | DeleteSubBrand
  | ClearMessages;
