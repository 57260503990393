import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-content-string-multiline-readonly',
  templateUrl: './card-content-string-multiline-readonly.component.html',
  styleUrls: ['./card-content-string-multiline-readonly.component.scss']
})
export class CardContentStringMultilineReadonlyComponent implements OnInit {
  @Input() value: string;
  // If true, the show more/show less link shows, and the value is truncated by 'truncateAt'
  @Input() truncate: boolean = false;
  @Input() truncateAt: number = 100;

  get displayText(): string { return this.getValue(); }
  get toggleText(): string { return this.getToggleButtonText(); }

  private readonly SHOW_MORE = 'Show More';
  private readonly SHOW_LESS = 'Show Less';
  showFullValue: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  getValue(): string {
    if (this.showFullValue || !this.truncate) {
      return this.value;
    }

    if (this.value.length > this.truncateAt) {
      return this.value.slice(0, this.truncateAt) + '...';
    }

    return this.value;
  }

  getToggleButtonText(): string {
    if (this.showFullValue) {
      return this.SHOW_LESS;
    }

    return this.SHOW_MORE;
  }

  toggle(): void {
    this.showFullValue = !this.showFullValue;
  }
}
