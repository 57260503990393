import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import { IUserRole, UserRole } from './user-role.model';
import * as _ from 'lodash';

export interface IUser extends IBaseAuditModel {
  email: string;
  firstName: string;
  lastName: string;
  isDeleted: boolean;
  lastLoginDate?: Date;
  isAuthorized: boolean;
  userRoles: Array<IUserRole>;
}

export class User extends BaseAuditModel implements IUser {
  email: string;
  firstName: string;
  lastName: string;
  isDeleted: boolean;
  lastLoginDate?: Date;
  isAuthorized: boolean;
  userRoles: Array<UserRole> = [];

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`.trim();
  }

  constructor(data?: IUser) {
    super(data);
    if (!data) {
      return;
    }
    _.assign(this, data);
  }
}
