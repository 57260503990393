import { IBaseAuditModel, BaseAuditModel } from './base-audit.model';
import * as _ from 'lodash';
import { AttributeOptionType, IAttributeOptionType } from './attribute-option-type.model';
import { AttributeOptionValue, IAttributeOptionValue } from './attribute-option-value.model';
import { AttributeOptionLite, IAttributeOptionLite } from './attribute-option-lite.model';
import { AttributeOptionOfferingExclusion } from './attribute-option-offering-exclusion.model';

export interface IAttributeOption extends IBaseAuditModel {
  name: string;
  shortDescription: string;
  longDescription: string;
  imageUrl: string;
  isInDraft: boolean;
  attributeOptionTypeId?: number;
  attributeOptionType?: IAttributeOptionType;
  parentAttributeOptionId?: number;
  parentAttributeOptions?: Array<IAttributeOptionLite>;
  attributeOptionValues?: Array<IAttributeOptionValue>;
  iconUpload: File;
  iconName: string;
  offeringExclusions?: Array<AttributeOptionOfferingExclusion>;
  globalSubcategoryAttributeTypeId?: number;
}
export class AttributeOption extends BaseAuditModel implements IAttributeOption {
  name: string;
  shortDescription: string;
  longDescription: string;
  imageUrl: string;
  isInDraft: boolean;
  attributeOptionTypeId?: number;
  attributeOptionType?: AttributeOptionType;
  parentAttributeOptionId?: number;
  parentAttributeOptions?: Array<AttributeOptionLite> = [];
  attributeOptionValues?: Array<AttributeOptionValue> = [];
  iconUpload: File;
  iconName: string;  
  offeringExclusions?: Array<AttributeOptionOfferingExclusion> = [];
  globalSubcategoryAttributeTypeId?: number;

  constructor(data: IAttributeOption) {
    super(data);
    let link = data.imageUrl;
    this.iconName = link && link.lastIndexOf('/') !== -1 && link.substring(link.lastIndexOf('/') + 1, link.length);

    if (!data) {
      return;
    }
    _.assign(this, data);
  }
}