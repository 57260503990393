<div>
  <div mat-dialog-title>
    <h6 class="h6">
      <strong>Add Certifications</strong>
    </h6>
  </div>

  <div mat-dialog-content class="content-container">
    <mat-tab-group
      class="tabs-container">
      <mat-tab label="Asset Entry">

        <form class="form-container" [formGroup]="customEntryFormGroup" autocomplete="off" novalidate>
          <mat-form-field appearance="outline">
            <mat-label>Document Type</mat-label>
            <mat-select formControlName="type" id="type">
              <div *ngIf="selectOptions$ | async">
                <mat-option *ngFor="let documentType of (selectOptions$ | async).globalProductCertificationTypes" [value]="documentType">
                  {{documentType.name}}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Document URL</mat-label>
            <input matInput placeholder="Document URL" formControlName="url" id="url" autocomplete="off">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Document Name</mat-label>
            <input matInput placeholder="Document Name" formControlName="name" id="name" autocomplete="off">
          </mat-form-field>

          <app-locale-select [parentForm]="customEntryFormGroup"></app-locale-select>
        </form>

      </mat-tab>
    </mat-tab-group>
  </div>

  <div mat-dialog-actions>
    <ng-container>
      <button class="mat-raised-button button-spacing" [mat-dialog-close]="result">Cancel</button>
      <button
        class="mat-raised-button primary-button"
        [disabled]="!customEntryFormGroup.valid || isPreSelected()"
        (click)="confirmEntry()">
        Add Certification
      </button>
    </ng-container>
  </div>

</div>
