<ng-container *ngIf="min || max; else minMaxElse">
  <div fxFlex class="align-center-vertical align-right">
    <app-dimension [value]="min" [unitOfMeasure]="unitOfMeasure"></app-dimension>
    <span>
      &nbsp;-&nbsp;
    </span>
    <app-dimension [value]="max" [unitOfMeasure]="unitOfMeasure"></app-dimension>
  </div>
</ng-container>
<ng-template #minMaxElse>
  <div fxFlex class="none align-center-vertical align-right">None</div>
</ng-template>
