<div
  [formGroup]="form"
  [matTooltip]="getIntegrationsTooltip()"
  matTooltipClass="tooltip-multiline">
  <mat-form-field
    appearance="outline"
    [ngClass]="compact ? 'mat-form-field-compact' : ''">
    <mat-label *ngIf="label?.length > 0">{{ label }}</mat-label>

    <mat-select
      placeholder="Integrations"
      [multiple]="allowMultipleSelection"
      formControlName="integrations">
      <mat-select-trigger>

        <div
          class="integration-select-trigger"
          *ngIf="selectedIntegrations && selectedIntegrations.length > 0; else noIntegrationsSelected">

          <div
            *ngIf="selectedIntegrations[0] as integration"
            class="integration-option-container">
              <img
              [src]="integration.iconLink"
              [alt]="integration.name"
              class="integration-icon-trigger"
              onerror="this.src='../../../../../../../assets/images/placeholders/image_placeholder.png'" />
              <span class="integration-name-trigger">{{integration.name}}</span>
          </div>

          <span *ngIf="selectedIntegrations.length > 1" class="additional-selections">
            (+{{selectedIntegrations.length - 1}} {{selectedIntegrations?.length === 2 ? 'other' : 'others'}})
          </span>
        </div>

        <ng-template #noIntegrationsSelected>
          None
        </ng-template>
      </mat-select-trigger>

      <div *ngIf="integrations && integrations.length > 0">

        <mat-option *ngFor="let integration of integrations" [value]="integration.id">
          <div class="integration-option-container">
            <img
            [src]="integration.iconLink"
            [alt]="integration.name"
            class="integration-icon"
            onerror="this.src='../../../../../../../assets/images/placeholders/image_placeholder.png'" />
            <span>{{integration.name}}</span>
          </div>
        </mat-option>
      </div>
    </mat-select>

    <mat-error *ngIf="hasError('integrations', 'required')">Integration is required</mat-error>
  </mat-form-field>
</div>
