import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import { assign as _assign } from 'lodash-es';

export interface IDesignerSummary extends IBaseAuditModel {
  name: string;
  imageLink: string;
  bio: string;
  webPageUrl: string;
}

export class DesignerSummary extends BaseAuditModel implements IDesignerSummary {
  name: string;
  imageLink: string;
  bio: string;
  webPageUrl: string;

  constructor(data: IDesignerSummary) {
    super(data);

    if (!data) {
      return;
    }
    _assign(this, data);
  }
}
