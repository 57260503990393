import { FormGroup } from '@angular/forms';
import { Region } from '../../../core/models/region.model';
import { FlagsService } from '../../../core/services/flags-service.service';
import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../app-store/reducers';

@Component({
  selector: 'app-locale-select',
  templateUrl: './locale-select.component.html',
  styleUrls: ['./locale-select.component.scss']
})
export class LocaleSelectComponent {

  @Input() label = 'Locales';
  @Input() parentForm: FormGroup;
  regions$: Observable<Array<Region>>;

  constructor(
    private store: Store<fromStore.State>,
    private flagsService: FlagsService
    ) {
    this.regions$ = this.store.select(fromStore.getRegions);
  }

  getLocaleFlagImage(localeName: string) {
    return this.flagsService.getFlagFileName(localeName);
  }

}
