<form class="form-container" [formGroup]="datasource.customEntryFormGroup" autocomplete="off" novalidate>
  <mat-form-field appearance="outline">
    <mat-label>Document Type</mat-label>
    <mat-select formControlName="type" id="type">
      <div *ngIf="selectOptions$ | async">
        <mat-option *ngFor="let documentType of (selectOptions$ | async).globalProductLinkTypes" [value]="documentType">
          {{documentType.name}}
        </mat-option>
      </div>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Document URL</mat-label>
    <input matInput placeholder="Document URL" formControlName="url" id="url" autocomplete="off">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Document Name</mat-label>
    <input matInput placeholder="Document Name" formControlName="name" id="name" autocomplete="off">
  </mat-form-field>

  <app-locale-select [parentForm]="datasource.customEntryFormGroup"></app-locale-select>
</form>
