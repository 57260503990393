import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import * as _ from 'lodash';

export interface IDesigner extends IBaseAuditModel {
  name: string;
  imageLink: string;
  bio: string;
  webPageUrl: string;
  aemTag: string;
}

export class Designer extends BaseAuditModel implements IDesigner {
  name: string;
  imageLink: string;
  bio: string;
  webPageUrl: string;
  aemTag: string;

  constructor(data?: IDesigner) {
    super(data);

    if (!data) {
      return;
    }

    _.assign(this, data);
  }
}
