import { Action } from '@ngrx/store';
import { Subcategory } from 'src/app/core/models/subcategory.model';
import { SubcategoryPut } from 'src/app/core/models/subcategory-put.model';

export enum SubcategoryApiActionTypes {
  LoadSuccess = '[Subcategory/API] Load Success',
  LoadFailure = '[Subcategory/API] Load Failure',
  CreateSuccess = '[Subcategory/API] Create Success',
  CreateFailure = '[Subcategory/API] Create Failure',
  UpdateSuccess = '[Subcategory/API] Update Success',
  UpdateFailure = '[Subcategory/API] Update Failure',
  RenameSuccess = '[Subcategory/API] Rename Success',
  RenameFailure = '[Subcategory/API] Rename Failure',
  DeleteSuccess = '[Subcategory/API] Delete Success',
  DeleteFailure = '[Subcategory/API] Delete Failure'
}

export class LoadSuccess implements Action {
  readonly type = SubcategoryApiActionTypes.LoadSuccess;

  constructor(public payload: Subcategory) { }
}

export class LoadFailure implements Action {
  readonly type = SubcategoryApiActionTypes.LoadFailure;

  constructor(public payload: any) { }
}

export class CreateSuccess implements Action {
  readonly type = SubcategoryApiActionTypes.CreateSuccess;

  constructor(public payload: Subcategory) { }
}

export class CreateFailure implements Action {
  readonly type = SubcategoryApiActionTypes.CreateFailure;

  constructor(public payload: any) { }
}


export class UpdateSuccess implements Action {
  readonly type = SubcategoryApiActionTypes.UpdateSuccess;

  constructor(public payload: { subcategory: SubcategoryPut, subcategoryName: string }) { }
}

export class UpdateFailure implements Action {
  readonly type = SubcategoryApiActionTypes.UpdateFailure;

  constructor(public payload: any) { }
}

export class RenameSuccess implements Action {
  readonly type = SubcategoryApiActionTypes.RenameSuccess;

  constructor(public payload: { subcategoryId: number, subcategoryName: string }) { }
}

export class RenameFailure implements Action {
  readonly type = SubcategoryApiActionTypes.RenameFailure;

  constructor(public payload: any) { }
}

export class DeleteSuccess implements Action {
  readonly type = SubcategoryApiActionTypes.DeleteSuccess;

  constructor(public payload: { subcategoryId: number, subcategoryName: string }) { }
}

export class DeleteFailure implements Action {
  readonly type = SubcategoryApiActionTypes.DeleteFailure;

  constructor(public payload: any) { }
}

export type SubcategoryApiActionsUnion =
  LoadSuccess
  | LoadFailure
  | CreateSuccess
  | CreateFailure
  | UpdateSuccess
  | UpdateFailure
  | RenameSuccess
  | RenameFailure
  | DeleteSuccess
  | DeleteFailure;
