import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export enum ButtonStyle {
  Primary,
  Success,
  Warning,
  Danger
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  public isConfirmedByUser: boolean;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      id: number
      title: string,
      message: string,
      buttonText: string,
      buttonStyle: ButtonStyle,
      enableConfirmation: boolean,
      showIrreversibleWarning: boolean,
      badgeText: string,
      badgeHidden: true,
      enableCancelButton: true
    }) {
      if (!data || data.enableCancelButton === undefined) {
        this.data.enableCancelButton = true;
      }
    }

  ngOnInit() {
  }

  slideToggleChange(matSlideToggleChange: MatSlideToggleChange) {
    if (matSlideToggleChange.checked) {
      this.isConfirmedByUser = true;
    } else {
      this.isConfirmedByUser = false;
    }
  }

  enableAction(): boolean {
    if (!this.data.enableConfirmation) {
      return true;
    }

    return this.isConfirmedByUser;
  }

  confirmAction(action: boolean) {
    this.dialogRef.close(action);
  }

  getButtonClass(): string {
    let style: string;

    switch (this.data.buttonStyle) {
      case ButtonStyle.Primary: {
        style = 'primary-button';
        break;
      }
      case ButtonStyle.Success: {
        style = 'success-button';
        break;
      }
      case ButtonStyle.Warning: {
        style = 'warning-button';
        break;
      }
      case ButtonStyle.Danger: {
        style = 'danger-button';
        break;
      }
      default: {
        style = 'primary-button';
        break;
      }
    }

    return style;
  }

}
