import { Action } from '@ngrx/store';
import { GlobalCategory } from 'src/app/core/models/global-category.model';
import { GlobalCategoryPut } from 'src/app/core/models/global-category-put.model';

export enum GlobalCategoryApiActionTypes {
  LoadSuccess = '[GlobalCategory/API] Load Success',
  LoadFailure = '[GlobalCategory/API] Load Failure',
  CreateSuccess = '[GlobalCategory/API] Create Success',
  CreateFailure = '[GlobalCategory/API] Create Failure',
  UpdateSuccess = '[GlobalCategory/API] Update Success',
  UpdateFailure = '[GlobalCategory/API] Update Failure',
  RenameSuccess = '[GlobalCategory/API] Rename Success',
  RenameFailure = '[GlobalCategory/API] Rename Failure',
  DeleteSuccess = '[GlobalCategory/API] Delete Success',
  DeleteFailure = '[GlobalCategory/API] Delete Failure'
}

export class LoadSuccess implements Action {
  readonly type = GlobalCategoryApiActionTypes.LoadSuccess;

  constructor(public payload: GlobalCategory) { }
}

export class LoadFailure implements Action {
  readonly type = GlobalCategoryApiActionTypes.LoadFailure;

  constructor(public payload: any) { }
}

export class CreateSuccess implements Action {
  readonly type = GlobalCategoryApiActionTypes.CreateSuccess;

  constructor(public payload: GlobalCategory) { }
}

export class CreateFailure implements Action {
  readonly type = GlobalCategoryApiActionTypes.CreateFailure;

  constructor(public payload: any) { }
}


export class UpdateSuccess implements Action {
  readonly type = GlobalCategoryApiActionTypes.UpdateSuccess;

  constructor(public payload: { globalCategory: GlobalCategoryPut, globalCategoryName: string }) { }
}

export class UpdateFailure implements Action {
  readonly type = GlobalCategoryApiActionTypes.UpdateFailure;

  constructor(public payload: any) { }
}

export class RenameSuccess implements Action {
  readonly type = GlobalCategoryApiActionTypes.RenameSuccess;

  constructor(public payload: { globalCategoryId: number, globalCategoryName: string }) { }
}

export class RenameFailure implements Action {
  readonly type = GlobalCategoryApiActionTypes.RenameFailure;

  constructor(public payload: any) { }
}

export class DeleteSuccess implements Action {
  readonly type = GlobalCategoryApiActionTypes.DeleteSuccess;

  constructor(public payload: { globalCategoryId: number, globalCategoryName: string }) { }
}

export class DeleteFailure implements Action {
  readonly type = GlobalCategoryApiActionTypes.DeleteFailure;

  constructor(public payload: any) { }
}

export type GlobalCategoryApiActionsUnion =
  LoadSuccess
  | LoadFailure
  | CreateSuccess
  | CreateFailure
  | UpdateSuccess
  | UpdateFailure
  | RenameSuccess
  | RenameFailure
  | DeleteSuccess
  | DeleteFailure;
