import { catchError, map, tap } from 'rxjs/operators';
import { PagedResults } from './../models/page-results.model';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CadLinks } from '../models/cad-links.model';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CadLinksService {
    
  basePath = 'cadlinks';

  constructor(private http: HttpClient) {
  }

  getCadLinks$(
        searchTerm: string, 
        pageNumber: number, 
        pageSize: number,
        orderBy: string,
        sortAscending: boolean,
        desiredLinkType: string = null
    ): Observable<PagedResults<CadLinks>> {

        let params = new HttpParams()
            .set('pageNumber', pageNumber.toString())
            .set('pageSize', pageSize.toString())
            .set('orderBy', orderBy.toString())
            .set('sortOrder', sortAscending ? 'asc' : 'desc')
            .set('search', searchTerm)
            .set('desiredLinkType', desiredLinkType);

        return this.http
            .get(`${environment.apiUrl}${this.basePath}`, { params })
            .pipe(
                map(
                    (response: PagedResults<CadLinks>) => response
                )
        );
    }
}
