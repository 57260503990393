import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import * as fromStore from '../../reducers';

import {
  GlobalCategoryApiActions,
  GlobalCategoryActions
} from '../actions';

import { switchMap, map, catchError, tap, mergeMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { GlobalCategoriesService } from 'src/app/core/services/global-categories.service';

@Injectable()
export class GlobalCategoryEffects {

  loadGlobalCategory$ = createEffect(() => this.actions$.pipe(
    ofType(GlobalCategoryActions.GlobalCategoryActionTypes.LoadGlobalCategory),
    map((action: GlobalCategoryActions.LoadGlobalCategory) => action.payload),
    switchMap(globalCategoryId =>
      this.globalCategoriesService.getGlobalCategory$(globalCategoryId).pipe(
        map(
          (globalCategory) => new GlobalCategoryApiActions.LoadSuccess(globalCategory)
        ),
        catchError(error =>
          of(new GlobalCategoryApiActions.LoadFailure(error))
        )
      )
    )
  ));

  createGlobalCategory$ = createEffect(() => this.actions$.pipe(
    ofType<GlobalCategoryActions.CreateGlobalCategory>(GlobalCategoryActions.GlobalCategoryActionTypes.CreateGlobalCategory),
    switchMap((request) =>
      this.globalCategoriesService.createGlobalCategory$(request.payload).pipe(
        map((globalCategory) =>
          new GlobalCategoryApiActions.CreateSuccess(globalCategory)
        ),
        tap((response) => {
          // Navigate to the page in edit mode.
          this.router.navigate([`products/organizational-data/global-category-detail/${response.payload.id}`], { queryParams: { edit: true } });
        }),
        catchError(error =>
          of(new GlobalCategoryApiActions.CreateFailure(error))
        )
      )
    )
  ));

  updateGlobalCategory$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<GlobalCategoryActions.UpdateGlobalCategory>(GlobalCategoryActions.GlobalCategoryActionTypes.UpdateGlobalCategory),
    switchMap(request =>
      this.globalCategoriesService.updateGlobalCategory$(request.payload.globalCategory).pipe(
        map((response) => new GlobalCategoryApiActions.UpdateSuccess({ globalCategory: request.payload.globalCategory, globalCategoryName: request.payload.globalCategoryName })),
        catchError(error => of(new GlobalCategoryApiActions.UpdateFailure(error)))
      )
    )
  ));

  updateGlobalCategorySuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GlobalCategoryApiActions.GlobalCategoryApiActionTypes.UpdateSuccess),
    map((action: GlobalCategoryApiActions.UpdateSuccess) => action.payload),
    map(payload =>
      // Reload the category from the API if the save was successful.
      new GlobalCategoryActions.LoadGlobalCategory(payload.globalCategory.id)
    )
  ));

  renameGlobalCategory$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<GlobalCategoryActions.RenameGlobalCategory>(GlobalCategoryActions.GlobalCategoryActionTypes.RenameGlobalCategory),
    switchMap((request) =>
      this.globalCategoriesService.renameGlobalCategory$({
        globalCategoryId: request.payload.globalCategoryId,
        globalCategoryName: request.payload.globalCategoryName
      }).pipe(
        map(_ => new GlobalCategoryApiActions.RenameSuccess({
          globalCategoryId: request.payload.globalCategoryId,
          globalCategoryName: request.payload.globalCategoryName
        })
        ),
        tap(res => this.store.dispatch(new GlobalCategoryActions.LoadGlobalCategory(request.payload.globalCategoryId))),
        catchError(error =>
          of(new GlobalCategoryApiActions.RenameFailure(error)),
        )
      )
    )
  ));

  deleteGlobalCategory$ = createEffect(() => this.actions$.pipe(
    ofType<GlobalCategoryActions.DeleteGlobalCategory>(GlobalCategoryActions.GlobalCategoryActionTypes.DeleteGlobalCategory),
    switchMap((request) =>
      this.globalCategoriesService.deleteGlobalCategory$(request.payload.globalCategoryId).pipe(
        map((response) =>
          new GlobalCategoryApiActions.DeleteSuccess({ globalCategoryId: request.payload.globalCategoryId, globalCategoryName: request.payload.globalCategoryName })
        ),
        tap((response) => {
          // Navigate to all global categories page
          this.router.navigate([`products/organizational-data/global-categories`]);
        }),
        catchError(error =>
          of(new GlobalCategoryApiActions.DeleteFailure(error))
        )
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<fromStore.State>,
    private globalCategoriesService: GlobalCategoriesService) { }

}
