import { catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { PagedResults } from './../../../core/models/page-results.model';
import { CadLinks } from 'src/app/core/models/cad-links.model';
import { CadLinksService } from 'src/app/core/services/cad-links.service';
import { Injectable } from '@angular/core';
import { CadLinksModalDataSourceConstants } from './cad-links-modal.constants';
import { SubSink } from 'subsink';
import { ModelFileLinkTypes } from '../../constants';


@Injectable({ providedIn: 'root' })
export class CadLinksModalDataSource {

	// UI component config
	desiredLinkType: ModelFileLinkTypes = ModelFileLinkTypes.AutoCad2D;
	
	// set of ordering options for selection from the UI
	readonly orderByOptions = CadLinksModalDataSourceConstants.ORDERBYOPTIONS;

	// variables that are sent to the API for controlling the returned results
	searchTerm: string;
	pageNumber = 1;
	pageSize = 10;
	orderBy: string = this.orderByOptions[0].value;
	sortAscending = true;

	// variables for housing returned data
	totalRecordCount = 0;
	totalPageCount = 0;
	returnedCadLinks: PagedResults<CadLinks> = null;

	loading = false;
	private subscriptions = new SubSink();

	constructor(private cadLinksService: CadLinksService) { }

	public beginSearch() {
		if (!this.searchTerm || this.searchTerm.trim().length == 0) {
			return;
		}

		this.load();
	}

	private load() {
		this.unsubscribeAll();

		this.pageNumber = 1;
		this.loading = true;

		const desiredLinkTypeString = this.getDesiredLinkTypeString(this.desiredLinkType);

		this.subscriptions.add(
			this.cadLinksService.getCadLinks$(this.searchTerm.toUpperCase(), this.pageNumber, this.pageSize, this.orderBy, this.sortAscending, desiredLinkTypeString)
				.pipe(
					catchError(() => of([])),
					finalize(() => {
							this.loading = false;
						}
					)
				)
				.subscribe(
					(response: PagedResults<CadLinks>) => {
						this.returnedCadLinks = response;
						this.totalRecordCount = response.totalRecordCount;
						this.totalPageCount = response.totalPageCount;
					}
				)
		);
	}

	public loadNext() {
		this.unsubscribeAll();

		if (this.pageNumber >= this.totalPageCount) {
			return;
		}
			
		this.pageNumber += 1;

		const desiredLinkTypeString = this.getDesiredLinkTypeString(this.desiredLinkType);

		this.subscriptions.add(
			this.cadLinksService.getCadLinks$(this.searchTerm, this.pageNumber, this.pageSize, this.orderBy, this.sortAscending, desiredLinkTypeString)
				.pipe(
					catchError(() => of([])),
					finalize(() => {
							this.loading = false;
						}
					)
				)
				.subscribe(
					(response: PagedResults<CadLinks>) => {
						this.pageNumber = response.pageNumber;
						this.returnedCadLinks.results.push(...response.results);
						this.totalRecordCount = response.totalRecordCount;
						this.totalPageCount = response.totalPageCount;
					}
				)
		);
	}

	public clearPreviousData() {
		this.returnedCadLinks = null;
	}

	unsubscribeAll() {
		this.subscriptions.unsubscribe();
	}

	getDesiredLinkTypeString(desiredLinkType: ModelFileLinkTypes): string {
		let desiredLinkTypeString: string = null;
		switch (desiredLinkType){
			case ModelFileLinkTypes.AutoCad2D: {
				desiredLinkTypeString = 'cad2d';
				break;
			}
			case ModelFileLinkTypes.AutoCad3D: {
				desiredLinkTypeString = 'cad3d';
				break;
			}
			case ModelFileLinkTypes.Revit: {
				desiredLinkTypeString = 'revit';
				break;
			}case ModelFileLinkTypes.Sketchup: {
				desiredLinkTypeString = null;
				break;
			}
			default: {
				// default to 2D cad
				desiredLinkTypeString = null;
			}
		}
		return desiredLinkTypeString;
	}
}