import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import * as _ from 'lodash';

export interface IContactPost extends IBaseAuditModel {
  name: string;
}

export class ContactPost extends BaseAuditModel implements IContactPost {
  name: string;

  constructor(data?: IContactPost) {
    super(data);

    if (!data) {
      return;
    }

    _.assign(this, data);
  }
}
