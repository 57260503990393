import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import { assign as _assign } from 'lodash-es';

export interface IGlobalCategoryPost extends IBaseAuditModel {
  name: string;
  industryId: number;
}

export class GlobalCategoryPost extends BaseAuditModel {
  name: string;
  industryId: number;

  constructor(data?: IGlobalCategoryPost) {
    super(data);

    if (!data) {
      return;
    }
    _assign(this, data);
  }
}
