import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { IMaterial, IMaterialColor } from 'src/app/core/models/material.model';
import { ChartData } from 'chart.js';

@Component({
  selector: 'app-material-modal',
  templateUrl: './material-modal.component.html',
  styleUrls: ['./material-modal.component.scss']
})
export class MaterialModalComponent implements OnInit {
  material: IMaterial;
  materialColor: IMaterialColor;

  public chartLabels: Array<string> = [];
  public chartData: ChartData<'doughnut'>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      material: IMaterial,
      materialColor: IMaterialColor
    }
  ) { }

  ngOnInit(): void {
    this.material = this.data.material;
    this.materialColor = this.data.materialColor;
    this.chartLabels = this.material?.fabricContents?.map(x => x.name);
    this.chartData = {
      labels: this.chartLabels,
      datasets: [
        {
          data: this.material?.fabricContents?.map(x => x.percentage),
          backgroundColor: ['#00816C', '#0D75CB', '#252525', '#e22d00', '#876B8B', '#007E94', '#CE973D'],
          hoverBackgroundColor: ["#00816C", "#0D75CB"],
          type: 'doughnut'
        }
      ]
    };
  }

}
