import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumberService } from 'src/app/core/services/phone-number-service';


@Pipe({name: 'phoneNumber'})
export class PhoneNumberPipe implements PipeTransform {

    constructor(
        private phoneNumberService: PhoneNumberService
    ){}

    transform(value: string): string {
        return this.phoneNumberService.formatPhoneNumber(value);
    }
}