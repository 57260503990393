import { EndpointProductOfferingTag, EndpointProductOfferingTagPost, IEndpointProductOfferingTag, EndpointProductOfferingTagPut } from './../models/endpoint-product-offering-tag.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EndpointProductOfferingTagsService {
  resource = 'tags';

  constructor(private http: HttpClient) { }

  getAll$(endpointId: number): Observable<Array<EndpointProductOfferingTag>> {
    return this.http.get(`${environment.apiUrl}endpoints/${endpointId}/${this.resource}`).pipe(
      map((response: Array<EndpointProductOfferingTag>) => response)
    );
  }

  getDuplicates$(endpointId: number, request: EndpointProductOfferingTagPost): Observable<Array<EndpointProductOfferingTag>> {
    return this.http.post(`${environment.apiUrl}endpoints/${endpointId}/${this.resource}/duplicates`, request).pipe(
      map((response: Array<EndpointProductOfferingTag>) => response)
    );
  }

  create$(endpointId: number, request: EndpointProductOfferingTagPost): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}endpoints/${endpointId}/${this.resource}`,
      request
    ).pipe(
      map((response) => response),
      catchError((error: Response) => {
        return throwError(error);
      }),
      map((tag: IEndpointProductOfferingTag) => new EndpointProductOfferingTag(tag))
    );
  }

  update$(endpointId: number, endpointProductOfferingTagId: number, request: EndpointProductOfferingTagPut): Observable<any> {
    return this.http.put(`${environment.apiUrl}endpoints/${endpointId}/${this.resource}/${endpointProductOfferingTagId}`, request)
    .pipe(
      map(response => response),
      catchError((error: Response) => {
        return throwError(error);
      })
    );
  }

  delete$(endpointId: number, endpointProductOfferingTagId: number): Observable<any> {
    return this.http.delete(
      `${environment.apiUrl}endpoints/${endpointId}/${this.resource}/${endpointProductOfferingTagId}`
      );
  }
}
