import { LightboxConfig, IAlbum, Lightbox } from 'ngx-lightbox';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LightboxesService {

  constructor(
    private lightbox: Lightbox,
    private lightboxConfig: LightboxConfig
   ) {
    this.lightboxConfig.centerVertically = true;
    this.lightboxConfig.fitImageInViewPort = true;
    this.lightboxConfig.disableScrolling = true;
  }

  open(album: IAlbum[], index: number = 0) {
    this.lightbox.open(album, index);
  }
}
