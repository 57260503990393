import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import {
  IntegrationsApiActions,
  IntegrationsActions
} from '../actions';

import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { switchMap, map, catchError } from 'rxjs/operators';
import { IntegrationsService } from 'src/app/core/services/integrations.service';


@Injectable()
export class IntegrationsEffects {
  @Effect()
  loadIntegrationCollection$: Observable<Action> = this.actions$.pipe(
    ofType<IntegrationsActions.LoadIntegrations>(IntegrationsActions.IntegrationsActionTypes.LoadIntegrations),
    switchMap(() =>
      this.integrationsService.getIntegrations$().pipe(
        map((integrations) => new IntegrationsApiActions.LoadSuccess(integrations)
        ),
        catchError(error =>
          of(new IntegrationsApiActions.LoadFailure(error))
        )
      )
    )
  );

  constructor(private actions$: Actions,
              private integrationsService: IntegrationsService, ) {}

}
