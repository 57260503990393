import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as fromStore from '../../../app-store/reducers';

import {
  IntegrationApiActions,
  IntegrationActions
} from '../actions';

import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { IntegrationsService } from 'src/app/core/services/integrations.service';

@Injectable()
export class IntegrationEffects {
  @Effect()
  getIntegrationById$: Observable<Action> = this.actions$.pipe(
    ofType<IntegrationActions.LoadIntegration>(IntegrationActions.IntegrationActionTypes.LoadIntegration),
    map((action: IntegrationActions.LoadIntegration) => action.payload),
    switchMap((option) =>
      this.integrationsService.getIntegrationById$(option).pipe(
        map((integration) => new IntegrationApiActions.LoadSuccess(integration)
        ),
        catchError(error =>
          of(new IntegrationApiActions.LoadFailure(error))
        )
      )
    )
  );

  @Effect()
  updateIntegration$: Observable<Action> = this.actions$.pipe(
    ofType<IntegrationActions.UpdateIntegration>(IntegrationActions.IntegrationActionTypes.UpdateIntegration),
    switchMap((request) =>
      this.integrationsService.updateIntegration$(request.payload).pipe(
        map((updatedIntegration) => new IntegrationApiActions.UpdateSuccess(updatedIntegration)
        ),
        catchError((error) =>
          of(new IntegrationApiActions.UpdateFailure(error, request.payload))
        )
      )
    )
  );

  @Effect()
  updateIntegrationSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(IntegrationApiActions.IntegrationApiActionTypes.UpdateSuccess),
    map((action: IntegrationApiActions.UpdateSuccess) => action.payload),
    map(payload =>
      // Reload the application from the API if the save was successful.
      new IntegrationActions.LoadIntegration(payload.id)
    )
  );

  @Effect()
  renameIntegration$: Observable<Action> = this.actions$.pipe(
    ofType<IntegrationActions.RenameIntegration>(IntegrationActions.IntegrationActionTypes.RenameIntegration),
    switchMap((request) =>
      this.integrationsService.renameIntegration$({
        integrationId: request.payload.integrationId,
        integrationName: request.payload.integrationName
      }).pipe(
        map(_ => new IntegrationApiActions.RenameSuccess({
          integrationId: request.payload.integrationId,
          integrationName: request.payload.integrationName
        })
        ),
        tap(res => this.store.dispatch(new IntegrationActions.LoadIntegration(request.payload.integrationId))),
        catchError(error =>
          of(new IntegrationApiActions.RenameFailure(error)),
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private integrationsService: IntegrationsService,
    private store: Store<fromStore.State>) { }

}
