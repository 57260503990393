import { WebLinkType } from './global-product-web-link.model';
import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import { assign as _assign } from 'lodash-es';
import { ContactGlobalProductLocalePut } from './contact-global-product-local-put.model';
import { GlobalSubcategoryAttributeType } from './global-subcategory-attribute-type.model';
import { AttributeOption } from './attribute-option.model';
import { GlobalProductParentAttributeOption, IGlobalProductParentAttributeOption } from './global-product-parent-attribute-option.model';

export interface IGlobalProductPut
  extends IBaseAuditModel {
  brandId: number;
  subBrandId?: number;
  includeInCoBranding: boolean;
  designerIds: Array<number>;
  familyIds: Array<number>;
  portfolioIds: Array<number>;
  globalProductSubcategories: Array<GlobalProductSubcategoryPut>;
  globalProductGlobalSubcategories: Array<IGlobalProductGlobalSubcategoryPut>;
  pageTitleKeyword: string;
  globalProductLocales: Array<GlobalProductLocalePut>;
  globalProductTags: Array<GlobalProductTagPut>;
  warrantyId: number;
  isSpecialDelivery: boolean;
  isEssentials: boolean;
  globalProductWebLinks: Array<GlobalProductWebLinkPut>;
  brandSiteProductLink: string;
  materialsLink: string;
  scoutLink: string;
  omniLink: string;
  status?: number;
  globalProductConfigurators: Array<IGlobalProductConfiguratorPut>;
  globalProductMediaLinks: Array<GlobalProductMediaLinkPut>;
  globalProductLinks: Array<GlobalProductLinkPut>;
  globalProductCertifications: Array<GlobalProductCertificationPut>;
  globalProductAttributeOptions: Array<GlobalProductAttributeOptionPut>;
  modelFilesProfiles: Array<ModelFilesProfilePut>;
  contacts: Array<ContactGlobalProductLocalePut>;
  globalProductAttributeOptionsOfferingExclusions: Array<AttributeOptionOfferingExclusionsPut>;
}

export class GlobalProductPut
  extends BaseAuditModel
  implements IGlobalProductPut {

  brandId: number;
  subBrandId?: number;
  includeInCoBranding: boolean;
  designerIds: Array<number> = [];
  familyIds: Array<number> = [];
  portfolioIds: Array<number> = [];
  globalProductSubcategories: Array<GlobalProductSubcategoryPut> = [];
  globalProductGlobalSubcategories: Array<GlobalProductGlobalSubcategoryPut> = [];
  pageTitleKeyword: string;
  globalProductLocales: Array<GlobalProductLocalePut> = [];
  globalProductTags: Array<GlobalProductTagPut> = [];
  warrantyId: number;
  isSpecialDelivery: boolean;
  isEssentials: boolean;
  globalProductWebLinks: Array<GlobalProductWebLinkPut> = [];
  brandSiteProductLink: string;
  materialsLink: string;
  scoutLink: string;
  omniLink: string;
  status?: number;
  globalProductConfigurators: Array<GlobalProductConfiguratorPut>;
  globalProductMediaLinks: Array<GlobalProductMediaLinkPut>;
  globalProductLinks: Array<GlobalProductLinkPut> = [];
  globalProductCertifications: Array<GlobalProductCertificationPut> = [];
  globalProductAttributeOptions: Array<GlobalProductAttributeOptionPut> = [];
  modelFilesProfiles: Array<ModelFilesProfilePut> = [];
  contacts: Array<ContactGlobalProductLocalePut> = [];
  globalProductAttributeOptionsOfferingExclusions: Array<AttributeOptionOfferingExclusionsPut> = [];

  constructor(data?: IGlobalProductPut) {
    super(data);
    if (!data) {
      return;
    }

    _assign(this, data);
  }
}

export interface IGlobalProductSubcategoryPut {
  subcategoryId: number;
  isPrimary: boolean;
}

export class GlobalProductSubcategoryPut
  implements IGlobalProductSubcategoryPut {

  subcategoryId: number;
  isPrimary: boolean;

  constructor(data?: IGlobalProductSubcategoryPut) {
    if (!data) {
      return;
    }

    _assign(this, data);
  }
}

export interface IGlobalProductGlobalSubcategoryPut {
  globalSubcategoryId: number;
  isPrimary: boolean;
}

export class GlobalProductGlobalSubcategoryPut
  implements IGlobalProductGlobalSubcategoryPut {

  globalSubcategoryId: number;
  isPrimary: boolean;

  constructor(data?: IGlobalProductGlobalSubcategoryPut) {
    if (!data) {
      return;
    }
    _assign(this, data);
  }
}

export interface IGlobalProductLocalePut {
  localeId: number;
  isPrimaryLocale: boolean;
}

export class GlobalProductLocalePut
  implements IGlobalProductLocalePut {

  localeId: number;
  isPrimaryLocale: boolean;

  constructor(data?: IGlobalProductLocalePut) {
    if (!data) {
      return;
    }

    _assign(this, data);
  }
}

export interface IGlobalProductTagPut
  extends IBaseAuditModel {
  tag: string;
}

export class GlobalProductTagPut
  extends BaseAuditModel
  implements IGlobalProductTagPut {
  tag: string;

  constructor(data?: IGlobalProductTagPut) {
    super(data);
    if (!data) {
      return;
    }
    _assign(this, data);
  }
}

export interface IGlobalProductConfiguratorPut
  extends IBaseAuditModel {
  configurator: string;
  isPrimary: boolean;
}

export class GlobalProductConfiguratorPut
  extends BaseAuditModel
  implements IGlobalProductConfiguratorPut {
  configurator: string;
  isPrimary: boolean;

  constructor(data?: IGlobalProductConfiguratorPut) {
    super(data);
    if (!data) {
      return;
    }
    _assign(this, data);
  }
}
export interface IGlobalProductWebLinkPut
  extends IBaseAuditModel {

  localeId: number;
  webLinkTypeId: WebLinkType;
  webLinkUrl: string;
}

export class GlobalProductWebLinkPut
  extends BaseAuditModel
  implements IGlobalProductWebLinkPut {

  localeId: number;
  webLinkTypeId: WebLinkType;
  webLinkUrl: string;

  constructor(data?: IGlobalProductWebLinkPut) {
    super(data);
    if (!data) {
      return;
    }

    _assign(this, data);
  }
}

export interface IGlobalProductLinkPut
  extends IBaseAuditModel {
  name: string;
  globalProductLinkTypeId: number;
  localeIds: Array<number>;
  link: string;
  ordinal: number;
}

export class GlobalProductLinkPut
  extends BaseAuditModel
  implements IGlobalProductLinkPut {

  name: string;
  globalProductLinkTypeId: number;
  localeIds: Array<number> = [];
  link: string;
  ordinal: number;

  constructor(data?: IGlobalProductLinkPut) {
    super(data);
    if (!data) {
      return;
    }

    _assign(this, data);
  }
}

export interface IGlobalProductCertificationPut
  extends IBaseAuditModel {
  name: string;
  globalProductCertificationTypeId: number;
  localeIds: Array<number>;
  link: string;
  ordinal: number;
}

export class GlobalProductCertificationPut
  extends BaseAuditModel
  implements IGlobalProductCertificationPut {

  name: string;
  globalProductCertificationTypeId: number;
  localeIds: Array<number> = [];
  link: string;
  ordinal: number;

  constructor(data?: IGlobalProductCertificationPut) {
    super(data);
    if (!data) {
      return;
    }

    _assign(this, data);
  }
}

export interface IModelFilesProfilePut
  extends IBaseAuditModel {
  name: string;
  globalProductId: number;
  modelFileLinks: Array<ModelFileLinkPut>;
}

export class ModelFilesProfilePut
  extends BaseAuditModel
  implements IModelFilesProfilePut {

  name: string;
  globalProductId: number;
  modelFileLinks: Array<ModelFileLinkPut>;

  constructor(data?: IModelFilesProfilePut) {
    super(data);
    if (!data) {
      return;
    }

    _assign(this, data);
  }
}

export interface IAttributeOptionOfferingExclusionsPut
  extends IBaseAuditModel {
  attributeOptionId: number;
  globalProductOfferingId: number;
}

export class AttributeOptionOfferingExclusionsPut
  extends BaseAuditModel
  implements IAttributeOptionOfferingExclusionsPut {
  attributeOptionId: number;
  globalProductOfferingId: number;

  constructor(data?: IAttributeOptionOfferingExclusionsPut) {
    super(data);
    if (!data) {
      return;
    }

    _assign(this, data);
  }
}

export interface IModelFileLinkPut
  extends IBaseAuditModel {
  link: string;
  modelFileLinkTypeId: number;

}

export interface IGlobalProductAttributeOptionPut {
  attributeOptionId: number;
  globalProductId: number;
  globalProductParentAttributeOptions?: Array<IGlobalProductParentAttributeOption>;
}

export class GlobalProductAttributeOptionPut
  implements IGlobalProductAttributeOptionPut {
  attributeOptionId: number;
  globalProductId: number;
  globalProductParentAttributeOptions?: Array<GlobalProductParentAttributeOption> = [];
  constructor(data?: IGlobalProductAttributeOptionPut) {
    if (!data) {
      return;
    }
    _assign(this, data);
  }
}

export interface IGlobalProductAttributeOptionValuePut {
  globalSubcategoryAttributeType: GlobalSubcategoryAttributeType;
  attributeOption: AttributeOption;
}

export class GlobalProductAttributeOptionValuePut implements IGlobalProductAttributeOptionValuePut {
  globalSubcategoryAttributeType: GlobalSubcategoryAttributeType;
  attributeOption: AttributeOption;

  constructor(data?: IGlobalProductAttributeOptionValuePut) {
    if (!data) {
      return;
    }

    _assign(this, data);
  }

}

export class ModelFileLinkPut
  extends BaseAuditModel
  implements IModelFileLinkPut {

  link: string;
  modelFileLinkTypeId: number;

  constructor(data?: IModelFileLinkPut) {
    super(data);
    if (!data) {
      return;
    }

    _assign(this, data);
  }
}

export interface IGlobalProductMediaLinkPut
  extends IBaseAuditModel {
  globalProductId: number;
  thumbnailImageLink: string;
  thumbnailTitle: string;
  thumbnailAltText: string;
  thumbnailMediaSubType: string;
  thumbnailDamProviderId: number;

  damProviderId: number;
  mediaTypeId: number;
  mediaSubTypeId: number;
  title: string;
  altText: string;
  mediaSubType: string;
  mediaLink: string;
  ordinal: number;
}

export class GlobalProductMediaLinkPut
  extends BaseAuditModel
  implements IGlobalProductMediaLinkPut {

  globalProductId: number;
  thumbnailImageLink: string;
  thumbnailTitle: string;
  thumbnailAltText: string;
  thumbnailMediaSubType: string;
  thumbnailDamProviderId: number;

  damProviderId: number;
  mediaTypeId: number;
  mediaSubTypeId: number;
  title: string;
  altText: string;
  mediaSubType: string;
  mediaLink: string;
  ordinal: number;

  constructor(data?: IGlobalProductMediaLinkPut) {
    super(data);
    if (!data) {
      return;
    }

    _assign(this, data);
  }
}
