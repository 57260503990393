import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { PagedResults } from '../models/page-results.model';
import { FamilySummary } from '../models/family-summary.model';
import { Family, IFamily } from '../models/family.model';
import { FamilyPost } from '../models/family-post.model';
import { FamilyPut } from '../models/family-put.model';
import { RenamePut } from '../models/rename-put.model';
import { FamilyDuplicateCheck } from '../models/family-duplicate-check.model';

@Injectable({
  providedIn: 'root'
})
export class FamiliesService {
  basePath = 'families';
  constructor(private http: HttpClient) {
  }

  getFamilies$(searchTerm: string = '', pageNumber: number, pageSize: number, sortActive: string, sortOrder: string): Observable<PagedResults<FamilySummary>> {
    return this.http.get(`${environment.apiUrl}${this.basePath}`, {
      params: new HttpParams()
        .set('search', searchTerm)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
        .set('orderBy', sortActive.toString())
        .set('sortOrder', sortOrder.toString())
    }).pipe(
      map((response: PagedResults<FamilySummary>) => response)
    );
  }

  getFamily$(id: number): Observable<Family> {
    console.log(`getFamily$(${id})`);
    return this.http
      .get(environment.apiUrl + `${this.basePath}/${id}`)
      .pipe(map(response => response)
        , map((x: IFamily) => new Family(x)));
  }

  createFamily$(family: FamilyPost): Observable<Family> {
    console.log(`createFamily(${family})`);

    return this.http
      .post(environment.apiUrl + `${this.basePath}`, family)
      .pipe(map(res => res)
        , catchError((err: Response) => {
          const obj = err;
          return observableThrowError(obj);
        })
        , map((x: IFamily) => new Family(x)));
  }

  getDuplicates$(familyName: string): Observable<Array<FamilySummary>> {
    const request: FamilyDuplicateCheck = new FamilyDuplicateCheck();
    request.name = familyName;

    return this.http
      .post(`${environment.apiUrl}${this.basePath}/duplicates`, request)
      .pipe(
        map(response => response),
        catchError((error: Response) => {
          return observableThrowError(error);
        }),
        map((response: Array<FamilySummary>) => response)
      );
  }

  deleteFamily$(familyId: number): Observable<any> {
    return this.http.delete(environment.apiUrl + `${this.basePath}/${familyId}`)
      .pipe(
        map(res => res),
        catchError((err: Response) => {
          const obj = err;
          return observableThrowError(obj);
        })
      );
  }

  updateFamily$(family: FamilyPut): Observable<any> {
    console.log(`updateFamily(${family})`);

    return this.http
      .put(`${environment.apiUrl}${this.basePath}/${family.id}`, family)
      .pipe(map(response => response),
        catchError((err: Response) => {
          console.log('Error on family update:');
          console.log(err);
          return observableThrowError(err);
        })
      );
  }

  renameFamily$(request: { familyId: number, familyName: string }): Observable<any> {
    const renamePut: RenamePut = new RenamePut({ name: request.familyName });
    return this.http
      .put(environment.apiUrl + `${this.basePath}/${request.familyId}/rename`,
        renamePut,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
      );
  }
}
