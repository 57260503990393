import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PagedResults } from '../models/page-results.model';
import { AttributeTypeSummary } from '../models/attribute-type-summary.model';
import { IAttributeType, AttributeType } from '../models/attribute-type.model';
import { AttributeTypePost } from '../models/attribute-type-post.model';
import { RenamePut } from '../models/rename-put.model';

@Injectable({
  providedIn: 'root'
})
export class AttributeTypeService {
  basePath = 'attributetypes';

  constructor(private http: HttpClient) { }

  getDuplicates$(attributeTypeName: string): Observable<Array<AttributeTypeSummary>> {
    const request: AttributeTypePost = new AttributeTypePost();
    request.name = attributeTypeName;

    return this.http
      .post(`${environment.apiUrl}${this.basePath}/duplicates`, request)
      .pipe(
        map(response => response),
        catchError((error: Response) => {
          return throwError(error);
        }),
        map((response: Array<AttributeTypeSummary>) => response)
      );
  }

  getAttributeTypes$(searchTerm: string, pageNumber: number, pageSize: number, 
    sortActive: string, sortDirection: string) : Observable<PagedResults<AttributeTypeSummary>>
  {
    return this.http.get(`${environment.apiUrl}${this.basePath}`, {
      params: new HttpParams()
      .set('search', searchTerm)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
        .set('orderBy', sortActive.toString())
        .set('sortOrder', sortDirection.toString())
    }).pipe(
      map((response: PagedResults<AttributeTypeSummary>) => response)
    );
  }

  deleteAttributeType$(attributeTypeId: number): Observable<any>{
    return this.http.delete(environment.apiUrl + `${this.basePath}/${attributeTypeId}`)
    .pipe(
      map(res => res),
      catchError((err: Response) => {
        const obj = err;
        return throwError(obj);
      })
    );
  }

  createAttributeType$(attributeType: AttributeTypePost): Observable<AttributeType>{
    return this.http.post(environment.apiUrl + `${this.basePath}`, attributeType)
    .pipe(map(res => res)
      ,catchError((err: Response) => {
        const obj = err;
        return throwError(obj);
      })
      ,map((x: IAttributeType) => new AttributeType(x)));
  }


  getAttributeType$(id: number): Observable<AttributeType> {
    console.log(`getAttributeType$(${id})`);
    return this.http
      .get(environment.apiUrl + `${this.basePath}/${id}`)
      .pipe(map(response => response)
        , map((x: IAttributeType) => new AttributeType(x)));
  }

  renameAttributeType$(request : { attributeTypeId: number, attributeTypeName: string }): Observable<any>{
    const renamePut: RenamePut = new RenamePut({ name: request.attributeTypeName});

    return this.http.put(environment.apiUrl + `${this.basePath}/${request.attributeTypeId}/rename`,
    renamePut,
    { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
    );
  }
  
}
