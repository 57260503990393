import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import { assign as _assign } from 'lodash-es';

export interface IPortfolioPost extends IBaseAuditModel {
  name: string;
  environmentId: number;
}

export class PortfolioPost extends BaseAuditModel {
  name: string;
  environmentId: number;

  constructor(data?: IPortfolioPost) {
    super(data);

    if (!data) {
      return;
    }

    _assign(this, data);
  }
}
