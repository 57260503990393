import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import * as _ from 'lodash';

export interface IGlobalProductOfferingDuplicateCheck extends IBaseAuditModel {
  name: string;
  templateId: number;
  globalProductId: number;
}

export class GlobalProductOfferingDuplicateCheck extends BaseAuditModel implements IGlobalProductOfferingDuplicateCheck {
  name: string;
  templateId: number;
  globalProductId: number;

  constructor(data?: IGlobalProductOfferingDuplicateCheck) {
    super(data);

    if (!data) {
      return;
    }
    _.assign(this, data);
  }
}
