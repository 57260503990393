import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ghost-sidebar-item',
  templateUrl: './ghost-sidebar-item.component.html',
  styleUrls: ['./ghost-sidebar-item.component.scss']
})
export class GhostSidebarItemComponent implements OnInit {
  @Input() items = 3;
  @Input() subItems = 2;

  ghostItems: GhostItem[] = [];

  constructor() { }

  ngOnInit() {
    this.populate();
  }

  private populate() {
    for (let i = 0; i < this.items; i++) {
      this.ghostItems.push(new GhostItem());

      for (let s = 0; s < this.subItems; s++) {
        this.ghostItems[i].ghostSubItems.push(new GhostSubItem());
      }
    }
  }
}

class GhostItem {
  constructor(
    public ghostSubItems: GhostSubItem[] = []
  ) {}
}

class GhostSubItem {
  constructor() {}
}
