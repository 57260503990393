<div>
  <div mat-dialog-title>
    <h6 class="h6">
      <strong>Add Literature Documents</strong>
    </h6>
  </div>

  <div mat-dialog-content class="content-container">
    <mat-tab-group
      class="tabs-container"
      [(selectedIndex)]="this.datasource.visibleView">
      <mat-tab
        label="Digital Asset Manager">
        <app-product-literature-grid
          [datasource]="datasource">
        </app-product-literature-grid>
      </mat-tab>
      <mat-tab label="Asset Entry">
        <app-product-literature-document-entry
          [datasource]="datasource">
        </app-product-literature-document-entry>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div mat-dialog-actions>
    <ng-container *ngIf="datasource.visibleView === Views.Selection">
      <a
        class="clear-selection button-spacing"
        (click)="clearSelection()">
        Clear Selection
      </a>
      <button
        class="mat-raised-button button-spacing"
        [mat-dialog-close]="result">
        Cancel
      </button>
      <button
        class="mat-raised-button primary-button"
        [disabled]="datasource.selection.selected.length === 0"
        (click)="confirmSelection();">
          Add Literature
          ({{datasource.selection.selected.length}})
      </button>
    </ng-container>

    <ng-container *ngIf="datasource.visibleView === Views.Custom">
      <button class="mat-raised-button button-spacing" [mat-dialog-close]="result">Cancel</button>
      <button
        class="mat-raised-button primary-button"
        [disabled]="!datasource.customEntryFormGroup.valid || datasource.isPreSelected(datasource.getCustomEntry())"
        (click)="confirmEntry()">
        Add Literature
      </button>
    </ng-container>
  </div>

</div>
