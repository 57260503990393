import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { PagedResults } from '../models/page-results.model';
import { RenamePut } from '../models/rename-put.model';
import { ISubBrand, SubBrand } from '../models/sub-brand.model';
import { SubBrandSummary } from '../models/sub-brand-summary.model';
import { SubBrandPost } from '../models/sub-brand-post.model';
import { SubBrandPut } from '../models/sub-brand-put.model';

@Injectable({
  providedIn: 'root'
})
export class SubBrandsService {
  basePath = 'subBrands';
  constructor(private http: HttpClient) {
  }

  getSubBrands$(searchTerm: string = '', pageNumber: number, pageSize: number, sortActive: string, sortOrder: string): Observable<PagedResults<SubBrandSummary>> {
    return this.http.get(`${environment.apiUrl}${this.basePath}`, {
      params: new HttpParams()
        .set('search', searchTerm)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
        .set('orderBy', sortActive.toString())
        .set('sortOrder', sortOrder.toString())
    }).pipe(
      map((response: PagedResults<SubBrandSummary>) => response)
    );
  }

  getSubBrand$(id: number): Observable<SubBrand> {
    console.log(`getSubBrand$(${id})`);
    return this.http
      .get(environment.apiUrl + `${this.basePath}/${id}`)
      .pipe(map(response => response)
        , map((x: ISubBrand) => new SubBrand(x)));
  }

  createSubBrand$(subBrand: SubBrandPost): Observable<SubBrand> {
    console.log(`createSubBrand(${subBrand})`);

    return this.http
      .post(environment.apiUrl + `${this.basePath}`, subBrand)
      .pipe(map(res => res)
        , catchError((err: Response) => {
          const obj = err;
          return observableThrowError(obj);
        })
        , map((x: ISubBrand) => new SubBrand(x)));
  }

  updateSubBrand$(subBrand: SubBrandPut): Observable<any> {
    console.log(`updateSubBrand(${subBrand})`);

    return this.http
      .put(`${environment.apiUrl}${this.basePath}/${subBrand.id}`, subBrand)
      .pipe(map(response => response),
        catchError((err: Response) => {
          console.log('Error on subBrand update:');
          console.log(err);
          return observableThrowError(err);
        })
      );
  }

  renameSubBrand$(request: { subBrandId: number, subBrandName: string }): Observable<any> {
    const renamePut: RenamePut = new RenamePut({ name: request.subBrandName });
    return this.http
      .put(environment.apiUrl + `${this.basePath}/${request.subBrandId}/rename`,
        renamePut,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
      );
  }

  deleteSubBrand$(subBrandId: number): Observable<any> {
    return this.http.delete(environment.apiUrl + `${this.basePath}/${subBrandId}`)
      .pipe(
        map(res => res),
        catchError((err: Response) => {
          const obj = err;
          return observableThrowError(obj);
        })
      );
  }

  getDuplicates$(subBrandName: string, brandId: number): Observable<Array<SubBrandSummary>> {
    const request: SubBrandPost = new SubBrandPost();
    request.name = subBrandName;
    request.brandId = brandId;

    return this.http
      .post(`${environment.apiUrl}${this.basePath}/duplicates`, request)
      .pipe(
        map(response => response),
        catchError((error: Response) => {
          return observableThrowError(error);
        }),
        map((response: Array<SubBrandSummary>) => response)
      );
  }
}
