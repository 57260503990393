export class Dimensions {
  public static readonly MODAL_STANDARD_WIDTH = '450px';
  public static readonly MODAL_STANDARD_X_WIDTH = '500px';
  public static readonly MODAL_WIDE_WIDTH = '750px';
  public static readonly MODAL_LARGE_MIN_WIDTH = '300px';
  public static readonly MODAL_LARGE_WIDE_WIDTH = '1200px';
  public static readonly MODAL_STANDARD_HEIGHT = '450px';
}

export enum LocaleIds {
  UNITED_STATES = 1,
  CANADA = 21
}

export enum ApplicationStatus {
  Active = 'active',
  ActiveIcon = 'check_circle',
  Draft = 'draft',
  DraftIcon = 'drafts'
}

export enum ProductStatusId {
  Active = 1,
  Discontinued = 2
}

export enum CurrencyIds {
  USD = 1
}

export enum ContractIds {
  US_PRIME = 1,
  CANADIAN_PRIME = 2
}

export enum SifTypeIds {
  SIF_TYPE_DEFAULT = 1,
  SIF_TYPE_NO_SEATING = 2
}

export enum BrandRelationshipGroupIds {
  HermanMiller,
  AlliancePartner
}

export enum UOMStandards {
  FeetInches = 0,
  MillimeterCentimeterMeter = 1,
  Millimeter
}

export enum UserRoles {
  Admin = 1,
  ProductAdmin = 2,
  IntegrationSpecialist = 3,
  ReadOnly = 4,
  ApplicationAdmin = 5,
  PlatformAdmin = 6,
  IntegrationAdmin = 7
}

export class CurrencyCodes {
  public static readonly USD = 'USD';
}

export class ProductConstants {
  public static readonly GRID_NAME = 'name';
  public static readonly GRID_BRAND_NAME = 'brandName';
  public static readonly GRID_LAST_MODIFIED_DATE = 'lastModifiedDate';
  public static readonly GRID_STATUS = 'status';
  public static readonly GRID_GLOBAL_PRODUCT_OFFERINGS = 'globalProductOfferings';

  public static readonly GRID_DETAIL_IMAGE_URL = 'imageUrl';
  public static readonly GRID_DETAIL_TITLE = 'title';
  public static readonly GRID_DETAIL_TEMPLATE = 'template';
  public static readonly GRID_DETAIL_STATUS = 'status';
  public static readonly GRID_DETAIL_CONFIGURATION_COUNT = 'configurationsCount';
  public static readonly GRID_DETAIL_END_POINT_COUNT = 'endpointsCount';

  public static readonly GRID_ORGANIZATIONAL_DATA_NAME = 'name';
  public static readonly GRID_ORGANIZATIONAL_DATA_BRAND = 'brand';
  public static readonly GRID_ORGANIZATIONAL_DATA_BRANDS = 'brands';
  public static readonly GRID_ORGANIZATIONAL_DATA_SUBCATEGORIES = 'subcategories';
  public static readonly GRID_ORGANIZATIONAL_DATA_GLOBAL_SUBCATEGORIES = 'globalSubcategories';
  public static readonly GRID_ORGANIZATIONAL_DATA_INDUSTRY_TYPE = 'industryType';
  public static readonly GRID_ORGANIZATIONAL_DATA_CATEGORY = 'categoryName';
  public static readonly GRID_ORGANIZATIONAL_DATA_GLOBAL_CATEGORY = 'globalCategoryName';
  public static readonly GRID_ORGANIZATIONAL_DATA_SUBBRANDS = 'subbrands';
  public static readonly GRID_ORGANIZATIONAL_DATA_BRAND_NAME = 'brandName';
  public static readonly GRID_ORGANIZATIONAL_DATA_TYPE = 'type';
  public static readonly GRID_ORGANIZATIONAL_DATA_UPDATED = 'updated';
  public static readonly GRID_ORGANIZATIONAL_DATA_LAST_MODIFIED_DATE = 'lastModifiedDate';
  public static readonly GRID_ORGANIZATIONAL_DATA_ACTIONS = 'actions';
  public static readonly GRID_ORGANIZATIONAL_DATA_FAMILY = 'familyName';
  public static readonly GRID_ORGANIZATIONAL_DATA_ENVIRONMENT = 'environmentName';
  public static readonly GRID_ORGANIZATIONAL_DATA_WARRANTY = 'name';
  public static readonly GRID_ORGANIZATIONAL_DATA_APPLICATIONTAG = 'tag';
  public static readonly GRID_ORGANIZATIONAL_DATA_ATTRIBUTE_OPTION = 'name';

  public static readonly GRID_INTEGRATION_OFFERINGS_DETAIL_NAME = 'name';
  public static readonly GRID_INTEGRATION_OFFERINGS_DETAIL_TEMPLATE = 'template';
  public static readonly GRID_INTEGRATION_OFFERINGS_DETAIL_CONFIGURATIONS_COUNT = 'configurationsCount';
  public static readonly GRID_INTEGRATION_OFFERINGS_DETAIL_ENDPOINTS_COUNT = 'endpointsCount';
}

export class BrandConstants {
  public static readonly No_Associated_Brands = 'None';
}

export class ApplicationConstants {
  public static readonly GRID_ID = 'code';
  public static readonly GRID_NAME = 'name';
  public static readonly GRID_STATUS = 'status';
  public static readonly GRID_UPDATED = 'lastModifiedDate';
  public static readonly GRID_PRODUCTS = 'productCount';
  public static readonly GRID_ACTIONS = 'actions';
}

export class IntegrationsConstants {
  public static readonly GRID_INTEGRATION = 'name';
  public static readonly GRID_TEMPLATES = 'templatesCount';
  public static readonly GRID_OFFERINGS = 'globalProductOfferingsCount';
  public static readonly GRID_UPDATED = 'lastModifiedDate';

  public static readonly GRID_ENDPOINT_NAME = 'name';
  public static readonly GRID_ENDPOINT_TEMPLATE = 'template';
  public static readonly GRID_ENDPOINT_TAGS = 'tags';
  public static readonly GRID_ENDPOINT_LAST_MODIFIED_DATE = 'lastModifiedDate';
  public static readonly GRID_ENDPOINT_ACTIONS = 'actions';

  public static readonly GRID_USER_PERMISSIONS_NAME = 'name';
  public static readonly GRID_USER_PERMISSIONS_EMAIL = 'email';
  public static readonly GRID_USER_PERMISSIONS_PERMISSIONS = 'rolename';
  public static readonly GRID_USER_PERMISSIONS_LASTMODIFIEDDATE = 'lastModifiedDate';
}

export class CommonConstants {
  public static readonly IMG_MAX_SIZE = 5242880;
}

export class ConfigurationConstants {
  public static readonly ORGANIZATIONAL_DATA_CONFIGURATION_GRID_NAME = 'name';
  public static readonly ORGANIZATIONAL_DATA_CONFIGURATION_GRID_MODELFILESASSOCIATED = 'modelFilesAssociated';
  public static readonly ORGANIZATIONAL_DATA_CONFIGURATION_GRID_DIMENSIONSASSOCIATED = 'dimensionsAssociated';
  public static readonly ORGANIZATIONAL_DATA_CONFIGURATION_GRID_INTEGRATIONOWNER = 'integrationOwner';
  public static readonly ORGANIZATIONAL_DATA_CONFIGURATION_GRID_PRICINGDISABLED = 'pricingDisabled';
  public static readonly ORGANIZATIONAL_DATA_CONFIGURATION_GRID_PRICEDATE = 'priceDate';
}

export class CaslActions {
  public static readonly CREATE = 'create';
  public static readonly READ = 'read';
  public static readonly UPDATE = 'update';
  public static readonly DELETE = 'delete';
  public static readonly MANAGE = 'manage';
}

export class RegularExpressions {
  public static readonly VALID_URL = '(https?://)?([\\w.-]+)\\.([\\w.]{2,6})[/\\w .-?=&%-]*/?';
}

export class Remappings {
  public static readonly NOT_APPLICABLE = 'Not Applicable';
}

export enum Alert {
  Success = 'Success',
  Error = 'Error',
  Warning = 'Warning',
  Help = 'Help',
  Info = 'Info',
  Note = 'Note'
}

export enum DamProviderIds {
  UserEnteredMedia = 0,
  Bynder = 1,
  Aem = 2,
  Digizuite = 3,
  InRiver = 4
}

export enum MediaTypeIds {
  ThumbnailFrontView = 1,
  ThumbnailHoverView = 2,
  Masthead = 3,
  ImageGallery = 4,
  Videos = 5,
  DimensionalLineDrawing = 6
}

export enum MediaTypes {
  ThumbnailFrontView = 'Thumbnail Front View',
  ThumbnailHoverView = 'Thumbnail Hover View',
  Masthead = 'Masthead',
  ImageGallery = 'Image Gallery',
  Videos = 'Videos',
  DimensionalLineDrawing = 'Dimensional Line Drawing'
}

export enum ApplicationStatus {
  None,
  CreateSuccess,
  CreateFailure,
  UpdateSuccess,
  UpdateFailure,
  DeleteSuccess,
  DeleteFailure,
  DeleteOptionSuccess,
  DeleteOptionFailure,
}

export enum AttributeTypeStatus {
  None,
  CreateSuccess,
  CreateFailure,
  UpdateSuccess,
  UpdateFailure,
  RenameSuccess,
  RenameFailure,
  DeleteSuccess,
  DeleteFailure
}

export enum EndpointProductOfferingStatus {
  None,
  RemoveSuccess,
  RemoveFailure,
  UpdateSuccess,
  UpdateFailure,
  ApplyTagsSuccess,
  ApplyTagsFailure,
  RemoveTagsSuccess,
  RemoveTagsFailure
}

export enum GlobalConfigurationStatus {
  None,
  CreateSuccess,
  CreateFailure,
  DeleteSuccess,
  DeleteFailure,
  DuplicateSuccess,
  DuplicateFailure,
  RenameSuccess,
  RenameFailure,
  UpdateSuccess,
  UpdateFailure,
  UpdateSKUSuccess,
  UpdateSKUFailure,
  UpdateDimensionsSuccess,
  UpdateDimensionsFailure,
  UpdateModelFilesProfilesSuccess,
  UpdateModelFilesProfilesFailure,
  UpdateLeadtimesSuccess,
  UpdateLeadtimesFailure,
}

export enum GlobalProductStatus {
  None,
  CreateSuccess,
  CreateFailure,
  DeleteSuccess,
  DeleteFailure,
  RenameSuccess,
  RenameFailure,
  UpdateSuccess,
  UpdateFailure,
  DeleteConfigurationSuccess,
  DeleteConfigurationFailure,
  RenameConfigurationSuccess,
  RenameConfigurationFailure,
  DuplicateConfigurationSuccess,
  DuplicateConfigurationFailure,
}

export enum GlobalProductOfferingStatus {
  None,
  CreateSuccess,
  CreateFailure,
  UpdateSuccess,
  UpdateFailure,
  DeleteSuccess,
  DeleteFailure,
  RenameSuccess,
  RenameFailure,
}

export enum IntegrationStatus {
  None,
  CreateSuccess,
  CreateFailure,
  UpdateSuccess,
  UpdateFailure,
  RenameSuccess,
  RenameFailure
}

export enum TemplateStatus {
  None,
  CreateSuccess,
  CreateFailure,
  RenameSuccess,
  RenameFailure,
  DuplicateSuccess,
  DuplicateFailure,
}

export enum EndpointStatus {
  None,
  CreateSuccess,
  CreateFailure,
  RenameSuccess,
  RenameFailure,
  DuplicateSuccess,
  DuplicateFailure,
  DeleteSuccess,
  DeleteFailure,
  CreateEndpointProductOfferingTagSuccess,
  CreateEndpointProductOfferingTagFailure,
  UpdateEndpointProductOfferingTagSuccess,
  UpdateEndpointProductOfferingTagFailure,
  DeleteEndpointProductOfferingTagSuccess,
  DeleteEndpointProductOfferingTagFailure,
}

export enum DesignerStatus {
  None,
  CreateSuccess,
  CreateFailure,
  UpdateSuccess,
  UpdateFailure,
  RenameSuccess,
  RenameFailure,
  DeleteSuccess,
  DeleteFailure
}

export enum ContactStatus {
  None,
  CreateSuccess,
  CreateFailure,
  UpdateSuccess,
  UpdateFailure,
  RenameSuccess,
  RenameFailure,
  DeleteSuccess,
  DeleteFailure
}

export enum CategoryStatus {
  None,
  CreateSuccess,
  CreateFailure,
  UpdateSuccess,
  UpdateFailure,
  RenameSuccess,
  RenameFailure,
  DeleteSuccess,
  DeleteFailure
}

export enum GlobalCategoryStatus {
  None,
  CreateSuccess,
  CreateFailure,
  UpdateSuccess,
  UpdateFailure,
  RenameSuccess,
  RenameFailure,
  DeleteSuccess,
  DeleteFailure
}

export enum FamilyStatus {
  None,
  CreateSuccess,
  CreateFailure,
  UpdateSuccess,
  UpdateFailure,
  RenameSuccess,
  RenameFailure,
  DeleteSuccess,
  DeleteFailure
}
export enum PortfolioStatus {
  None,
  CreateSuccess,
  CreateFailure,
  UpdateSuccess,
  UpdateFailure,
  RenameSuccess,
  RenameFailure,
  DeleteSuccess,
  DeleteFailure
}

export enum BrandStatus {
  None,
  CreateSuccess,
  CreateFailure,
  UpdateSuccess,
  UpdateFailure,
  RenameSuccess,
  RenameFailure,
  DeleteSuccess,
  DeleteFailure
}

export enum WarrantyStatus {
  None,
  CreateSuccess,
  CreateFailure,
  UpdateSuccess,
  UpdateFailure,
  RenameSuccess,
  RenameFailure,
  DeleteSuccess,
  DeleteFailure
}

export enum ApplicationTagStatus {
  None,
  CreateSuccess,
  CreateFailure,
  UpdateSuccess,
  UpdateFailure,
  RenameSuccess,
  RenameFailure,
  DeleteSuccess,
  DeleteFailure
}

export enum SubcategoryStatus {
  None,
  CreateSuccess,
  CreateFailure,
  UpdateSuccess,
  UpdateFailure,
  RenameSuccess,
  RenameFailure,
  DeleteSuccess,
  DeleteFailure
}

export enum GlobalSubcategoryStatus {
  None,
  CreateSuccess,
  CreateFailure,
  UpdateSuccess,
  UpdateFailure,
  RenameSuccess,
  RenameFailure,
  DeleteSuccess,
  DeleteFailure
}

export enum SubBrandStatus {
  None,
  CreateSuccess,
  CreateFailure,
  UpdateSuccess,
  UpdateFailure,
  RenameSuccess,
  RenameFailure,
  DeleteSuccess,
  DeleteFailure
}

export enum UserPermissionStatus {
  None,
  CreateSuccess,
  CreateFailure,
  UpdateSuccess,
  UpdateFailure,
  DeleteSuccess,
  DeleteFailure
}

export enum ModelFileLinkTypes {
  AutoCad2D = 1,
  AutoCad3D = 2,
  Revit = 3,
  Sketchup = 4
}

export class ModelFilesProfilesConstants {
  public static readonly LIST_PAGE_SIZE: number = 10;
  public static readonly SELECT_PAGE_SIZE: number = 5;
}
export enum DamAssetsStatus {
  None
}

export class DamAssetMetapropertyConstants {
  public static readonly AssetPurpose = 'AssetPurpose';
  public static readonly MediaSubType = 'MediaSubType';
}

export enum DamProviders {
  Bynder = 1,
  AEM = 2
}

export enum ToggleImages {
  Omni = 1,
  Harbour = 2
}

export enum AttributeOptionTypes {
  Range = 1,
  Single = 2
}

//This is added to filter out the field 'ExludeFromPG' from other templates in a product and apply
//it only for Scout offerings
export enum HMTemplate {
  Id = 2
}

