import { Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModelFileLinkTypes } from '../../constants';
import { CadLinksModalDataSource } from './cad-links-modal.datasource';


@Component({
	selector: 'app-cad-links-modal',
	templateUrl: './cad-links-modal.component.html',
	styleUrls: ['./cad-links-modal.component.scss']
})
export class CadLinksModalComponent implements OnInit{

	public readonly existingCadLinkUrl: string;
	public readonly relatedProductNumbers: string[];
	selectedTabIndex: number;
	disableApplyButton = true;
	updatedValidCadLinkUrl: string = null;
	cadLinkTypeFlavorText: string;
	searchableLinkType: boolean;

	constructor(
		public dialogRef: MatDialogRef<CadLinksModalComponent>,
		public datasource: CadLinksModalDataSource,
		@Inject(MAT_DIALOG_DATA) public data: {
			searchTerm: string,
			relatedProductNumbers: string[],
			desiredLinkType: ModelFileLinkTypes,
			existingCadLinkUrl: string
		}
	) { 
		this.datasource.searchTerm = data.searchTerm;
		this.datasource.desiredLinkType = data.desiredLinkType;
		this.relatedProductNumbers = data.relatedProductNumbers;
		this.existingCadLinkUrl = data.existingCadLinkUrl;
	}

	ngOnInit() {
		// determine which type of model file link is desired for flavor text
		switch (this.datasource.desiredLinkType){
			case ModelFileLinkTypes.AutoCad2D: {
				this.cadLinkTypeFlavorText= 'Auto CAD 2D';
				break;
			}
			case ModelFileLinkTypes.AutoCad3D: {
				this.cadLinkTypeFlavorText= 'Auto CAD 3D';
				break;
			}
			case ModelFileLinkTypes.Revit: {
				this.cadLinkTypeFlavorText= 'Revit';
				break;
			}
			case ModelFileLinkTypes.Revit: {
				this.cadLinkTypeFlavorText= 'Sketchup';
				break;
			}
		}

		// check if the desired type of model files link can be looked up
		if (this.data.desiredLinkType == ModelFileLinkTypes.AutoCad2D ||
			this.data.desiredLinkType == ModelFileLinkTypes.AutoCad3D || 
			this.data.desiredLinkType == ModelFileLinkTypes.Revit) {
				this.searchableLinkType = true;
				
				// if an existing cad link url was passed in, start on the manual entry tab
				this.selectedTabIndex = this.data.existingCadLinkUrl.length > 0 ? 1 : 0;
		}
		else{
			this.searchableLinkType = false;
			this.selectedTabIndex = 1;
		}
	}

	updateValidCadLinkUrl(cadLinkUrl: string) {
		this.updatedValidCadLinkUrl = cadLinkUrl;
		this.disableApplyButton = cadLinkUrl ? false : true;
	}
}