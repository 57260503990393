import { SelectOptionGrouping } from './select-option-grouping';
import { SelectOption } from './select-option.model';
import { assign as _assign } from 'lodash-es';

export interface ISelectOptions {
  applicationTags: Array<SelectOption>;
  applicationOptionTags: Array<SelectOption>;
  applicationSifTypes: Array<SelectOption>;
  attributeTypes: Array<SelectOption>;
  attributeOptionTypes: Array<SelectOption>;
  brands: Array<SelectOption>;
  applicationStatuses: Array<SelectOption>;
  environments: Array<SelectOption>;
  brandRelationshipTypes: Array<SelectOption>;
  categories: Array<SelectOptionGrouping>;
  categoryList: Array<SelectOption>;
  designers: Array<SelectOption>;
  enclosures: Array<SelectOption>;
  rotations: Array<SelectOption>;
  families: Array<SelectOption>;
  flexibilities: Array<SelectOption>;
  globalCategories: Array<SelectOptionGrouping>;
  globalCategoryList: Array<SelectOption>;
  globalProductCertificationTypes: Array<SelectOption>;
  globalProductLinkTypes: Array<SelectOption>;
  industries: Array<SelectOption>;
  mediaTypes: Array<SelectOption>;
  numberOfPeoples: Array<SelectOption>;
  portfolios: Array<SelectOption>;
  productStatuses: Array<SelectOption>;
  postures: Array<SelectOption>;
  orientations: Array<SelectOption>;
  settings: Array<SelectOption>;
  settingTypes: Array<SelectOption>;
  storages: Array<SelectOption>;
  subBrands: Array<SelectOption>;
  technologies: Array<SelectOption>;
  teamTypes: Array<SelectOption>;
  warranties: Array<SelectOption>;
}

export class SelectOptions implements ISelectOptions {
  applicationTags: Array<SelectOption> = [];
  applicationOptionTags: Array<SelectOption> = [];
  applicationSifTypes: Array<SelectOption> = [];
  attributeTypes: Array<SelectOption> = [];
  attributeOptionTypes: Array<SelectOption> = [];
  brands: Array<SelectOption> = [];
  applicationStatuses: Array<SelectOption> = [];
  environments: Array<SelectOption> = [];
  brandRelationshipTypes: Array<SelectOption> = [];
  categories: Array<SelectOptionGrouping> = [];
  categoryList: Array<SelectOption> = [];
  designers: Array<SelectOption> = [];
  enclosures: Array<SelectOption> = [];
  rotations: Array<SelectOption> = [];
  families: Array<SelectOption> = [];
  flexibilities: Array<SelectOption> = [];
  globalCategories: Array<SelectOptionGrouping> = [];
  globalCategoryList: Array<SelectOption> = [];
  globalProductCertificationTypes: Array<SelectOption> = [];
  globalProductLinkTypes: Array<SelectOption> = [];
  industries: Array<SelectOption> = [];
  mediaTypes: Array<SelectOption> = [];
  mediaSubTypes: Array<SelectOption> = [];
  numberOfPeoples: Array<SelectOption> = [];
  portfolios: Array<SelectOption> = [];
  productStatuses: Array<SelectOption> = [];
  postures: Array<SelectOption> = [];
  orientations: Array<SelectOption> = [];
  settings: Array<SelectOption> = [];
  settingTypes: Array<SelectOption> = [];
  storages: Array<SelectOption> = [];
  subBrands: Array<SelectOption> = [];
  technologies: Array<SelectOption> = [];
  technologyLevels: Array<SelectOption> = [];
  teamTypes: Array<SelectOption> = [];
  warranties: Array<SelectOption> = [];
  leadTimes: Array<SelectOption> = [];

  constructor(data?: ISelectOptions) {
    if (!data) { return; }
    _assign(this, data);
  }

}
