import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import * as _ from 'lodash';
import { IRole, Role } from '../models/role.model';

@Injectable({
  providedIn: 'root'
})
export class UserRolesService {
  basePath = 'roles';
  constructor(private http: HttpClient) {
  }

  getRoles$(): Observable<Array<Role>> {
    return this.http
      .get(environment.apiUrl + `${this.basePath}`)
      .pipe(map(res => res)
        , catchError((err: Response) => {
          const obj = err;
          return observableThrowError(obj);
        })
        , map((list: Array<IRole>) => list.map(r => new Role(r)))
      );
  }
}
