import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-alert-message-card',
  templateUrl: './alert-message-card.component.html',
  styleUrls: ['./alert-message-card.component.scss']
})
export class AlertMessageCardComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() cssClassName: string;
  cssClasses: Array<string> = new Array<string>();

  constructor() {
   }

  ngOnInit() {
    this.cssClasses.push('alert-message');
    this.cssClasses.push(this.cssClassName);
  }
}
