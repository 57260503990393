<div>
  <div class="detail-header-title-text">
    <span *ngIf="title; else elseTitle">
      {{title}}
    </span>
    <ng-template #elseTitle>
      {{placeholder}}
    </ng-template>
  </div>
  <div
    *ngIf="this.model"
    class="detail-header-updated-on-text">
    <span
      [matTooltip]="getAuditableToolTip()"
      matTooltipPosition="after"
      matTooltipClass="tooltip-multiline">
      Updated On: {{ this.model.lastModifiedDate | date:'MMM d, y' }}
    </span>
  </div>
</div>
