import { IBaseAuditModel, BaseAuditModel } from './base-audit.model';

export interface IIntegrationContact extends IBaseAuditModel {
    name: string;
    emailAddress: string;
    role: string;
}

export class IntegrationContact extends BaseAuditModel {
    name: string;
    emailAddress: string;
    role: string;

    constructor(data?: IIntegrationContact) {
        if (data) {
        super(data);
        this.name = data.name;
        this.emailAddress = data.emailAddress;
        this.role = data.role;
        } else {
            super();
            this.name = '';
            this.emailAddress = '';
            this.role = '';
        }
    }
}
