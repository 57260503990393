import { Component, OnInit, Input } from '@angular/core';
import { CadLinks } from 'src/app/core/models/cad-links.model';
import { ModelFileLinkTypes } from 'src/app/shared/constants';


@Component({
	selector: 'app-cad-links-search-result',
	templateUrl: './cad-links-search-result.component.html',
	styleUrls: ['./cad-links-search-result.component.scss']
})
export class CadLinksSearchResultComponent implements OnInit{
	
	@Input() cadLinks: CadLinks;
	@Input() desiredLinkType: ModelFileLinkTypes;
	
	cadLinkUrl: string;
	cadLinkTypeFlavorText: string;
	cadLinkTypeImageSource: string;
	cadLinkTypeImageAltText: string;
	hasDesiredLinkType: boolean;

	ngOnInit() {
		// determine which type of model file link to display and return from the component
		switch (this.desiredLinkType){
			case ModelFileLinkTypes.AutoCad2D: {
				this.cadLinkUrl = this.cadLinks.dwgLink;
				this.cadLinkTypeImageSource = '../../../../../../../../../assets/images/file-types/icon-dwg.png';
				this.cadLinkTypeImageAltText = 'Auto CAD 2D Model File';
				this.cadLinkTypeFlavorText= 'Auto CAD 2D';
				this.hasDesiredLinkType = this.cadLinks.dwgLink.length > 0 && this.cadLinks.dwgLink != "None" ? true : false; 
				break;
			}
			case ModelFileLinkTypes.AutoCad3D: {
				this.cadLinkUrl = this.cadLinks.cadLink;
				this.cadLinkTypeImageSource = '../../../../../../../../../assets/images/file-types/icon-autodesk.png';
				this.cadLinkTypeImageAltText = 'Auto CAD 3D Model File';
				this.cadLinkTypeFlavorText= 'Auto CAD 3D';
				this.hasDesiredLinkType = this.cadLinks.cadLink.length > 0 && this.cadLinks.cadLink != "None" ? true : false;
				break;
			}
			case ModelFileLinkTypes.Revit: {
				this.cadLinkUrl = this.cadLinks.revitLink;
				this.cadLinkTypeImageSource = '../../../../../../../../../assets/images/file-types/icon-revit.png';
				this.cadLinkTypeImageAltText = 'Revit Model File';
				this.hasDesiredLinkType = this.cadLinks.revitLink.length > 0 && this.cadLinks.revitLink != "None" ? true : false;
				this.cadLinkTypeFlavorText= 'Revit';
				break;
			}
		}
	}
}
