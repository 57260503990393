import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import * as fromStore from '../../reducers';

import {
  SubBrandApiActions,
  SubBrandActions
} from '../actions';

import { switchMap, map, catchError, tap, mergeMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { SubcategoriesService } from 'src/app/core/services/subcategories.service';
import { SubBrandsService } from 'src/app/core/services/sub-brands.service';

@Injectable()
export class SubBrandEffects {

  @Effect()
  loadSubBrand$ = this.actions$.pipe(
    ofType(SubBrandActions.SubBrandActionTypes.LoadSubBrand),
    map((action: SubBrandActions.LoadSubBrand) => action.payload),
    switchMap(subBrandId =>
      this.subBrandsService.getSubBrand$(subBrandId).pipe(
        map(
          (subBrand) => new SubBrandApiActions.LoadSuccess(subBrand)
        ),
        catchError(error =>
          of(new SubBrandApiActions.LoadFailure(error))
        )
      )
    )
  );

  @Effect()
  createSubBrand$ = this.actions$.pipe(
    ofType<SubBrandActions.CreateSubBrand>(SubBrandActions.SubBrandActionTypes.CreateSubBrand),
    switchMap((request) =>
      this.subBrandsService.createSubBrand$(request.payload).pipe(
        map((subBrand) =>
          new SubBrandApiActions.CreateSuccess(subBrand)
        ),
        tap((response) => {
          // Navigate to the page in edit mode.
          this.router.navigate([`products/organizational-data/sub-brand-detail/${response.payload.id}`], { queryParams: { edit: true } });
        }),
        catchError(error =>
          of(new SubBrandApiActions.CreateFailure(error))
        )
      )
    )
  );

  @Effect()
  updateSubBrand$: Observable<Action> = this.actions$.pipe(
    ofType<SubBrandActions.UpdateSubBrand>(SubBrandActions.SubBrandActionTypes.UpdateSubBrand),
    switchMap(request =>
      this.subBrandsService.updateSubBrand$(request.payload.subBrand).pipe(
        map((response) => new SubBrandApiActions.UpdateSuccess({ subBrand: request.payload.subBrand, subBrandName: request.payload.subBrandName })),
        catchError(error => of(new SubBrandApiActions.UpdateFailure(error)))
      )
    )
  );

  @Effect()
  updateSubBrandSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(SubBrandApiActions.SubBrandApiActionTypes.UpdateSuccess),
    map((action: SubBrandApiActions.UpdateSuccess) => action.payload),
    map(payload =>
      // Reload the SubBrand from the API if the save was successful.
      new SubBrandActions.LoadSubBrand(payload.subBrand.id)
    )
  );

  @Effect()
  renameSubBrand$: Observable<Action> = this.actions$.pipe(
    ofType<SubBrandActions.RenameSubBrand>(SubBrandActions.SubBrandActionTypes.RenameSubBrand),
    switchMap((request) =>
      this.subBrandsService.renameSubBrand$({
        subBrandId: request.payload.subBrandId,
        subBrandName: request.payload.subBrandName
      }).pipe(
        map(_ => new SubBrandApiActions.RenameSuccess({
          subBrandId: request.payload.subBrandId,
          subBrandName: request.payload.subBrandName
        })
        ),
        tap(res => this.store.dispatch(new SubBrandActions.LoadSubBrand(request.payload.subBrandId)),
          catchError(error =>
            of(new SubBrandApiActions.RenameFailure(error)),
          )
        )
      )
    )
  );

  @Effect()
  deleteSubBrand$ = this.actions$.pipe(
    ofType<SubBrandActions.DeleteSubBrand>(SubBrandActions.SubBrandActionTypes.DeleteSubBrand),
    switchMap((request) =>
      this.subBrandsService.deleteSubBrand$(request.payload.subBrandId).pipe(
        map((response) =>
          new SubBrandApiActions.DeleteSuccess({ subBrandId: request.payload.subBrandId, subBrandName: request.payload.subBrandName })
        ),
        tap((response) => {
          // Navigate to all SubBrands page
          this.router.navigate([`products/organizational-data/sub-brands`]);
        }),
        catchError(error =>
          of(new SubBrandApiActions.DeleteFailure(error))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<fromStore.State>,
    private subBrandsService: SubBrandsService) { }

}
