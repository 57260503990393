import { SnackBarService } from './../../../core/services/snackbar.service';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';

import {
  EndpointsApiActions,
  EndpointsActions
} from '../actions';

import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { switchMap, map, tap, catchError } from 'rxjs/operators';
import { EndpointsService } from 'src/app/core/services/endpoints.service';


@Injectable()
export class EndpointsEffects {

  constructor(
    private actions$: Actions,
    private endpointsService: EndpointsService,
    private snackBarService: SnackBarService,
    private router: Router) {}

  @Effect()
  loadEndpointsSummaryCollection$: Observable<Action> = this.actions$.pipe(
    ofType<EndpointsActions.LoadEndpoints>(EndpointsActions.EndpointsActionTypes.LoadEndpoints),
    switchMap(() =>
      this.endpointsService.getEndpointsSummaries$().pipe(
        map((endpoints) => new EndpointsApiActions.LoadSuccess(endpoints)),
        catchError(error =>
          of(new EndpointsApiActions.LoadFailure(error))
        )
      )
    )
  );

  @Effect()
  createEndpoint$: Observable<Action> = this.actions$.pipe(
    ofType<EndpointsActions.CreateEndpoint>(EndpointsActions.EndpointsActionTypes.CreateEndpoint),
    switchMap((request) =>
      this.endpointsService.createEndpoint$(request.payload).pipe(
        tap((e) => {
          // Navigate to the page in edit mode.
          this.router.navigate([`integrations/endpoint-detail/${e.id}`]);
        }),
        map((createdEndpoint) => new EndpointsApiActions.CreateSuccess(createdEndpoint)),
        catchError(error =>
          of(new EndpointsApiActions.CreateFailure(error))
        )
      )
    )
  );

  @Effect()
  renameEndpoint$: Observable<Action> = this.actions$.pipe(
    ofType<EndpointsActions.RenameEndpoint>(EndpointsActions.EndpointsActionTypes.RenameEndpoint),
    switchMap((request) =>
      this.endpointsService.renameEndpoint$({
          endpointId: request.payload.endpointId,
          endpointName: request.payload.endpointName
        }).pipe(
        map(_ => new EndpointsApiActions.RenameSuccess({
          integrationId: request.payload.integrationId,
          endpointId: request.payload.endpointId,
          endpointName: request.payload.endpointName
        })),
        catchError(error =>
          of(new EndpointsApiActions.RenameFailure(error)),
        )
      )
    )
  );

  @Effect()
  duplicateEndpoint$: Observable<Action> = this.actions$.pipe(
    ofType<EndpointsActions.DuplicateEndpoint>(EndpointsActions.EndpointsActionTypes.DuplicateEndpoint),
    switchMap((request) =>
      this.endpointsService.duplicateEndpoint$(request.payload).pipe(
        map((duplicatedEndpoint) => new EndpointsApiActions.DuplicateSuccess(duplicatedEndpoint)
        ),
        catchError(error =>
          of(new EndpointsApiActions.DuplicateFailure(error))
        )
      )
    )
  );

  @Effect()
  deleteEndpoint$: Observable<Action> = this.actions$.pipe(
    ofType<EndpointsActions.DeleteEndpoint>(EndpointsActions.EndpointsActionTypes.DeleteEndpoint),
    switchMap((request) =>
      this.endpointsService.deleteEndpoint$(request.payload).pipe(
        map(() => new EndpointsApiActions.DeleteSuccess(request.payload)),
        catchError(error =>
          of(new EndpointsApiActions.DeleteFailure(error))
        )
      )
    )
  );
}
