import { Action } from '@ngrx/store';
import { ApplicationTagPost } from 'src/app/core/models/application-tag-post.model';
import { ApplicationTagPut } from 'src/app/core/models/application-tag-put.model';



export enum ApplicationTagActionTypes {
  LoadApplicationTag = '[ApplicationTag] Load ApplicationTag',
  CreateApplicationTag = '[ApplicationTag] Create ApplicationTag',
  UpdateApplicationTag = '[ApplicationTag] Update ApplicationTag',
  RenameApplicationTag = '[ApplicationTag] Rename ApplicationTag',
  DeleteApplicationTag = '[ApplicationTag] Delete ApplicationTag',
  ClearMessages = '[ApplicationTag] Clear Messages',
}

export class LoadApplicationTag implements Action {
  readonly type = ApplicationTagActionTypes.LoadApplicationTag;

  constructor(public payload: number) { }
}

export class CreateApplicationTag implements Action {
  readonly type = ApplicationTagActionTypes.CreateApplicationTag;

  constructor(public payload: ApplicationTagPost) { }
}

export class UpdateApplicationTag implements Action {
  readonly type = ApplicationTagActionTypes.UpdateApplicationTag;

  constructor(public payload: { applicationTag: ApplicationTagPut, applicationTagName: string }) { }
}

export class RenameApplicationTag implements Action {
  readonly type = ApplicationTagActionTypes.RenameApplicationTag;

  constructor(public payload: { applicationTagId: number, applicationTagName: string }) { }
}

export class DeleteApplicationTag implements Action {
  readonly type = ApplicationTagActionTypes.DeleteApplicationTag;

  constructor(public payload: { applicationTagId: number, applicationTagName: string }) { }
}

export class ClearMessages implements Action {
  readonly type = ApplicationTagActionTypes.ClearMessages;

  constructor() { }
}

export type ApplicationTagActionsUnion =
  LoadApplicationTag
  | CreateApplicationTag
  | UpdateApplicationTag
  | RenameApplicationTag
  | DeleteApplicationTag
  | ClearMessages;
