import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import { assign as _assign } from 'lodash-es';

export interface IApplicationSummary extends IBaseAuditModel {
  name: string;
  code: string;
  shortDescription: string;
  longDescription: string;
  applicationStatus: string;
  productCount: number;
  imageUrl: string;
}

export class ApplicationSummary extends BaseAuditModel implements IApplicationSummary {
  name: string;
  code: string;
  shortDescription: string;
  longDescription: string;
  applicationStatus: string;
  productCount: number;
  imageUrl: string;

  constructor(data?: IApplicationSummary) {
    super(data);

    if (data) {
      _assign(this, data);
    }
  }
}
