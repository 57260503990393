import { IGlobalProductQueryPageRequest } from './../models/global-product-query-page-request.model';
import { GlobalProductAttributeOptionValuePut, GlobalProductPut } from './../models/global-product-put.model';
import { PagedResults } from './../models/page-results.model';
import { GlobalProductSummary, IGlobalProductSummary } from '../models/global-product-summary.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { IGlobalProduct, GlobalProduct } from '../models/global-product.model';
import { environment } from 'src/environments/environment';
import { catchError, finalize, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError as observableThrowError, throwError } from 'rxjs';
import { GlobalConfiguration } from '../models/global-configuration.model';
import { RenamePut } from '../models/rename-put.model';
import { GlobalProductDuplicateCheck } from '../models/global-product-duplicate-check.model';
import { IProductGuidanceAttribute, ProductGuidanceAttribute } from '../models/product-guidance-attribute.model';


@Injectable({
  providedIn: 'root'
})
export class GlobalProductsService {
  basePath = 'globalproducts';
  configurationsBasePath = 'globalConfigurations';
  constructor(private http: HttpClient) { }

  public getGlobalProducts$(query: IGlobalProductQueryPageRequest): Observable<PagedResults<GlobalProductSummary>> {
    if (query.sortOrder === '') {
      query.orderBy = 'name';
      query.sortOrder = 'asc';
    }

    let params = new HttpParams()
      .set('search', query.searchTerm)
      .set('pageNumber', query.pageNumber.toString())
      .set('pageSize', query.pageSize.toString())
      .set('orderBy', query.orderBy)
      .set('sortOrder', query.sortOrder);

    if (query.brandIds?.length > 0) {
      query.brandIds.forEach(x => {
        params = params.append('brandIds', x.toString());
      });
    }

    if (query.categoryIds?.length > 0) {
      query.categoryIds.forEach(x => {
        params = params.append('categoryIds', x.toString());
      });
    }

    if (query.contactIds?.length > 0) {
      query.contactIds.forEach(x => {
        params = params.append('contactIds', x.toString());
      });
    }

    if (query.designerIds?.length > 0) {
      query.designerIds.forEach(x => {
        params = params.append('designerIds', x.toString());
      });
    }

    if (query.familyIds?.length > 0) {
      query.familyIds.forEach(x => {
        params = params.append('familyIds', x.toString());
      });
    }

    if (query.globalCategoryIds?.length > 0) {
      query.globalCategoryIds.forEach(x => {
        params = params.append('globalCategoryIds', x.toString());
      });
    }

    if (query.globalSubcategoryIds?.length > 0) {
      query.globalSubcategoryIds.forEach(x => {
        params = params.append('globalSubcategoryIds', x.toString());
      });
    }

    if (query.portfolioIds?.length > 0) {
      query.portfolioIds.forEach(x => {
        params = params.append('portfolioIds', x.toString());
      });
    }

    if (query.subbrandIds?.length > 0) {
      query.subbrandIds.forEach(x => {
        params = params.append('subBrandIds', x.toString());
      });
    }

    if (query.subcategoryIds?.length > 0) {
      query.subcategoryIds.forEach(x => {
        params = params.append('subcategoryIds', x.toString());
      });
    }

    if (query.warrantyIds?.length > 0) {
      query.warrantyIds.forEach(x => {
        params = params.append('warrantyIds', x.toString());
      });
    }

    return this.http.get(environment.apiUrl + `${this.basePath}`, { params }).pipe(
      map((response: PagedResults<GlobalProductSummary>) => response)
    );
  }

  // Used with sidebar searching.
  getGlobalProductsByName$(searchTerm: string): Observable<Array<GlobalProductSummary>> {
    let params = new HttpParams()
      .set('name', searchTerm);

    return this.http.get(`${environment.apiUrl}${this.basePath}/names`, { params }).pipe(
      map((response: Array<GlobalProductSummary>) => response)
    );
  }

  getGlobalProduct$(id: number): Observable<GlobalProduct> {
    return this.http
      .get(environment.apiUrl + `${this.basePath}/${id}`)
      .pipe(
        map(response => response),
        map((x: IGlobalProduct) => new GlobalProduct(x))
      );
  }

  deleteGlobalProduct$(product: GlobalProduct): Observable<GlobalProduct> {
    return this.http
      .delete(environment.apiUrl + `${this.basePath}/${product.id}`)
      .pipe(
        map(response => product)
        , catchError((err: Response) => {
          const obj = err;
          return observableThrowError(obj);
        })
      );
  }

  renameProduct$(product: GlobalProduct, newName: string): Observable<GlobalProduct> {
    const renamePut: RenamePut = new RenamePut({ name: newName });
    return this.http
      .put(
        environment.apiUrl + `${this.basePath}/${product.id}/rename`,
        renamePut,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
      .pipe(
        map(response => product)
        , catchError((err: Response) => {
          return observableThrowError(err);
        })
      );
  }

  createGlobalProduct$(product: GlobalProduct): Observable<GlobalProduct> {
    return this.http
      .post(environment.apiUrl + `${this.basePath}`, product)
      .pipe(map(res => res)
        , catchError((err: Response) => {
          const obj = err;
          return observableThrowError(obj);
        })
        , map((x: IGlobalProduct) => new GlobalProduct(x)));
  }

  updateGlobalProduct$(globalProductPut: GlobalProductPut): Observable<Object> {
    return this.http
      .put(`${environment.apiUrl}${this.basePath}/${globalProductPut.id}`, globalProductPut)
      .pipe(
        map(response => response),
        catchError((err: Response) => {
          console.log('Error on product update:');
          console.log(err);
          return observableThrowError(err);
        })
      );
  }


  getDuplicates$(globalProductName: string): Observable<Array<GlobalProductSummary>> {
    const request: GlobalProductDuplicateCheck = new GlobalProductDuplicateCheck();
    request.name = globalProductName;

    return this.http
      .post(`${environment.apiUrl}${this.basePath}/duplicates`, request)
      .pipe(
        map(response => response),
        catchError((error: Response) => {
          return observableThrowError(error);
        }),
        map((response: Array<GlobalProductSummary>) => response)
      );
  }

  getProductGuidanceAttributes$(id: number): Observable<Array<ProductGuidanceAttribute>> {
    return this.http
      .get(environment.apiUrl + `${this.basePath}/${id}/productguidanceattributes`)
      .pipe(
        map(response => response),
        map((response: Array<IProductGuidanceAttribute>) => response)
      );
  }

  addGlobalProductAttributeOption$(globalProductId: number, globalProductAttributeOptionValuePut: GlobalProductAttributeOptionValuePut): Observable<HttpResponse<Object>> {
    const formData: FormData = new FormData();
    if (globalProductAttributeOptionValuePut?.attributeOption?.iconUpload != null) {
      formData.append('iconFile', globalProductAttributeOptionValuePut?.attributeOption?.iconUpload, globalProductAttributeOptionValuePut?.attributeOption?.iconName);
    }
    formData.append('globalProductAttributeOption', JSON.stringify(globalProductAttributeOptionValuePut));
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    const params = new HttpParams().set('globalProductId', globalProductId.toString());
    return this.http
      .post(environment.apiUrl + `${this.basePath}/attributeoption/${globalProductId}`, formData, { observe: 'response' })
      .pipe(
        map(response => {
          return response;
        }),
        catchError((err: any) => {
          return throwError(err);
        }),
        finalize(() => {
        })
      );
  }
}
