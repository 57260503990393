import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import * as fromStore from '../../reducers';

import {
  DesignerApiActions,
  DesignerActions
} from '../actions';

import { switchMap, map, catchError, tap, mergeMap } from 'rxjs/operators';
import { DesignersService } from 'src/app/core/services/designers.service';
import { of, Observable } from 'rxjs';

@Injectable()
export class DesignerEffects {

  @Effect()
  loadDesigner$ = this.actions$.pipe(
    ofType(DesignerActions.DesignerActionTypes.LoadDesigner),
    map((action: DesignerActions.LoadDesigner) => action.payload),
    switchMap(designerId =>
      this.designersService.getDesigner$(designerId).pipe(
        map(
          (designer) => new DesignerApiActions.LoadSuccess(designer)
        ),
        catchError(error =>
          of(new DesignerApiActions.LoadFailure(error))
        )
      )
    )
  );

  @Effect()
  createDesigner$ = this.actions$.pipe(
    ofType<DesignerActions.CreateDesigner>(DesignerActions.DesignerActionTypes.CreateDesigner),
    switchMap((request) =>
      this.designersService.createDesigner$(request.payload).pipe(
        map((designer) =>
          new DesignerApiActions.CreateSuccess(designer)
        ),
        tap((response) => {
          // Navigate to the page in edit mode.
          this.router.navigate([`products/organizational-data/designer-detail/${response.payload.id}`], { queryParams: { edit: true } });
        }),
        catchError(error =>
          of(new DesignerApiActions.CreateFailure(error))
        )
      )
    )
  );

  @Effect()
  updateDesigner$: Observable<Action> = this.actions$.pipe(
    ofType<DesignerActions.UpdateDesigner>(DesignerActions.DesignerActionTypes.UpdateDesigner),
    switchMap(request =>
      this.designersService.updateDesigner$(request.payload.designer).pipe(
        map((response) => new DesignerApiActions.UpdateSuccess({ designer: request.payload.designer, designerName: request.payload.designerName })),
        catchError(error => of(new DesignerApiActions.UpdateFailure(error)))
      )
    )
  );

  @Effect()
  updateDesignernSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(DesignerApiActions.DesignerApiActionTypes.UpdateSuccess),
    map((action: DesignerApiActions.UpdateSuccess) => action.payload),
    map(payload =>
      // Reload the designer from the API if the save was successful.
      new DesignerActions.LoadDesigner(payload.designer.id)
    )
  );

  @Effect()
  renameDesigner$: Observable<Action> = this.actions$.pipe(
    ofType<DesignerActions.RenameDesigner>(DesignerActions.DesignerActionTypes.RenameDesigner),
    switchMap((request) =>
      this.designersService.renameDesigner$({
        designerId: request.payload.designerId,
        designerName: request.payload.designerName
      }).pipe(
        map(_ => new DesignerApiActions.RenameSuccess({
          designerId: request.payload.designerId,
          designerName: request.payload.designerName
        })
        ),
        tap(res => this.store.dispatch(new DesignerActions.LoadDesigner(request.payload.designerId)),
          catchError(error =>
            of(new DesignerApiActions.RenameFailure(error)),
          )
        )
      )
    )
  );

  @Effect()
  deleteDesigner$ = this.actions$.pipe(
    ofType<DesignerActions.DeleteDesigner>(DesignerActions.DesignerActionTypes.DeleteDesigner),
    switchMap((request) =>
      this.designersService.deleteDesigner$(request.payload.designerId).pipe(
        map((response) =>
          new DesignerApiActions.DeleteSuccess({ designerId: request.payload.designerId, designerName: request.payload.designerName })
        ),
        tap((response) => {
          // Navigate to all designers page
          this.router.navigate([`products/organizational-data/designers`]);
        }),
        catchError(error =>
          of(new DesignerApiActions.DeleteFailure(error))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<fromStore.State>,
    private designersService: DesignersService) { }

}
