<div>
  <div mat-dialog-title>
    <span class="h6"><strong>{{ data.title }}</strong></span>
  </div>

  <div mat-dialog-content>
    <p>{{ data.message }}</p>

    <div
      *ngIf="this.data.enableConfirmation"
      fxLayout="row"
      class="alert-message Error">

      <div fxFlex="nogrow">
        <mat-icon class="warning-icon">warning</mat-icon>
      </div>

      <div
        fxFlex="60%"
        class="align-center-vertical warning-icon-alignment">
          <div *ngIf="this.data.showIrreversibleWarning">
            <strong>Danger:&nbsp;</strong>
            This action is irreversible. Data will not be recoverable. Confirm the action to unlock the button.
          </div>

          <div *ngIf="!this.data.showIrreversibleWarning">
            <strong>Warning:&nbsp;</strong>
            This action is not trivial to undo once committed. Confirm the action to unlock the button.
          </div>
      </div>

      <div fxFlex fxLayoutAlign="end center">
        <mat-slide-toggle
          labelPosition="after"
          [checked]="isConfirmedByUser"
          (change)="slideToggleChange($event)">
          <strong>Confirm Action</strong>
        </mat-slide-toggle>
      </div>
    </div>
  </div>

  <div mat-dialog-actions>
    <button
      *ngIf="data.enableCancelButton"
      class="mat-raised-button button-spacing"
      (click)="confirmAction(false)">Cancel</button>
    <button
      [matBadge]="data.badgeText"
      [matBadgeHidden]="data.badgeHidden"
      matBadgePosition="below"
      [disabled]="!enableAction()"
      class="mat-raised-button"
      (click)="confirmAction(true)"
      [ngClass]="getButtonClass()">
      {{ data.buttonText }}
    </button>
  </div>
</div>
