import { RegionsService } from './../../../core/services/regions.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { Region } from 'src/app/core/models/region.model';
import { Locale } from 'src/app/core/models/locale.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import * as _ from 'lodash';

@Component({
  selector: 'app-locale-selector-modal',
  templateUrl: './locale-selector-modal.component.html',
  styleUrls: ['./locale-selector-modal.component.scss']
})
export class LocaleSelectorModalComponent implements OnInit, AfterViewInit {
  regions: Array<Region> = [];
  selectedLocales: Array<Locale> = [];
  modalTitle: string;
  buttonText: string;

  // Fixes an issue with angular material expanders in modals where the expander expands on load then collapses.
  // This disables animations until after ngAfterInit and then reactivates them.
  // The @.disabled in the html and ngAfterInit also are part of this fix.
  // https://stackblitz.com/edit/angular-issue13870-workaround?file=src%2Fapp%2Fdialog-demo%2Fdialog-demo.component.ts
  disableAnimation = true;

  constructor(
    public dialogRef: MatDialogRef<LocaleSelectorModalComponent>,
    private regionsService: RegionsService,
    @Inject(MAT_DIALOG_DATA) public data: {
      regions: Array<Region>,
      selectedLocales: Array<Locale>,
      modalTitle: string,
      buttonText: string
    }) {
      if (this.data && this.data.selectedLocales) {
        this.selectedLocales = _.cloneDeep(this.data.selectedLocales);
        this.regions = _.cloneDeep(this.data.regions);

        // Remove any locales from selected locales that do not belong in the available regions/locales collection.
        this.limitSelectedLocalesByAvailableLocales();
      }

      if (!this.data?.modalTitle || this.data?.modalTitle?.length <= 0) {
        this.modalTitle = 'Locales Available In';
      }
      else {
        this.modalTitle = this.data.modalTitle;
      }

      if (!this.data?.buttonText || this.data?.buttonText?.length <= 0) {
        this.buttonText = 'Select';
      }
      else {
        this.buttonText = this.data.buttonText;
      }
    }

  ngOnInit(): void {
    // if (this.data && this.data.selectedLocales) {
    //   this.selectedLocales = _.cloneDeep(this.data.selectedLocales);
    //   this.regions = _.cloneDeep(this.data.regions);

    //   // Remove any locales from selected locales that do not belong in the available regions/locales collection.
    //   this.limitSelectedLocalesByAvailableLocales();
    // }
  }

  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => this.disableAnimation = false);
  }

  public applySelections(): void {
    this.dialogRef.close(this.selectedLocales);
  }

  public onRegionChange(matCheckboxChange: MatCheckboxChange, region: Region): void {
    if (matCheckboxChange.checked) {
      region.locales.forEach(locale => {
        const existing = this.selectedLocales.find(l => l.id === locale.id);

        if (!existing) {
          this.selectedLocales.push(locale);
        }
      });
    } else {
      region.locales.forEach(locale => {
        const existing = this.selectedLocales.find(l => l.id === locale.id);

        if (existing) {
          this.selectedLocales = this.selectedLocales.filter(l => l.id !== existing.id);
        }
      });
    }
  }

  public onLocaleChange(matCheckboxChange: MatCheckboxChange, locale: Locale): void {
    if (matCheckboxChange.checked) {
      this.selectedLocales.push(locale);
    } else {
      const existing = this.selectedLocales.find(l => l.id === locale.id);
      this.selectedLocales = this.selectedLocales.filter(l => l.id !== existing.id);
    }
  }

  public getHeaderIndeterminateDash(region: Region): boolean {
    const totalCount = region.locales.length;
    let locales: Locale[] = [];

    locales = this.selectedLocales.filter(l => l.regionId === region.id);

    if (locales.length > 0 && locales.length !== totalCount) {
      return true;
    }

    return false;
  }

  public getHeaderSelectedLocalesCount(region: Region): number {
    const locales = this.selectedLocales.filter(l => l.regionId === region.id);
    return locales.length;
  }

  public getHeaderChecked(region: Region): boolean {
    const totalCount = region.locales.length;
    let locales: Locale[] = [];

    locales = this.selectedLocales.filter(l => l.regionId === region.id);

    if (locales.length !== totalCount) {
      return false;
    }

    return true;
  }

  public getChecked(locale: Locale) {
    return this.selectedLocales.find(l => l.id === locale.id);
  }

  // Restrict selected locales to that of the locales the regions has.
  // Use Case: Configuration has 3 locales: US, UK, and Mexico.  Product only has US and UK.  We need to remove mexico because the product for the configuration
  // does not allow for mexico to be in the selected locales.
  private limitSelectedLocalesByAvailableLocales(): void {
      const availableLocales: Array<Locale> = this.regionsService.getAllLocalesFromRegions(this.regions);

      this.selectedLocales = this.selectedLocales.filter(selectedLocale =>
        availableLocales.some(availableLocale => availableLocale.id === selectedLocale.id)
      );
  }
}
