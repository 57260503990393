import { Subcategory, ISubcategory } from './subcategory.model';
import { ImageLink, IImageLink } from './image-link.model';
import { assign as _assign } from 'lodash-es';
import { IBaseAuditModel, BaseAuditModel } from './base-audit.model';

export interface ICategory extends IBaseAuditModel {
  name: string;
  description: string;
  categoryImages: IImageLink[];
  subcategories: ISubcategory[];
}

export class Category extends BaseAuditModel implements ICategory {
  name: string;
  description: string;
  categoryImages: ImageLink[];
  subcategories: Subcategory[];

  constructor(data?: ICategory) {
    super(data);
    if (!data) {
      return;
    }
    _assign(this, data);
  }
}
