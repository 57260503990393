import { LocaleSpecifier } from './../../../core/models/locale-specifier.model';
import { Action } from '@ngrx/store';

export enum LocaleSpecifierApiActionTypes {
  LoadSuccess           = '[LocaleSpecifiers/API] Fetch Success',
  LoadFailure           = '[LocaleSpecifiers/API] Fetch Failure',
}

export class LoadSuccess implements Action {
  readonly type = LocaleSpecifierApiActionTypes.LoadSuccess;

  constructor(public payload: Array<LocaleSpecifier>) { }
}

export class LoadFailure implements Action {
  readonly type = LocaleSpecifierApiActionTypes.LoadFailure;

  constructor(public payload: any) { }
}

export type LocaleSpecifierApiActionsUnion =
    LoadSuccess
  | LoadFailure;
