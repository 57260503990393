import { SelectOptions } from './../../../core/models/select-options.model';
import { SelectOptionsService } from './../../../core/services/select-options.service';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { SelectOptionsActions, SelectOptionsApiActions } from '../actions';
import { of } from 'rxjs';
@Injectable()
export class SelectOptionsEffects {
  loadSelectOptions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SelectOptionsActions.SelectOptionsActionTypes.LoadSelectOptions),
      switchMap(() =>
        this.selectOptionsService.getSelectOptions$().pipe(
          map((selectOptions: SelectOptions) => new SelectOptionsApiActions.LoadSuccess(selectOptions)),
          catchError(error =>
            of(new SelectOptionsApiActions.LoadFailure(error))
          )
        )
      )
    );
  });

  loadSelectOptionsByCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SelectOptionsActions.SelectOptionsActionTypes.LoadSelectOptionsByCode),
      map((action: SelectOptionsActions.LoadSelectOptionsByCode) => action.payload),
      switchMap((code) =>
        this.selectOptionsService.getSelectOptions$([code]).pipe(
          map((selectOptions: SelectOptions) => new SelectOptionsApiActions.LoadSuccess(selectOptions)),
          catchError(error =>
            of(new SelectOptionsApiActions.LoadFailure(error))
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private selectOptionsService: SelectOptionsService) { }
}
