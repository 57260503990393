import { UserPut } from './../../../core/models/user-put.model';
import { User } from './../../../core/models/user.model';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import * as fromStore from '../../../app-store/reducers';
import {
  UserApiActions,
  UserActions
} from '../actions';

import { switchMap, map, catchError, tap, mergeMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { UserPermissionsService } from 'src/app/core/services/user-permissions.service';
import { UserPermissionsGridService } from 'src/app/core/services/user-permissions-grid.service';
import { IUserPermissionsQuery } from 'src/app/core/models/user-permissions-query.model';

@Injectable()
export class UserEffects {
  @Effect()
  loadUsers$ = this.actions$.pipe(
    ofType(UserActions.UserActionTypes.LoadUsers),
    map((action: UserActions.LoadUsers) => action.payload),
    switchMap(requestParams =>
      this.userPermissionsService.getUsers$(requestParams)
        .pipe(map((user) => new UserApiActions.LoadPageSuccess(user)),
          catchError(error =>
            of(new UserApiActions.LoadPageFailure(error))
          )
        )
    )
  );

  @Effect()
  loadUser$ = this.actions$.pipe(
    ofType(UserActions.UserActionTypes.LoadUser),
    map((action: UserActions.LoadUser) => action.payload),
    switchMap((userId: number) =>
      this.userPermissionsService.getUser$(userId).pipe(
          map((user: User) => new UserApiActions.LoadSuccess(user)),
          catchError(error =>
            of(new UserApiActions.LoadFailure(error))
          )
        )
    )
  );

  @Effect()
  createUser$ = this.actions$.pipe(
    ofType<UserActions.CreateUser>(UserActions.UserActionTypes.CreateUser),
    switchMap((request) =>
      this.userPermissionsService.createUser$(request.payload).pipe(
        map((user) =>
          new UserApiActions.CreateSuccess(user)
        ),
        tap((response) => {
          const queryParams: IUserPermissionsQuery = {
            searchTerm: this.userPermissionsGridService.searchTerm,
            pageNumber: this.userPermissionsGridService.pageNumber,
            pageSize: this.userPermissionsGridService.pageSize,
            sortActive: this.userPermissionsGridService.sortActive,
            sortDirection: this.userPermissionsGridService.sortDirection
          };
          // Reload users from API if create is successful
          this.store.dispatch(new UserActions.LoadUsers(queryParams));
        }),
        catchError(error =>
          of(new UserApiActions.CreateFailure(error))
        )
      )
    )
  );

  @Effect()
  updateUser$: Observable<Action> = this.actions$.pipe(
    ofType<UserActions.UpdateUser>(UserActions.UserActionTypes.UpdateUser),
    switchMap(request =>
      this.userPermissionsService.updateUser$(request.payload.user).pipe(
        map((response) => new UserApiActions.UpdateSuccess(request.payload.user)),
        catchError(error => of(new UserApiActions.UpdateFailure(error))),
        tap((response) => {
          const queryParams: IUserPermissionsQuery = {
            searchTerm: this.userPermissionsGridService.searchTerm,
            pageNumber: this.userPermissionsGridService.pageNumber,
            pageSize: this.userPermissionsGridService.pageSize,
            sortActive: this.userPermissionsGridService.sortActive,
            sortDirection: this.userPermissionsGridService.sortDirection
          };
          // Reload users from API if update is successful/failure
          this.store.dispatch(new UserActions.LoadUsers(queryParams));
        })
      )
    )
  );

  @Effect()
  updateUserSuccess$ = this.actions$.pipe(
    ofType(UserApiActions.UserApiActionTypes.UpdateSuccess),
    map((action: UserApiActions.UpdateSuccess) => action.payload),
    map((user: UserPut) =>
      // Reload the product from the API if the save was successful.
      new UserActions.LoadUser(user.id)
    )
  );

  @Effect()
  deleteUser$ = this.actions$.pipe(
    ofType<UserActions.DeleteUser>(UserActions.UserActionTypes.DeleteUser),
    switchMap((request) =>
      this.userPermissionsService.deleteUser$(request.payload.userId).pipe(
        map((response) =>
          new UserApiActions.DeleteSuccess({ userId: request.payload.userId, userName: request.payload.userName })
        ),
        catchError(error =>
          of(new UserApiActions.DeleteFailure(error))
        ),
        tap(() => {
          // Navigate to the configuration's product configuration list.
          this.router.navigate([`admin/all-users`]);
        }),
      )
    )
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<fromStore.State>,
    private userPermissionsService: UserPermissionsService,
    private userPermissionsGridService: UserPermissionsGridService) { }

}
