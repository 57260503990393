import { DamAssetMetapropertyConstants, DamAssetsStatus } from 'src/app/shared/constants';
import { DamAssetMetaproperty } from 'src/app/core/models/dam-asset-metaproperty.model';
import { DamAssetMetapropertyOption } from 'src/app/core/models/dam-asset-metaproperty-option.model';
import { DamAssetsMetapropertyActions, DamAssetsMetapropertyApiActions } from '../actions';
import { MediaSubType } from 'src/app/core/models/media-subtype.model';

export interface State {
  loading: boolean;
  loaded: boolean;
  assetMetaproperty: DamAssetMetaproperty;
  assetMetapropertyOptions: Array<DamAssetMetapropertyOption>;
  assetPurposeMetaproperty: DamAssetMetaproperty;
  mediaSubtypeMetaproperty: DamAssetMetaproperty;
  assetPurposeMetaPropertyOptions: Array<DamAssetMetapropertyOption>;
  mediaSubtypeMetaPropertyOptions: Array<DamAssetMetapropertyOption>;
  assetPurposeMetaPropertyOmniOptions: Array<DamAssetMetapropertyOption>;
  mediaSubtypes: Array<MediaSubType>;
  mediaSubTypesLoaded: boolean;
  mediaSubTypesLoading: boolean;
  status: DamAssetsStatus;
  error: string;
  success: string;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  assetMetaproperty: null,
  assetMetapropertyOptions: [],
  assetPurposeMetaproperty: null,
  mediaSubtypeMetaproperty: null,
  assetPurposeMetaPropertyOptions: [],
  mediaSubtypeMetaPropertyOptions: [],
  assetPurposeMetaPropertyOmniOptions: [],
  mediaSubtypes: [],
  mediaSubTypesLoaded: false,
  mediaSubTypesLoading: false,
  status: DamAssetsStatus.None,
  error: null,
  success: null,
};

export function reducer(state = initialState, action: DamAssetsMetapropertyActions.DamAssetsMetapropertyActionsUnion | DamAssetsMetapropertyApiActions.DamAssetsMetapropertyApiActionsUnion): State {
  switch (action.type) {

    case DamAssetsMetapropertyActions.DamAssetsMetapropertyActionTypes.LoadDamAssetsMetaproperty: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case DamAssetsMetapropertyApiActions.DamAssetsMetapropertyApiActionTypes.LoadDamAssetsMetapropertySuccess: {
      if (action.payload.code === DamAssetMetapropertyConstants.AssetPurpose) {
        return {
          ...state,
          loading: false,
          loaded: true,
          assetMetaproperty: action.payload.metaProperty,
          assetPurposeMetaproperty: action.payload.metaProperty
        };
      }

      if (action.payload.code === DamAssetMetapropertyConstants.MediaSubType) {
        return {
          ...state,
          loading: false,
          loaded: true,
          assetMetaproperty: action.payload.metaProperty,
          mediaSubtypeMetaproperty: action.payload.metaProperty
        };
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        assetMetaproperty: action.payload.metaProperty
      };
    }

    case DamAssetsMetapropertyApiActions.DamAssetsMetapropertyApiActionTypes.LoadDamAssetsMetapropertyFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        assetMetaproperty: null,
        error: action.payload
      };
    }

    case DamAssetsMetapropertyActions.DamAssetsMetapropertyActionTypes.LoadMediaSubTypes: {
      return {
        ...state,
        mediaSubTypesLoading: true,
        mediaSubTypesLoaded: false,
      };
    }

    case DamAssetsMetapropertyApiActions.DamAssetsMetapropertyApiActionTypes.LoadMediaSubTypesSuccess: {
      return {
        ...state,
        mediaSubTypesLoading: false,
        mediaSubTypesLoaded: true,
        mediaSubtypes: action.payload
      };
    }

    case DamAssetsMetapropertyApiActions.DamAssetsMetapropertyApiActionTypes.LoadMediaSubTypesFailure: {
      return {
        ...state,
        mediaSubTypesLoading: false,
        mediaSubTypesLoaded: false,
        mediaSubtypes: [],
        error: action.payload
      };
    }

    case DamAssetsMetapropertyActions.DamAssetsMetapropertyActionTypes.LoadDamAssetsMetapropertyOptions: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case DamAssetsMetapropertyApiActions.DamAssetsMetapropertyApiActionTypes.LoadDamAssetsMetapropertyOptionsSuccess: {
      if (action.payload.metaProperty === DamAssetMetapropertyConstants.AssetPurpose)
        return {
          ...state,
          loading: false,
          loaded: true,
          assetMetapropertyOptions: action.payload.metaPropertyOption,
          assetPurposeMetaPropertyOptions: action.payload.metaPropertyOption
        }; 

      if (action.payload.metaProperty === DamAssetMetapropertyConstants.MediaSubType)
        return {
          ...state,
          loading: false,
          loaded: true,
          assetMetapropertyOptions: action.payload.metaPropertyOption,
          mediaSubtypeMetaPropertyOptions: action.payload.metaPropertyOption
        };

      return {
        ...state,
        loading: false,
        loaded: true,
        assetMetapropertyOptions: action.payload.metaPropertyOption
      };
    }

    case DamAssetsMetapropertyApiActions.DamAssetsMetapropertyApiActionTypes.LoadDamAssetsMetapropertyOptionsFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        assetMetapropertyOptions: null,
        error: action.payload
      };
    }

    case DamAssetsMetapropertyActions.DamAssetsMetapropertyActionTypes.LoadDamAssetsMetapropertyOmniOptions: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case DamAssetsMetapropertyApiActions.DamAssetsMetapropertyApiActionTypes.LoadDamAssetsMetapropertyOmniOptionsSuccess: {
      if (action.payload.metaProperty === DamAssetMetapropertyConstants.AssetPurpose)
        return {
          ...state,
          loading: false,
          loaded: true,
          assetMetapropertyOptions: action.payload.metaPropertyOption,
          assetPurposeMetaPropertyOmniOptions: action.payload.metaPropertyOption,
        }; 

      return {
        ...state,
        loading: false,
        loaded: true,
        assetMetapropertyOptions: action.payload.metaPropertyOption
      };
    }

    case DamAssetsMetapropertyApiActions.DamAssetsMetapropertyApiActionTypes.LoadDamAssetsMetapropertyOmniOptionsFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        assetMetapropertyOptions: null,
        error: action.payload
      };
    }

    default:
      return state;
  }
}

export const getIsLoading = (state: State) => state.loading;
export const getIsLoaded = (state: State) => state.loaded;
export const getLoadedAssetMetaproperty = (state: State) => state.assetMetaproperty;
export const getLoadedAssetPurposeMetaProperty = (state: State) => state.assetPurposeMetaproperty;
export const getLoadedMediaSubtypeMetaProperty = (state: State) => state.mediaSubtypeMetaproperty;
export const getLoadedAssetMetapropertyOptions = (state: State) => state.assetMetapropertyOptions;
export const getLoadedAssetPurposeMetaPropertyOptions = (state: State) => state.assetPurposeMetaPropertyOptions;
export const getLoadedMediaSubtypeMetaPropertyOptions = (state: State) => state.mediaSubtypeMetaPropertyOptions;
export const getLoadedAssetPurposeMetaPropertyOmniOptions = (state: State) => state.assetPurposeMetaPropertyOmniOptions;
export const getLoadedMediaSubTypes = (state: State) => state.mediaSubtypes;
export const getMediaSubTypesIsLoaded = (state: State) => state.mediaSubTypesLoaded;
export const getMediaSubTypesIsLoading = (state: State) => state.mediaSubTypesLoading;
export const getErrorMessage = (state: State) => state.error;
export const getSuccessMessage = (state: State) => state.success;
export const getStatus = (state: State) => state.status;
