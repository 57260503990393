import { TemplateSummary } from './../../../core/models/template-summary';
import * as _ from 'lodash';
import {
  TemplatesApiActions,
  TemplatesActions
} from '../actions';
import { IntegrationTemplateSummary } from '../../../core/models/integration-template-summary.model';
import { TemplateStatus } from 'src/app/shared/constants';

export interface State {
  integrationTemplateSummaryList: Array<IntegrationTemplateSummary>;
  loading: boolean;
  loaded: boolean;
  status: TemplateStatus;
  error: string;
  success: string;
}

export const initialState: State = {
  integrationTemplateSummaryList: [],
  loading: false,
  loaded: false,
  status: TemplateStatus.None,
  error: null,
  success: null
};

export function reducer(state = initialState, action: TemplatesActions.TemplatesActionsUnion | TemplatesApiActions.TemplatesApiActionsUnion): State {
  switch (action.type) {

    case TemplatesActions.TemplatesActionTypes.LoadTemplates: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case TemplatesApiActions.TemplatesApiActionTypes.LoadSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        integrationTemplateSummaryList: action.payload
      };
    }

    case TemplatesApiActions.TemplatesApiActionTypes.LoadFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        integrationTemplateSummaryList: [],
        error: action.payload,
        success: null
      };
    }

    case TemplatesActions.TemplatesActionTypes.ClearMessages: {
      return {
        ...state,
        status: TemplateStatus.None,
        error: null,
        success: null
      };
    }

    case TemplatesActions.TemplatesActionTypes.CreateTemplate: {
      return {
        ...state,
        loading: true,
        status: TemplateStatus.None,
      };
    }

    case TemplatesApiActions.TemplatesApiActionTypes.CreateSuccess: {
      const summaries = _.cloneDeep([...state.integrationTemplateSummaryList]);

      // Locate the integration that needs a template added to it, and add the template.
      const summary = summaries.find(i => i.id === action.payload.integrationId);
      if (summary != null && summary.templates != null) {
        summary.templates
          .push({
            id: action.payload.id,
            name: action.payload.name,
            lastModifiedDate: action.payload.lastModifiedDate,
            globalProductOfferingsCount: 0
          } as TemplateSummary);
      }

      return {
        ...state,
        integrationTemplateSummaryList: summaries,
        loading: false,
        status: TemplateStatus.CreateSuccess,
        error: null,
        success: `Successfully created template: ${action.payload.name}`
      };
    }

    case TemplatesApiActions.TemplatesApiActionTypes.CreateFailure: {
      return {
        ...state,
        loading: false,
        status: TemplateStatus.CreateFailure,
        error: action.payload
      };
    }

    case TemplatesActions.TemplatesActionTypes.RenameTemplate: {
      return {
        ...state,
        loading: true,
        status: TemplateStatus.None,
      };
    }

    case TemplatesApiActions.TemplatesApiActionTypes.RenameSuccess: {
      const summaries = _.cloneDeep([...state.integrationTemplateSummaryList]);

      // Locate the integration that needs a template added to it, and add the template.
      const summary = summaries.find(i => i.id === action.payload.integrationId);
      if (summary != null && summary.templates != null) {
        const template = summary.templates.find(t => t.id === action.payload.templateId);
        template.name = action.payload.templateName;
      }

      return {
        ...state,
        integrationTemplateSummaryList: summaries,
        loading: false,
        status: TemplateStatus.RenameSuccess,
        success: `Successfully renamed template to ${action.payload.templateName}.`,
        error: null
      };
    }

    case TemplatesApiActions.TemplatesApiActionTypes.RenameFailure: {
      return {
        ...state,
        loading: false,
        status: TemplateStatus.RenameFailure,
        error: action.payload
      };
    }

    case TemplatesActions.TemplatesActionTypes.DuplicateTemplate: {
      return {
        ...state,
        loading: true,
        status: TemplateStatus.None,
      };
    }

    case TemplatesApiActions.TemplatesApiActionTypes.DuplicateSuccess: {
      const summaries = _.cloneDeep([...state.integrationTemplateSummaryList]);

      // Locate the integration that needs a template added to it, and add the template.
      const summary = summaries.find(i => i.id === action.payload.integrationId);
      if (summary != null && summary.templates != null) {
        summary.templates
          .push({
            id: action.payload.id,
            name: action.payload.name,
            lastModifiedDate: action.payload.lastModifiedDate,
            globalProductOfferingsCount: 0
          } as TemplateSummary);
      }

      return {
        ...state,
        integrationTemplateSummaryList: summaries,
        loading: false,
        status: TemplateStatus.DuplicateSuccess,
        success: `Successfully duplicated template to ${action.payload.name}.`,
        error: null,
      };
    }

    case TemplatesApiActions.TemplatesApiActionTypes.DuplicateFailure: {
      return {
        ...state,
        loading: false,
        status: TemplateStatus.DuplicateFailure,
        error: action.payload
      };
    }

    default:
      return state;
  }
}

export const getTemplates = (state: State) => state.integrationTemplateSummaryList;
export const getErrorMessage = (state: State) => state.error;
export const getSuccessMessage = (state: State) => state.success;
export const getStatus = (state: State) => state.status;
export const isLoading = (state: State) => state.loading;
export const isLoaded = (state: State) => state.loaded;
