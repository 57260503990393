<div fxFlex class="card-image-container">
  <ng-container *ngIf="imageLink; else imageUrlElse">
    <img class="card-image" [src]="imageLink" [alt]="getImageFileName(imageLink)" (click)="showLightbox()"
      onerror="this.src='../../../../../assets/images/placeholders/image_placeholder.png'">
  </ng-container>
  <ng-template #imageUrlElse>
    <img class="card-image card-empty-image" src="../../../../assets/images/placeholders/image_placeholder.png"
      [alt]="placeholderAlt">
  </ng-template>
</div>
