import { Action } from '@ngrx/store';

export enum IntegrationsActionTypes {
  LoadIntegrations = '[Integrations] Load Integrations'
}

export class LoadIntegrations implements Action {
  readonly type = IntegrationsActionTypes.LoadIntegrations;
}

export type IntegrationsActionsUnion = LoadIntegrations;
