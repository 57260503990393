import { assign as _assign } from 'lodash-es';
import { IBaseAuditModel, BaseAuditModel } from './base-audit.model';
import { TemplateSummary } from './template-summary';

export interface IIntegrationTemplateSummary extends IBaseAuditModel {
  name: string;
  iconLink: string;
  templates: TemplateSummary[];
}

export class IntegrationTemplateSummary extends BaseAuditModel {
  name: string;
  iconLink: string;
  templates: TemplateSummary[];

  constructor(data?: IIntegrationTemplateSummary) {
    if (!data) {
      return;
    }

    super(data);
    _assign(this, data);
  }
}
