import * as _ from 'lodash';
export interface IRenamePut {
  name: string;
}

export class RenamePut implements IRenamePut {
  name = '';
  constructor(data?: IRenamePut) {
    if (data) {
      _.assign(this, data);
    }
  }
}
