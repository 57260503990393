import { assign as _assign } from 'lodash-es';
import { IBaseAuditModel, BaseAuditModel } from './base-audit.model';
import { Integration, IIntegration } from './integration.model';

export interface ITemplate extends IBaseAuditModel {
  name: string;
  integrationId: number;
  integration: IIntegration;

  showBrand: boolean;
  showSubBrand: boolean;
  showDealerCoBranding: boolean;
  showDesigners: boolean;
  showFamilies: boolean;
  showPortfolios: boolean;
  showCategoriesSubcategories: boolean;
  showPrimarySubcategory: boolean;
  showPageTitleKeyword: boolean;
  showShortDescription: boolean;
  showLongDescription: boolean;
  showMetaDescription: boolean;
  showHMGProductLink: boolean;
  showBrandProductLink: boolean;
  showStoreLink: boolean;
  showMaterialsLink: boolean;
  showWarranty: boolean;
  showMastHeadImage: boolean;
  showThumbnailFrontViewImage: boolean;
  showThumbnailHoverImage: boolean;
  showImageGallery: boolean;
  showProductLiterature: boolean;
  showProductCertifications: boolean;
  showLowestListPrice: boolean;
  showListPrice: boolean;
  showIncludeInListPriceCalc: boolean;
  showLeadTime: boolean;
  showProductLineCode: boolean;
  showGSAIndex: boolean;
  showDimensionalLineDrawing: boolean;
  showDimensionHeight: boolean;
  showDimensionWidth: boolean;
  showDimensionDepth: boolean;
  showDepthWhenOpen: boolean;
  showSeatDepth: boolean;
  showSeatHeight: boolean;
  showArmHeight: boolean;
  show3DSMax: boolean;
  show2DModel: boolean;
  show3DModel: boolean;
  showSketchup: boolean;
  showRevit: boolean;
  showCet: boolean;
  showSif: boolean;
  showFastOffice: boolean;
}

export class Template extends BaseAuditModel {
  name: string;
  integrationId: number;
  integration: Integration;

  // All template options default to true.
  showBrand = true;
  showSubBrand = true;
  showDealerCoBranding = true;
  showDesigners = true;
  showFamilies = true;
  showPortfolios = true;
  showCategoriesSubcategories = true;
  showPrimarySubcategory = true;
  showPageTitleKeyword = true;
  showShortDescription = true;
  showLongDescription = true;
  showMetaDescription = true;
  showHMGProductLink = true;
  showBrandProductLink = true;
  showStoreLink = true;
  showMaterialsLink = true;
  showWarranty = true;
  showMastHeadImage = true;
  showThumbnailFrontViewImage = true;
  showThumbnailHoverImage = true;
  showImageGallery = true;
  showProductLiterature = true;
  showProductCertifications = true;
  showLowestListPrice = true;
  showListPrice = true;
  showIncludeInListPriceCalc = true;
  showLeadTime = true;
  showProductLineCode = true;
  showGSAIndex = true;
  showDimensionalLineDrawing = true;
  showDimensionHeight = true;
  showDimensionWidth = true;
  showDimensionDepth = true;
  showDepthWhenOpen = true;
  showSeatDepth = true;
  showSeatHeight = true;
  showArmHeight = true;
  show3DSMax = true;
  show2DModel = true;
  show3DModel = true;
  showSketchup = true;
  showRevit = true;
  showCet = true;
  showSif = true;
  showFastOffice = true;

  constructor(data?: ITemplate) {
    super(data);
    if (!data) {
      return;
    }

    _assign(this, data);
  }
}
