import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import * as fromStore from '../../reducers';

import {
  CategoryApiActions,
  CategoryActions
} from '../actions';

import { switchMap, map, catchError, tap, mergeMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { CategoriesService } from 'src/app/core/services/categories.service';

@Injectable()
export class CategoryEffects {

  @Effect()
  loadCategory$ = this.actions$.pipe(
    ofType(CategoryActions.CategoryActionTypes.LoadCategory),
    map((action: CategoryActions.LoadCategory) => action.payload),
    switchMap(categoryId =>
      this.categoriesService.getCategory$(categoryId).pipe(
        map(
          (category) => new CategoryApiActions.LoadSuccess(category)
        ),
        catchError(error =>
          of(new CategoryApiActions.LoadFailure(error))
        )
      )
    )
  );

  @Effect()
  createCategory$ = this.actions$.pipe(
    ofType<CategoryActions.CreateCategory>(CategoryActions.CategoryActionTypes.CreateCategory),
    switchMap((request) =>
      this.categoriesService.createCategory$(request.payload).pipe(
        map((category) =>
          new CategoryApiActions.CreateSuccess(category)
        ),
        tap((response) => {
          // Navigate to the page in edit mode.
          this.router.navigate([`products/organizational-data/category-detail/${response.payload.id}`], { queryParams: { edit: true } });
        }),
        catchError(error =>
          of(new CategoryApiActions.CreateFailure(error))
        )
      )
    )
  );

  @Effect()
  updateCategory$: Observable<Action> = this.actions$.pipe(
    ofType<CategoryActions.UpdateCategory>(CategoryActions.CategoryActionTypes.UpdateCategory),
    switchMap(request =>
      this.categoriesService.updateCategory$(request.payload.category).pipe(
        map((response) => new CategoryApiActions.UpdateSuccess({ category: request.payload.category, categoryName: request.payload.categoryName })),
        catchError(error => of(new CategoryApiActions.UpdateFailure(error)))
      )
    )
  );

  @Effect()
  updateCategorySuccess$: Observable<Action> = this.actions$.pipe(
    ofType(CategoryApiActions.CategoryApiActionTypes.UpdateSuccess),
    map((action: CategoryApiActions.UpdateSuccess) => action.payload),
    map(payload =>
      // Reload the category from the API if the save was successful.
      new CategoryActions.LoadCategory(payload.category.id)
    )
  );

  @Effect()
  renameCategory$: Observable<Action> = this.actions$.pipe(
    ofType<CategoryActions.RenameCategory>(CategoryActions.CategoryActionTypes.RenameCategory),
    switchMap((request) =>
      this.categoriesService.renameCategory$({
        categoryId: request.payload.categoryId,
        categoryName: request.payload.categoryName
      }).pipe(
        map(_ => new CategoryApiActions.RenameSuccess({
          categoryId: request.payload.categoryId,
          categoryName: request.payload.categoryName
        })
        ),
        tap(res => this.store.dispatch(new CategoryActions.LoadCategory(request.payload.categoryId)),
          catchError(error =>
            of(new CategoryApiActions.RenameFailure(error)),
          )
        )
      )
    )
  );

  @Effect()
  deleteCategory$ = this.actions$.pipe(
    ofType<CategoryActions.DeleteCategory>(CategoryActions.CategoryActionTypes.DeleteCategory),
    switchMap((request) =>
      this.categoriesService.deleteCategory$(request.payload.categoryId).pipe(
        map((response) =>
          new CategoryApiActions.DeleteSuccess({ categoryId: request.payload.categoryId, categoryName: request.payload.categoryName })
        ),
        tap((response) => {
          // Navigate to all categories page
          this.router.navigate([`products/organizational-data/categories`]);
        }),
        catchError(error =>
          of(new CategoryApiActions.DeleteFailure(error))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<fromStore.State>,
    private categoriesService: CategoriesService) { }

}
