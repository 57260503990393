import { SelectOptions } from '../../../../core/models/select-options.model';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Component, Input } from '@angular/core';
import { AddProductLiteratureModalDataSource } from '../add-product-literature-modal.datasource';
import * as fromStore from '../../../../app-store/reducers';

@Component({
  selector: 'app-product-literature-document-entry',
  templateUrl: './product-literature-document-entry.component.html',
  styleUrls: ['./product-literature-document-entry.component.scss']
})
export class ProductLiteratureDocumentEntryComponent {

  @Input() datasource: AddProductLiteratureModalDataSource;
  selectOptions$: Observable<SelectOptions>;

  constructor(private store: Store<fromStore.State>) {
    this.selectOptions$ = this.store.select(fromStore.getSelectOptions);
  }

}
