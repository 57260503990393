import { PagedResults } from './../models/page-results.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  constructor() { }

  // This method is useful for getting 'x' in a paging statement: 'showing x - y of <pageSize>' in a paging label.
  public getPageStartIndex<T>(page: PagedResults<T>, staticPageSize: number): number {
    if (!page || page?.results.length <= 0) {
      return 0;
    }

    return (+(staticPageSize) * (+(page.pageNumber) - 1)) + 1;
  }

  // This method is useful for getting 'y' in a paging statement: 'showing x - y of <pageSize>' in a paging label.
  public getPageEndIndex<T>(page: PagedResults<T>, staticPageSize: number): number {
    if (!page || page?.results.length <= 0) {
      return 0;
    }

    return (+(staticPageSize) * (+(page.pageNumber) - 1)) + page.results?.length;
  }
}
