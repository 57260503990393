export class CadLinksModalDataSourceConstants {

    public static readonly ORDERBYOPTIONS = [
        {
            value: "baseproductnumber",
            label: "Base Product Number"
        },
        {
            value: "familyname",
            label: "Family Name"
        },
        {
            value: "typename",
            label: "Type Name"
        },
        {
            value: "legacyname",
            label: "Legacy Name"
        }
    ];
}
