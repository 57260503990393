import { GlobalConfigurationsService } from './../../../core/services/global-configurations.service';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { GlobalProductsService } from 'src/app/core/services/global-products.service';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { map, switchMap, catchError, mergeMap, tap } from 'rxjs/operators';

import {
  ProductActions, ProductApiActions
} from '../actions';
import { Router } from '@angular/router';
import * as fromStore from '../../../app-store/reducers';

@Injectable()
export class ProductEffects {
  @Effect()
  loadProduct$ = this.actions$.pipe(
    ofType(ProductActions.ProductActionTypes.LoadProduct),
    map((action: ProductActions.LoadProduct) => action.payload),
    switchMap(productId =>
      this.globalProductService.getGlobalProduct$(productId).pipe(
        map(
          (product) => new ProductApiActions.LoadSuccess(product)
        ),
        catchError(error =>
          of(new ProductApiActions.LoadFailure(error))
        )
      )
    )
  );

  @Effect()
  createProduct$: Observable<Action> = this.actions$.pipe(
    ofType(ProductActions.ProductActionTypes.CreateProduct),
    map((action: ProductActions.CreateProduct) => action.payload.product),
    mergeMap(product =>
      this.globalProductService.createGlobalProduct$(product).pipe(
        tap((p) => {
          // Navigate to the page in edit mode.
          this.router.navigate([`products/product-detail/${p.id}/classification`], { queryParams: { edit: true } });
        }),
        map((p) => new ProductApiActions.CreateSuccess(p)),
        catchError(error =>
          of(new ProductApiActions.CreateFailure(error))
        ),
      )
    )
  );

  @Effect()
  updateProduct$: Observable<Action> = this.actions$.pipe(
    ofType(ProductActions.ProductActionTypes.UpdateProduct),
    map((action: ProductActions.UpdateProduct) => action.payload.product),
    mergeMap(product =>
      this.globalProductService.updateGlobalProduct$(product).pipe(
        map(() => new ProductApiActions.UpdateSuccess(product)),
        catchError(error => of(new ProductApiActions.UpdateFailure(error)))
      )
    )
  );

  @Effect()
  updateProductSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(ProductApiActions.ProductApiActionTypes.UpdateSuccess),
    map((action: ProductApiActions.UpdateSuccess) => action.payload),
    map(product =>
      // Reload the product from the API if the save was successful.
      new ProductActions.LoadProduct(product.id)
    )
  );

  @Effect()
  renameProduct$: Observable<Action> = this.actions$.pipe(
    ofType(ProductActions.ProductActionTypes.RenameProduct),
    map((action: ProductActions.RenameProduct) => action.payload),
    switchMap(rename => {
      return this.globalProductService.renameProduct$(rename.product, rename.newName).pipe(
        map(
          (renamedProduct) => {
            return new ProductApiActions.RenameProductSuccess(rename);
          }
        ),
        catchError((error) => {
          return of(new ProductApiActions.RenameProductFailure({ product: rename.product }));
        }),
        tap((response) => {
          // Reload product from API if rename is successful/failed
          this.store.dispatch(new ProductActions.LoadProduct(response.payload.product.id));
        })
      );
    }
    ));

  @Effect()
  deleteProduct$: Observable<Action> = this.actions$.pipe(
    ofType(ProductActions.ProductActionTypes.DeleteProduct),
    map((action: ProductActions.DeleteProduct) => action.payload.product),
    switchMap(product =>
      this.globalProductService.deleteGlobalProduct$(product).pipe(
        map(
          (deletedProductId) => {
            this.router.navigate([`products`]);
            return new ProductApiActions.DeleteSuccess(product);
          }
        ),
        catchError((error) => {
          return of(new ProductApiActions.DeleteFailure(error));
        })
      ))
  );

  @Effect()
  renameConfiguration$: Observable<Action> = this.actions$.pipe(
    ofType(ProductActions.ProductActionTypes.RenameConfiguration),
    map((action: ProductActions.RenameConfiguration) => action.payload),
    switchMap(rename => {
      return this.globalConfigurationsService.renameConfiguration$(rename.configuration, rename.newName).pipe(
        map(
          (renamedConfiguration) => {
            return new ProductApiActions.RenameConfigurationSuccess({ configuration: rename.configuration, newName: rename.newName });
          }
        ),
        catchError((error) => {
          return of(new ProductApiActions.RenameConfigurationFailure({ configuration: rename.configuration }));
        }),
        tap(() =>
          this.store.dispatch(new ProductActions.LoadProduct(rename.configuration.globalProductId))
        )
      );
    }
    ));

  @Effect()
  deleteConfiguration$: Observable<Action> = this.actions$.pipe(
    ofType(ProductActions.ProductActionTypes.DeleteConfiguration),
    map((action: ProductActions.DeleteConfiguration) => action.payload),
    switchMap(payload => {
      return this.globalConfigurationsService.deleteConfiguration$(payload.configuration).pipe(
        tap(() =>
          this.store.dispatch(new ProductActions.LoadProduct(payload.configuration.globalProductId))
        ),
        map(() => {
          return new ProductApiActions.DeleteConfigurationSuccess({ configuration: payload.configuration });
        }),
        catchError((error) => {
          return of(new ProductApiActions.DeleteConfigurationFailure({ configuration: payload.configuration }));
        })
      );
    })
  );

  @Effect()
  duplicateConfiguration$: Observable<Action> = this.actions$.pipe(
    ofType(ProductActions.ProductActionTypes.DuplicateConfiguration),
    map((action: ProductActions.DuplicateConfiguration) => action.payload),
    switchMap(request => {
      return this.globalConfigurationsService.duplicateConfiguration$(request.configuration, request.newName).pipe(
        map((duplicatedConfiguration) => {
            return new ProductApiActions.DuplicateConfigurationSuccess({ configuration: duplicatedConfiguration, newName: request.newName });
          }
        ),
        catchError((error) => {
          return of(new ProductApiActions.DuplicateConfigurationFailure({ configuration: request.configuration }));
        }),
        tap(() =>
          this.store.dispatch(new ProductActions.LoadProduct(request.configuration.globalProductId))
        )
      );
    }
  ));

  constructor(
    private actions$: Actions,
    private router: Router,
    private globalProductService: GlobalProductsService,
    private globalConfigurationsService: GlobalConfigurationsService,
    private store: Store<fromStore.State>) { }
}
