import { Observable } from 'rxjs';
import { IConfigurationsPageRequest } from 'src/app/core/models/configurations-page-request.model';
import { ConfigurationsPageRequestBuilder } from './../../../core/services/configurations-page-request-builder.service';
import { RolesService } from './../../../core/auth/roles.service';
import { Store } from '@ngrx/store';
import { PagedResults } from 'src/app/core/models/page-results.model';
import { SubSink } from 'subsink';
import { GlobalConfiguration } from 'src/app/core/models/global-configuration.model';
import * as fromStore from '../../../app-store/reducers';
import { ConfigurationsActions } from 'src/app/app-store/configurations-store';
import * as _ from 'lodash';


export interface IConfigurationSearchCriteria {
  searchTerm: string;
  integrationIds: Array<number>;
  localeIds: Array<number>;
}
export class ConfigurationSearchCriteria implements IConfigurationSearchCriteria {
  searchTerm = '';
  integrationIds: Array<number> = [];
  localeIds: Array<number> = [];

  constructor(data?: IConfigurationSearchCriteria) {
    if (!data) {
      return;
    }
    _.assign(this, data);
  }
}

export class GlobalConfigurationSearchDatasource {
  public criteria: ConfigurationSearchCriteria = new ConfigurationSearchCriteria();
  public selections: Array<GlobalConfiguration> = [];

  public page$: Observable<PagedResults<GlobalConfiguration>>;
  public page: PagedResults<GlobalConfiguration> = new PagedResults();

  public get loading$(): Observable<boolean> { return this.store.select(fromStore.getConfigurationsIsLoading); }
  public get loaded$(): Observable<boolean> { return this.store.select(fromStore.getConfigurationsIsLoaded); }

  private subscriptions: SubSink = new SubSink();
  private FIRST_PAGE = 1;
  private PAGE_SIZE = 50;

  public constructor(
    private globalProductId: number,
    private store: Store<fromStore.State>,
    private rolesService: RolesService
  ) {
    this.page$ = this.store.select(fromStore.getConfigurations);
  }

  public connect(): void {
    this.subscribeToGlobalConfigurations();
  }

  public disconnect(): void {
    this.subscriptions.unsubscribe();
  }

  public hasResults() {
    return this.page?.results?.length > 0;
  }

  public hasSelections() {
    return this.selections?.length > 0;
  }

  public hasPermissions(globalConfiguration: GlobalConfiguration): boolean {
    // if there is no configuration, don't allow selection (should be impossible)
    if (!globalConfiguration) {
      return false;
    }

    const integrationIds = globalConfiguration?.globalConfigurationIntegrations?.map(x => x.integrationId);
    // if there are no integration ids applied to the configuration, it has no specific owner and can be modified by anyone.
    if (!integrationIds || !(integrationIds.length > 0)) {
      return true;
    }

    return !(this.rolesService.isIntegrationRoleInvalidForIntegrationIds(integrationIds));
  }

  public isSelected(globalConfiguration: GlobalConfiguration): boolean {
    if (globalConfiguration) {
      return this.selections.find(x => x.id === globalConfiguration.id) != null ? true : false;
    }

    return false;
  }

  public clearSelections(): void {
    this.selections = [];
  }

  public selectAll(): void {
    if (!this.page || !this.page.results || this.page.results.length <= 0) {
      return;
    }

    this.page.results.forEach((x: GlobalConfiguration) => {
      this.select(x, true);
    });
  }

  public select(globalConfiguration: GlobalConfiguration, isSelected: boolean): void {
    const isChecked = this.isSelected(globalConfiguration);
    const hasPermission = this.hasPermissions(globalConfiguration);

    // Apply selection if isSelected is true, and we haven't already selected the configuration.
    if (isSelected && !isChecked && hasPermission) {
      this.selections.push(globalConfiguration);
      return;
    }

    // Remove selection
    if (!isSelected) {
      const index = this.selections.findIndex(x => x.id === globalConfiguration.id);
      if (index >= 0) {
        this.selections.splice(index, 1);
      }
    }
  }

  public getPageRecordCountStart(): number {
    if (!this.page || this.page?.results.length <= 0) {
      return 0;
    }

    return (+(this.PAGE_SIZE) * (+(this.page.pageNumber) - 1)) + 1;
  }

  public getPageRecordCountEnd(): number {
    if (!this.page || this.page?.results.length <= 0) {
      return 0;
    }

    return (+(this.PAGE_SIZE) * (+(this.page.pageNumber) - 1)) + this.page.results?.length;
  }

  public nextPage() {
    if (!this.page || this.page?.pageNumber === this.page.totalPageCount) {
      return;
    }

    this.searchByPageNumber(this.criteria, this.page.pageNumber + 1);
  }

  public previousPage() {
    if (!this.page || this.page?.pageNumber === this.FIRST_PAGE) {
      return;
    }

    this.searchByPageNumber(this.criteria, this.page.pageNumber - 1);
  }

  public search(criteria: IConfigurationSearchCriteria): void {
    this.searchByPageNumber(criteria, this.FIRST_PAGE);
  }

  public refresh(): void {
    this.search(this.criteria);
  }

  private searchByPageNumber(criteria: IConfigurationSearchCriteria, pageNumber: number): void {
    if (!criteria) {
      criteria = new ConfigurationSearchCriteria();
    }

    this.criteria.searchTerm = criteria.searchTerm;
    this.criteria.integrationIds = [...criteria.integrationIds];
    this.criteria.localeIds = [...criteria.localeIds];

    if (!this.criteria.searchTerm) {
      this.criteria.searchTerm = '';
    }

    // Build request
    const builder = new ConfigurationsPageRequestBuilder();
    const request = builder
      .setGlobalProductId(this.globalProductId)
      .setSearchTerm(this.criteria.searchTerm)
      .setPageNumber(pageNumber)
      .setPageSize(this.PAGE_SIZE)
      .setOrderBy('name')
      .setSortOrder('asc')
      .setIntegrationIds([...this.criteria.integrationIds])
      .setLocaleIds([...this.criteria.localeIds])
      .build();

    this.setStoreConfigurations(request);
  }

  private setStoreConfigurations(request: IConfigurationsPageRequest) {
    this.store.dispatch(new ConfigurationsActions.LoadConfigurationsByProductId(request));
  }

  private subscribeToGlobalConfigurations() {
    this.subscriptions.add(
      this.page$.subscribe((page: PagedResults<GlobalConfiguration>) => {
        this.page = page;
        // console.log('search updated');
        // console.log(this.page);
      })
    );
  }
}
