import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import * as _ from 'lodash';
import { ImageLink, IImageLink } from './image-link.model';
import { ISelectOption, SelectOption } from './select-option.model';

export interface ISettingSummary extends IBaseAuditModel {
  name: string;
  description: string;
  settingImages: Array<IImageLink>;
  settingWorkModes: Array<ISelectOption>;
  settingOrientations: Array<ISelectOption>;
  settingRotations:  Array<SelectOption>;
  settingStorages: Array<ISelectOption>;
}

export class SettingSummary extends BaseAuditModel implements ISettingSummary {
  name: string;
  description: string;
  settingImages: Array<ImageLink> = [];
  settingWorkModes: Array<SelectOption> = [];
  settingOrientations: Array<SelectOption> = [];
  settingRotations:  Array<SelectOption> = [];
  settingStorages: Array<SelectOption> = [];

  constructor(data?: ISettingSummary) {
    super(data);

    if (!data) {
      return;
    }

    _.assign(this, data);
  }
}
