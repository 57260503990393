import { Action } from '@ngrx/store';
import { FamilyPut } from 'src/app/core/models/family-put.model';
import { Family } from 'src/app/core/models/family.model';

export enum FamilyApiActionTypes {
  LoadSuccess = '[Family/API] Load Success',
  LoadFailure = '[Family/API] Load Failure',
  CreateSuccess = '[Family/API] Create Success',
  CreateFailure = '[Family/API] Create Failure',
  UpdateSuccess = '[Family/API] Update Success',
  UpdateFailure = '[Family/API] Update Failure',
  RenameSuccess = '[Family/API] Rename Success',
  RenameFailure = '[Family/API] Rename Failure',
  DeleteSuccess = '[Family/API] Delete Success',
  DeleteFailure = '[Family/API] Delete Failure'
}

export class LoadSuccess implements Action {
  readonly type = FamilyApiActionTypes.LoadSuccess;

  constructor(public payload: Family) { }
}

export class LoadFailure implements Action {
  readonly type = FamilyApiActionTypes.LoadFailure;

  constructor(public payload: any) { }
}

export class CreateSuccess implements Action {
  readonly type = FamilyApiActionTypes.CreateSuccess;

  constructor(public payload: Family) { }
}

export class CreateFailure implements Action {
  readonly type = FamilyApiActionTypes.CreateFailure;

  constructor(public payload: any) { }
}


export class UpdateSuccess implements Action {
  readonly type = FamilyApiActionTypes.UpdateSuccess;

  constructor(public payload: { family: FamilyPut, familyName: string }) { }
}

export class UpdateFailure implements Action {
  readonly type = FamilyApiActionTypes.UpdateFailure;

  constructor(public payload: any) { }
}

export class RenameSuccess implements Action {
  readonly type = FamilyApiActionTypes.RenameSuccess;

  constructor(public payload: { familyId: number, familyName: string }) { }
}

export class RenameFailure implements Action {
  readonly type = FamilyApiActionTypes.RenameFailure;

  constructor(public payload: any) { }
}

export class DeleteSuccess implements Action {
  readonly type = FamilyApiActionTypes.DeleteSuccess;

  constructor(public payload: { familyId: number, familyName: string }) { }
}

export class DeleteFailure implements Action {
  readonly type = FamilyApiActionTypes.DeleteFailure;

  constructor(public payload: any) { }
}


export type FamilyApiActionsUnion =
  LoadSuccess
  | LoadFailure
  | CreateSuccess
  | CreateFailure
  | UpdateSuccess
  | UpdateFailure
  | RenameSuccess
  | RenameFailure
  | DeleteSuccess
  | DeleteFailure;
