<div class="header-container">
  <div>
    <ng-content select="[header-detail-alerts]"></ng-content>
  </div>

  <div class="description-container">
    <ng-content select="[header-detail-description]"></ng-content>
  </div>

  <div fxLayout="row" fxLayoutGap="10px">
    <div fxFlex>
      <ng-content select="[header-detail-title]"></ng-content>
    </div>
    <div fxFlex="nogrow">
      <ng-content select="[header-detail-actions]"></ng-content>
    </div>
  </div>
</div>
