import { assign as _assign } from 'lodash-es';
import { IPageRequest, PageRequest } from './page-request.model';

export interface IGlobalProductQueryPageRequest extends IPageRequest {
  searchTerm: string;
  brandIds: Array<number>;
  designerIds: Array<number>;
  categoryIds: Array<number>;
  contactIds: Array<number>;
  familyIds: Array<number>;
  globalCategoryIds: Array<number>;
  globalSubcategoryIds: Array<number>;
  portfolioIds: Array<number>;
  subbrandIds: Array<number>;
  subcategoryIds: Array<number>;
  warrantyIds: Array<number>;
}

export class GlobalProductQueryPageRequest extends PageRequest {
  searchTerm: string;
  brandIds: Array<number> = [];
  designerIds: Array<number> = [];
  categoryIds: Array<number> = [];
  contactIds: Array<number> = [];
  familyIds: Array<number> = [];
  globalCategoryIds: Array<number> = [];
  globalSubcategoryIds: Array<number> = [];
  portfolioIds: Array<number> = [];
  subbrandIds: Array<number> = [];
  subcategoryIds: Array<number> = [];
  warrantyIds: Array<number> = [];

  constructor(data?: IGlobalProductQueryPageRequest) {
    super(data);

    if (!data) {
      return;
    }
    _assign(this, data);
  }
}
