import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import * as _ from 'lodash';

export interface IApplicationTagPost extends IBaseAuditModel {
  tag: string;
}

export class ApplicationTagPost extends BaseAuditModel {
  tag: string;

  constructor(data?: IApplicationTagPost) {
    super(data);

    if (!data) {
      return;
    }
    _.assign(this, data);
  }
}
