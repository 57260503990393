import { GlobalConfigurationLocale } from './global-configuration-locale.model';
import { GlobalConfigurationProductNumber } from './global-configuration-product-number.model';
import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import * as _ from 'lodash';
import { ModelFilesProfile } from './model-files-profile.model';
import { GlobalConfigurationIntegration } from './global-configuration-integration.model';
import { GlobalConfigurationMediaLink } from './global-configuration-media-link.model';

export interface IGlobalConfiguration extends IBaseAuditModel {
  name: string;
  globalProductId: number;
  productNumber: string;
  productLineCode: string;
  listPrice?: number;
  leadTime?: number;
  gsaIndex: string;
  excludeFromLowListPriceCalc: boolean;

  minHeight?: number;
  maxHeight?: number;
  minWidth?: number;
  maxWidth?: number;
  minDepth?: number;
  maxDepth?: number;
  minSeatDepth?: number;
  maxSeatDepth?: number;
  minArmHeight?: number;
  maxArmHeight?: number;
  minDepthWhenOpen?: number;
  LeadTime?: number;

  threeDSMaxFileLink: string;
  revitLink: string;
  cadLink: string;
  dwgLink: string;
  cetLink: string;
  sifLink: string;
  fastOfficeDwgLink: string;
  sketchupLink: string;

  productNumbers: Array<GlobalConfigurationProductNumber>;
  globalConfigurationMediaLinks: Array<GlobalConfigurationMediaLink>;
  globalConfigurationLocales: Array<GlobalConfigurationLocale>;
  modelFilesProfileId?: number;
  modelFilesProfile?: ModelFilesProfile;
  globalConfigurationIntegrations: Array<GlobalConfigurationIntegration>;
}

export class GlobalConfiguration extends BaseAuditModel implements IGlobalConfiguration {
  name: string;
  globalProductId: number;
  productNumber: string;
  productLineCode: string;
  listPrice?: number;
  leadTime?: number;
  gsaIndex: string;
  excludeFromLowListPriceCalc: boolean;

  minHeight?: number;
  maxHeight?: number;
  minWidth?: number;
  maxWidth?: number;
  minDepth?: number;
  maxDepth?: number;
  minSeatHeight?: number;
  maxSeatHeight?: number;
  minSeatDepth?: number;
  maxSeatDepth?: number;
  minArmHeight?: number;
  maxArmHeight?: number;
  minDepthWhenOpen?: number;
  LeadTime?: number;

  threeDSMaxFileLink: string;
  revitLink: string;
  cadLink: string;
  dwgLink: string;
  cetLink: string;
  sifLink: string;
  fastOfficeDwgLink: string;
  sketchupLink: string;

  productNumbers: Array<GlobalConfigurationProductNumber> = [];
  globalConfigurationMediaLinks: Array<GlobalConfigurationMediaLink> = [];
  globalConfigurationLocales: Array<GlobalConfigurationLocale> = [];
  globalConfigurationIntegrations: Array<GlobalConfigurationIntegration> = [];

  modelFilesProfileId?: number;
  modelFilesProfile?: ModelFilesProfile;

  constructor(data?: IGlobalConfiguration) {
    super(data);

    if (!data) {
      return;
    }

    _.assign(this, data);
  }
}

export class GlobalConfigurationSelection {
  selectedIndex: number;
  globalConfiguration: GlobalConfiguration;

  constructor(selectedIndex: number, globalConfiguration: GlobalConfiguration) {
    this.selectedIndex = selectedIndex;
    this.globalConfiguration = globalConfiguration;
  }
}
