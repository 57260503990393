import { Action } from '@ngrx/store';
import { Integration } from 'src/app/core/models/integration.model';

export enum IntegrationActionTypes {
  LoadIntegration = '[Integration] Load Integration',
  UpdateIntegration = '[Integration] Update Integration',
  RenameIntegration = '[Integration] Rename Integration',
  ClearMessages = '[Integration] Clear Messages'
}

export class LoadIntegration implements Action {
  readonly type = IntegrationActionTypes.LoadIntegration;

  constructor(public payload: number) { }
}

export class UpdateIntegration implements Action {
  readonly type = IntegrationActionTypes.UpdateIntegration;

  constructor(public payload: Integration) { }
}

export class RenameIntegration implements Action {
  readonly type = IntegrationActionTypes.RenameIntegration;

  constructor(public payload: { integrationId: number, integrationName: string }) { }
}

export class ClearMessages implements Action {
  readonly type = IntegrationActionTypes.ClearMessages;

  constructor() { }
}

export type IntegrationActionsUnion = LoadIntegration | UpdateIntegration | RenameIntegration | ClearMessages;
