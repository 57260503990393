import { Injectable } from '@angular/core';
import { IntegrationsConstants } from 'src/app/shared/constants';
import { IGridStateService } from './gridstate-service';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionsGridService implements IGridStateService {
  public DEFAULT_PAGE_SIZE = 50;
  public DEFAULT_SORT_BY = IntegrationsConstants.GRID_USER_PERMISSIONS_LASTMODIFIEDDATE;
  public DEFAULT_SORT_DIRECTION = 'desc';

  public searchTerm = '';
  public pageNumber = 0;
  public pageSize = this.DEFAULT_PAGE_SIZE;
  public sortActive = this.DEFAULT_SORT_BY;
  public sortDirection = this.DEFAULT_SORT_DIRECTION;

  constructor() { }
}
