import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-attribute-type-readonly',
  templateUrl: './attribute-type-readonly.component.html',
  styleUrls: ['./attribute-type-readonly.component.scss']
})
export class AttributeTypeReadonlyComponent {
  @Input() attributeTypeName: string;
  @Input() impactsPriceSignificantly: boolean;
  @Input() isInDraft: boolean;

}
