import { map, catchError, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { ProductGuidanceAttributesActions, ProductGuidanceAttributesApiActions } from '../actions';
import { of } from 'rxjs';
import { GlobalProductsService } from 'src/app/core/services/global-products.service';
import { ProductGuidanceAttribute } from 'src/app/core/models/product-guidance-attribute.model';
@Injectable()
export class ProductGuidanceAttributesEffects {
  loadProductGuidanceAttributes$ = createEffect(() => this.actions$.pipe(
    ofType(ProductGuidanceAttributesActions.ProductGuidanceAttributesActionTypes.LoadProductGuidanceAttributes),
    map((action: ProductGuidanceAttributesActions.LoadProductGuidanceAttributes) => action.payload),
    switchMap((id) =>
      this.globalProductsService.getProductGuidanceAttributes$(id).pipe(
        map((productGuidanceAttributes: Array<ProductGuidanceAttribute>) => new ProductGuidanceAttributesApiActions.LoadSuccess(productGuidanceAttributes)),
        catchError(error =>
          of(new ProductGuidanceAttributesApiActions.LoadFailure(error))
        )
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private globalProductsService: GlobalProductsService) { }
}
