import { ApplicationPost } from './../../../core/models/application-post.model';
import { Action } from '@ngrx/store';
import { ApplicationPut } from 'src/app/core/models/application-put.model';

export enum ApplicationActionTypes {
  LoadApplication = '[Application] Load Application',
  CreateApplication = '[Application] Create Application',
  UpdateApplication = '[Application] Update Application',
  DeleteApplication = '[Application] Delete Application',
  DeleteApplicationOption = '[Application] Delete Application Option',
  ClearMessages = '[Application] Clear Messages',
}

export class LoadApplication implements Action {
  readonly type = ApplicationActionTypes.LoadApplication;

  constructor(public payload: number) { }
}

export class CreateApplication implements Action {
  readonly type = ApplicationActionTypes.CreateApplication;

  constructor(public payload: ApplicationPost) { }
}

export class UpdateApplication implements Action {
  readonly type = ApplicationActionTypes.UpdateApplication;

  constructor(public payload: { application: ApplicationPut, applicationName: string }) { }
}

export class DeleteApplication implements Action {
  readonly type = ApplicationActionTypes.DeleteApplication;

  constructor(public payload: { applicationId: number, applicationName: string }) { }
}

export class DeleteApplicationOption implements Action {
  readonly type = ApplicationActionTypes.DeleteApplicationOption;

  constructor(public payload: { applicationId: number, applicationOptionId: number }) { }
}

export class ClearMessages implements Action {
  readonly type = ApplicationActionTypes.ClearMessages;

  constructor() { }
}

export type ApplicationActionsUnion =
  LoadApplication
  | CreateApplication
  | UpdateApplication
  | DeleteApplication
  | DeleteApplicationOption
  | ClearMessages;
