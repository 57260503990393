import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import { assign as _assign } from 'lodash-es';

export interface IContactPost extends IBaseAuditModel {
  name: string;
}

export class ContactPost extends BaseAuditModel implements IContactPost {
  name: string;

  constructor(data?: IContactPost) {
    super(data);

    if (!data) {
      return;
    }

    _assign(this, data);
  }
}
