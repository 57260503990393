import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import { assign as _assign } from 'lodash-es';

export interface IWarranty extends IBaseAuditModel {
  name: string;
}

export class Warranty extends BaseAuditModel implements IWarranty {
  name: string;

  constructor(data: IWarranty) {
    super(data);

    if (!data) {
      return;
    }

    _assign(this, data);
  }
}
