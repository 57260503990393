<div>
  <div fxLayout="column" fxFlex class="align-center-vertical align-right">
    <ng-container *ngIf="selectedRegions && selectedRegions.length > 0; else localesElse">
      <ng-container *ngFor="let region of selectedRegions;">
        <div fxFlex class="list-title">{{ region.name }}</div>
        <ng-container *ngFor="let locale of getSelectLocalesForRegion(region.id)">
          <div class="list-item">{{ locale.name }}</div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <ng-template #localesElse>
    <div fxFlex class="none align-center-vertical align-right">None</div>
  </ng-template>
</div>
