<div class="background"
  infiniteScroll
  [infiniteScrollContainer]="'app-cad-links-modal .mat-tab-body-content'"
  [fromRoot]="true"
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  (scrolled)="onScrollDown()"
  [scrollWindow]="false" >


  <div class="cad-links-search-container-top">
    <div fxLayout="row">
      <div fxFlex fxLayout="col"
        class="cad-links-search-container-top-input">
          <mat-form-field class="wrapper-none" appearance="outline">
            <mat-label>Product number begins with</mat-label>
            <input
              matInput
              [value]="datasource.searchTerm"
              #search
              placeholder="Product number begins with..." 
              [matAutocomplete]="auto">
            <mat-icon matSuffix>search</mat-icon>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onAutoCompleteOptionSelected($event)">
              <mat-option *ngFor="let prodNumber of autoCompleteOptions" [value]="prodNumber">
                {{ prodNumber }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
      </div>
      <div fxFlex=30 fxLayout="col" class="cad-links-search-container-top-orderby" >
        <mat-form-field>
          <mat-label>Order by</mat-label>
          <mat-select
            [(value)]="datasource.orderBy"
            #orderBy
            (selectionChange)="onOrderByChanged()"
            matTooltip="Change how the results are sorted">
              <mat-option 
                *ngFor="let option of datasource.orderByOptions"
                [value]="option.value" >
                  {{ option.label }}
              </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="10" fxLayout="col" class="cad-links-search-container-top-sortOrder align-center-vertical">
        <button
          mat-button (click)="onToggleSortOrder()"
          matTooltip="Change the sort order" >
          <mat-icon *ngIf="datasource.sortAscending" class="sort-order-indicator">arrow_upward</mat-icon>
          <mat-icon *ngIf="!datasource.sortAscending" class="sort-order-indicator">arrow_downward</mat-icon>
        </button>
      </div>
    </div>
    <div
      class="cad-links-search-container-result-count" 
      *ngIf="datasource.returnedCadLinks && !datasource.loading && datasource.returnedCadLinks.totalRecordCount == 0">
        Displaying {{ datasource.returnedCadLinks.totalRecordCount }} matching CadPack profiles.
    </div>
    <div
      class="cad-links-search-container-result-count" 
      *ngIf="datasource.returnedCadLinks && !datasource.loading && datasource.returnedCadLinks.totalRecordCount == 1">
        Displaying {{ datasource.returnedCadLinks.totalRecordCount }} matching CadPack profile.
    </div>
    <div
      class="cad-links-search-container-result-count" 
      *ngIf="datasource.returnedCadLinks && !datasource.loading && datasource.returnedCadLinks.totalRecordCount > 1">
        Displaying {{ datasource.returnedCadLinks.totalRecordCount }} matching CadPack profiles.
    </div>
  </div>

  <div class="cad-links-search-results">
    <div *ngIf="(datasource.loading)">
      <div class="spinner-container">
        <span class="spinner">
          <mat-spinner color='accent' diameter="15"></mat-spinner>
        </span>
        <span class="spinner-message">Retrieving matching results...</span>
      </div>
    </div>
    <div class="cad-links-search-results-items">
      <div *ngIf="datasource.returnedCadLinks && !datasource.loading">
        <div class="cad-links-search-result-item" *ngFor="let cadLinks of datasource.returnedCadLinks.results">
          <app-cad-links-search-result
            [cadLinks]=cadLinks
            [desiredLinkType]="datasource.desiredLinkType">
          </app-cad-links-search-result>
        </div>
      </div>
    </div>
    <div
      class="cad-links-search-results-reached-end" 
      *ngIf="datasource.pageNumber == datasource.totalPageCount && datasource.totalPageCount > 1">
        There are no further matching profiles to retrieve.
    </div>
  </div>

  <div class="cad-links-search-container-bottom">
    <div fxLayout="row">
      <div
        class="cad-links-search-results-note"
        *ngIf="datasource.returnedCadLinks && datasource.returnedCadLinks.results && datasource.returnedCadLinks.results.length > 0 && !datasource.loading">
          NOTE: Result data shown above is not provided by Harbour, the data is displayed as it is received from CadPack in its raw form.
      </div>
    </div>
</div>

</div>