import { IBaseAuditModel, BaseAuditModel } from './base-audit.model';
import { IIntegrationContact, IntegrationContact } from './integration-contact.model';
import { map as _map } from 'lodash-es';

export interface IIntegration extends IBaseAuditModel {
  name: string;
  description: string;
  iconLink: string;
  integrationContacts: Array<IIntegrationContact>;
  iconUpload: File;
  iconName: string;
  errorMessage: string;
  error: boolean;
}

export class Integration extends BaseAuditModel {
  name: string;
  description: string;
  iconLink: string;
  integrationContacts: Array<IntegrationContact>;
  iconUpload: File;
  iconName: string;
  errorMessage: string;
  error: boolean;

  constructor(data?: IIntegration) {
    if (data) {
      super(data);
      this.name = data.name;
      this.description = data.description;
      this.iconLink = data.iconLink;
      this.integrationContacts = data.integrationContacts && _map(data.integrationContacts as Array<IIntegrationContact>, x => new IntegrationContact(x));
      this.iconUpload = data.iconUpload;
      this.iconName = data.iconLink && data.iconLink.lastIndexOf('/') !== -1 && data.iconLink.substring(data.iconLink.lastIndexOf('/') + 1, data.iconLink.length);
      this.errorMessage = data.errorMessage;
      this.error = data.error;
    } else {
      super();
      this.name = '';
      this.description = '';
      this.iconLink = '';
      this.integrationContacts = new Array<IntegrationContact>();
      this.iconUpload = null;
      this.iconName = '';
      this.errorMessage = '';
      this.error = false;
    }
  }
}
