<div class="search-container">
  <div class="button-filter-toggle-container">
    <button
      mat-raised-button
      disableRipple
      (click)="this.toggleFilters()"
      class="button-filter-toggle"
      [matTooltip]="getFiltersTooltip()"
      [matBadgeHidden]="getActiveFilterCount() <= 0"
      [matBadge]="getActiveFilterCount()">
      <mat-icon *ngIf="!showFilters">filter_list</mat-icon>
      <mat-icon *ngIf="showFilters">filter_list_off</mat-icon>
    </button>
  </div>

  <div class="input-search-box">
    <mat-form-field class="input-search input-search-detail" appearance="outline">
      <input
        (click)="$event.stopPropagation()"
        matInput
        placeholder="Search..."
        autocomplete="off"
        #input>
      <mat-icon
        *ngIf="!(loading$ | async)"
        matSuffix>search</mat-icon>
      <mat-icon
        class="loading"
        *ngIf="(loading$ | async)"
        matSuffix>sync</mat-icon>
    </mat-form-field>
  </div>

  <div class="button-paging-container" *ngIf="(page$ | async) as page">
    <button
      mat-icon-button
      disableRipple
      [disabled]="page.pageNumber === 1"
      (click)="this.previousPage()">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
  </div>

  <div class="button-paging" *ngIf="(page$ | async) as page">
    <button
      mat-icon-button
      disableRipple
      [disabled]="page.pageNumber === page.totalPageCount"
      (click)="this.nextPage()">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>
</div>

<div class="search-filters-container" *ngIf="showFilters">
  <div class="filter-label-container">
    <span>Filters</span>
  </div>
  <div class="filter-integrations-container">
    <app-integration-selection-dropdown
      [allowMultipleSelection]="true"
      [initialSelectedIntegrationIds]="integrationIds"
      [compact]="true"
      (selectionChanged)="onIntegrationSelectionsChanged($event)">
    </app-integration-selection-dropdown>
  </div>
</div>

<div class="search-results-container">
  <span *ngIf="(page$ | async) as page">Displaying {{ getPageRecordCountStart() }} - {{ getPageRecordCountEnd() }} of {{ +(page?.totalRecordCount) }} configurations.</span>
  <span *ngIf="!(page$ | async)">Displaying 0 of 0 configurations.</span>
</div>
