<div fxLayout="column">
  <div fxFlex class="card-title"><strong>{{title}}</strong></div>
  <div fxFlex class="card-sub-title">{{subtitle}}</div>
  <ng-container *ngIf="webLink; else webLinkElse">
    <div fxFlex class="card-link word-wrap-break-word">
      <a [href]="webLink" target="_blank" rel="noopener noreferrer">{{ webLink }}</a>
    </div>
  </ng-container>
  <ng-template #webLinkElse>
    <div fxFlex class="none">None</div>
  </ng-template>
</div>
