import { DamAssetMetapropertyConstants, DamProviderIds, ToggleImages } from './../../shared/constants';
import { MediaSubType } from './../models/media-subtype.model';
import { DocumentAsset } from './../models/document-asset.model';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PagedResults } from './../models/page-results.model';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DamAssetMetaproperty } from '../models/dam-asset-metaproperty.model';
import { DamAssetMetapropertyOption } from '../models/dam-asset-metaproperty-option.model';
import { SubSink } from 'subsink';
import * as fromStore from '../../app-store/reducers';
import { Store } from '@ngrx/store';
import { DamImageAssetResponse } from '../models/dam-image-asset-response.model';
import { DamAssetMetapropertyQuery } from '../models/dam-asset-metaproperty-query.model';
import { DamAssetsMetapropertyActions } from 'src/app/app-store/dam-assets-store';


@Injectable({
  providedIn: 'root'
})
export class DigitalAssetManagerService {
  basePath = 'dam';
  metaPropertyOptions: string;
  metaPropertyOmniOptions: string;
  private subscriptions: SubSink = new SubSink();

  constructor(private http: HttpClient,
    private store: Store<fromStore.State>) {
    this.subscriptions.add(this.store.select(fromStore.getLoadedDamAssetPurposeMetapropertyOptions).subscribe((assetMetapropertyOptions) => {
      this.metaPropertyOptions = assetMetapropertyOptions.map(option => option.id).join(",");
    }));
    this.subscriptions.add(this.store.select(fromStore.getLoadedDamAssetPurposeMetapropertyOmniOptions).subscribe((assetMetapropertyOptions) => {
      this.metaPropertyOmniOptions = assetMetapropertyOptions.map(option => option.id).join(",");
    }));
  }

  getImageAssets$(searchTerm: string, selectedMediaSubType: number, selectedLocale: string, pageNumber: number, pageSize: number, damProviderId: number = DamProviderIds.Bynder, toggleImageValue: number, bynderRefresh?: boolean): Observable<DamImageAssetResponse> {
    let params = new HttpParams()
      .set('searchTerm', searchTerm)
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString())
      .set('damProvider', damProviderId.toString());

    if (selectedLocale) {
      params = params.set('localeCode', selectedLocale);
    }

    if(toggleImageValue === ToggleImages.Harbour || !toggleImageValue && (!bynderRefresh))
    {
      if (this.metaPropertyOptions) {
        params = params.set('assetPropertyIds', this.metaPropertyOptions);
      }
    }
    else if (toggleImageValue === ToggleImages.Omni && (!bynderRefresh)) 
    {
      if (this.metaPropertyOmniOptions) {
        params = params.set('assetPropertyIds', this.metaPropertyOmniOptions);
      }
    }


    if (selectedMediaSubType) {
      params = params.set('mediaSubType', selectedMediaSubType.toString());
    }
    
    return this.http.get(`${environment.apiUrl}${this.basePath}/assets/images`, { params })
      .pipe(map((response: DamImageAssetResponse) => response)
      );
  }

  getDocumentAssets$(searchTerm: string, pageNumber: number, pageSize: number, damProviderId: number = 1): Observable<PagedResults<DocumentAsset>> {
    let params = new HttpParams()
      .set('searchTerm', searchTerm)
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString())
      .set('damProvider', damProviderId.toString());

    if (this.metaPropertyOptions) {
      params = params.set('assetPropertyIds', this.metaPropertyOptions);
    }

    return this.http.get(`${environment.apiUrl}${this.basePath}/assets/documents`, { params })
      .pipe(map((response: PagedResults<DocumentAsset>) => response)
      );
  }

  getAssetTags$(tag: string): Observable<Array<String>> {
    let params = new HttpParams()
      .set('searchTerm', tag);

    if (this.metaPropertyOptions) {
      params = params.set('assetPropertyIds', this.metaPropertyOptions);
    }

    return this.http.get(`${environment.apiUrl}${this.basePath}/assets/tags`, { params })
      .pipe(map((response: Array<String>) => response)
      );
  }

  getAssetMetapropertyDetails$(name: string): Observable<DamAssetMetaproperty> {
    return this.http.get(`${environment.apiUrl}${this.basePath}/assets/metaproperties/${name}`)
      .pipe(
        map((response: DamAssetMetaproperty) => response)
      );
  }

  

  getAssetMetapropertyOptions$(id: string, name: string, includeOnlyOptionsSubset: boolean = false, omniUser: boolean = false): Observable<Array<DamAssetMetapropertyOption>> {
    return this.http.get(`${environment.apiUrl}${this.basePath}/assets/metaproperties/options`, {
      params: new HttpParams()
        .set('id', id)
        .set('name', name)
        .set('includeOnlyOptionsSubset', includeOnlyOptionsSubset.toString())
        .set('omniUser', omniUser.toString())
    })
      .pipe(
        map((response: Array<DamAssetMetapropertyOption>) => response)
      );
  }



  getMediaSubTypes$(): Observable<Array<MediaSubType>> {
    return this.http.get(`${environment.apiUrl}${this.basePath}/mediasubtypes`)
      .pipe(
        map((response: Array<MediaSubType>) => response)
      );
  }

  preLoadBynderDamOptions(store: Store<fromStore.State>, subs: SubSink): void{
    store.dispatch(new DamAssetsMetapropertyActions.LoadDamAssetsMetaproperty(DamAssetMetapropertyConstants.MediaSubType));
    subs.add(store.select(fromStore.getLoadedDamMediaSubtypeMetaproperty).subscribe((mediaSubtypeMetaProperty) => {
      if (mediaSubtypeMetaProperty) {
        store.dispatch(new DamAssetsMetapropertyActions.LoadDamAssetsMetapropertyOptions({
          id: mediaSubtypeMetaProperty?.id,
          name: DamAssetMetapropertyConstants.MediaSubType,
          includeOnlyOptionsSubset: false,
          omniUser: false
        }));
        store.dispatch(new DamAssetsMetapropertyActions.LoadDamAssetsMetaproperty(DamAssetMetapropertyConstants.AssetPurpose));
        subs.add(store.select(fromStore.getLoadedDamAssetPurposeMetaproperty).subscribe(assetPurposeMetaproperty => {
          if (assetPurposeMetaproperty) 
          {
            store.dispatch(new DamAssetsMetapropertyActions.LoadDamAssetsMetapropertyOptions({ 
              id: assetPurposeMetaproperty?.id, 
              name: DamAssetMetapropertyConstants.AssetPurpose, 
              includeOnlyOptionsSubset: true,
              omniUser: true 
            }));    
          }
        }
        ));
        subs.add(store.select(fromStore.getLoadedDamAssetPurposeMetaproperty).subscribe(assetPurposeMetaproperty => {
          if (assetPurposeMetaproperty) 
          {
            store.dispatch(new DamAssetsMetapropertyActions.LoadDamAssetsMetapropertyOmniOptions({ 
              id: assetPurposeMetaproperty?.id, 
              name: DamAssetMetapropertyConstants.AssetPurpose, 
              includeOnlyOptionsSubset: true,
              omniUser: false 
            }));    
          }
        }
        ));
      }
    }));
  }
}
