<div class="dialog-size">
    <div mat-dialog-title>
      <span class="h6"><strong>{{ data.title }}</strong></span>
    </div>
  
    <div mat-dialog-content>
      <p>{{ data.message }}</p>
      <ul *ngFor="let product of data.productAttributeOptions">
        <li>
            <strong>{{ product.productName }}</strong>
            <ul>
              <li *ngFor="let attributeOptionName of getDisplayAttributeOptions(product); let i = index"
                  [matTooltip]="getFullListAttributeOptions(product)">
                  {{ attributeOptionName }}
                <span *ngIf="shouldShowEllipsis(i)"> ...</span>
              </li>
            </ul>
        </li>
      </ul>
    </div>
  
    <div mat-dialog-actions>
      <button        
        matBadgePosition="below"
        class="mat-raised-button"
        (click)="confirmAction(true)"
        [ngClass]="getButtonClass()">
        {{ data.buttonText }}
      </button>
    </div>
  </div>