export interface IBaseAuditModel {
  id: number;
  createdByUserName?: string;
  createdDate?: Date;
  lastModifiedByUserName?: string;
  lastModifiedDate?: Date;
}

export class BaseAuditModel {
  id: number;
  createdByUserName?: string;
  createdDate?: Date;
  lastModifiedByUserName?: string;
  lastModifiedDate?: Date;

  constructor(data?: IBaseAuditModel) {
    if (data) {
      this.id = data.id;
      this.createdByUserName = data.createdByUserName;
      this.createdDate = data.createdDate;
      this.lastModifiedByUserName = data.lastModifiedByUserName;
      this.lastModifiedDate = data.lastModifiedDate;
    } else {
      this.id = 0;
    }
  }
}
