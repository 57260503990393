import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import * as _ from 'lodash';

export interface IGlobalProductTag extends IBaseAuditModel {
  globalProductId: number;
  tag: string;
}

export class GlobalProductTag extends BaseAuditModel implements IGlobalProductTag {
  globalProductId: number;
  tag: string;

  constructor(data: IGlobalProductTag) {
    super(data);

    if (!data) {
      return;
    }

    _.assign(this, data);
  }
}
