import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IAlbum } from 'ngx-lightbox';
import { LightboxesService } from 'src/app/core/services/lightboxes.service';
import { Alert, CommonConstants } from '../../constants';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent {
  @Input() imageLink: string;
  @Input() iconName: string;
  @Input() iconNameFormControl: FormControl;
  @Input() iconUploadFormControl: FormControl;
  @Input() imageLinkFormControl: FormControl;
  @Input() attributeOptionImage: string;
  @Input() attributeOptionForm: FormGroup;

  error: boolean;
  errorMessage: string;
  alert = Alert;

  constructor(private lightboxesService: LightboxesService) { }

  iconUploadChange(event) {
    if (event?.target?.files?.length > 0) {
      this.error = false;
      this.errorMessage = '';
      this.imageLinkFormControl?.setValue('');
      this.iconUploadFormControl?.setValue(null);
      this.imageLink = '';
      this.iconName = '';

      const uploadedIcon = event.target.files[0] as File;
      // Don't allow file sizes over 5MB
      if (uploadedIcon.size < CommonConstants.IMG_MAX_SIZE) {
        const mimeType = uploadedIcon.type;
        if (mimeType.match(/image\/*/) == null) {
          this.errorMessage = 'Incorrect file type. Please select an image file for upload';
          this.error = true;
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(uploadedIcon);
        reader.onload = () => {
          this.imageLinkFormControl?.patchValue(reader?.result as string);
          this.attributeOptionForm?.controls['imageUrl'].patchValue(reader?.result as string);
        };

        this.iconName = uploadedIcon?.name;
        this.iconUploadFormControl?.patchValue(uploadedIcon);
        this.iconNameFormControl?.patchValue(uploadedIcon?.name);
        this.attributeOptionForm?.controls['iconUpload']?.patchValue(uploadedIcon);
        this.attributeOptionForm?.controls['iconName']?.patchValue(uploadedIcon?.name);
        this.attributeOptionForm?.controls['imageUrl']?.patchValue(reader?.result as string);
      }
      else
      {
        // Display error message
        this.errorMessage = 'Image file is too large to upload. Maximum allowed file size is 5 MB';
        this.error = true;
      }
    }
  }

  removeIcon() {
    this.error = false;
    this.errorMessage = '';
    this.imageLink = '';
    this.iconName = '';
    this.iconNameFormControl?.patchValue('');
    this.iconUploadFormControl?.patchValue(null);
    this.imageLinkFormControl?.patchValue(null);
    this.attributeOptionForm?.controls['imageUrl'].setValue('');
    this.attributeOptionForm?.controls['iconUpload'].setValue(null);
  }

  showLightbox() {
    this.lightboxesService.open(this.getAlbum());
  }

  private getAlbum(): IAlbum[] {
    return [
      {
        src: this.imageLink,
        caption: this.attributeOptionImage == 'attributeOptionImage' ? (this.attributeOptionForm?.controls['iconName']?.value != '' ? this.attributeOptionForm?.controls['iconName']?.value : this.getImageFileName(this.attributeOptionForm?.controls['imageUrl']?.value)) : this.iconName,
        thumb: this.imageLink,
        downloadUrl: this.imageLink
      }
    ];
  }

  getImageFileName(url: string): string {
    if (!url) { return null; }
    return url.substring(url.lastIndexOf('/') + 1);
  }

}
