import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() cssClassName: string;
  isOpen = true;
  cssClasses: Array<string> = new Array<string>();

  constructor() {
   }

  ngOnInit() {
    this.cssClasses.push('alert-message');
    this.cssClasses.push(this.cssClassName);
  }

  closeAlertMessage(){
    this.isOpen = false;
  }

}
