import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import { assign as _assign } from 'lodash-es';
import { Environment, IEnvironment } from './environment.model';

export interface IPortfolio extends IBaseAuditModel {
  name: string;
  description: string;
  image: string;
  environmentId: number;
  environmentName: string;
  environments: IEnvironment[];
}

export class Portfolio extends BaseAuditModel implements IPortfolio {
  name: string;
  description: string;
  image: string;
  environmentId: number;
  environmentName: string;
  environments: Environment[];

  constructor(data: IPortfolio) {
    super(data);

    if (!data) {
      return;
    }

    _assign(this, data);
  }
}
