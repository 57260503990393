import { MaterialsResponse } from './../../../core/models/material.model';
import { Action } from '@ngrx/store';

export enum MaterialApiActionTypes {
  FetchSuccess = '[Material/API] Fetch Success',
  FetchFailure = '[Material/API] Fetch Failure'
}

export class FetchSuccess implements Action {
  readonly type = MaterialApiActionTypes.FetchSuccess;

  constructor(public payload: MaterialsResponse) { }
}

export class FetchFailure implements Action {
  readonly type = MaterialApiActionTypes.FetchFailure;

  constructor(public payload: any) { }
}

export type MaterialApiActionsUnion =
  FetchSuccess
  | FetchFailure;
