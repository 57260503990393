import { Observable, Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollService implements OnDestroy {
  private scrollPosition = new Subject<number>();

  constructor() { }

  setScrollPosition(val: number) {
    this.scrollPosition.next(val);
  }

  getScrollPosition$(): Observable<number> {
    return this.scrollPosition.asObservable();
  }

  ngOnDestroy() {
    this.scrollPosition.unsubscribe();
  }
}
