<div fxFlex class="card-image-container">
  <ng-container *ngIf="imageLink; else imageUrlElse">
    <img class="card-image" [src]="imageLink" [alt]="getImageFileName(imageLink)" (click)="showLightbox()"
    onerror="this.src='../../../../../assets/images/placeholders/image_placeholder.png'">
  </ng-container>
  <ng-template #imageUrlElse>
    <img class="card-image card-empty-image" src="../../../../assets/images/placeholders/image_placeholder.png"
    [alt]="placeholderAlt">
  </ng-template>
</div>
<div fxFlex fxLayout="column" class="align-center-vertical">
  <div fxFlex>{{title}}</div>
    <ng-container *ngIf="getImageFileName(imageLink); else imageNameElse">
      <div fxFlex class="card-data-name">{{ getImageFileName(imageLink) }}</div>
    </ng-container>
    <ng-template #imageNameElse>
      <div fxFlex class="none">None</div>
    </ng-template>
    <div fxFlex></div>
  </div>
