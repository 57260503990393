import { IGlobalConfigurationAssociation } from './../../../core/models/global-configuration-association.model';
import { GlobalConfigurationDimensionsPut } from './../../../core/models/global-configurations-dimensions-put.model';
import { PagedResults } from './../../../core/models/page-results.model';
import { Action } from '@ngrx/store';
import { GlobalConfigurationPut, ProductNumberReprocessRequestPut } from 'src/app/core/models/global-configuration-put.model';
import { GlobalConfiguration, IGlobalConfiguration } from 'src/app/core/models/global-configuration.model';
import { GlobalConfigurationModelFilesProfilesPut } from 'src/app/core/models/global-configurations-model-files-profile-put.model';
import { GlobalConfigurationLeadtimesPut } from 'src/app/core/models/global-configurations-leadtimes-put.model';

export enum ConfigurationsApiActionTypes {
  LoadSuccess = '[Configurations/API] Fetch Success',
  LoadFailure = '[Configurations/API] Fetch Failure',
  CreateSuccess = '[Configurations/API] Create Success',
  CreateFailure = '[Configurations/API] Create Failure',
  UpdateSuccess = '[Configurations/API] Update Success',
  UpdateFailure = '[Configurations/API] Update Failure',
  UpdateSKUSuccess = '[Configurations/API] Update SKU Success',
  UpdateSKUFailure = '[Configurations/API] Update SKU Failure',
  UpdateDimensionsSuccess = '[Configurations/API] Update Dimensions Success',
  UpdateDimensionsFailure = '[Configurations/API] Update Dimensions Failure',
  UpdateLeadTimesSuccess = '[Configurations/API] Update LeadTimes Success',
  UpdateLeadTimesFailure = '[Configurations/API] Update LeadTimes Failure',
  DeleteSuccess = '[Configurations/API] Delete Success',
  DeleteFailure = '[Configurations/API] Delete Failure',
  RenameSuccess = '[Configurations/API] Rename Success',
  RenameFailure = '[Configurations/API] Rename Failure',
  DuplicateSuccess = '[Configurations/API] Duplicate Success',
  DuplicateFailure = '[Configurations/API] Duplicate Failure',
  LoadByProductSuccess = '[Configurations/API] Load By Product Success',
  LoadByProductFailure = '[Configurations/API] Load By Product Failure',
  UpdateModelFilesProfilesSuccess = '[Configurations/API] Update Model Files Profiles Success',
  UpdateModelFilesProfilesFailure = '[Configurations/API] Update Model Files Profiles Failure',
  LoadAssociationsSuccess = '[Configurations/API] Load Associations Success',
  LoadAssociationsFailure = '[Configurations/API] Load Associations Failure',
}

export class LoadSuccess implements Action {
  readonly type = ConfigurationsApiActionTypes.LoadSuccess;
  constructor(public payload: IGlobalConfiguration) { }
}

export class LoadFailure implements Action {
  readonly type = ConfigurationsApiActionTypes.LoadFailure;
  constructor(public payload: any) { }
}

export class CreateSuccess implements Action {
  readonly type = ConfigurationsApiActionTypes.CreateSuccess;
  constructor(public payload: GlobalConfiguration) { }
}

export class CreateFailure implements Action {
  readonly type = ConfigurationsApiActionTypes.CreateFailure;
  constructor(public payload: any) { }
}

export class UpdateSuccess implements Action {
  readonly type = ConfigurationsApiActionTypes.UpdateSuccess;
  constructor(public payload: GlobalConfigurationPut) {}
}

export class UpdateFailure implements Action {
  readonly type = ConfigurationsApiActionTypes.UpdateFailure;
  constructor(public payload: any) { }
}

export class UpdateSKUSuccess implements Action {
  readonly type = ConfigurationsApiActionTypes.UpdateSKUSuccess;
  constructor(public payload: ProductNumberReprocessRequestPut) {}
}

export class UpdateSKUFailure implements Action {
  readonly type = ConfigurationsApiActionTypes.UpdateSKUFailure;
  constructor(public payload: any) { }
}

export class UpdateDimensionsSuccess implements Action {
  readonly type = ConfigurationsApiActionTypes.UpdateDimensionsSuccess;
  constructor(public payload: GlobalConfigurationDimensionsPut) {}
}

export class UpdateDimensionsFailure implements Action {
  readonly type = ConfigurationsApiActionTypes.UpdateDimensionsFailure;
  constructor(public payload: any) { }
}

export class UpdateLeadTimesSuccess implements Action {
  readonly type = ConfigurationsApiActionTypes.UpdateLeadTimesSuccess;
  constructor(public payload: GlobalConfigurationLeadtimesPut) {}
}

export class UpdateLeadTimesFailure implements Action {
  readonly type = ConfigurationsApiActionTypes.UpdateLeadTimesFailure;
  constructor(public payload: any) { }
}

export class RenameSuccess implements Action {
  readonly type = ConfigurationsApiActionTypes.RenameSuccess;
  constructor(public payload: { globalConfiguration: GlobalConfiguration, newName: string }) { }
}

export class RenameFailure implements Action {
  readonly type = ConfigurationsApiActionTypes.RenameFailure;
  constructor(public payload: any) { }
}

export class DeleteSuccess implements Action {
  readonly type = ConfigurationsApiActionTypes.DeleteSuccess;
  constructor(public payload: { globalConfiguration: GlobalConfiguration }) { }
}

export class DeleteFailure implements Action {
  readonly type = ConfigurationsApiActionTypes.DeleteFailure;
  constructor(public payload: any) { }
}

export class DuplicateSuccess implements Action {
  readonly type = ConfigurationsApiActionTypes.DuplicateSuccess;
  constructor(public payload: { globalConfiguration: GlobalConfiguration, newName: string }) { }
}

export class DuplicateFailure implements Action {
  readonly type = ConfigurationsApiActionTypes.DuplicateFailure;
  constructor(public payload: any) { }
}

export class LoadByProductSuccess implements Action {
  readonly type = ConfigurationsApiActionTypes.LoadByProductSuccess;
  constructor(public payload: PagedResults<GlobalConfiguration>) { }
}

export class LoadByProductFailure implements Action {
  readonly type = ConfigurationsApiActionTypes.LoadByProductFailure;
  constructor(public payload: any) { }
}

export class UpdateModelFilesProfilesSuccess implements Action {
  readonly type = ConfigurationsApiActionTypes.UpdateModelFilesProfilesSuccess;
  constructor(public payload: GlobalConfigurationModelFilesProfilesPut) {}
}

export class UpdateModelFilesProfilesFailure implements Action {
  readonly type = ConfigurationsApiActionTypes.UpdateModelFilesProfilesFailure;
  constructor(public payload: any) {}
}

export class LoadAssociationsSuccess implements Action {
  readonly type = ConfigurationsApiActionTypes.LoadAssociationsSuccess;
  constructor(public payload: Array<IGlobalConfigurationAssociation>) { }
}

export class LoadAssociationsFailure implements Action {
  readonly type = ConfigurationsApiActionTypes.LoadAssociationsFailure;
  constructor(public payload: any) { }
}

export type ConfigurationsApiActionsUnion =
    LoadSuccess
  | LoadFailure
  | CreateSuccess
  | CreateFailure
  | UpdateSuccess
  | UpdateFailure
  | UpdateSKUSuccess
  | UpdateSKUFailure
  | UpdateDimensionsSuccess
  | UpdateDimensionsFailure
  | UpdateLeadTimesSuccess
  | UpdateLeadTimesFailure
  | RenameSuccess
  | RenameFailure
  | DeleteSuccess
  | DeleteFailure
  | DuplicateSuccess
  | DuplicateFailure
  | LoadByProductSuccess
  | LoadByProductFailure
  | UpdateModelFilesProfilesSuccess
  | UpdateModelFilesProfilesFailure
  | LoadAssociationsSuccess
  | LoadAssociationsFailure;
