import { GlobalProductOffering, IGlobalProductOffering } from './../models/global-product-offering.model';
import { IIntegrationProductOfferingSummary, IntegrationProductOfferingSummary } from './../models/integration-product-offering-summary.model';
import { environment } from './../../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalProductOfferingPut } from '../models/global-product-offering-put.model';
import { RenamePut } from '../models/rename-put.model';
import { GlobalProductOfferingDuplicateCheck } from '../models/global-product-offering-duplicate-check.model';
import { GlobalProductOfferingSummary } from '../models/global-product-offering-summary.model';


@Injectable({
  providedIn: 'root',
})
export class GlobalProductOfferingsService {
  basePath = 'globalproductofferings';

  constructor(private http: HttpClient) { }

  getGlobalProductOfferings$(productId: number): Observable<Array<IntegrationProductOfferingSummary>> {
    return this.http
      .get(environment.apiUrl + `globalproducts/${productId}/globalproductofferings`)
      .pipe(
        map(res => res),
        catchError((err: Response) => {
          const obj = err;
          return throwError(obj);
        }),
        map((list: Array<IIntegrationProductOfferingSummary>) => list.map(m => new IntegrationProductOfferingSummary(m)))
      );
  }

  getGlobalProductOffering$(id: number): Observable<GlobalProductOffering> {
    return this.http
      .get(environment.apiUrl + `${this.basePath}/${id}`)
      .pipe(
        map(response => response),
        catchError((err: Response) => {
          const obj = err;
          return throwError(obj);
        }),
        map((x: IGlobalProductOffering) => new GlobalProductOffering(x))
      );
  }

  createProductOffering$(productOffering: GlobalProductOffering): Observable<GlobalProductOffering> {
    return this.http.post(environment.apiUrl + `${this.basePath}`, productOffering)
      .pipe(
        map(res => res),
        catchError((err: Response) => {
          const obj = err;
          return throwError(obj);
        }),
        map((createdOffer: IGlobalProductOffering) => new GlobalProductOffering(createdOffer)),
      );
  }

  UpdateProductOffering$(productOffering: GlobalProductOfferingPut): Observable<any> {
    return this.http.put(`${environment.apiUrl}${this.basePath}/${productOffering.id}`, productOffering)
      .pipe(
        map(res => res),
        catchError((err: Response) => {
          const obj = err;
          return throwError(obj);
        })
      );
  }

  deleteProductOffering$(productOffering: GlobalProductOffering): Observable<any> {
    return this.http.delete(environment.apiUrl + `${this.basePath}/${productOffering.id}`)
      .pipe(
        map(res => res),
        catchError((err: Response) => {
          const obj = err;
          return throwError(obj);
        })
      );
  }

  renameProductOffering$(request: { offeringId: number, offeringName: string }): Observable<any> {
    const renamePut: RenamePut = new RenamePut({ name: request.offeringName });
    return this.http.put(environment.apiUrl + `${this.basePath}/${request.offeringId}/rename`,
      renamePut, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
    );
  }

  getDuplicates$(portfolioName: string, templateId: number, globalProductId: number): Observable<Array<GlobalProductOfferingSummary>> {
    const request: GlobalProductOfferingDuplicateCheck = new GlobalProductOfferingDuplicateCheck();
    request.name = portfolioName;
    request.templateId = templateId;
    request.globalProductId = globalProductId;

    return this.http
      .post(`${environment.apiUrl}${this.basePath}/duplicates`, request)
      .pipe(
        map(response => response),
        catchError((error: Response) => {
          return throwError(error);
        }),
        map((response: Array<GlobalProductOfferingSummary>) => response)
      );
  }
}
