import { GlobalProductPut } from './../../../core/models/global-product-put.model';
import { Action } from '@ngrx/store';
import { GlobalProduct } from 'src/app/core/models/global-product.model';
import {GlobalConfiguration} from '../../../core/models/global-configuration.model';

export enum ProductApiActionTypes {
  LoadSuccess = '[Product/API] Fetch Success',
  LoadFailure = '[Product/API] Fetch Failure',
  CreateSuccess = '[Product/API] Create Success',
  CreateFailure = '[Product/API] Create Failure',
  UpdateSuccess = '[Product/API] Update Success',
  UpdateFailure = '[Product/API] Update Failure',
  DeleteSuccess = '[Product/API] Delete Success',
  DeleteFailure = '[Product/API] Delete Failure',
  RenameProductSuccess = '[Product/API] Rename Product Success',
  RenameProductFailure = '[Product/API] Rename Product Failure',
  RenameConfigurationSuccess = '[Product/API] Rename Configuration Success',
  RenameConfigurationFailure = '[Product/API] Rename Configuration Failure',
  DeleteConfigurationSuccess = '[Product/API] Delete Configuration Success',
  DeleteConfigurationFailure = '[Product/API] Delete Configuration Failure',
  DuplicateConfigurationSuccess = '[Product/API] Duplicate Configuration Success',
  DuplicateConfigurationFailure = '[Product/API] Duplicate Configuration Failure'
}

export class LoadSuccess implements Action {
  readonly type = ProductApiActionTypes.LoadSuccess;

  constructor(public payload: GlobalProduct) { }
}

export class LoadFailure implements Action {
  readonly type = ProductApiActionTypes.LoadFailure;

  constructor(public payload: any) { }
}

export class CreateSuccess implements Action {
  readonly type = ProductApiActionTypes.CreateSuccess;

  constructor(public payload: GlobalProduct) { }
}

export class CreateFailure implements Action {
  readonly type = ProductApiActionTypes.CreateFailure;

  constructor(public payload: any) { }
}

export class UpdateSuccess implements Action {
  readonly type = ProductApiActionTypes.UpdateSuccess;

  constructor(public payload: GlobalProductPut) {}
}

export class UpdateFailure implements Action {
  readonly type = ProductApiActionTypes.UpdateFailure;

  constructor(public payload: any) { }
}

export class DeleteSuccess implements Action {
  readonly type = ProductApiActionTypes.DeleteSuccess;

  constructor(public payload: GlobalProduct) { }
}

export class DeleteFailure implements Action {
  readonly type = ProductApiActionTypes.DeleteFailure;

  constructor(public payload: GlobalProduct) { }
}

export class RenameProductSuccess implements Action {
  readonly type = ProductApiActionTypes.RenameProductSuccess;

  constructor(public payload: { product: GlobalProduct, newName: string }) { }
}

export class RenameProductFailure implements Action {
  readonly type = ProductApiActionTypes.RenameProductFailure;

  constructor(public payload: { product: GlobalProduct }) { }
}

export class RenameConfigurationSuccess implements Action {
  readonly type = ProductApiActionTypes.RenameConfigurationSuccess;

  constructor(public payload: { configuration: GlobalConfiguration, newName: string }) { }
}

export class RenameConfigurationFailure implements Action {
  readonly type = ProductApiActionTypes.RenameConfigurationFailure;

  constructor(public payload: { configuration: GlobalConfiguration }) { }
}

export class DeleteConfigurationSuccess implements Action {
  readonly type = ProductApiActionTypes.DeleteConfigurationSuccess;

  constructor(public payload: { configuration: GlobalConfiguration }) { }
}

export class DeleteConfigurationFailure implements Action {
  readonly type = ProductApiActionTypes.DeleteConfigurationFailure;

  constructor(public payload: { configuration: GlobalConfiguration }) { }
}

export class DuplicateConfigurationSuccess implements Action {
  readonly type = ProductApiActionTypes.DuplicateConfigurationSuccess;

  constructor(public payload: { configuration: GlobalConfiguration, newName: string }) { }
}

export class DuplicateConfigurationFailure implements Action {
  readonly type = ProductApiActionTypes.DuplicateConfigurationFailure;

  constructor(public payload: { configuration: GlobalConfiguration }) { }
}

export type ProductApiActionsUnion =
  LoadSuccess
  | LoadFailure
  | CreateSuccess
  | CreateFailure
  | UpdateSuccess
  | UpdateFailure
  | DeleteSuccess
  | DeleteFailure
  | RenameProductSuccess
  | RenameProductFailure
  | RenameConfigurationSuccess
  | RenameConfigurationFailure
  | DeleteConfigurationSuccess
  | DeleteConfigurationFailure
  | DuplicateConfigurationSuccess
  | DuplicateConfigurationFailure
;
