<div>
  <div fxLayout="column" fxFlex class="align-right">
    <ng-container *ngIf="values?.length > 0; else valuesElse">
      <ng-container *ngFor="let value of values">
        <div fxFlex class="list-item">{{ value }}</div>
      </ng-container>
    </ng-container>
  </div>
  <ng-template #valuesElse>
    <div fxFlex class="none align-right">None</div>
  </ng-template>
</div>
