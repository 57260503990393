import { Action } from '@ngrx/store';
import { BrandPost } from 'src/app/core/models/brand-post.model';
import { BrandPut } from 'src/app/core/models/brand-put.model';

export enum BrandActionTypes {
  LoadBrands = '[Brand] Load Brands',
  LoadBrand = '[Brand] Load Brand',
  CreateBrand = '[Brand] Create Brand',
  UpdateBrand = '[Brand] Update Brand',
  RenameBrand = '[Brand] Rename Brand',
  DeleteBrand = '[Brand] Delete Brand',
  ClearMessages = '[Brand] Clear Messages'
}

export class LoadBrands implements Action {
  readonly type = BrandActionTypes.LoadBrands;

  constructor(public payload: string) { }
}

export class LoadBrand implements Action {
  readonly type = BrandActionTypes.LoadBrand;

  constructor(public payload: number) { }
}

export class CreateBrand implements Action {
  readonly type = BrandActionTypes.CreateBrand;

  constructor(public payload: BrandPost) { }
}

export class UpdateBrand implements Action {
  readonly type = BrandActionTypes.UpdateBrand;

  constructor(public payload: { brand: BrandPut, brandName: string }) { }
}

export class RenameBrand implements Action {
  readonly type = BrandActionTypes.RenameBrand;

  constructor(public payload: { brandId: number, brandName: string }) { }
}

export class DeleteBrand implements Action {
  readonly type = BrandActionTypes.DeleteBrand;

  constructor(public payload: { brandId: number, brandName: string }) { }
}

export class ClearMessages implements Action {
  readonly type = BrandActionTypes.ClearMessages;

  constructor() { }
}

export type BrandActionsUnion =
  LoadBrands
  | LoadBrand
  | CreateBrand
  | UpdateBrand
  | RenameBrand
  | DeleteBrand
  | ClearMessages;
