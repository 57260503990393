<div>
  <div mat-dialog-title>
    <h6 class="h6">
      <strong>Add Images</strong>
    </h6>
  </div>

  <div mat-dialog-content class="content-container">
    <mat-tab-group class="tabs-container" (selectedTabChange)="setView($event)">
      <mat-tab label="Digital Asset Library">
        <app-digital-asset-library
          [datasource]="datasource">
        </app-digital-asset-library>
      </mat-tab>
      <mat-tab label="Image URL" (click)="datasource.visibleView = Views.ImageUrl">
        <app-image-url
          [datasource]="datasource" [mediaSubTypes]="data.mediaSubTypes" [productImages]="data.productImages">
        </app-image-url>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div mat-dialog-actions>
    <ng-container *ngIf="datasource.visibleView === Views.ImageGrid">
      <a
        *ngIf="datasource.isMultiple"
        class="clear-selection button-spacing"
        (click)="clearSelection()">
        Clear Selection
      </a>
      <button
        class="mat-raised-button button-spacing"
        [mat-dialog-close]="result">
        Cancel
      </button>
      <button
        class="mat-raised-button primary-button"
        [disabled]="datasource.selectedImages.length === 0"
        (click)="applyImages();">
        <ng-container *ngIf="!datasource.isMultiple">
          Add Image
        </ng-container>
        <ng-container *ngIf="datasource.isMultiple">
          Add Images
          ({{datasource.selectedImages.length}})
        </ng-container>
      </button>
    </ng-container>

    <ng-container *ngIf="datasource.visibleView === Views.ImageUrl">
      <button class="mat-raised-button button-spacing" [mat-dialog-close]="result">Cancel</button>
      <button
        class="mat-raised-button primary-button"
        [disabled]="!datasource.addImageUrlFormGroup.valid"
        (click)="applyImageUrl()">
        Add Image
      </button>
    </ng-container>
  </div>

</div>
