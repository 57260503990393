import {
  CategoryApiActions,
  CategoryActions
} from '../actions';

import { Category } from 'src/app/core/models/category.model';
import { CategoryStatus } from 'src/app/shared/constants';

export interface State {
  category: Category;
  loading: boolean;
  loaded: boolean;
  status: CategoryStatus;
  error: string;
  success: string;
}

export const initialState: State = {
  category: null,
  loading: false,
  loaded: false,
  status: CategoryStatus.None,
  error: null,
  success: null
};

export function reducer(state = initialState, action: CategoryActions.CategoryActionsUnion | CategoryApiActions.CategoryApiActionsUnion): State {
  switch (action.type) {

    case CategoryActions.CategoryActionTypes.LoadCategory: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case CategoryApiActions.CategoryApiActionTypes.LoadSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        category: action.payload
      };
    }

    case CategoryApiActions.CategoryApiActionTypes.LoadFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        category: null,
        error: action.payload
      };
    }

    case CategoryActions.CategoryActionTypes.ClearMessages: {
      return {
        ...state,
        status: CategoryStatus.None,
        error: null,
        success: null
      };
    }

    case CategoryActions.CategoryActionTypes.CreateCategory: {
      return {
        ...state,
      };
    }

    case CategoryApiActions.CategoryApiActionTypes.CreateSuccess: {
      return {
        ...state,
        category: action.payload,
        status: CategoryStatus.CreateSuccess,
      };
    }

    case CategoryApiActions.CategoryApiActionTypes.CreateFailure: {
      return {
        ...state,
        category: null,
        status: CategoryStatus.CreateFailure,
        error: 'Failed to Create Brand Category',
        success: null
      };
    }

    case CategoryActions.CategoryActionTypes.UpdateCategory: {
      return {
        ...state,
        loading: true,
        loaded: false,
        status: CategoryStatus.None
      };
    }

    case CategoryApiActions.CategoryApiActionTypes.UpdateSuccess: {
      return {
        ...state,
        status: CategoryStatus.UpdateSuccess,
        error: null,
        success: `Successfully Updated Brand Category: ${action.payload.categoryName}`
      };
    }

    case CategoryApiActions.CategoryApiActionTypes.UpdateFailure: {
      return {
        ...state,
        loading: false,
        loaded: true,
        status: CategoryStatus.UpdateFailure,
        error: 'Failed to Update Brand Category',
        success: null
      };
    }

    case CategoryActions.CategoryActionTypes.RenameCategory: {
      return {
        ...state
      };
    }

    case CategoryApiActions.CategoryApiActionTypes.RenameSuccess: {
      const category = { ...state.category };
      category.name = action.payload.categoryName;

      return {
        ...state,
        status: CategoryStatus.RenameSuccess,
        error: null,
        success: `Successfully renamed Brand Category: ${action.payload.categoryName}`
      };
    }

    case CategoryApiActions.CategoryApiActionTypes.RenameFailure: {
      return {
        ...state,
        category: null,
        status: CategoryStatus.RenameFailure,
        error: 'Failed to rename Brand Category',
        success: null
      };
    }

    case CategoryActions.CategoryActionTypes.DeleteCategory: {
      return {
        ...state,
      };
    }

    case CategoryApiActions.CategoryApiActionTypes.DeleteSuccess: {
      return {
        ...state,
        status: CategoryStatus.DeleteSuccess,
        error: null,
        success: `Successfully Deleted Brand Category: ${action.payload.categoryName}`
      };
    }

    case CategoryApiActions.CategoryApiActionTypes.DeleteFailure: {
      return {
        ...state,
        category: null,
        status: CategoryStatus.DeleteFailure,
        error: 'Failed to Delete Brand Category',
        success: null
      };
    }

    default:
      return state;
  }
}

export const getCategory = (state: State) => state.category;
export const getErrorMessage = (state: State) => state.error;
export const getSuccessMessage = (state: State) => state.success;
export const getStatus = (state: State) => state.status;
export const isLoading = (state: State) => state.loading;
export const isLoaded = (state: State) => state.loaded;
