import { MediaSubType } from './../../../core/models/media-subtype.model';
import { Action } from '@ngrx/store';
import { DamAssetMetapropertyOption } from 'src/app/core/models/dam-asset-metaproperty-option.model';
import { DamAssetMetaproperty } from 'src/app/core/models/dam-asset-metaproperty.model';

export enum DamAssetsMetapropertyApiActionTypes {
  LoadDamAssetsMetapropertySuccess = '[DamAssetsMetaproperty/API] Load Success',
  LoadDamAssetsMetapropertyFailure = '[DamAssetsMetaproperty/API] Load Failure',
  LoadDamAssetsMetapropertyOptionsSuccess = '[DamAssetsMetapropertyOptions/API] Load Success',
  LoadDamAssetsMetapropertyOptionsFailure = '[DamAssetsMetapropertyOptions/API] Load Failure',
  LoadDamAssetsMetapropertyOmniOptionsSuccess = '[DamAssetsMetapropertyOmniOptions/API] Load Success',
  LoadDamAssetsMetapropertyOmniOptionsFailure = '[DamAssetsMetapropertyOmniOptions/API] Load Failure',
  LoadMediaSubTypesSuccess = '[MediaSubType] LoadSuccess',
  LoadMediaSubTypesFailure = '[MediaSubType] LoadFailure',
}

export class LoadDamAssetsMetapropertySuccess implements Action {
  readonly type = DamAssetsMetapropertyApiActionTypes.LoadDamAssetsMetapropertySuccess;

  constructor(public payload: { metaProperty: DamAssetMetaproperty, code: string }) { }
}

export class LoadDamAssetsMetapropertyFailure implements Action {
  readonly type = DamAssetsMetapropertyApiActionTypes.LoadDamAssetsMetapropertyFailure;

  constructor(public payload: any) { }
}

export class LoadDamAssetsMetapropertyOptionsSuccess implements Action {
  readonly type = DamAssetsMetapropertyApiActionTypes.LoadDamAssetsMetapropertyOptionsSuccess;

  constructor(public payload: { metaPropertyOption: Array<DamAssetMetapropertyOption>, metaProperty: string }) { }
}

export class LoadDamAssetsMetapropertyOptionsFailure implements Action {
  readonly type = DamAssetsMetapropertyApiActionTypes.LoadDamAssetsMetapropertyOptionsFailure;

  constructor(public payload: any) { }
}

export class LoadDamAssetsMetapropertyOmniOptionsSuccess implements Action {
  readonly type = DamAssetsMetapropertyApiActionTypes.LoadDamAssetsMetapropertyOmniOptionsSuccess;

  constructor(public payload: { metaPropertyOption: Array<DamAssetMetapropertyOption>, metaProperty: string }) { }
}

export class LoadDamAssetsMetapropertyOmniOptionsFailure implements Action {
  readonly type = DamAssetsMetapropertyApiActionTypes.LoadDamAssetsMetapropertyOmniOptionsFailure;

  constructor(public payload: any) { }
}

export class LoadMediaSubTypesSuccess implements Action {
  readonly type = DamAssetsMetapropertyApiActionTypes.LoadMediaSubTypesSuccess;

  constructor(public payload: Array<MediaSubType>) { }
}

export class LoadMediaSubTypesFailure implements Action {
  readonly type = DamAssetsMetapropertyApiActionTypes.LoadMediaSubTypesFailure;

  constructor(public payload: any) { }
}

export type DamAssetsMetapropertyApiActionsUnion =
  LoadDamAssetsMetapropertySuccess
  | LoadDamAssetsMetapropertyFailure
  | LoadDamAssetsMetapropertyOptionsSuccess
  | LoadDamAssetsMetapropertyOptionsFailure
  | LoadDamAssetsMetapropertyOmniOptionsSuccess
  | LoadDamAssetsMetapropertyOmniOptionsFailure
  | LoadMediaSubTypesSuccess
  | LoadMediaSubTypesFailure
  ;
