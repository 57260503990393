<div fxLayout="row" class="manual-entry-container-input">
    <mat-form-field class="wrapper-none" appearance="outline" >
      <mat-label>Enter a model files download link URL</mat-label>
      <input autocomplete="off"
        matInput
        [formControl]="cadLinkEntryInput"
        #cadLinkUrlInput
        [errorStateMatcher]="errorStateMatcher"
        >
      <mat-error class="error-message" *ngIf="cadLinkEntryInput.invalid">{{ getValidationErrorMessage() }}</mat-error>
    </mat-form-field>
</div>
