import { Action } from '@ngrx/store';
import { GlobalProductOfferingPut } from 'src/app/core/models/global-product-offering-put.model';
import { GlobalProductOffering } from 'src/app/core/models/global-product-offering.model';

export enum ProductOfferingActionTypes {
  LoadProductOffering = '[ProductOffering] Load Product Offering',
  LoadProductOfferingByProductId = '[ProductOffering] Load Product Offering By Product Id',
  CreateProductOffering = '[ProductOffering] Create',
  UpdateProductOffering = '[ProductOffering] Update',
  DeleteProductOffering = '[ProductOffering] Delete',
  RenameProductOffering = '[ProductOffering] Rename Product Offering',
  ClearMessages = '[ProductOffering] Clear Messages',
  ClearProductOffering = '[ProductOffering] Clear Product Offering',
  ClearProductOfferingSummaries = '[ProductOffering] Clear Product Offering Summaries',
}

export class LoadProductOffering implements Action {
  readonly type = ProductOfferingActionTypes.LoadProductOffering;

  constructor(public payload: number) { }
}

export class LoadProductOfferingByProductId implements Action {
  readonly type = ProductOfferingActionTypes.LoadProductOfferingByProductId;

  constructor(public payload: number) { }
}

export class CreateProductOffering implements Action {
  readonly type = ProductOfferingActionTypes.CreateProductOffering;

  constructor(public payload: GlobalProductOffering) { }
}
export class UpdateProductOffering implements Action {
  readonly type = ProductOfferingActionTypes.UpdateProductOffering;

  constructor(public payload: GlobalProductOfferingPut) { }
}

export class DeleteProductOffering implements Action {
  readonly type = ProductOfferingActionTypes.DeleteProductOffering;

  constructor(public payload: { productOffering: GlobalProductOffering }) { }
}

export class RenameProductOffering implements Action {
  readonly type = ProductOfferingActionTypes.RenameProductOffering;

  constructor(public payload: { offeringId: number, productId: number, offeringName: string }) { }
}

export class ClearMessages implements Action {
  readonly type = ProductOfferingActionTypes.ClearMessages;

  constructor() {
  }
}

export class ClearProductOffering implements Action {
  readonly type = ProductOfferingActionTypes.ClearProductOffering;

  constructor() {
  }
}
export class ClearProductOfferingSummaries implements Action {
  readonly type = ProductOfferingActionTypes.ClearProductOfferingSummaries;

  constructor() {
  }
}

export type ProductOfferingActionsUnion =
  LoadProductOffering
  | LoadProductOfferingByProductId
  | CreateProductOffering
  | UpdateProductOffering
  | DeleteProductOffering
  | RenameProductOffering
  | ClearMessages
  | ClearProductOffering
  | ClearProductOfferingSummaries;
