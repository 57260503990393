import { IContact } from './contact.model';
import { MediaSubType } from 'src/app/core/models/media-subtype.model';
import { IMediaSubType } from './media-subtype.model';
import { IDamMediaLink } from './dam-media-link.model';
import { IMediaType } from './media-type.model';
import { MediaType } from 'src/app/core/models/media-type.model';
import { ILocale } from './locale.model';
import { Locale } from 'src/app/core/models/locale.model';
import { Subcategory } from 'src/app/core/models/subcategory.model';
import { GlobalSubcategory } from 'src/app/core/models/global-subcategory.model';
import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import * as _ from 'lodash';
import { FamilyBrand, IFamilyBrand } from './family-brand.model';
export interface IFamily extends IBaseAuditModel {
  name: string;
  shortDescription: string;
  description: string;
  familyBrands: Array<IFamilyBrand>;
  familySubcategories: Array<IFamilySubcategory>;
  familyGlobalSubcategories: Array<IFamilyGlobalSubcategory>;
  familyLocales: Array<IFamilyLocale>;
  familyMediaLinks: Array<IFamilyMediaLink>;
  familyContacts: Array<IFamilyContact>;
  scoutLink: string;
  omniLink: string;
}
export class Family extends BaseAuditModel implements IFamily {
  name: string;
  shortDescription: string;
  description: string;
  familyBrands: Array<FamilyBrand> = [];
  familySubcategories: IFamilySubcategory[] = [];
  familyGlobalSubcategories: IFamilyGlobalSubcategory[] = [];
  familyLocales: IFamilyLocale[] = [];
  familyMediaLinks: IFamilyMediaLink[] = [];
  familyContacts: Array<IFamilyContact> = [];
  scoutLink: string;
  omniLink: string;

  constructor(data: IFamily) {
    super(data);
    if (!data) {
      return;
    }
    _.assign(this, data);
  }
}

export interface IFamilySubcategory extends IBaseAuditModel {
  familyId: number;
  subcategoryId: number;
  subcategory: Subcategory;
}

export class FamilySubcategory extends BaseAuditModel implements IFamilySubcategory {
  familyId: number;
  subcategoryId: number;
  subcategory: Subcategory;

  constructor(data: IFamilySubcategory) {
    super(data);

    if (!data) {
      return;
    }

    _.assign(this, data);
  }
}

export interface IFamilyGlobalSubcategory extends IBaseAuditModel {
  familyId: number;
  globalSubcategoryId: number;
  globalSubcategory: GlobalSubcategory;
}

export class FamilyGlobalSubcategory extends BaseAuditModel implements IFamilyGlobalSubcategory {
  familyId: number;
  globalSubcategoryId: number;
  globalSubcategory: GlobalSubcategory;

  constructor(data: IFamilyGlobalSubcategory) {
    super(data);

    if (!data) {
      return;
    }

    _.assign(this, data);
  }
}

export interface IFamilyLocale extends IBaseAuditModel {
  familyId: number;
  localeId: number;
  locale: Locale;
}

export class FamilyLocale extends BaseAuditModel implements IFamilyLocale {
  familyId: number;
  localeId: number;
  locale: Locale;

  constructor(data: IFamilyLocale) {
    super(data);

    if (!data) {
      return;
    }

    _.assign(this, data);
  }
}

export interface IFamilyMediaLink extends IBaseAuditModel, IDamMediaLink {
  familyId: number;
  ordinal: number;
  localeId: number;
  locale: ILocale;
  mediaTypeId: number;
  mediaType: IMediaType;
  mediaSubTypeId: number;
  mediaSubType: IMediaSubType;
  mediaLink: string;
  title: string;
  altText: string;
  damProviderId: number;
  bynderId?: string;
}

export class FamilyMediaLink extends BaseAuditModel implements IFamilyMediaLink {
  familyId: number;
  ordinal: number;
  localeId: number;
  locale: Locale;
  mediaTypeId: number;
  mediaType: MediaType;
  mediaSubTypeId: number;
  mediaSubType: MediaSubType;
  mediaLink: string;
  title: string;
  altText: string;
  damProviderId: number;
  bynderId?: string;

  constructor(data?: IFamilyMediaLink) {
    super(data);

    if (!data) {
      return;
    }
    _.assign(this, data);
  }
}

export interface IFamilyContact extends IBaseAuditModel {
  familyId: number;
  contactId: number;
  contact: IContact;
  localeId: number;
  locale: ILocale;
}

export class FamilyContact extends BaseAuditModel implements IFamilyContact {
  familyId: number;
  contactId: number;
  contact: IContact;
  localeId: number;
  locale: ILocale;

  constructor(data?: IFamilyContact) {
    super(data);

    if (!data) {
      return;
    }
    _.assign(this, data);
  }
}
