import { DamProviderIds } from '../../../constants';
import { IDamMediaLink } from '../../../../core/models/dam-media-link.model';
import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-view-image-details-url',
  templateUrl: './view-image-details-url.component.html',
  styleUrls: ['./view-image-details-url.component.scss']
})
export class ViewImageDetailsUrlComponent implements OnInit {
  @Input() mediaLink: IDamMediaLink;

  constructor() { }

  ngOnInit(): void {

  }

  public isManualEntryImage(): boolean {
    return this.mediaLink?.damProviderId === DamProviderIds.UserEnteredMedia;
  }

  public isAemImage(): boolean {
    return this.mediaLink?.damProviderId === DamProviderIds.Aem;
  }

  public isBynderImage(): boolean {
    return this.mediaLink?.damProviderId === DamProviderIds.Bynder;
  }

  public isDigizuite(): boolean {
    return this.mediaLink?.damProviderId === DamProviderIds.Digizuite;
  }

  public isInRiver(): boolean {
    return this.mediaLink?.damProviderId === DamProviderIds.InRiver;
  }

  public getProviderName(): string {
    if (this.isAemImage()) {
      return 'AEM';
    }

    if (this.isBynderImage()) {
      return 'Bynder';
    }

    if (this.isDigizuite()) {
      return 'Digizuite';
    }

    if (this.isInRiver()) {
      return 'InRiver';
    }

    return 'Manual Entry';
  }
}
