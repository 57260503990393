import { GlobalProductCertification } from './global-product-certification.model';
import { Warranty } from './warranty.model';
import { GlobalProductTag } from './global-product-tag.model';
import { GlobalProductPortfolio } from './global-product-portfolio.model';
import { GlobalProductFamily } from './global-product-family.model';
import { GlobalProductDesigner } from './global-product-designer.model';
import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import { Brand, IBrand } from './brand.model';
import { assign as _assign } from 'lodash-es';
import { GlobalProductLocale } from './global-product-locale.model';
import { SubBrand } from './sub-brand.model';
import { GlobalProductSubcategory } from './global-product-sub-category.model';
import { GlobalProductGlobalSubcategory } from './global-product-global-sub-category.model';
import { GlobalProductWebLink } from './global-product-web-link.model';
import { GlobalProductLink } from './global-product-link.model';
import { GlobalProductMediaLink } from './global-product-media-link.model';
import { ModelFilesProfile } from './model-files-profile.model';
import { ContactGlobalProductLocale } from './contact-global-product-locale.model';
import { GlobalProductAttributeOption, IGlobalProductAttributeOption } from './global-product-attribute-option.model';
import { GlobalProductConfigurator, IGlobalProductConfigurator } from './global-product-configurator.model';
import { ProductStatus } from './product-status.model';

export interface IGlobalProduct extends IBaseAuditModel {
  name: string;
  brandId: number;
  brand: IBrand;
  subBrandId: number;
  subBrand: SubBrand;
  includeInCoBranding: boolean;
  globalProductDesigners: Array<GlobalProductDesigner>;
  globalProductFamilies: Array<GlobalProductFamily>;
  globalProductPortfolios: Array<GlobalProductPortfolio>;
  globalProductSubcategories: Array<GlobalProductSubcategory>;
  globalProductGlobalSubcategories: Array<GlobalProductGlobalSubcategory>;
  pageTitleKeyword: string;
  globalProductLocales: Array<GlobalProductLocale>;
  globalProductWebLinks: Array<GlobalProductWebLink>;
  brandSiteProductLink: string;
  materialsLink: string;
  scoutLink: string;
  omniLink: string;
  globalProductTags: Array<GlobalProductTag>;
  shortDescription: string;
  longDescription: string;
  overviewMetaDescription: string;
  warrantyId: number;
  warranty: Warranty;
  isSpecialDelivery: boolean;
  isEssentials: boolean;
  status?: number;
  productStatus: ProductStatus;
  globalProductConfigurators: Array<IGlobalProductConfigurator>;
  globalProductMediaLinks?: Array<GlobalProductMediaLink>;
  globalProductLinks: Array<GlobalProductLink>;
  globalProductCertifications: Array<GlobalProductCertification>;
  globalProductAttributeOptions: Array<IGlobalProductAttributeOption>;
  modelFilesProfiles?: Array<ModelFilesProfile>;
  contacts: Array<ContactGlobalProductLocale>;
}

export class GlobalProduct
  extends BaseAuditModel
  implements IGlobalProduct {
  name: string;
  brandId: number;
  brand: Brand;
  subBrandId: number;
  subBrand: SubBrand;
  includeInCoBranding: boolean;
  globalProductDesigners: Array<GlobalProductDesigner> = [];
  globalProductFamilies: Array<GlobalProductFamily> = [];
  globalProductPortfolios: Array<GlobalProductPortfolio> = [];
  globalProductSubcategories: Array<GlobalProductSubcategory> = [];
  globalProductGlobalSubcategories: Array<GlobalProductGlobalSubcategory> = [];
  pageTitleKeyword: string;
  globalProductLocales: Array<GlobalProductLocale> = [];
  globalProductWebLinks: Array<GlobalProductWebLink> = [];
  brandSiteProductLink: string;
  materialsLink: string;
  scoutLink: string;
  omniLink: string;

  globalProductTags: Array<GlobalProductTag> = [];
  shortDescription: string;
  longDescription: string;
  overviewMetaDescription: string;
  warrantyId: number;
  warranty: Warranty;
  isSpecialDelivery: boolean;
  isEssentials: boolean;
  status?: number;
  productStatus: ProductStatus;
  globalProductConfigurators: Array<GlobalProductConfigurator>;

  globalProductMediaLinks?: Array<GlobalProductMediaLink>;
  globalProductLinks: Array<GlobalProductLink> = [];
  globalProductCertifications: Array<GlobalProductCertification> = [];
  globalProductAttributeOptions: Array<GlobalProductAttributeOption> = [];
  modelFilesProfiles?: Array<ModelFilesProfile>;
  contacts: Array<ContactGlobalProductLocale>;

  constructor(data?: IGlobalProduct) {
    super(data);

    if (!data) {
      return;
    }
    _assign(this, data);
  }
}
