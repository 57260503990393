import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private matSnackBar: MatSnackBar) { }

  showSuccessMessage(message: string): void {
    this.show(message, 'snackbar-success');
  }

  showFailureMessage(message: string): void {
    this.show(message, 'snackbar-failure');
  }

  showWarningMessage(message: string): void {
    this.show(message, 'snackbar-warning');
  }

  showHelpMessage(message: string): void {
    this.show(message, 'snackbar-help');
  }

  show(message: string, cssClass: string, duration?: 3000) {
    this.matSnackBar.open(message, 'Close', {
      duration,
      horizontalPosition: 'right',
      panelClass: cssClass,
    });
  }
}
