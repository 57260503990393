import { assign as _assign } from 'lodash-es';
import { IBaseAuditModel, BaseAuditModel } from './base-audit.model';
import { GlobalProductOfferingSummary } from './global-product-offering-summary.model';

export interface IIntegrationProductOfferingSummary extends IBaseAuditModel {
  name: string;
  iconLink: string;
  productOfferings: GlobalProductOfferingSummary[];
}

export class IntegrationProductOfferingSummary extends BaseAuditModel {
  name: string;
  iconLink: string;
  productOfferings: GlobalProductOfferingSummary[];

  constructor(data?: IIntegrationProductOfferingSummary) {
    if (!data) {
      return;
    }

    super(data);
    _assign(this, data);
  }
}
