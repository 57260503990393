import { FilesService } from '../../../core/services/files.service';
import { Component, Input, OnInit } from '@angular/core';
import { LightboxesService } from 'src/app/core/services/lightboxes.service';
import { ImagesService } from 'src/app/core/services/images.service';
import { IAlbum } from 'ngx-lightbox';
@Component({
  selector: 'app-card-content-image',
  templateUrl: './card-content-image.component.html',
  styleUrls: ['./card-content-image.component.scss']
})
export class CardContentImageComponent implements OnInit {
  @Input() imageLink: string;
  @Input() placeholderAlt: string;
  constructor(
    private lightboxesService: LightboxesService,
    private imagesService: ImagesService) { }

  ngOnInit(): void {
  }

  showLightbox() {
    this.lightboxesService.open(this.getAlbum());
  }

  getImageFileName(url: string): string {
    return this.imagesService.getName(url);
  }

  private getAlbum(): IAlbum[] {
    const album: IAlbum[] = [];
    if (this.imageLink?.length > 0) {
      album.push({
        src: this.imageLink,
        caption: this.getImageFileName(this.imageLink),
        thumb: this.imageLink,
        downloadUrl: this.imageLink
      });
    }
    return album;
  }

}
