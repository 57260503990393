import { Action } from '@ngrx/store';
import { BrandPut } from 'src/app/core/models/brand-put.model';
import { Brand } from 'src/app/core/models/brand.model';
import { SelectOption } from 'src/app/core/models/select-option.model';

export enum BrandApiActionTypes {
  FetchSuccess = '[Brand/API] Fetch Success',
  FetchFailure = '[Brand/API] Fetch Failure',
  LoadSuccess = '[Brand/API] Load Success',
  LoadFailure = '[Brand/API] Load Failure',
  CreateSuccess = '[Brand/API] Create Success',
  CreateFailure = '[Brand/API] Create Failure',
  UpdateSuccess = '[Brand/API] Update Success',
  UpdateFailure = '[Brand/API] Update Failure',
  RenameSuccess = '[Brand/API] Rename Success',
  RenameFailure = '[Brand/API] Rename Failure',
  DeleteSuccess = '[Brand/API] Delete Success',
  DeleteFailure = '[Brand/API] Delete Failure'
}

export class FetchSuccess implements Action {
  readonly type = BrandApiActionTypes.FetchSuccess;

  constructor(public payload: SelectOption[]) { }
}

export class FetchFailure implements Action {
  readonly type = BrandApiActionTypes.FetchFailure;

  constructor(public payload: any) { }
}

export class LoadSuccess implements Action {
  readonly type = BrandApiActionTypes.LoadSuccess;

  constructor(public payload: Brand) { }
}

export class LoadFailure implements Action {
  readonly type = BrandApiActionTypes.LoadFailure;

  constructor(public payload: any) { }
}

export class CreateSuccess implements Action {
  readonly type = BrandApiActionTypes.CreateSuccess;

  constructor(public payload: Brand) { }
}

export class CreateFailure implements Action {
  readonly type = BrandApiActionTypes.CreateFailure;

  constructor(public payload: any) { }
}

export class UpdateSuccess implements Action {
  readonly type = BrandApiActionTypes.UpdateSuccess;

  constructor(public payload: { brand: BrandPut, brandName: string }) { }
}

export class UpdateFailure implements Action {
  readonly type = BrandApiActionTypes.UpdateFailure;

  constructor(public payload: any) { }
}

export class RenameSuccess implements Action {
  readonly type = BrandApiActionTypes.RenameSuccess;

  constructor(public payload: { brandId: number, brandName: string }) { }
}

export class RenameFailure implements Action {
  readonly type = BrandApiActionTypes.RenameFailure;

  constructor(public payload: any) { }
}

export class DeleteSuccess implements Action {
  readonly type = BrandApiActionTypes.DeleteSuccess;

  constructor(public payload: { brandId: number, brandName: string }) { }
}

export class DeleteFailure implements Action {
  readonly type = BrandApiActionTypes.DeleteFailure;

  constructor(public payload: any) { }
}

export type BrandApiActionsUnion =
  FetchSuccess
  | FetchFailure
  | LoadSuccess
  | LoadFailure
  | CreateSuccess
  | CreateFailure
  | UpdateSuccess
  | UpdateFailure
  | RenameSuccess
  | RenameFailure
  | DeleteSuccess
  | DeleteFailure;
