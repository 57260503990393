import { Observable } from 'rxjs';
import { LightboxesService } from 'src/app/core/services/lightboxes.service';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Dimensions } from '../../constants';
import { IMaterial, Material, MaterialColor, FabricContent, ActSymbol, IMaterialColor } from 'src/app/core/models/material.model';
import { IAlbum } from 'ngx-lightbox';
import { MaterialModalComponent } from '../material-modal/material-modal.component';

@Component({
  selector: 'app-materials-card',
  templateUrl: './materials-card.component.html',
  styleUrls: ['./materials-card.component.scss']
})
export class MaterialsCardComponent implements OnInit, OnDestroy {
  @Input() materials: Array<IMaterial>;
  @Input() isLoading: boolean;

  constructor(
    private matDialog: MatDialog,
    private lightboxesService: LightboxesService) { }

  ngOnInit(): void {
    //this.materials = this.buildMaterials();
  }

  ngOnDestroy(): void {

  }

  public showLightbox(materialIndex: number, colorIndex: number): void {
    const index = this.getAlbumIndex(materialIndex, colorIndex);
    this.lightboxesService.open(this.getAlbum(this.materials), index);
  }


  public showMaterialDetail(material: IMaterial, materialColor: IMaterialColor): void {
    const dialogRef = this.matDialog.open(MaterialModalComponent, {
      data: {
        material: material,
        materialColor: materialColor
      },
      maxWidth: Dimensions.MODAL_WIDE_WIDTH,
      restoreFocus: false
    });
​
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //     this.removeProductNumber(index);
    //   }
    // });
  }


  private getAlbum = (materials: Array<IMaterial>): IAlbum[] => {
    const album: IAlbum[] = [];

    if (materials && materials.length > 0) {
      materials.forEach((material) => {
        material.colors.forEach((color) => {
          album.push({
            src: color.imageUrl,
            caption: `${material.name} - ${color.name}`,
            thumb: color.imageThumbUrl,
            downloadUrl: color.imageThumbUrl
          });
        });
      });
    }

    return album;
  }

  private getAlbumIndex(materialIndex: number, colorIndex: number): number {
    // get the material color for the material/color indices.
    var materialColor = this.materials[materialIndex].colors[colorIndex];

    // get all colors across all materials.
    var colors = this.materials
      .map(x => x.colors)
      .reduce((a, b) => {
        return a.concat(b);
    });

    return colors.findIndex(x => x.id == materialColor.id);
  }
}
