import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import * as _ from 'lodash';
import { ILocale, Locale } from './locale.model';

export interface IRegion extends IBaseAuditModel {
    name: string;
    code: string;
    locales: Array<ILocale>;
}

export class Region extends BaseAuditModel {
    name: string;
    code: string;
    locales: Array<Locale>;

    constructor(data: IRegion) {
        if (data) {
            super(data);
            this.name = data.name;
            this.code = data.code;
            this.locales = data.locales && _.map(data.locales as Array<ILocale>, x => new Locale(x));
        }
    }
}
