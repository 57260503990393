import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PagedResults } from '../models/page-results.model';
import { PortfolioPost } from '../models/portfolio-post.model';
import { PortfolioPut } from '../models/portfolio-put.model';
import { RenamePut } from '../models/rename-put.model';
import { PortfolioSummary } from '../models/portfolio-summary.model';
import { IPortfolio, Portfolio } from '../models/portfolio.model';

@Injectable({
  providedIn: 'root'
})
export class PortfolioService {
  basePath = 'portfolios';

  constructor(private http: HttpClient) { }

  getDuplicates$(portfolioName: string, environmentId: number): Observable<Array<PortfolioSummary>> {
    const request: PortfolioPost = new PortfolioPost();
    request.name = portfolioName;
    request.environmentId = environmentId;

    return this.http
      .post(`${environment.apiUrl}${this.basePath}/duplicates`, request)
      .pipe(
        map(response => response),
        catchError((error: Response) => {
          return throwError(error);
        }),
        map((response: Array<PortfolioSummary>) => response)
      );
  }

  getPortfolios$(searchTerm: string, pageNumber: number, pageSize: number, 
    sortActive: string, sortDirection: string) : Observable<PagedResults<PortfolioSummary>>
  {
    return this.http.get(`${environment.apiUrl}${this.basePath}`, {
      params: new HttpParams()
      .set('search', searchTerm)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
        .set('orderBy', sortActive.toString())
        .set('sortOrder', sortDirection.toString())
    }).pipe(
      map((response: PagedResults<PortfolioSummary>) => response)
    );
  }

  deletePortfolio$(portfolioId: number): Observable<any>{
    return this.http.delete(environment.apiUrl + `${this.basePath}/${portfolioId}`)
    .pipe(
      map(res => res),
      catchError((err: Response) => {
        const obj = err;
        return throwError(obj);
      })
    );
  }

  createPortfolio$(portfolio: PortfolioPost): Observable<Portfolio>{
    return this.http.post(environment.apiUrl + `${this.basePath}`, portfolio)
    .pipe(map(res => res)
      ,catchError((err: Response) => {
        const obj = err;
        return throwError(obj);
      })
      ,map((x: IPortfolio) => new Portfolio(x)));
  }

  getPortfolio$(id: number): Observable<Portfolio>{
    return this.http.get(environment.apiUrl + `${this.basePath}/${id}`)
    .pipe(map(res => res),
    map ((x: IPortfolio) => new Portfolio(x)));
  }

  renamePortfolio$(request : { portfolioId: number, portfolioName: string }): Observable<any>{
    const renamePut: RenamePut = new RenamePut({ name: request.portfolioName});

    return this.http.put(environment.apiUrl + `${this.basePath}/${request.portfolioId}/rename`,
    renamePut,
    { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
    );
  }

  updatePortfolio$(portfolio: PortfolioPut): Observable<any> {
    return this.http.put(`${environment.apiUrl}${this.basePath}/${portfolio.id}`, portfolio)
    .pipe(map(response => response),
      catchError((error: Response) => {
        return throwError(error);
      })
    );
  }
}
