import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DisplayProductAttributeOptions } from 'src/app/core/models/child-product-attribute-options.model';

const sliceName: number = 3;

@Component({
  selector: 'app-product-attribute-option-children',
  templateUrl: './product-attribute-option-children.component.html',
  styleUrls: ['./product-attribute-option-children.component.scss']
})
export class ProductAttributeOptionChildrenComponent {

  constructor(
    public dialogRef: MatDialogRef<ProductAttributeOptionChildrenComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      message: string,
      productAttributeOptions: Array<DisplayProductAttributeOptions>,
      buttonText: string
    }) { }

  confirmAction(action: boolean) {
    this.dialogRef.close(action);
  }

  getButtonClass(): string {
    return 'warning-button';
  }

  getDisplayAttributeOptions(displayAttributeOptions: DisplayProductAttributeOptions) : string[] {
    const attributeOptionsToDisplay = displayAttributeOptions?.attributeOptionNames?.slice(0, 3);
    return attributeOptionsToDisplay;
  }

  getFullListAttributeOptions(displayAttributeOptions: DisplayProductAttributeOptions) : string {
    if (displayAttributeOptions.attributeOptionNames?.length > sliceName) {
      return displayAttributeOptions?.attributeOptionNames?.join(', ');
    }
    return '';
  }

  shouldShowEllipsis(index: number): boolean {
    return index === sliceName - 1;
  }
}
