import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sidebar-back-link',
  templateUrl: './sidebar-back-link.component.html',
  styleUrls: ['./sidebar-back-link.component.scss']
})
export class SidebarBackLinkComponent {
  @Input() text = 'link';
  @Output() click: EventEmitter<any> = new EventEmitter();

  public performClickEvent(event) {
    // prevents default anchor tag behavior (not doing this causes navigation to occur twice, firing the guard twice.)
    event.stopPropagation();
    this.click.emit();
  }

}
