import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import { assign as _assign } from 'lodash-es';

export interface IGlobalProductTag extends IBaseAuditModel {
  globalProductId: number;
  tag: string;
}

export class GlobalProductTag extends BaseAuditModel implements IGlobalProductTag {
  globalProductId: number;
  tag: string;

  constructor(data: IGlobalProductTag) {
    super(data);

    if (!data) {
      return;
    }

    _assign(this, data);
  }
}
