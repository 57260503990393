import { GlobalSubcategory, IGlobalSubcategory } from './global-subcategory.model';
import { ImageLink, IImageLink } from './image-link.model';
import { assign as _assign } from 'lodash-es';
import { IBaseAuditModel, BaseAuditModel } from './base-audit.model';
import { IIndustry, Industry } from './industry.model';

export interface IGlobalCategory extends IBaseAuditModel {
  name: string;
  description: string;
  industry: IIndustry;
  globalCategoryImages: IImageLink[];
  globalSubcategories: IGlobalSubcategory[];
  iconUpload: File;
  iconName: string;
}

export class GlobalCategory extends BaseAuditModel implements IGlobalCategory {
  name: string;
  description: string;
  industry: Industry;
  globalCategoryImages: ImageLink[];
  globalSubcategories: GlobalSubcategory[];
  iconUpload: File;
  iconName: string;

  constructor(data?: IGlobalCategory) {
    super(data);
    let link = data.globalCategoryImages[0]?.imageLink;
    this.iconName = link && link.lastIndexOf('/') !== -1 && link.substring(link.lastIndexOf('/') + 1, link.length);

    if (!data) {
      return;
    }
    _assign(this, data);
  }
}
