import { Action } from '@ngrx/store';
import { PagedResults } from 'src/app/core/models/page-results.model';
import { UserPut } from 'src/app/core/models/user-put.model';
import { User } from 'src/app/core/models/user.model';

export enum UserApiActionTypes {
  LoadPageSuccess = '[User/API] Load Page Success',
  LoadPageFailure = '[User/API] Load Page Failure',
  LoadSuccess = '[User/API] Load Success',
  LoadFailure = '[User/API] Load Failure',
  CreateSuccess = '[User/API] Create Success',
  CreateFailure = '[User/API] Create Failure',
  UpdateSuccess = '[User/API] Update Success',
  UpdateFailure = '[User/API] Update Failure',
  DeleteSuccess = '[User/API] Delete Success',
  DeleteFailure = '[User/API] Delete Failure'
}

export class LoadPageSuccess implements Action {
  readonly type = UserApiActionTypes.LoadPageSuccess;

  constructor(public payload: PagedResults<User>) { }
}

export class LoadPageFailure implements Action {
  readonly type = UserApiActionTypes.LoadPageFailure;

  constructor(public payload: any) { }
}

export class LoadSuccess implements Action {
  readonly type = UserApiActionTypes.LoadSuccess;

  constructor(public payload: User) { }
}

export class LoadFailure implements Action {
  readonly type = UserApiActionTypes.LoadFailure;

  constructor(public payload: any) { }
}

export class CreateSuccess implements Action {
  readonly type = UserApiActionTypes.CreateSuccess;

  constructor(public payload: User) { }
}

export class CreateFailure implements Action {
  readonly type = UserApiActionTypes.CreateFailure;

  constructor(public payload: any) { }
}

export class UpdateSuccess implements Action {
  readonly type = UserApiActionTypes.UpdateSuccess;

  constructor(public payload: UserPut) { }
}

export class UpdateFailure implements Action {
  readonly type = UserApiActionTypes.UpdateFailure;

  constructor(public payload: any) { }
}

export class DeleteSuccess implements Action {
  readonly type = UserApiActionTypes.DeleteSuccess;

  constructor(public payload: { userId: number, userName: string }) { }
}

export class DeleteFailure implements Action {
  readonly type = UserApiActionTypes.DeleteFailure;

  constructor(public payload: any) { }
}

export type UserApiActionsUnion =
    LoadPageSuccess
  | LoadPageFailure
  | LoadSuccess
  | LoadFailure
  | CreateSuccess
  | CreateFailure
  | UpdateSuccess
  | UpdateFailure
  | DeleteSuccess
  | DeleteFailure;
