<div class="image-control-container scrollbar">
  <div class="image-details-container">

    <div
      class="image-detail"
      *ngIf="mediaLink?.title?.length > 0">
      <small>Title</small>
      <small> ({{ getProviderName() }})</small>
      <p>
        {{ mediaLink.title }}
      </p>
    </div>

    <div
      class="image-detail"
      *ngIf="mediaLink?.altText?.length > 0">
      <small>Alt Text</small>
      <p>
        {{ mediaLink.altText }}
      </p>
    </div>

    <div
      class="image-detail"
      *ngIf="mediaLink?.mediaSubType?.bynderName">
      <small>Media Subtype</small>
      <p>
        {{ mediaLink.mediaSubType?.bynderName }}
      </p>
    </div>

    <div
      class="image-detail"
      *ngIf="mediaLink?.bynderId?.length > 0">
      <small>Bynder ID</small>
      <p>
        {{ mediaLink.bynderId }}
      </p>
    </div>

    <div
      class="image-detail">
      <small>Image Url</small>
      <div>
        <a
          [href]="mediaLink.mediaLink"
          target="_blank">{{ mediaLink.mediaLink }}</a>
      </div>
    </div>
  </div>

  <div class="image-preview-container">
    <a
      [href]="mediaLink.mediaLink"
      target="_blank">
      <img
        src="{{ mediaLink.mediaLink }}"
        layout-fill
        class="selected-image image-center"
        onerror="this.src='../../../../../assets/images/placeholders/product_placeholder.svg'">
    </a>
  </div>
</div>
