import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import * as fromStore from '../../reducers';

import {
  AttributeTypeApiActions,  
  AttributeTypeActions
} from '../actions';

import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AttributeTypeService } from 'src/app/core/services/attribute-type.service';

@Injectable()
export class AttributeTypeEffects {

  createAttributeType$ = createEffect(() => { 
    return this.actions$.pipe(
      ofType<AttributeTypeActions.CreateAttributeType>(AttributeTypeActions.AttributeTypeActionTypes.CreateAttributeType),
      switchMap((request) =>
        this.attributeTypeService.createAttributeType$(request.payload).pipe(
          map((objAttributeType) =>
            new AttributeTypeApiActions.CreateSuccess(objAttributeType)
          ),
          catchError(error =>
            of(new AttributeTypeApiActions.CreateFailure(error))
          )
        )
      )
    );
  });

  deleteAttributeType$ = createEffect(() => { 
    return this.actions$.pipe(
        ofType<AttributeTypeActions.DeleteAttributeType>(AttributeTypeActions.AttributeTypeActionTypes.DeleteAttributeType),
      switchMap((request) =>
        this.attributeTypeService.deleteAttributeType$(request.payload.attributeTypeId).pipe(
          map((response) =>
            new AttributeTypeApiActions.DeleteSuccess({ attributeTypeId: request.payload.attributeTypeId, attributeTypeName: request.payload.attributeTypeName })
          ),
          catchError(error =>
            of(new AttributeTypeApiActions.DeleteFailure(error))
          )
        )
      )
    );
  });

  renameAttributeType$: Observable<Action> = createEffect(() => { 
    return this.actions$.pipe(
      ofType<AttributeTypeActions.RenameAttributeType>(AttributeTypeActions.AttributeTypeActionTypes.RenameAttributeType),
      switchMap((request) =>
        this.attributeTypeService.renameAttributeType$({
          attributeTypeId: request.payload.attributeTypeId,
          attributeTypeName: request.payload.attributeTypeName
        }).pipe(
          map(_ => new AttributeTypeApiActions.RenameSuccess({
            attributeTypeId: request.payload.attributeTypeId,
            attributeTypeName: request.payload.attributeTypeName
          })
          ),
          catchError(error =>
            of(new AttributeTypeApiActions.RenameFailure(error))
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<fromStore.State>,
    private attributeTypeService: AttributeTypeService) { }

}
