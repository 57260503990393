import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-content-string-list-readonly',
  templateUrl: './card-content-string-list-readonly.component.html',
  styleUrls: ['./card-content-string-list-readonly.component.scss']
})
export class CardContentStringListReadonlyComponent implements OnInit {
  @Input() values: Array<string> = [];
  constructor() { }

  ngOnInit(): void {
  }

}
