import { LocaleSpecifier } from './../../../core/models/locale-specifier.model';

import {
  LocaleSpecifierApiActions,
  LocaleSpecifierActions
} from '../actions';

export interface State {
  localeSpecifiersLoading: boolean;
  localeSpecifiersLoaded: boolean;
  localeSpecifiers: Array<LocaleSpecifier>;
  error: string;
}

export const initialState: State = {
  localeSpecifiersLoading: false,
  localeSpecifiersLoaded: false,
  localeSpecifiers: [],
  error: ''
};

export function reducer(state = initialState, action: LocaleSpecifierActions.LocaleSpecifierActionsUnion | LocaleSpecifierApiActions.LocaleSpecifierApiActionsUnion): State {
  switch (action.type) {

    case LocaleSpecifierActions.LocaleSpecifierActionTypes.LoadLocaleSpecifiers: {
      return {
        ...state,
        localeSpecifiersLoading: true,
        localeSpecifiersLoaded: false,
        error: null
      };
    }

    case LocaleSpecifierApiActions.LocaleSpecifierApiActionTypes.LoadSuccess: {
      return {
        ...state,
        localeSpecifiers: action.payload,
        localeSpecifiersLoading: false,
        localeSpecifiersLoaded: true
      };
    }

    case LocaleSpecifierApiActions.LocaleSpecifierApiActionTypes.LoadFailure: {
      return {
        ...state,
        localeSpecifiers: null,
        localeSpecifiersLoading: false,
        localeSpecifiersLoaded: false,
        error: action.payload
      };
    }

    default:
      return state;
  }
}

export const getLocaleSpecifiersIsLoading = (state: State) => state.localeSpecifiersLoading;
export const getLocaleSpecifiersIsLoaded = (state: State) => state.localeSpecifiersLoaded;
export const getLocaleSpecifiers = (state: State) => state.localeSpecifiers;
