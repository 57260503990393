import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import { assign as _assign } from 'lodash-es';

export interface IGlobalProductOfferingDuplicateCheck extends IBaseAuditModel {
  name: string;
  templateId: number;
  globalProductId: number;
}

export class GlobalProductOfferingDuplicateCheck extends BaseAuditModel implements IGlobalProductOfferingDuplicateCheck {
  name: string;
  templateId: number;
  globalProductId: number;

  constructor(data?: IGlobalProductOfferingDuplicateCheck) {
    super(data);

    if (!data) {
      return;
    }
    _assign(this, data);
  }
}
