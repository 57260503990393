import { Action } from '@ngrx/store';
import { AttributeType } from 'src/app/core/models/attribute-type.model';

export enum AttributeTypeApiActionTypes {
  CreateSuccess = '[AttributeType/API] Create Success',
  CreateFailure = '[AttributeType/API] Create Failure',
  RenameSuccess = '[AttributeType/API] Rename Success',
  RenameFailure = '[AttributeType/API] Rename Failure',
  DeleteSuccess = '[AttributeType/API] Delete Success',
  DeleteFailure = '[AttributeType/API] Delete Failure'
}

export class CreateSuccess implements Action {
  readonly type = AttributeTypeApiActionTypes.CreateSuccess;

  constructor(public payload: AttributeType) { }
}

export class CreateFailure implements Action {
  readonly type = AttributeTypeApiActionTypes.CreateFailure;

  constructor(public payload: any) { }
}

export class RenameSuccess implements Action {
  readonly type = AttributeTypeApiActionTypes.RenameSuccess;

  constructor(public payload: { attributeTypeId: number, attributeTypeName: string }) { }
}

export class RenameFailure implements Action {
  readonly type = AttributeTypeApiActionTypes.RenameFailure;

  constructor(public payload: any) { }
}

export class DeleteSuccess implements Action {
  readonly type = AttributeTypeApiActionTypes.DeleteSuccess;

  constructor(public payload: { attributeTypeId: number, attributeTypeName: string }) { }
}

export class DeleteFailure implements Action {
  readonly type = AttributeTypeApiActionTypes.DeleteFailure;

  constructor(public payload: any) { }
}

export type AttributeTypeApiActionsUnion =
  | CreateSuccess
  | CreateFailure
  | RenameSuccess
  | RenameFailure
  | DeleteSuccess
  | DeleteFailure;
