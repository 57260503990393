import { GlobalProductPut } from './../../../core/models/global-product-put.model';
import { Action } from '@ngrx/store';
import { GlobalProduct } from 'src/app/core/models/global-product.model';
import { GlobalConfiguration } from '../../../core/models/global-configuration.model';

export enum ProductActionTypes {
  LoadProduct = '[Product] Load Product',
  CreateProduct = '[Product] Create',
  UpdateProduct = '[Product] Update',
  RenameProduct = '[Product] Rename Product',
  DeleteProduct = '[Product] Delete Product',
  RenameConfiguration = '[Product] Rename Configuration',
  DeleteConfiguration = '[Product] Delete Configuration',
  DuplicateConfiguration = '[Product] Duplicate Configuration',
  ClearMessages = '[Product] Clear Messages',
  ClearProduct = '[Product] Clear Product'
}

export class LoadProduct implements Action {
  readonly type = ProductActionTypes.LoadProduct;

  constructor(public payload: number) { }
}

export class CreateProduct implements Action {
  readonly type = ProductActionTypes.CreateProduct;

  constructor(public payload: { product: GlobalProduct }) { }
}

export class UpdateProduct implements Action {
  readonly type = ProductActionTypes.UpdateProduct;

  constructor(public payload: { product: GlobalProductPut }) { }
}

export class RenameProduct implements Action {
  readonly type = ProductActionTypes.RenameProduct;

  constructor(public payload: { product: GlobalProduct, newName: string }) { }
}

export class DeleteProduct implements Action {
  readonly type = ProductActionTypes.DeleteProduct;

  constructor(public payload: { product: GlobalProduct }) { }
}

export class RenameConfiguration implements Action {
  readonly type = ProductActionTypes.RenameConfiguration;

  constructor(public payload: { configuration: GlobalConfiguration, newName: string }) { }
}

export class DeleteConfiguration implements Action {
  readonly type = ProductActionTypes.DeleteConfiguration;

  constructor(public payload: { configuration: GlobalConfiguration }) { }
}

export class DuplicateConfiguration implements Action {
  readonly type = ProductActionTypes.DuplicateConfiguration;

  constructor(public payload: { configuration: GlobalConfiguration, newName: string }) { }
}

export class ClearMessages implements Action {
  readonly type = ProductActionTypes.ClearMessages;

  constructor() { }
}
export class ClearProduct implements Action {
  readonly type = ProductActionTypes.ClearProduct;

  constructor() {
  }
}

export type ProductActionsUnion =
  LoadProduct
  | CreateProduct
  | UpdateProduct
  | RenameProduct
  | DeleteProduct
  | RenameConfiguration
  | DeleteConfiguration
  | DuplicateConfiguration
  | ClearMessages
  | ClearProduct
  ;
