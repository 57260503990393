import { AttributeType } from 'src/app/core/models/attribute-type.model';
import { AttributeTypeStatus } from 'src/app/shared/constants';

import {
  AttributeTypeApiActions,
  AttributeTypeActions
} from '../actions';


export interface State {
  attributeType: AttributeType;
  loading: boolean;
  loaded: boolean;
  status: AttributeTypeStatus;
  error: string;
  success: string;
}

export const initialState: State = {
  attributeType: null,
  loading: false,
  loaded: false,
  status: AttributeTypeStatus.None,
  error: null,
  success: null
};

export function reducer(state = initialState, action: AttributeTypeActions.AttributeTypeActionsUnion | AttributeTypeApiActions.AttributeTypeApiActionsUnion): State {
  switch (action.type) {

    case AttributeTypeActions.AttributeTypeActionTypes.ClearMessages: {
      return {
        ...state,
        status: AttributeTypeStatus.None,
        error: null,
        success: null
      };
    }

    case AttributeTypeActions.AttributeTypeActionTypes.CreateAttributeType: {
      return {
        ...state,
      };
    }

    case AttributeTypeApiActions.AttributeTypeApiActionTypes.CreateSuccess: {
      return {
        ...state,
        attributeType: action.payload,
        status: AttributeTypeStatus.CreateSuccess,
        error: null,
        success: `Successfully Created AttributeType: ${action.payload.name}`
      };
    }

    case AttributeTypeApiActions.AttributeTypeApiActionTypes.CreateFailure: {
      return {
        ...state,
        attributeType: null,
        status: AttributeTypeStatus.CreateFailure,
        error: 'Failed to Create AttributeType',
        success: null
      };
    }

    case AttributeTypeActions.AttributeTypeActionTypes.RenameAttributeType: {
      return {
        ...state
      };
    }

    case AttributeTypeApiActions.AttributeTypeApiActionTypes.RenameSuccess: {
      const attributeType = { ...state.attributeType };
      attributeType.name = action.payload.attributeTypeName;

      return {
        ...state,
        status: AttributeTypeStatus.RenameSuccess,
        error: null,
        success: `Successfully renamed AttributeType: ${action.payload.attributeTypeName}`
      };
    }

    case AttributeTypeApiActions.AttributeTypeApiActionTypes.RenameFailure: {
      return {
        ...state,
        attributeType: null,
        status: AttributeTypeStatus.RenameFailure,
        error: 'Failed to rename AttributeType',
        success: null
      };
    }

    case AttributeTypeActions.AttributeTypeActionTypes.DeleteAttributeType: {
      return {
        ...state,
      };
    }

    case AttributeTypeApiActions.AttributeTypeApiActionTypes.DeleteSuccess: {
      return {
        ...state,
        status: AttributeTypeStatus.DeleteSuccess,
        error: null,
        success: `Successfully Deleted AttributeType: ${action.payload.attributeTypeName}`
      };
    }

    case AttributeTypeApiActions.AttributeTypeApiActionTypes.DeleteFailure: {
      return {
        ...state,
        attributeType: null,
        status: AttributeTypeStatus.DeleteFailure,
        error: 'Failed to Delete AttributeType',
        success: null
      };
    }

    default:
      return state;
   }
}

export const getAttributeType = (state: State) => state.attributeType;
export const getErrorMessage = (state: State) => state.error;
export const getSuccessMessage = (state: State) => state.success;
export const getStatus = (state: State) => state.status;
export const isLoading = (state: State) => state.loading;
export const isLoaded = (state: State) => state.loaded;
