import { assign as _assign } from 'lodash-es';
import { IBaseAuditModel, BaseAuditModel } from './base-audit.model';

export interface IMaterial extends IBaseAuditModel {
  integrationId: number;
  name: string;
  materialBrand: string;
  seriesNumber: string;
  status: string;
  introductionDate: Date;
  updateDate: Date;
  abrasion: number | null;
  abrasionType: string;
  lightFastnessGrade: string;
  lightFastnessHour: string;
  origin: string;
  flammability: Array<IMaterialFlammability>;
  actSymbols: Array<IMaterialActSymbol>;
  colors: Array<IMaterialColor>;
  fabricContents: Array<IFabricContent>;
}

export class Material extends BaseAuditModel implements IMaterial {
  integrationId: number;
  name: string;
  materialBrand: string;
  seriesNumber: string;
  status: string;
  introductionDate: Date;
  updateDate: Date;
  abrasion: number | null;
  abrasionType: string;
  lightFastnessGrade: string;
  lightFastnessHour: string;
  origin: string;
  flammability: Array<IMaterialFlammability> = [];
  actSymbols: Array<IMaterialActSymbol> = [];
  colors: Array<IMaterialColor> = [];
  fabricContents: Array<IFabricContent> = [];

  constructor(data?: IMaterial) {
    super(data);

    if (data) {
      _assign(this, data);
    }
  }
}

export interface IMaterialColor extends IBaseAuditModel {
  materialId: number;
  name: string;
  optionId: string;
  imageUrl: string;
  imageThumbUrl: string;
}

export class MaterialColor extends BaseAuditModel implements IMaterialColor {
  materialId: number;
  name: string;
  optionId: string;
  imageUrl: string;
  imageThumbUrl: string;

  constructor(data?: IMaterialColor) {
    super(data);

    if (data) {
      _assign(this, data);
    }
  }
}

export interface IFabricContent extends IBaseAuditModel {
  materialId: number;
  name: string;
  percentage: number;
}

export class FabricContent extends BaseAuditModel implements IFabricContent {
  materialId: number;
  name: string;
  percentage: number;

  constructor(data?: IFabricContent) {
    super(data);

    if (data) {
      _assign(this, data);
    }
  }
}

export interface IMaterialFlammability extends IBaseAuditModel {
  materialId: number;
  code: string;
}

export class MaterialFlammability extends BaseAuditModel implements IMaterialFlammability {
  materialId: number;
  code: string;

  constructor(data?: IMaterialFlammability) {
    super(data);

    if (data) {
      _assign(this, data);
    }
  }
}

export interface IMaterialActSymbol extends IBaseAuditModel {
  materialId: number;
  actSymbolId: number;
  actSymbol: IActSymbol;
}

export class MaterialActSymbol extends BaseAuditModel implements IMaterialActSymbol {
  materialId: number;
  actSymbolId: number;
  actSymbol: IActSymbol;

  constructor(data?: IMaterialActSymbol) {
    super(data);

    if (data) {
      _assign(this, data);
    }
  }
}

export interface IActSymbol extends IBaseAuditModel {
  name: string;
  symbolUrl: string;
}

export class ActSymbol extends BaseAuditModel implements IActSymbol {
  name: string;
  symbolUrl: string;

  constructor(data?: IActSymbol) {
    super(data);

    if (data) {
      _assign(this, data);
    }
  }
}

export interface IMaterialsRequest {
  productNumbers: Array<string>;
}

export class MaterialsRequest implements IMaterialsRequest {
  productNumbers: Array<string> = [];

  constructor(data?: IMaterialsRequest) {
    if (data) {
      _assign(this, data);
    }
  }
}

export interface IMaterialsResponse {
  materials: Array<IMaterial>;
}

export class MaterialsResponse implements IMaterialsResponse {
  materials: Array<IMaterial>;

  constructor(data?: IMaterialsResponse) {
    if (data) {
      _assign(this, data);
    }
  }
}
