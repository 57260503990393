import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import { assign as _assign } from 'lodash-es';

export interface IContact extends IBaseAuditModel {
  name: string;
  email: string;
  phoneNumber: string;
  webPageUrl: string;
}

export class Contact extends BaseAuditModel implements IContact {
  name: string;
  email: string;
  phoneNumber: string;
  webPageUrl: string;

  constructor(data?: IContact) {
    super(data);

    if (!data) {
      return;
    }

    _assign(this, data);
  }
}
