import { assign as _assign } from 'lodash-es';
import { IBaseAuditModel, BaseAuditModel } from './base-audit.model';
import { Integration, IIntegration } from './integration.model';
import { Locale, ILocale } from './locale.model';
import { Language, ILanguage } from './language.model';
import { CategoryRemap, ICategoryRemap } from './category-remap.model';
import { SubcategoryRemap, ISubcategoryRemap } from './subcategory-remap.model';

export interface IEndpoint extends IBaseAuditModel {
  name: string;
  integrationId: number;
  integration: IIntegration;
  localeId: number;
  locale: ILocale;
  languageId: number;
  language: ILanguage;
  isDeleted: boolean;
  categoryRemaps: ICategoryRemap[];
  subcategoryRemaps: ISubcategoryRemap[];
}

export class Endpoint extends BaseAuditModel implements IEndpoint {
  name: string;
  integrationId: number;
  integration: Integration;
  localeId: number;
  locale: Locale;
  languageId: number;
  language: Language;
  isDeleted: boolean;
  categoryRemaps: CategoryRemap[];
  subcategoryRemaps: SubcategoryRemap[];

  constructor(data?: IEndpoint) {
    super(data);

    if (!data) {
      return;
    }

    _assign(this, data);
  }
}
