import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApplicationTagPost } from '../models/application-tag-post.model';
import { ApplicationTagRenamePut } from '../models/application-tag-rename-put.model';
import { ApplicationTagSummary } from '../models/application-tag-summary.model';
import { ApplicationTag, IApplicationTag } from '../models/application-tag.model';
import { PagedResults } from '../models/page-results.model';
import { RenamePut } from '../models/rename-put.model';


@Injectable({
  providedIn: 'root'
})
export class ApplicationTagService {
  basePath = 'applicationtags';

  constructor(private http: HttpClient) { }

  getDuplicates$(ApplicationTagName: string): Observable<Array<ApplicationTagSummary>> {
    const request: ApplicationTagPost = new ApplicationTagPost();
    request.tag = ApplicationTagName;

    return this.http
      .post(`${environment.apiUrl}${this.basePath}/duplicates`, request)
      .pipe(
        map(response => response),
        catchError((error: Response) => {
          return throwError(error);
        }),
        map((response: Array<ApplicationTagSummary>) => response)
      );
  }

  getApplicationTags$(searchTerm: string, pageNumber: number, pageSize: number, 
    sortActive: string, sortDirection: string) : Observable<PagedResults<ApplicationTagSummary>>
  {
    return this.http.get(`${environment.apiUrl}${this.basePath}`, {
      params: new HttpParams()
      .set('search', searchTerm)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
        .set('orderBy', sortActive.toString())
        .set('sortOrder', sortDirection.toString())
    }).pipe(
      map((response: PagedResults<ApplicationTagSummary>) => response)
    );
  }

  deleteApplicationTag$(applicationTagId: number): Observable<any>{
    return this.http.delete(environment.apiUrl + `${this.basePath}/${applicationTagId}`)
    .pipe(
      map(res => res),
      catchError((err: Response) => {
        const obj = err;
        return throwError(obj);
      })
    );
  }

  createApplicationTag$(applicationTag: ApplicationTagPost): Observable<ApplicationTag>{
    return this.http.post(environment.apiUrl + `${this.basePath}`, applicationTag)
    .pipe(map(res => res)
      ,catchError((err: Response) => {
        const obj = err;
        return throwError(obj);
      })
      ,map((x: IApplicationTag) => new ApplicationTag(x)));
  }


  getApplicationTag$(id: number): Observable<ApplicationTag> {
    console.log(`getApplicationTag$(${id})`);
    return this.http
      .get(environment.apiUrl + `${this.basePath}/${id}`)
      .pipe(map(response => response)
        , map((x: IApplicationTag) => new ApplicationTag(x)));
  }

  renameApplicationTag$(request : { applicationTagId: number, applicationTagName: string }): Observable<any>{
    const renamePut: ApplicationTagRenamePut = new ApplicationTagRenamePut({ tag: request.applicationTagName});

    return this.http.put(environment.apiUrl + `${this.basePath}/${request.applicationTagId}/rename`,
    renamePut,
    { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
    );
  }
  
}
