import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PagedResults } from '../models/page-results.model';
import { WarrantySummary } from '../models/warranty-summary.model';
import { IWarranty, Warranty } from '../models/warranty.model';
import { WarrantyPost } from '../models/warranty-post.model';
import { RenamePut } from '../models/rename-put.model';

@Injectable({
  providedIn: 'root'
})
export class WarrantyService {
  basePath = 'warranties';

  constructor(private http: HttpClient) { }

  getDuplicates$(warrantyName: string): Observable<Array<WarrantySummary>> {
    const request: WarrantyPost = new WarrantyPost();
    request.name = warrantyName;

    return this.http
      .post(`${environment.apiUrl}${this.basePath}/duplicates`, request)
      .pipe(
        map(response => response),
        catchError((error: Response) => {
          return throwError(error);
        }),
        map((response: Array<WarrantySummary>) => response)
      );
  }

  getWarrantys$(searchTerm: string, pageNumber: number, pageSize: number, 
    sortActive: string, sortDirection: string) : Observable<PagedResults<WarrantySummary>>
  {
    return this.http.get(`${environment.apiUrl}${this.basePath}`, {
      params: new HttpParams()
      .set('search', searchTerm)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
        .set('orderBy', sortActive.toString())
        .set('sortOrder', sortDirection.toString())
    }).pipe(
      map((response: PagedResults<WarrantySummary>) => response)
    );
  }

  deleteWarranty$(warrantyId: number): Observable<any>{
    return this.http.delete(environment.apiUrl + `${this.basePath}/${warrantyId}`)
    .pipe(
      map(res => res),
      catchError((err: Response) => {
        const obj = err;
        return throwError(obj);
      })
    );
  }

  createWarranty$(warranty: WarrantyPost): Observable<Warranty>{
    return this.http.post(environment.apiUrl + `${this.basePath}`, warranty)
    .pipe(map(res => res)
      ,catchError((err: Response) => {
        const obj = err;
        return throwError(obj);
      })
      ,map((x: IWarranty) => new Warranty(x)));
  }


  getWarranty$(id: number): Observable<Warranty> {
    console.log(`getWarranty$(${id})`);
    return this.http
      .get(environment.apiUrl + `${this.basePath}/${id}`)
      .pipe(map(response => response)
        , map((x: IWarranty) => new Warranty(x)));
  }

  renameWarranty$(request : { warrantyId: number, warrantyName: string }): Observable<any>{
    const renamePut: RenamePut = new RenamePut({ name: request.warrantyName});

    return this.http.put(environment.apiUrl + `${this.basePath}/${request.warrantyId}/rename`,
    renamePut,
    { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
    );
  }
  
}
