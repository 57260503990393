import { Action } from '@ngrx/store';

export enum TranslationsActionTypes {
  LoadTranslations = '[Translations] Load Global Translations',
}

export class LoadTranslations implements Action {
  readonly type = TranslationsActionTypes.LoadTranslations;

  constructor() { }
}

export type TranslationsActionsUnion =
    LoadTranslations;
