import {
  SettingsApiActions,
  SettingsActions
} from '../actions';

import { SettingSummary } from 'src/app/core/models/setting-summary.model';


export interface State {
  settings: SettingSummary[];
  loading: boolean;
  loaded: boolean;
  error: string;
}

export const initialState: State = {
  settings: [],
  loading: false,
  loaded: false,
  error: ''
};

export function reducer(state = initialState, action: SettingsActions.SettingsActionsUnion | SettingsApiActions.SettingsApiActionsUnion): State {
  switch (action.type) {

    case SettingsActions.SettingsActionTypes.LoadSettings: {
      return {
        ...state,
        loading: true
      };
    }

    case SettingsApiActions.SettingsApiActionTypes.FetchSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        settings: action.payload
      };
    }

    case SettingsApiActions.SettingsApiActionTypes.FetchFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        settings: [],
        error: action.payload
      };
    }

    default:
      return state;
  }
}

export const getSettings = (state: State) => state.settings;
export const isLoading = (state: State) => state.loading;
export const isLoaded = (state: State) => state.loaded;
