<div>
  <div mat-dialog-title>
    <h6 class="h6">
      <strong>{{ data.modalTitle }}</strong>
    </h6>
  </div>

  <form [formGroup]="modalForm" autocomplete="off" novalidate (ngSubmit)="submitForm()">
    <div mat-dialog-content>
      <mat-form-field appearance="outline">
        <mat-label>{{ data.modalLabel }}</mat-label>
        <input autocomplete="off"
          matInput
          (focus)="$event.target.select();"
          id="url"
          formControlName="url"
          [placeholder]="data.modalInputPlaceholder">
          <mat-error *ngIf="hasError('url', 'required')">File URL is required.</mat-error>
          <mat-error *ngIf="hasError('url', 'pattern')">Invalid file type. Valid types: {{ getValidExtensions() }}</mat-error>
          <mat-error *ngIf="hasError('url', 'fileNotExists')">File does not exist</mat-error>
          <mat-error *ngIf="hasError('url', 'fileExistsCheckFailure')">File exists check could not be completed</mat-error>
      </mat-form-field>
    </div>

    <div mat-dialog-actions>
      <button class="mat-raised-button button-spacing" [mat-dialog-close]="result">Cancel</button>
      <button class="mat-raised-button primary-button" [disabled]="!modalForm.valid">{{ data.modalActionButtonText }}</button>
    </div>
  </form>
</div>
