import { DimensionService } from './../../../core/services/dimension.service';
import { UOMStandards } from './../../constants';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dimension',
  templateUrl: './dimension.component.html',
  styleUrls: ['./dimension.component.scss']
})
export class DimensionComponent implements OnInit {

  @Input() value?: number = null;
  @Input() unitOfMeasure: UOMStandards = UOMStandards.FeetInches;
  get dimension(): string { return this.getFormattedDimensionValue(this.value, this.unitOfMeasure); }

  constructor(private dimensionService: DimensionService) { }

  ngOnInit() {
  }

  getFormattedDimensionValue(inches: number | null, standard: UOMStandards): string {
    if (!inches) {
      return null;
    }

    let formattedValue = null;
    switch (standard) {
      case UOMStandards.MillimeterCentimeterMeter:
        const metric = this.dimensionService.getMetricFromInches(inches);
        formattedValue = this.getFormattedCombinedMetricValue(metric.meters, metric.centimeters, metric.millimeters);
        break;
      case UOMStandards.Millimeter:
        const millimeters = this.dimensionService.getMillimetersFromInches(inches);
        formattedValue = this.getFormattedMillimeters(millimeters.roundToTwo());
        break;
      default:
        formattedValue = this.getFormattedInches(inches);
        break;
    }

    return formattedValue;
  }

  private getFormattedCombinedMetricValue(meters?: number, centimeters?: number, millimeters?: number): string {
    let formattedValue = ''; // Initialize to empty, this will prevent 'null' from appearing on string concatenation.

    // examples:
    // meters: 1, centimeters: 40, millimeters 4 => 1m 40.4cm
    // meters: 0, centimeters: 40, millimeters 4 => 40.4cm
    // meters: 1, centimeters: 0, millimeters 4 => 1m 4mm
    // meters: 0, centimeters: 0, millimeters 4 => 4mm

    if (meters != null && meters > 0) {
      formattedValue = this.getFormattedMeters(meters);
    }

    // Display cm with decimal mm
    if (centimeters != null && centimeters > 0) {
      if (formattedValue && formattedValue.length > 0) { formattedValue = `${formattedValue} `; }

      // Correct the centimeters by adding the millimeters back in.  The millimeters will not be displayed separately as a result.
      if (millimeters != null && millimeters > 0) {
        centimeters = centimeters + (millimeters / 10);
      }
      formattedValue = `${formattedValue} ${this.getFormattedCentimeters(centimeters.roundToTwo())}`;
    }

    // Diplay mm if centimeters is 0 but there is still remainder millimeters.
    if (centimeters <= 0 && millimeters != null && millimeters > 0) {
      if (formattedValue && formattedValue.length > 0) { formattedValue = `${formattedValue} `; }
      formattedValue = `${formattedValue} ${this.getFormattedMillimeters(millimeters)}`;
    }

    // If the formatted value is an empty string, return null instead.
    if (formattedValue.length <= 0) { formattedValue = null; }

    return formattedValue;
  }

  private getFormattedInches(inches: number): string {
    return `${inches}in`;
  }

  private getFormattedMeters(meters: number): string {
    return `${meters}m`;
  }

  private getFormattedCentimeters(centimeters: number): string {
    return `${centimeters}cm`;
  }

  private getFormattedMillimeters(millimeters: number): string {
    return `${millimeters}mm`;
  }
}
