import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { MaterialModule } from '../material.module';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NameModalComponent } from './components/name-modal/name-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AddImageUrlModalComponent } from './components/add-image-url-modal/add-image-url-modal.component';
import { EditSnackBarComponent } from './components/edit-snackbar/edit-snackbar.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { AlertMessageComponent } from './components/alert-message/alert-message.component';
import { AlertMessageCardComponent } from './components/alert-message-card/alert-message-card.component';
import { DigitalAssetManagerModalComponent } from './components/digital-asset-manager-modal/digital-asset-manager-modal.component';
import { DigitalAssetLibraryComponent } from './components/digital-asset-manager-modal/digital-asset-library/digital-asset-library.component';
import { ImageUrlComponent } from './components/digital-asset-manager-modal/image-url/image-url.component';
import { DigitalAssetLibraryImageGridComponent } from './components/digital-asset-manager-modal/digital-asset-library/digital-asset-library-image-grid/digital-asset-library-image-grid.component';
import { LightboxModule } from 'ngx-lightbox';
import { NgChartsModule } from 'ng2-charts';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { GhostDisplayCardComponent } from './ghost-components/ghost-display-card/ghost-display-card.component';
import { GhostTableComponent } from './ghost-components/ghost-table/ghost-table.component';
import { GhostSidebarHeaderComponent } from './ghost-components/ghost-sidebar-header/ghost-sidebar-header.component';
import { GhostSidebarItemComponent } from './ghost-components/ghost-sidebar-item/ghost-sidebar-item.component';
import { GhostAccordionComponent } from './ghost-components/ghost-accordion/ghost-accordion.component';
import { LocalesSelectedComponent } from './components/locales-selected/locales-selected.component';
import { AddProductLiteratureModalComponent } from './components/add-product-literature-modal/add-product-literature-modal.component';
import { ProductLiteratureGridComponent } from './components/add-product-literature-modal/product-literature-grid/product-literature-grid.component';
import { ProductLiteratureDocumentEntryComponent } from './components/add-product-literature-modal/product-literature-document-entry/product-literature-document-entry.component';
import { LocaleSelectComponent } from './components/locale-select/locale-select.component';
import { AddProductCertificationsModalComponent } from './components/add-product-certifications-modal/add-product-certifications-modal.component';
import { AvailableInComponent } from './components/available-in/available-in.component';
import { DimensionRangeComponent } from './components/dimension-range/dimension-range.component';
import { DimensionComponent } from './components/dimension/dimension.component';
import { FileUrlModalComponent } from './components/file-url-modal/file-url-modal.component';
import { CardContentBooleanReadonlyComponent } from './components/card-content-boolean-readonly/card-content-boolean-readonly.component';
import { CardContentStringReadonlyComponent } from './components/card-content-string-readonly/card-content-string-readonly.component';
import { CardContentComponent } from './components/card-content/card-content.component';
import { CardContentStringListReadonlyComponent } from './components/card-content-string-list-readonly/card-content-string-list-readonly.component';
import { CardContentStringMultilineReadonlyComponent } from './components/card-content-string-multiline-readonly/card-content-string-multiline-readonly.component';
import { CardContentFileReadonlyComponent } from './components/card-content-file-readonly/card-content-file-readonly.component';
import { CardContentTitleIconComponent } from './components/card-content-title-icon/card-content-title-icon.component';
import { HeaderDetailComponent } from './components/header-detail/header-detail.component';
import { HeaderDetailTitleComponent } from './components/header-detail-title/header-detail-title.component';
import { CardContentTitleImageComponent } from './components/card-content-title-image/card-content-title-image.component';
import { CardContentTitleWeblinkReadonlyComponent } from './components/card-content-title-weblink-readonly/card-content-title-weblink-readonly.component';
import { MaterialsCardComponent } from './components/materials-card/materials-card.component';
import { MaterialModalComponent } from './components/material-modal/material-modal.component';
import { SidebarBackLinkComponent } from './components/sidebar-back-link/sidebar-back-link.component';
import { SidebarProductSearchComponent } from './components/sidebar-product-search/sidebar-product-search.component';
import { SidebarProductConfigurationsComponent } from './components/sidebar-product-configurations/sidebar-product-configurations.component';
import { CadLinksModalComponent } from './components/cad-links-modal/cad-links-modal.component';
import { CadLinksSearchComponent } from './components/cad-links-modal/cad-links-search/cad-links-search.component';
import { CadLinksSearchResultComponent } from './components/cad-links-modal/cad-links-search/cad-links-search-result/cad-links-search-result.component';
import { CadLinksManualEntryComponent } from './components/cad-links-modal/cad-links-manual-entry/cad-links-manual-entry.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { IntegrationSelectionDropdownComponent } from './components/integration-selection-dropdown/integration-selection-dropdown.component';
import { IntegrationPillComponent } from './components/integration-pill/integration-pill.component';
import { GlobalConfigurationSearchComponent } from './components/global-configuration-search/global-configuration-search.component';
import { GlobalConfigurationListItemComponent } from './components/global-configuration-list-item/global-configuration-list-item.component';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { LocalesDropdownComponent } from './components/locales-dropdown/locales-dropdown.component';
import { ViewImageDetailsDigitalAssetManagerModalComponent } from './components/view-image-details-digital-asset-manager-modal/view-image-details-digital-asset-manager-modal.component';
import { ViewImageDetailsUrlComponent } from './components/view-image-details-digital-asset-manager-modal/view-image-details-url/view-image-details-url.component';
import { EndpointOfferingSearchComponent } from './components/endpoint-offering-search/endpoint-offering-search.component';
import { CategoriesSelectedReadonlyComponent } from './components/categories-selected-readonly/categories-selected-readonly.component';
import { GlobalCategoriesSelectedReadonlyComponent } from './components/global-categories-selected-readonly/global-categories-selected-readonly.component';
import { CardTitleFlagImageComponent } from './components/card-title-flag-image/card-title-flag-image.component';
import { SidebarProductsSearchComponent } from './components/sidebar-products-search/sidebar-products-search.component';
import { PhoneNumberPipe } from './pipes/phone-number/phone-number.pipe';
import { BynderImageRefreshComponent } from './components/bynder-image-refresh/bynder-image-refresh.component';
import { BynderImageRefreshNoImageComponent } from './components/bynder-image-refresh-noimage/bynder-image-refresh-noimage.component';
import { AttributeOptionReadonlyComponent } from './components/attribute-option-readonly/attribute-option-readonly.component';
import { AttributeTypeReadonlyComponent } from './components/attribute-type-readonly/attribute-type-readonly.component';
import { AttributeOptionDetailReadonlyModalComponent } from './components/attribute-option-detail-readonly-modal/attribute-option-detail-readonly-modal.component';
import { CardContentImageComponent } from './components/card-content-image/card-content-image.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { TextOverflowPipe } from './pipes/text-overflow/text-overflow.pipe';
import { ProductAttributeOptionChildrenComponent } from './components/product-attribute-option-children/product-attribute-option-children.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    LightboxModule,
    InfiniteScrollModule,
    NgChartsModule
  ],
  declarations: [
    NameModalComponent,
    ConfirmationDialogComponent,
    AlertMessageComponent,
    AlertMessageCardComponent,
    AddImageUrlModalComponent,
    EditSnackBarComponent,
    DigitalAssetManagerModalComponent,
    ViewImageDetailsDigitalAssetManagerModalComponent,
    DigitalAssetLibraryComponent,
    ImageUrlComponent,
    ViewImageDetailsUrlComponent,
    DigitalAssetLibraryImageGridComponent,
    GhostDisplayCardComponent,
    GhostTableComponent,
    GhostSidebarHeaderComponent,
    GhostSidebarItemComponent,
    GhostAccordionComponent,
    LocalesSelectedComponent,
    AddProductLiteratureModalComponent,
    ProductLiteratureGridComponent,
    ProductLiteratureDocumentEntryComponent,
    LocaleSelectComponent,
    AddProductCertificationsModalComponent,
    AvailableInComponent,
    DimensionRangeComponent,
    DimensionComponent,
    FileUrlModalComponent,
    CardContentBooleanReadonlyComponent,
    CardContentStringReadonlyComponent,
    CardContentComponent,
    CardContentImageComponent,
    CardContentStringListReadonlyComponent,
    CardContentStringMultilineReadonlyComponent,
    CardContentFileReadonlyComponent,
    CardContentTitleIconComponent,
    CardContentTitleImageComponent,
    CardContentTitleWeblinkReadonlyComponent,
    HeaderDetailComponent,
    HeaderDetailTitleComponent,
    MaterialsCardComponent,
    MaterialModalComponent,
    SidebarBackLinkComponent,
    SidebarProductSearchComponent,
    SidebarProductConfigurationsComponent,
    CadLinksModalComponent,
    CadLinksSearchComponent,
    CadLinksSearchResultComponent,
    CadLinksManualEntryComponent,
    LoadingIndicatorComponent,
    IntegrationSelectionDropdownComponent,
    IntegrationPillComponent,
    GlobalConfigurationSearchComponent,
    GlobalConfigurationListItemComponent,
    LocalesDropdownComponent,
    EnumToArrayPipe,
    LocalesDropdownComponent,
    EndpointOfferingSearchComponent,
    CategoriesSelectedReadonlyComponent,
    GlobalCategoriesSelectedReadonlyComponent,
    CardTitleFlagImageComponent,
    SidebarProductsSearchComponent,
    PhoneNumberPipe,
    TextOverflowPipe,
    BynderImageRefreshComponent,
    BynderImageRefreshNoImageComponent,
    AttributeOptionReadonlyComponent,
    AttributeTypeReadonlyComponent,
    AttributeOptionDetailReadonlyModalComponent,
    CardContentImageComponent,
    ImageUploadComponent,
    ProductAttributeOptionChildrenComponent
  ],
  entryComponents: [
    NameModalComponent,
    ConfirmationDialogComponent,
    AddImageUrlModalComponent,
    EditSnackBarComponent,
    DigitalAssetManagerModalComponent,
    ViewImageDetailsDigitalAssetManagerModalComponent,
    AddProductLiteratureModalComponent,
    AddProductCertificationsModalComponent,
    FileUrlModalComponent,
    MaterialModalComponent,
    AttributeOptionDetailReadonlyModalComponent
  ],
  exports: [
    AlertMessageComponent,
    AlertMessageCardComponent,
    CommonModule,
    MaterialModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NgxMaskModule,
    EditSnackBarComponent,
    LightboxModule,
    InfiniteScrollModule,
    GhostDisplayCardComponent,
    GhostTableComponent,
    GhostSidebarHeaderComponent,
    GhostSidebarItemComponent,
    GhostAccordionComponent,
    LocalesDropdownComponent,
    LocalesSelectedComponent,
    LocaleSelectComponent,
    AvailableInComponent,
    DimensionRangeComponent,
    DimensionComponent,
    CardContentComponent,
    CardContentImageComponent,
    CardContentBooleanReadonlyComponent,
    CardContentStringListReadonlyComponent,
    CardContentStringMultilineReadonlyComponent,
    CardContentStringReadonlyComponent,
    CardContentFileReadonlyComponent,
    CardContentTitleIconComponent,
    CardContentTitleImageComponent,
    CardContentTitleWeblinkReadonlyComponent,
    HeaderDetailComponent,
    HeaderDetailTitleComponent,
    MaterialsCardComponent,
    SidebarBackLinkComponent,
    SidebarProductSearchComponent,
    SidebarProductConfigurationsComponent,
    LoadingIndicatorComponent,
    IntegrationSelectionDropdownComponent,
    IntegrationPillComponent,
    GlobalConfigurationSearchComponent,
    GlobalConfigurationListItemComponent,
    LoadingIndicatorComponent,
    EndpointOfferingSearchComponent,
    CategoriesSelectedReadonlyComponent,
    GlobalCategoriesSelectedReadonlyComponent,
    CardTitleFlagImageComponent,
    SidebarProductsSearchComponent,
    PhoneNumberPipe,
    TextOverflowPipe,
    BynderImageRefreshComponent,
    BynderImageRefreshNoImageComponent,
    AttributeOptionReadonlyComponent,
    AttributeTypeReadonlyComponent,
    ImageUploadComponent
  ]
})
export class SharedModule { }
