import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import { assign as _assign } from 'lodash-es';

export interface IDesigner extends IBaseAuditModel {
  name: string;
  imageLink: string;
  bio: string;
  webPageUrl: string;
  aemTag: string;
}

export class Designer extends BaseAuditModel implements IDesigner {
  name: string;
  imageLink: string;
  bio: string;
  webPageUrl: string;
  aemTag: string;

  constructor(data?: IDesigner) {
    super(data);

    if (!data) {
      return;
    }

    _assign(this, data);
  }
}
