import { Region } from 'src/app/core/models/region.model';
import { Action } from '@ngrx/store';

export enum RegionApiActionTypes {
  LoadSuccess           = '[Regions/API] Fetch Success',
  LoadFailure           = '[Regions/API] Fetch Failure',
}

export class LoadSuccess implements Action {
  readonly type = RegionApiActionTypes.LoadSuccess;

  constructor(public payload: Array<Region>) { }
}

export class LoadFailure implements Action {
  readonly type = RegionApiActionTypes.LoadFailure;

  constructor(public payload: any) { }
}

export type RegionApiActionsUnion =
    LoadSuccess
  | LoadFailure;
