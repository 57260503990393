import { Action } from '@ngrx/store';

export enum TechnologiesActionTypes {
  LoadTechnologies = '[Technologies] Load Technologies',
}

export class LoadTechnologies implements Action {
  readonly type = TechnologiesActionTypes.LoadTechnologies;

  constructor() { }
}

export type TechnologiesActionsUnion = LoadTechnologies;
