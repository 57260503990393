<div class="sidebar-collection-container scrollbar">

  <div class="sidebar-search-input-container">
    <mat-form-field class="sidebar-search-input-box" appearance="outline">
      <input
        (click)="$event.stopPropagation()"
        matInput
        placeholder="Search..."
        autocomplete="off"
        #input>
      <mat-icon
        *ngIf="!globalProductsLoading"
        matSuffix>search</mat-icon>
      <mat-icon
        class="loading"
        *ngIf="globalProductsLoading"
        matSuffix>sync</mat-icon>
    </mat-form-field>

    <div *ngIf="page && page?.totalRecordCount > PAGE_SIZE">
      <button
        mat-icon-button
        disableRipple
        [disabled]="page.pageNumber === 1"
        (click)="previousPage()">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
    </div>
    <div *ngIf="page && page?.totalRecordCount > PAGE_SIZE">
      <button
        mat-icon-button
        disableRipple
        [disabled]="page.pageNumber === page.totalPageCount"
        (click)="nextPage()">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
  </div>

  <div class="sidebar-details-header-container">
    <div class="sidebar-details-header">
      <span *ngIf="page && !globalProductsLoading">
        Displaying {{ getPageStartIndex() }} - {{ getPageEndIndex() }} of {{ +(page?.totalRecordCount) }} products.
      </span>
      <span *ngIf="!page && !globalProductsLoading">
        Displaying 0 of 0 configurations.
      </span>
      <span *ngIf="globalProductsLoading">
        <em>Loading...</em>
      </span>
    </div>
  </div>

  <div *ngIf="globalProductsLoaded && !globalProductsLoading">
    <div *ngFor="let globalProduct of page.results">
      <div
        class="sidebar-product-container"
        [ngClass]="{'selectedProduct': isSelectionEnabled && selectedGlobalProductId === globalProduct.id}"
        (click)="navigateToProduct(globalProduct.id);">
        <div class="sidebar-product-container-left">
          <div
            class="product-image-container"
            *ngIf="globalProduct?.thumbnailImageLink1; else imageNotAvailable">
            <img
              [src]="globalProduct.thumbnailImageLink1"
              [alt]="globalProduct.name"
              onerror="this.src='../../../../assets/images/placeholders/product_placeholder.png'" />
          </div>

          <ng-template #imageNotAvailable>
            <img
              class="product-image-container"
              src="../../../../assets/images/placeholders/product_placeholder.png"
              alt="Placeholder image">
          </ng-template>
        </div>

        <div
          class="sidebar-product-container-middle">
          <div
            class="sidebar-name-container"
            [matTooltip]="globalProduct.name">
            <span class="sidebar-product-name">{{ globalProduct.name }}</span>
          </div>
          <span>{{ globalProduct.brandName }}</span>
        </div>

        <div class="sidebar-product-container-right">
          <!-- Status is removed till the feature is added in. NOTE: Commented code needs to be made dynamic -->
          <!-- <div class="pill status-published">
            <div class="pill-text">
                Published
            </div>
          </div> -->
        </div>
      </div>
      <mat-divider></mat-divider>
    </div>

    <div *ngIf="this.page?.results?.length === 0">
      <div class="sidebar-message-container">
        <span class="sidebar-message">No Products</span>
      </div>
    </div>
  </div>

  <div *ngIf="!globalProductsLoaded && !globalProductsLoading">
    <div class="sidebar-message-container">
      <span class="sidebar-message">Failed to Load Products.</span>
    </div>
  </div>
</div>
