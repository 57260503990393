import { MediaSubType } from 'src/app/core/models/media-subtype.model';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { iif, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { map, switchMap, catchError } from 'rxjs/operators';

import {
  DamAssetsMetapropertyApiActions, DamAssetsMetapropertyActions
} from '../actions';
import * as fromStore from '../../reducers';
import { DigitalAssetManagerService } from 'src/app/core/services/digital-asset-manager.service';

@Injectable()
export class DamAssetsMetapropertyEffects {
  @Effect()
  loadDamAssetsMetaproperty$ = this.actions$.pipe(
    ofType(DamAssetsMetapropertyActions.DamAssetsMetapropertyActionTypes.LoadDamAssetsMetaproperty),
    map((action: DamAssetsMetapropertyActions.LoadDamAssetsMetaproperty) => action.payload),
    switchMap(assetMetapropertyString =>
      this.damAssetManagerService.getAssetMetapropertyDetails$(assetMetapropertyString).pipe(
        map(
          (assetMetaproperty) => new DamAssetsMetapropertyApiActions.LoadDamAssetsMetapropertySuccess({ metaProperty: assetMetaproperty, code: assetMetapropertyString })
        ),
        catchError(error =>
          of(new DamAssetsMetapropertyApiActions.LoadDamAssetsMetapropertyFailure(error))
        )
      )
    )
  );


  @Effect()
  loadDamAssetsMetapropertyOptions$ = this.actions$.pipe(
    ofType(DamAssetsMetapropertyActions.DamAssetsMetapropertyActionTypes.LoadDamAssetsMetapropertyOptions),
    map((action: DamAssetsMetapropertyActions.LoadDamAssetsMetapropertyOptions) => action.payload),
    switchMap(assetMetaproperty =>
      iif(() => !assetMetaproperty.omniUser
        , this.damAssetManagerService.getAssetMetapropertyOptions$(assetMetaproperty.id, assetMetaproperty.name, assetMetaproperty.includeOnlyOptionsSubset, assetMetaproperty.omniUser).pipe(
          map(
            (assetMetapropertyOptions) => new DamAssetsMetapropertyApiActions.LoadDamAssetsMetapropertyOptionsSuccess({ metaPropertyOption: assetMetapropertyOptions, metaProperty: assetMetaproperty.name })
          ),
          catchError(error =>
            of(new DamAssetsMetapropertyApiActions.LoadDamAssetsMetapropertyFailure(error))
          )
        )
        , this.damAssetManagerService.getAssetMetapropertyOptions$(assetMetaproperty.id, assetMetaproperty.name, assetMetaproperty.includeOnlyOptionsSubset, assetMetaproperty.omniUser).pipe(
          map(
            (assetMetapropertyOptions) => new DamAssetsMetapropertyApiActions.LoadDamAssetsMetapropertyOmniOptionsSuccess({ metaPropertyOption: assetMetapropertyOptions, metaProperty: assetMetaproperty.name })
          ),
          catchError(error =>
            of(new DamAssetsMetapropertyApiActions.LoadDamAssetsMetapropertyFailure(error))
          )
        )
      )
    )
  );

  @Effect()
  loadDamAssetsMetapropertyOmniOptions$ = this.actions$.pipe(
    ofType(DamAssetsMetapropertyActions.DamAssetsMetapropertyActionTypes.LoadDamAssetsMetapropertyOmniOptions),
    map((action: DamAssetsMetapropertyActions.LoadDamAssetsMetapropertyOmniOptions) => action.payload),
    switchMap(assetMetaproperty =>
      iif(() => !assetMetaproperty.omniUser
        , this.damAssetManagerService.getAssetMetapropertyOptions$(assetMetaproperty.id, assetMetaproperty.name, assetMetaproperty.includeOnlyOptionsSubset, assetMetaproperty.omniUser).pipe(
          map(
            (assetMetapropertyOptions) => new DamAssetsMetapropertyApiActions.LoadDamAssetsMetapropertyOptionsSuccess({ metaPropertyOption: assetMetapropertyOptions, metaProperty: assetMetaproperty.name })
          ),
          catchError(error =>
            of(new DamAssetsMetapropertyApiActions.LoadDamAssetsMetapropertyFailure(error))
          )
        )
        , this.damAssetManagerService.getAssetMetapropertyOptions$(assetMetaproperty.id, assetMetaproperty.name, assetMetaproperty.includeOnlyOptionsSubset, assetMetaproperty.omniUser).pipe(
          map(
            (assetMetapropertyOptions) => new DamAssetsMetapropertyApiActions.LoadDamAssetsMetapropertyOmniOptionsSuccess({ metaPropertyOption: assetMetapropertyOptions, metaProperty: assetMetaproperty.name })
          ),
          catchError(error =>
            of(new DamAssetsMetapropertyApiActions.LoadDamAssetsMetapropertyFailure(error))
          )
        )
      )
    )
  );

  @Effect()
  loadMediaSubTypes$ = this.actions$.pipe(
    ofType(DamAssetsMetapropertyActions.DamAssetsMetapropertyActionTypes.LoadMediaSubTypes),
    map((action: DamAssetsMetapropertyActions.LoadMediaSubTypes) => {  }),
    switchMap(() =>
      this.damAssetManagerService.getMediaSubTypes$()
        .pipe(
          map((mediaSubTypes: Array<MediaSubType>) => new DamAssetsMetapropertyApiActions.LoadMediaSubTypesSuccess(mediaSubTypes)
        ),
        catchError(error => of(new DamAssetsMetapropertyApiActions.LoadMediaSubTypesFailure(error)))
      )
    )
  );

  constructor(
    private actions$: Actions,
    private damAssetManagerService: DigitalAssetManagerService,
    private store: Store<fromStore.State>) { }
}
