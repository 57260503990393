import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isUndefined } from 'util';

import { environment } from '../../../environments/environment';
import { UserIdentity } from './user-identity.model';
import { Area } from 'src/app/shared/models/area.model';

@Injectable()
export class AuthService {

  private readonly storageKey: string = '.identity';

  private areas: Area[] = [
    { label: 'Products', url: '/products', children: [] },
    { label: 'Applications', url: '/applications', children: [] },
    { label: 'Integrations', url: '/integrations', children: [] },
    { label: 'Admin', url: '/admin', children: [] }
  ];

  constructor(private http: HttpClient) {
  }

  public getToken(): string {
    const identity = this.getIdentity();
    return identity && identity.token;
  }

  public isAuthenticated(): boolean {
    // check for a token, if adding expiry could check here as well
    return !!this.getToken();
  }

  public authenticate(token: string): Observable<UserIdentity> {
    // first clear identity in case user was logged in
    localStorage.removeItem(this.storageKey);

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`
      })
    };

    // attempt to authenticate with new token
    return this.http.get<UserIdentity>(`${environment.apiUrl}account`, httpOptions)
      .pipe(map(identity => {
        identity.token = token;
        this.storeIdentity(identity);
        return identity;
      }));
  }

  public logout(goToLogin: boolean = false) {
    localStorage.removeItem(this.storageKey);
    if (goToLogin) {
      window.location.href = environment.loginUrl;
    } else {
      window.location.href = environment.logoutUrl;
    }
  }

  public getIdentity(): UserIdentity {
    const identityString = localStorage.getItem(this.storageKey);
    let identity: UserIdentity = null;

    if (identityString) {
      identity = JSON.parse(identityString);
    }

    return identity;
  }

  private storeIdentity(identity: UserIdentity) {
    localStorage.setItem(this.storageKey, JSON.stringify(identity));
  }

  public getAreas(): Area[] {
    const areas = this.areas.filter(x => this.canAccess(x));
    areas.forEach(x => x.children = x.children.filter(y => this.canAccess(y)));
    return areas;
  }

  public getArea(url: string): Area {
    const area = this.getAreaByUrl(url);
    if (area && this.canAccess(area)) {
      // remove children without permission access
      area.children = area.children.filter(y => this.canAccess(y));
      return area;
    } else {
      // can't access
      return null;
    }
  }

  public canAccessUrl(url: string): boolean {
    const area = this.getAreaByUrl(url);
    return !!area && this.canAccess(area);
  }

  private getAreaByUrl(url: string) {
    let match = this.areas.find(x => x.url === url);

    if (match) {
      return match;
    } else {
      // if area not found, check children
      for (const area of this.areas) {
        match = area.children.find(x => x.url === url);
        if (match) {
          return match;
        }
      }
    }

    // no match found, possibly in a sub page so remove last part of URL
    // and try again
    const lastPartStart = url.lastIndexOf('/');
    if (lastPartStart > 0) {
      url = url.substring(0, lastPartStart);
      return this.getAreaByUrl(url);
    }

    return null;
  }

  private canAccess(area: Area): boolean {
    const identity = this.getIdentity();
    // permissions not implemented yet, only super users have access
    // For now, just check that the user has a role.
    // console.log(identity);
    // console.log(identity.userRoles);
    if (identity) {
      if (isUndefined(identity.userRoles)) {
        this.logout(true);
      } else {
        const hasRoles = identity.userRoles.some(item => item.role);
        if (!hasRoles) {
          return false;
        }

        if (area.label === 'Admin' && !identity.userRoles.some(item => item.role.id === 1)) {
          return false;
        }

        return true;
      }
    }
  }
}
