<div>
  <div mat-dialog-title>
    <h6 class="h6">
      <strong *ngIf="isBynderImage()">Bynder Image Details</strong>
      <strong *ngIf="isAemImage()">AEM Image Details</strong>
      <strong *ngIf="isManualEntryImage()"> Manual Entry Image Details</strong>
    </h6>
  </div>

  <div mat-dialog-content class="dialog-content">
    <app-view-image-details-url
      [mediaLink]="mediaLink">
    </app-view-image-details-url>
  </div>

  <div mat-dialog-actions>
    <ng-container>
      <button
        class="mat-raised-button button-spacing"
        [mat-dialog-close]>
        Close
      </button>
    </ng-container>
  </div>

</div>
