import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import { assign as _assign } from 'lodash-es';

export interface IFamilyDuplicateCheck extends IBaseAuditModel {
  name: string;
}

export class FamilyDuplicateCheck extends BaseAuditModel implements IFamilyDuplicateCheck {
  name: string;

  constructor(data?: IFamilyDuplicateCheck) {
    super(data);

    if (!data) {
      return;
    }
    _assign(this, data);
  }
}
