import { Subcategory } from 'src/app/core/models/subcategory.model';
import { SubcategoryStatus } from 'src/app/shared/constants';
import {
  SubcategoryApiActions,
  SubcategoryActions
} from '../actions';

export interface State {
  subcategory: Subcategory;
  loading: boolean;
  loaded: boolean;
  status: SubcategoryStatus;
  error: string;
  success: string;
}

export const initialState: State = {
  subcategory: null,
  loading: false,
  loaded: false,
  status: SubcategoryStatus.None,
  error: null,
  success: null
};

export function reducer(state = initialState, action: SubcategoryActions.SubcategoryActionsUnion | SubcategoryApiActions.SubcategoryApiActionsUnion): State {
  switch (action.type) {

    case SubcategoryActions.SubcategoryActionTypes.LoadSubcategory: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case SubcategoryApiActions.SubcategoryApiActionTypes.LoadSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        subcategory: action.payload
      };
    }

    case SubcategoryApiActions.SubcategoryApiActionTypes.LoadFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        subcategory: null,
        error: action.payload
      };
    }

    case SubcategoryActions.SubcategoryActionTypes.ClearMessages: {
      return {
        ...state,
        status: SubcategoryStatus.None,
        error: null,
        success: null
      };
    }

    case SubcategoryActions.SubcategoryActionTypes.CreateSubcategory: {
      return {
        ...state,
      };
    }

    case SubcategoryApiActions.SubcategoryApiActionTypes.CreateSuccess: {
      return {
        ...state,
        subcategory: action.payload,
        status: SubcategoryStatus.CreateSuccess,
      };
    }

    case SubcategoryApiActions.SubcategoryApiActionTypes.CreateFailure: {
      return {
        ...state,
        subcategory: null,
        status: SubcategoryStatus.CreateFailure,
        error: 'Failed to Create Brand Subcategory',
        success: null
      };
    }

    case SubcategoryActions.SubcategoryActionTypes.UpdateSubcategory: {
      return {
        ...state,
        loading: true,
        loaded: false,
        status: SubcategoryStatus.None
      };
    }

    case SubcategoryApiActions.SubcategoryApiActionTypes.UpdateSuccess: {
      return {
        ...state,
        status: SubcategoryStatus.UpdateSuccess,
        error: null,
        success: `Successfully Updated Brand Subcategory: ${action.payload.subcategoryName}`
      };
    }

    case SubcategoryApiActions.SubcategoryApiActionTypes.UpdateFailure: {
      return {
        ...state,
        loading: false,
        loaded: true,
        status: SubcategoryStatus.UpdateFailure,
        error: 'Failed to Update Brand Subcategory',
        success: null
      };
    }

    case SubcategoryActions.SubcategoryActionTypes.RenameSubcategory: {
      return {
        ...state
      };
    }

    case SubcategoryApiActions.SubcategoryApiActionTypes.RenameSuccess: {
      const subcategory = { ...state.subcategory };
      subcategory.name = action.payload.subcategoryName;

      return {
        ...state,
        status: SubcategoryStatus.RenameSuccess,
        error: null,
        success: `Successfully renamed Brand Subcategory: ${action.payload.subcategoryName}`
      };
    }

    case SubcategoryApiActions.SubcategoryApiActionTypes.RenameFailure: {
      return {
        ...state,
        subcategory: null,
        status: SubcategoryStatus.RenameFailure,
        error: 'Failed to rename Brand Subcategory',
        success: null
      };
    }

    case SubcategoryActions.SubcategoryActionTypes.DeleteSubcategory: {
      return {
        ...state,
      };
    }

    case SubcategoryApiActions.SubcategoryApiActionTypes.DeleteSuccess: {
      return {
        ...state,
        status: SubcategoryStatus.DeleteSuccess,
        error: null,
        success: `Successfully Deleted Brand Subcategory: ${action.payload.subcategoryName}`
      };
    }

    case SubcategoryApiActions.SubcategoryApiActionTypes.DeleteFailure: {
      return {
        ...state,
        subcategory: null,
        status: SubcategoryStatus.DeleteFailure,
        error: 'Failed to Delete Brand Subcategory',
        success: null
      };
    }

    default:
      return state;
  }
}

export const getSubcategory = (state: State) => state.subcategory;
export const getErrorMessage = (state: State) => state.error;
export const getSuccessMessage = (state: State) => state.success;
export const getStatus = (state: State) => state.status;
export const isLoading = (state: State) => state.loading;
export const isLoaded = (state: State) => state.loaded;
