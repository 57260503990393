import { Action } from '@ngrx/store';
import { FamilyPost } from 'src/app/core/models/family-post.model';
import { FamilyPut } from 'src/app/core/models/family-put.model';

export enum FamilyActionTypes {
  LoadFamily = '[Family] Load Family',
  CreateFamily = '[Family] Create Family',
  UpdateFamily = '[Family] Update Family',
  RenameFamily = '[Family] Rename Family',
  DeleteFamily = '[Family] Delete Family',
  ClearMessages = '[Family] Clear Messages',
}

export class LoadFamily implements Action {
  readonly type = FamilyActionTypes.LoadFamily;

  constructor(public payload: number) { }
}

export class CreateFamily implements Action {
  readonly type = FamilyActionTypes.CreateFamily;

  constructor(public payload: FamilyPost) { }
}

export class UpdateFamily implements Action {
  readonly type = FamilyActionTypes.UpdateFamily;

  constructor(public payload: { family: FamilyPut, familyName: string }) { }
}

export class RenameFamily implements Action {
  readonly type = FamilyActionTypes.RenameFamily;

  constructor(public payload: { familyId: number, familyName: string }) { }
}

export class DeleteFamily implements Action {
  readonly type = FamilyActionTypes.DeleteFamily;

  constructor(public payload: { familyId: number, familyName: string }) { }
}

export class ClearMessages implements Action {
  readonly type = FamilyActionTypes.ClearMessages;

  constructor() { }
}

export type FamilyActionsUnion =
  LoadFamily
  | CreateFamily
  | UpdateFamily
  | RenameFamily
  | DeleteFamily
  | ClearMessages;
