import { Action } from '@ngrx/store';
import { Role } from 'src/app/core/models/role.model';

export enum RolesApiActionTypes {
  LoadSuccess = '[Roles/API] Load Success',
  LoadFailure = '[Roles/API] Load Failure',
}

export class LoadSuccess implements Action {
  readonly type = RolesApiActionTypes.LoadSuccess;

  constructor(public payload: Role[]) { }
}

export class LoadFailure implements Action {
  readonly type = RolesApiActionTypes.LoadFailure;

  constructor(public payload: any) { }
}

export type RolesApiActionsUnion = LoadSuccess | LoadFailure;
