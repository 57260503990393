import { Action } from '@ngrx/store';
import { ApplicationTag } from 'src/app/core/models/application-tag.model';
import { ApplicationTagPut } from 'src/app/core/models/application-tag-put.model';

export enum ApplicationTagApiActionTypes {
  LoadSuccess = '[ApplicationTag/API] Load Success',
  LoadFailure = '[ApplicationTag/API] Load Failure',
  CreateSuccess = '[ApplicationTag/API] Create Success',
  CreateFailure = '[ApplicationTag/API] Create Failure',
  UpdateSuccess = '[ApplicationTag/API] Update Success',
  UpdateFailure = '[ApplicationTag/API] Update Failure',
  RenameSuccess = '[ApplicationTag/API] Rename Success',
  RenameFailure = '[ApplicationTag/API] Rename Failure',
  DeleteSuccess = '[ApplicationTag/API] Delete Success',
  DeleteFailure = '[ApplicationTag/API] Delete Failure'
}

export class LoadSuccess implements Action {
  readonly type = ApplicationTagApiActionTypes.LoadSuccess;

  constructor(public payload: ApplicationTag) { }
}

export class LoadFailure implements Action {
  readonly type = ApplicationTagApiActionTypes.LoadFailure;

  constructor(public payload: any) { }
}

export class CreateSuccess implements Action {
  readonly type = ApplicationTagApiActionTypes.CreateSuccess;

  constructor(public payload: ApplicationTag) { }
}

export class CreateFailure implements Action {
  readonly type = ApplicationTagApiActionTypes.CreateFailure;

  constructor(public payload: any) { }
}


export class UpdateSuccess implements Action {
  readonly type = ApplicationTagApiActionTypes.UpdateSuccess;

  constructor(public payload: { applicationTag: ApplicationTagPut, applicationTagName: string }) { }
}

export class UpdateFailure implements Action {
  readonly type = ApplicationTagApiActionTypes.UpdateFailure;

  constructor(public payload: any) { }
}

export class RenameSuccess implements Action {
  readonly type = ApplicationTagApiActionTypes.RenameSuccess;

  constructor(public payload: { applicationTagId: number, applicationTagName: string }) { }
}

export class RenameFailure implements Action {
  readonly type = ApplicationTagApiActionTypes.RenameFailure;

  constructor(public payload: any) { }
}

export class DeleteSuccess implements Action {
  readonly type = ApplicationTagApiActionTypes.DeleteSuccess;

  constructor(public payload: { applicationTagId: number, applicationTagName: string }) { }
}

export class DeleteFailure implements Action {
  readonly type = ApplicationTagApiActionTypes.DeleteFailure;

  constructor(public payload: any) { }
}

export type ApplicationTagApiActionsUnion =
  LoadSuccess
  | LoadFailure
  | CreateSuccess
  | CreateFailure
  | UpdateSuccess
  | UpdateFailure
  | RenameSuccess
  | RenameFailure
  | DeleteSuccess
  | DeleteFailure;
