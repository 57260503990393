import { Action } from '@ngrx/store';
import { Endpoint } from 'src/app/core/models/endpoint.model';

export enum EndpointsActionTypes {
  LoadEndpoints = '[Endpoints] Load Endpoints',
  CreateEndpoint = '[Endpoints] Create Endpoint',
  RenameEndpoint = '[Endpoints] Rename Endpoint',
  DuplicateEndpoint = '[Endpoints] Duplicate Endpoint',
  DeleteEndpoint = '[Endpoints] Delete Endpoint',
  ClearMessages = '[Endpoints] Clear Messages'
}

export class LoadEndpoints implements Action {
  readonly type = EndpointsActionTypes.LoadEndpoints;
}

export class CreateEndpoint implements Action {
  readonly type = EndpointsActionTypes.CreateEndpoint;

  constructor(public payload: Endpoint) { }
}

export class RenameEndpoint implements Action {
  readonly type = EndpointsActionTypes.RenameEndpoint;

  constructor(public payload: {integrationId: number, endpointId: number, endpointName: string}) { }
}

export class DuplicateEndpoint implements Action {
  readonly type = EndpointsActionTypes.DuplicateEndpoint;

  constructor(public payload: {endpointId: number, endpointName: string}) { }
}

export class DeleteEndpoint implements Action {
  readonly type = EndpointsActionTypes.DeleteEndpoint;

  constructor(public payload: {endpointId: number, endpointName: string}) { }
}

export class ClearMessages implements Action {
  readonly type = EndpointsActionTypes.ClearMessages;

  constructor() { }
}

export type EndpointsActionsUnion =
    LoadEndpoints
  | CreateEndpoint
  | RenameEndpoint
  | DuplicateEndpoint
  | DeleteEndpoint
  | ClearMessages
  ;
