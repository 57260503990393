import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import { assign as _assign } from 'lodash-es';

export interface ITemplatePost extends IBaseAuditModel {
  id: number;
  name: string;
  integrationId: number;

}

export class TemplatePost extends BaseAuditModel {
  override id: number;
  name: string;
  integrationId: number;

  constructor(data?: ITemplatePost) {
    super(data);

    if (!data) {
      return;
    }

    _assign(this, data);
  }
}
