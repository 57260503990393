import {
  GlobalCategoryApiActions,
  GlobalCategoryActions
} from '../actions';

import { GlobalCategory } from 'src/app/core/models/global-category.model';
import { GlobalCategoryStatus } from 'src/app/shared/constants';

export interface State {
  globalCategory: GlobalCategory;
  loading: boolean;
  loaded: boolean;
  status: GlobalCategoryStatus;
  error: string;
  success: string;
}

export const initialState: State = {
  globalCategory: null,
  loading: false,
  loaded: false,
  status: GlobalCategoryStatus.None,
  error: null,
  success: null
};

export function reducer(state = initialState, action: GlobalCategoryActions.GlobalCategoryActionsUnion | GlobalCategoryApiActions.GlobalCategoryApiActionsUnion): State {
  switch (action.type) {

    case GlobalCategoryActions.GlobalCategoryActionTypes.LoadGlobalCategory: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case GlobalCategoryApiActions.GlobalCategoryApiActionTypes.LoadSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        globalCategory: action.payload
      };
    }

    case GlobalCategoryApiActions.GlobalCategoryApiActionTypes.LoadFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        globalCategory: null,
        error: action.payload
      };
    }

    case GlobalCategoryActions.GlobalCategoryActionTypes.ClearMessages: {
      return {
        ...state,
        status: GlobalCategoryStatus.None,
        error: null,
        success: null
      };
    }

    case GlobalCategoryActions.GlobalCategoryActionTypes.CreateGlobalCategory: {
      return {
        ...state,
      };
    }

    case GlobalCategoryApiActions.GlobalCategoryApiActionTypes.CreateSuccess: {
      return {
        ...state,
        globalCategory: action.payload,
        status: GlobalCategoryStatus.CreateSuccess,
      };
    }

    case GlobalCategoryApiActions.GlobalCategoryApiActionTypes.CreateFailure: {
      return {
        ...state,
        globalCategory: null,
        status: GlobalCategoryStatus.CreateFailure,
        error: 'Failed to Create Global Category',
        success: null
      };
    }

    case GlobalCategoryActions.GlobalCategoryActionTypes.UpdateGlobalCategory: {
      return {
        ...state,
        loading: true,
        loaded: false,
        status: GlobalCategoryStatus.None
      };
    }

    case GlobalCategoryApiActions.GlobalCategoryApiActionTypes.UpdateSuccess: {
      return {
        ...state,
        status: GlobalCategoryStatus.UpdateSuccess,
        error: null,
        success: `Successfully Updated Global Category: ${action.payload.globalCategoryName}`
      };
    }

    case GlobalCategoryApiActions.GlobalCategoryApiActionTypes.UpdateFailure: {
      return {
        ...state,
        loading: false,
        loaded: true,
        status: GlobalCategoryStatus.UpdateFailure,
        error: 'Failed to Update Global Category',
        success: null
      };
    }

    case GlobalCategoryActions.GlobalCategoryActionTypes.RenameGlobalCategory: {
      return {
        ...state
      };
    }

    case GlobalCategoryApiActions.GlobalCategoryApiActionTypes.RenameSuccess: {
      const globalCategory = { ...state.globalCategory };
      globalCategory.name = action.payload.globalCategoryName;

      return {
        ...state,
        status: GlobalCategoryStatus.RenameSuccess,
        error: null,
        success: `Successfully renamed Global Category: ${action.payload.globalCategoryName}`
      };
    }

    case GlobalCategoryApiActions.GlobalCategoryApiActionTypes.RenameFailure: {
      return {
        ...state,
        globalCategory: null,
        status: GlobalCategoryStatus.RenameFailure,
        error: 'Failed to rename Global Category',
        success: null
      };
    }

    case GlobalCategoryActions.GlobalCategoryActionTypes.DeleteGlobalCategory: {
      return {
        ...state,
      };
    }

    case GlobalCategoryApiActions.GlobalCategoryApiActionTypes.DeleteSuccess: {
      return {
        ...state,
        status: GlobalCategoryStatus.DeleteSuccess,
        error: null,
        success: `Successfully Deleted Global Category: ${action.payload.globalCategoryName}`
      };
    }

    case GlobalCategoryApiActions.GlobalCategoryApiActionTypes.DeleteFailure: {
      return {
        ...state,
        globalCategory: null,
        status: GlobalCategoryStatus.DeleteFailure,
        error: 'Failed to Delete Global Category',
        success: null
      };
    }

    default:
      return state;
  }
}

export const getGlobalCategory = (state: State) => state.globalCategory;
export const getErrorMessage = (state: State) => state.error;
export const getSuccessMessage = (state: State) => state.success;
export const getStatus = (state: State) => state.status;
export const isLoading = (state: State) => state.loading;
export const isLoaded = (state: State) => state.loaded;
