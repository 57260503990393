import { ActionReducerMap, MetaReducer, createFeatureSelector, createSelector } from '@ngrx/store';
import { environment } from 'src/environments/environment';

import { ProductOfferingReducer } from './product-offerings-store';
import { ProductReducer } from './products-store';
import { BrandReducer } from './brand-store';
import { TemplatesReducer } from './templates-store';
import { IntegrationsReducer, IntegrationReducer } from './integrations-store';
import { EndpointReducer } from './endpoint-store';
import { EndpointsReducer } from './endpoints-store';
import { RegionReducer } from './regions-store';
import { SelectOptionsReducer } from './select-options-store';
import { LocaleSpecifierReducer } from './locale-specifiers-store';
import { TranslationsReducer } from './translations-store';
import { ApplicationReducer } from './application-store/reducers';
import { SettingsReducer } from './settings-store';
import { TechnologiesReducer } from './technologies-store/reducers';
import { RolesReducer } from './roles-store';
import { UserReducer } from './user-store';
import { ConfigurationsReducer } from './configurations-store';
import { DesignerReducer } from './designer-store';
import { CategoryReducer } from './category-store';
import { SubcategoryReducer } from './subcategory-store';
import { SubBrandReducer } from './sub-brand-store';
import { FamilyReducer } from './families-store';
import { PortfolioReducer } from './portfolios-store';
import { MaterialReducer } from './materials-store';
import { WarrantyReducer } from './warranties-store';
import { ApplicationTagReducer } from './applicationtag-store';
import { DamAssetsMetapropertyReducer } from './dam-assets-store';
import { EndpointProductOfferingsReducer } from './endpoint-product-offerings-store';
import { ContactReducer } from './contact-store';
import { AttributeTypeReducer } from './attribute-type-store';
import { GlobalCategoryReducer } from './global-category-store';
import { GlobalSubcategoryReducer } from './global-subcategory-store';
import { ProductGuidanceAttributesReducer } from './product-guidance-attributes-store';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */

export interface State {
  application: ApplicationReducer.State;
  brand: BrandReducer.State;
  subBrand: SubBrandReducer.State;
  configurations: ConfigurationsReducer.State;
  designer: DesignerReducer.State;
  damAssetMetaproperty: DamAssetsMetapropertyReducer.State;
  category: CategoryReducer.State;
  subcategory: SubcategoryReducer.State;
  endpoint: EndpointReducer.State;
  endpoints: EndpointsReducer.State;
  endpointProductOfferings: EndpointProductOfferingsReducer.State;
  integrations: IntegrationsReducer.State;
  integration: IntegrationReducer.State;
  localeSpecifiers: LocaleSpecifierReducer.State;
  productOffering: ProductOfferingReducer.State;
  product: ProductReducer.State;
  regions: RegionReducer.State;
  roles: RolesReducer.State;
  selectOptions: SelectOptionsReducer.State;
  settings: SettingsReducer.State;
  technologies: TechnologiesReducer.State;
  templates: TemplatesReducer.State;
  translations: TranslationsReducer.State;
  user: UserReducer.State;
  family: FamilyReducer.State;
  portfolio: PortfolioReducer.State;
  material: MaterialReducer.State;
  warranty: WarrantyReducer.State;
  applicationtag: ApplicationTagReducer.State;
  contact: ContactReducer.State;
  attributeType: AttributeTypeReducer.State;
  globalCategory: GlobalCategoryReducer.State;
  globalSubcategory: GlobalSubcategoryReducer.State;
  productGuidanceAttributes: ProductGuidanceAttributesReducer.State;
}
/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<State, any> = {
  application: ApplicationReducer.reducer,
  brand: BrandReducer.reducer,
  subBrand: SubBrandReducer.reducer,
  configurations: ConfigurationsReducer.reducer,
  designer: DesignerReducer.reducer,
  damAssetMetaproperty: DamAssetsMetapropertyReducer.reducer,
  category: CategoryReducer.reducer,
  subcategory: SubcategoryReducer.reducer,
  endpoint: EndpointReducer.reducer,
  endpoints: EndpointsReducer.reducer,
  endpointProductOfferings: EndpointProductOfferingsReducer.reducer,
  integrations: IntegrationsReducer.reducer,
  integration: IntegrationReducer.reducer,
  localeSpecifiers: LocaleSpecifierReducer.reducer,
  productOffering: ProductOfferingReducer.reducer,
  product: ProductReducer.reducer,
  regions: RegionReducer.reducer,
  roles: RolesReducer.reducer,
  settings: SettingsReducer.reducer,
  selectOptions: SelectOptionsReducer.reducer,
  technologies: TechnologiesReducer.reducer,
  templates: TemplatesReducer.reducer,
  translations: TranslationsReducer.reducer,
  user: UserReducer.reducer,
  family: FamilyReducer.reducer,
  portfolio: PortfolioReducer.reducer,
  material: MaterialReducer.reducer,
  warranty: WarrantyReducer.reducer,
  applicationtag: ApplicationTagReducer.reducer,
  contact: ContactReducer.reducer,
  attributeType: AttributeTypeReducer.reducer,
  globalCategory: GlobalCategoryReducer.reducer,
  globalSubcategory: GlobalSubcategoryReducer.reducer,
  productGuidanceAttributes: ProductGuidanceAttributesReducer.reducer
};

export const metaReducers: MetaReducer<any>[] = !environment.production ? [] : [];

export const getAppState = (state: State) => state;

// state for Select Options
export const getSelectOptionsState = createFeatureSelector<SelectOptionsReducer.State>('selectOptions');
export const getSelectOptionsIsLoading = createSelector(
  getSelectOptionsState,
  SelectOptionsReducer.getSelectOptionsIsLoading
);

export const getSelectOptionsIsLoaded = createSelector(
  getSelectOptionsState,
  SelectOptionsReducer.getSelectOptionsIsLoaded
);

export const getSelectOptions = createSelector(
  getSelectOptionsState,
  SelectOptionsReducer.getSelectOptions
);

// state for Regions and Locales
export const getRegionsState = createFeatureSelector<RegionReducer.State>('regions');
export const getRegionsIsLoading = createSelector(
  getRegionsState,
  RegionReducer.getRegionsIsLoading
);

export const getRegionsIsLoaded = createSelector(
  getRegionsState,
  RegionReducer.getRegionsIsLoaded
);

export const getRegions = createSelector(
  getRegionsState,
  RegionReducer.getRegions
);

export const getLocaleSpecifierState = createFeatureSelector<LocaleSpecifierReducer.State>('localeSpecifiers');
export const getLocaleSpecifiersIsLoading = createSelector(
  getLocaleSpecifierState,
  LocaleSpecifierReducer.getLocaleSpecifiersIsLoading
);

export const getLocaleSpecifiersIsLoaded = createSelector(
  getLocaleSpecifierState,
  LocaleSpecifierReducer.getLocaleSpecifiersIsLoaded
);

export const getLocaleSpecifiers = createSelector(
  getLocaleSpecifierState,
  LocaleSpecifierReducer.getLocaleSpecifiers
);

// state for Product Offerings
export const getProductOfferingState = createFeatureSelector<ProductOfferingReducer.State>('productOffering');

export const getProductOfferingIsLoading = createSelector(
  getProductOfferingState,
  ProductOfferingReducer.getSelectedIsLoading
);

export const getProductOfferingIsLoaded = createSelector(
  getProductOfferingState,
  ProductOfferingReducer.getSelectedIsLoaded
);

export const getLoadedProductOffering = createSelector(
  getProductOfferingState,
  ProductOfferingReducer.getSelectedProductOffering
);

export const getProductOfferingsIsLoading = createSelector(
  getProductOfferingState,
  ProductOfferingReducer.getSummariesIsLoading
);

export const getProductOfferingsIsLoaded = createSelector(
  getProductOfferingState,
  ProductOfferingReducer.getSummariesIsLoaded
);

export const getLoadedProductOfferings = createSelector(
  getProductOfferingState,
  ProductOfferingReducer.getLoadedProductOfferings
);

export const getProductOfferingErrorMessage = createSelector(
  getProductOfferingState,
  ProductOfferingReducer.getErrorMessage
);

export const getProductOfferingSuccessMessage = createSelector(
  getProductOfferingState,
  ProductOfferingReducer.getSuccessMessage
);

export const getProductOfferingStatus = createSelector(
  getProductOfferingState,
  ProductOfferingReducer.getStatus
);

// state for the currently loaded product
export const getProductState = createFeatureSelector<ProductReducer.State>('product');

export const getProductIsLoading = createSelector(
  getProductState,
  ProductReducer.getIsLoading
);

export const getProductIsLoaded = createSelector(
  getProductState,
  ProductReducer.getIsLoaded
);

export const getLoadedProduct = createSelector(
  getProductState,
  ProductReducer.getLoadedProduct
);

export const getProductErrorMessage = createSelector(
  getProductState,
  ProductReducer.getErrorMessage
);

export const getProductSuccessMessage = createSelector(
  getProductState,
  ProductReducer.getSuccessMessage
);

export const getProductStatus = createSelector(
  getProductState,
  ProductReducer.getStatus
);

// Brand State Selectors
export const getBrandState = createFeatureSelector<BrandReducer.State>('brand');

export const getAllBrands = createSelector(
  getBrandState,
  BrandReducer.getBrands
);

export const getIsBrandsLoading = createSelector(
  getBrandState,
  BrandReducer.isLoading
);

export const getIsBrandsLoaded = createSelector(
  getBrandState,
  BrandReducer.isLoaded
);

export const getLoadedBrand = createSelector(
  getBrandState,
  BrandReducer.getBrand
);

export const getIsBrandLoading = createSelector(
  getBrandState,
  BrandReducer.isLoading
);

export const getIsBrandLoaded = createSelector(
  getBrandState,
  BrandReducer.isLoaded
);

export const getBrandErrorMessage = createSelector(
  getBrandState,
  BrandReducer.getErrorMessage
);

export const getBrandSuccessMessage = createSelector(
  getBrandState,
  BrandReducer.getSuccessMessage
);

export const getBrandStatus = createSelector(
  getBrandState,
  BrandReducer.getStatus
);

// Application State Selectors
export const getApplicationState = createFeatureSelector<ApplicationReducer.State>('application');

export const getLoadedApplication = createSelector(
  getApplicationState,
  ApplicationReducer.getApplication
);

export const getIsApplicationLoading = createSelector(
  getApplicationState,
  ApplicationReducer.isLoading
);

export const getIsApplicationLoaded = createSelector(
  getApplicationState,
  ApplicationReducer.isLoaded
);

export const getApplicationErrorMessage = createSelector(
  getApplicationState,
  ApplicationReducer.getErrorMessage
);

export const getApplicationSuccessMessage = createSelector(
  getApplicationState,
  ApplicationReducer.getSuccessMessage
);

export const getApplicationStatus = createSelector(
  getApplicationState,
  ApplicationReducer.getStatus
);

// Templates State Selectors
export const getTemplatesState = createFeatureSelector<TemplatesReducer.State>('templates');

export const getAllTemplates = createSelector(
  getTemplatesState,
  TemplatesReducer.getTemplates
);

export const getIsTemplatesLoading = createSelector(
  getTemplatesState,
  TemplatesReducer.isLoading
);

export const getIsTemplatesLoaded = createSelector(
  getTemplatesState,
  TemplatesReducer.isLoaded
);

export const getTemplateErrorMessage = createSelector(
  getTemplatesState,
  TemplatesReducer.getErrorMessage
);

export const getTemplateSuccessMessage = createSelector(
  getTemplatesState,
  TemplatesReducer.getSuccessMessage
);

export const getTemplateStatus = createSelector(
  getTemplatesState,
  TemplatesReducer.getStatus
);

// Endpoint State Selectors
export const getEndpointState = createFeatureSelector<EndpointReducer.State>('endpoint');

export const getEndpoint = createSelector(
  getEndpointState,
  EndpointReducer.getEndpoint
);

export const getEndpointCategories = createSelector(
  getEndpointState,
  EndpointReducer.getCategories
);

export const getEndpointCategoryRemaps = createSelector(
  getEndpointState,
  EndpointReducer.getCategoryRemaps
);

export const getEndpointSubcategoryRemaps = createSelector(
  getEndpointState,
  EndpointReducer.getSubcategoryRemaps
);

export const getIsEndpointLoading = createSelector(
  getEndpointState,
  EndpointReducer.isLoading
);

export const getIsEndpointLoaded = createSelector(
  getEndpointState,
  EndpointReducer.isLoaded
);

export const getIsCategoriesLoading = createSelector(
  getEndpointState,
  EndpointReducer.isCategoriesLoading
);

export const getIsCategoriesLoaded = createSelector(
  getEndpointState,
  EndpointReducer.isCategoriesLoaded
);

export const getIsEndpointRemapsLoading = createSelector(
  getEndpointState,
  EndpointReducer.isEndpointRemapsLoading
);

export const getIsEndpointRemapsLoaded = createSelector(
  getEndpointState,
  EndpointReducer.isEndpointRemapsLoaded
);

export const getEndpointProductOfferingTags = createSelector(
  getEndpointState,
  EndpointReducer.getTags
);

export const getEndpointProductOfferingTagsLoading = createSelector(
  getEndpointState,
  EndpointReducer.isTagsLoading
);

export const getEndpointProductOfferingTagsLoaded = createSelector(
  getEndpointState,
  EndpointReducer.isTagsLoaded
);

export const getEndpointErrorMessage = createSelector(
  getEndpointState,
  EndpointReducer.getErrorMessage
);

export const getEndpointSuccessMessage = createSelector(
  getEndpointState,
  EndpointReducer.getSuccessMessage
);

export const getEndpointStatus = createSelector(
  getEndpointState,
  EndpointReducer.getStatus
);

// Enpoints State Selectors
export const getEndpointsState = createFeatureSelector<EndpointsReducer.State>('endpoints');

export const getAllEndpoints = createSelector(
  getEndpointsState,
  EndpointsReducer.getEndpoints
);

export const getIsEndpointsLoading = createSelector(
  getEndpointsState,
  EndpointsReducer.isLoading
);

export const getIsEndpointsLoaded = createSelector(
  getEndpointsState,
  EndpointsReducer.isLoaded
);

export const getEndpointsErrorMessage = createSelector(
  getEndpointsState,
  EndpointsReducer.getErrorMessage
);

export const getEndpointsSuccessMessage = createSelector(
  getEndpointsState,
  EndpointsReducer.getSuccessMessage
);

export const getEndpointsStatus = createSelector(
  getEndpointsState,
  EndpointsReducer.getStatus
);

// Endpoint Product Offerings State Selectors
export const getEndpointProductOfferingsState = createFeatureSelector<EndpointProductOfferingsReducer.State>('endpointProductOfferings');

export const getEndpointProductOfferings = createSelector(
  getEndpointProductOfferingsState,
  EndpointProductOfferingsReducer.getEndpointProductOfferings
);

export const getIsEndpointProductOfferingsLoading = createSelector(
  getEndpointProductOfferingsState,
  EndpointProductOfferingsReducer.isLoading
);

export const getIsEndpointProductOfferingsLoaded = createSelector(
  getEndpointProductOfferingsState,
  EndpointProductOfferingsReducer.isLoaded
);

export const getEndpointProductOfferingsErrorMessage = createSelector(
  getEndpointProductOfferingsState,
  EndpointProductOfferingsReducer.getErrorMessage
);

export const getEndpointProductOfferingsSuccessMessage = createSelector(
  getEndpointProductOfferingsState,
  EndpointProductOfferingsReducer.getSuccessMessage
);

export const getEndpointProductOfferingsStatus = createSelector(
  getEndpointProductOfferingsState,
  EndpointProductOfferingsReducer.getStatus
);

// Integrations State Selectors
export const getIntegrationsState = createFeatureSelector<IntegrationsReducer.State>('integrations');

export const getAllIntegrations = createSelector(
  getIntegrationsState,
  IntegrationsReducer.getIntegrations
);

export const getIsIntegrationsLoading = createSelector(
  getIntegrationsState,
  IntegrationsReducer.isLoading
);

export const getIsIntegrationsLoaded = createSelector(
  getIntegrationsState,
  IntegrationsReducer.isLoaded
);

// Integration State Selectors
export const getIntegrationState = createFeatureSelector<IntegrationReducer.State>('integration');

export const getIntegration = createSelector(
  getIntegrationState,
  IntegrationReducer.getIntegration
);

export const getIsIntegrationLoading = createSelector(
  getIntegrationState,
  IntegrationReducer.isLoading
);

export const getIsIntegrationLoaded = createSelector(
  getIntegrationState,
  IntegrationReducer.isLoaded
);

export const getIntegrationErrorMessage = createSelector(
  getIntegrationState,
  IntegrationReducer.getErrorMessage
);

export const getIntegrationSuccessMessage = createSelector(
  getIntegrationState,
  IntegrationReducer.getSuccessMessage
);

export const getIntegrationStatus = createSelector(
  getIntegrationState,
  IntegrationReducer.getStatus
);

// state for Translations
export const getTranslationsState = createFeatureSelector<TranslationsReducer.State>('translations');
export const getTranslationsIsLoading = createSelector(
  getTranslationsState,
  TranslationsReducer.getTranslationsIsLoading
);

export const getTranslationsIsLoaded = createSelector(
  getTranslationsState,
  TranslationsReducer.getTranslationsIsLoaded
);

export const getTranslations = createSelector(
  getTranslationsState,
  TranslationsReducer.getTranslations
);

// Settings State Selectors
export const getSettingsState = createFeatureSelector<SettingsReducer.State>('settings');

export const getAllSettings = createSelector(
  getSettingsState,
  SettingsReducer.getSettings
);

export const getIsSettingsLoading = createSelector(
  getSettingsState,
  SettingsReducer.isLoading
);

export const getIsSettingsLoaded = createSelector(
  getSettingsState,
  SettingsReducer.isLoaded
);

// Technologies State Selectors
export const getTechnologiesState = createFeatureSelector<TechnologiesReducer.State>('technologies');

export const getAllTechnologies = createSelector(
  getTechnologiesState,
  TechnologiesReducer.getTechnologies
);

export const getIsTechnologiesLoading = createSelector(
  getTechnologiesState,
  TechnologiesReducer.isLoading
);

export const getIsTechnologiesLoaded = createSelector(
  getTechnologiesState,
  TechnologiesReducer.isLoaded
);

// state for Roles
export const getRolesState = createFeatureSelector<RolesReducer.State>('roles');
export const getRolesIsLoading = createSelector(
  getRolesState,
  RolesReducer.isLoading
);

export const getRolesIsLoaded = createSelector(
  getRolesState,
  RolesReducer.isLoaded
);

export const getRoles = createSelector(
  getRolesState,
  RolesReducer.getRoles
);

// User State Selectors
export const getUserState = createFeatureSelector<UserReducer.State>('user');

export const getUsers = createSelector(
  getUserState,
  UserReducer.getUsers
);

export const getUser = createSelector(
  getUserState,
  UserReducer.getUser
);

export const getIsUsersLoading = createSelector(
  getUserState,
  UserReducer.isLoading
);

export const getIsUsersLoaded = createSelector(
  getUserState,
  UserReducer.isLoaded
);

export const getUserErrorMessage = createSelector(
  getUserState,
  UserReducer.getErrorMessage
);

export const getUserSuccessMessage = createSelector(
  getUserState,
  UserReducer.getSuccessMessage
);

export const getUserStatus = createSelector(
  getUserState,
  UserReducer.getStatus
);

// state for Configurations
export const getConfigurationsState = createFeatureSelector<ConfigurationsReducer.State>('configurations');
export const getLoadedConfiguration = createSelector(
  getConfigurationsState,
  ConfigurationsReducer.getLoadedConfiguration
);

export const getConfigurationsIsLoading = createSelector(
  getConfigurationsState,
  ConfigurationsReducer.isLoading
);

export const getConfigurationsIsLoaded = createSelector(
  getConfigurationsState,
  ConfigurationsReducer.isLoaded
);

export const getConfigurationErrorMessage = createSelector(
  getConfigurationsState,
  ConfigurationsReducer.getErrorMessage
);

export const getConfigurationSuccessMessage = createSelector(
  getConfigurationsState,
  ConfigurationsReducer.getSuccessMessage
);

export const getConfigurationStatus = createSelector(
  getConfigurationsState,
  ConfigurationsReducer.getStatus
);

export const getConfigurations = createSelector(
  getConfigurationsState,
  ConfigurationsReducer.getConfigurations
);

export const getConfigurationAssociationsIsLoading = createSelector(
  getConfigurationsState,
  ConfigurationsReducer.isLoadingAssociations
);

export const getLoadedConfigurationAssociations = createSelector(
  getConfigurationsState,
  ConfigurationsReducer.getLoadedAssociations
);

// Designer State Selectors
export const getDesignerState = createFeatureSelector<DesignerReducer.State>('designer');

export const getLoadedDesigner = createSelector(
  getDesignerState,
  DesignerReducer.getDesigner
);

export const getIsDesignerLoading = createSelector(
  getDesignerState,
  DesignerReducer.isLoading
);

export const getIsDesignerLoaded = createSelector(
  getDesignerState,
  DesignerReducer.isLoaded
);

export const getDesignerErrorMessage = createSelector(
  getDesignerState,
  DesignerReducer.getErrorMessage
);

export const getDesignerSuccessMessage = createSelector(
  getDesignerState,
  DesignerReducer.getSuccessMessage
);

export const getDesignerStatus = createSelector(
  getDesignerState,
  DesignerReducer.getStatus
);

// Contact State Selectors
export const getContactState = createFeatureSelector<ContactReducer.State>('contact');

export const getLoadedContact = createSelector(
  getContactState,
  ContactReducer.getContact
);

export const getIsContactLoading = createSelector(
  getContactState,
  ContactReducer.isLoading
);

export const getIsContactLoaded = createSelector(
  getContactState,
  ContactReducer.isLoaded
);

export const getContactErrorMessage = createSelector(
  getContactState,
  ContactReducer.getErrorMessage
);

export const getContactSuccessMessage = createSelector(
  getContactState,
  ContactReducer.getSuccessMessage
);

export const getContactStatus = createSelector(
  getContactState,
  ContactReducer.getStatus
);

// Category State Selectors
export const getCategoryState = createFeatureSelector<CategoryReducer.State>('category');

export const getLoadedCategory = createSelector(
  getCategoryState,
  CategoryReducer.getCategory
);

export const getIsCategoryLoading = createSelector(
  getCategoryState,
  CategoryReducer.isLoading
);

export const getIsCategoryLoaded = createSelector(
  getCategoryState,
  CategoryReducer.isLoaded
);

export const getCategoryErrorMessage = createSelector(
  getCategoryState,
  CategoryReducer.getErrorMessage
);

export const getCategorySuccessMessage = createSelector(
  getCategoryState,
  CategoryReducer.getSuccessMessage
);

export const getCategoryStatus = createSelector(
  getCategoryState,
  CategoryReducer.getStatus
);

// Global category State Selectors
export const getGlobalCategoryState = createFeatureSelector<GlobalCategoryReducer.State>('globalCategory');

export const getLoadedGlobalCategory = createSelector(
  getGlobalCategoryState,
  GlobalCategoryReducer.getGlobalCategory
);

export const getIsGlobalCategoryLoading = createSelector(
  getGlobalCategoryState,
  GlobalCategoryReducer.isLoading
);

export const getIsGlobalCategoryLoaded = createSelector(
  getGlobalCategoryState,
  GlobalCategoryReducer.isLoaded
);

export const getGlobalCategoryErrorMessage = createSelector(
  getGlobalCategoryState,
  GlobalCategoryReducer.getErrorMessage
);

export const getGlobalCategorySuccessMessage = createSelector(
  getGlobalCategoryState,
  GlobalCategoryReducer.getSuccessMessage
);

export const getGlobalCategoryStatus = createSelector(
  getGlobalCategoryState,
  GlobalCategoryReducer.getStatus
);

// Portfolio State Selectors
export const getPortfolioState = createFeatureSelector<PortfolioReducer.State>('portfolio');

export const getLoadedPortfolio = createSelector(
  getPortfolioState,
  PortfolioReducer.getPortfolio
);

export const getIsPortfolioLoaded = createSelector(
  getPortfolioState,
  PortfolioReducer.isLoaded
);

export const getIsPortfolioLoading = createSelector(
  getPortfolioState,
  PortfolioReducer.isLoading
);

export const getPortfolioSuccessMessage = createSelector(
  getPortfolioState,
  PortfolioReducer.getSuccessMessage
);

export const getPortfolioErrorMessage = createSelector(
  getPortfolioState,
  PortfolioReducer.getErrorMessage
);

export const getPortfolioStatus = createSelector(
  getPortfolioState,
  PortfolioReducer.getStatus
);


// Family State Selectors
export const getFamilyState = createFeatureSelector<FamilyReducer.State>('family');

export const getLoadedFamily = createSelector(
  getFamilyState,
  FamilyReducer.getFamily
);

export const getIsFamilyLoading = createSelector(
  getFamilyState,
  FamilyReducer.isLoading
);

export const getIsFamilyLoaded = createSelector(
  getFamilyState,
  FamilyReducer.isLoaded
);

export const getFamilyErrorMessage = createSelector(
  getFamilyState,
  FamilyReducer.getErrorMessage
);

export const getFamilySuccessMessage = createSelector(
  getFamilyState,
  FamilyReducer.getSuccessMessage
);

export const getFamilyStatus = createSelector(
  getFamilyState,
  FamilyReducer.getStatus
);



// Subcategory State Selectors
export const getSubcategoryState = createFeatureSelector<SubcategoryReducer.State>('subcategory');

export const getLoadedSubcategory = createSelector(
  getSubcategoryState,
  SubcategoryReducer.getSubcategory
);

export const getIsSubcategoryLoading = createSelector(
  getSubcategoryState,
  SubcategoryReducer.isLoading
);

export const getIsSubcategoryLoaded = createSelector(
  getSubcategoryState,
  SubcategoryReducer.isLoaded
);

export const getSubcategoryErrorMessage = createSelector(
  getSubcategoryState,
  SubcategoryReducer.getErrorMessage
);

export const getSubcategorySuccessMessage = createSelector(
  getSubcategoryState,
  SubcategoryReducer.getSuccessMessage
);

export const getSubcategoryStatus = createSelector(
  getSubcategoryState,
  SubcategoryReducer.getStatus
);

// Global Subcategory State Selectors
export const getGlobalSubcategoryState = createFeatureSelector<GlobalSubcategoryReducer.State>('globalSubcategory');

export const getLoadedGlobalSubcategory = createSelector(
  getGlobalSubcategoryState,
  GlobalSubcategoryReducer.getGlobalSubcategory
);

export const getIsGlobalSubcategoryLoading = createSelector(
  getGlobalSubcategoryState,
  GlobalSubcategoryReducer.isLoading
);

export const getIsGlobalSubcategoryLoaded = createSelector(
  getGlobalSubcategoryState,
  GlobalSubcategoryReducer.isLoaded
);

export const getGlobalSubcategoryErrorMessage = createSelector(
  getGlobalSubcategoryState,
  GlobalSubcategoryReducer.getErrorMessage
);

export const getGlobalSubcategorySuccessMessage = createSelector(
  getGlobalSubcategoryState,
  GlobalSubcategoryReducer.getSuccessMessage
);

export const getGlobalSubcategoryStatus = createSelector(
  getGlobalSubcategoryState,
  GlobalSubcategoryReducer.getStatus
);

// SubBrand State Selectors
export const getSubBrandState = createFeatureSelector<SubBrandReducer.State>('subBrand');

export const getLoadedSubBrand = createSelector(
  getSubBrandState,
  SubBrandReducer.getSubBrand
);

export const getIsSubBrandLoading = createSelector(
  getSubBrandState,
  SubBrandReducer.isLoading
);

export const getIsSubBrandLoaded = createSelector(
  getSubBrandState,
  SubBrandReducer.isLoaded
);

export const getSubBrandErrorMessage = createSelector(
  getSubBrandState,
  SubBrandReducer.getErrorMessage
);

export const getSubBrandSuccessMessage = createSelector(
  getSubBrandState,
  SubBrandReducer.getSuccessMessage
);

export const getSubBrandStatus = createSelector(
  getSubBrandState,
  SubBrandReducer.getStatus
);

// Material State Selectors
export const getMaterialState = createFeatureSelector<MaterialReducer.State>('material');

export const getLoadedMaterials = createSelector(
  getMaterialState,
  MaterialReducer.getMaterials
);

export const getIsMaterialLoading = createSelector(
  getMaterialState,
  MaterialReducer.isLoading
);

export const getIsMaterialLoaded = createSelector(
  getMaterialState,
  MaterialReducer.isLoaded
);

export const getMaterialErrorMessage = createSelector(
  getMaterialState,
  MaterialReducer.getErrorMessage
);
// Warranty State Selectors

export const getWarrantyState = createFeatureSelector<WarrantyReducer.State>('warranty');

export const getLoadedWarranty = createSelector(
  getWarrantyState,
  WarrantyReducer.getWarranty
);

export const getIsWarrantyLoading = createSelector(
  getWarrantyState,
  WarrantyReducer.isLoading
);

export const getIsWarrantyLoaded = createSelector(
  getWarrantyState,
  WarrantyReducer.isLoaded
);

export const getWarrantyErrorMessage = createSelector(
  getWarrantyState,
  WarrantyReducer.getErrorMessage
);

export const getWarrantySuccessMessage = createSelector(
  getWarrantyState,
  WarrantyReducer.getSuccessMessage
);

export const getWarrantyStatus = createSelector(
  getWarrantyState,
  WarrantyReducer.getStatus
);

export const getDamAssetMetapropertyState = createFeatureSelector<DamAssetsMetapropertyReducer.State>('damAssetMetaproperty');

export const getLoadedDamAssetMetaproperty = createSelector(
  getDamAssetMetapropertyState,
  DamAssetsMetapropertyReducer.getLoadedAssetMetaproperty
);

export const getLoadedDamAssetPurposeMetaproperty = createSelector(
  getDamAssetMetapropertyState,
  DamAssetsMetapropertyReducer.getLoadedAssetPurposeMetaProperty
);

export const getLoadedDamMediaSubtypeMetaproperty = createSelector(
  getDamAssetMetapropertyState,
  DamAssetsMetapropertyReducer.getLoadedMediaSubtypeMetaProperty
);

export const getLoadedDamAssetMetapropertyOptions = createSelector(
  getDamAssetMetapropertyState,
  DamAssetsMetapropertyReducer.getLoadedAssetMetapropertyOptions
);

export const getLoadedDamAssetPurposeMetapropertyOptions = createSelector(
  getDamAssetMetapropertyState,
  DamAssetsMetapropertyReducer.getLoadedAssetPurposeMetaPropertyOptions
);

export const getLoadedDamAssetPurposeMetapropertyOmniOptions = createSelector(
  getDamAssetMetapropertyState,
  DamAssetsMetapropertyReducer.getLoadedAssetPurposeMetaPropertyOmniOptions
);

export const getLoadedDamMediaSubtypeMetapropertyOptions = createSelector(
  getDamAssetMetapropertyState,
  DamAssetsMetapropertyReducer.getLoadedMediaSubtypeMetaPropertyOptions
);

export const getMediaSubTypes = createSelector(
  getDamAssetMetapropertyState,
  DamAssetsMetapropertyReducer.getLoadedMediaSubTypes
);

export const getMediaSubTypesLoaded = createSelector(
  getDamAssetMetapropertyState,
  DamAssetsMetapropertyReducer.getMediaSubTypesIsLoaded
);

export const getMediaSubTypesLoading = createSelector(
  getDamAssetMetapropertyState,
  DamAssetsMetapropertyReducer.getMediaSubTypesIsLoading
);

// ApplicationTag State Selectors
export const getApplicationTagState = createFeatureSelector<ApplicationTagReducer.State>('applicationtag');

export const getLoadedApplicationTag = createSelector(
  getApplicationTagState,
  ApplicationTagReducer.getApplicationtag
);

export const getIsApplicationTagLoading = createSelector(
  getApplicationTagState,
  ApplicationTagReducer.isLoading
);

export const getIsApplicationTagLoaded = createSelector(
  getApplicationTagState,
  ApplicationTagReducer.isLoaded
);

export const getApplicationTagErrorMessage = createSelector(
  getApplicationTagState,
  ApplicationTagReducer.getErrorMessage
);

export const getApplicationTagSuccessMessage = createSelector(
  getApplicationTagState,
  ApplicationTagReducer.getSuccessMessage
);

export const getApplicationTagStatus = createSelector(
  getApplicationTagState,
  ApplicationTagReducer.getStatus
);


// AttributeType State Selectors
export const getAttributeTypeState = createFeatureSelector<AttributeTypeReducer.State>('attributeType');

export const getAttributeType = createSelector(
  getAttributeTypeState,
  AttributeTypeReducer.getAttributeType
);

export const getAttributeTypeErrorMessage = createSelector(
  getAttributeTypeState,
  AttributeTypeReducer.getErrorMessage
);

export const getAttributeTypeSuccessMessage = createSelector(
  getAttributeTypeState,
  AttributeTypeReducer.getSuccessMessage
);

export const getAttributeTypeStatus = createSelector(
  getAttributeTypeState,
  AttributeTypeReducer.getStatus
);

// ProductGuidanceAttributes State Selectors
export const getProductGuidanceAttributesState = createFeatureSelector<ProductGuidanceAttributesReducer.State>('productGuidanceAttributes');

export const getProductGuidanceAttributes = createSelector(
  getProductGuidanceAttributesState,
  ProductGuidanceAttributesReducer.getProductGuidanceAttributes
);

export const getIsProductGuidanceAttributesLoading = createSelector(
  getProductGuidanceAttributesState,
  ProductGuidanceAttributesReducer.getProductGuidanceAttributesIsLoading
);

export const getIsProductGuidanceAttributesLoaded = createSelector(
  getProductGuidanceAttributesState,
  ProductGuidanceAttributesReducer.getProductGuidanceAttributesIsLoaded
);

export const getProductGuidanceAttributesErrorMessage = createSelector(
  getProductGuidanceAttributesState,
  ProductGuidanceAttributesReducer.getErrorMessage
);
