import { EndpointProductOfferingTag } from './../../../core/models/endpoint-product-offering-tag.model';
import { IRemaps } from 'src/app/core/models/remaps.model';
import { Endpoint } from './../../../core/models/endpoint.model';
import { Action } from '@ngrx/store';
import { ICategory } from 'src/app/core/models/category.model';

export enum EndpointApiActionTypes {
  LoadSuccess = '[Endpoint/API] Load Success',
  LoadFailure = '[Endpoint/API] Load Failure',
  LoadEndpointRemapsSuccess = '[Endpoint/API] Load Endpoint Remaps Success',
  LoadEndpointRemapsFailure = '[Endpoint/API] Load Endpoint Remaps Failure',
  LoadCategoriesSuccess = '[Endpoint/API] Load Categories Success',
  LoadCategoriesFailure = '[Endpoint/API] Load Categories Failure',
  UpdateRemapsSuccess = '[Endpoint/API] Update Remaps Success',
  UpdateRemapsFailure = '[Endpoint/API] Update Remaps Failure',

  LoadOfferingTagsSuccess = '[Endpoint/API] Load Offering Tags Success',
  LoadOfferingTagsFailure = '[Endpoint/API] Load Offering Tags Failure',
  CreateOfferingTagSuccess = '[Endpoint/API] Create Endpoint Offering Tag Success',
  CreateOfferingTagFailure = '[Endpoint/API] Create Endpoint Offering Tag Failure',
  UpdateOfferingTagSuccess = '[Endpoint/API] Update Endpoint Offering Tag Success',
  UpdateOfferingTagFailure = '[Endpoint/API] Update Endpoint Offering Tag Failure',
  DeleteOfferingTagSuccess = '[Endpoint/API] Delete Endpoint Offering Tag Success',
  DeleteOfferingTagFailure = '[Endpoint/API] Delete Endpoint Offering Tag Failure',
}

export class LoadSuccess implements Action {
  readonly type = EndpointApiActionTypes.LoadSuccess;

  constructor(public payload: Endpoint) { }
}

export class LoadFailure implements Action {
  readonly type = EndpointApiActionTypes.LoadFailure;

  constructor(public payload: any) { }
}

export class LoadEndpointRemapsSuccess implements Action {
  readonly type = EndpointApiActionTypes.LoadEndpointRemapsSuccess;

  constructor(public payload: IRemaps) { }
}

export class LoadEndpointRemapsFailure implements Action {
  readonly type = EndpointApiActionTypes.LoadEndpointRemapsFailure;

  constructor(public payload: any) { }
}

export class LoadCategoriesSuccess implements Action {
  readonly type = EndpointApiActionTypes.LoadCategoriesSuccess;

  constructor(public payload: ICategory[]) { }
}

export class LoadCategoriesFailure implements Action {
  readonly type = EndpointApiActionTypes.LoadCategoriesFailure;

  constructor(public payload: any) { }
}

export class UpdateRemapsSuccess implements Action {
  readonly type = EndpointApiActionTypes.UpdateRemapsSuccess;
}

export class UpdateRemapsFailure implements Action {
  readonly type = EndpointApiActionTypes.UpdateRemapsFailure;

  constructor(public payload: any) { }
}

export class LoadOfferingTagsSuccess implements Action {
  readonly type = EndpointApiActionTypes.LoadOfferingTagsSuccess;

  constructor(public payload: Array<EndpointProductOfferingTag>) { }
}

export class LoadOfferingTagsFailure implements Action {
  readonly type = EndpointApiActionTypes.LoadOfferingTagsFailure;

  constructor(public payload: any) { }
}

export class CreateOfferingTagSuccess implements Action {
  readonly type = EndpointApiActionTypes.CreateOfferingTagSuccess;

  constructor(public payload: EndpointProductOfferingTag) { }
}

export class CreateOfferingTagFailure implements Action {
  readonly type = EndpointApiActionTypes.CreateOfferingTagFailure;

  constructor(public payload: any) { }
}

export class UpdateOfferingTagSuccess implements Action {
  readonly type = EndpointApiActionTypes.UpdateOfferingTagSuccess;

  constructor() { }
}

export class UpdateOfferingTagFailure implements Action {
  readonly type = EndpointApiActionTypes.UpdateOfferingTagFailure;

  constructor(public payload: any) { }
}

export class DeleteOfferingTagSuccess implements Action {
  readonly type = EndpointApiActionTypes.DeleteOfferingTagSuccess;

  constructor() { }
}

export class DeleteOfferingTagFailure implements Action {
  readonly type = EndpointApiActionTypes.DeleteOfferingTagFailure;

  constructor(public payload: any) { }
}

export type EndpointApiActionsUnion =
    LoadSuccess
  | LoadFailure
  | LoadEndpointRemapsSuccess
  | LoadEndpointRemapsFailure
  | LoadCategoriesSuccess
  | LoadCategoriesFailure
  | UpdateRemapsSuccess
  | UpdateRemapsFailure
  | LoadOfferingTagsSuccess
  | LoadOfferingTagsFailure
  | CreateOfferingTagSuccess
  | CreateOfferingTagFailure
  | UpdateOfferingTagSuccess
  | UpdateOfferingTagFailure
  | DeleteOfferingTagSuccess
  | DeleteOfferingTagFailure;
