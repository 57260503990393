import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IMaterialsResponse, MaterialsRequest, MaterialsResponse } from '../models/material.model';

@Injectable({
  providedIn: 'root'
})
export class MaterialsService {
  basePath = 'materials';

  constructor(private http: HttpClient) { }

  getMaterialsByProductNumbers$(request: MaterialsRequest): Observable<IMaterialsResponse>{
    return this.http.post(`${environment.apiUrl}${this.basePath}/productnumbersearch`, request)
    .pipe(
      map(res => res),
      catchError((err: Response) => {
        return throwError(err);
      }),
      map((x: IMaterialsResponse) => new MaterialsResponse(x))
    );
  }

}
