import { assign as _assign } from 'lodash-es';
import { IPageRequest, PageRequest } from './page-request.model';

export interface IConfigurationsPageRequest extends IPageRequest {
  searchTerm: string;
  globalProductId: number;
  integrationIds: Array<number>;
  localeIds: Array<number>;
  excludedGlobalConfigurationIds: Array<number>;
}

export class ConfigurationsPageRequest extends PageRequest {
  searchTerm: string;
  globalProductId: number;
  integrationIds: Array<number> = [];
  localeIds: Array<number> = [];
  excludedGlobalConfigurationIds: Array<number> = [];

  constructor(data?: IConfigurationsPageRequest) {
    super(data);

    if (!data) {
      return;
    }
    _assign(this, data);
  }
}
