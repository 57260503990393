import { Template } from '../../../core/models/template';
import { IntegrationTemplateSummary } from '../../../core/models/integration-template-summary.model';
import { Action } from '@ngrx/store';

export enum TemplatesApiActionTypes {
  LoadSuccess = '[Templates/API] Load Success',
  LoadFailure = '[Templates/API] Load Failure',
  CreateSuccess = '[Templates/API] Create Success',
  CreateFailure = '[Templates/API] Create Failure',
  RenameSuccess = '[Templates/API] Rename Success',
  RenameFailure = '[Templates/API] Rename Failure',
  DuplicateSuccess = '[Templates/API] Duplicate Success',
  DuplicateFailure = '[Templates/API] Duplicate Failure'
}

export class LoadSuccess implements Action {
  readonly type = TemplatesApiActionTypes.LoadSuccess;

  constructor(public payload: IntegrationTemplateSummary[]) { }
}

export class LoadFailure implements Action {
  readonly type = TemplatesApiActionTypes.LoadFailure;

  constructor(public payload: any) { }
}

export class CreateSuccess implements Action {
  readonly type = TemplatesApiActionTypes.CreateSuccess;

  constructor(public payload: Template) { }
}

export class CreateFailure implements Action {
  readonly type = TemplatesApiActionTypes.CreateFailure;

  constructor(public payload: any) { }
}

export class RenameSuccess implements Action {
  readonly type = TemplatesApiActionTypes.RenameSuccess;

  constructor(public payload: {integrationId: number, templateId: number, templateName: string}) { }
}

export class RenameFailure implements Action {
  readonly type = TemplatesApiActionTypes.RenameFailure;

  constructor(public payload: any) { }
}

export class DuplicateSuccess implements Action {
  readonly type = TemplatesApiActionTypes.DuplicateSuccess;

  constructor(public payload: Template) { }
}

export class DuplicateFailure implements Action {
  readonly type = TemplatesApiActionTypes.DuplicateFailure;

  constructor(public payload: any) { }
}

export type TemplatesApiActionsUnion =
    LoadSuccess
  | LoadFailure
  | CreateSuccess
  | CreateFailure
  | RenameSuccess
  | RenameFailure
  | DuplicateSuccess
  | DuplicateFailure
  ;
