import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import { assign as _assign } from 'lodash-es';

export interface IApplicationTag extends IBaseAuditModel {
  tag: string;
}

export class ApplicationTag extends BaseAuditModel implements IApplicationTag {
  tag: string;

  constructor(data: IApplicationTag) {
    super(data);

    if (!data) {
      return;
    }

    _assign(this, data);
  }
}
