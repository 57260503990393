import { ApplicationTag } from 'src/app/core/models/application-tag.model';
import { ApplicationTagStatus } from 'src/app/shared/constants';

import {
  ApplicationTagApiActions,
  ApplicationTagActions
} from '../actions';


export interface State {
  applicationTag: ApplicationTag;
  loading: boolean;
  loaded: boolean;
  status: ApplicationTagStatus;
  error: string;
  success: string;
}

export const initialState: State = {
  applicationTag: null,
  loading: false,
  loaded: false,
  status: ApplicationTagStatus.None,
  error: null,
  success: null
};

export function reducer(state = initialState, action: ApplicationTagActions.ApplicationTagActionsUnion | ApplicationTagApiActions.ApplicationTagApiActionsUnion): State {
  switch (action.type) {

    case ApplicationTagActions.ApplicationTagActionTypes.LoadApplicationTag: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case ApplicationTagApiActions.ApplicationTagApiActionTypes.LoadSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        applicationTag: action.payload
      };
    }

    case ApplicationTagApiActions.ApplicationTagApiActionTypes.LoadFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        applicationTag: null,
        error: action.payload
      };
    }

    case ApplicationTagActions.ApplicationTagActionTypes.ClearMessages: {
      return {
        ...state,
        status: ApplicationTagStatus.None,
        error: null,
        success: null
      };
    }

    case ApplicationTagActions.ApplicationTagActionTypes.CreateApplicationTag: {
      return {
        ...state,
      };
    }

    case ApplicationTagApiActions.ApplicationTagApiActionTypes.CreateSuccess: {
      return {
        ...state,
        applicationTag: action.payload,
        status: ApplicationTagStatus.CreateSuccess,
        error: null,
        success: `Successfully Created ApplicationTag: ${action.payload.tag}`
      };
    }

    case ApplicationTagApiActions.ApplicationTagApiActionTypes.CreateFailure: {
      return {
        ...state,
        applicationTag: null,
        status: ApplicationTagStatus.CreateFailure,
        error: 'Failed to Create ApplicationTag',
        success: null
      };
    }

    case ApplicationTagActions.ApplicationTagActionTypes.UpdateApplicationTag: {
      return {
        ...state,
        loading: true,
        loaded: false,
        status: ApplicationTagStatus.None
      };
    }

    case ApplicationTagApiActions.ApplicationTagApiActionTypes.UpdateSuccess: {
      return {
        ...state,
        status: ApplicationTagStatus.UpdateSuccess,
        error: null,
        success: `Successfully Updated ApplicationTag: ${action.payload.applicationTagName}`
      };
    }

    case ApplicationTagApiActions.ApplicationTagApiActionTypes.UpdateFailure: {
      return {
        ...state,
        loading: false,
        loaded: true,
        status: ApplicationTagStatus.UpdateFailure,
        error: 'Failed to Update ApplicationTag',
        success: null
      };
    }

    case ApplicationTagActions.ApplicationTagActionTypes.RenameApplicationTag: {
      return {
        ...state
      };
    }

    case ApplicationTagApiActions.ApplicationTagApiActionTypes.RenameSuccess: {
      const applicationTag = { ...state.applicationTag };
      applicationTag.tag = action.payload.applicationTagName;

      return {
        ...state,
        status: ApplicationTagStatus.RenameSuccess,
        error: null,
        success: `Successfully renamed ApplicationTag: ${action.payload.applicationTagName}`
      };
    }

    case ApplicationTagApiActions.ApplicationTagApiActionTypes.RenameFailure: {
      return {
        ...state,
        applicationTag: null,
        status: ApplicationTagStatus.RenameFailure,
        error: 'Failed to rename ApplicationTag',
        success: null
      };
    }

    case ApplicationTagActions.ApplicationTagActionTypes.DeleteApplicationTag: {
      return {
        ...state,
      };
    }

    case ApplicationTagApiActions.ApplicationTagApiActionTypes.DeleteSuccess: {
      return {
        ...state,
        status: ApplicationTagStatus.DeleteSuccess,
        error: null,
        success: `Successfully Deleted ApplicationTag: ${action.payload.applicationTagName}`
      };
    }

    case ApplicationTagApiActions.ApplicationTagApiActionTypes.DeleteFailure: {
      return {
        ...state,
        applicationTag: null,
        status: ApplicationTagStatus.DeleteFailure,
        error: 'Failed to Delete ApplicationTag',
        success: null
      };
    }

    default:
      return state;
   }
}

export const getApplicationtag = (state: State) => state.applicationTag;
export const getErrorMessage = (state: State) => state.error;
export const getSuccessMessage = (state: State) => state.success;
export const getStatus = (state: State) => state.status;
export const isLoading = (state: State) => state.loading;
export const isLoaded = (state: State) => state.loaded;
