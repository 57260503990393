<div>
  <div mat-dialog-title>
    <strong class="h6"><strong>{{ modalTitle }}</strong></strong>
  </div>

  <div mat-dialog-content>
    <div *ngFor="let region of data.regions" [@.disabled]="disableAnimation">
      <mat-accordion>
        <mat-expansion-panel
          class="dropdown-checkbox">
          <mat-expansion-panel-header>
            <mat-checkbox
              [checked]="getHeaderChecked(region)"
              [indeterminate]="getHeaderIndeterminateDash(region)"
              (click)="$event.stopPropagation()"
              (change)="onRegionChange($event, region)">
              <strong>{{region.name}}</strong>
            </mat-checkbox>
            <span
              *ngIf="getHeaderSelectedLocalesCount(region) > 0"
              class="align-center-vertical">
              <small class="selected-count">
                ({{ getHeaderSelectedLocalesCount(region) }} selected)
              </small>
            </span>
          </mat-expansion-panel-header>
          <div
            class="dropdown-checkbox-content checkbox-item"
            *ngFor="let locale of region.locales">
            <mat-checkbox
              [checked]="getChecked(locale)"
              (change)="onLocaleChange($event, locale)">
              {{locale.name}}
            </mat-checkbox>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <div mat-dialog-actions>
    <button
      class="mat-raised-button button-spacing"
      mat-dialog-close>Cancel
    </button>
    <button
      class="mat-raised-button primary-button"
      (click)="applySelections()">
        {{ buttonText }} ({{selectedLocales.length}})
      </button>
  </div>
</div>
