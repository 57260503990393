<div fxFlex fxLayout="column" class="align-center-vertical">
  <div fxFlex class="card-title">
    <ng-container *ngIf="globalProductOfferingConfiguration?.displayName; else configurationNameElse">
      <strong>{{globalProductOfferingConfiguration.displayName}}</strong>
      <mat-icon class="remapping-icon subdue-icon" matTooltip="Re-mapped from: {{globalConfiguration?.name}}"
        matTooltipPosition="below">
        sync_alt
      </mat-icon>
    </ng-container>
    <ng-template #configurationNameElse>
      <strong>{{getBestName()}}</strong>
    </ng-template>
    <mat-icon *ngIf="getIsConfigurationContainsInvalidProductNumber(globalConfiguration)" color="warn"
      class="invalid-pricing-icon" matTooltip="This configuration has a SKU(s) that has been disabled after mutliple pricing attempts. Correct SKU or attempt manual retry in the Details > Edit page"
      matTooltipPosition="below">
      info
    </mat-icon>
  </div>
  <div fxFlex class="card-data-name">
    <span *ngIf="showListPrice">{{ getListPrice(globalConfiguration) | currency}}</span>
    <ng-container *ngIf="globalConfiguration.productNumbers.length > 0; else productSkuElse">
      <ng-container *ngFor="let productSku of globalConfiguration.productNumbers">
        <strong>·</strong> {{productSku.productNumber?.fullProductNumber}}
      </ng-container>
    </ng-container>
    <ng-template #productSkuElse>
      No SKU assigned
    </ng-template>
  </div>
  <div class="integrations-container"
    *ngIf="globalConfiguration.globalConfigurationIntegrations && globalConfiguration.globalConfigurationIntegrations.length > 0">
    <div class="integration-pill"
      *ngFor="let globalConfigurationIntegration of globalConfiguration.globalConfigurationIntegrations">
      <app-integration-pill [name]="globalConfigurationIntegration.integration.name"
        [iconUrl]="globalConfigurationIntegration.integration.iconLink">
      </app-integration-pill>
    </div>
  </div>
</div>
