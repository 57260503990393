import { ApplicationSetting } from './application-setting.model';
import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import { assign as _assign } from 'lodash-es';

export interface IApplicationPost extends IBaseAuditModel {
    name: string;
    applicationSettings: Array<ApplicationSetting>;
}

export class ApplicationPost extends BaseAuditModel {
    name: string;
    applicationSettings: Array<ApplicationSetting> = [];

    constructor(data?: IApplicationPost) {
      super(data);

      if (!data) {
        return;
      }

      _assign(this, data);
    }
}
