import { SelectOption } from 'src/app/core/models/select-option.model';
import { SelectOptionGrouping } from 'src/app/core/models/select-option-grouping';
import { GlobalSubcategory } from 'src/app/core/models/global-subcategory.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-global-categories-selected-readonly',
  templateUrl: './global-categories-selected-readonly.component.html',
  styleUrls: ['./global-categories-selected-readonly.component.scss']
})
export class GlobalCategoriesSelectedReadonlyComponent implements OnInit {
  @Input() globalCategoryOptions: Array<SelectOptionGrouping> = [];
  @Input() selectedGlobalSubcategories: Array<GlobalSubcategory> = [];

  constructor() { }

  ngOnInit(): void {
  }

  get globalCategories(): Array<SelectOption> {
    const results: Array<SelectOption> = [];

    if (!this.selectedGlobalSubcategories) {
      return results;
    }

    this.selectedGlobalSubcategories.forEach(s => {
      if (!this.globalCategoryOptions) { return []; }
      const option = this.globalCategoryOptions.find(o => o.id === s.globalCategoryId);
      if (!option) { return []; }

      if (!results.some(o => o.id === option.id)) {
        results.push(option);
      }
    });

    return results;
  }

  getGlobalSubcategories(globalCategoryId: number): Array<GlobalSubcategory> {
    if (!this.selectedGlobalSubcategories) { return []; }
    return this.selectedGlobalSubcategories.filter(x => x.globalCategoryId === globalCategoryId);
  }
}
