import { Action } from '@ngrx/store';

export enum RegionActionTypes {
  LoadRegions = '[Region] Load Regions',
}

export class LoadRegions implements Action {
  readonly type = RegionActionTypes.LoadRegions;

  constructor() { }
}

export type RegionActionsUnion =
    LoadRegions;
