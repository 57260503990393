import { LocaleSpecifier } from './../../../core/models/locale-specifier.model';
import { RegionsService } from 'src/app/core/services/regions.service';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { LocaleSpecifierActions, LocaleSpecifierApiActions } from '../actions';
import { of } from 'rxjs';


@Injectable()
export class LocaleSpecifierEffects {
  @Effect()
  loadLocaleSpecifiers$ = this.actions$.pipe(
    ofType(LocaleSpecifierActions.LocaleSpecifierActionTypes.LoadLocaleSpecifiers),
    switchMap(() =>
      this.regionsService.getLocaleSpecifiers$().pipe(
        map((localeSpecifiers: Array<LocaleSpecifier>) => new LocaleSpecifierApiActions.LoadSuccess(localeSpecifiers)),
        catchError(error =>
          of(new LocaleSpecifierApiActions.LoadFailure(error))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private regionsService: RegionsService) {}
}
