import { Action } from '@ngrx/store';
export enum ProductGuidanceAttributesActionTypes {
  LoadProductGuidanceAttributes = '[ProductGuidanceAttributes] Load Product Guidance Attributes'
}

export class LoadProductGuidanceAttributes implements Action {
  readonly type = ProductGuidanceAttributesActionTypes.LoadProductGuidanceAttributes;
  constructor(public payload: number) { }
}

export type ProductGuidanceAttributesActionsUnion =
  LoadProductGuidanceAttributes;
