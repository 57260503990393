import { IProductNumberPrice, ProductNumberPrice } from './../models/product-number-price.model';
import { Injectable } from '@angular/core';
import { assign as _assign } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class ProductNumberPriceCalculationService {

  constructor() { }

  public sumOfAllListPrices(productNumberPrices: Array<IProductNumberPrice>) : number | null {
    const sum = this.filterContractGrouping(productNumberPrices)?.
      map(x => x.totalListPrice).
      reduce((a, b) => a + b, 0);

    return this.convertZeroToNull(sum);
  }

  public sumOfAllContractListPrices(productNumberPrices: Array<IProductNumberPrice>) : number | null {
    const sum = this.filterContractGrouping(productNumberPrices)?.
      map(x => x.netPrice).
      reduce((a, b) => a + b, 0);

    return this.convertZeroToNull(sum);
  }

  private convertZeroToNull(value: number | null) : number | null {
    return !value || value === 0 ? null : value;
  }

  private filterContractGrouping(productNumberPrices: Array<IProductNumberPrice>) : Array<IProductNumberPrice> {
    const contractPrices = productNumberPrices.filter(x => x.contractId !== null && x.contractId !== 0 && x.isValid === true);
    const nonContractPrices = productNumberPrices.filter(x => x.contractId === null && x.isValid === true);

    // If there are no valid pricies, just return;
    if (!(contractPrices?.length > 0) && !(nonContractPrices?.length > 0)) {
      return productNumberPrices;
    }

    // If there is no contract pricing, return non-contract pricing.
    if (!(contractPrices.length > 0)) {
      // ensure we don't display a prime price for non-contract even though pricing returns it.  This is to avoid user confusion.
      const newNonContractPrices = nonContractPrices.map(x => new ProductNumberPrice(x));
      newNonContractPrices.forEach(x => { x.netPrice = 0 });
      return newNonContractPrices;
    }

    // Only return contract pricing for one contract.
    // NOTE: This will need to be re-worked in the event we allow multi-contract pricing.
    const contractId = contractPrices[0].contractId;
    return contractPrices.filter(x => x.contractId === contractId);
  }
}
