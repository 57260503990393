<div *ngIf="numberOfMediaToBeUpdated > 0">
  <button
    mat-icon-button
    disableRipple
    [matMenuTriggerFor]="menu">
    <mat-icon>more_horiz</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      (click)="updateBynderDetailsDialog()"
      matTooltip="Update Bynder Details">
      <mat-icon>update</mat-icon>
      Update Bynder Details
    </button>
  </mat-menu>
</div>