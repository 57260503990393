import { map, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { ConfigurationsApiActions } from 'src/app/app-store/configurations-store';
import { ofType, Actions } from '@ngrx/effects';
import { GlobalConfiguration } from 'src/app/core/models/global-configuration.model';
import { PagedResults } from 'src/app/core/models/page-results.model';
import { fromEvent, Observable } from 'rxjs';
import { Component, Input, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { ConfigurationSearchCriteria, GlobalConfigurationSearchDatasource } from './global-configuration-search.datasource';
import { SubSink } from 'subsink';
import { IntegrationSummary } from 'src/app/core/models/integration-summary.model';

@Component({
  selector: 'app-global-configuration-search',
  templateUrl: './global-configuration-search.component.html',
  styleUrls: ['./global-configuration-search.component.scss']
})
export class GlobalConfigurationSearchComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() datasource: GlobalConfigurationSearchDatasource;
  @Input() initiallySelectedIntegrationIds: Array<number> = [];
  @Input() initiallySelectedLocaleIds: Array<number> = [];

  @ViewChild('input') input: ElementRef;

  public get page$(): Observable<PagedResults<GlobalConfiguration>> { return this.datasource?.page$; }
  public get loading$(): Observable<boolean> { return this.datasource?.loading$; }
  public get loaded$(): Observable<boolean> { return this.datasource.loaded$; }
  public get integrationIds(): Array<number> { return this.selectedIntegrationIds; }

  public searchTerm = '';
  public localeIds: Array<number> = [];

  public showFilters = false;

  private subscriptions: SubSink = new SubSink();
  private selectedIntegrationIds: Array<number> = [];

  constructor(
    private actions$: Actions) {
  }

  ngOnInit(): void {
    this.selectedIntegrationIds = [...this.initiallySelectedIntegrationIds];
    this.localeIds = [...this.initiallySelectedLocaleIds];
    this.search('', [...this.selectedIntegrationIds], [...this.localeIds]);

    this.subscriptions.add(
      this.actions$.pipe(
        ofType(ConfigurationsApiActions.ConfigurationsApiActionTypes.UpdateLeadTimesSuccess)
      ).subscribe(() => {
        this.refresh();
        this.datasource.clearSelections();
      })
    );

    this.subscriptions.add(
      this.actions$.pipe(
        ofType(ConfigurationsApiActions.ConfigurationsApiActionTypes.UpdateDimensionsSuccess)
      ).subscribe(() => {
        this.refresh();
        this.datasource.clearSelections();
      })
    );    
  }

  ngAfterViewInit(): void {
    this.subscribeToSearchKeyPress();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public getPageRecordCountStart(): number {
    return this.datasource.getPageRecordCountStart();
  }

  public getPageRecordCountEnd(): number {
    return this.datasource.getPageRecordCountEnd();
  }

  public nextPage(): void {
    this.datasource.nextPage();
  }

  public previousPage(): void {
    this.datasource.previousPage();
  }

  public toggleFilters(): void {
    this.showFilters = !this.showFilters;
  }

  public getFiltersTooltip(): string {
    if (!this.showFilters) {
      return 'Show additional search filters';
    }
    return 'Hide additional search filters';
  }

  public getActiveFilterCount(): number {
    return this.datasource?.criteria?.integrationIds?.length;
  }

  public onIntegrationSelectionsChanged(integrations: Array<IntegrationSummary>): void {
    this.selectedIntegrationIds = [...integrations?.map(x => x.id)];
    this.search(this.searchTerm, [...this.selectedIntegrationIds], [...this.localeIds]);
  }

  private subscribeToSearchKeyPress(): void {
    const keypress$ = fromEvent(this.input.nativeElement, 'keyup').pipe(
      map(x => this.input.nativeElement.value),
      debounceTime(500),
      // Prevents keys like 'enter' and arrow keys from re-running the query.
      distinctUntilChanged((x, y) => x === y),
      tap(() => {
        this.searchTerm = this.input?.nativeElement?.value;
        this.search(this.searchTerm, [...this.selectedIntegrationIds], [...this.localeIds]);
      })
    ).subscribe();

    this.subscriptions.add(keypress$);
  }

  private search(searchTerm: string, integrationIds: Array<number>, localeIds: Array<number>): void {
    const criteria = new ConfigurationSearchCriteria({
      searchTerm,
      integrationIds,
      localeIds
    });
    this.datasource.search(criteria);
  }

  private refresh(): void {
    this.datasource.refresh();
  }
}
