<form [formGroup]="attributeOptionForm" autocomplete="off" novalidate>
<div>
  <div mat-dialog-title>
    <div class="h6"><strong>{{data.attributeOption?.name}} Attribute Option Details</strong></div>
  </div>

  <div mat-dialog-content class="card-section">
    <mat-card class="app-card detail-card-readonly">
      <mat-card-content>
        <div class="card-content" *ngIf="!data.attributeOption?.attributeOptionType">
          <app-card-content>
            <div card-content-title>
              <strong>Name</strong>
            </div>
            <div card-content-value>
              <app-card-content-string-readonly [value]="data.attributeOption?.name">
              </app-card-content-string-readonly>
            </div>
          </app-card-content>
        </div>

        <div class="card-content">
          <app-card-content>
            <div card-content-title>
              <strong>Description</strong>
            </div>
            <div card-content-value class="word-break-all">
              <app-card-content-string-multiline-readonly [value]="data.attributeOption?.longDescription"
                truncate="true">
              </app-card-content-string-multiline-readonly>
            </div>
          </app-card-content>
        </div>

        <div class="card-content">
          <app-card-content>
            <div card-content-title>
              <strong>Is In Draft</strong>
            </div>
            <div card-content-value>
              <app-card-content-boolean-readonly [value]="data.attributeOption?.isInDraft">
              </app-card-content-boolean-readonly>
            </div>
          </app-card-content>
        </div>

        <div class="card-content">
          <app-card-content>
            <div card-content-title>
              <strong>Icon Image URL</strong>
            </div>
            <div card-content-value class="word-break-all">
              <app-card-content-string-multiline-readonly [value]="data.attributeOption?.imageUrl" truncate="true">
              </app-card-content-string-multiline-readonly>
            </div>
          </app-card-content>
        </div>

        <div class="card-content">
          <app-card-content>
            <div card-content-title>
              <strong>Icon Image Preview</strong>
            </div>
            <div card-content-value>
              <app-card-content-image class="align-right" [imageLink]="data.attributeOption?.imageUrl"
                placeholderAlt="Placeholder Image">
              </app-card-content-image>
            </div>
          </app-card-content>
        </div>

        <div class="card-content">
          <app-card-content>
            <div card-content-title>
              <strong>Attribute Option Type</strong>
            </div>
            <div card-content-value>
              <app-card-content-string-readonly [value]="data.attributeOption?.attributeOptionType?.name">
              </app-card-content-string-readonly>
            </div>
          </app-card-content>
          <app-card-content *ngIf="data.attributeOption?.attributeOptionType">
            <div card-content-title>
              <strong>{{getAttributeOptionValueText(data.attributeOption?.attributeOptionType)}}</strong>
            </div>
            <div card-content-value>
              <app-card-content-string-readonly
                [value]="getAttributeOptionValue(data?.attributeOption?.attributeOptionValues)">
              </app-card-content-string-readonly>
            </div>
          </app-card-content>
        </div>

        <div class="card-content" *ngIf="this.data.productAttributeOption">
          <app-card-content>
            <div card-content-title>
              <strong>Parent Attribute Option</strong>
            </div>
            <div card-content-value>
              <div *ngIf="!this.data.editComponent">                
                <app-card-content-string-list-readonly
                  [values]="getParentAttributeOptionValue()">
                </app-card-content-string-list-readonly>
              </div>
            </div>
          </app-card-content>
          <div *ngIf="this.data.editComponent">
            <div>
              <small>{{getNumberofParentsSelected()}} option(s) selected.</small>
            </div><br>
            <mat-form-field appearance="outline">
              <mat-label>Parent Attribute Option</mat-label>
              <mat-select class="tooltip" [matTooltip]="getTooltipAttributeOptions()" (selectionChange)="sortFilteredAttributeOptionItems()"
                formControlName="parentAttributeOptionId" placeholder="Select Parent Attribute Option..." [multiple]="true" >
                <div fxLayout="row" class="fixed-search-header">
                  <mat-form-field [floatLabel]="'never'" class="fixed-mat-form-field">
                    <mat-icon fxFlex="7.5%">search</mat-icon>
                    <input fxFlex="85%" matInput class="fixed-mat-input"
                      (input)="filterParentAttributeOptions($event.target.value);" #inputFilter>
                    <mat-icon fxFlex="7.5%" (click)="clearSearchText(inputFilter);">clear</mat-icon>
                  </mat-form-field>
                </div>
                <div class="div-mat-option">
                  <mat-option *ngFor="let attributeOption of this.filteredAttributeOptionInfo" [value]="attributeOption.id">
                    {{attributeOption.attributeTypeAndOptionName}}
                  </mat-option>
                </div>
              </mat-select>
            </mat-form-field>
          </div>
        </div>        

        <div class="card-content" *ngIf="this.data.productAttributeOption">
          <div>
            <app-card-content>
              <div card-content-title>
                <strong>Offering Exclusion(s)</strong>
              </div>
              <div card-content-value *ngIf="!this.data?.editComponent && excludedItemsListInternal?.length == 0">
                <app-card-content-string-readonly> None
                </app-card-content-string-readonly>
              </div>
            </app-card-content> <br />
          </div>

            <div *ngIf="this.data.editComponent">
              <mat-form-field appearance="outline">
                <mat-label>Not Available in Offerings</mat-label>
                <mat-select [formControl]="unavailableOfferings" placeholder="Select Offerings..." multiple
                  [panelClass]="'custom-dropdown-panel'" (selectionChange)="optionSelected($event)">
                  <!-- Outer loop -->
                  <ng-container *ngFor="let summary of this.data?.integrationProductOfferingSummarys">
                    <mat-option *ngFor="let product of summary.productOfferings" [value]="product.id">
                      <img [src]="summary.iconLink" alt="Product Icon" height="20" width="20"
                        style="vertical-align: middle; margin-right: 5px;">
                      {{ product.name }} - {{ product.templateName }}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>


          <ng-container *ngFor="let group of excludedItemsListInternal; let last = last">
            <div class="group-container">
              <img [src]="group.imageIcon" alt="Group Icon" class="group-icon">
              <h3 class="group-name">{{ group.name }}</h3>
            </div>
            <mat-chip-list class="custom-chip-list">
              <div style="margin-bottom: 2px;"></div>
              <mat-chip *ngFor="let optionText of group.listOfOfferings" class="custom-chip option-chip">
                {{ optionText }}
              </mat-chip>
            </mat-chip-list>
            <div *ngIf="!last" style="margin-bottom: 10px;"></div>
          </ng-container>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div mat-dialog-actions>
    <button class="mat-raised-button primary-button" mat-dialog-close (click)="onSubmitClose()">Close</button>
    <span *ngIf="this.data.editComponent" style="margin-right: 10px;"></span>
    <button *ngIf="this.data.editComponent" class="mat-raised-button secondary-button" mat-dialog-close
      (click)="onSubmit()" [class.disabled-button]="!isUnavailableOfferingsDirty()"
      [disabled]="!isUnavailableOfferingsDirty()">Update Attribute Option</button>
  </div>
</div>
</form>