import * as _ from 'lodash';
import { IImageAsset, ImageAsset } from './image-asset.model';

export interface IImageAssetLocale {
  localeId: number;
  mediaType: number;
  imageAsset: ImageAsset[];
}

export class ImageAssetLocale implements IImageAssetLocale {
  localeId: number;
  mediaType: number;
  imageAsset: ImageAsset[];

  constructor(data?: IImageAsset) {
    if (!data) {
      return;
    }

    _.assign(this, data);
  }
}
