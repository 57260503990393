import { FilesService } from './files.service';
import { ApplicationSummary } from 'src/app/core/models/application-summary.model';
import { Application } from 'src/app/core/models/application.model';
import { ApplicationsService } from 'src/app/core/services/applications.service';
import { FormGroup, ValidatorFn, FormArray, ValidationErrors, AbstractControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DigitalAssetManagerService } from './digital-asset-manager.service';
import { map, debounceTime, distinctUntilChanged, tap, switchMap, catchError, take } from 'rxjs/operators';
import { UserPermissionsService } from './user-permissions.service';
import { User } from '../models/user.model';
import { DesignersService } from './designers.service';
import { DesignerSummary } from '../models/designer-summary.model';
import { CategoriesService } from './categories.service';
import { GlobalCategoriesService } from './global-categories.service';
import { CategorySummary } from '../models/category-summary.model';
import { GlobalCategorySummary } from '../models/global-category-summary.model';
import { SubcategorySummary } from '../models/subcategory-summary.model';
import { SubcategoriesService } from './subcategories.service';
import { GlobalSubcategoriesService } from './global-subcategories.service';
import { GlobalSubcategorySummary } from '../models/global-subcategory-summary.model';
import { BrandsService } from './brands.service';
import { BrandSummary } from '../models/brand-summary.model';
import { SubBrandsService } from './sub-brands.service';
import { SubBrandSummary } from '../models/sub-brand-summary.model';
import { FamiliesService } from './families.service';
import { FamilySummary } from '../models/family-summary.model';
import { PortfolioService } from './portfolio.service';
import { PortfolioSummary } from '../models/portfolio-summary.model';
import { Portfolio } from '../models/portfolio.model';
import { WarrantyService } from './warranty.service';
import { ApplicationTagService } from './application-tag.service';
import { ApplicationTagSummary } from '../models/application-tag-summary.model';
import { GlobalProductsService } from './global-products.service';
import { GlobalProductSummary } from '../models/global-product-summary.model';
import { GlobalProductOfferingSummary } from '../models/global-product-offering-summary.model';
import { GlobalProductOfferingsService } from './global-product-offerings.service';
import { ApplicationSetting } from '../models/application-setting.model';
import { ContactsService } from './contacts.service';
import { Contact } from '../models/contact.model';
import { WarrantySummary } from '../models/warranty-summary.model';
import { ContactGlobalProductLocale } from '../models/contact-global-product-locale.model';
import { Locale } from '../models/locale.model';
import { TemplatesService } from './templates.service';
import { TemplateSummary } from '../models/template-summary';
import { AttributeTypeSummary } from '../models/attribute-type-summary.model';
import { AttributeTypeService } from './attribute-type.service';



@Injectable({
  providedIn: 'root'
})
export class CustomValidatorsService {
  constructor(
    private digitalAssetManagerService: DigitalAssetManagerService,
    private applicationsService: ApplicationsService,
    private designersService: DesignersService,
    private categoriesService: CategoriesService,
    private globalCategoriesService: GlobalCategoriesService,
    private subcategoriesService: SubcategoriesService,
    private globalSubcategoriesService: GlobalSubcategoriesService,
    private brandsService: BrandsService,
    private templatesService: TemplatesService,
    private subBrandsService: SubBrandsService,
    private userPermissionService: UserPermissionsService,
    private filesService: FilesService,
    private familyService: FamiliesService,
    private portfolioService: PortfolioService,
    private warrantyService: WarrantyService,
    private attributeTypeService: AttributeTypeService,
    private applicationTagService: ApplicationTagService,
    private globalProductsService: GlobalProductsService,
    private globalProductOfferingsService: GlobalProductOfferingsService,
    private contactsService: ContactsService) { }

  public getFileExtensionRegEx(extensions: Array<string>): string {
    let extensionString = '';
    let allowsNoExtensions = false;

    if (extensions.some(x => x === '')) {
      allowsNoExtensions = true;
      extensions = extensions.filter(x => x !== '');
    }

    extensions.map(e => extensionString = extensionString.length > 0 ? `${extensionString}|${e.toLowerCase()}|${e.toUpperCase()}` : `${e.toLowerCase()}|${e.toUpperCase()}`);
    let regex = `(^.*\\.(${extensionString}))`;

    // Check for '' in the list.  If it's in the list, then 'no extension' is a viable option.
    if (allowsNoExtensions) {
      regex = `${regex}|(^.*\\/\\/.*\\/[^.]*)`; // Allows for no extension
    }
    regex = `${regex}$`;

    // Example: '^.*\.(jpg|JPG|gif|GIF|doc|DOC|pdf|PDF)$';
    return regex;
  }

  public validateAtLeastOne(): ValidatorFn {
    return (formArray: FormArray): ValidationErrors | null => {
      let hasAtLeastOneControl = false;

      if (formArray.controls.length > 0) {
        hasAtLeastOneControl = true;
      }

      return !hasAtLeastOneControl ? { atLeastOneRequired: { value: formArray.value } } : null;
    };
  }

  public validateStringNotEqualTo(invalidValues: Array<string>): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.valueChanges || control.pristine || !control.value || !invalidValues || invalidValues.length === 0) {
        return null;
      }

      let hasInvalidValue = false;
      if (invalidValues.some(v => v.toLowerCase() === control.value.toLowerCase())) {
        hasInvalidValue = true;
      }

      return hasInvalidValue ? { invalidValue: { value: control.value } } : null;
    };
  }

  public validateNullableGreaterThanOrEqualTo(lessThanFormControlKey: string, greaterThanFormControlKey: string) {
    return (formGroup: FormGroup) => {
      const minControl = formGroup.controls[lessThanFormControlKey];
      const maxControl = formGroup.controls[greaterThanFormControlKey];

      if (!minControl || !maxControl) {
        return null;
      }

      // If either controls already have other errors, don't evaluate the greater than error just yet.
      if (
        (minControl.errors || maxControl.errors) &&
        (!maxControl.errors || (maxControl.errors && !maxControl.errors.mustBeGreaterThan))
      ) {
        return null;
      }

      const isMinMaxValid: boolean = this.isMinMaxValid((minControl.value as number), (maxControl.value as number));
      if (!isMinMaxValid) {
        maxControl.setErrors({ mustBeGreaterThan: true });
      } else {
        maxControl.setErrors(null);
      }
    };
  }

  public validateApplicationSettingDuplicate(
    settingFormControlKey: string,
    settingTypeFormControlKey: string,
    industryFormControlKey: string,
    applicationSettings: Array<ApplicationSetting>) {
    return (formGroup: FormGroup) => {
      const settingControl = formGroup.controls[settingFormControlKey];
      const settingTypeControl = formGroup.controls[settingTypeFormControlKey];
      const industryControl = formGroup.controls[industryFormControlKey];

      if (!settingControl || !settingTypeControl || !industryControl) {
        return null;
      }

      // If all controls already have other errors, don't evaluate the duplicate error just yet.
      if (settingControl.errors || settingTypeControl.errors || industryControl.errors) {
        return null;
      }

      const isDuplicate: boolean = applicationSettings.some(as => as.settingId == settingControl.value &&
        as.settingTypeId == settingTypeControl.value && as.industryId == industryControl.value);

      if (isDuplicate) {
        settingControl.setErrors({ isDuplicate: true });
      } else {
        settingControl.setErrors(null);
      }
    };
  }

  public validateFileUrlExists = (control: AbstractControl): Observable<ValidationErrors | null> => {
    if (!control.value) {
      return of(null);
    }

    if (control.value) {
      return this.filesService.exists$(control.value).pipe(
        map(urlExists => urlExists === true ? null : { fileNotExists: true }),
        catchError(error => {
          console.error(error);
          //return of(null);
          return of({ fileExistsCheckFailure: true });
        }),
        take(1)
      );
    }
  }

  public validateUniqueApplication = (control: AbstractControl): Observable<ValidationErrors | null> => {
    if (!control.valueChanges || control.pristine || !control.value) {
      return of(null);
    }

    return control.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((value: string) => this.applicationsService.getDuplicates$(value)),
      map((response: Array<ApplicationSummary>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      take(1)
    );
  }

  public validateUniqueDesigner = (control: AbstractControl): Observable<ValidationErrors | null> => {
    if (!control.valueChanges || control.pristine || !control.value) {
      return of(null);
    }

    return control.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((value: string) => this.designersService.getDuplicates$(value)),
      map((response: Array<DesignerSummary>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      take(1)
    );
  }

  public validateUniqueContact = (control: AbstractControl): Observable<ValidationErrors | null> => {
    if (!control.valueChanges || control.pristine || !control.value) {
      return of(null);
    }

    return control.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((value: string) => this.contactsService.getDuplicates$(value)),
      map((response: Array<Contact>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      take(1)
    );
  }

  public validateUniqueCategory = (control: AbstractControl): Observable<ValidationErrors | null> => {
    if (!control.valueChanges || control.pristine || !control.value) {
      return of(null);
    }

    return control.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((value: string) => this.categoriesService.getDuplicates$(value)),
      map((response: Array<CategorySummary>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      take(1)
    );
  }

  public validateUniqueGlobalCategory = () => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.valueChanges || control.pristine || !control.value) {
        return of(null);
      }

      let name = control.get("name")?.value;
      let industryId = control.get("industryId")?.value;

      return control.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value: string) => this.globalCategoriesService.getDuplicates$(name, industryId)),
        map((response: Array<GlobalCategorySummary>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
        catchError(error => {
          console.error(error);
          return of(null);
        }),
        take(1)
      );
    }
  }

  public validateUniqueGlobalCategoryByIndustryId = (industryId: any) => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.valueChanges || control.pristine || !control.value) {
        return of(null);
      }

      return control.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value: string) => this.globalCategoriesService.getDuplicates$(value, industryId)),
        map((response: Array<GlobalCategorySummary>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
        catchError(error => {
          console.error(error);
          return of(null);
        }),
        take(1)
      );
    }
  }

  public validateUniqueFamily = (control: AbstractControl): Observable<ValidationErrors | null> => {
    if (!control.valueChanges || control.pristine || !control.value) {
      return of(null);
    }

    return control.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((value: string) => this.familyService.getDuplicates$(value)),
      map((response: Array<FamilySummary>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      take(1)
    );
  }

  public validateUniqueProductOfferingNameForm = (globalProductId: any) => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.valueChanges || control.pristine || !control.value) {
        return of(null);
      }

      let name = control.get("name")?.value;
      let templateId = control.get("templateId")?.value;


      return control.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value: string) => this.globalProductOfferingsService.getDuplicates$(name, templateId, globalProductId)),
        map((response: Array<GlobalProductOfferingSummary>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
        catchError(error => {
          console.error(error);
          return of(null);
        }),
        take(1)
      );
    }
  }

  public validateUniqueProductName = () => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.valueChanges || control.pristine || !control.value) {
        return of(null);
      }

      let name = control.value;

      return control.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value: string) => this.globalProductsService.getDuplicates$(name)),
        map((response: Array<GlobalProductSummary>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
        catchError(error => {
          console.error(error);
          return of(null);
        }),
        take(1)
      );
    }
  }

  public validateUniqueProductNameRename() {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.valueChanges || control.pristine || !control.value) {
        return of(null);
      }

      let name = control.value;

      return control.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value: string) => this.globalProductsService.getDuplicates$(name)),
        map((response: Array<GlobalProductSummary>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
        catchError(error => {
          console.error(error);
          return of(null);
        }),
        take(1)
      );
    }
  }

  public validateUniqueProductOfferingNameRename = (globalProductId: any, templateId: any) => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.valueChanges || control.pristine || !control.value) {
        return of(null);
      }

      let name = control.value;

      return control.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value: string) => this.globalProductOfferingsService.getDuplicates$(name, templateId, globalProductId)),
        map((response: Array<GlobalProductOfferingSummary>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
        catchError(error => {
          console.error(error);
          return of(null);
        }),
        take(1)
      );
    }
  }

  public validateUniquePortfolio = () => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.valueChanges || control.pristine || !control.value) {
        return of(null);
      }

      let name = control.get("name")?.value;
      let environmentId = control.get("environmentId")?.value;

      return control.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value: string) => this.portfolioService.getDuplicates$(name, environmentId)),
        map((response: Array<PortfolioSummary>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
        catchError(error => {
          console.error(error);
          return of(null);
        }),
        take(1)
      );
    }
  }

  public validateUniqueSubcategory = () => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.valueChanges || control.pristine || !control.value) {
        return of(null);
      }

      let name = control.get("name")?.value;
      let categoryId = control.get("categoryId")?.value;

      return control.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value: string) => this.subcategoriesService.getDuplicates$(name, categoryId)),
        map((response: Array<SubcategorySummary>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
        catchError(error => {
          console.error(error);
          return of(null);
        }),
        take(1)
      );
    }
  }

  public validateUniqueGlobalSubcategory = () => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.valueChanges || control.pristine || !control.value) {
        return of(null);
      }

      let name = control.get("name")?.value;
      let globalCategoryId = control.get("globalCategoryId")?.value;

      return control.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value: string) => this.globalSubcategoriesService.getDuplicates$(name, globalCategoryId)),
        map((response: Array<GlobalSubcategorySummary>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
        catchError(error => {
          console.error(error);
          return of(null);
        }),
        take(1)
      );
    }
  }

  public validateUniqueBrand = (control: AbstractControl): Observable<ValidationErrors | null> => {
    if (!control.valueChanges || control.pristine || !control.value) {
      return of(null);
    }

    return control.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((value: string) => this.brandsService.getDuplicates$(value)),
      map((response: Array<BrandSummary>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      take(1)
    );
  }

  public validateUniqueTemplate = (integrationId: any) => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.valueChanges || control.pristine || !control.value) {
        return of(null);
      }

      let name = control.value

      return control.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value: string) => this.templatesService.getDuplicates$(name, integrationId)),
        map((response: Array<TemplateSummary>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
        catchError(error => {
          console.error(error);
          return of(null);
        }),
        take(1)
      );
    }
  }

  public validateUniqueWarrantyRename = (control: AbstractControl): Observable<ValidationErrors | null> => {
    if (!control.valueChanges || control.pristine || !control.value) {
      return of(null);
    }

    return control.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((value: string) => this.warrantyService.getDuplicates$(value)),
      map((response: Array<WarrantySummary>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      take(1)
    );
  }

  public validateUniqueWarranty = () => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.valueChanges || control.pristine || !control.value) {
        return of(null);
      }

      let name = control.get("name")?.value;

      return control.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value: string) => this.warrantyService.getDuplicates$(name)),
        map((response: Array<WarrantySummary>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
        catchError(error => {
          console.error(error);
          return of(null);
        }),
        take(1)
      );
    }
  }

  public validateUniqueAttributeTypeRename(previousName: string) {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.valueChanges || control.pristine || !control.value) {
        return of(null);
      }

      return control.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value: string) => this.attributeTypeService.getDuplicates$(value)),
        map((response: Array<AttributeTypeSummary>) => {
          if (response.length == 1 && response[0].name === previousName) {
            return null;
          }

          return !response || response.length === 0 ? null : { hasDuplicates: true }
        }),
        catchError(error => {
          console.error(error);
          return of(null);
        }),
        take(1)
      );
    }
  }

  public validateUniqueAttributeType() {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.valueChanges || control.pristine || !control.value) {
        return of(null);
      }

      return control.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value: string) => this.attributeTypeService.getDuplicates$(value)),
        map((response: Array<AttributeTypeSummary>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
        catchError(error => {
          console.error(error);
          return of(null);
        }),
        take(1)
      );
    }
  }

  public validateUniqueDesignerNew = () => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.valueChanges || control.pristine || !control.value) {
        return of(null);
      }

      let name = control.get("name")?.value;

      return control.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value: string) => this.designersService.getDuplicates$(name)),
        map((response: Array<DesignerSummary>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
        catchError(error => {
          console.error(error);
          return of(null);
        }),
        take(1)
      );
    }
  }

  public validateUniqueContactNew = () => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.valueChanges || control.pristine || !control.value) {
        return of(null);
      }

      let name = control.get("name")?.value;

      return control.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value: string) => this.contactsService.getDuplicates$(name)),
        map((response: Array<Contact>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
        catchError(error => {
          console.error(error);
          return of(null);
        }),
        take(1)
      );
    }
  }

  public cannotContainSpace = (control: AbstractControl): ValidationErrors | null => {
    if (control.value.trim() == '')
      return { cannotContainSpace: true };
  }

  public validateUniqueApplicationTag = () => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.valueChanges || control.pristine || !control.value) {
        return of(null);
      }

      let tag = control.get("tag")?.value;

      return control.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value: string) => this.applicationTagService.getDuplicates$(tag)),
        map((response: Array<ApplicationTagSummary>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
        catchError(error => {
          console.error(error);
          return of(null);
        }),
        take(1)
      );
    }
  }

  public validateUniquePortfolioByEnvironmentId = (environmentId: any) => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.valueChanges || control.pristine || !control.value) {
        return of(null);
      }

      return control.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value: string) => this.portfolioService.getDuplicates$(value, environmentId)),
        map((response: Array<Portfolio>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
        catchError(error => {
          console.error(error);
          return of(null);
        }),
        take(1)
      );
    }
  }

  public validateUniqueSubcategoryByCategoryId = (categoryId: any) => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.valueChanges || control.pristine || !control.value) {
        return of(null);
      }

      return control.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value: string) => this.subcategoriesService.getDuplicates$(value, categoryId)),
        map((response: Array<SubcategorySummary>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
        catchError(error => {
          console.error(error);
          return of(null);
        }),
        take(1)
      );
    }
  }

  public validateUniqueGlobalSubcategoryByGlobalCategoryId = (globalCategoryId: any) => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.valueChanges || control.pristine || !control.value) {
        return of(null);
      }

      return control.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value: string) => this.globalSubcategoriesService.getDuplicates$(value, globalCategoryId)),
        map((response: Array<GlobalSubcategorySummary>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
        catchError(error => {
          console.error(error);
          return of(null);
        }),
        take(1)
      );
    }
  }

  public validateUniqueSubBrand = () => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.valueChanges || control.pristine || !control.value) {
        return of(null);
      }

      let name = control.get("name")?.value;
      let brandId = control.get("brandId")?.value;

      return control.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value: string) => this.subBrandsService.getDuplicates$(name, brandId)),
        map((response: Array<SubBrandSummary>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
        catchError(error => {
          console.error(error);
          return of(null);
        }),
        take(1)
      );
    }
  }

  public validateUniqueSubBrandByBrandId = (brandId: any) => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.valueChanges || control.pristine || !control.value) {
        return of(null);
      }

      return control.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value: string) => this.subBrandsService.getDuplicates$(value, brandId)),
        map((response: Array<SubBrandSummary>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
        catchError(error => {
          console.error(error);
          return of(null);
        }),
        take(1)
      );
    }
  }

  public validateUniqueUserEmail = (control: AbstractControl): Observable<ValidationErrors | null> => {
    if (!control.valueChanges || control.pristine || !control.value) {
      return of(null);
    }

    return control.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((value: string) => this.userPermissionService.getDuplicates$(value)),
      map((response: Array<User>) => !response || response.length === 0 ? null : { hasDuplicates: true }),
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      take(1)
    );
  }

  private isMinMaxValid(min: number | null, max: number | null): boolean {
    // If either value is null, the statement is true.
    if (
      (min === null || min === undefined || max === null || max === undefined) ||
      (min !== null && min !== undefined && max !== null && max !== undefined && (min <= max))
    ) {
      return true;
    }

    return false;
  }

  public isAutocompleteSelection(control: AbstractControl) {
    const selection: any = control.value;
    if (control.dirty && selection && typeof selection === 'string') {
      return { notSelected: true };
    }
    return null;
  }

  // Used by the model files profile edit modal for determining if user entered model files profile name already exists
  // on a specific product
  public validateNotDuplicateModelFilesProfileName(modelFilesProfileNames: Array<string>): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value;
      if (!value) {
        return null;
      }

      // convert the value being input to lower case, and remove any spaces from the ends.
      const valueCleaned = value.toLowerCase().trim();

      const duplicates = modelFilesProfileNames.filter(n => n.toLowerCase() == valueCleaned);

      if (duplicates == null || duplicates.length == 0) {
        return null;
      }

      return { duplicateName: true };
    }
  }

  // Used by the add contact-global-product-locale-modal for determining if user entered a contact and locale combination
  // that already exists for the global product.
  public validateNotDuplicateContactAndLocale(existingContacts: Array<ContactGlobalProductLocale>): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: ContactGlobalProductLocale = control.value;
      if (!value || !value.contact || !value.locale) {
        return null;
      }

      const duplicates = existingContacts.filter(n => n.contact.id == value.contact.id && n.locale.id == value.locale.id);

      if (duplicates == null || duplicates.length == 0) {
        return null;
      }

      return { duplicateContactAndLocale: true };
    }
  }

  // Used by the add contact-global-product-locale-modal for determining if user entered a locale
  // that is already assigned to another contact for the global product.
  public validateNotDuplicateContactLocale(existingContacts: Array<ContactGlobalProductLocale>): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: ContactGlobalProductLocale = control.value;
      if (!value || !value.contact || !value.locale) {
        return null;
      }

      const duplicates = existingContacts.filter(n => n.locale.id == value.locale.id);

      if (duplicates == null || duplicates.length == 0) {
        return null;
      }

      return { duplicateContactLocale: true };
    }
  }

  // Used by the add contact-global product-locale modal for determining if user selected a contact
  public validateContact(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: Contact = control.value;
      if (!value) {
        return null;
      }

      if (value && value.id > 0) {
        return null;
      }

      return { invalidContact: true };
    }
  }

  // Used by the add contact-global product-locale modal for determining if user selected a locale
  public validateLocale(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: Locale = control.value;
      if (!value) {
        return null;
      }

      if (value && value.id > 0) {
        return null;
      }

      return { invalidLocale: true };
    }
  }

  public validateNotJustWhiteSpace(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.valueChanges || control.pristine || !control.value) {
        return null;
      }

      return control.value.trim().length > 0 ? null : { 'whitespace': true };
    }
  }
}
