import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { MaterialApiActions, MaterialActions } from '../actions';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { switchMap, map, catchError } from 'rxjs/operators';
import { MaterialsService } from 'src/app/core/services/materials.service';
import { MaterialsRequest, MaterialsResponse } from 'src/app/core/models/material.model';

@Injectable()
export class MaterialEffects {
  @Effect()
  loadMaterialsByProductNumbers$: Observable<Action> = this.actions$.pipe(
    ofType<MaterialActions.LoadMaterials>(MaterialActions.MaterialActionTypes.LoadMaterials),
    map((action: MaterialActions.LoadMaterials) => action.payload),
    switchMap((request: MaterialsRequest) =>
      this.materialsService.getMaterialsByProductNumbers$(request).pipe(
        map((response: MaterialsResponse) =>
          new MaterialApiActions.FetchSuccess(response)
        ),
        catchError((error: any) =>
          of(new MaterialApiActions.FetchFailure(error))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private materialsService: MaterialsService) { }
}
