import { ITemplate } from './../models/template';
import { Template } from '../models/template';
import { Injectable } from '@angular/core';
import { map, catchError, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IntegrationTemplateSummary, IIntegrationTemplateSummary } from '../models/integration-template-summary.model';
import { RenamePut } from '../models/rename-put.model';
import { TemplateSummary } from '../models/template-summary';
import { TemplatePost } from '../models/template-post.model';

@Injectable({
  providedIn: 'root',
})
export class TemplatesService {
  basePath = 'templates';

  constructor(private http: HttpClient) { }

  getIntegrationTemplateSummaries$(): Observable<Array<IntegrationTemplateSummary>> {
    return this.http.get(environment.apiUrl + `${this.basePath}`)
      .pipe(
        map(res => res),
        catchError((err: Response) => {
          const obj = err;
          return throwError(obj);
        }),
        map((list: Array<IIntegrationTemplateSummary>) => list.map(m => new IntegrationTemplateSummary(m))),
      );
  }

  createTemplate$(template: Template): Observable<Template> {
    return this.http.post(environment.apiUrl + `${this.basePath}`, template)
      .pipe(
        map(res => res),
        catchError((err: Response) => {
          const obj = err;
          return throwError(obj);
        }),
        map((createdTemplate: ITemplate) => new Template(createdTemplate))
      );
  }

  renameTemplate$(request: { templateId: number, templateName: string }): Observable<any> {
    const renamePut: RenamePut = new RenamePut({ name: request.templateName });
    return this.http.put(
      environment.apiUrl + `${this.basePath}/${request.templateId}/rename`,
      renamePut,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
    );
  }

  duplicateTemplate$(request: { templateId: number, templateName: string }): Observable<Template> {
    return this.http.post(
      environment.apiUrl + `${this.basePath}/${request.templateId}/duplicate`,
      `\"${request.templateName}\"`, // this notation is required for sending raw strings in the body.
      { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
      .pipe(
        map(res => res),
        catchError((err: Response) => {
          const obj = err;
          return throwError(obj);
        }),
        map((createdTemplate: ITemplate) => new Template(createdTemplate))
      );
  }

  getDuplicates$(templateName: string, integrationId: number): Observable<Array<TemplateSummary>> {
    const request: TemplatePost = new TemplatePost();
    request.integrationId = integrationId;
    request.name = templateName;


    return this.http
      .post(`${environment.apiUrl}${this.basePath}/duplicates`, request)
      .pipe(
        map(response => response),
        catchError((error: Response) => {
          return throwError(error);
        }),
        map((response: Array<TemplateSummary>) => response)
      );
  }

}
