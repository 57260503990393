<div>
  <ng-container *ngIf="(value); else valueElse">
    <div class="align-right">Yes</div>
  </ng-container>
  <ng-template #valueElse>
    <ng-container>
      <div class="align-right">No</div>
    </ng-container>
  </ng-template>
</div>

