import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import {
  SettingsApiActions,
  SettingsActions
} from '../actions';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { SettingsService } from 'src/app/core/services/settings.service';
import { SettingSummary } from 'src/app/core/models/setting-summary.model';

@Injectable()
export class SettingsEffects {
  @Effect()
  loadSettings$ = this.actions$.pipe(
    ofType(SettingsActions.SettingsActionTypes.LoadSettings),
    switchMap(() =>
      this.settingsService.getSettings$().pipe(
        map((settings: Array<SettingSummary>) => new SettingsApiActions.FetchSuccess(settings)),
        catchError(error =>
          of(new SettingsApiActions.FetchFailure(error)
          )
        )
      )
    ));

  constructor(private actions$: Actions,
              private settingsService: SettingsService) {
    console.log('Settings effect called');
  }

}
