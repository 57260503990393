import * as _ from 'lodash';


export interface ISifResults {
  records: Array<ISifRecord>;
}

export class SifResults {
  records: Array<SifRecord> = [];

  constructor(data?: ISifResults) {
    if (!data) {
      return;
    }

    _.assign(this, data);
  }
}

export interface ISifRecord {
  productNumber: string;
  productDescription: string;
  listPrice?: number;
  discountApplied?: number;
  netPrice?: number;
  currencyCode: string;
  quantity?: number;
  leadTime?: number;
  productLineCode: string;
  options: Array<ISifOption>;
  fullProductNumber: string;
}

export class SifRecord implements ISifRecord {
  productNumber: string;
  productDescription: string;
  listPrice?: number;
  discountApplied?: number;
  netPrice?: number;
  currencyCode: string;
  quantity?: number;
  leadTime?: number;
  productLineCode: string;
  options: Array<SifOption> = [];
  fullProductNumber: string;

  constructor(data?: ISifRecord) {
    if (!data) {
      return;
    }

    _.assign(this, data);
  }
}

export interface ISifOption {
  optionNumber: string;
  optionDescription: string;
}

export class SifOption implements ISifOption {
  optionNumber: string;
  optionDescription: string;

  constructor(data?: ISifOption) {
    if (!data) {
      return;
    }

    _.assign(this, data);
  }
}
