import { DigitalAssetManagerModalDataSource } from './../digital-asset-manager-modal.datasource';
import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { DamAssetMetapropertyOption } from 'src/app/core/models/dam-asset-metaproperty-option.model';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../../app-store/reducers';
import { MediaSubType } from 'src/app/core/models/media-subtype.model';

@Component({
  selector: 'app-image-url',
  templateUrl: './image-url.component.html',
  styleUrls: ['./image-url.component.scss']
})
export class ImageUrlComponent implements OnInit {
  @Input() datasource: DigitalAssetManagerModalDataSource;
  @Input() mediaSubTypes: MediaSubType;
  @Input() productImages: boolean;

  constructor(private store: Store<fromStore.State>) { }

  ngOnInit() {
    this.enableControls();
  }

  enableControls() {
    this.datasource.addImageUrlFormGroup.controls.title.enable();
    this.datasource.addImageUrlFormGroup.controls.altText.enable();
    this.datasource.addImageUrlFormGroup.controls.mediaSubType.enable();
    this.datasource.addImageUrlFormGroup.controls.imageUrl.enable();
  }
}
