import { Action } from '@ngrx/store';
import { SubcategoryPost } from 'src/app/core/models/subcategory-post.model';
import { SubcategoryPut } from 'src/app/core/models/subcategory-put.model';

export enum SubcategoryActionTypes {
  LoadSubcategory = '[Subcategory] Load Subcategory',
  CreateSubcategory = '[Subcategory] Create Subcategory',
  UpdateSubcategory = '[Subcategory] Update Subcategory',
  RenameSubcategory = '[Subcategory] Rename Subcategory',
  DeleteSubcategory = '[Subcategory] Delete Subcategory',
  ClearMessages = '[Subcategory] Clear Messages',
}

export class LoadSubcategory implements Action {
  readonly type = SubcategoryActionTypes.LoadSubcategory;

  constructor(public payload: number) { }
}

export class CreateSubcategory implements Action {
  readonly type = SubcategoryActionTypes.CreateSubcategory;

  constructor(public payload: SubcategoryPost) { }
}

export class UpdateSubcategory implements Action {
  readonly type = SubcategoryActionTypes.UpdateSubcategory;

  constructor(public payload: { subcategory: SubcategoryPut, subcategoryName: string }) { }
}

export class RenameSubcategory implements Action {
  readonly type = SubcategoryActionTypes.RenameSubcategory;

  constructor(public payload: { subcategoryId: number, subcategoryName: string }) { }
}

export class DeleteSubcategory implements Action {
  readonly type = SubcategoryActionTypes.DeleteSubcategory;

  constructor(public payload: { subcategoryId: number, subcategoryName: string }) { }
}

export class ClearMessages implements Action {
  readonly type = SubcategoryActionTypes.ClearMessages;

  constructor() { }
}

export type SubcategoryActionsUnion =
  LoadSubcategory
  | CreateSubcategory
  | UpdateSubcategory
  | RenameSubcategory
  | DeleteSubcategory
  | ClearMessages;
