<div class="sidebar-collection-container scrollbar">

  <div>
    <mat-form-field class="sidebar-search-input-box" appearance="outline">
      <input
        (click)="$event.stopPropagation()"
        matInput
        placeholder="Search..."
        autocomplete="off"
        #input>
      <mat-icon
        *ngIf="!globalConfigurationsLoading"
        matSuffix>search</mat-icon>
      <mat-icon
        class="loading"
        *ngIf="globalConfigurationsLoading"
        matSuffix>sync</mat-icon>
    </mat-form-field>
  </div>

  <div class="sidebar-details-header">
    <span
      *ngIf="page && searchTerm.length > 0 && !globalConfigurationsLoading"
      class="sidebar-details-header-text">
      Now showing {{ page.results?.length }} of {{ page.totalRecordCount }} for <em>'{{ searchTerm }}'</em>
    </span>
    <span
      *ngIf="page && searchTerm.length <= 0 && !globalConfigurationsLoading"
      class="sidebar-details-header-text">
      Now showing {{ page.results?.length }} of {{ page.totalRecordCount }} total configurations
    </span>
    <span *ngIf="globalConfigurationsLoading">
      <em>Loading...</em>
    </span>
  </div>

  <div *ngIf="globalConfigurationsLoaded && !globalConfigurationsLoading">
    <div *ngFor="let globalConfiguration of page.results">
      <div
        class="sidebar-configuration-container"
        [ngClass]="{'selectedConfiguration': isSelectionEnabled && selectedGlobalConfigurationId === globalConfiguration.id}"
        (click)="navigateToConfiguration(globalConfiguration.id);">
        <div class="sidebar-configuration-container-left">
          <div
            class="sidebar-name-container"
            [matTooltip]="globalConfiguration.name">
            <span class="sidebar-configuration-name">{{ globalConfiguration.name }}</span>
          </div>
          <span
            *ngFor="let globalConfigurationProductNumber of globalConfiguration.productNumbers"
            class="sidebar-template-name">
            {{ globalConfigurationProductNumber?.productNumber.fullProductNumber }}
          </span>
        </div>

        <div class="sidebar-configuration-container-right">
          <!-- Status is removed till the feature is added in. NOTE: Commented code needs to be made dynamic -->
          <!-- <div class="pill status-published">
            <div class="pill-text">
                Published
            </div>
          </div> -->
        </div>
      </div>
      <mat-divider></mat-divider>
    </div>

    <div *ngIf="this.page?.results?.length === 0">
      <div class="sidebar-message-container">
        <span class="sidebar-message">No Configurations</span>
      </div>
    </div>
  </div>

  <div *ngIf="!globalConfigurationsLoaded && !globalConfigurationsLoading">
    <div class="sidebar-message-container">
      <span class="sidebar-message">Failed to Load Configurations.</span>
    </div>
  </div>
</div>
