import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { AuthService } from '../core/auth/auth.service';
import { UserIdentity } from '../core/auth/user-identity.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  appTitle = 'Helios';
  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private authService: AuthService) {
      this.appTitle = environment.appName;
     }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const token = params.token;
      if (!!token) {
        // token received, authenticate user
        this.authService.authenticate(token).subscribe(
          identity => {
            // authentication successful
            this.goToLanding();
          },
          error => {
            // Unexpected error authenticating, should have already been authenticated
            // through OneLogin but an error was encountered trying to access the user's details
            // set flags and go to error page
            this.goToError();
          });
      } else {
        // returning to the site without a token, check if logged in
        if (this.authService.isAuthenticated()) {
          // logged in, go to landing page
          this.goToLanding();
        } else {
          // not logged in, return to OneLogin
          this.authService.logout(true);
        }
      }
    });
  }

  goToLanding() {
    const areas = this.authService.getAreas();
    if (areas.length > 0) {
      this.router.navigate([areas[0].url]);
    } else {
      // no access, display error page
      this.goToError('noAccess');
    }
  }

  goToError(errorType?: string) {
    if (errorType) {
      this.router.navigate(['/error'], { queryParams: { type: errorType } });
    } else {
      this.router.navigate(['/error']);
    }
  }

}
