<div fxLayout="row" class="ghost-container">
  <img
    class="ghost-image"
    src="../../../../assets/images/placeholders/product_placeholder.svg" />
  <div fxFlex fxLayout="column" class="ghost-content-container align-center-vertical">
    <div fxLayout="row">
      <div fxFlex="50%" class="ghost-title lines"></div>
    </div>
    <div fxFlex="row">
      <div fxFlex="75%" class="lines"></div>
    </div>
  </div>
</div>
