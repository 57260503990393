import * as _ from 'lodash';
import { IBaseAuditModel, BaseAuditModel } from './base-audit.model';
import { EndpointSummary } from './endpoint-summary.model';

export interface IEndpointsSummary extends IBaseAuditModel {
  name: string;
  iconLink: string;
  endpoints: EndpointSummary[];
}

export class EndpointsSummary extends BaseAuditModel {
  name: string;
  iconLink: string;
  endpoints: EndpointSummary[];

  constructor(data?: IEndpointsSummary) {
    if (!data) {
      return;
    }

    super(data);
    _.assign(this, data);
  }
}
