import { EndpointProductOfferingTagPost, EndpointProductOfferingTagPut, EndpointProductOfferingTagRelationshipDelete, EndpointProductOfferingTagRelationshipPost } from './../../../core/models/endpoint-product-offering-tag.model';
import { Action } from '@ngrx/store';
import { IRemaps } from 'src/app/core/models/remaps.model';

export enum EndpointActionTypes {
  LoadEndpoint = '[Endpoint] Load Endpoint',
  LoadEndpointRemaps = '[Endpoint] Load Endpoint Remaps',
  LoadCategories = '[Endpoint] Load Categories',
  UpdateRemaps = '[Endpoint] Update Remaps',
  ClearMessages = '[Endpoint] Clear Messages',

  LoadOfferingTags = '[Endpoint] Load Offering Tags',
  CreateOfferingTag = '[Endpoint] Create Endpoint Offering Tag',
  UpdateOfferingTag = '[Endpoint] Update Endpoint Offering Tag',
  DeleteOfferingTag = '[Endpoint] Delete Endpoint Offering Tag',
}

export class LoadEndpoint implements Action {
  readonly type = EndpointActionTypes.LoadEndpoint;

  constructor(public payload: { endpointId: number }) { }
}

export class LoadEndpointRemaps implements Action {
  readonly type = EndpointActionTypes.LoadEndpointRemaps;

  constructor(public payload: { endpointId: number }) { }
}

export class LoadCategories implements Action {
  readonly type = EndpointActionTypes.LoadCategories;

  constructor(public payload: { endpointId: number }) { }
}

export class UpdateRemaps implements Action {
  readonly type = EndpointActionTypes.UpdateRemaps;

  constructor(public payload: { endpointId: number, remaps: IRemaps }) { }
}

export class ClearMessages implements Action {
  readonly type = EndpointActionTypes.ClearMessages;

  constructor() { }
}

export class LoadOfferingTags implements Action {
  readonly type = EndpointActionTypes.LoadOfferingTags;

  constructor(public payload: number) { }
}

export class CreateOfferingTag implements Action {
  readonly type = EndpointActionTypes.CreateOfferingTag;

  constructor(public payload: EndpointProductOfferingTagPost) { }
}

export class UpdateOfferingTag implements Action {
  readonly type = EndpointActionTypes.UpdateOfferingTag;

  constructor(public payload: EndpointProductOfferingTagPut) { }
}

export class DeleteOfferingTag implements Action {
  readonly type = EndpointActionTypes.DeleteOfferingTag;

  constructor(public payload: { endpointId: number, endpointProductOfferingTagId: number }) { }
}

export type EndpointActionsUnion =
    LoadEndpoint
  | LoadEndpointRemaps
  | LoadCategories
  | UpdateRemaps
  | ClearMessages
  | LoadOfferingTags
  | CreateOfferingTag
  | UpdateOfferingTag
  | DeleteOfferingTag;
