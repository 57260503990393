import { Locale } from './locale.model';
import * as _ from 'lodash';

export interface IDocumentAsset {
  url: string;
  title: string;
  documentType: DocumentAssetType;
  locales: Array<Locale>;
}

export class DocumentAsset implements IDocumentAsset {
  url: string;
  title: string;
  documentType: DocumentAssetType;
  locales: Array<Locale>;

  constructor(data?: IDocumentAsset) {
    if (!data) {
      return;
    }

    _.assign(this, data);
  }
}

export interface IDocumentAssetType {
  id: number;
  name: string;
}

export class DocumentAssetType implements IDocumentAssetType {
  id: number;
  name: string;

  constructor(data?: IDocumentAssetType) {
    if (!data) {
      return;
    }

    _.assign(this, data);
  }
}
