<div fxLayout="row" class="card-content card-content-image">
  <div fxFlex class="card-image-container">
    <ng-container *ngIf="attributeImage; else attributeImageElse">
      <img class="card-image" src="{{attributeImage}}" alt="{{getImageFileName(attributeImage)}}"
        (click)="showLightbox(0)" onerror="this.src='../../../../assets/images/placeholders/image_placeholder.png'">
    </ng-container>
    <ng-template #attributeImageElse>
      <img class="card-image card-empty-image" src="../../../../assets/images/placeholders/image_placeholder.png"
        alt="Placeholder image">
    </ng-template>
  </div>
  <div fxFlex fxLayout="column" class="align-center-vertical">
    <div fxFlex><strong>{{attributeOption?.name}}</strong></div>
    <ng-container *ngIf="attributeOption?.shortDescription; else attributeDescriptionElse">
      <div fxFlex class="card-data-name">
        <app-card-content-string-multiline-readonly [value]="attributeOption?.shortDescription" truncate="true"
          [truncateAt]="60">
        </app-card-content-string-multiline-readonly>
      </div>
    </ng-container>
    <ng-template #attributeDescriptionElse>
      <div fxFlex class="none">None</div>
    </ng-template>
  </div>
  <div fxFlex class="card-details align-center-vertical align-right">
    <ng-container *ngIf="attributeOption?.isInDraft">
      <mat-icon fxFlex class="draft-icon" matTooltip="In Draft">assignment</mat-icon>
    </ng-container>
    <ng-container *ngIf="attributeOption?.name">
      <a fxFlex mat-button disableRipple (click)="openAttributeOptionModal()">
        Details
        <mat-icon class="arrow-icon">
          keyboard_arrow_right
        </mat-icon>
      </a>
    </ng-container>
  </div>
</div>
