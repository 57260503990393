import { UserRoles } from './../../shared/constants';
import { UserRole } from './../models/user-role.model';
import { UserRoleIntegration } from '../models/user-role-integration.model';
import { UserIdentity } from './user-identity.model';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { union as _union } from 'lodash-es';

export class RoleNames {
  public static readonly ROLE_ADMIN = 'Admin';
  public static readonly ROLE_APPLICATION_ADMIN = 'Application Admin';
  public static readonly ROLE_INTEGRATION_ADMIN = 'Integration Admin';
  public static readonly ROLE_INTEGRATION_SPECIALIST = 'Integration Specialist';
  public static readonly ROLE_PLATFORM_ADMIN = 'Platform Admin';
  public static readonly ROLE_PRODUCT_ADMIN = 'Product Admin';
  public static readonly ROLE_READ_ONLY = 'Read Only';
}

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private auth: AuthService) { }

  public getRolesForCurrentUser(): string[] {
    const identity = this.auth.getIdentity();
    return identity.userRoles.map((r) => r.role.name);
  }

  public isAdmin(): boolean {
    const identity = this.auth.getIdentity();
    return this.isIdentityInRole(identity, RoleNames.ROLE_ADMIN);
  }

  public isProductAdmin(): boolean {
    const identity = this.auth.getIdentity();
    return this.isIdentityInRole(identity, RoleNames.ROLE_PRODUCT_ADMIN);
  }

  public isIntegrationAdmin(): boolean {
    const identity = this.auth.getIdentity();
    return this.isIdentityInRole(identity, RoleNames.ROLE_INTEGRATION_ADMIN);
  }

  public isIntegrationSpecialist(): boolean {
    const identity = this.auth.getIdentity();
    return this.isIdentityInRole(identity, RoleNames.ROLE_INTEGRATION_SPECIALIST);
  }

  public isApplicationAdmin(): boolean {
    const identity = this.auth.getIdentity();
    return this.isIdentityInRole(identity, RoleNames.ROLE_APPLICATION_ADMIN);
  }

  public isPlatformAdmin(): boolean {
    const identity = this.auth.getIdentity();
    return this.isIdentityInRole(identity, RoleNames.ROLE_PLATFORM_ADMIN);
  }

  // All users are, at the very least, read only.
  public isReadOnly(): boolean {
    return true;
  }

  // Returns false if the user is not an integration role. Returns true none of the integration ids are present in the user's accepted integration role list.
  public isIntegrationRoleInvalidForIntegrationIds(integrationIds: Array<number>): boolean {
    // If the user is not a integration role, then they cannot have invalid integration ids.
    if (!this.isIntegrationRole()) {
      return false;
    }

    const hasValidIntegrationId: boolean = integrationIds.some((integrationId: number) => this.isIntegrationRoleValidForIntegrationId(integrationId));
    if (hasValidIntegrationId) {
      return false;
    }

    return true;
  }

  // Returns false if the user is not an integration role. Returns true if the integration id is not present in the user's accepted integration role list.
  public isIntegrationRoleInvalidForIntegrationId(integrationId: number): boolean {
    // If the user is not a integration role, then they cannot have invalid integration ids.
    if (!this.isIntegrationRole()) {
      return false;
    }

    // the user is specifically valid for this integration.
    if (this.isIntegrationRoleValidForIntegrationId(integrationId)) {
      return false;
    }

    return true;
  }

  // If the current role's permissions are based on what integrations they're allowed to manage, this returns true.
  public isIntegrationRole(): boolean {
    if (this.isIntegrationSpecialist() || this.isIntegrationAdmin()) {
      return true;
    }

    return false;
  }

  public isIntegrationRoleById(roleId: number) {
    if (roleId === UserRoles.IntegrationAdmin || roleId === UserRoles.IntegrationSpecialist) {
      return true;
    }

    return false;
  }

  // If the user's role isn't an integration role, this returns with an empty array.
  public getIntegrationIds(): Array<number> {
    let integrationIds = [];
    if (!this.isIntegrationRole()) {
      return integrationIds;
    }

    const userRoles: Array<UserRole> = this.getIntegrationRoles();
    if (!userRoles || userRoles.length <= 0) {
      return integrationIds;
    }

    // get unique integration ids from all roles.
    userRoles.forEach((userRole: UserRole) => {
      const userRoleIntegrationIds = userRole.userRoleIntegrations.map(x => x.integrationId);
      if (userRole.userRoleIntegrations?.length > 0) {
        integrationIds = _union(integrationIds, userRoleIntegrationIds);
      }
    });

    return integrationIds;
  }

  // Returns true if the integration id supplied is valid for the current user.
  private isIntegrationRoleValidForIntegrationId(integrationId: number): boolean {
    const userRoles: Array<UserRole> = this.getIntegrationRoles();
    if (userRoles === null || userRoles === undefined || userRoles.length <= 0) {
      return false;
    }

    let isRoleValid = false;
    userRoles.forEach((role: UserRole) => {
      const hasIntegration = role.userRoleIntegrations.some((p: UserRoleIntegration) => p.integration.id === integrationId);
      if (hasIntegration) {
        isRoleValid = true;
      }
    });

    return isRoleValid;
  }

  // Returns an integration role if one exists on the user.  Returns null if no integration role exists.
  private getIntegrationRoles(): Array<UserRole> | null {
    const identity = this.auth.getIdentity();
    const userRoles: Array<UserRole> = identity.userRoles.filter((u: UserRole) =>
      u.role.name === RoleNames.ROLE_INTEGRATION_SPECIALIST || u.role.name === RoleNames.ROLE_INTEGRATION_ADMIN
    );

    return userRoles;
  }

  // Returns true if the identity belongs to the role name supplied.
  private isIdentityInRole(identity: UserIdentity, role: string): boolean {
    if (identity) {
      return identity.userRoles.some((u: UserRole) => u.role.name === role);
    }

    return false;
  }
}
