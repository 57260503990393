import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import * as fromStore from '../../reducers';

import {
  FamilyApiActions,  
  FamilyActions
} from '../actions';

import { switchMap, map, catchError, tap, mergeMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { FamiliesService } from 'src/app/core/services/families.service';

@Injectable()
export class FamilyEffects {

  @Effect()
  loadFamily$ = this.actions$.pipe(
    ofType(FamilyActions.FamilyActionTypes.LoadFamily),
    map((action: FamilyActions.LoadFamily) => action.payload),
    switchMap(familyId =>
      this.familiesService.getFamily$(familyId).pipe(
        map(
          (family) => new FamilyApiActions.LoadSuccess(family)
        ),
        catchError(error =>
          of(new FamilyApiActions.LoadFailure(error))
        )
      )
    )
  );

  @Effect()
  createFamily$ = this.actions$.pipe(
    ofType<FamilyActions.CreateFamily>(FamilyActions.FamilyActionTypes.CreateFamily),
    switchMap((request) =>
      this.familiesService.createFamily$(request.payload).pipe(
        map((family) =>
          new FamilyApiActions.CreateSuccess(family)
        ),
        tap((response) => {
          // Navigate to the page in edit mode.
          this.router.navigate([`products/organizational-data/family-detail/${response.payload.id}`], { queryParams: { edit: true } });
        }),
        catchError(error =>
          of(new FamilyApiActions.CreateFailure(error))
        )
      )
    )
  );

  @Effect()
  deleteFamily$ = this.actions$.pipe(
    ofType<FamilyActions.DeleteFamily>(FamilyActions.FamilyActionTypes.DeleteFamily),
    switchMap((request) =>
      this.familiesService.deleteFamily$(request.payload.familyId).pipe(
        map((response) =>
          new FamilyApiActions.DeleteSuccess({ familyId: request.payload.familyId, familyName: request.payload.familyName })
        ),
        tap((response) => {
          // Navigate to all families page
          this.router.navigate([`products/organizational-data/families`]);
        }),
        catchError(error =>
          of(new FamilyApiActions.DeleteFailure(error))
        )
      )
    )
  );

  @Effect()
  updateFamily$: Observable<Action> = this.actions$.pipe(
    ofType<FamilyActions.UpdateFamily>(FamilyActions.FamilyActionTypes.UpdateFamily),
    switchMap(request =>
      this.familiesService.updateFamily$(request.payload.family).pipe(
        map((response) => new FamilyApiActions.UpdateSuccess({ family: request.payload.family, familyName: request.payload.familyName })),
        catchError(error => of(new FamilyApiActions.UpdateFailure(error)))
      )
    )
  );

  @Effect()
  updateFamilySuccess$: Observable<Action> = this.actions$.pipe(
    ofType(FamilyApiActions.FamilyApiActionTypes.UpdateSuccess),
    map((action: FamilyApiActions.UpdateSuccess) => action.payload),
    map(payload =>
      // Reload the family from the API if the save was successful.
      new FamilyActions.LoadFamily(payload.family.id)
    )
  );

  @Effect()
  renameFamily$: Observable<Action> = this.actions$.pipe(
    ofType<FamilyActions.RenameFamily>(FamilyActions.FamilyActionTypes.RenameFamily),
    switchMap((request) =>
      this.familiesService.renameFamily$({
        familyId: request.payload.familyId,
        familyName: request.payload.familyName
      }).pipe(
        map(_ => new FamilyApiActions.RenameSuccess({
          familyId: request.payload.familyId,
          familyName: request.payload.familyName
        })
        ),
        tap(res => this.store.dispatch(new FamilyActions.LoadFamily(request.payload.familyId)),
          catchError(error =>
            of(new FamilyApiActions.RenameFailure(error)),
          )
        )
      )
    )
  );



  

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<fromStore.State>,
    private familiesService: FamiliesService) { }

}
