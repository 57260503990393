<div>
  <div fxLayout="column" fxFlex class="align-center-vertical align-right">
    <ng-container *ngIf="categories.length > 0; else categoriesElse">
      <ng-container *ngFor="let category of categories">
        <div fxFlex class="list-title">{{category.name}}</div>
        <ng-container *ngFor="let subcategory of getSubcategories(category.id)">
          <div fxFlex class="list-item">{{subcategory.name}}</div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <ng-template #categoriesElse>
    <div fxFlex class="none align-center-vertical align-right">None</div>
  </ng-template>
</div>
