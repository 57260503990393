import { assign as _assign } from 'lodash-es';
export interface IRenamePut {
  name: string;
}

export class RenamePut implements IRenamePut {
  name = '';
  constructor(data?: IRenamePut) {
    if (data) {
      _assign(this, data);
    }
  }
}
