import {
  FamilyApiActions,
  FamilyActions
} from '../actions';

import { Family } from 'src/app/core/models/family.model';
import { FamilyStatus } from 'src/app/shared/constants';

export interface State {
  family: Family;
  loading: boolean;
  loaded: boolean;
  status: FamilyStatus;
  error: string;
  success: string;
}

export const initialState: State = {
  family: null,
  loading: false,
  loaded: false,
  status: FamilyStatus.None,
  error: null,
  success: null
};

export function reducer(state = initialState, action: FamilyActions.FamilyActionsUnion | FamilyApiActions.FamilyApiActionsUnion): State {
  switch (action.type) {

    case FamilyActions.FamilyActionTypes.LoadFamily: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case FamilyApiActions.FamilyApiActionTypes.LoadSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        family: action.payload
      };
    }

    case FamilyApiActions.FamilyApiActionTypes.LoadFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        family: null,
        error: action.payload
      };
    }

    case FamilyActions.FamilyActionTypes.ClearMessages: {
      return {
        ...state,
        status: FamilyStatus.None,
        error: null,
        success: null
      };
    }

    case FamilyActions.FamilyActionTypes.CreateFamily: {
      return {
        ...state,
      };
    }

    case FamilyApiActions.FamilyApiActionTypes.CreateSuccess: {
      return {
        ...state,
        family: action.payload,
        status: FamilyStatus.CreateSuccess,
      };
    }

    case FamilyApiActions.FamilyApiActionTypes.CreateFailure: {
      return {
        ...state,
        family: null,
        status: FamilyStatus.CreateFailure,
        error: 'Failed to Create Family',
        success: null
      };
    }

    case FamilyActions.FamilyActionTypes.UpdateFamily: {
      return {
        ...state,
        loading: true,
        loaded: false,
        status: FamilyStatus.None
      };
    }

    case FamilyApiActions.FamilyApiActionTypes.UpdateSuccess: {
      return {
        ...state,
        status: FamilyStatus.UpdateSuccess,
        error: null,
        success: `Successfully Updated Family: ${action.payload.familyName}`
      };
    }

    case FamilyApiActions.FamilyApiActionTypes.UpdateFailure: {
      return {
        ...state,
        loading: false,
        loaded: true,
        status: FamilyStatus.UpdateFailure,
        error: 'Failed to Update Family',
        success: null
      };
    }

    case FamilyActions.FamilyActionTypes.RenameFamily: {
      return {
        ...state
      };
    }

    case FamilyApiActions.FamilyApiActionTypes.RenameSuccess: {
      const family = { ...state.family };
      family.name = action.payload.familyName;

      return {
        ...state,
        status: FamilyStatus.RenameSuccess,
        error: null,
        success: `Successfully renamed Family: ${action.payload.familyName}`
      };
    }

    case FamilyApiActions.FamilyApiActionTypes.RenameFailure: {
      return {
        ...state,
        family: null,
        status: FamilyStatus.RenameFailure,
        error: 'Failed to rename Family',
        success: null
      };
    }

    case FamilyActions.FamilyActionTypes.DeleteFamily: {
      return {
        ...state,
      };
    }

    case FamilyApiActions.FamilyApiActionTypes.DeleteSuccess: {
      return {
        ...state,
        status: FamilyStatus.DeleteSuccess,
        error: null,
        success: `Successfully Deleted Family: ${action.payload.familyName}`
      };
    }

    case FamilyApiActions.FamilyApiActionTypes.DeleteFailure: {
      return {
        ...state,
        family: null,
        status: FamilyStatus.DeleteFailure,
        error: 'Failed to Delete Family',
        success: null
      };
    }

    default:
      return state;
  }
}

export const getFamily = (state: State) => state.family;
export const getErrorMessage = (state: State) => state.error;
export const getSuccessMessage = (state: State) => state.success;
export const getStatus = (state: State) => state.status;
export const isLoading = (state: State) => state.loading;
export const isLoaded = (state: State) => state.loaded;
