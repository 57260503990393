import { Action } from '@ngrx/store';
import { PortfolioPost } from 'src/app/core/models/portfolio-post.model';
import { PortfolioPut } from 'src/app/core/models/portfolio-put.model';

export enum PortfolioActionTypes {
  LoadPortfolio = '[Portfolio] Load Portfolio',
  CreatePortfolio = '[Portfolio] Create Portfolio',
  UpdatePortfolio = '[Portfolio] Update Portfolio',
  RenamePortfolio = '[Portfolio] Rename Portfolio',
  DeletePortfolio = '[Portfolio] Delete Portfolio',
  ClearMessages = '[Portfolio] Clear Messages',
}

export class LoadPortfolio implements Action {
  readonly type = PortfolioActionTypes.LoadPortfolio;

  constructor(public payload: number) { }
}

export class CreatePortfolio implements Action {
  readonly type = PortfolioActionTypes.CreatePortfolio;

  constructor(public payload: PortfolioPost) { }
}

export class UpdatePortfolio implements Action {
  readonly type = PortfolioActionTypes.UpdatePortfolio;

  constructor(public payload: { portfolio: PortfolioPut, portfolioName: string }) { }
}

export class RenamePortfolio implements Action {
  readonly type = PortfolioActionTypes.RenamePortfolio;

  constructor(public payload: { portfolioId: number, portfolioName: string }) { }
}

export class DeletePortfolio implements Action {
  readonly type = PortfolioActionTypes.DeletePortfolio;

  constructor(public payload: { portfolioId: number, portfolioName: string }) { }
}

export class ClearMessages implements Action {
  readonly type = PortfolioActionTypes.ClearMessages;

  constructor() { }
}

export type PortfolioActionsUnion =
  LoadPortfolio
  | CreatePortfolio
  | UpdatePortfolio
  | RenamePortfolio
  | DeletePortfolio
  | ClearMessages;
