import { Locale } from 'src/app/core/models/locale.model';
import { FlagsService } from '../../../core/services/flags-service.service';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-title-flag-image',
  templateUrl: './card-title-flag-image.component.html',
  styleUrls: ['./card-title-flag-image.component.scss']
})
export class CardTitleFlagImageComponent {
  @Input() selectedLocale: Locale;
  @Input() tooltip: string;

  constructor(
    private flagsService: FlagsService
  ) { }

  public getLocaleFlagImage(locale: Locale): string {
    return this.flagsService.getFlagFileName(locale.name);
  }

}
