import { Brand, IBrand } from './../models/brand.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { PagedResults } from '../models/page-results.model';
import { BrandPost } from '../models/brand-post.model';
import { BrandSummary } from '../models/brand-summary.model';
import { BrandPut } from '../models/brand-put.model';
import { RenamePut } from '../models/rename-put.model';
@Injectable({
  providedIn: 'root'
})
export class BrandsService {
  basePath = 'brands';
  constructor(private http: HttpClient) {
  }

  getBrands$(searchTerm: string = '', pageNumber: number, pageSize: number, sortActive: string, sortOrder: string): Observable<PagedResults<BrandSummary>> {
    return this.http.get(`${environment.apiUrl}${this.basePath}`, {
      params: new HttpParams()
        .set('search', searchTerm)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
        .set('orderBy', sortActive.toString())
        .set('sortOrder', sortOrder.toString())
    }).pipe(
      map((response: PagedResults<BrandSummary>) => response)
    );
  }

  getBrand$(id: number): Observable<Brand> {
    console.log(`getBrand$(${id})`);
    return this.http
      .get(environment.apiUrl + `${this.basePath}/${id}`)
      .pipe(map(response => response)
        , map((x: IBrand) => new Brand(x)));
  }

  createBrand$(brand: BrandPost): Observable<Brand> {
    console.log(`createBrand(${brand})`);

    return this.http
      .post(environment.apiUrl + `${this.basePath}`, brand)
      .pipe(map(res => res)
        , catchError((err: Response) => {
          const obj = err;
          return observableThrowError(obj);
        })
        , map((x: IBrand) => new Brand(x)));
  }

  updateBrand$(brand: BrandPut): Observable<any> {
    console.log(`updateBrand(${brand})`);

    return this.http
      .put(`${environment.apiUrl}${this.basePath}/${brand.id}`, brand)
      .pipe(map(response => response),
        catchError((err: Response) => {
          console.log('Error on brand update:');
          console.log(err);
          return observableThrowError(err);
        })
      );
  }

  renameBrand$(request: { brandId: number, brandName: string }): Observable<any> {
    const renamePut: RenamePut = new RenamePut({ name: request.brandName });
    return this.http
      .put(environment.apiUrl + `${this.basePath}/${request.brandId}/rename`,
        renamePut,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
      );
  }

  deleteBrand$(brandId: number): Observable<any> {
    return this.http.delete(environment.apiUrl + `${this.basePath}/${brandId}`)
      .pipe(
        map(res => res),
        catchError((err: Response) => {
          const obj = err;
          return observableThrowError(obj);
        })
      );
  }

  getDuplicates$(brandName: string): Observable<Array<BrandSummary>> {
    const request: BrandPost = new BrandPost();
    request.name = brandName;

    return this.http
      .post(`${environment.apiUrl}${this.basePath}/duplicates`, request)
      .pipe(
        map(response => response),
        catchError((error: Response) => {
          return observableThrowError(error);
        }),
        map((response: Array<BrandSummary>) => response)
      );
  }
}
