import { IntegrationProductOfferingSummary } from './../../../core/models/integration-product-offering-summary.model';
import { GlobalProductOffering } from 'src/app/core/models/global-product-offering.model';
import { Action } from '@ngrx/store';
import { GlobalProductOfferingPut } from 'src/app/core/models/global-product-offering-put.model';

export enum ProductOfferingApiActionTypes {
  LoadSuccess = '[ProductOffering/API] Fetch Success',
  LoadFailure = '[ProductOffering/API] Fetch Failure',
  LoadForProductOfferingSuccess = '[ProductOffering/API] Fetch For Product Offering Success',
  LoadForProductOfferingFailure = '[ProductOffering/API] Fetch For Product Offering Failure',
  CreateSuccess = '[ProductOffering/API] Create Success',
  CreateFailure = '[ProductOffering/API] Create Failure',
  UpdateSuccess = '[ProductOffering/API] Update Success',
  UpdateFailure = '[ProductOffering/API] Update Failure',
  DeleteSuccess = '[ProductOffering/API] Delete Success',
  DeleteFailure = '[ProductOffering/API] Delete Failure',
  RenameSuccess = '[ProductOffering/API] Rename Success',
  RenameFailure = '[ProductOffering/API] Rename Failure',
}

export class LoadSuccess implements Action {
  readonly type = ProductOfferingApiActionTypes.LoadSuccess;

  constructor(public payload: GlobalProductOffering) { }
}

export class LoadFailure implements Action {
  readonly type = ProductOfferingApiActionTypes.LoadFailure;

  constructor(public payload: any) { }
}

export class LoadForProductOfferingSuccess implements Action {
  readonly type = ProductOfferingApiActionTypes.LoadForProductOfferingSuccess;

  constructor(public payload: IntegrationProductOfferingSummary[]) { }
}

export class LoadForProductOfferingFailure implements Action {
  readonly type = ProductOfferingApiActionTypes.LoadForProductOfferingFailure;

  constructor(public payload: any) { }
}

export class CreateSuccess implements Action {
  readonly type = ProductOfferingApiActionTypes.CreateSuccess;

  constructor(public payload: GlobalProductOffering) { }
}

export class CreateFailure implements Action {
  readonly type = ProductOfferingApiActionTypes.CreateFailure;

  constructor(public payload: any) { }
}

export class UpdateSuccess implements Action {
  readonly type = ProductOfferingApiActionTypes.UpdateSuccess;

  constructor(public payload: GlobalProductOfferingPut) { }
}

export class UpdateFailure implements Action {
  readonly type = ProductOfferingApiActionTypes.UpdateFailure;

  constructor(public payload: any) { }
}
export class DeleteSuccess implements Action {
  readonly type = ProductOfferingApiActionTypes.DeleteSuccess;

  constructor(public payload: GlobalProductOffering) { }
}

export class DeleteFailure implements Action {
  readonly type = ProductOfferingApiActionTypes.DeleteFailure;

  constructor(public payload: GlobalProductOffering, public error: any) { }
}

export class RenameSuccess implements Action {
  readonly type = ProductOfferingApiActionTypes.RenameSuccess;

  constructor(public payload: { offeringId: number, offeringName: string }) { }
}

export class RenameFailure implements Action {
  readonly type = ProductOfferingApiActionTypes.RenameFailure;

  constructor(public payload: any) { }
}

export type ProductOfferingApiActionsUnion =
  LoadSuccess
  | LoadFailure
  | LoadForProductOfferingSuccess
  | LoadForProductOfferingFailure
  | CreateSuccess
  | CreateFailure
  | UpdateSuccess
  | UpdateFailure
  | DeleteSuccess
  | DeleteFailure
  | RenameSuccess
  | RenameFailure;
