import { Language } from './language.model';
import { Currency } from './currency.model';
import { Locale } from './locale.model';
import { assign as _assign } from 'lodash-es';

export interface ILocaleSpecifier {
  localeId: number;
  locale: Locale;
  currencyId: number;
  currency: Currency;
  languageId: number;
  langauge: Language;
  uomType: number;
  uomStandards: number;
  uomSeparatorType: number;
}

export class LocaleSpecifier implements ILocaleSpecifier {
  localeId: number;
  locale: Locale;
  currencyId: number;
  currency: Currency;
  languageId: number;
  langauge: Language;
  uomType: number;
  uomStandards: number;
  uomSeparatorType: number;

  constructor(data?: ILocaleSpecifier) {
    if (data) {
      _assign(this, data);
    }
  }
}
