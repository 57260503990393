<header class="app-header">
  <div fxLayout="row" class="app-header-container">
    <div fxLayout="row" fxLayoutAlign="start center" class="app-brand">
        <img src="./assets/images/logo/logo.svg" (click)="goToRoot()"/>
        <mat-divider vertical class="divider"></mat-divider>
        <span>{{appTitle}}</span>
    </div>

    <nav class="app-navigation">
        <ul>
            <li *ngFor="let area of getAreas()" class="nav-item">
                <a [routerLink]="[area.url]" routerLinkActive="active">
                {{area.label}}
                </a>
            </li>
        </ul>
    </nav>

    <div class="app-user">
      <ng-container *ngIf="isAuthenticated()">
        <mat-menu #menu="matMenu">
            <div class="user-information-container">

              <div
              *ngIf="user?.userRoles as userRoles"
              [matTooltip]="userRolesTooltip(userRoles)"
              matTooltipClass="tooltip-multiline"
              class="user-role">
                <mat-icon>badge</mat-icon>
              </div>

              <div class="user-details">
                <div class="user-fullname">{{user.firstName + ' ' + user.lastName}}</div>
                <div class="user-email">{{user.email}}</div>
              </div>
            </div>
            <mat-divider></mat-divider>
            <div
              mat-menu-item
              class="app-user-menu-item"
              (click)="logout()">
              <mat-icon>logout</mat-icon>
              <span>Logout</span>
            </div>
        </mat-menu>
        <mat-icon class="material-icons" [matMenuTriggerFor]="menu">account_circle</mat-icon>
      </ng-container>
    </div>
  </div>
</header>
