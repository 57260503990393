import { environment } from './../../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilesService {
  basePath = 'files';

  constructor(private http: HttpClient) { }

  exists$(fileUrl: string): Observable<boolean> {
    return this.http
    .post(`${environment.apiUrl}${this.basePath}/exists`,
      `\"${fileUrl}\"`, // this notation is required for sending raw strings in the body.
      {headers: new HttpHeaders({'Content-Type': 'application/json'})})
    .pipe(
      map(response => response),
      catchError((err: Response) => {
        return throwError(err);
      }),
      map((response: boolean) => response)
    );
  }

  getName(fileUri: string): string {
    if (!fileUri) { return null; }
    return fileUri.substring(fileUri.lastIndexOf('/') + 1);
  }
}
