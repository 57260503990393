import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { IIntegration, Integration } from '../models/integration.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IntegrationSummary, IIntegrationSummary } from '../models/integration-summary.model';
import { RenamePut } from '../models/rename-put.model';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {
  basePath = 'integrations';

  constructor(private httpClient: HttpClient) { }

  getIntegrations$(): Observable<Array<IntegrationSummary>> {
    return this.httpClient
      .get(environment.apiUrl + `${this.basePath}`)
      .pipe(map(res => res)
        , catchError((err: Response) => {
          const json = err.json();
          return throwError(json);
        })
        , map((list: Array<IIntegrationSummary>) => list.map(m => new IntegrationSummary(m)))
      );
  }

  getIntegrationById$(id: number): Observable<Integration> {
    return this.httpClient
      .get(environment.apiUrl + `${this.basePath}/${id}`)
      .pipe(map(res => res)
        , catchError((err: Response) => {
          const json = err.json();
          return throwError(json);
        })
        , map((x: IIntegration) => new Integration(x))
      );
  }

  createIntegration$(integration: IIntegration): Observable<Integration> {
    return this.httpClient
      .post(environment.apiUrl + this.basePath, integration)
      .pipe(
        map(response => response),
        catchError((error: Response) => {
          const json = error.json();
          return throwError(json);
        }), map((x: IIntegration) => new Integration(x))
      );
  }

  updateIntegration$(integration: IIntegration): Observable<Integration> {
    const formData: FormData = new FormData();
    const integrationId: any = integration.id;
    // Icon Upload
    if (integration.iconUpload != null) {
      formData.append('iconFile', integration.iconUpload, integration.iconName);
    }
    // Product
    formData.append('integration', JSON.stringify(integration));

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    const params = integrationId;
    return this.httpClient
      .put<Integration>(`${environment.apiUrl + this.basePath}/${integrationId}`, formData, { headers, params })
      .pipe(map(response => response),
        catchError((error: Response) => {
          return throwError(error);
        }), map((x: IIntegration) => new Integration(x))
      );
  }

  renameIntegration$(request: { integrationId: number, integrationName: string }): Observable<any> {
    const renamePut: RenamePut = new RenamePut({ name: request.integrationName });
    return this.httpClient.put(environment.apiUrl + `${this.basePath}/${request.integrationId}/rename`,
      renamePut,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
    );
  }
}
