import { Component } from '@angular/core';

@Component({
  selector: 'app-ghost-sidebar-header',
  templateUrl: './ghost-sidebar-header.component.html',
  styleUrls: ['./ghost-sidebar-header.component.scss']
})
export class GhostSidebarHeaderComponent {

}
