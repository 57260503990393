import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FlagsService {

  constructor() {}

  getFlagFileName(localeName: string): string {
    let imageName: string;

    switch (localeName) {
      case 'United States':
        imageName = 'united-states';
        break;
      case 'Mexico – Spanish':
      case 'Mexico – English':
        imageName = 'mexico';
        break;
      case 'Brazil – Portuguese':
      case 'Brazil – English':
        imageName = 'brazil';
        break;
      case 'Canada':
        imageName = 'canada';
        break;
      case 'China':
        imageName = 'china';
        break;
      case 'France':
        imageName = 'france';
        break;
      case 'Germany':
        imageName = 'germany';
        break;
      case 'India':
        imageName = 'india';
        break;
      case 'Italy':
        imageName = 'italy';
        break;
      case 'Japan':
        imageName = 'japan';
        break;
      case 'Netherlands':
        imageName = 'netherlands';
        break;
      case 'United Kingdom':
        imageName = 'united-kingdom';
        break;
      case 'Australia':
        imageName = 'australia';
        break;
      default:
        imageName = 'region';
        break;
    }

    return imageName;
  }
}
