<div class="sidebar-dropdown-container">
  <div *ngIf="globalProductLoaded && !globalProductLoading && globalProduct">
    <div fxLayout="row">
      <div fxFlex (click)="navigateToProductDetail(globalProduct.id)">
        <img
          class="sidebar-product-image"
          *ngIf="thumbnailFrontImage; else placeholderImage"
          [src]="thumbnailFrontImage"
          onerror="this.src='../../../assets/images/placeholders/product_placeholder.svg'" />
        <ng-template #placeholderImage>
          <img
            class="sidebar-product-image"
            src="../../../assets/images/placeholders/product_placeholder.svg" />
        </ng-template>
        <div fxFlex fxLayout="column" class="sidebar-product-container align-center-vertical">
          <span>Product</span>
          <span class="sidebar-product-name">{{ globalProduct.name }}</span>
        </div>
      </div>
      <div
        class="dropdown-icon-container"
        [matMenuTriggerFor]="search"
        (click)="focusOnSearch()">
        <mat-icon class="dropdown-icon">
          keyboard_arrow_down
        </mat-icon>
      </div>
    </div>
  </div>

  <mat-menu
    xPosition="before"
    #search="matMenu"
    class="sidebar-product-search scrollbar">
    <div class="sidebar-product-search-container">
      <div class="sidebar-product-search-box-container" (click)="$event.stopPropagation()">
        <mat-form-field class="input-search" appearance="outline">
          <input
            (click)="$event.stopPropagation()"
            matInput
            placeholder="Search..."
            autocomplete="off"
            #input>
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div>
        <div
          *ngIf="searchLoading$ | async"
          class="sidebar-product-search-item sidebar-search-message">
          <span><em>Loading</em></span>
          <em><span class="ellipsis-animation"><span>.</span><span>.</span><span>.</span></span></em>
        </div>
        <div
          class="sidebar-product-search-item sidebar-search-message"
          *ngIf="(search$ | async).length === 0 && !(searchLoading$ | async)">
          <span><em>No Results</em></span>
        </div>
        <div class="sidebar-product-search-item"
          *ngFor="let product of (search$ | async)"
          (click)="navigateToProductDetail(product.id)">
          <div fxFlex fxLayout="row">
            <img class="sidebar-product-search-image"
              *ngIf="product.thumbnailImageLink1; else placeholderSearchItemImage"
              [src]="product.thumbnailImageLink1"
              onerror="this.src='../../../assets/images/placeholders/product_placeholder.svg'" />
            <ng-template #placeholderSearchItemImage>
              <img
                class="sidebar-product-search-image"
                src="../../../assets/images/placeholders/product_placeholder.svg" />
            </ng-template>
            <div class="sidebar-product-search-name align-center-vertical">
              <span>{{ product.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-menu>

  <div *ngIf="!globalProductLoaded && globalProductLoading && !globalProduct">
    <!-- <app-ghost-sidebar-header></app-ghost-sidebar-header> -->
    <div class="sidebar-spinner-container">
      <span class="sidebar-spinner">
        <mat-spinner color='accent' diameter="25"></mat-spinner>
      </span>
      <span class="spinner-message">Loading Product...</span>
    </div>
  </div>

  <div *ngIf="!globalProductLoaded && !globalProductLoading && !globalProduct">
    Failed to Load Product
  </div>
</div>
