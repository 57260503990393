<div class="cad-links-modal-window">
    <div mat-dialog-title>
        <h6 class="h6">
            <strong>Add a download link for {{ cadLinkTypeFlavorText }} model file(s)</strong>
        </h6>
    </div>
    <div mat-dialog-content class="cad-links-modal-window-content" >
        <mat-tab-group class="cad-links-modal-window-content-tabs" [(selectedIndex)]="selectedTabIndex">
            <mat-tab label="Search" [disabled]="!searchableLinkType">
                <app-cad-links-search 
                    [datasource]=datasource
                    [relatedProductNumbers]=relatedProductNumbers >
                </app-cad-links-search>
            </mat-tab>
            <mat-tab label="Manual Entry" >
                <app-cad-links-manual-entry
                    [desiredLinkType]="datasource.desiredLinkType"
                    [existingCadLinkUrl]="existingCadLinkUrl"
                    (newValidatedCadLinkUrl)="updateValidCadLinkUrl($event)">
                </app-cad-links-manual-entry>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div mat-dialog-actions class="cad-links-modal-window-actions">
        <div fxFlex class="align-center-vertical" *ngIf="selectedTabIndex === 1 && updatedValidCadLinkUrl" >
            <a [href]="updatedValidCadLinkUrl" target="_blank" mat-icon-button disableRipple matTooltip="Download the file(s)">
                <mat-icon class="subdue-icon">cloud_download</mat-icon>
            </a>
        </div>
        <button mat-raised-button disableRipple mat-dialog-close>Cancel</button>
        <div *ngIf="selectedTabIndex === 1">
            <button 
                class="mat-raised-button primary-button"
                mat-raised-button 
                disableRipple 
                [mat-dialog-close]="updatedValidCadLinkUrl" 
                [disabled]="disableApplyButton">Apply</button>
        </div>
    </div>
</div>