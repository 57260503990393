import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import * as _ from 'lodash';
export interface IRole extends IBaseAuditModel {
  name: string;
  description: string;
  isDeleted: boolean;
}

export class Role extends BaseAuditModel implements IRole {
  name: string;
  description: string;
  isDeleted: boolean;

  constructor(data?: IRole) {
    super(data);
    if (!data) {
      return;
    }
    _.assign(this, data);
  }
}
