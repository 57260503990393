import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AttributeOptionLite } from 'src/app/core/models/attribute-option-lite.model';
import { AttributeOptionType } from 'src/app/core/models/attribute-option-type.model';
import { AttributeOptionValue } from 'src/app/core/models/attribute-option-value.model';
import { AttributeOption, IAttributeOption } from 'src/app/core/models/attribute-option.model';
import { AttributeOptionTypes } from '../../constants';
import * as _ from 'lodash';
import { IntegrationProductOfferingSummary } from 'src/app/core/models/integration-product-offering-summary.model';
import { IAttributeOptionOfferingExclusion } from 'src/app/core/models/attribute-option-offering-exclusion.model';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { GlobalProductAttributeOption } from 'src/app/core/models/global-product-attribute-option.model';
import { ProductDetailFormDataService } from 'src/app/core/services/product-detail-form-data.service';
import { IExludedItems, IAttributeOptionNameId, IAttributeOptionInfo } from 'src/app/core/models/attribute-option-extensions.model';
import { MatInput } from '@angular/material/input';
@Component({
  selector: 'app-attribute-option-detail-readonly-modal',
  templateUrl: './attribute-option-detail-readonly-modal.component.html',
  styleUrls: ['./attribute-option-detail-readonly-modal.component.scss']
})
export class AttributeOptionDetailReadonlyModalComponent implements OnInit {
  @ViewChild(MatSelect) matSelect: MatSelect;
  attributeOptionForm: FormGroup;
  result: {
    parentAttributeOptionId: string[],
    unavailableOfferings: IAttributeOptionOfferingExclusion[]
  };
  selectedOptions: string[] = [];
  excludedItemsListInternal: Array<IExludedItems> = [];
  unavailableOfferings = new FormControl();
  listOfOfferingIds: number[] = [];
  attributeOptionNameID: Array<IAttributeOptionNameId> = [];
  resultExclusions: Array<IAttributeOptionOfferingExclusion> = [];
  initialUnavailableOfferingsValue: number[] = [];
  parentAttributeOptionIdInitialValue: number[] = [];
  filteredAttributeOptionInfo: Array<IAttributeOptionInfo>;

  constructor(
    public dialogRef: MatDialogRef<AttributeOptionDetailReadonlyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      attributeOption: AttributeOption,
      excludedItemsList: Array<IAttributeOptionOfferingExclusion>,
      integrationProductOfferingSummarys: Array<IntegrationProductOfferingSummary>,
      editComponent: boolean,
      prefillWithName: boolean,
      attributeOptionInfoList: Array<IAttributeOptionInfo>,
      attributeOptionFormArray: FormArray,
      productAttributeOption: boolean,
      attributeOptionList: Array<IAttributeOption>,
      currentAttributeOptionDetails: GlobalProductAttributeOption
    },
    private productDetailFormDataService: ProductDetailFormDataService) { }

  ngOnInit(): void {
    this.data.attributeOptionFormArray?.controls?.forEach(control => {
      const optionValue = control.value as GlobalProductAttributeOption;
      this.attributeOptionNameID.push({
        attributeOptionId: optionValue.attributeOptionId,
        attributeOptionName: optionValue.attributeOption.name
      });
    });

    this.attributeOptionForm = new FormGroup({
      parentAttributeOptionId: new FormControl(null),
      unavailableOfferings: new FormControl('')
    });

    if (this.data.prefillWithName) {
      const offeringIds: Array<number> = [];
      this.data.excludedItemsList.forEach(item => {
        const offeringId = item.globalProductOfferingId;
        if (!offeringIds.includes(offeringId)) {
          offeringIds.push(offeringId);
        }
      });
      this.listOfOfferingIds = [];
      this.unavailableOfferings?.setValue(this.listOfOfferingIds);
      this.data.integrationProductOfferingSummarys.forEach(x => {
        x.productOfferings.forEach(y => {
          if (offeringIds.includes(y.id))
            this.listOfOfferingIds.push(y.id);
        })
      });
      this.data.integrationProductOfferingSummarys.forEach(x => {
        x.productOfferings
          .filter(y => offeringIds.includes(y.id))
          .forEach(y => {
            if (!this.excludedItemsListInternal.some(a => a.imageIcon.includes(x.iconLink) && a.name.includes(x.name))) {
              this.excludedItemsListInternal.push({
                summaryId: x.id, name: x.name, imageIcon: x.iconLink, listOfOfferings: []
              });
            }
          });
      });

      this.selectedOptions = this.unavailableOfferings.value;
      this.selectedOptions.forEach(option => {
        this.data.integrationProductOfferingSummarys.forEach(summary => {
          summary.productOfferings.forEach(offering => {
            if (offering.id === parseInt(option)) {
              const excludedItem = this.excludedItemsListInternal.find(x => x.summaryId === summary.id);
              if (excludedItem) {
                excludedItem.listOfOfferings.push(offering.name + ' - ' + offering.templateName);
              }
            }
          });
        });
      });
      this.initialUnavailableOfferingsValue = this.unavailableOfferings.value;
      this.filteredAttributeOptionInfo = this.data.attributeOptionInfoList;

      var parentAttributeOption = this.getParentAttributeOptionIds();
      this.attributeOptionForm.get('parentAttributeOptionId').setValue(parentAttributeOption);
      this.parentAttributeOptionIdInitialValue = this.attributeOptionForm.get('parentAttributeOptionId')?.value;
      this.sortFilteredAttributeOptionItems();
    }
  }

  getParentAttributeOptionIds(): number[] {
    var parentAttributeOptionIds =
      (this.data.currentAttributeOptionDetails?.globalProductParentAttributeOptions && this.data.currentAttributeOptionDetails?.globalProductParentAttributeOptions.length > 0) ?
        this.data.currentAttributeOptionDetails?.globalProductParentAttributeOptions?.map(x => x.parentAttributeOptionId) : [];

    return parentAttributeOptionIds;
  }

  isUnavailableOfferingsDirty(): boolean {
    var initialParentAttributeOptionValue = (this.parentAttributeOptionIdInitialValue && this.parentAttributeOptionIdInitialValue.length > 0) ? this.parentAttributeOptionIdInitialValue : [];
    var finalParentAttributeOptionValue = (this.attributeOptionForm.get('parentAttributeOptionId')?.value && this.attributeOptionForm.get('parentAttributeOptionId')?.value?.length > 0) ? this.attributeOptionForm.get('parentAttributeOptionId')?.value : [];

    const unavailableOfferingsChanged =
      JSON.stringify(this.initialUnavailableOfferingsValue) !== JSON.stringify(this.unavailableOfferings.value);

    const parentAttributeOptionIdChanged =
      JSON.stringify(initialParentAttributeOptionValue) !== JSON.stringify(finalParentAttributeOptionValue);

    return unavailableOfferingsChanged || parentAttributeOptionIdChanged;

  }

  getParentAttributeOptionValue(): Array<string> {
    const parentAttributeOption = this.productDetailFormDataService.getAttributeOptionLite(this.data.currentAttributeOptionDetails);
    if (parentAttributeOption)
      return this.productDetailFormDataService.getParentAttributeOptionValue(parentAttributeOption)?.split(',').map(item => item.trim());
    else
      [''];
  }

  getParentAttributeOptionCount(): number {
    const parentAttributeOption = this.productDetailFormDataService.getAttributeOptionLite(this.data.currentAttributeOptionDetails);
    return (parentAttributeOption && parentAttributeOption.length > 0) ? parentAttributeOption.length : 0;
  }

  getAttributeOptionValueText(attributeOptionType: AttributeOptionType): string {
    return attributeOptionType.id == AttributeOptionTypes.Range ? "Min - Max" : "Value";
  }

  getAttributeOptionValue(attributeOptionValues: Array<AttributeOptionValue>): string {
    let sortedAttributeOptionValues = _.cloneDeep(attributeOptionValues).sort((aov1, aov2) => (aov1 > aov2 ? -1 : 1));
    let values: string;
    sortedAttributeOptionValues.forEach((aov, index) => {
      if (index == 0)
        values = `${aov.value}`;
      else
        values += ` - ${aov.value}`;
    });
    return values;
  }

  optionSelected(event: any) {
    const offeringIds = event.value;
    this.excludedItemsListInternal = [];
    this.data.integrationProductOfferingSummarys.forEach(x => {
      x.productOfferings.forEach(y => {
        if (offeringIds.includes(y.id)) {
          if (!(this.excludedItemsListInternal.find(a => a.imageIcon.includes(x.iconLink) && a.name.includes(x.name)))) {
            const excludedItem: IExludedItems = {
              summaryId: x.id, name: x.name, imageIcon: x.iconLink, listOfOfferings: []
            };
            this.excludedItemsListInternal.push(excludedItem);
          }
        }
      })
    });
    this.selectedOptions = event.source.selected.map(option => option.viewValue);
    this.selectedOptions.forEach(selectedOption => {
      this.data.integrationProductOfferingSummarys.forEach(summary => {
        summary.productOfferings.forEach(offering => {
          const offeringName = offering.name + ' - ' + offering.templateName;
          const excludedItem = this.excludedItemsListInternal.find(x => x.summaryId === summary.id);
          if (offeringName === selectedOption && excludedItem) {
            excludedItem.listOfOfferings.push(offeringName);
          }
        });
      });
    });
  }

  onSubmitClose() {
    this.dialogRef.close('No change');
  }
  onSubmit() {
    let existingExclusionId: number = 0;
    const attributeOptionOfferingExclusions: IAttributeOptionOfferingExclusion[] = this.unavailableOfferings.value.map(x => {
      const exclusionsLength = this.data.attributeOption.offeringExclusions?.length || 0;
      const exclusion = {
        id: existingExclusionId < exclusionsLength
          ? this.data.attributeOption.offeringExclusions[existingExclusionId]?.id || 0
          : 0,
        attributeOptionId: this.getAttributeOptionId(),
        globalProductOfferingId: x
      };
      existingExclusionId++;
      return exclusion;
    });
    this.resultExclusions = attributeOptionOfferingExclusions;
    if (attributeOptionOfferingExclusions.length == 0) {
      var noExclusions = this.alterExclusions();
      this.resultExclusions = noExclusions;
    }
    this.result = {
      parentAttributeOptionId: this.attributeOptionForm.get('parentAttributeOptionId') ? this.attributeOptionForm.get('parentAttributeOptionId')?.value : '',
      unavailableOfferings: this.resultExclusions
    };
    this.dialogRef.close(this.result);
  }
  alterExclusions(): IAttributeOptionOfferingExclusion[] {
    const attributeOptionOfferingExclusions: IAttributeOptionOfferingExclusion[] = [{
      attributeOptionId: this.getAttributeOptionId(),
      globalProductOfferingId: 0,
      id: 0
    }];
    return attributeOptionOfferingExclusions;
  }
  getAttributeOptionId(): number {
    let attributeOptionId: number = 0;
    if (!this.data.attributeOption.id) {
      var id = this.attributeOptionNameID.find(x => x.attributeOptionName == this.data.attributeOption.name).attributeOptionId;
      attributeOptionId = id;
    }
    else {
      attributeOptionId = this.data.attributeOption.id;
    }
    return attributeOptionId;
  }

  getTooltipAttributeOptions(): string {
    const attributeOptionDetails = this.getParentAttributeOptionDetails();
    if (attributeOptionDetails) {
      const parentAttributeOptionNames = attributeOptionDetails.map((pao) => pao.name);
      return parentAttributeOptionNames.join(', ');
    } else {
      return '';
    }
  }

  getNumberofParentsSelected(): number {
    const attributeOptionDetails = this.getParentAttributeOptionDetails();
    if (attributeOptionDetails && attributeOptionDetails.length > 0)
      return attributeOptionDetails.length;
    else
      return 0;
  }

  getParentAttributeOptionDetails(): Array<AttributeOption> | null {
    let parentAttributeOptionList: Array<AttributeOption> = [];
    if (this.parentAttributeOptionExists()) {
      let parentAttributeOptionIds = this.attributeOptionForm.get('parentAttributeOptionId')?.value;
      if (parentAttributeOptionIds && parentAttributeOptionIds.length > 0) {
        parentAttributeOptionIds.forEach(attributeOptionId => {
          var attributeOption = this.data.attributeOptionList.find(x => x.id === attributeOptionId);
          if (attributeOption)
            parentAttributeOptionList.push(this.getParentAttributeOption(attributeOption));
        });
        return parentAttributeOptionList;
      }
      return null;
    }
  }

  getParentAttributeOption(attributeOption: IAttributeOption): AttributeOption {
    const attributeOptionModified: IAttributeOption = {
      name: this.data.attributeOptionInfoList.find(x => x.id === attributeOption.id).attributeTypeAndOptionName,
      shortDescription: '',
      longDescription: attributeOption.longDescription,
      imageUrl: attributeOption.imageUrl,
      isInDraft: attributeOption.isInDraft,
      iconUpload: attributeOption.iconUpload,
      iconName: attributeOption.iconName,
      id: 0,
      attributeOptionTypeId: attributeOption.attributeOptionTypeId,
      parentAttributeOptionId: attributeOption.parentAttributeOptionId
    };
    const attributeOptionInstance = new AttributeOption(attributeOptionModified);
    return attributeOptionInstance;
  }

  clearSearchText(matInput: MatInput) {
    matInput.value = '';
    this.filterParentAttributeOptions('');
  }

  filterParentAttributeOptions(filterText: string) {
    this.filteredAttributeOptionInfo = [];
    if (!filterText) {
      this.filteredAttributeOptionInfo = this.data?.attributeOptionInfoList;
      this.sortFilteredAttributeOptionItems();
    } else {
      for (let i = 0; i < this.data?.attributeOptionInfoList.length; i++) {
        if (this.data?.attributeOptionInfoList[i].attributeTypeAndOptionName.toLowerCase().startsWith(filterText)
          || this.attributeOptionForm.get('parentAttributeOptionId').value.includes(this.data?.attributeOptionInfoList[i].id)) {
          this.filteredAttributeOptionInfo.push(this.data?.attributeOptionInfoList[i]);
        }
      }
      this.sortFilteredAttributeOptionItems();
    }
  }

  sortFilteredAttributeOptionItems() {
    if (this.parentAttributeOptionExists()) {
      this.filteredAttributeOptionInfo.sort((a, b) => {
        const aHasParentId = this.attributeOptionForm.get('parentAttributeOptionId').value.includes(a.id);
        const bHasParentId = this.attributeOptionForm.get('parentAttributeOptionId').value.includes(b.id);

        if (aHasParentId && !bHasParentId) {
          return -1;
        } else if (!aHasParentId && bHasParentId) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  }

  parentAttributeOptionExists(): boolean {
    if (this.attributeOptionForm.get('parentAttributeOptionId').value)
      return true;
    else
      return false;
  }
}
