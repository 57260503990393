import { GlobalTranslations } from './../../../core/models/global-translations.model';
import { Action } from '@ngrx/store';

export enum TranslationsApiActionTypes {
  LoadSuccess           = '[Translations/API] Fetch Success',
  LoadFailure           = '[Translations/API] Fetch Failure',
}

export class LoadSuccess implements Action {
  readonly type = TranslationsApiActionTypes.LoadSuccess;

  constructor(public payload: GlobalTranslations) { }
}

export class LoadFailure implements Action {
  readonly type = TranslationsApiActionTypes.LoadFailure;

  constructor(public payload: any) { }
}

export type TranslationsApiActionsUnion =
    LoadSuccess
  | LoadFailure;
