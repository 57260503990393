import { assign as _assign } from 'lodash-es';

export interface IPageRequest {
  pageNumber: number;
  pageSize: number;
  orderBy: string;
  sortOrder: string;
}

export class PageRequest implements IPageRequest {
  pageNumber: number = 1;
  pageSize: number = 50;
  orderBy: string;
  sortOrder: string;

  constructor(data?: IPageRequest) {
    if (!data) {
      return;
    }
    _assign(this, data);
  }
}
