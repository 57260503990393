import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import { assign as _assign } from 'lodash-es';

export interface ISubcategoryPost extends IBaseAuditModel {
  name: string;
  categoryId: number;
}

export class SubcategoryPost extends BaseAuditModel {
  name: string;
  categoryId: number;

  constructor(data?: ISubcategoryPost) {
    super(data);

    if (!data) {
      return;
    }
    _assign(this, data);
  }
}
