import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { PagedResults } from '../models/page-results.model';
import { IGlobalSubcategory, GlobalSubcategory } from '../models/global-subcategory.model';
import { GlobalSubcategorySummary } from '../models/global-subcategory-summary.model';
import { GlobalSubcategoryPost } from '../models/global-subcategory-post.model';
import { GlobalSubcategoryPut } from '../models/global-subcategory-put.model';
import { RenamePut } from '../models/rename-put.model';
import { GlobalSubcategoryAttributeTypePut } from '../models/global-subcategory-attribute-type-put.model';

@Injectable({
  providedIn: 'root'
})
export class GlobalSubcategoriesService {
  basePath = 'globalsubcategories';
  attributeOptionDelimiter = '_^_^_';
  constructor(private http: HttpClient) {
  }

  getGlobalSubcategories$(searchTerm: string = '', pageNumber: number, pageSize: number, sortActive: string, sortOrder: string): Observable<PagedResults<GlobalSubcategorySummary>> {
    return this.http.get(`${environment.apiUrl}${this.basePath}`, {
      params: new HttpParams()
        .set('search', searchTerm)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
        .set('orderBy', sortActive.toString())
        .set('sortOrder', sortOrder.toString())
    }).pipe(
      map((response: PagedResults<GlobalSubcategorySummary>) => response)
    );
  }

  getGlobalSubcategory$(id: number): Observable<GlobalSubcategory> {
    console.log(`getGlobalSubcategory$(${id})`);
    return this.http
      .get(environment.apiUrl + `${this.basePath}/${id}`)
      .pipe(map(response => response)
        , map((x: IGlobalSubcategory) => new GlobalSubcategory(x)));
  }

  createGlobalSubcategory$(globalSubcategory: GlobalSubcategoryPost): Observable<GlobalSubcategory> {
    console.log(`createGlobalSubcategory(${globalSubcategory})`);

    return this.http
      .post(environment.apiUrl + `${this.basePath}`, globalSubcategory)
      .pipe(map(res => res)
        , catchError((err: Response) => {
          const obj = err;
          return observableThrowError(() => obj);
        })
        , map((x: IGlobalSubcategory) => new GlobalSubcategory(x)));
  }

  updateGlobalSubcategory$(globalSubcategory: GlobalSubcategoryPut): Observable<GlobalSubcategoryPut> {
    console.log(`updateSubcategory(${globalSubcategory})`);
    const formData: FormData = new FormData();
    const globalSubcatgegoryId: any = globalSubcategory.id;  
    if (globalSubcategory.iconUpload != null) {
      formData.append('iconFile', globalSubcategory.iconUpload, globalSubcategory.iconName);
    }    
    formData.append('globalSubcategory', JSON.stringify(globalSubcategory));
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    this.addAttributeOptionImages(formData, globalSubcategory.globalSubcategoryAttributeTypes);
    const params = globalSubcatgegoryId;
    return this.http
      // .put(`${environment.apiUrl}${this.basePath}/${globalSubcategory.id}`, globalSubcategory)
      .put<GlobalSubcategoryPut>(`${environment.apiUrl + this.basePath}/${globalSubcatgegoryId}`, formData, { headers, params })
      .pipe(map(response => response),
        catchError((err: Response) => {
          console.log('Error on global subcategory update:');
          console.log(err);
          return observableThrowError(() => err);
        })
      );
  }

  private addAttributeOptionImages(formData: FormData, globalSubcategoryAttributeTypes: Array<GlobalSubcategoryAttributeTypePut>)
  {
    globalSubcategoryAttributeTypes.forEach(gsat => {
        gsat.attributeOptions.forEach(ao => {
           if (ao?.iconUpload != null && ao?.name) {
            formData.append('attributeOptionImages' + this.attributeOptionDelimiter + ao.name.toString() + this.attributeOptionDelimiter + gsat.id.toString(), ao.iconUpload, ao.iconName);
           }
        });
    });
  }

  renameGlobalSubcategory$(request: { globalSubcategoryId: number, globalSubcategoryName: string }): Observable<any> {
    const renamePut: RenamePut = new RenamePut({ name: request.globalSubcategoryName });
    return this.http
      .put(environment.apiUrl + `${this.basePath}/${request.globalSubcategoryId}/rename`,
        renamePut,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
      );
  }

  deleteGlobalSubcategory$(globalSubcategoryId: number): Observable<any> {
    return this.http.delete(environment.apiUrl + `${this.basePath}/${globalSubcategoryId}`)
      .pipe(
        map(res => res),
        catchError((err: Response) => {
          const obj = err;
          return observableThrowError(() => err);
        })
      );
  }

  getDuplicates$(globalSubcategoryName: string, globalCategoryId: number): Observable<Array<GlobalSubcategorySummary>> {
    const request: GlobalSubcategoryPost = new GlobalSubcategoryPost();
    request.name = globalSubcategoryName;
    request.globalCategoryId = globalCategoryId;

    return this.http
      .post(`${environment.apiUrl}${this.basePath}/duplicates`, request)
      .pipe(
        map(response => response),
        catchError((error: Response) => {
          return observableThrowError(() => error);
        }),
        map((response: Array<GlobalSubcategorySummary>) => response)
      );
  }

}
