import { Action } from '@ngrx/store';
import { SettingSummary } from 'src/app/core/models/setting-summary.model';

export enum SettingsApiActionTypes {
  FetchSuccess = '[Settings/API] Fetch Success',
  FetchFailure = '[Settings/API] Fetch Failure',
}

export class FetchSuccess implements Action {
  readonly type = SettingsApiActionTypes.FetchSuccess;

  constructor(public payload: SettingSummary[]) { }
}

export class FetchFailure implements Action {
  readonly type = SettingsApiActionTypes.FetchFailure;

  constructor(public payload: any) { }
}

export type SettingsApiActionsUnion = FetchSuccess | FetchFailure;
