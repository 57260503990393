import { Component, OnDestroy, ViewChild, ElementRef, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { CustomValidatorsService } from 'src/app/core/services/custom-validators.service';
import { ModelFileLinkTypes } from 'src/app/shared/constants';
import { SubSink } from 'subsink';


// Show form errors when invalid control is dirty, touched, or submitted (show errors immediately).
export class CustomErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
	    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
	}
}

  
@Component({
	selector: 'app-cad-links-manual-entry',
	templateUrl: './cad-links-manual-entry.component.html',
	styleUrls: ['./cad-links-manual-entry.component.scss']
})
export class CadLinksManualEntryComponent implements OnInit, OnDestroy {

	@Input() desiredLinkType: ModelFileLinkTypes;
	@Input() existingCadLinkUrl: string;
	@Output() newValidatedCadLinkUrl = new EventEmitter<string>();
	@ViewChild('cadLinkUrlInput') input: ElementRef;

	cadLinkEntryInput: FormControl;
	errorStateMatcher = new CustomErrorStateMatcher();

	cadLinkUrlIsValid = false;

	private subscriptions = new SubSink();

	constructor(
		private customValidatorsService: CustomValidatorsService
	){}

	ngOnInit() {
		// select the correct file extensions for input validation depending on the type of cad link is desired
		let validExtensions: string[];

		switch (this.desiredLinkType){
			case ModelFileLinkTypes.AutoCad2D: {
				validExtensions = ['zip', 'dwg', ''];
				break;
			}
			case ModelFileLinkTypes.AutoCad3D: {
				validExtensions = ['zip', 'dwg', 'skp', ''];
				break;
			}
			case ModelFileLinkTypes.Revit: {
				validExtensions = ['rvt', 'zip', ''];
				break;
			}
			case ModelFileLinkTypes.Sketchup: {
				validExtensions = ['skp', ''];
				break;
			}
			default: {
				// default to 2D cad
				validExtensions = ['zip', 'dwg', ''];
			}
		}

		// build a form control and add validation to it
		this.cadLinkEntryInput = new FormControl(null);
		this.cadLinkEntryInput.setValidators([
			Validators.required,
			Validators.pattern(this.customValidatorsService.getFileExtensionRegEx(validExtensions))
		]);
		this.cadLinkEntryInput.setAsyncValidators([
			this.customValidatorsService.validateFileUrlExists
		]);

		// subscribe to changes in the form control's status
		this.subscriptions.add(
			this.cadLinkEntryInput.statusChanges.subscribe(
				status => {
					if (status === "VALID") {
						this.cadLinkUrlIsValid = true;
						this.newValidatedCadLinkUrl.emit(this.cadLinkEntryInput.value);
					} else {
						this.cadLinkUrlIsValid = false;
						this.newValidatedCadLinkUrl.emit(null);
					}
				}
			)
		);

		// if there was an existing download link url, enter the value in the input form contrl, and kick off validation
		// with immediate indication of errors.  
		// NOTE: this is wrapped in a setTimeout to prevent a hung validation "PENDING" status when setting the value in
		// ngOnInit, or from another Angular lifecycle hook method.
		setTimeout(
			() => {
				if (this.existingCadLinkUrl) {
					this.cadLinkEntryInput.setValue(this.existingCadLinkUrl);
					this.cadLinkEntryInput.markAsTouched();
				}
			}
		);
	
	}

	getValidationErrorMessage() {
		if (this.cadLinkEntryInput.hasError('required')) {
		  	return "A file download link URL must be entered.";
		}

		if (this.cadLinkEntryInput.hasError('pattern')) {
		  	return "The provided download link URL is not for the correct type of file.";
		}

		if (this.cadLinkEntryInput.hasError('fileNotExists')) {
			return "The link URL is properly formatted, but a downloadable file does not exist at this link URL.";
	  	}

		if (this.cadLinkEntryInput.hasError('fileExistsCheckFailure')) {
			return "The link URL is properly formatted, but there was a failure while confirming the link URL.";
	  	}

		return "The entry is invalid.";
	}
	
	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

}