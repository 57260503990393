import * as _ from 'lodash';

export interface IGlobalTranslations {
  brandTranslations: Array<BrandTranslation>;
  categoryTranslations: Array<CategoryTranslation>;
  designerTranslations: Array<DesignerTranslation>;
  familyTranslations: Array<FamilyTranslation>;
  portfolioTranslations: Array<PortfolioTranslation>;
  subBrandTranslations: Array<SubBrandTranslation>;
  subcategoryTranslations: Array<SubcategoryTranslation>;
  warrantyTranslations: Array<WarrantyTranslation>;
}

export class GlobalTranslations implements IGlobalTranslations {
  brandTranslations: Array<BrandTranslation> = [];
  categoryTranslations: Array<CategoryTranslation> = [];
  designerTranslations: Array<DesignerTranslation> = [];
  familyTranslations: Array<FamilyTranslation> = [];
  portfolioTranslations: Array<PortfolioTranslation> = [];
  subBrandTranslations: Array<SubBrandTranslation> = [];
  subcategoryTranslations: Array<SubcategoryTranslation> = [];
  warrantyTranslations: Array<WarrantyTranslation> = [];

  constructor(data?: IGlobalTranslations) {
    if (data) {
      _.assign(this, data);
    }
  }
}

export interface IBrandTranslation {
  brandId: number;
  languageId: number;
  name: string;
  description: string;
}

export class BrandTranslation implements IBrandTranslation {
  brandId: number;
  languageId: number;
  name: string;
  description: string;

  constructor(data?: IBrandTranslation) {
    if (data) {
      _.assign(this, data);
    }
  }
}

export interface ICategoryTranslation {
  categoryId: number;
  languageId: number;
  name: string;
  description: string;
}

export class CategoryTranslation implements ICategoryTranslation {
  categoryId: number;
  languageId: number;
  name: string;
  description: string;

  constructor(data?: ICategoryTranslation) {
    if (data) {
      _.assign(this, data);
    }
  }
}

export interface IDesignerTranslation {
  designerId: number;
  languageId: number;
  name: string;
  bio: string;
}

export class DesignerTranslation implements IDesignerTranslation {
  designerId: number;
  languageId: number;
  name: string;
  bio: string;

  constructor(data?: IDesignerTranslation) {
    if (data) {
      _.assign(this, data);
    }
  }
}

export interface IFamilyTranslation {
  familyId: number;
  languageId: number;
  name: string;
}

export class FamilyTranslation implements IFamilyTranslation {
  familyId: number;
  languageId: number;
  name: string;

  constructor(data?: IFamilyTranslation) {
    if (data) {
      _.assign(this, data);
    }
  }
}

export interface IPortfolioTranslation {
  portfolioId: number;
  languageId: number;
  name: string;
  description: string;
}

export class PortfolioTranslation implements IPortfolioTranslation {
  portfolioId: number;
  languageId: number;
  name: string;
  description: string;

  constructor(data?: IPortfolioTranslation) {
    if (data) {
      _.assign(this, data);
    }
  }
}

export interface ISubBrandTranslation {
  subBrandId: number;
  languageId: number;
  description: string;
}

export class SubBrandTranslation implements ISubBrandTranslation {
  subBrandId: number;
  languageId: number;
  description: string;

  constructor(data?: ISubBrandTranslation) {
    if (data) {
      _.assign(this, data);
    }
  }
}

export interface ISubcategoryTranslation {
  subcategoryId: number;
  languageId: number;
  name: string;
  description: string;
}

export class SubcategoryTranslation implements ISubcategoryTranslation {
  subcategoryId: number;
  languageId: number;
  name: string;
  description: string;

  constructor(data?: ISubcategoryTranslation) {
    if (data) {
      _.assign(this, data);
    }
  }
}

export interface IWarrantyTranslation {
  warrantyId: number;
  languageId: number;
  name: string;
}

export class WarrantyTranslation implements IWarrantyTranslation {
  warrantyId: number;
  languageId: number;
  name: string;

  constructor(data?: IWarrantyTranslation) {
    if (data) {
      _.assign(this, data);
    }
  }
}
