import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ghost-display-card',
  templateUrl: './ghost-display-card.component.html',
  styleUrls: ['./ghost-display-card.component.scss'],
})
export class GhostDisplayCardComponent {
  @Input() rows = 3;
  public getRows() {
    return Array.from(Array(this.rows), (x, i) => i);
  }
}
