import { Action } from '@ngrx/store';
import { Designer } from 'src/app/core/models/designer.model';
import { DesignerPut } from 'src/app/core/models/designer-put.model';

export enum DesignerApiActionTypes {
  LoadSuccess = '[Designer/API] Load Success',
  LoadFailure = '[Designer/API] Load Failure',
  CreateSuccess = '[Designer/API] Create Success',
  CreateFailure = '[Designer/API] Create Failure',
  UpdateSuccess = '[Designer/API] Update Success',
  UpdateFailure = '[Designer/API] Update Failure',
  RenameSuccess = '[Designer/API] Rename Success',
  RenameFailure = '[Designer/API] Rename Failure',
  DeleteSuccess = '[Designer/API] Delete Success',
  DeleteFailure = '[Designer/API] Delete Failure'
}

export class LoadSuccess implements Action {
  readonly type = DesignerApiActionTypes.LoadSuccess;

  constructor(public payload: Designer) { }
}

export class LoadFailure implements Action {
  readonly type = DesignerApiActionTypes.LoadFailure;

  constructor(public payload: any) { }
}

export class CreateSuccess implements Action {
  readonly type = DesignerApiActionTypes.CreateSuccess;

  constructor(public payload: Designer) { }
}

export class CreateFailure implements Action {
  readonly type = DesignerApiActionTypes.CreateFailure;

  constructor(public payload: any) { }
}


export class UpdateSuccess implements Action {
  readonly type = DesignerApiActionTypes.UpdateSuccess;

  constructor(public payload: { designer: DesignerPut, designerName: string }) { }
}

export class UpdateFailure implements Action {
  readonly type = DesignerApiActionTypes.UpdateFailure;

  constructor(public payload: any) { }
}

export class RenameSuccess implements Action {
  readonly type = DesignerApiActionTypes.RenameSuccess;

  constructor(public payload: { designerId: number, designerName: string }) { }
}

export class RenameFailure implements Action {
  readonly type = DesignerApiActionTypes.RenameFailure;

  constructor(public payload: any) { }
}

export class DeleteSuccess implements Action {
  readonly type = DesignerApiActionTypes.DeleteSuccess;

  constructor(public payload: { designerId: number, designerName: string }) { }
}

export class DeleteFailure implements Action {
  readonly type = DesignerApiActionTypes.DeleteFailure;

  constructor(public payload: any) { }
}

export type DesignerApiActionsUnion =
  LoadSuccess
  | LoadFailure
  | CreateSuccess
  | CreateFailure
  | UpdateSuccess
  | UpdateFailure
  | RenameSuccess
  | RenameFailure
  | DeleteSuccess
  | DeleteFailure;
