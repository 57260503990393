import { ContactApiActions, ContactActions } from '../actions';
import { Contact } from 'src/app/core/models/contact.model';
import { ContactStatus } from 'src/app/shared/constants';


export interface State {
  contact: Contact;
  loading: boolean;
  loaded: boolean;
  status: ContactStatus;
  error: string;
  success: string;
}

export const initialState: State = {
  contact: null,
  loading: false,
  loaded: false,
  status: ContactStatus.None,
  error: null,
  success: null
};

export function reducer(state = initialState, action: ContactActions.ContactActionsUnion | ContactApiActions.ContactApiActionsUnion): State {
  switch (action.type) {

    case ContactActions.ContactActionTypes.LoadContact: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case ContactApiActions.ContactApiActionTypes.LoadSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        contact: action.payload
      };
    }

    case ContactApiActions.ContactApiActionTypes.LoadFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        contact: null,
        error: action.payload
      };
    }

    case ContactActions.ContactActionTypes.ClearMessages: {
      return {
        ...state,
        status: ContactStatus.None,
        error: null,
        success: null
      };
    }

    case ContactActions.ContactActionTypes.CreateContact: {
      return {
        ...state,
      };
    }

    case ContactApiActions.ContactApiActionTypes.CreateSuccess: {
      return {
        ...state,
        contact: action.payload,
        status: ContactStatus.CreateSuccess,
      };
    }

    case ContactApiActions.ContactApiActionTypes.CreateFailure: {
      return {
        ...state,
        contact: null,
        status: ContactStatus.CreateFailure,
        error: 'Failed to Create Contact',
        success: null
      };
    }

    case ContactActions.ContactActionTypes.UpdateContact: {
      return {
        ...state,
        loading: true,
        loaded: false,
        status: ContactStatus.None
      };
    }

    case ContactApiActions.ContactApiActionTypes.UpdateSuccess: {
      return {
        ...state,
        status: ContactStatus.UpdateSuccess,
        error: null,
        success: `Successfully Updated Contact: ${action.payload.contactName}`
      };
    }

    case ContactApiActions.ContactApiActionTypes.UpdateFailure: {
      return {
        ...state,
        loading: false,
        loaded: true,
        status: ContactStatus.UpdateFailure,
        error: 'Failed to Update Contact',
        success: null
      };
    }

    case ContactActions.ContactActionTypes.RenameContact: {
      return {
        ...state
      };
    }

    case ContactApiActions.ContactApiActionTypes.RenameSuccess: {
      const contact = { ...state.contact };
      contact.name = action.payload.contactName;

      return {
        ...state,
        status: ContactStatus.RenameSuccess,
        error: null,
        success: `Successfully renamed Contact: ${action.payload.contactName}`
      };
    }

    case ContactApiActions.ContactApiActionTypes.RenameFailure: {
      return {
        ...state,
        contact: null,
        status: ContactStatus.RenameFailure,
        error: 'Failed to rename Contact',
        success: null
      };
    }

    case ContactActions.ContactActionTypes.DeleteContact: {
      return {
        ...state,
      };
    }

    case ContactApiActions.ContactApiActionTypes.DeleteSuccess: {
      return {
        ...state,
        status: ContactStatus.DeleteSuccess,
        error: null,
        success: `Successfully Deleted Contact: ${action.payload.contactName}`
      };
    }

    case ContactApiActions.ContactApiActionTypes.DeleteFailure: {
      return {
        ...state,
        contact: null,
        status: ContactStatus.DeleteFailure,
        error: 'Failed to Delete Contact',
        success: null
      };
    }

    default:
      return state;
  }
}

export const getContact = (state: State) => state.contact;
export const getErrorMessage = (state: State) => state.error;
export const getSuccessMessage = (state: State) => state.success;
export const getStatus = (state: State) => state.status;
export const isLoading = (state: State) => state.loading;
export const isLoaded = (state: State) => state.loaded;