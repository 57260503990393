import { DamProviderIds } from './../../constants';
import { DigitalAssetManagerModalDataSource, Views } from './digital-asset-manager-modal.datasource';
import { DigitalAssetManagerService } from 'src/app/core/services/digital-asset-manager.service';
import { ImageAsset } from './../../../core/models/image-asset.model';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import * as fromStore from '../../../app-store/reducers';
import { SubSink } from 'subsink';
import { Store } from '@ngrx/store';
import { MediaSubType } from 'src/app/core/models/media-subtype.model';
import { RolesService } from 'src/app/core/auth/roles.service';

@Component({
  selector: 'app-digital-asset-manager-modal',
  templateUrl: './digital-asset-manager-modal.component.html',
  styleUrls: ['./digital-asset-manager-modal.component.scss']
})
export class DigitalAssetManagerModalComponent implements OnInit, OnDestroy {
  result: DigitalAssetManagerResult = new DigitalAssetManagerResult(false, []);
  Views = Views;
  datasource: DigitalAssetManagerModalDataSource = new DigitalAssetManagerModalDataSource(this.digitalAssetManagerService);
  subs = new SubSink();

  constructor(
    private store: Store<fromStore.State>,
    public dialogRef: MatDialogRef<DigitalAssetManagerModalComponent>,
    private digitalAssetManagerService: DigitalAssetManagerService,
    private rolesService: RolesService,
    @Inject(MAT_DIALOG_DATA) public data: {
      search: string,
      selectedLocale: string,
      isMultiple: boolean,
      mediaSubTypes: MediaSubType[],
      productImages: boolean
    }
  ) { }

  ngOnInit() {
    this.datasource.searchTerm = this.data.search;
    this.datasource.selectedLocale = this.data.selectedLocale;
    this.datasource.isMultiple = this.data.isMultiple;
    this.datasource.visibleView = Views.ImageGrid;
    this.datasource.mediaSubTypes = this.data.mediaSubTypes;
    this.datasource.selectedImage = null;
    if (this.datasource.searchTerm && this.datasource.searchTerm.length > 0) {
      this.datasource.searchImages(this.data.search, null);
    }
    this.result = new DigitalAssetManagerResult(false, []);

    this.digitalAssetManagerService.preLoadBynderDamOptions(this.store, this.subs);
  }

  ngOnDestroy() {
    this.datasource.disconnect();
  }

  clearSelection() {
    this.datasource.clearSelection();
  }

  applyImages() {
    this.result.isValid = true;
    this.result.images = this.datasource.selectedImages;

    this.datasource.clearData();
    this.dialogRef.close(this.result);
  }

  applyImageUrl() {
    this.result.isValid = true;

    const fileName: string = this.datasource.getImageUrlFileName();
    const url: string = this.datasource.addImageUrl;
    var title: string = this.datasource.addImageUrlFormGroup.controls.title.value;
    var altText: string = this.datasource.addImageUrlFormGroup.controls.altText.value;
    var mediaSubType: string = this.datasource.addImageUrlFormGroup.controls.mediaSubType.value;

    this.result.images.push(
      {
        url,
        thumbnailUrl: url,
        largeImageUrl: url,
        title: title,
        altText: altText,
        mediaSubType: mediaSubType,
        bynderId: null,
        damProviderId: DamProviderIds.UserEnteredMedia
      });

    this.datasource.clearData();
    this.dialogRef.close(this.result);
  }

  setView(matTabChangeEvent: MatTabChangeEvent) {
    switch (matTabChangeEvent.index) {
      case 0: {
        this.datasource.visibleView = Views.ImageGrid;
        this.clearSelection();
        break;
      }
      case 1: {
        this.datasource.visibleView = Views.ImageUrl;
        this.clearSelection();
        break;
      }
    }
    this.enableControls();
  }

  enableControls() {
    this.datasource.addImageUrlFormGroup.controls.title.enable();
    this.datasource.addImageUrlFormGroup.controls.altText.enable();
    this.datasource.addImageUrlFormGroup.controls.mediaSubType.enable();
    this.datasource.addImageUrlFormGroup.controls.imageUrl.enable();
    this.datasource.addImageUrlFormGroup.controls.imageUrl.patchValue("");
    this.datasource.addImageUrlFormGroup.controls.altText.patchValue("");
    this.datasource.addImageUrlFormGroup.controls.title.patchValue("");
    this.datasource.addImageUrlFormGroup.controls.mediaSubType.patchValue("");
  }
}

export class DigitalAssetManagerResult {
  constructor(public isValid: boolean, public images: Array<ImageAsset> = []) { }
}
