import { assign as _assign } from 'lodash-es';

export class PagedResults<T> {
  pageNumber: number;
  pageSize: number;
  totalRecordCount: number = 0;
  totalPageCount: number = 0;
  results: Array<T> = [];

  constructor(data?: PagedResults<T>) {
    if (!data) {
      return;
    }

    _assign(this, data);
  }
}
