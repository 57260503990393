import { assign as _assign } from 'lodash-es';
import { IBaseAuditModel, BaseAuditModel } from './base-audit.model';

export interface IEndpointProductOfferingTag extends IBaseAuditModel {
  endpointId: number;
  tag: string;
}

export class EndpointProductOfferingTag extends BaseAuditModel implements IEndpointProductOfferingTag {
  endpointId: number;
  tag: string;

  constructor(data?: IEndpointProductOfferingTag) {
    super(data);

    if (!data) {
      return;
    }
    _assign(this, data);
  }
}


export interface IEndpointProductOfferingTagPost {
  endpointId: number;
  tag: string;
}

export class EndpointProductOfferingTagPost implements IEndpointProductOfferingTagPost {
  endpointId: number;
  tag: string;

  constructor(data?: IEndpointProductOfferingTagPost) {
    if (!data) {
      return;
    }
    _assign(this, data);
  }
}


export interface IEndpointProductOfferingTagPut {
  id: number;
  endpointId: number;
  tag: string;
}

export class EndpointProductOfferingTagPut implements IEndpointProductOfferingTagPut {
  id: number;
  endpointId: number;
  tag: string;

  constructor(data?: IEndpointProductOfferingTagPut) {
    if (!data) {
      return;
    }
    _assign(this, data);
  }
}


export interface IEndpointProductOfferingTagRelationshipPost {
  endpointId: number;
  endpointProductOfferingIds: Array<number>;
  endpointProductOfferingTagIds: Array<number>;
}

export class EndpointProductOfferingTagRelationshipPost implements IEndpointProductOfferingTagRelationshipPost {
  endpointId: number;
  endpointProductOfferingIds: Array<number>;
  endpointProductOfferingTagIds: Array<number>;

  constructor(data?: IEndpointProductOfferingTagRelationshipPost) {
    if (!data) {
      return;
    }
    _assign(this, data);
  }
}


export interface IEndpointProductOfferingTagRelationshipDelete {
  endpointId: number;
  endpointProductOfferingIds: Array<number>;
  endpointProductOfferingTagIds: Array<number>;
}

export class EndpointProductOfferingTagRelationshipDelete implements IEndpointProductOfferingTagRelationshipPost {
  endpointId: number;
  endpointProductOfferingIds: Array<number>;
  endpointProductOfferingTagIds: Array<number>;

  constructor(data?: IEndpointProductOfferingTagRelationshipPost) {
    if (!data) {
      return;
    }
    _assign(this, data);
  }
}
