import { GlobalSubcategory } from 'src/app/core/models/global-subcategory.model';
import { GlobalSubcategoryStatus } from 'src/app/shared/constants';
import {
  GlobalSubcategoryApiActions,
  GlobalSubcategoryActions
} from '../actions';

export interface State {
  globalSubcategory: GlobalSubcategory;
  loading: boolean;
  loaded: boolean;
  status: GlobalSubcategoryStatus;
  error: string;
  success: string;
}

export const initialState: State = {
  globalSubcategory: null,
  loading: false,
  loaded: false,
  status: GlobalSubcategoryStatus.None,
  error: null,
  success: null
};

export function reducer(state = initialState, action: GlobalSubcategoryActions.GlobalSubcategoryActionsUnion | GlobalSubcategoryApiActions.GlobalSubcategoryApiActionsUnion): State {
  switch (action.type) {

    case GlobalSubcategoryActions.GlobalSubcategoryActionTypes.LoadGlobalSubcategory: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case GlobalSubcategoryApiActions.GlobalSubcategoryApiActionTypes.LoadSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        globalSubcategory: action.payload
      };
    }

    case GlobalSubcategoryApiActions.GlobalSubcategoryApiActionTypes.LoadFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        globalSubcategory: null,
        error: action.payload
      };
    }

    case GlobalSubcategoryActions.GlobalSubcategoryActionTypes.ClearMessages: {
      return {
        ...state,
        status: GlobalSubcategoryStatus.None,
        error: null,
        success: null
      };
    }

    case GlobalSubcategoryActions.GlobalSubcategoryActionTypes.CreateGlobalSubcategory: {
      return {
        ...state,
      };
    }

    case GlobalSubcategoryApiActions.GlobalSubcategoryApiActionTypes.CreateSuccess: {
      return {
        ...state,
        globalSubcategory: action.payload,
        status: GlobalSubcategoryStatus.CreateSuccess,
      };
    }

    case GlobalSubcategoryApiActions.GlobalSubcategoryApiActionTypes.CreateFailure: {
      return {
        ...state,
        globalSubcategory: null,
        status: GlobalSubcategoryStatus.CreateFailure,
        error: 'Failed to Create Global Subcategory',
        success: null
      };
    }

    case GlobalSubcategoryActions.GlobalSubcategoryActionTypes.UpdateGlobalSubcategory: {
      return {
        ...state,
        loading: true,
        loaded: false,
        status: GlobalSubcategoryStatus.None
      };
    }

    case GlobalSubcategoryApiActions.GlobalSubcategoryApiActionTypes.UpdateSuccess: {
      return {
        ...state,
        status: GlobalSubcategoryStatus.UpdateSuccess,
        error: null,
        success: `Successfully Updated Global Subcategory: ${action.payload.globalSubcategoryName}`
      };
    }

    case GlobalSubcategoryApiActions.GlobalSubcategoryApiActionTypes.UpdateFailure: {
      return {
        ...state,
        loading: false,
        loaded: true,
        status: GlobalSubcategoryStatus.UpdateFailure,
        error: 'Failed to Update Global Subcategory',
        success: null
      };
    }

    case GlobalSubcategoryActions.GlobalSubcategoryActionTypes.RenameGlobalSubcategory: {
      return {
        ...state
      };
    }

    case GlobalSubcategoryApiActions.GlobalSubcategoryApiActionTypes.RenameSuccess: {
      const globalSubcategory = { ...state.globalSubcategory };
      globalSubcategory.name = action.payload.globalSubcategoryName;

      return {
        ...state,
        status: GlobalSubcategoryStatus.RenameSuccess,
        error: null,
        success: `Successfully renamed Global Subcategory: ${action.payload.globalSubcategoryName}`
      };
    }

    case GlobalSubcategoryApiActions.GlobalSubcategoryApiActionTypes.RenameFailure: {
      return {
        ...state,
        globalSubcategory: null,
        status: GlobalSubcategoryStatus.RenameFailure,
        error: 'Failed to rename Global Subcategory',
        success: null
      };
    }

    case GlobalSubcategoryActions.GlobalSubcategoryActionTypes.DeleteGlobalSubcategory: {
      return {
        ...state,
      };
    }

    case GlobalSubcategoryApiActions.GlobalSubcategoryApiActionTypes.DeleteSuccess: {
      return {
        ...state,
        status: GlobalSubcategoryStatus.DeleteSuccess,
        error: null,
        success: `Successfully Deleted Global Subcategory: ${action.payload.globalSubcategoryName}`
      };
    }

    case GlobalSubcategoryApiActions.GlobalSubcategoryApiActionTypes.DeleteFailure: {
      return {
        ...state,
        globalSubcategory: null,
        status: GlobalSubcategoryStatus.DeleteFailure,
        error: 'Failed to Delete Global Subcategory',
        success: null
      };
    }

    default:
      return state;
  }
}

export const getGlobalSubcategory = (state: State) => state.globalSubcategory;
export const getErrorMessage = (state: State) => state.error;
export const getSuccessMessage = (state: State) => state.success;
export const getStatus = (state: State) => state.status;
export const isLoading = (state: State) => state.loading;
export const isLoaded = (state: State) => state.loaded;
