import { SelectOptions } from './../../../core/models/select-options.model';

import {
  SelectOptionsApiActions,
  SelectOptionsActions
} from '../actions';

export interface State {
  selectOptionsLoading: boolean;
  selectOptionsLoaded: boolean;
  selectOptions: SelectOptions;
  error: string;
}

export const initialState: State = {
  selectOptionsLoading: false,
  selectOptionsLoaded: false,
  selectOptions: new SelectOptions(),
  error: ''
};

export function reducer(state = initialState, action: SelectOptionsActions.SelectOptionsActionsUnion | SelectOptionsApiActions.SelectOptionsApiActionsUnion): State {
  switch (action.type) {

    case SelectOptionsActions.SelectOptionsActionTypes.LoadSelectOptions: {
      return {
        ...state,
        selectOptionsLoading: true,
        selectOptionsLoaded: false,
        error: null
      };
    }

    case SelectOptionsActions.SelectOptionsActionTypes.LoadSelectOptionsByCode: {
      return {
        ...state,
        selectOptionsLoading: true,
        selectOptionsLoaded: false,
        error: null
      };
    }


    case SelectOptionsApiActions.SelectOptionsApiActionTypes.LoadSuccess: {
      return {
        ...state,
        selectOptions: action.payload,
        selectOptionsLoading: false,
        selectOptionsLoaded: true
      };
    }

    case SelectOptionsApiActions.SelectOptionsApiActionTypes.LoadFailure: {
      return {
        ...state,
        selectOptions: null,
        selectOptionsLoading: false,
        selectOptionsLoaded: false,
        error: action.payload
      };
    }

    default:
      return state;
  }
}

export const getSelectOptionsIsLoading = (state: State) => state.selectOptionsLoading;
export const getSelectOptionsIsLoaded = (state: State) => state.selectOptionsLoaded;
export const getSelectOptions = (state: State) => state.selectOptions;
