import { TranslationsEffects } from './translations-store/effects/translations.effects';
import { LocaleSpecifierEffects } from './locale-specifiers-store/effects/locale-specifier.effects';
import { SelectOptionsEffects } from './select-options-store/effects/select-options.effects';
import { RegionEffects } from './regions-store/effects/region.effects';
import { EndpointsEffects } from './endpoints-store/effects/endpoints.effects';
import { EndpointEffects } from './endpoint-store/effects/endpoint.effects';
import { ProductOfferingEffects } from './product-offerings-store/effects/product-offering.effects';
import { TemplatesEffects } from './templates-store/effects/templates.effects';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers, metaReducers } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { ProductEffects } from './products-store/effects/product.effects';
import { BrandEffects } from './brand-store/effects/brand.effects';
import { environment } from 'src/environments/environment';
import { IntegrationsEffects } from './integrations-store/effects/integrations.effects';
import { IntegrationEffects } from './integrations-store/effects/integration.effects';
import { ApplicationEffects } from './application-store/effects/application.effects';
import { SettingsEffects } from './settings-store/effects/settings.effects';
import { TechnologiesEffects } from './technologies-store/effects/technologies.effects';
import { RolesEffects } from './roles-store/effects/roles.effects';
import { UserEffects } from './user-store/effects/user.effects';
import { ConfigurationsEffects } from './configurations-store/effects/configurations.effects';
import { DesignerEffects } from './designer-store/effects/designer.effects';
import { CategoryEffects } from './category-store/effects/category.effects';
import { SubcategoryEffects } from './subcategory-store/effects/subcategory.effects';
import { SubBrandEffects } from './sub-brand-store/effects/sub-brand.effects';
import { FamilyEffects } from './families-store/effects/family.effects';
import { PortfolioEffects } from './portfolios-store/effects/portfolio.effects';
import { MaterialEffects } from './materials-store/effects/material.effects';
import { WarrantyEffects } from './warranties-store/effects/warranty.effects';
import { ApplicationTagEffects } from './applicationtag-store/effects/applicationtag.effects';
import { DamAssetsMetapropertyEffects } from './dam-assets-store/effects/dam-assets-metaproperty.effects';
import { EndpointProductOfferingsEffects } from './endpoint-product-offerings-store/effects/endpoint-product-offerings.effects';
import { ContactEffects } from './contact-store/effects/contact.effects';
import { AttributeTypeEffects } from './attribute-type-store/effects/attribute-type.effects';
import { GlobalCategoryEffects } from './global-category-store/effects/global-category.effects';
import { GlobalSubcategoryEffects } from './global-subcategory-store/effects/global-subcategory.effects';
import { ProductGuidanceAttributesEffects } from './product-guidance-attributes-store/effects/product-guidance-attributes.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([
      ProductOfferingEffects,
      ProductEffects,
      BrandEffects,
      SubBrandEffects,
      ConfigurationsEffects,
      DesignerEffects,
      CategoryEffects,
      SubcategoryEffects,
      ApplicationEffects,
      TemplatesEffects,
      IntegrationsEffects,
      IntegrationEffects,
      EndpointEffects,
      EndpointsEffects,
      RegionEffects,
      RolesEffects,
      SelectOptionsEffects,
      LocaleSpecifierEffects,
      TranslationsEffects,
      SettingsEffects,
      TechnologiesEffects,
      UserEffects,
      FamilyEffects,
      PortfolioEffects,
      MaterialEffects,
      ApplicationTagEffects,
      WarrantyEffects,
      DamAssetsMetapropertyEffects,
      EndpointProductOfferingsEffects,
      ContactEffects,
      AttributeTypeEffects,
      GlobalCategoryEffects,
      GlobalSubcategoryEffects,
      ProductGuidanceAttributesEffects
    ])
  ]
})
export class AppStoreModule { }
