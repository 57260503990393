import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import {
  TechnologiesApiActions,
  TechnologiesActions
} from '../actions';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { TechnologiesService } from 'src/app/core/services/technologies.service';
import { Technology } from 'src/app/core/models/technology.model';

@Injectable()
export class TechnologiesEffects {
  @Effect()
  loadTechnologies$ = this.actions$.pipe(
    ofType(TechnologiesActions.TechnologiesActionTypes.LoadTechnologies),
    switchMap(() =>
      this.technologiesService.getTechnologies$().pipe(
        map((technologies: Array<Technology>) => new TechnologiesApiActions.FetchSuccess(technologies)),
        catchError(error =>
          of(new TechnologiesApiActions.FetchFailure(error)
          )
        )
      )
    ));

  constructor(private actions$: Actions,
              private technologiesService: TechnologiesService) {
  }

}
