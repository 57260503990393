import { IBaseAuditModel, BaseAuditModel } from './base-audit.model';
import { assign as _assign } from 'lodash-es';

export interface IProductNumberPut extends IBaseAuditModel {
  fullProductNumber: string;
}

export class ProductNumberPut extends BaseAuditModel implements IProductNumberPut {
  fullProductNumber: string;

  constructor(data: IProductNumberPut) {
    super(data);

    if (!data) {
      return;
    }

    _assign(this, data);
  }
}
