import { Action } from '@ngrx/store';

export enum SettingsActionTypes {
  LoadSettings = '[Settings] Load Settings',
}

export class LoadSettings implements Action {
  readonly type = SettingsActionTypes.LoadSettings;

  constructor() { }
}

export type SettingsActionsUnion = LoadSettings;
