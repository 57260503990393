<div
  class="integration-pill pill"
  *ngIf="name.length > 0">
  <img
    [src]="iconUrl"
    [alt]="name"
    class="integration-icon"
    onerror="this.src='../../../../assets/images/placeholders/image_placeholder.png'" />
  <span>{{ name }}</span>
</div>
