import { ScrollService } from './core/services/scroll.service';
import { Component, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { MatTooltipDefaultOptions } from '@angular/material/tooltip';

export const TooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 250,
  hideDelay: 0,
  touchendHideDelay: 250,
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private scrollService: ScrollService,
    private titleService: Title) {
    this.titleService.setTitle (environment.appName);
  }

  onScroll(scroll: any) {
    this.scrollService.setScrollPosition(scroll.srcElement.scrollTop);
  }
}
