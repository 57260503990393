import { RolesService } from '../../auth/roles.service';
import { UserRoleIntegration } from '../../models/user-role-integration.model';
import { UserRole } from '../../models/user-role.model';
import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { UserIdentity } from '../../auth/user-identity.model';
import { Area } from '../../../shared/models/area.model';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent {
  appTitle = 'Harbour';
  user: UserIdentity;

  constructor(
    private authService: AuthService,
    private rolesService: RolesService,
    private router: Router) {
    this.appTitle = environment.appName;
  }

  public isAuthenticated(): boolean {
    if (this.authService.isAuthenticated()) {
        this.user = this.authService.getIdentity();
        return true;
    }
    return false;
  }

  public userRolesTooltip(userRoles: Array<UserRole>): string {
    let tooltip = '';

    // This should never be true;
    if (!userRoles || userRoles.length === 0) {
      tooltip = 'No roles assigned!';
      return tooltip;
    }

    userRoles.forEach((userRole: UserRole, userRoleIndex: number) => {
      if (!userRole?.role) {
        return;
      }

      if (userRoleIndex > 0) {
        tooltip = `${tooltip} \n`;
      }

      tooltip = `Access: ${userRole.role.name}`;

      // Show integrations
      if (this.rolesService.isIntegrationRole() && userRole.userRoleIntegrations?.length > 0) {
        tooltip = `${tooltip} \nIntegrations: `;
        userRole.userRoleIntegrations.forEach((userRoleIntegration: UserRoleIntegration, userRoleIntegrationIndex: number) => {
          if (userRoleIntegrationIndex > 0) {
            tooltip = `${tooltip}, `; // add comma and space
          }

          tooltip = `${tooltip} ${userRoleIntegration?.integration?.name}`;
        });
      }

      tooltip = `${tooltip} \n\n${userRole.role.description}`;
    });

    return tooltip;
  }

  public logout(): void {
    this.authService.logout();
  }

  public getAreas(): Area[] {
    return this.authService.getAreas();
  }

  public goToRoot(): void {
    this.router.navigate([`/`]);
  }
}
