import { UpdateDimensionsSuccess, LoadAssociationsFailure, UpdateLeadTimesSuccess } from './../actions/configurations-api.actions';
import { PagedResults } from './../../../core/models/page-results.model';
import { GlobalConfiguration } from 'src/app/core/models/global-configuration.model';
import { ConfigurationsApiActions, ConfigurationsActions } from '../actions';
import { GlobalConfigurationStatus } from 'src/app/shared/constants';
import { IGlobalConfigurationAssociation } from 'src/app/core/models/global-configuration-association.model';

export interface State {
  loading: boolean;
  loaded: boolean;
  configuration: GlobalConfiguration;
  configurations: PagedResults<GlobalConfiguration>;
  status: GlobalConfigurationStatus;
  error: string;
  success: string;
  loadingAssociations: boolean;
  associations: Array<IGlobalConfigurationAssociation>;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  configuration: null,
  configurations: new PagedResults<GlobalConfiguration>(),
  status: GlobalConfigurationStatus.None,
  error: '',
  success: '',

  loadingAssociations: false,
  associations: []
};

export function reducer(state = initialState, action: ConfigurationsActions.ConfigurationsActionsUnion | ConfigurationsApiActions.ConfigurationsApiActionsUnion): State {
  switch (action.type) {

    case ConfigurationsActions.ConfigurationsActionTypes.LoadConfiguration: {
      return {
        ...state,
        loading: true,
        loaded: false,
        configuration: null
      };
    }

    case ConfigurationsApiActions.ConfigurationsApiActionTypes.LoadSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        configuration: action.payload
      };
    }

    case ConfigurationsApiActions.ConfigurationsApiActionTypes.LoadFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        configuration: null,
        error: action.payload
      }
    }

    case ConfigurationsActions.ConfigurationsActionTypes.ClearMessages: {
      return {
        ...state,
        error: null,
        success: null,
        status: GlobalConfigurationStatus.None,
      };
    }

    case ConfigurationsActions.ConfigurationsActionTypes.CreateConfiguration: {
      return {
        ...state,
      };
    }

    case ConfigurationsApiActions.ConfigurationsApiActionTypes.CreateSuccess: {
      return {
        ...state,
        configuration: action.payload,
        loading: false,
        loaded: true,
        status: GlobalConfigurationStatus.CreateSuccess
      };
    }

    case ConfigurationsApiActions.ConfigurationsApiActionTypes.CreateFailure: {
      return {
        ...state,
        configuration: null,
        loading: false,
        loaded: false,
        error: action.payload,
        success: null,
        status: GlobalConfigurationStatus.CreateFailure
      };
    }

    case ConfigurationsActions.ConfigurationsActionTypes.DeleteConfiguration: {
      return {
        ...state,
      };
    }

    case ConfigurationsApiActions.ConfigurationsApiActionTypes.DeleteSuccess: {
      return {
        ...state,
        error: null,
        success: `Configuration ${action.payload.globalConfiguration.name} successfully deleted.`,
        status: GlobalConfigurationStatus.DeleteSuccess,
      };
    }

    case ConfigurationsApiActions.ConfigurationsApiActionTypes.DeleteFailure: {
      return {
        ...state,
        error: `Failed to delete configuration.`,
        success: null,
        status: GlobalConfigurationStatus.DeleteFailure,
      };
    }


    case ConfigurationsActions.ConfigurationsActionTypes.DuplicateConfiguration: {
      return {
        ...state,
      };
    }

    case ConfigurationsApiActions.ConfigurationsApiActionTypes.DuplicateSuccess: {
      return {
        ...state,
        error: null,
        success: `Configuration successfully duplicated: ${action.payload.newName}`,
        status: GlobalConfigurationStatus.DuplicateSuccess
      };
    }

    case ConfigurationsApiActions.ConfigurationsApiActionTypes.DuplicateFailure: {
      return {
        ...state,
        error: `Failed to duplicate configuration.`,
        success: null,
        status: GlobalConfigurationStatus.DuplicateFailure,
      };
    }

    case ConfigurationsActions.ConfigurationsActionTypes.RenameConfiguration: {
      return {
        ...state,
      };
    }

    case ConfigurationsApiActions.ConfigurationsApiActionTypes.RenameSuccess: {
      return {
        ...state,
        error: null,
        success: `Successfully renamed to ${action.payload.newName}`,
        status: GlobalConfigurationStatus.RenameSuccess
      };
    }

    case ConfigurationsApiActions.ConfigurationsApiActionTypes.RenameFailure: {
      return {
        ...state,
        error: `Failed to rename configuration.`,
        success: null,
        status: GlobalConfigurationStatus.RenameFailure,
      };
    }

    case ConfigurationsActions.ConfigurationsActionTypes.UpdateConfiguration: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case ConfigurationsApiActions.ConfigurationsApiActionTypes.UpdateSuccess: {
      return {
        ...state,
        error: null,
        success: `${state.configuration.name} was saved successfully.`,
        status: GlobalConfigurationStatus.UpdateSuccess
      };
    }

    case ConfigurationsApiActions.ConfigurationsApiActionTypes.UpdateFailure: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
        success: null,
        status: GlobalConfigurationStatus.UpdateFailure
      };
    }

    case ConfigurationsApiActions.ConfigurationsApiActionTypes.UpdateSKUSuccess: {
      return {
        ...state,
        error: null,
        success: `The SKU(s) got successfully repriced.`,
        status: GlobalConfigurationStatus.UpdateSKUSuccess
      };
    }

    case ConfigurationsApiActions.ConfigurationsApiActionTypes.UpdateSKUFailure: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
        success: null,
        status: GlobalConfigurationStatus.UpdateSKUFailure
      };
    }

    case ConfigurationsApiActions.ConfigurationsApiActionTypes.UpdateDimensionsSuccess: {
      return {
        ...state,
        error: null,
        success: `Dimensions for ${action.payload.globalConfigurationIds.length} configurations were updated successfully.`,
        status: GlobalConfigurationStatus.UpdateDimensionsSuccess
      };
    }

    case ConfigurationsApiActions.ConfigurationsApiActionTypes.UpdateDimensionsFailure: {
      return {
        ...state,
        error: action.payload,
        success: null,
        status: GlobalConfigurationStatus.UpdateDimensionsFailure
      };
    }

    case ConfigurationsApiActions.ConfigurationsApiActionTypes.UpdateModelFilesProfilesSuccess: {
      return {
        ...state,
        error: null,
        success: `Model files profiles for ${action.payload.globalConfigurationIds.length} configurations were updated successfully.`,
        status: GlobalConfigurationStatus.UpdateModelFilesProfilesSuccess
      };
    }

    case ConfigurationsApiActions.ConfigurationsApiActionTypes.UpdateModelFilesProfilesFailure: {
      return {
        ...state,
        error: action.payload,
        success: null,
        status: GlobalConfigurationStatus.UpdateModelFilesProfilesFailure
      };
    }
    
    case ConfigurationsApiActions.ConfigurationsApiActionTypes.UpdateLeadTimesSuccess: {
      return {
        ...state,
        error: null,
        success: `Leadtimes for ${action.payload.globalConfigurationIds.length} configurations were updated successfully.`,
        status: GlobalConfigurationStatus.UpdateLeadtimesSuccess
      };
    }

    case ConfigurationsApiActions.ConfigurationsApiActionTypes.UpdateLeadTimesFailure: {
      return {
        ...state,
        error: action.payload,
        success: null,
        status: GlobalConfigurationStatus.UpdateLeadtimesFailure
      };
    }

    case ConfigurationsActions.ConfigurationsActionTypes.LoadConfigurationsByProductId: {
      return {
        ...state,
        loading: true,
        loaded: false,
        configurations: new PagedResults<GlobalConfiguration>()
      };
    }

    case ConfigurationsApiActions.ConfigurationsApiActionTypes.LoadByProductSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        configurations: action.payload
      };
    }

    case ConfigurationsApiActions.ConfigurationsApiActionTypes.LoadByProductFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        configurations: new PagedResults<GlobalConfiguration>(),
        error: action.payload
      };
    }

    case ConfigurationsActions.ConfigurationsActionTypes.ClearConfigurations: {
      return {
        ...state,
        loading: false,
        loaded: false,
        configurations: new PagedResults<GlobalConfiguration>(),
        error: null
      };
    }

    case ConfigurationsActions.ConfigurationsActionTypes.LoadConfigurationAssociations: {
      return {
        ...state,
        loadingAssociations: true,
        associations: []
      };
    }

    case ConfigurationsApiActions.ConfigurationsApiActionTypes.LoadAssociationsSuccess: {
      return {
        ...state,
        loadingAssociations: false,
        associations: action.payload
      };
    }

    case ConfigurationsApiActions.ConfigurationsApiActionTypes.LoadAssociationsFailure: {
      return {
        ...state,
        loadingAssociations: false,
        associations: [],
      }
    }

    default:
      return state;
  }
}

export const isLoading = (state: State) => state.loading;
export const isLoaded = (state: State) => state.loaded;
export const getLoadedConfiguration = (state: State) => state.configuration;
export const getConfigurations = (state: State) => state.configurations;
export const getErrorMessage = (state: State) => state.error;
export const getSuccessMessage = (state: State) => state.success;
export const getStatus = (state: State) => state.status;

export const isLoadingAssociations = (state: State) => state.loadingAssociations;
export const getLoadedAssociations = (state: State) => state.associations;
