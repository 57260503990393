import { Action } from '@ngrx/store';
import { GlobalSubcategoryPost } from 'src/app/core/models/global-subcategory-post.model';
import { GlobalSubcategoryPut } from 'src/app/core/models/global-subcategory-put.model';

export enum GlobalSubcategoryActionTypes {
  LoadGlobalSubcategory = '[GlobalSubcategory] Load Global Subcategory',
  CreateGlobalSubcategory = '[GlobalSubcategory] Create Global Subcategory',
  UpdateGlobalSubcategory = '[GlobalSubcategory] Update Global Subcategory',
  RenameGlobalSubcategory = '[GlobalSubcategory] Rename Global Subcategory',
  DeleteGlobalSubcategory = '[GlobalSubcategory] Delete Global Subcategory',
  ClearMessages = '[GlobalSubcategory] Clear Messages',
}

export class LoadGlobalSubcategory implements Action {
  readonly type = GlobalSubcategoryActionTypes.LoadGlobalSubcategory;

  constructor(public payload: number) { }
}

export class CreateGlobalSubcategory implements Action {
  readonly type = GlobalSubcategoryActionTypes.CreateGlobalSubcategory;

  constructor(public payload: GlobalSubcategoryPost) { }
}

export class UpdateGlobalSubcategory implements Action {
  readonly type = GlobalSubcategoryActionTypes.UpdateGlobalSubcategory;

  constructor(public payload: { globalSubcategory: GlobalSubcategoryPut, globalSubcategoryName: string }) { }
}

export class RenameGlobalSubcategory implements Action {
  readonly type = GlobalSubcategoryActionTypes.RenameGlobalSubcategory;

  constructor(public payload: { globalSubcategoryId: number, globalSubcategoryName: string }) { }
}

export class DeleteGlobalSubcategory implements Action {
  readonly type = GlobalSubcategoryActionTypes.DeleteGlobalSubcategory;

  constructor(public payload: { globalSubcategoryId: number, globalSubcategoryName: string }) { }
}

export class ClearMessages implements Action {
  readonly type = GlobalSubcategoryActionTypes.ClearMessages;

  constructor() { }
}

export type GlobalSubcategoryActionsUnion =
  LoadGlobalSubcategory
  | CreateGlobalSubcategory
  | UpdateGlobalSubcategory
  | RenameGlobalSubcategory
  | DeleteGlobalSubcategory
  | ClearMessages;
