import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { CustomValidatorsService } from '../../../core/services/custom-validators.service';
import { CrossFieldErrorMatcher } from 'src/app/shared/services/cross-field-error-matcher';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-name-modal',
  templateUrl: './name-modal.component.html',
  styleUrls: ['./name-modal.component.scss']
})
export class NameModalComponent implements OnInit {
  errorMatcher = new CrossFieldErrorMatcher();
  subscriptions: SubSink = new SubSink();
  nameForm: FormGroup = new FormGroup({
    name: new FormControl('',{ validators: [Validators.required], asyncValidators: [this.customValidatorsService.validateUniqueTemplate(this.data.integrationId)]})
  }); ;
  result: { id: number, name: string, isValid: boolean };

  constructor(
    public dialogRef: MatDialogRef<NameModalComponent>,
    private customValidatorsService: CustomValidatorsService,
    @Inject(MAT_DIALOG_DATA) public data: {
      id: number,
      name: string,
      title: string,
      fieldName: string,
      integrationId: number,
      placeholder: string,
      buttonText: string
      prefillWithName: boolean
    }) { }

  ngOnInit() {
    this.result = { id: this.data.id, name: this.data.name, isValid: false };
    if (this.data.prefillWithName) {
      this.nameForm.setValue({ name: this.data.name });
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.nameForm.controls[controlName].hasError(errorName);
  }

  applyName() {
    console.log(this.nameForm.value);
    if (!this.nameForm.valid) { return; }

    this.result.id = this.data.id;
    this.result.name = this.nameForm.value.name;
    this.result.isValid = true;

    this.dialogRef.close(this.result);
  }

}
