import { assign as _assign } from 'lodash-es';

export interface IApplicationTagRenamePut {
  tag: string;
}

export class ApplicationTagRenamePut implements IApplicationTagRenamePut {
  tag = '';

  constructor(data?: IApplicationTagRenamePut) {
    if (data) {
      _assign(this, data);
    }
  }
}
