import { ApplicationSetting, IApplicationSetting } from './application-setting.model';
import { Enclosure, IEnclosure } from './enclosure.model';
import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import { assign as _assign } from 'lodash-es';
import { IApplicationOption, ApplicationOption } from './application-option.model';
import { IOrientation, Orientation } from './orientation.model';
import { IPosture, Posture } from './posture.model';
import { INumberOfPeople, NumberOfPeople } from './numberOfPeople.model';
import { IFlexibility, Flexibility } from './flexibility.model';
import { IApplicationStorage, ApplicationStorage } from './application-storage.model';
import { IApplicationWorkerProfile, ApplicationWorkerProfile } from './application-worker-profile.model';
import { IApplicationWorkMode, ApplicationWorkMode } from './application-work-mode.model';
import { IApplicationTeamType, ApplicationTeamType } from './application-team-type.model';
import { ITechnologyLevel, TechnologyLevel } from './technology-level.model';
import { IApplicationTagRelationship, ApplicationTagRelationship } from './application-tag-relationship.model';
import { ApplicationStatus } from './application-status.model';
import { IRotation } from './rotation.model';

export interface IApplication extends IBaseAuditModel {
  name: string;
  code: string;
  defaultImage: string;
  shortDescription: string;
  longDescription: string;
  isPlanningIdea: boolean;
  frontWidth?: number;
  depth?: number;
  height?: number;
  footprintX?: number;
  footprintY?: number;
  visualPrivacy?: number;
  spaceSize?: number;
  acousticPrivacy?: number;
  noisePotential?: number;
  frontCirculation?: number;
  otherCirculation?: number;
  positiveFlex?: number;
  negativeFlex?: number;
  rotationId?: number;
  rotation: IRotation;
  enclosureId?: number;
  enclosure: IEnclosure;
  orientationId?: number;
  orientation: IOrientation;
  postureId?: number;
  posture: IPosture;
  numberOfPeopleId?: number;
  numberOfPeople: INumberOfPeople;
  flexibilityId?: number;
  flexibility: IFlexibility;
  technologyLevelId?: number;
  technologyLevel: ITechnologyLevel;
  status?: number;
  havenSeatsId?: number;
  havenSeats: INumberOfPeople;
  havenOtherSeatsId?: number;
  havenOtherSeats: INumberOfPeople;
  hiveSeatsId?: number;
  hiveSeats: INumberOfPeople;
  jumpSpaceSeatsId?: number;
  jumpSpaceSeats: INumberOfPeople;
  coveSeatsId?: number;
  coveSeats: INumberOfPeople;
  meetingSpaceSeatsId?: number;
  meetingSpaceSeats: INumberOfPeople;
  havenNumberofPeopleId?: number;
  havenNumberofPeople: INumberOfPeople;
  havenOtherNumberofPeopleId?: number;
  havenOtherNumberofPeople: INumberOfPeople;
  hiveNumberofPeopleId?: number;
  hiveNumberofPeople: INumberOfPeople;
  jumpSpaceNumberofPeopleId?: number;
  jumpSpaceNumberofPeople: INumberOfPeople;
  coveNumberofPeopleId?: number;
  coveNumberofPeople: INumberOfPeople;
  meetingSpaceNumberofPeopleId?: number;
  meetingSpaceNumberofPeople: INumberOfPeople;
  areaTotal?: number;
  circulationSqFt?: number;
  havenSqft?: number;
  havenOtherSqft?: number;
  hiveSqft?: number;
  jumpspaceSqft?: number;
  coveSqft?: number;
  meetingSpaceSqft?: number;
  hiveDeskWidth?: number;
  hiveDeskDepth?: number;
  hiveFootprintX?: number;
  hiveFootprintY?: number;
  applicationStatus: ApplicationStatus;
  applicationSettings: Array<IApplicationSetting>;
  applicationStorages: Array<IApplicationStorage>;
  applicationOptions: Array<IApplicationOption>;
  applicationWorkerProfiles: Array<IApplicationWorkerProfile>;
  applicationWorkModes: Array<IApplicationWorkMode>;
  applicationTeamTypes: Array<IApplicationTeamType>;
  applicationTags: Array<IApplicationTagRelationship>;
}

export class Application extends BaseAuditModel implements IApplication {
  name: string;
  code: string;
  defaultImage: string;
  shortDescription: string;
  longDescription: string;
  isPlanningIdea: boolean;
  frontWidth?: number;
  depth?: number;
  height?: number;
  footprintX?: number;
  footprintY?: number;
  visualPrivacy?: number;
  spaceSize?: number;
  acousticPrivacy?: number;
  noisePotential?: number;
  frontCirculation?: number;
  otherCirculation?: number;
  positiveFlex?: number;
  negativeFlex?: number;
  rotationId?: number;
  rotation: IRotation;
  enclosureId?: number;
  enclosure: Enclosure;
  orientationId?: number;
  orientation: Orientation;
  postureId?: number;
  posture: Posture;
  numberOfPeopleId?: number;
  numberOfPeople: NumberOfPeople;
  flexibilityId?: number;
  flexibility: Flexibility;
  technologyLevelId?: number;
  technologyLevel: TechnologyLevel;
  status?: number;
  havenSeatsId?: number;
  havenSeats: NumberOfPeople;
  havenOtherSeatsId?: number;
  havenOtherSeats: NumberOfPeople;
  hiveSeatsId?: number;
  hiveSeats: NumberOfPeople;
  jumpSpaceSeatsId?: number;
  jumpSpaceSeats: NumberOfPeople;
  coveSeatsId?: number;
  coveSeats: NumberOfPeople;
  meetingSpaceSeatsId?: number;
  meetingSpaceSeats: NumberOfPeople;
  havenNumberofPeopleId?: number;
  havenNumberofPeople: NumberOfPeople;
  havenOtherNumberofPeopleId?: number;
  havenOtherNumberofPeople: NumberOfPeople;
  hiveNumberofPeopleId?: number;
  hiveNumberofPeople: NumberOfPeople;
  jumpSpaceNumberofPeopleId?: number;
  jumpSpaceNumberofPeople: NumberOfPeople;
  coveNumberofPeopleId?: number;
  coveNumberofPeople: NumberOfPeople;
  meetingSpaceNumberofPeopleId?: number;
  meetingSpaceNumberofPeople: NumberOfPeople;
  areaTotal?: number;
  circulationSqFt?: number;
  havenSqft?: number;
  havenOtherSqft?: number;
  hiveSqft?: number;
  jumpspaceSqft?: number;
  coveSqft?: number;
  meetingSpaceSqft?: number;
  hiveDeskWidth?: number;
  hiveDeskDepth?: number;
  hiveFootprintX?: number;
  hiveFootprintY?: number;
  applicationStatus: ApplicationStatus;
  applicationSettings: Array<ApplicationSetting> = [];
  applicationStorages: Array<ApplicationStorage> = [];
  applicationOptions: Array<ApplicationOption> = [];
  applicationWorkerProfiles: Array<ApplicationWorkerProfile> = [];
  applicationWorkModes: Array<ApplicationWorkMode> = [];
  applicationTeamTypes: Array<ApplicationTeamType> = [];
  applicationTags: Array<ApplicationTagRelationship> = [];

  constructor(data?: IApplication) {
    super(data);

    if (!data) {
      return;
    }

    _assign(this, data);
  }
}
