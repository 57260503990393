

interface Number {
  roundToTwo(this: number): number;
}

// roundToTwo Extension.  This takes a number and rounds it to the nearest two decimal places. Examples: 1.23456 = 1.23, 1.3 = 1.3, 1.555 = 1.56, 1 = 1
function roundToTwo(this: number | null): number {
  if (!this) { return null; }
  return +(Math.round((this as unknown as number) + ('e+2' as unknown as number)) + ('e-2' as unknown as number));
}

Number.prototype.roundToTwo = roundToTwo;
