import { IBaseAuditModel } from './../../../core/models/base-audit.model';
import { Component, Input, OnInit } from '@angular/core';
import { BaseAuditModelService } from 'src/app/core/services/base-audit-model.service';

@Component({
  selector: 'app-header-detail-title',
  templateUrl: './header-detail-title.component.html',
  styleUrls: ['./header-detail-title.component.scss']
})
export class HeaderDetailTitleComponent implements OnInit {
  @Input() title: string;
  @Input() placeholder: string = 'Not Loaded';
  @Input() model: IBaseAuditModel = null;

  constructor(private baseAuditModelService: BaseAuditModelService) { }

  ngOnInit(): void {
  }

  public getAuditableToolTip(): string {
    if (!this.model) {
      return;
    }

    return this.baseAuditModelService.getAuditableTooltip(this.model);
  }
}
