import { Locale } from '../../../core/models/locale.model';
import { RegionsService } from '../../../core/services/regions.service';
import { Component, Input } from '@angular/core';
import { Region } from 'src/app/core/models/region.model';

@Component({
  selector: 'app-locales-selected',
  templateUrl: './locales-selected.component.html',
  styleUrls: ['./locales-selected.component.scss']
})
export class LocalesSelectedComponent {
  @Input() regions: Array<Region> = [];
  @Input() selectedLocales: Array<Locale> = [];

  get selectedRegions(): Array<Region> {
    if (!this.selectedLocales) { return []; }
    return this.regionsService.getFilteredRegionsAndLocales(this.regions, this.selectedLocales);
  }

  getSelectLocalesForRegion(regionId: number): Array<Locale> {
    if (!this.selectedLocales) { return []; }
    return this.selectedLocales.filter(l => l.regionId === regionId);
  }

  constructor(private regionsService: RegionsService) { }

}
