import { IBaseAuditModel, BaseAuditModel } from './base-audit.model';
import { assign as _assign } from 'lodash-es';

export interface IProductNumberPrice extends IBaseAuditModel {
  productNumberId: number;
  currencyId: number;
  contractId?: number;
  priceDate: Date;
  basePrice: number;
  totalListPrice: number;
  contractBasePrice: number;
  contractListPrice: number;
  discountApplied: number;
  netPrice: number;
  isValid: boolean;
  message: string;
  priceUpdateSystemTypeId: number;
  dynamicPricingDisabled: boolean;
}

export class ProductNumberPrice extends BaseAuditModel implements IProductNumberPrice {
  productNumberId: number;
  currencyId: number;
  contractId?: number;
  priceDate: Date;
  basePrice: number;
  totalListPrice: number;
  contractBasePrice: number;
  contractListPrice: number;
  discountApplied: number;
  netPrice: number;
  isValid: boolean;
  message: string;
  priceUpdateSystemTypeId: number;
  dynamicPricingDisabled: boolean;

  constructor(data?: IProductNumberPrice) {
    super(data);
    if (!data) {
      return;
    }
    _assign(this, data);
  }
}
