import { ApplicationOptionsService } from 'src/app/core/services/application-options.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import * as fromStore from '../../../app-store/reducers';

import {
  ApplicationApiActions,
  ApplicationActions
} from '../actions';

import { switchMap, map, catchError, tap, mergeMap, take } from 'rxjs/operators';
import { ApplicationsService } from 'src/app/core/services/applications.service';
import { of, Observable } from 'rxjs';

@Injectable()
export class ApplicationEffects {

  @Effect()
  loadApplication$ = this.actions$.pipe(
    ofType(ApplicationActions.ApplicationActionTypes.LoadApplication),
    map((action: ApplicationActions.LoadApplication) => action.payload),
    switchMap(applicationId =>
      this.applicationsService.getApplication$(applicationId).pipe(
        map(
          (application) => new ApplicationApiActions.LoadSuccess(application)
        ),
        catchError(error => {
          if (error.status === 404) {
            this.router.navigate(['/error'], { queryParams: { type: 'notFound' } });
          }
          return of(new ApplicationApiActions.LoadFailure(error))
        })
      )
    )
  );

  @Effect()
  createApplication$ = this.actions$.pipe(
    ofType<ApplicationActions.CreateApplication>(ApplicationActions.ApplicationActionTypes.CreateApplication),
    switchMap((request) =>
      this.applicationsService.createApplication$(request.payload).pipe(
        map((application) =>
          new ApplicationApiActions.CreateSuccess(application)
        ),
        tap((response) => {
          // Navigate to the page in edit mode.
          this.router.navigate([`applications/application-detail/${response.payload.id}`], { queryParams: { edit: true } });
        }),
        catchError(error =>
          of(new ApplicationApiActions.CreateFailure(error))
        )
      )
    )
  );

  @Effect()
  updateApplication$: Observable<Action> = this.actions$.pipe(
    ofType<ApplicationActions.UpdateApplication>(ApplicationActions.ApplicationActionTypes.UpdateApplication),
    switchMap(request =>
      this.applicationsService.updateApplication$(request.payload.application).pipe(
        map((response) => new ApplicationApiActions.UpdateSuccess({ application: request.payload.application, applicationName: request.payload.applicationName })),
        catchError(error => of(new ApplicationApiActions.UpdateFailure(error)))
      )
    )
  );

  @Effect()
  updateApplicationSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(ApplicationApiActions.ApplicationApiActionTypes.UpdateSuccess),
    map((action: ApplicationApiActions.UpdateSuccess) => action.payload),
    map(payload =>
      // Reload the application from the API if the save was successful.
      new ApplicationActions.LoadApplication(payload.application.id)
    )
  );

  @Effect()
  deleteApplication$ = this.actions$.pipe(
    ofType<ApplicationActions.DeleteApplication>(ApplicationActions.ApplicationActionTypes.DeleteApplication),
    switchMap((request) =>
      this.applicationsService.deleteApplication$(request.payload.applicationId).pipe(
        map((response) =>
          new ApplicationApiActions.DeleteSuccess({ applicationId: request.payload.applicationId, applicationName: request.payload.applicationName })
        ),
        catchError(error =>
          of(new ApplicationApiActions.DeleteFailure(error))
        )
      )
    )
  );

  @Effect()
  deleteApplicationOption$ = this.actions$.pipe(
    ofType<ApplicationActions.DeleteApplicationOption>(ApplicationActions.ApplicationActionTypes.DeleteApplicationOption),
    switchMap((request) =>
      this.applicationOptionsService.deleteApplicationOption$(request.payload.applicationOptionId).pipe(
        tap(() =>
          this.store.dispatch(new ApplicationActions.LoadApplication(request.payload.applicationId))
        ),
        map((response) =>
          new ApplicationApiActions.DeleteOptionSuccess({ applicationOptionId: request.payload.applicationOptionId })
        ),
        catchError(error =>
          of(new ApplicationApiActions.DeleteOptionFailure(error))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<fromStore.State>,
    private applicationsService: ApplicationsService,
    private applicationOptionsService: ApplicationOptionsService) { }

}
