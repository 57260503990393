import { DamProviderIds } from './../../../constants';
import { ImagesService } from '../../../../core/services/images.service';
import { DocumentAsset } from '../../../../core/models/document-asset.model';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Subscription, fromEvent, Observable } from 'rxjs';
import { Component, OnInit, Input, AfterViewInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { AddProductLiteratureModalDataSource } from '../add-product-literature-modal.datasource';
import { SelectionModel } from '@angular/cdk/collections';
import { SubSink } from 'subsink';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../../app-store/reducers';
import { DamAssetMetapropertyOption } from 'src/app/core/models/dam-asset-metaproperty-option.model';

@Component({
  selector: 'app-product-literature-grid',
  templateUrl: './product-literature-grid.component.html',
  styleUrls: ['./product-literature-grid.component.scss']
})
export class ProductLiteratureGridComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('search') input: ElementRef;
  @Input() datasource: AddProductLiteratureModalDataSource;
  subscriptions: Array<Subscription> = [];
  public damProviders = DamProviderIds;
  public selectedDamProviderId: number = 1;
  subs = new SubSink();
  loadAssetPurpose$: Observable<DamAssetMetapropertyOption[]>;
  loadMediaSubType$: Observable<DamAssetMetapropertyOption[]>;
  loadAssetPurposeOmni$: Observable<DamAssetMetapropertyOption[]>;

  readonly columnDefSelect: string = 'select';
  readonly columnDefName: string = 'name';
  readonly columnDefDocumentTypeName: string = 'documentTypeName';
  readonly columnDefLocales: string = 'locales';
  readonly columnDefOpenDocument: string = 'openDocument';
  displayedColumns: string[] = [
    this.columnDefSelect,
    this.columnDefName,
    this.columnDefDocumentTypeName,
    this.columnDefLocales,
    this.columnDefOpenDocument
  ];

  constructor(private imagesService: ImagesService,
    private store: Store<fromStore.State>) { }

  ngOnInit() {
    this.loadAssetPurpose$ = this.store.select(fromStore.getLoadedDamAssetPurposeMetapropertyOptions);
    this.loadMediaSubType$ = this.store.select(fromStore.getLoadedDamAssetMetapropertyOptions);
    this.loadAssetPurposeOmni$ = this.store.select(fromStore.getLoadedDamAssetPurposeMetapropertyOmniOptions);
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngAfterViewInit() {
    // search
    this.subscriptions.push(
      fromEvent(this.input.nativeElement, 'keyup').pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => {
          this.datasource.search(this.input.nativeElement.value, this.selectedDamProviderId);
        })
      ).subscribe()
    );
  }

  public onDamProviderSelectionChanged(value: number) {
    this.selectedDamProviderId = value;
    this.datasource.selection = new SelectionModel<DocumentAsset>(true, []);
    this.datasource.search(this.input?.nativeElement?.value, this.selectedDamProviderId);
  }

  onScrolled() {
    this.datasource.getNextPage();
  }

  public getDamProviders(damProviderIds: Array<{ index: number, name: string }>) {
    return damProviderIds.filter(x =>
      x.index === DamProviderIds.Bynder ||
      x.index === DamProviderIds.Aem);
  }

  isCheckBoxDisabled(document: DocumentAsset): boolean {
    return this.datasource.isPreSelected(document);
  }

  getDocumentTitle(document: DocumentAsset): string {
    if (document.title) {
      return document.title;
    }

    if (document.url) {
      return this.imagesService.getName(document.url);
    }

    return 'None';
  }

  getLocaleTooltip(document: DocumentAsset): string {
    let tooltip = '';

    [...document.locales].map(l => {
      if (tooltip.length > 0) {
        tooltip = `${tooltip}\n `;
      }

      tooltip = `${tooltip}${l.code}`;
    });

    return tooltip;
  }

  showDocument($event, documentAsset: DocumentAsset): void {
    $event.stopPropagation();
    window.open(documentAsset.url, '_blank');
  }

}
