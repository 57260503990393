import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import * as _ from 'lodash';
import { TechnologyLevel } from './technology-level.model';

export interface ITechnology extends IBaseAuditModel {
  name: string;
  technologyLevel: TechnologyLevel;
}

export class Technology extends BaseAuditModel implements ITechnology {
  name: string;
  technologyLevel: TechnologyLevel;

  constructor(data: ITechnology) {
    super(data);

    if (!data) {
      return;
    }

    _.assign(this, data);
  }
}
