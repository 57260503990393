import { MaterialApiActions, MaterialActions } from '../actions';
import { Material } from 'src/app/core/models/material.model';

export interface State {
  materials: Array<Material>;
  loading: boolean;
  loaded: boolean;
  error: string;
}

export const initialState: State = {
  materials: [],
  loading: false,
  loaded: false,
  error: null
};

export function reducer(state = initialState, action: MaterialActions.MaterialActionsUnion | MaterialApiActions.MaterialApiActionsUnion): State {
  switch (action.type) {

    case MaterialActions.MaterialActionTypes.LoadMaterials: {
      return {
        ...state,
        loading: true,
        loaded: false,
        materials: [],
        error: null
      };
    }

    case MaterialApiActions.MaterialApiActionTypes.FetchSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        materials: action.payload.materials,
        error: null
      };
    }

    case MaterialApiActions.MaterialApiActionTypes.FetchFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        materials: [],
        error: action.payload
      };
    }

    default:
      return state;
  }
}

export const getMaterials = (state: State) => state.materials;
export const getErrorMessage = (state: State) => state.error;
export const isLoading = (state: State) => state.loading;
export const isLoaded = (state: State) => state.loaded;
