<div class="material-modal-container">
  <div class="material-modal-title-container">
    <div class="material-modal-title">
      <strong>{{material.name}}</strong>
    </div>
    <div>
      <small>{{materialColor.name}}</small>
    </div>
  </div>

  <div mat-dialog-content>
    <div class="material-modal-content-container">
      <div class="material-modal-image-container">
        <img class="material-image" src="{{materialColor.imageUrl}}" />
      </div>

      <div class="material-modal-detail-container">
        <div>
          <h6 class="h6"><strong>Material Details</strong></h6>
        </div>

        <div class="material-details">
          <div>
            <strong>Introduced: </strong>
            <span *ngIf="material?.introductionDate">{{material.introductionDate | date:'MMM d, y'}}</span>
          </div>

          <div>
            <strong>Option:</strong>
            <mat-icon class="material-info" matTooltip="Series: {{material.seriesNumber}}">
              info_outline
            </mat-icon>
            <span *ngIf="materialColor?.optionId"> {{materialColor?.optionId}}</span>
          </div>

          <div>
            <strong>Material Brand: </strong>
            <span *ngIf="material?.materialBrand">{{material.materialBrand}}</span>
          </div>

          <div>
            <strong>Abrasion: </strong>
            <span *ngIf="material?.abrasion > -1">{{material.abrasion}}</span>
            <span *ngIf="material.abrasionType"> ({{material.abrasionType}})</span>
          </div>

          <div>
            <strong>Lightfastness: </strong>
            <span *ngIf="material?.lightFastnessGrade">Grade {{material.lightFastnessGrade}}</span>
            <span *ngIf="material?.lightFastnessHour"> ({{material.lightFastnessHour}} Hours)</span>
          </div>

          <div>
            <strong>Flammability: </strong>
            <span *ngFor="let flammability of material.flammability; let i = index">
              <span *ngIf="i > 0">, </span>
              <span>{{flammability.code}}</span>
            </span>
          </div>

          <div>
            <strong>Origin: </strong>
            <span *ngIf="material?.origin">{{material.origin}}</span>
          </div>
        </div>

        <div class="act-symbols">
          <div class="act-symbol" *ngFor="let actSymbol of material.actSymbols">
            <img
              [src]="actSymbol.actSymbol.symbolUrl"
              [alt]="actSymbol.actSymbol.name"
              [matTooltip]="actSymbol.actSymbol.name" />
          </div>
        </div>

        <div class="fabric-contents" *ngIf="material.fabricContents?.length > 0">
          <canvas baseChart
            width="9"
            height="6"
            [legend]="false"
            [data]="chartData"
            [labels]="chartLabels" ></canvas>
        </div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions>
    <button class="mat-raised-button button-spacing" mat-dialog-close>Close</button>
  </div>
</div>
