import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SelectOptions, ISelectOptions } from '../models/select-options.model';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError as observableThrowError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelectOptionsService {
  basePath = 'selectoptions';
  constructor(private http: HttpClient) { }

  public getSelectOptions$(optionsToInclude?: string[]): Observable<SelectOptions> {
    let params = new HttpParams();

    if (optionsToInclude && optionsToInclude.length > 0) {
      params = params.append('include', optionsToInclude.join(',').toLowerCase());
    }

    return this.http
      .get(environment.apiUrl + `${this.basePath}`, { params })
      .pipe(
          map(res => res),
          catchError((err: Response) => {
            return observableThrowError(err);
          }),
          map((x: ISelectOptions) => new SelectOptions(x))
      );
  }
}
