import { EndpointsSummary } from '../../../core/models/endpoints-summary.model';
import { EndpointSummary } from '../../../core/models/endpoint-summary.model';
import { cloneDeep as _cloneDeep } from 'lodash-es';
import {
  EndpointsApiActions,
  EndpointsActions
} from '../actions';
import { EndpointStatus } from 'src/app/shared/constants';

export interface State {
  endpointsSummaryList: Array<EndpointsSummary>;
  loading: boolean;
  loaded: boolean;
  error: string;
  success: string;
  status: EndpointStatus;
}

export const initialState: State = {
  endpointsSummaryList: [],
  loading: false,
  loaded: false,
  error: '',
  success: null,
  status: EndpointStatus.None
};

export function reducer(
  state = initialState,
  action: EndpointsActions.EndpointsActionsUnion | EndpointsApiActions.EndpointsApiActionsUnion): State {

  switch (action.type) {

    case EndpointsActions.EndpointsActionTypes.LoadEndpoints: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case EndpointsApiActions.EndpointsApiActionTypes.LoadSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        endpointsSummaryList: action.payload
      };
    }

    case EndpointsApiActions.EndpointsApiActionTypes.LoadFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        endpointsSummaryList: [],
        error: action.payload
      };
    }

    case EndpointsActions.EndpointsActionTypes.CreateEndpoint: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case EndpointsApiActions.EndpointsApiActionTypes.CreateSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }

    case EndpointsApiActions.EndpointsApiActionTypes.CreateFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };
    }

    case EndpointsActions.EndpointsActionTypes.RenameEndpoint: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case EndpointsActions.EndpointsActionTypes.ClearMessages: {
      return {
        ...state,
        status: EndpointStatus.None,
        error: null,
        success: null
      };
    }

    case EndpointsApiActions.EndpointsApiActionTypes.RenameSuccess: {
      const summaries = _cloneDeep([...state.endpointsSummaryList]);

      // Locate the integration that needs a template added to it, and add the template.
      const summary = summaries.find(i => i.id === action.payload.integrationId);
      if (summary != null && summary.endpoints != null) {
        const template = summary.endpoints.find(t => t.id === action.payload.endpointId);
        if (template) {
          template.name = action.payload.endpointName;
        }
      }

      return {
        ...state,
        endpointsSummaryList: summaries,
        loading: false,
        loaded: true,
        status: EndpointStatus.RenameSuccess,
        success: `Successfully renamed endpoint to ${action.payload.endpointName}`,
        error: null
      };
    }

    case EndpointsApiActions.EndpointsApiActionTypes.RenameFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };
    }

    case EndpointsActions.EndpointsActionTypes.DuplicateEndpoint: {
      return {
        ...state,
        loading: true,
        status: EndpointStatus.None,
      };
    }

    case EndpointsApiActions.EndpointsApiActionTypes.DuplicateSuccess: {
      const summaries = _cloneDeep([...state.endpointsSummaryList]);

      // Locate the integration that needs a template added to it, and add the template.
      const summary = summaries.find(i => i.id === action.payload.integrationId);
      if (summary != null && summary.endpoints != null) {
        summary.endpoints
          .push({
            id: action.payload.id,
            name: action.payload.name,
            localeName: action.payload.localeName,
            languageName: action.payload.languageName,
            globalProductOfferingsCount: 0
          } as EndpointSummary);
      }

      return {
        ...state,
        endpointsSummaryList: summaries,
        loading: false,
        status: EndpointStatus.DuplicateSuccess,
        success: `Successfully duplicated endpoint to ${action.payload.name}`,
        error: null,
      };
    }

    case EndpointsApiActions.EndpointsApiActionTypes.DuplicateFailure: {
      return {
        ...state,
        loading: false,
        status: EndpointStatus.DuplicateFailure,
        error: action.payload
      };
    }

    case EndpointsActions.EndpointsActionTypes.DeleteEndpoint: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case EndpointsApiActions.EndpointsApiActionTypes.DeleteSuccess: {
      const summaries = _cloneDeep([...state.endpointsSummaryList]);

      summaries.forEach(s => {
        s.endpoints.forEach((endpoint, index) => {
          if (endpoint.id === action.payload.endpointId) {
            s.endpoints.splice(index, 1);
          }
        });
      });

      return {
        ...state,
        loading: false,
        loaded: true,
        endpointsSummaryList: summaries,
        success: `Successfully deleted Endpoint: ${action.payload.endpointName}`,
        status: EndpointStatus.DeleteSuccess,
        error: null
      };
    }

    case EndpointsApiActions.EndpointsApiActionTypes.DeleteFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };
    }

    default:
      return state;
  }
}

export const getEndpoints = (state: State) => state.endpointsSummaryList;
export const isLoading = (state: State) => state.loading;
export const isLoaded = (state: State) => state.loaded;
export const getErrorMessage = (state: State) => state.error;
export const getSuccessMessage = (state: State) => state.success;
export const getStatus = (state: State) => state.status;
