import { Injectable } from '@angular/core';
import './../../shared/extensions';

@Injectable({
  providedIn: 'root'
})
export class DimensionService {

  constructor() { }

  getMillimetersFromInches(inches?: number): number {
    const MILLIMETERS_PER_INCH = 25.4;
    return !inches ? null : inches * MILLIMETERS_PER_INCH;
  }

  getMetricFromInches(inches?: number): {meters: number, centimeters: number, millimeters: number} {
    if (!inches) {
      return null;
    }
    const MILLIMETERS_PER_METER = 1000;
    const MILLIMETERS_PER_CENTIMETER = 10;
    let millimeters = this.getMillimetersFromInches(inches);

    const meters = Math.floor(millimeters / MILLIMETERS_PER_METER);
    if (meters >= 1) {
      millimeters = millimeters % MILLIMETERS_PER_METER;
    }

    const centimeters = Math.floor(millimeters / MILLIMETERS_PER_CENTIMETER);
    if (centimeters >= 1) {
      millimeters = millimeters % MILLIMETERS_PER_CENTIMETER;
    }

    if (millimeters > 0) { millimeters = millimeters.roundToTwo(); }
    return { meters, centimeters, millimeters};
  }
}
