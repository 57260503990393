import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-content-title-weblink-readonly',
  templateUrl: './card-content-title-weblink-readonly.component.html',
  styleUrls: ['./card-content-title-weblink-readonly.component.scss']
})
export class CardContentTitleWeblinkReadonlyComponent {
  @Input() webLink: string;
  @Input() title: string;
  @Input() subtitle: string;

  constructor() { }
}
