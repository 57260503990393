import { UOMStandards } from './../../constants';
import { Component, OnInit, Input } from '@angular/core';
import './../../extensions';

@Component({
  selector: 'app-dimension-range',
  templateUrl: './dimension-range.component.html',
  styleUrls: ['./dimension-range.component.scss']
})
export class DimensionRangeComponent implements OnInit {

  @Input() min?: number = null;
  @Input() max?: number = null;
  @Input() unitOfMeasure: UOMStandards = UOMStandards.FeetInches;

  constructor() { }

  ngOnInit() {
  }
}
