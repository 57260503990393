<div *ngFor="let ghostItem of ghostItems">
  <div fxLayout="row" fxLayoutGap="10px" class="ghost-header">
    <div>
      <img
      class="ghost-header-img"
      mat-card-avatar
      src="../../../../assets/images/placeholders/image_placeholder.png" />
    </div>
    <div fxFlex class="ghost-header-text lines"></div>
  </div>

  <div *ngFor="let ghostSubItem of ghostItem.ghostSubItems">
    <div class="ghost-container">
      <div fxLayout="column" class="ghost-container-left">
        <div fxLayout="row">
          <div fxFlex="25%" class="ghost-name lines"></div>
        </div>
        <div fxLayout="row">
          <div fxFlex="50%" class="lines"></div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
  </div>
</div>
