import { ProductNumberPut } from './../models/product-number-put.model';
import { ProductNumber } from './../models/product-number.model';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { PagedResults } from './../models/page-results.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IProductNumberPrice } from './../models/product-number-price.model';
import { Injectable } from '@angular/core';
import { IProductNumber } from '../models/product-number.model';
import { environment } from 'src/environments/environment';
import { ProductNumberMatch } from '../models/product-number-match.model';
import { ContractIds } from 'src/app/shared/constants';

export enum PriceUpdateSystemTypes {
  QuoteTool = 1,
  PDM,
  DynamicPricingAPI,
  CPQ,
  SIF
}

@Injectable({
  providedIn: 'root'
})
export class ProductNumbersService {
  basePath = 'productnumbers';

  constructor(private http: HttpClient) { }

  getProductNumberMatches$(searchTerm: string = '', pageNumber: number, pageSize: number, sortActive: string, sortOrder: string): Observable<PagedResults<ProductNumberMatch>> {
    return this.http.get(`${environment.apiUrl}${this.basePath}/matches`, {
      params: new HttpParams()
        .set('search', searchTerm)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
        .set('orderBy', sortActive.toString())
        .set('sortOrder', sortOrder.toString())
    })
      .pipe(
        map((response: PagedResults<ProductNumberMatch>) => response)
      );
  }

  getDuplicates$(fullProductNumber: string): Observable<Array<ProductNumber>> {
    const request: ProductNumberPut = new ProductNumberPut({ id: 0, fullProductNumber });

    return this.http
      .post(`${environment.apiUrl}${this.basePath}/duplicates`, request)
      .pipe(
        map(response => response),
        catchError((error: Response) => {
          return throwError(error);
        }),
        map((response: Array<ProductNumber>) => response)
      );
  }

  public getBestPrice(productNumber: IProductNumber, currencyId: number, contractId: number): IProductNumberPrice {

    // Attempt to get the contract price if one exists.
    let price = this.getPrice(productNumber, currencyId, contractId);
    if (price) {
      return price;
    }

    // Attempt to get the non-contract price if no contract price exists.
    price = this.getPrice(productNumber, currencyId, null);
    if (price) {
      return price;
    }

    // Return null if no price record could be found.
    return null;
  }

  public getPrice(productNumber: IProductNumber, currencyId: number, contractId?: number): IProductNumberPrice {
    const prices = productNumber?.prices?.filter(x =>
      x.currencyId === currencyId &&
      x.contractId === contractId &&
      x.isValid);

    if (prices) {
      return prices[0];
    }

    return null;
  }

  public getNonContractPrice(productNumber: IProductNumber, currencyId: number): IProductNumberPrice {
    const prices = productNumber?.prices?.filter(x =>
      x.currencyId === currencyId &&
      x.contractId === null &&
      x.isValid);

    if (prices) {
      return prices[0];
    }

    return null;
  }

  public getPricesByProductNumbers(productNumbers: Array<IProductNumber>, currencyId: number): Array<IProductNumberPrice> {
    const prices: Array<IProductNumberPrice> = [];
    productNumbers.forEach((productNumber: IProductNumber) => {
      prices.push(...productNumber.prices.filter(gcp => gcp.currencyId === currencyId));
    });

    return prices;
  }

  public getProductNumberWithMaxLeadTime(productNumbers: Array<IProductNumber>): IProductNumber {
    return productNumbers.reduce((max, productNumber) => max.leadTime > productNumber.leadTime ? max : productNumber);
  }

  public getIsAnyProductNumberInvalid(productNumbers: Array<IProductNumber>, currencyId: number): boolean {
    let isInvalid = false;
    productNumbers.forEach((productNumber: IProductNumber) => {
      if (this.checkForSIFSystemType(productNumber)) {
        const prices = productNumber.prices.filter(gcp => gcp.currencyId === currencyId);
        // Check is there any price with US prime contract
        let usPrimeContractPrice = prices.find(pr => pr.contractId == ContractIds.US_PRIME);
        if (usPrimeContractPrice && !usPrimeContractPrice?.isValid) {
          isInvalid = true;
          return;
        }

        // Check non-contract price if US prime contract is not available
        if (!usPrimeContractPrice) {
          let nonContractPrice = prices.find(pr => pr.contractId == null);
          if (nonContractPrice && !nonContractPrice?.isValid) {
            isInvalid = true;
            return;
          }
        }
      }
    });
    return isInvalid;
  }

  public getProductNumberInvalidErrorMessage(productNumber: IProductNumber, currencyId: number): string {
    const prices = productNumber.prices.filter(gcp => gcp.currencyId === currencyId);
    // Check is there any price with US prime contract
    let usPrimeContractPrice = prices.find(pr => pr.contractId == ContractIds.US_PRIME);
    if (usPrimeContractPrice && !usPrimeContractPrice?.isValid) {
      return usPrimeContractPrice.message;
    }

    // Check non-contract price if US prime contract is not available
    if (!usPrimeContractPrice) {
      let nonContractPrice = prices.find(pr => pr.contractId == null);
      if (nonContractPrice && !nonContractPrice?.isValid) {
        return nonContractPrice.message;
      }
    }
    return;
  }

  checkForSIFSystemType(productNumber: ProductNumber): boolean {
    if (productNumber.prices.some(x => x.priceUpdateSystemTypeId === PriceUpdateSystemTypes.SIF))
      return false;
    else
      return true;
  }
}
