import { Region } from 'src/app/core/models/region.model';
import { RegionsService } from 'src/app/core/services/regions.service';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { RegionActions, RegionApiActions } from '../actions';
import { of } from 'rxjs';


@Injectable()
export class RegionEffects {
  @Effect()
  loadRegions$ = this.actions$.pipe(
    ofType(RegionActions.RegionActionTypes.LoadRegions),
    switchMap(() =>
      this.regionsService.getRegions$().pipe(
        map((regions: Array<Region>) => new RegionApiActions.LoadSuccess(regions)),
        catchError(error =>
          of(new RegionApiActions.LoadFailure(error))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private regionsService: RegionsService) {}
}
