import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import * as fromStore from '../../reducers';

import {
  PortfolioApiActions,  
  PortfolioActions
} from '../actions';

import { switchMap, map, catchError, tap, mergeMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { PortfolioService } from 'src/app/core/services/portfolio.service';

@Injectable()
export class PortfolioEffects {

  @Effect()
  loadPortfolio$ = this.actions$.pipe(
    ofType(PortfolioActions.PortfolioActionTypes.LoadPortfolio),
    map((action: PortfolioActions.LoadPortfolio) => action.payload),
    switchMap(portfolioId =>
      this.portfolioService.getPortfolio$(portfolioId).pipe(
        map(
          (portfolio) => new PortfolioApiActions.LoadSuccess(portfolio)
        ),
        catchError(error =>
          of(new PortfolioApiActions.LoadFailure(error))
        )
      )
    )
  );

  @Effect()
  createPortfolio$ = this.actions$.pipe(
    ofType<PortfolioActions.CreatePortfolio>(PortfolioActions.PortfolioActionTypes.CreatePortfolio),
    switchMap((request) =>
      this.portfolioService.createPortfolio$(request.payload).pipe(
        map((objPortfolio) =>
          new PortfolioApiActions.CreateSuccess(objPortfolio)
        ),
        tap((response) => {
          // Navigate to the page in edit mode.
          this.router.navigate([`products/organizational-data/portfolio-detail/${response.payload.id}`], { queryParams: { edit: true } });
        }),
        catchError(error =>
          of(new PortfolioApiActions.CreateFailure(error))
        )
      )
    )
  );

  @Effect()
  deletePortfolio$ = this.actions$.pipe(
    ofType<PortfolioActions.DeletePortfolio>(PortfolioActions.PortfolioActionTypes.DeletePortfolio),
    switchMap((request) =>
      this.portfolioService.deletePortfolio$(request.payload.portfolioId).pipe(
        map((response) =>
          new PortfolioApiActions.DeleteSuccess({ portfolioId: request.payload.portfolioId, portfolioName: request.payload.portfolioName })
        ),
        tap((response) => {
          // Navigate to all portfolios page
          this.router.navigate([`products/organizational-data/portfolios`]);
        }),
        catchError(error =>
          of(new PortfolioApiActions.DeleteFailure(error))
        )
      )
    )
  );

  @Effect()
  updatePortfolio$: Observable<Action> = this.actions$.pipe(
    ofType<PortfolioActions.UpdatePortfolio>(PortfolioActions.PortfolioActionTypes.UpdatePortfolio),
    switchMap(request =>
      this.portfolioService.updatePortfolio$(request.payload.portfolio).pipe(
        map((response) => new PortfolioApiActions.UpdateSuccess({ portfolio: request.payload.portfolio, portfolioName: request.payload.portfolioName })),
        catchError(error => of(new PortfolioApiActions.UpdateFailure(error)))
      )
    )
  );

  @Effect()
  updatePortfolioSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(PortfolioApiActions.PortfolioApiActionTypes.UpdateSuccess),
    map((action: PortfolioApiActions.UpdateSuccess) => action.payload),
    map(payload =>
      // Reload the portfolio from the API if the save was successful.
      new PortfolioActions.LoadPortfolio(payload.portfolio.id)
    )
  );

  @Effect()
  renamePortfolio$: Observable<Action> = this.actions$.pipe(
    ofType<PortfolioActions.RenamePortfolio>(PortfolioActions.PortfolioActionTypes.RenamePortfolio),
    switchMap((request) =>
      this.portfolioService.renamePortfolio$({
        portfolioId: request.payload.portfolioId,
        portfolioName: request.payload.portfolioName
      }).pipe(
        map(_ => new PortfolioApiActions.RenameSuccess({
          portfolioId: request.payload.portfolioId,
          portfolioName: request.payload.portfolioName
        })
        ),
        tap(res => this.store.dispatch(new PortfolioActions.LoadPortfolio(request.payload.portfolioId)),
          catchError(error =>
            of(new PortfolioApiActions.RenameFailure(error)),
          )
        )
      )
    )
  );



  

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<fromStore.State>,
    private portfolioService: PortfolioService) { }

}
