import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';

export interface IIntegrationSummary extends IBaseAuditModel {
    name: string;
    iconLink: string;
    templatesCount: number;
    globalProductOfferingsCount: number;
}

export class IntegrationSummary extends BaseAuditModel {
    name: string;
    iconLink: string;
    templatesCount: number;
    globalProductOfferingsCount: number;

    constructor(data?: IIntegrationSummary) {
        if (data) {
            super(data);
            this.name = data.name;
            this.iconLink =  data.iconLink ? data.iconLink : 'assets/images/placeholders/image_placeholder.png';
            this.templatesCount = data.templatesCount;
            this.globalProductOfferingsCount = data.globalProductOfferingsCount;
        }
    }
}
