import * as _ from 'lodash';

export interface IApplicationCalculationResult {
  id: number;
  positiveFlex?: number;
  negativeFlex?: number;
  frontCirculation?: number;
  otherCirculation?: number;
  acousticPrivacy?: number;
  noisePotential?: number;
  spaceSize?: number;
  optionResults: Array<IApplicationOptionCalculationResult>;
}

export class ApplicationCalculationResult implements IApplicationCalculationResult {
  id: number;
  positiveFlex?: number;
  negativeFlex?: number;
  frontCirculation?: number;
  otherCirculation?: number;
  acousticPrivacy?: number;
  noisePotential?: number;
  spaceSize?: number;
  optionResults: Array<ApplicationOptionCalculationResult> = [];

  constructor(data?: IApplicationCalculationResult) {
    if (data) {
      _.assign(this, data);
    }
  }
}

export interface IApplicationOptionCalculationResult {
  ordinal: number;
  sifTypeId: number;
  contractId?: number;
  currencyId: number;
  primePrice?: number;
  pricePerStation?: number;
  //leadTimeId: number;
}

export class ApplicationOptionCalculationResult implements IApplicationOptionCalculationResult {
  ordinal: number;
  sifTypeId: number;
  contractId?: number;
  currencyId: number;
  primePrice?: number;
  pricePerStation?: number;
  //leadTimeId: number;

  constructor(data?: IApplicationOptionCalculationResult) {
    if (data) {
      _.assign(this, data);
    }
  }
}
