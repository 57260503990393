import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  constructor() { }

  getName(imageUri: string): string {
    if (!imageUri) { return null; }
    return imageUri.substring(imageUri.lastIndexOf('/') + 1);
  }
}
