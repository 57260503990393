import { Action } from '@ngrx/store';
import { MaterialsRequest } from 'src/app/core/models/material.model';

export enum MaterialActionTypes {
  LoadMaterials = '[Material] Load Materials'
}

export class LoadMaterials implements Action {
  readonly type = MaterialActionTypes.LoadMaterials;

  constructor(public payload: MaterialsRequest) { }
}

export type MaterialActionsUnion =
  LoadMaterials;
