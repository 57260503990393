import { SelectOption } from 'src/app/core/models/select-option.model';
import { SelectOptionGrouping } from 'src/app/core/models/select-option-grouping';
import { Subcategory } from 'src/app/core/models/subcategory.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-categories-selected-readonly',
  templateUrl: './categories-selected-readonly.component.html',
  styleUrls: ['./categories-selected-readonly.component.scss']
})
export class CategoriesSelectedReadonlyComponent implements OnInit {
  @Input() categoryOptions: Array<SelectOptionGrouping> = [];
  @Input() selectedSubcategories: Array<Subcategory> = [];

  constructor() { }

  ngOnInit(): void {
  }

  get categories(): Array<SelectOption> {
    //const subcategories = this.globalProduct.globalProductSubcategories;
    const results: Array<SelectOption> = [];

    if (!this.selectedSubcategories) {
      return results;
    }

    this.selectedSubcategories.forEach(s => {
      if (!this.categoryOptions) { return []; }
      const option = this.categoryOptions.find(o => o.id === s.categoryId);
      if (!option) { return []; }

      if (!results.some(o => o.id === option.id)) {
        results.push(option);
      }
    });

    return results;
  }

  getSubcategories(categoryId: number): Array<Subcategory> {
    if (!this.selectedSubcategories) { return []; }
    return this.selectedSubcategories.filter(x => x.categoryId === categoryId);
  }

}
