import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import { assign as _assign } from 'lodash-es';
import { ImageLink } from './image-link.model';
import { Category, ICategory } from './category.model';

export interface ISubcategory extends IBaseAuditModel {
  name: string;
  description: string;
  subcategoryImages: Array<ImageLink>;
  categoryId: number;
  categoryName: string;
}

export class Subcategory extends BaseAuditModel implements ISubcategory {
  name: string;
  description: string;
  subcategoryImages: Array<ImageLink> = [];
  categoryId: number;
  categoryName: string;

  constructor(data?: ISubcategory) {
    super(data);

    if (!data) {
      return;
    }

    _assign(this, data);
  }
}
