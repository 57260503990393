import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { PagedResults } from '../models/page-results.model';
import { ISubcategory, Subcategory } from '../models/subcategory.model';
import { SubcategorySummary } from '../models/subcategory-summary.model';
import { SubcategoryPost } from '../models/subcategory-post.model';
import { SubcategoryPut } from '../models/subcategory-put.model';
import { RenamePut } from '../models/rename-put.model';

@Injectable({
  providedIn: 'root'
})
export class SubcategoriesService {
  basePath = 'subcategories';
  constructor(private http: HttpClient) {
  }

  getSubcategories$(searchTerm: string = '', pageNumber: number, pageSize: number, sortActive: string, sortOrder: string): Observable<PagedResults<SubcategorySummary>> {
    return this.http.get(`${environment.apiUrl}${this.basePath}`, {
      params: new HttpParams()
        .set('search', searchTerm)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
        .set('orderBy', sortActive.toString())
        .set('sortOrder', sortOrder.toString())
    }).pipe(
      map((response: PagedResults<SubcategorySummary>) => response)
    );
  }

  getSubcategory$(id: number): Observable<Subcategory> {
    console.log(`getSubcategory$(${id})`);
    return this.http
      .get(environment.apiUrl + `${this.basePath}/${id}`)
      .pipe(map(response => response)
        , map((x: ISubcategory) => new Subcategory(x)));
  }

  createSubcategory$(subcategory: SubcategoryPost): Observable<Subcategory> {
    console.log(`createSubcategory(${subcategory})`);

    return this.http
      .post(environment.apiUrl + `${this.basePath}`, subcategory)
      .pipe(map(res => res)
        , catchError((err: Response) => {
          const obj = err;
          return observableThrowError(obj);
        })
        , map((x: ISubcategory) => new Subcategory(x)));
  }

  updateSubcategory$(subcategory: SubcategoryPut): Observable<any> {
    console.log(`updateSubcategory(${subcategory})`);

    return this.http
      .put(`${environment.apiUrl}${this.basePath}/${subcategory.id}`, subcategory)
      .pipe(map(response => response),
        catchError((err: Response) => {
          console.log('Error on subcategory update:');
          console.log(err);
          return observableThrowError(err);
        })
      );
  }

  renameSubcategory$(request: { subcategoryId: number, subcategoryName: string }): Observable<any> {
    const renamePut: RenamePut = new RenamePut({ name: request.subcategoryName });
    return this.http
      .put(environment.apiUrl + `${this.basePath}/${request.subcategoryId}/rename`,
        renamePut,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
      );
  }

  deleteSubcategory$(subcategoryId: number): Observable<any> {
    return this.http.delete(environment.apiUrl + `${this.basePath}/${subcategoryId}`)
      .pipe(
        map(res => res),
        catchError((err: Response) => {
          const obj = err;
          return observableThrowError(obj);
        })
      );
  }

  getDuplicates$(subcategoryName: string, categoryId: number): Observable<Array<SubcategorySummary>> {
    const request: SubcategoryPost = new SubcategoryPost();
    request.name = subcategoryName;
    request.categoryId = categoryId;

    return this.http
      .post(`${environment.apiUrl}${this.basePath}/duplicates`, request)
      .pipe(
        map(response => response),
        catchError((error: Response) => {
          return observableThrowError(error);
        }),
        map((response: Array<SubcategorySummary>) => response)
      );
  }
}
