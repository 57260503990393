<div class="header-container section-title">
  <h6 class="h6">
    <strong>{{attributeTypeName}}</strong>
  </h6>

  <div class="indicator-container">
    <ng-container *ngIf="isInDraft">
      <mat-icon class="draft-icon" matTooltip="In Draft">assignment</mat-icon>
    </ng-container>
    <ng-container *ngIf="impactsPriceSignificantly">
      <div class="impact-price-text"><strong>NOTE: </strong>Impacts price significantly</div>
    </ng-container>
  </div>
</div>
