import {
  ApplicationApiActions,
  ApplicationActions
} from '../actions';

import { Application } from 'src/app/core/models/application.model';
import { ApplicationStatus } from 'src/app/shared/constants';

export interface State {
  application: Application;
  loading: boolean;
  loaded: boolean;
  status: ApplicationStatus;
  error: string;
  success: string;
}

export const initialState: State = {
  application: null,
  loading: false,
  loaded: false,
  status: ApplicationStatus.None,
  error: null,
  success: null
};

export function reducer(state = initialState, action: ApplicationActions.ApplicationActionsUnion | ApplicationApiActions.ApplicationApiActionsUnion): State {
  switch (action.type) {

    case ApplicationActions.ApplicationActionTypes.LoadApplication: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case ApplicationApiActions.ApplicationApiActionTypes.LoadSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        application: action.payload
      };
    }

    case ApplicationApiActions.ApplicationApiActionTypes.LoadFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        application: null,
        error: action.payload
      };
    }

    case ApplicationActions.ApplicationActionTypes.ClearMessages: {
      return {
        ...state,
        status: ApplicationStatus.None,
        error: null,
        success: null
      };
    }

    case ApplicationActions.ApplicationActionTypes.CreateApplication: {
      return {
        ...state,
      };
    }

    case ApplicationApiActions.ApplicationApiActionTypes.CreateSuccess: {
      return {
        ...state,
        application: action.payload,
        status: ApplicationStatus.CreateSuccess,
      };
    }

    case ApplicationApiActions.ApplicationApiActionTypes.CreateFailure: {
      return {
        ...state,
        application: null,
        status: ApplicationStatus.CreateFailure,
        error: 'Failed to Create Application',
        success: null
      };
    }

    case ApplicationActions.ApplicationActionTypes.UpdateApplication: {
      return {
        ...state,
        loading: true,
        loaded: false,
        status: ApplicationStatus.None
      };
    }

    case ApplicationApiActions.ApplicationApiActionTypes.UpdateSuccess: {
      return {
        ...state,
        status: ApplicationStatus.UpdateSuccess,
        error: null,
        success: `Successfully Updated Application: ${action.payload.applicationName}`
      };
    }

    case ApplicationApiActions.ApplicationApiActionTypes.UpdateFailure: {
      return {
        ...state,
        loading: false,
        loaded: true,
        status: ApplicationStatus.UpdateFailure,
        error: 'Failed to Update Application',
        success: null
      };
    }

    case ApplicationActions.ApplicationActionTypes.DeleteApplication: {
      return {
        ...state,
      };
    }

    case ApplicationApiActions.ApplicationApiActionTypes.DeleteSuccess: {
      return {
        ...state,
        status: ApplicationStatus.DeleteSuccess,
        error: null,
        success: `Successfully Deleted Application: ${action.payload.applicationName}`
      };
    }

    case ApplicationApiActions.ApplicationApiActionTypes.DeleteFailure: {
      return {
        ...state,
        application: null,
        status: ApplicationStatus.DeleteFailure,
        error: 'Failed to Delete Application',
        success: null
      };
    }

    case ApplicationActions.ApplicationActionTypes.DeleteApplicationOption: {
      return {
        ...state,
      };
    }

    case ApplicationApiActions.ApplicationApiActionTypes.DeleteOptionSuccess: {
      return {
        ...state,
        status: ApplicationStatus.DeleteOptionSuccess,
        error: null,
        success: `Successfully Deleted Application Option`
      };
    }

    case ApplicationApiActions.ApplicationApiActionTypes.DeleteOptionFailure: {
      return {
        ...state,
        application: null,
        status: ApplicationStatus.DeleteOptionFailure,
        error: 'Failed to Delete Application Option',
        success: null
      };
    }

    default:
      return state;
  }
}

export const getApplication = (state: State) => state.application;
export const getErrorMessage = (state: State) => state.error;
export const getSuccessMessage = (state: State) => state.success;
export const getStatus = (state: State) => state.status;
export const isLoading = (state: State) => state.loading;
export const isLoaded = (state: State) => state.loaded;
