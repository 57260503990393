<div class="align-right">
  <ng-container *ngIf="value; else valueElse">
    <div fxFlex
      class="list-item list-item-config-model align-center-vertical button-spacing"
      [matTooltip]="value"
      matTooltipPosition="below"
      matTooltipClass="tooltip-multiline">
      {{ getFileName(value) }}
    </div>
    <div fxFlex="10%" class="align-center-vertical">
      <a
        [href]="value"
        target="_blank"
        mat-icon-button
        disableRipple matTooltip="Download the file(s)">
        <mat-icon class="subdue-icon">cloud_download</mat-icon>
      </a>
    </div>
  </ng-container>
  <ng-template #valueElse>
    <div fxFlex class="none">None</div>
  </ng-template>
</div>
