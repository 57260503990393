<mat-card class="app-card detail-card-edit">
    <mat-card-content>
      <div fxLayout="row" class="card-content card-content-image">
        <div fxFlex class="card-image-container">
          <ng-container *ngIf="imageLink; else imageLinkElse">
            <img
              class="attributeOptionImage == 'attributeOptionImage' ? image-preview-card : card-image"
              src="{{imageLink}}"
              alt="{{imageLink}}"
              (click)="showLightbox()"
              onerror="this.src='../../../../../../assets/images/placeholders/image_placeholder.png'">
          </ng-container>
          <ng-template #imageLinkElse>
            <img
              class="attributeOptionImage == 'attributeOptionImage' ? image-preview-card : card-image card-empty-image"
              src="../../../../../../assets/images/placeholders/image_placeholder.png"
              alt="Placeholder image">
          </ng-template>
        </div>
        <div fxFlex fxLayout="column" class="align-center-vertical" *ngIf="!attributeOptionImage">
          <div fxFlex>Image</div>
          <ng-container *ngIf="iconName; else iconNameElse">
            <div fxFlex class="card-data-name">{{iconName}}</div>
          </ng-container>
          <ng-template #iconNameElse>
            <div fxFlex class="none">None</div>
          </ng-template>
          <div fxFlex></div>
        </div>
        <div fxFlex class="align-center-vertical align-right">
          <ng-container *ngIf="imageLink; else iconAddButtonElse">
            <input type="file" accept='.png, .jpg' #fileInput (change)="iconUploadChange($event)">
            <button (click)="fileInput.click()" type="button" mat-button disableRipple>Change</button>
            <button mat-icon-button disableRipple (click)="removeIcon()">
              <mat-icon class="subdue-icon">cancel</mat-icon>
            </button>
          </ng-container>
          <ng-template #iconAddButtonElse>
            <input type="file" accept='.png, .jpg' #fileInput (change)="iconUploadChange($event)">
            <button class="btnAlignment" (click)="fileInput.click()" type="button" mat-raised-button disableRipple>Add</button>
          </ng-template>
        </div>
      </div>

      <ng-container *ngIf="error">
        <app-alert-message-card [title]="alert.Error" [message]="(errorMessage)" [cssClassName]="alert.Error"></app-alert-message-card>
      </ng-container>

</mat-card-content>
</mat-card>