import { map, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { EndpointProductOfferingSummary } from '../../../core/models/endpoint-product-offering-summary.model';
import { PagedResults } from '../../../core/models/page-results.model';
import { fromEvent, Observable } from 'rxjs';
import { EndpointOfferingSearchDatasource, EndpointOfferingSearchCriteria } from './endpoint-offering-search.datasource';
import { Component, Input, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-endpoint-offering-search',
  templateUrl: './endpoint-offering-search.component.html',
  styleUrls: ['./endpoint-offering-search.component.scss']
})
export class EndpointOfferingSearchComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() datasource: EndpointOfferingSearchDatasource;
  @Input() initiallySelectedIntegrationIds: Array<number> = [];
  @Input() initiallySelectedLocaleIds: Array<number> = [];

  @ViewChild('input') input: ElementRef;

  public get page$(): Observable<PagedResults<EndpointProductOfferingSummary>> { return this.datasource?.page$; }
  public get loading$(): Observable<boolean> { return this.datasource?.loading$; }
  public get loaded$(): Observable<boolean> { return this.datasource.loaded$; }

  public searchTerm = '';
  private subscriptions: SubSink = new SubSink();


  ngOnInit(): void {
    this.search('');
  }

  ngAfterViewInit(): void {
    this.subscribeToSearchKeyPress();

    // focuses the search textbox textbox
    window.setTimeout(() => {
      this.input.nativeElement.focus();
      this.input.nativeElement.select();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public getPageRecordCountStart(): number {
    return this.datasource.getPageRecordCountStart();
  }

  public getPageRecordCountEnd(): number {
    return this.datasource.getPageRecordCountEnd();
  }

  public nextPage(): void {
    this.datasource.nextPage();
  }

  public previousPage(): void {
    this.datasource.previousPage();
  }

  private subscribeToSearchKeyPress(): void {
    const keypress$ = fromEvent(this.input.nativeElement, 'keyup').pipe(
      map(x => this.input.nativeElement.value),
      debounceTime(500),
      // Prevents keys like 'enter' and arrow keys from re-running the query.
      distinctUntilChanged((x, y) => x === y),
      tap(() => {
        this.searchTerm = this.input?.nativeElement?.value;
        this.search(this.searchTerm);
      })
    ).subscribe();

    this.subscriptions.add(keypress$);
  }

  private search(searchTerm: string): void {
    const criteria = new EndpointOfferingSearchCriteria({
      searchTerm
    });
    this.datasource.search(criteria);
  }

  private refresh(): void {
    this.datasource.refresh();
  }
}
