<div class="digital-asset-library-container" infiniteScroll
  [infiniteScrollContainer]="'app-digital-asset-manager-modal .mat-tab-body-content'" [fromRoot]="true"
  [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" (scrolled)="onScrolled()" [scrollWindow]="false">

  <div
    [ngClass]="{'disable-till-load': (loadAssetPurpose$ | async).length === 0 && (loadMediaSubType$ | async).length === 0 && (loadAssetPurposeOmni$ | async).length === 0}">
    <div class="search-container">
      <form [formGroup]="formGroup" autocomplete="off" novalidate>
        <mat-form-field class="wrapper-none padding" appearance="outline" fxFlex="flexSearchText">
          <input matInput value="{{ datasource.searchTerm }}" #search formControlName="searchTerm"
            placeholder="Search...">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        <div *ngIf="selectedDamProviderId == damProviders.Bynder" class="button-filter-toggle-container">
          <button mat-raised-button disableRipple (click)="this.toggleFilters()" class="button-filter-toggle"
            [matTooltip]="getFiltersTooltip()" [matBadgeHidden]="true" [matBadge]="true">
            <mat-icon *ngIf="!showFilters">filter_list</mat-icon>
            <mat-icon *ngIf="showFilters">filter_list_off</mat-icon>
          </button>
        </div>
        <div fxLayoutAlign="end start" class="mat-button-toggle-collapsed toggle-alignment">
          <mat-button-toggle-group [value]="selectedDamProviderId">
            <mat-button-toggle *ngFor="let damProvider of getDamProviders((damProviders | enumToArray))"
              [value]="damProvider.index" (change)="onDamProviderSelectionChanged($event.value)">
              {{damProvider.name}}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

      </form>

    </div>

    <div class="search-filters-container" *ngIf="showFilters && selectedDamProviderId == damProviders.Bynder">
      <div class="filter-integrations-container">
        <form [formGroup]="formGroup" autocomplete="off" novalidate>
          <mat-form-field class="adjust-right" appearance="outline" fxFlex="22">
            <mat-label>Media Type</mat-label>
            <mat-select placeholder="Media Types" formControlName="mediaType"
              (selectionChange)="selectionChange($event)">
              <mat-option [value]="0">
                <em>&lt;All&gt;</em>
              </mat-option>
              <div *ngIf="mediaSubTypes?.length > 0">
                <ng-container *ngFor="let mediaSubType of (mediaSubTypes)">
                  <mat-option *ngIf="areImagesAvailable(mediaSubType)" [value]="mediaSubType.bynderId">
                    {{mediaSubType.bynderName}}
                  </mat-option>
                </ng-container>
              </div>
            </mat-select>
          </mat-form-field>
          <div *ngIf="omniUser" flflex="5%" fxLayoutAlign="end start"
            class="mat-button-toggle-collapsed toggle-alignment adjust--right">
            <mat-button-toggle-group [value]="selectedToggleImagesId">
              <mat-button-toggle *ngFor="let toggleImage of toggleImages | enumToArray" [value]="toggleImage.index"
                (change)="onImageToggleSelectionChanged($event.value)">
                {{toggleImage.name}}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </form>
      </div>
    </div>
    <div class="loading-message" *ngIf="!(datasource.loading$ | async) && datasource.assets.length === 0">
      <span>No assets found.</span>
    </div>
  </div>

  <div
    *ngIf="((loadAssetPurpose$ | async).length === 0 && (loadMediaSubType$ | async).length === 0 && (loadAssetPurposeOmni$ | async).length === 0)">
    <div class="bynder-spinner">
      <span>
        <mat-spinner color='accent' diameter="25"></mat-spinner>
      </span>
      <span class="bynder-spinner-text">Loading bynder assets...</span>
    </div>
  </div>

  <div class="image-grid-container">
    <mat-grid-list gutterSize="25px" cols="6">
      <mat-grid-tile #matGridTile *ngFor="let image of datasource.assets; index as i" (mouseenter)="onMouseEnter(i)"
        (mouseleave)="onMouseLeave()" (click)="datasource.selectGridImage(image)">

        <!-- Image -->
        <img class="grid-image" layout-fill [src]="image.thumbnailUrl"
          onerror="this.src='../../../../../../assets/images/placeholders/product_placeholder.png'">
        <!-- Selected -->
        <div class="grid-tile-selected">
          <div>
            <mat-icon class="selected-icon"
              [@selectedAnimation]="datasource.checkSelected(image) ? 'selected' : 'unselected'">
              check_circle
            </mat-icon>
          </div>
        </div>
        <!-- Overlay -->
        <div *ngIf="hoverIndex === i" class="grid-tile-overlay">
          <div class="overlay-container">
            <!-- <div class="overlay-container"> -->
            <div class="align-right">
              <mat-icon (click)="viewImageDetail(image)">photo_size_select_large</mat-icon>
            </div>
            <div class="overlay-text" *ngIf="showName">
              <span class="hover-title">{{ image.title }}</span>
              <span class="hover-url">{{ imagesService.getName(image.url) }}</span>
            </div>
          </div>
        </div>

      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <div *ngIf="(datasource.loading$ | async)">
    <div class="spinner-container">
      <span class="spinner">
        <mat-spinner color='accent' diameter="15"></mat-spinner>
      </span>
      <span class="spinner-message">Retrieving Digital Assets...</span>
    </div>
  </div>

  <div class="loading-message" *ngIf="datasource.allRecordsLoaded">
    <span>All digital assets have been loaded.</span>
  </div>

</div>
