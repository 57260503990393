import { map } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { PageRequest } from './../../../core/models/page-request.model';
import { EndpointProductOfferingSummary } from './../../../core/models/endpoint-product-offering-summary.model';
import { IEndpointProductOfferingsPageRequest } from '../../../core/models/endpoint-product-offerings-page-request.model';
import { EndpointOfferingsPageRequestBuilder } from './../../../core/services/endpoint-offerings-page-request-builder.service';
import { RolesService } from './../../../core/auth/roles.service';
import { Store } from '@ngrx/store';
import { PagedResults } from './../../../core/models/page-results.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { assign as _assign } from 'lodash-es';
import * as fromStore from '../../../app-store/reducers';
import { SubSink } from 'subsink';
import { EndpointProductOfferingsActions, EndpointProductOfferingsApiActions } from 'src/app/app-store/endpoint-product-offerings-store';

export interface IEndpointOfferingSearchCriteria {
  searchTerm: string;
}
export class EndpointOfferingSearchCriteria implements IEndpointOfferingSearchCriteria {
  searchTerm = '';

  constructor(data?: IEndpointOfferingSearchCriteria) {
    if (!data) {
      return;
    }
    _assign(this, data);
  }
}

export class EndpointOfferingSearchDatasource {
  private FIRST_PAGE = 1;
  private PAGE_SIZE = 50;

  public criteria: EndpointOfferingSearchCriteria = new EndpointOfferingSearchCriteria();
  public pagination: PageRequest = new PageRequest({
    pageNumber: 1,
    pageSize: this.PAGE_SIZE,
    orderBy: 'name',
    sortOrder: 'asc'
  });
  public selections: Array<EndpointProductOfferingSummary> = [];

  public page$: Observable<PagedResults<EndpointProductOfferingSummary>>;
  public page: PagedResults<EndpointProductOfferingSummary> = new PagedResults();
  public data$: BehaviorSubject<Array<EndpointProductOfferingSummary>> = new BehaviorSubject<Array<EndpointProductOfferingSummary>>([]);

  public get loading$(): Observable<boolean> { return this.store.select(fromStore.getIsEndpointProductOfferingsLoading); }
  public get loaded$(): Observable<boolean> { return this.store.select(fromStore.getIsEndpointProductOfferingsLoaded); }

  private subscriptions: SubSink = new SubSink();

  public constructor(
    private endpointId: number,
    private store: Store<fromStore.State>,
    private rolesService: RolesService,
    private actions$: Actions
  ) {
    this.page$ = this.store.select(fromStore.getEndpointProductOfferings);
  }

  public connect(): void {
    this.subscribeToPageUpdates();
    this.subscribeToStoreChanges();
  }

  public disconnect(): void {
    this.subscriptions.unsubscribe();
  }

  public hasResults() {
    return this.page?.results?.length > 0;
  }

  public hasSelections() {
    return this.selections?.length > 0;
  }

  public hasPermissions(endpointProductOffering: EndpointProductOfferingSummary): boolean {
    // if there is no offering, don't allow selection (should be impossible)
    if (!endpointProductOffering || endpointProductOffering.integrationId <= 0) {
      return false;
    }

    return !(this.rolesService.isIntegrationRoleInvalidForIntegrationId(endpointProductOffering.integrationId));
  }

  public isSelected(endpointProductOffering: EndpointProductOfferingSummary): boolean {
    if (endpointProductOffering) {
      return this.selections.find(x => x.globalProductOfferingId === endpointProductOffering.globalProductOfferingId) != null ? true : false;
    }

    return false;
  }

  public clearSelections(): void {
    this.selections = [];
  }

  public selectAll(): void {
    if (!this.page || !this.page.results || this.page.results.length <= 0) {
      return;
    }

    this.page.results.forEach((x: EndpointProductOfferingSummary) => {
      this.select(x, true);
    });
  }

  public select(endpointProductOffering: EndpointProductOfferingSummary, isSelected: boolean): void {
    const isChecked = this.isSelected(endpointProductOffering);
    const hasPermission = this.hasPermissions(endpointProductOffering);

    // Apply selection if isSelected is true, and we haven't already selected the configuration.
    if (isSelected && !isChecked && hasPermission) {
      this.selections.push(endpointProductOffering);
      return;
    }

    // Remove selection
    if (!isSelected) {
      const index = this.selections.findIndex(x => x.globalProductOfferingId === endpointProductOffering.globalProductOfferingId);
      if (index >= 0) {
        this.selections.splice(index, 1);
      }
    }
  }

  private removeSelectionByGlobalProductOfferingId(globalProductOfferingIds: Array<number>): void {
    const selections = this.selections.filter(x => globalProductOfferingIds.some(y => y === x.globalProductOfferingId));
    if (!selections || selections.length <= 0) {
      return;
    }

    selections.forEach((selection) => {
      this.select(selection, false);
    });
  }

  public getPageRecordCountStart(): number {
    if (!this.page || this.page?.results.length <= 0) {
      return 0;
    }

    return (+(this.PAGE_SIZE) * (+(this.page.pageNumber) - 1)) + 1;
  }

  public getPageRecordCountEnd(): number {
    if (!this.page || this.page?.results.length <= 0) {
      return 0;
    }

    return (+(this.PAGE_SIZE) * (+(this.page.pageNumber) - 1)) + this.page.results?.length;
  }

  public nextPage() {
    if (!this.page || this.page?.pageNumber === this.page.totalPageCount) {
      return;
    }

    this.searchByPageNumber(this.criteria, this.page.pageNumber + 1);
  }

  public previousPage() {
    if (!this.page || this.page?.pageNumber === this.FIRST_PAGE) {
      return;
    }

    this.searchByPageNumber(this.criteria, this.page.pageNumber - 1);
  }

  public search(criteria: IEndpointOfferingSearchCriteria): void {
    this.searchByPageNumber(criteria, this.FIRST_PAGE);
  }

  public refresh(): void {
    this.search(this.criteria);
  }

  private searchByPageNumber(criteria: IEndpointOfferingSearchCriteria, pageNumber: number): void {
    if (!criteria) {
      criteria = new EndpointOfferingSearchCriteria();
    }

    this.criteria.searchTerm = criteria.searchTerm;
    if (!this.criteria.searchTerm) {
      this.criteria.searchTerm = '';
    }

    // Build request
    const builder = new EndpointOfferingsPageRequestBuilder();
    const request = builder
      .setEndpointId(this.endpointId)
      .setSearchTerm(this.criteria.searchTerm)
      .setPageNumber(pageNumber)
      .setPageSize(this.pagination.pageSize)
      .setOrderBy(this.pagination.orderBy)
      .setSortOrder(this.pagination.sortOrder)
      .build();

    this.setStoreEndpointOfferings(request);
  }

  private setStoreEndpointOfferings(request: IEndpointProductOfferingsPageRequest) {
    this.store.dispatch(new EndpointProductOfferingsActions.LoadEndpointProductOfferingsPageByEndpointId(request));
  }

  private subscribeToPageUpdates() {
    this.subscriptions.add(
      this.page$.subscribe((page: PagedResults<EndpointProductOfferingSummary>) => {
        this.page = page;
        this.data$.next(page.results);
        // console.log('search updated');
        // console.log(this.page);
      })
    );
  }

  private subscribeToStoreChanges(): void {
    this.subscriptions.add(
      this.actions$.pipe(
        ofType(EndpointProductOfferingsApiActions.EndpointProductOfferingsApiActionTypes.RemoveSuccess),
        map((action: EndpointProductOfferingsApiActions.RemoveSuccess) => action.payload)
      ).subscribe((payload) => {
        // update selections if the removed item is in the current list of selections.
        this.removeSelectionByGlobalProductOfferingId(payload.globalProductOfferingIds);

        this.refresh();
      })
    );

    this.subscriptions.add(
      this.actions$.pipe(
        ofType(EndpointProductOfferingsApiActions.EndpointProductOfferingsApiActionTypes.UpdateSuccess)
      ).subscribe(() => {
        this.refresh();
      })
    );

  }
}
