import { EndpointProductOfferingSummary } from './../../../core/models/endpoint-product-offering-summary.model';
import { PagedResults } from 'src/app/core/models/page-results.model';
import { Action } from '@ngrx/store';

export enum EndpointProductOfferingsApiActionTypes {
  LoadPageByEndpointSuccess = '[EndpointProductOffering/API] Load Page By Endpoint Success',
  LoadPageByEndpointFailure = '[EndpointProductOffering/API] Load Page By Endpoint Failure',
  RemoveSuccess = '[EndpointProductOffering/API] Remove Success',
  RemoveFailure = '[EndpointProductOffering/API] Remove Failure',
  UpdateSuccess = '[EndpointProductOffering/API] Update Success',
  UpdateFailure = '[EndpointProductOffering/API] Update Failure',

  ApplyOfferingTagsSuccess = '[EndpointProductOffering/API] Apply Tags to EndpointProductOfferings Success',
  ApplyOfferingTagsFailure = '[EndpointProductOffering/API] Apply Tags to EndpointProductOfferings Failure',
  RemoveOfferingTagsSuccess = '[EndpointProductOfferings/API] Remove Tags from EndpointProductOfferings Success',
  RemoveOfferingTagsFailure = '[EndpointProductOfferings/API] Remove Tags from EndpointProductOfferings Failure',
}

export class LoadPageByEndpointSuccess implements Action {
  readonly type = EndpointProductOfferingsApiActionTypes.LoadPageByEndpointSuccess;

  constructor(public payload: PagedResults<EndpointProductOfferingSummary>) { }
}

export class LoadPageByEndpointFailure implements Action {
  readonly type = EndpointProductOfferingsApiActionTypes.LoadPageByEndpointFailure;

  constructor(public payload: any) { }
}

export class RemoveSuccess implements Action {
  readonly type = EndpointProductOfferingsApiActionTypes.RemoveSuccess;

  constructor(public payload: { globalProductOfferingIds: Array<number> }) { }
}

export class RemoveFailure implements Action {
  readonly type = EndpointProductOfferingsApiActionTypes.RemoveFailure;

  constructor(public payload: any) { }
}

export class UpdateSuccess implements Action {
  readonly type = EndpointProductOfferingsApiActionTypes.UpdateSuccess;
}

export class UpdateFailure implements Action {
  readonly type = EndpointProductOfferingsApiActionTypes.UpdateFailure;

  constructor(public payload: any) { }
}

export class ApplyOfferingTagsSuccess implements Action {
  readonly type = EndpointProductOfferingsApiActionTypes.ApplyOfferingTagsSuccess;
}

export class ApplyOfferingTagsFailure implements Action {
  readonly type = EndpointProductOfferingsApiActionTypes.ApplyOfferingTagsFailure;

  constructor(public payload: any) { }
}

export class RemoveOfferingTagsSuccess implements Action {
  readonly type = EndpointProductOfferingsApiActionTypes.RemoveOfferingTagsSuccess;
}

export class RemoveOfferingTagsFailure implements Action {
  readonly type = EndpointProductOfferingsApiActionTypes.RemoveOfferingTagsFailure;

  constructor(public payload: any) { }
}

export type EndpointProductOfferingsApiActionsUnion =
    LoadPageByEndpointSuccess
  | LoadPageByEndpointFailure
  | RemoveSuccess
  | RemoveFailure
  | UpdateSuccess
  | UpdateFailure
  | ApplyOfferingTagsSuccess
  | ApplyOfferingTagsFailure
  | RemoveOfferingTagsSuccess
  | RemoveOfferingTagsFailure;
