<div>
  <table
    class="ghost-table"
    mat-table
    [dataSource]="dataSource">

    <ng-container matColumnDef="{{column}}" *ngFor="let column of columns">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let ghostItem">
        <div class="lines">{{ghostItem}}</div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns;"></tr>
    <tr mat-row *matRowDef="let ghostItem; columns: columns;"></tr>
  </table>
</div>
