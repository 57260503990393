import { PaginationService } from './../../../core/services/pagination.service';
import { GlobalProductQueryPageRequestBuilder } from './../../../core/services/global-product-query-page-request-builder.service';
import { map, debounceTime, distinctUntilChanged, tap, finalize } from 'rxjs/operators';
import { GlobalProductsService } from 'src/app/core/services/global-products.service';
import { Router } from '@angular/router';
import { SubSink } from 'subsink';
import { PagedResults } from './../../../core/models/page-results.model';
import { GlobalProductSummary } from 'src/app/core/models/global-product-summary.model';
import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-sidebar-products-search',
  templateUrl: './sidebar-products-search.component.html',
  styleUrls: ['./sidebar-products-search.component.scss']
})
export class SidebarProductsSearchComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('input') input: ElementRef;

  @Input() brandIds: Array<number> = [];
  @Input() designerIds: Array<number> = [];
  @Input() categoryIds: Array<number> = [];
  @Input() contactIds: Array<number> = [];
  @Input() familyIds: Array<number> = [];
  @Input() globalCategoryIds: Array<number> = [];
  @Input() globalSubcategoryIds: Array<number> = [];
  @Input() portfolioIds: Array<number> = [];
  @Input() subbrandIds: Array<number> = [];
  @Input() subcategoryIds: Array<number> = [];
  @Input() warrantyIds: Array<number> = [];

  @Input() isSelectionEnabled = false;
  @Input() selectedGlobalProductId: number;

  public page: PagedResults<GlobalProductSummary> = new PagedResults();
  public PAGE_SIZE = 50;
  public globalProductsLoading: boolean;
  public globalProductsLoaded: boolean;
  public searchTerm: string;

  private subscriptions: SubSink = new SubSink();

  constructor(
    private router: Router,
    private paginationService: PaginationService,
    private globalProductsService: GlobalProductsService) { }

  ngOnInit(): void {
    this.search('', 1);
  }

  ngAfterViewInit(): void {
    this.subscribeToSearchKeyPress();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public navigateToProduct(globalProductId: number): void {
    this.router.navigate([`products/product-detail/${globalProductId}`]);
  }

  public nextPage(): void {
    this.search(this.input.nativeElement.value, this.page.pageNumber + 1);
  }

  public previousPage(): void {
    this.search(this.input.nativeElement.value, this.page.pageNumber - 1);
  }

  public getPageStartIndex(): number {
    return this.paginationService.getPageStartIndex(this.page, this.PAGE_SIZE);
  }

  public getPageEndIndex(): number {
    return this.paginationService.getPageEndIndex(this.page, this.PAGE_SIZE);
  }

  private subscribeToSearchKeyPress(): void {
    const keypress$ = fromEvent(this.input.nativeElement, 'keyup').pipe(
      map(x => this.input.nativeElement.value),
      debounceTime(500),
      // Prevents keys like 'enter' and arrow keys from re-running the query.
      distinctUntilChanged((x, y) => x === y),
      tap(() => {
        this.search(this.input.nativeElement.value, 1);
      })
    ).subscribe();

    this.subscriptions.add(keypress$);
  }

  private search(searchTerm: string, pageNumber: number): void {
    this.searchTerm = searchTerm;

    this.globalProductsLoading = true;
    this.globalProductsLoaded = false;

    // Build request
    const builder = new GlobalProductQueryPageRequestBuilder();
    const request = builder
      .setPageNumber(pageNumber)
      .setPageSize(this.PAGE_SIZE)
      .setOrderBy('name')
      .setSortOrder('asc')
      .setSearchTerm(this.searchTerm)
      .setBrandIds(this.brandIds)
      .setDesignerIds(this.designerIds)
      .setCategoryIds(this.categoryIds)
      .setContactIds(this.contactIds)
      .setFamilyIds(this.familyIds)
      .setGlobalCategoryIds(this.globalCategoryIds)
      .setGlobalSubcategoryIds(this.globalSubcategoryIds)
      .setPortfolioIds(this.portfolioIds)
      .setSubBrandIds(this.subbrandIds)
      .setSubcategoryIds(this.subcategoryIds)
      .setWarrantyIds(this.warrantyIds)
      .build();

    const globalConfigurations$ = this.globalProductsService.getGlobalProducts$(request)
      .pipe(
        tap((page: PagedResults<GlobalProductSummary>) => {
          if (page) {
            this.globalProductsLoaded = true;
          }
        }),
        finalize(() => {
          this.globalProductsLoading = false;
        })
      )
      .subscribe((page: PagedResults<GlobalProductSummary>) => {
        this.page = page;
      });

    this.subscriptions.add(globalConfigurations$);
  }
}
