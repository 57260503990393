import { SubBrand } from 'src/app/core/models/sub-brand.model';
import { SubBrandStatus } from 'src/app/shared/constants';
import {
  SubBrandApiActions,
  SubBrandActions
} from '../actions';

export interface State {
  subBrand: SubBrand;
  loading: boolean;
  loaded: boolean;
  status: SubBrandStatus;
  error: string;
  success: string;
}

export const initialState: State = {
  subBrand: null,
  loading: false,
  loaded: false,
  status: SubBrandStatus.None,
  error: null,
  success: null
};

export function reducer(state = initialState, action: SubBrandActions.SubBrandActionsUnion | SubBrandApiActions.SubBrandApiActionsUnion): State {
  switch (action.type) {

    case SubBrandActions.SubBrandActionTypes.LoadSubBrand: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case SubBrandApiActions.SubBrandApiActionTypes.LoadSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        subBrand: action.payload
      };
    }

    case SubBrandApiActions.SubBrandApiActionTypes.LoadFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        subBrand: null,
        error: action.payload
      };
    }

    case SubBrandActions.SubBrandActionTypes.ClearMessages: {
      return {
        ...state,
        status: SubBrandStatus.None,
        error: null,
        success: null
      };
    }

    case SubBrandActions.SubBrandActionTypes.CreateSubBrand: {
      return {
        ...state,
      };
    }

    case SubBrandApiActions.SubBrandApiActionTypes.CreateSuccess: {
      return {
        ...state,
        subBrand: action.payload,
        status: SubBrandStatus.CreateSuccess,
      };
    }

    case SubBrandApiActions.SubBrandApiActionTypes.CreateFailure: {
      return {
        ...state,
        subBrand: null,
        status: SubBrandStatus.CreateFailure,
        error: 'Failed to Create Sub-Brand',
        success: null
      };
    }

    case SubBrandActions.SubBrandActionTypes.UpdateSubBrand: {
      return {
        ...state,
        loading: true,
        loaded: false,
        status: SubBrandStatus.None
      };
    }

    case SubBrandApiActions.SubBrandApiActionTypes.UpdateSuccess: {
      return {
        ...state,
        status: SubBrandStatus.UpdateSuccess,
        error: null,
        success: `Successfully Updated Sub-Brand: ${action.payload.subBrandName}`
      };
    }

    case SubBrandApiActions.SubBrandApiActionTypes.UpdateFailure: {
      return {
        ...state,
        loading: false,
        loaded: true,
        status: SubBrandStatus.UpdateFailure,
        error: 'Failed to Update Sub-Brand',
        success: null
      };
    }

    case SubBrandActions.SubBrandActionTypes.RenameSubBrand: {
      return {
        ...state
      };
    }

    case SubBrandApiActions.SubBrandApiActionTypes.RenameSuccess: {
      const subBrand = { ...state.subBrand };
      subBrand.name = action.payload.subBrandName;

      return {
        ...state,
        status: SubBrandStatus.RenameSuccess,
        error: null,
        success: `Successfully renamed Sub-Brand: ${action.payload.subBrandName}`
      };
    }

    case SubBrandApiActions.SubBrandApiActionTypes.RenameFailure: {
      return {
        ...state,
        subBrand: null,
        status: SubBrandStatus.RenameFailure,
        error: 'Failed to rename Sub-Brand',
        success: null
      };
    }

    case SubBrandActions.SubBrandActionTypes.DeleteSubBrand: {
      return {
        ...state,
      };
    }

    case SubBrandApiActions.SubBrandApiActionTypes.DeleteSuccess: {
      return {
        ...state,
        status: SubBrandStatus.DeleteSuccess,
        error: null,
        success: `Successfully Deleted Sub-Brand: ${action.payload.subBrandName}`
      };
    }

    case SubBrandApiActions.SubBrandApiActionTypes.DeleteFailure: {
      return {
        ...state,
        subBrand: null,
        status: SubBrandStatus.DeleteFailure,
        error: 'Failed to Delete Sub-Brand',
        success: null
      };
    }

    default:
      return state;
  }
}

export const getSubBrand = (state: State) => state.subBrand;
export const getErrorMessage = (state: State) => state.error;
export const getSuccessMessage = (state: State) => state.success;
export const getStatus = (state: State) => state.status;
export const isLoading = (state: State) => state.loading;
export const isLoaded = (state: State) => state.loaded;
