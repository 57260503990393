<div>
  <ng-container *ngIf="(value && value?.length > 0); else valueElse">
    <div fxFlex fxLayout="column">
      {{displayText}}
      <a
        class="show-button"
        href="#"
        (click)="$event.preventDefault()"
        (click)="toggle()"
        *ngIf="truncate && value?.length > truncateAt">
        {{toggleText}}
      </a>
    </div>
  </ng-container>
  <ng-template #valueElse>
    <div class="none align-right">
      <div class="none align-right">None</div>
    </div>
  </ng-template>
</div>
