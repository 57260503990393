import { GlobalConfiguration } from './../models/global-configuration.model';
import { Injectable } from '@angular/core';
import { ImageType } from '../models/global-configuration-image.model';

@Injectable({
  providedIn: 'root'
})
export class EditSnackbarService {
  result: EditSnackbarResult = EditSnackbarResult.Default;

  constructor() { }
}

export enum EditSnackbarResult {
  Default,
  Discard,
  Save
}
