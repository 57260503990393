import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable, Injector } from '@angular/core';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private injector: Injector,
                private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const auth = this.injector.get(AuthService);

        if (auth.isAuthenticated()) {
            if (auth.canAccessUrl(state.url)) {
                return true;
            } else {
                // not allowed to access requested page, show error
                this.router.navigate(['/error'], { queryParams: { type: 'accessDenied' } });
                return false;
            }
        } else {
            // not authenticated, call logout to route to login page
            auth.logout(true);
            return false;
        }
    }
}
