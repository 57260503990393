import { Action } from '@ngrx/store';
import { WarrantyPut } from 'src/app/core/models/warranty-put.model';
import { Warranty } from 'src/app/core/models/warranty.model';

export enum WarrantyApiActionTypes {
  LoadSuccess = '[Warranty/API] Load Success',
  LoadFailure = '[Warranty/API] Load Failure',
  CreateSuccess = '[Warranty/API] Create Success',
  CreateFailure = '[Warranty/API] Create Failure',
  UpdateSuccess = '[Warranty/API] Update Success',
  UpdateFailure = '[Warranty/API] Update Failure',
  RenameSuccess = '[Warranty/API] Rename Success',
  RenameFailure = '[Warranty/API] Rename Failure',
  DeleteSuccess = '[Warranty/API] Delete Success',
  DeleteFailure = '[Warranty/API] Delete Failure'
}

export class LoadSuccess implements Action {
  readonly type = WarrantyApiActionTypes.LoadSuccess;

  constructor(public payload: Warranty) { }
}

export class LoadFailure implements Action {
  readonly type = WarrantyApiActionTypes.LoadFailure;

  constructor(public payload: any) { }
}

export class CreateSuccess implements Action {
  readonly type = WarrantyApiActionTypes.CreateSuccess;

  constructor(public payload: Warranty) { }
}

export class CreateFailure implements Action {
  readonly type = WarrantyApiActionTypes.CreateFailure;

  constructor(public payload: any) { }
}


export class UpdateSuccess implements Action {
  readonly type = WarrantyApiActionTypes.UpdateSuccess;

  constructor(public payload: { warranty: WarrantyPut, warrantyName: string }) { }
}

export class UpdateFailure implements Action {
  readonly type = WarrantyApiActionTypes.UpdateFailure;

  constructor(public payload: any) { }
}

export class RenameSuccess implements Action {
  readonly type = WarrantyApiActionTypes.RenameSuccess;

  constructor(public payload: { warrantyId: number, warrantyName: string }) { }
}

export class RenameFailure implements Action {
  readonly type = WarrantyApiActionTypes.RenameFailure;

  constructor(public payload: any) { }
}

export class DeleteSuccess implements Action {
  readonly type = WarrantyApiActionTypes.DeleteSuccess;

  constructor(public payload: { warrantyId: number, warrantyName: string }) { }
}

export class DeleteFailure implements Action {
  readonly type = WarrantyApiActionTypes.DeleteFailure;

  constructor(public payload: any) { }
}

export type WarrantyApiActionsUnion =
  LoadSuccess
  | LoadFailure
  | CreateSuccess
  | CreateFailure
  | UpdateSuccess
  | UpdateFailure
  | RenameSuccess
  | RenameFailure
  | DeleteSuccess
  | DeleteFailure;
