import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PhoneNumberService {

  constructor() {
  }

  formatPhoneNumber(value) {
    if (!value || value.length < 10 || value.length > 14) {
      return value;
    }

    const length = value.length;
    const areaCodeStart = length - 10;
    const midSectionStart = length - 7;
    const lastSectionStart = length - 4;

    let countryCodeStr = '';

    if (length > 10){
        const countryCodeStart = 0;
        countryCodeStr = '+' + value.slice(countryCodeStart, areaCodeStart) + ' ';
    }

    const areaCodeStr = value.slice(areaCodeStart, midSectionStart);
    const midSectionStr = value.slice(midSectionStart, lastSectionStart);
    const lastSectionStr = value.slice(lastSectionStart);

    return `${countryCodeStr}(${areaCodeStr}) ${midSectionStr}-${lastSectionStr}`;
  }
}