import { DataSource } from '@angular/cdk/table';
import { catchError, finalize } from 'rxjs/operators';
import { PagedResults } from 'src/app/core/models/page-results.model';
import { DigitalAssetManagerService } from 'src/app/core/services/digital-asset-manager.service';
import { Subscription, BehaviorSubject, of, Observable } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { Locale } from 'src/app/core/models/locale.model';
import { DocumentAsset, DocumentAssetType } from '../../../core/models/document-asset.model';
import { FormGroup, Validators, FormControl } from '@angular/forms';
export enum Views {
  Selection,
  Custom
}

export class AddProductLiteratureModalDataSource implements DataSource<DocumentAsset> {
  searchTerm: string;
  preSelectedDocumentUrls: Array<string> = [];
  pageNumber = 1;
  pageSize = 100;
  totalRecordCount = 0;
  totalPageCount = 0;
  damProviderId = 1;
  assets$: BehaviorSubject<Array<DocumentAsset>> = new BehaviorSubject<Array<DocumentAsset>>([]);
  loading$ = new BehaviorSubject<boolean>(false);
  allRecordsLoaded: boolean;
  subscriptions: Array<Subscription> = [];

  customEntryFormGroup: FormGroup = new FormGroup({
    type: new FormControl('', [Validators.required]),
    url: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    locales: new FormControl('', [Validators.required]),
  });

  visibleView: Views;
  selection = new SelectionModel<DocumentAsset>(true, []);

  constructor(private digitalAssetManagerService: DigitalAssetManagerService) { }

  search(searchTerm: string, damProviderId: number = 1) {
    this.reset();
    this.searchTerm = searchTerm;
    this.damProviderId = damProviderId;
    if (!this.searchTerm) {
      return;
    }

    this.load();
  }

  isPreSelected(document: DocumentAsset): boolean {
    console.log(document);
    if (!document || !document.url || !this.preSelectedDocumentUrls) {
      return false;
    }

    return this.preSelectedDocumentUrls.some(u => u === document.url);
  }

  getNextPage() {
    // The maximum number of pages for this search has been met.  Don't call the api.
    if (this.totalPageCount <= this.pageNumber) {
      this.allRecordsLoaded = true;
      return;
    }

    this.pageNumber++;
    this.load();
  }

  connect(): Observable<DocumentAsset[]> {
    return this.assets$.asObservable();
  }

  disconnect() {
    // prevent memory leak when component destroyed
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private load() {
    this.loading$.next(true);

    this.subscriptions.push(
      this.digitalAssetManagerService.getDocumentAssets$(this.searchTerm, this.pageNumber, this.pageSize, this.damProviderId)
        .pipe(
          catchError(() => of([])),
          finalize(() => this.loading$.next(false))
        )
        .subscribe((response: PagedResults<DocumentAsset>) => {
          // console.log('%c Document Search Response:', 'background-color: darkslategray; color: greenyellow;');
          // console.log(response);
          if (response?.results) {
            const values = this.assets$.value;
            values.push(...response.results);

            this.assets$.next(values);
            this.totalRecordCount = response.totalRecordCount;
            this.totalPageCount = response.totalPageCount;
          }
        })
    );
  }

  toggleSelection() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.assets$.value.forEach(row => this.selection.select(row));
  }

  clearSelection() {
    this.selection.clear();
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.assets$.value.length;
    return numSelected === numRows;
  }

  getCustomEntry(): DocumentAsset {
    const document: DocumentAsset = new DocumentAsset();
    document.title = this.customEntryFormGroup.controls.name.value;
    document.url = this.customEntryFormGroup.controls.url.value;
    document.documentType = (this.customEntryFormGroup.controls.type.value as DocumentAssetType);
    document.locales = (this.customEntryFormGroup.controls.locales.value as Array<Locale>);

    return document;
  }

  clearData() {
    // this.searchTerm = '';
    this.selection.clear();

    this.reset();
  }

  private reset() {
    this.assets$.next([]);
    this.pageNumber = 1;
    this.totalRecordCount = 0;
    this.totalPageCount = 0;
    this.allRecordsLoaded = false;
  }
}
