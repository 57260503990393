import { Action } from '@ngrx/store';
import { Contact } from 'src/app/core/models/contact.model';
import { ContactPut } from 'src/app/core/models/contact-put.model';

export enum ContactApiActionTypes {
  LoadSuccess = '[Contact/API] Load Success',
  LoadFailure = '[Contact/API] Load Failure',
  CreateSuccess = '[Contact/API] Create Success',
  CreateFailure = '[Contact/API] Create Failure',
  UpdateSuccess = '[Contact/API] Update Success',
  UpdateFailure = '[Contact/API] Update Failure',
  RenameSuccess = '[Contact/API] Rename Success',
  RenameFailure = '[Contact/API] Rename Failure',
  DeleteSuccess = '[Contact/API] Delete Success',
  DeleteFailure = '[Contact/API] Delete Failure'
}

export class LoadSuccess implements Action {
  readonly type = ContactApiActionTypes.LoadSuccess;

  constructor(public payload: Contact) { }
}

export class LoadFailure implements Action {
  readonly type = ContactApiActionTypes.LoadFailure;

  constructor(public payload: any) { }
}

export class CreateSuccess implements Action {
  readonly type = ContactApiActionTypes.CreateSuccess;

  constructor(public payload: Contact) { }
}

export class CreateFailure implements Action {
  readonly type = ContactApiActionTypes.CreateFailure;

  constructor(public payload: any) { }
}


export class UpdateSuccess implements Action {
  readonly type = ContactApiActionTypes.UpdateSuccess;

  constructor(public payload: { contact: ContactPut, contactName: string }) { }
}

export class UpdateFailure implements Action {
  readonly type = ContactApiActionTypes.UpdateFailure;

  constructor(public payload: any) { }
}

export class RenameSuccess implements Action {
  readonly type = ContactApiActionTypes.RenameSuccess;

  constructor(public payload: { contactId: number, contactName: string }) { }
}

export class RenameFailure implements Action {
  readonly type = ContactApiActionTypes.RenameFailure;

  constructor(public payload: any) { }
}

export class DeleteSuccess implements Action {
  readonly type = ContactApiActionTypes.DeleteSuccess;

  constructor(public payload: { contactId: number, contactName: string }) { }
}

export class DeleteFailure implements Action {
  readonly type = ContactApiActionTypes.DeleteFailure;

  constructor(public payload: any) { }
}

export type ContactApiActionsUnion =
  LoadSuccess
  | LoadFailure
  | CreateSuccess
  | CreateFailure
  | UpdateSuccess
  | UpdateFailure
  | RenameSuccess
  | RenameFailure
  | DeleteSuccess
  | DeleteFailure;
