<ng-container
  *ngIf="datasource.visibleView === Views.ImageGrid">
  <app-digital-asset-library-image-grid
    [datasource]="datasource"
    (viewMediaLinkDetail)="viewMediaDetails($event)">
  </app-digital-asset-library-image-grid>
</ng-container>

<ng-container *ngIf="datasource.visibleView === Views.Image">
  <div class="back-container">
    <mat-icon class="back-icon">
      keyboard_arrow_left
    </mat-icon>
    <a class="back-anchor" (click)="datasource.viewImages()">
      Back to Images
    </a>
  </div>

  <app-view-image-details-url
    [mediaLink]="mediaLink">
  </app-view-image-details-url>
</ng-container>
