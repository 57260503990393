import { Action } from '@ngrx/store';

export enum RolesActionTypes {
  LoadRoles = '[Roles] Load Roles',
}

export class LoadRoles implements Action {
  readonly type = RolesActionTypes.LoadRoles;

  constructor() { }
}

export type RolesActionsUnion = LoadRoles;
