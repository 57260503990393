import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-ghost-table',
  templateUrl: './ghost-table.component.html',
  styleUrls: ['./ghost-table.component.scss']
})
export class GhostTableComponent implements OnInit {
  @Input() rows = 10;
  columns: string[] = ['column1', 'column2', 'column3', 'column4'];
  ghostItems: GhostItem[] = [];
  dataSource: MatTableDataSource<GhostItem>;

  constructor() { }

  ngOnInit() {
    this.populate();
    this.dataSource = new MatTableDataSource(this.ghostItems);
  }

  private populate() {
    for (let i = 0; i < this.rows; i++) {
      this.ghostItems.push(new GhostItem());
    }
  }
}

class GhostItem {
  constructor(
    public column1: string = '',
    public column2: string = '',
    public column3: string = '',
    public column4: string = ''
  ) {}
}
