<div class="card-section">
  <mat-card class="app-card display-card">

    <mat-card-header class="card-header vertical-align-text">
      <img mat-card-avatar class="vertical-align-text" src="../../../../assets/images/placeholders/image_placeholder.png">
      <mat-card-title>
        <div class="ghost-title lines"></div>
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div fxLayout="column lines">
        <ng-container *ngFor="let row of getRows()">
          <div fxLayout="row" class="card-content">
            <div fxFlex class="lines align-center-vertical"></div>
          </div>
        </ng-container>
      </div>
    </mat-card-content>

  </mat-card>
</div>
