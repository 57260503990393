import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import * as _ from 'lodash';

export interface IGlobalSubcategoryPost extends IBaseAuditModel {
  name: string;
  globalCategoryId: number;
}

export class GlobalSubcategoryPost extends BaseAuditModel {
  name: string;
  globalCategoryId: number;

  constructor(data?: IGlobalSubcategoryPost) {
    super(data);

    if (!data) {
      return;
    }
    _.assign(this, data);
  }
}
