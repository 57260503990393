import { Action } from '@ngrx/store';
import { PortfolioPut } from 'src/app/core/models/portfolio-put.model';
import { Portfolio } from 'src/app/core/models/portfolio.model';

export enum PortfolioApiActionTypes {
  LoadSuccess = '[Portfolio/API] Load Success',
  LoadFailure = '[Portfolio/API] Load Failure',
  CreateSuccess = '[Portfolio/API] Create Success',
  CreateFailure = '[Portfolio/API] Create Failure',
  UpdateSuccess = '[Portfolio/API] Update Success',
  UpdateFailure = '[Portfolio/API] Update Failure',
  RenameSuccess = '[Portfolio/API] Rename Success',
  RenameFailure = '[Portfolio/API] Rename Failure',
  DeleteSuccess = '[Portfolio/API] Delete Success',
  DeleteFailure = '[Portfolio/API] Delete Failure'
}

export class LoadSuccess implements Action {
  readonly type = PortfolioApiActionTypes.LoadSuccess;

  constructor(public payload: Portfolio) { }
}

export class LoadFailure implements Action {
  readonly type = PortfolioApiActionTypes.LoadFailure;

  constructor(public payload: any) { }
}

export class CreateSuccess implements Action {
  readonly type = PortfolioApiActionTypes.CreateSuccess;

  constructor(public payload: Portfolio) { }
}

export class CreateFailure implements Action {
  readonly type = PortfolioApiActionTypes.CreateFailure;

  constructor(public payload: any) { }
}


export class UpdateSuccess implements Action {
  readonly type = PortfolioApiActionTypes.UpdateSuccess;

  constructor(public payload: { portfolio: PortfolioPut, portfolioName: string }) { }
}

export class UpdateFailure implements Action {
  readonly type = PortfolioApiActionTypes.UpdateFailure;

  constructor(public payload: any) { }
}

export class RenameSuccess implements Action {
  readonly type = PortfolioApiActionTypes.RenameSuccess;

  constructor(public payload: { portfolioId: number, portfolioName: string }) { }
}

export class RenameFailure implements Action {
  readonly type = PortfolioApiActionTypes.RenameFailure;

  constructor(public payload: any) { }
}

export class DeleteSuccess implements Action {
  readonly type = PortfolioApiActionTypes.DeleteSuccess;

  constructor(public payload: { portfolioId: number, portfolioName: string }) { }
}

export class DeleteFailure implements Action {
  readonly type = PortfolioApiActionTypes.DeleteFailure;

  constructor(public payload: any) { }
}

export type PortfolioApiActionsUnion =
  LoadSuccess
  | LoadFailure
  | CreateSuccess
  | CreateFailure
  | UpdateSuccess
  | UpdateFailure
  | RenameSuccess
  | RenameFailure
  | DeleteSuccess
  | DeleteFailure;
