import { Component, Input, OnInit } from '@angular/core';
import { AttributeOption } from 'src/app/core/models/attribute-option.model';
import { AttributeType } from 'src/app/core/models/attribute-type.model';

@Component({
  selector: 'app-attribute-type-readonly',
  templateUrl: './attribute-type-readonly.component.html',
  styleUrls: ['./attribute-type-readonly.component.scss']
})
export class AttributeTypeReadonlyComponent implements OnInit {
  @Input() attributeTypeName: string;
  @Input() impactsPriceSignificantly: boolean;
  @Input() isInDraft: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
