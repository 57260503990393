import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import * as _ from 'lodash';

export interface IContact extends IBaseAuditModel {
  name: string;
  email: string;
  phoneNumber: string;
  webPageUrl: string;
}

export class Contact extends BaseAuditModel implements IContact {
  name: string;
  email: string;
  phoneNumber: string;
  webPageUrl: string;

  constructor(data?: IContact) {
    super(data);

    if (!data) {
      return;
    }

    _.assign(this, data);
  }
}
