import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-content-title-icon',
  templateUrl: './card-content-title-icon.component.html',
  styleUrls: ['./card-content-title-icon.component.scss']
})
export class CardContentTitleIconComponent implements OnInit {
  @Input() preset: CardContentTitleIconPresets = CardContentTitleIconPresets.Default;
  @Input() src: string;
  @Input() alt: string;
  @Input() value: string;

  constructor() {
  }

  ngOnInit(): void {
    this.assignPresets();
  }

  private assignPresets(): void {
    if (this.preset == CardContentTitleIconPresets.Default) {
      return;
    }

    this.assignPresetSrc(this.preset);
    this.assignPresetAlt(this.preset);
  }

  private assignPresetSrc(preset: CardContentTitleIconPresets): void {
    // Use the value assigned by the input.
    if (this.src && this.src?.length > 0) {
      return;
    }

    switch(preset) {
      case CardContentTitleIconPresets.AutoCad2D: {
        this.src = '../../../../assets/images/file-types/icon-dwg.png';
        break;
      }
      case CardContentTitleIconPresets.AutoCad3D: {
        this.src = '../../../../assets/images/file-types/icon-autodesk.png';
        break;
      }
      case CardContentTitleIconPresets.Cet: {
        this.src = '../../../../assets/images/file-types/icon-cet.png';
        break;
      }
      case CardContentTitleIconPresets.FastOfficeDwg: {
        this.src = '../../../../assets/images/file-types/icon-fastOffice.png';
        break;
      }
      case CardContentTitleIconPresets.Revit: {
        this.src = '../../../../assets/images/file-types/icon-revit.png';
        break;
      }
      case CardContentTitleIconPresets.Sif: {
        this.src = '../../../../assets/images/file-types/icon-sif.png';
        break;
      }
      case CardContentTitleIconPresets.Sketchup: {
        this.src = '../../../../assets/images/file-types/icon-sketchup.png';
        break;
      }
      case CardContentTitleIconPresets.ThreeDsMax: {
        this.src = '../../../../assets/images/file-types/icon-3ds-max.png';
        break;
      }
      default: {
        this.src = '../../../../assets/images/placeholders/image_placeholder.png';
        break;
      }
    }
  }

  private assignPresetAlt(preset: CardContentTitleIconPresets): void {
    // Use the value assigned by the input.
    if (this.alt && this.alt?.length > 0) {
      return;
    }

    switch(preset) {
      case CardContentTitleIconPresets.AutoCad2D: {
        this.alt = 'AutoCAD 2D File';
        break;
      }
      case CardContentTitleIconPresets.AutoCad3D: {
        this.alt = 'AutoCAD 3D File';
        break;
      }
      case CardContentTitleIconPresets.Cet: {
        this.alt = 'CET File';
        break;
      }
      case CardContentTitleIconPresets.FastOfficeDwg: {
        this.alt = 'Fast Office File';
        break;
      }
      case CardContentTitleIconPresets.Revit: {
        this.alt = 'Revit File';
        break;
      }
      case CardContentTitleIconPresets.Sif: {
        this.alt = 'SIF File';
        break;
      }
      case CardContentTitleIconPresets.Sketchup: {
        this.alt = 'Sketchup File';
        break;
      }
      case CardContentTitleIconPresets.ThreeDsMax: {
        this.alt = '3ds Max File';
        break;
      }
      default: {
        this.alt = null;
        break;
      }
    }
  }

}

export enum CardContentTitleIconPresets {
  Default,
  AutoCad2D,
  AutoCad3D,
  Cet,
  FastOfficeDwg,
  Revit,
  Sif,
  Sketchup,
  ThreeDsMax
}
