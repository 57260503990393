import { IGlobalConfigurationAssociation, GlobalConfigurationAssociation } from '../models/global-configuration-association.model';
import { GlobalConfigurationDimensionsPut } from './../models/global-configurations-dimensions-put.model';
import { GlobalConfigurationPost } from './../models/global-configuration-post.model';
import { PagedResults } from 'src/app/core/models/page-results.model';
import { GlobalConfiguration, IGlobalConfiguration } from 'src/app/core/models/global-configuration.model';
import { ProductNumbersService } from 'src/app/core/services/product-numbers.service';
import { map, catchError, finalize } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from './../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { GlobalConfigurationProductNumber } from './../models/global-configuration-product-number.model';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { IAlbum } from 'ngx-lightbox';
import { ImagesService } from './images.service';
import { RenamePut } from '../models/rename-put.model';
import { GlobalConfigurationPut, ProductNumberReprocessRequestPut } from '../models/global-configuration-put.model';
import { IConfigurationsPageRequest } from '../models/configurations-page-request.model';
import { GlobalConfigurationModelFilesProfilesPut } from '../models/global-configurations-model-files-profile-put.model';
import { GlobalConfigurationLeadtimesPut } from '../models/global-configurations-leadtimes-put.model';
import { GlobalConfigurationMediaLink } from '../models/global-configuration-media-link.model';
import { MediaTypeIds } from 'src/app/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class GlobalConfigurationsService {

  basePath = 'globalConfigurations';

  constructor(
    private imageService: ImagesService,
    private productNumbersService: ProductNumbersService,
    private http: HttpClient) { }

    createGlobalConfiguration$(globalConfiguration: GlobalConfigurationPost): Observable<GlobalConfiguration> {
      return this.http
        .post(environment.apiUrl + `${this.basePath}`, globalConfiguration)
        .pipe(
          map(response => response),
          catchError((error: Response) => {
            return throwError(error);
          }),
          map((globalConfiguration: IGlobalConfiguration) => new GlobalConfiguration(globalConfiguration))
        );
    }

    getGlobalConfiguration$(id: number): Observable<GlobalConfiguration> {
      return this.http
        .get(environment.apiUrl + `${this.basePath}/${id}`)
        .pipe(
          map(response => response),
          map((x: IGlobalConfiguration) => new GlobalConfiguration(x))
        );
    }

    getGlobalConfigurationAssociations$(id: number): Observable<Array<IGlobalConfigurationAssociation>> {
      return this.http
        .get(environment.apiUrl + `${this.basePath}/${id}/associations`)
        .pipe(
          map(response => response),
          map((x: Array<IGlobalConfigurationAssociation>) => x.map(y => new GlobalConfigurationAssociation(y)))
        );
    }

    getGlobalConfigurations$(request: IConfigurationsPageRequest): Observable<PagedResults<GlobalConfiguration>> {
      if (request.sortOrder === '') {
        request.orderBy = 'name';
        request.sortOrder = 'asc';
      }

      let params = new HttpParams();
      params = params.append('search', request.searchTerm);
      params = params.append('pageNumber', request.pageNumber.toString());
      params = params.append('pageSize', request.pageSize.toString());
      params = params.append('orderBy', request.orderBy);
      params = params.append('sortOrder', request.sortOrder);

      if (request.integrationIds && request.integrationIds.length > 0) {
        request.integrationIds.forEach(x => {
          params = params.append('integrationIds', x.toString());
        });
      }

      if (request.localeIds && request.localeIds.length > 0) {
        request.localeIds.forEach(x => {
          params = params.append('localeIds', x.toString());
        });
      }

      if (request.excludedGlobalConfigurationIds && request.excludedGlobalConfigurationIds.length > 0) {
        request.excludedGlobalConfigurationIds.forEach(x => {
          params = params.append('excludedGlobalConfigurationIds', x.toString());
        });
      }

      return this.http.get(`${environment.apiUrl}globalproducts/${request.globalProductId}/globalconfigurations`, { params }).pipe(
        map((response: PagedResults<GlobalConfiguration>) => response)
      );
    }

    updateGlobalConfiguration$(globalConfigurationPut: GlobalConfigurationPut): Observable<Object> {      
      return this.http
        .put(`${environment.apiUrl}${this.basePath}/${globalConfigurationPut.id}`, globalConfigurationPut)
        .pipe(
          map(response => response),
          catchError((err: Response) => {
            console.log('Error on global configuration update:');
            console.log(err);
            return throwError(err);
          })
        );
    }

    updateConfigurationDimensions$(globalConfigurationDimensionsPut: GlobalConfigurationDimensionsPut): Observable<any> {
      return this.http
      .put(`${environment.apiUrl}${this.basePath}/dimensions`, globalConfigurationDimensionsPut)
      .pipe(
        map(response => response),
        catchError((err: Response) => {
          console.log('Error on configuration dimensions update:');
          console.log(err);
          return throwError(err);
        })
      );
    }

    updateConfigurationModelFilesProfiles$(globalConfigurationModelFilesProfilesPut: GlobalConfigurationModelFilesProfilesPut): Observable<any> {
      return this.http
      .put(`${environment.apiUrl}${this.basePath}/modelFilesProfiles`, globalConfigurationModelFilesProfilesPut)
      .pipe(
        map(response => response),
        catchError((err: Response) => {
          console.log('Error on configuration model files profiles update:');
          console.log(err);
          return throwError(err);
        })
      );
    }

    updateConfigurationLeadtimes$(globalConfigurationLeadtimesPut: GlobalConfigurationLeadtimesPut): Observable<any> {
      return this.http
      .put(`${environment.apiUrl}${this.basePath}/leadtimes`, globalConfigurationLeadtimesPut)
      .pipe(
        map(response => response),
        catchError((err: Response) => {
          console.log('Error on configuration leadtimes update:');
          console.log(err);
          return throwError(err);
        })
      );
    }

    renameConfiguration$(configuration: GlobalConfiguration, newName: string): Observable<GlobalConfiguration> {
      const renamePut: RenamePut = new RenamePut({ name: newName });
      return this.http
        .put(
          environment.apiUrl + `${this.basePath}/${configuration.id}/rename`,
          renamePut,
          { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
        .pipe(
          map((response: GlobalConfiguration) => configuration),
          catchError((err: Response) => {
            return throwError(err);
          })
        );
    }

    deleteConfiguration$(configuration: GlobalConfiguration): Observable<GlobalConfiguration> {
      return this.http
        .delete(
          environment.apiUrl + `${this.basePath}/${configuration.id}`,
          { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
        .pipe(
          map((response: GlobalConfiguration) => configuration),
          catchError((err: Response) => {
            return throwError(err);
          })
        );
    }

    duplicateConfiguration$(configuration: GlobalConfiguration, newName: string): Observable<GlobalConfiguration> {
      return this.http
        .post(
          environment.apiUrl + `${this.basePath}/${configuration.id}/duplicate`,
          `\"${newName}\"`,
          { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
        .pipe(
          map((response: GlobalConfiguration) => new GlobalConfiguration(response)),
          catchError((err: Response) => {
            return throwError(err);
          })
        );
    }

    getConfigurationImages(configuration: GlobalConfiguration, imageType: MediaTypeIds): Array<GlobalConfigurationMediaLink> {
      if (!configuration || configuration.globalConfigurationMediaLinks.length === 0) {
        return null;
      }

      const configurationImages = configuration.globalConfigurationMediaLinks.filter(i => i.mediaTypeId === imageType).sort((a, b) => a.ordinal - b.ordinal);
      if (configurationImages.length === 0) {
        return null;
      }

      return configurationImages;
    }

    getConfigurationImageAlbum = (configurationImages: Array<GlobalConfigurationMediaLink>): IAlbum[] => {
      const album: IAlbum[] = [];
      if (configurationImages && configurationImages.length > 0) {
        configurationImages.forEach((configurationImage) => {
          album.push({
            src: configurationImage.mediaLink,
            caption: this.imageService.getName(configurationImage.mediaLink),
            thumb: configurationImage.mediaLink,
            downloadUrl: configurationImage.mediaLink
          });
        });
      }

      return album;
    }

    public getLeadTimeByConfiguration(configuration: GlobalConfiguration, currencyId: number): number {
      if (!configuration) {
        return null;
      }

      return this.getLeadTimeByProductNumbers(configuration.productNumbers, currencyId);
    }

    public getLeadTimeByProductNumbers(globalConfigurationProductNumbers: Array<GlobalConfigurationProductNumber>, currencyId: number) {
      if (globalConfigurationProductNumbers.length <= 0) {
        return null;
      }

      // For this, we'll be looking specifically for the lead time with the maximum value.
      return this.productNumbersService.getProductNumberWithMaxLeadTime(globalConfigurationProductNumbers.map(x => x.productNumber)).leadTime;
    }

    public getProductLineCodeByConfiguration(configuration: GlobalConfiguration, currencyId: number): string {
      if (!configuration) {
        return null;
      }

      return this.getProductLineCodeByProductNumbers(configuration.productNumbers, currencyId);
    }

    public getProductLineCodeByProductNumbers(productNumbers: Array<GlobalConfigurationProductNumber>, currencyId: number) {
      if (productNumbers.length <= 0) {
        return null;
      }

      return this.productNumbersService.getProductNumberWithMaxLeadTime(productNumbers.map(x => x.productNumber)).productLineCode;
    }

    getAllGlobalConfigurations$(request: IConfigurationsPageRequest): Observable<PagedResults<GlobalConfiguration>> {
      if (request.sortOrder === '') {
        request.orderBy = 'name';
        request.sortOrder = 'asc';
      }
      let params = new HttpParams();
      params = params.append('search', request.searchTerm);
      params = params.append('pageNumber', request.pageNumber.toString());
      params = params.append('pageSize', request.pageSize.toString());
      params = params.append('orderBy', request.orderBy);
      params = params.append('sortOrder', request.sortOrder);      

      return this.http.get(`${environment.apiUrl}allglobalconfigurations`, { params }).pipe(
        map((response: PagedResults<GlobalConfiguration>) => response)
      );
    }

    public reprocessInvalidProductNumbers$(productNumberReprocessRequestPut: ProductNumberReprocessRequestPut): Observable<HttpResponse<Object>> {
      try {
        return this.http
          .post(environment.apiUrl + `${this.basePath}/reprocessinvalidprices`, productNumberReprocessRequestPut, { observe: 'response' })
          .pipe(
            map(response => {
              console.log('Response from reprocess prices:');
              console.log(response);
              return response;
            }),
            catchError((err: any) => {
              console.log('Error in reprocess prices:');
              console.log(err);
              return throwError(err);
            }),
            finalize(() => {
              console.log('came here in finalize of reprocess prices');
            })
          );
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
}

