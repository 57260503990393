import * as _ from 'lodash';

export class PagedResults<T> {
  pageNumber: number;
  pageSize: number;
  totalRecordCount: number = 0;
  totalPageCount: number = 0;
  results: Array<T> = [];

  constructor(data?: PagedResults<T>) {
    if (!data) {
      return;
    }

    _.assign(this, data);
  }
}
