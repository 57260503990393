import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import * as fromStore from '../../reducers';

import {
  GlobalSubcategoryApiActions,
  GlobalSubcategoryActions
} from '../actions';

import { switchMap, map, catchError, tap, mergeMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { GlobalSubcategoriesService } from 'src/app/core/services/global-subcategories.service';

@Injectable()
export class GlobalSubcategoryEffects {

  loadGlobalSubcategory$ = createEffect(() => this.actions$.pipe(
    ofType(GlobalSubcategoryActions.GlobalSubcategoryActionTypes.LoadGlobalSubcategory),
    map((action: GlobalSubcategoryActions.LoadGlobalSubcategory) => action.payload),
    switchMap(globalSubcategoryId =>
      this.globalSubcategoriesService.getGlobalSubcategory$(globalSubcategoryId).pipe(
        map(
          (globalSubcategory) => new GlobalSubcategoryApiActions.LoadSuccess(globalSubcategory)
        ),
        catchError(error =>
          of(new GlobalSubcategoryApiActions.LoadFailure(error))
        )
      )
    )
  ));

  createGlobalSubcategory$ = createEffect(() => this.actions$.pipe(
    ofType<GlobalSubcategoryActions.CreateGlobalSubcategory>(GlobalSubcategoryActions.GlobalSubcategoryActionTypes.CreateGlobalSubcategory),
    switchMap((request) =>
      this.globalSubcategoriesService.createGlobalSubcategory$(request.payload).pipe(
        map((globalSubcategory) =>
          new GlobalSubcategoryApiActions.CreateSuccess(globalSubcategory)
        ),
        tap((response) => {
          // Navigate to the page in edit mode.
          this.router.navigate([`products/organizational-data/global-subcategory-detail/${response.payload.id}`], { queryParams: { edit: true } });
        }),
        catchError(error =>
          of(new GlobalSubcategoryApiActions.CreateFailure(error))
        )
      )
    )
  ));

  updateGlobalSubcategory$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<GlobalSubcategoryActions.UpdateGlobalSubcategory>(GlobalSubcategoryActions.GlobalSubcategoryActionTypes.UpdateGlobalSubcategory),
    switchMap(request =>
      this.globalSubcategoriesService.updateGlobalSubcategory$(request.payload.globalSubcategory).pipe(
        map((response) => new GlobalSubcategoryApiActions.UpdateSuccess({ globalSubcategory: request.payload.globalSubcategory, globalSubcategoryName: request.payload.globalSubcategoryName })),
        catchError(error => of(new GlobalSubcategoryApiActions.UpdateFailure(error)))
      )
    )
  ));

  updateGlobalSubcategorySuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GlobalSubcategoryApiActions.GlobalSubcategoryApiActionTypes.UpdateSuccess),
    map((action: GlobalSubcategoryApiActions.UpdateSuccess) => action.payload),
    map(payload =>
      // Reload the subcategory from the API if the save was successful.
      new GlobalSubcategoryActions.LoadGlobalSubcategory(payload.globalSubcategory.id)
    )
  ));

  renameGlobalSubcategory$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<GlobalSubcategoryActions.RenameGlobalSubcategory>(GlobalSubcategoryActions.GlobalSubcategoryActionTypes.RenameGlobalSubcategory),
    switchMap((request) =>
      this.globalSubcategoriesService.renameGlobalSubcategory$({
        globalSubcategoryId: request.payload.globalSubcategoryId,
        globalSubcategoryName: request.payload.globalSubcategoryName
      }).pipe(
        map(_ => new GlobalSubcategoryApiActions.RenameSuccess({
          globalSubcategoryId: request.payload.globalSubcategoryId,
          globalSubcategoryName: request.payload.globalSubcategoryName
        })
        ),
        tap(res => this.store.dispatch(new GlobalSubcategoryActions.LoadGlobalSubcategory(request.payload.globalSubcategoryId))),
        catchError(error =>
          of(new GlobalSubcategoryApiActions.RenameFailure(error)),
        )
      )
    )
  )
  );

  deleteGlobalSubcategory$ = createEffect(() => this.actions$.pipe(
    ofType<GlobalSubcategoryActions.DeleteGlobalSubcategory>(GlobalSubcategoryActions.GlobalSubcategoryActionTypes.DeleteGlobalSubcategory),
    switchMap((request) =>
      this.globalSubcategoriesService.deleteGlobalSubcategory$(request.payload.globalSubcategoryId).pipe(
        map((response) =>
          new GlobalSubcategoryApiActions.DeleteSuccess({ globalSubcategoryId: request.payload.globalSubcategoryId, globalSubcategoryName: request.payload.globalSubcategoryName })
        ),
        tap((response) => {
          // Navigate to all subcategories page
          this.router.navigate([`products/organizational-data/global-subcategories`]);
        }),
        catchError(error =>
          of(new GlobalSubcategoryApiActions.DeleteFailure(error))
        )
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<fromStore.State>,
    private globalSubcategoriesService: GlobalSubcategoriesService) { }

}
