import { Action } from '@ngrx/store';

export enum SelectOptionsActionTypes {
  LoadSelectOptions = '[SelectOptions] Load Select Options',
  LoadSelectOptionsByCode = '[SelectOptions] Load Select Options By Code'
}

export class LoadSelectOptions implements Action {
  readonly type = SelectOptionsActionTypes.LoadSelectOptions;

  constructor() { }
}
export class LoadSelectOptionsByCode implements Action {
  readonly type = SelectOptionsActionTypes.LoadSelectOptionsByCode;

  constructor(public payload: string) { }
}

export type SelectOptionsActionsUnion =
  LoadSelectOptions | LoadSelectOptionsByCode;
