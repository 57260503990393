import { assign as _assign } from 'lodash-es';
import { IBaseAuditModel, BaseAuditModel } from './base-audit.model';

export interface ILocale extends IBaseAuditModel {
  name: string;
  code: string;
  regionId?: number;
  regionName: string;
  regionCode: string;
  isHMStore: boolean;
}

export class Locale extends BaseAuditModel {
  name: string;
  code: string;
  regionId?: number;
  regionName: string;
  regionCode: string;
  isHMStore: boolean;

  constructor(data?: ILocale) {
    super(data);

    if (data) {
      _assign(this, data);
    }
  }
}
