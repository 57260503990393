import {
  TechnologiesApiActions,
  TechnologiesActions
} from '../actions';

import { Technology } from 'src/app/core/models/technology.model';


export interface State {
  technologies: Technology[];
  loading: boolean;
  loaded: boolean;
  error: string;
}

export const initialState: State = {
  technologies: [],
  loading: false,
  loaded: false,
  error: ''
};

export function reducer(state = initialState, action: TechnologiesActions.TechnologiesActionsUnion | TechnologiesApiActions.TechnologiesApiActionsUnion): State {
  switch (action.type) {

    case TechnologiesActions.TechnologiesActionTypes.LoadTechnologies: {
      return {
        ...state,
        loading: true
      };
    }

    case TechnologiesApiActions.TechnologiesApiActionTypes.FetchSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        technologies: action.payload
      };
    }

    case TechnologiesApiActions.TechnologiesApiActionTypes.FetchFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        technologies: [],
        error: action.payload
      };
    }

    default:
      return state;
  }
}

export const getTechnologies = (state: State) => state.technologies;
export const isLoading = (state: State) => state.loading;
export const isLoaded = (state: State) => state.loaded;
