import { Region } from 'src/app/core/models/region.model';

import {
  RegionApiActions,
  RegionActions
} from '../actions';

export interface State {
  regionsLoading: boolean;
  regionsLoaded: boolean;
  regions: Array<Region>;
  error: string;
}

export const initialState: State = {
  regionsLoading: false,
  regionsLoaded: false,
  regions: [],
  error: ''
};

export function reducer(state = initialState, action: RegionActions.RegionActionsUnion | RegionApiActions.RegionApiActionsUnion): State {
  switch (action.type) {

    case RegionActions.RegionActionTypes.LoadRegions: {
      return {
        ...state,
        regionsLoading: true,
        regionsLoaded: false,
        error: null
      };
    }

    case RegionApiActions.RegionApiActionTypes.LoadSuccess: {
      return {
        ...state,
        regions: action.payload,
        regionsLoading: false,
        regionsLoaded: true
      };
    }

    case RegionApiActions.RegionApiActionTypes.LoadFailure: {
      return {
        ...state,
        regions: null,
        regionsLoading: false,
        regionsLoaded: false,
        error: action.payload
      };
    }

    default:
      return state;
  }
}

export const getRegionsIsLoading = (state: State) => state.regionsLoading;
export const getRegionsIsLoaded = (state: State) => state.regionsLoaded;
export const getRegions = (state: State) => state.regions;
