import { FilesService } from '../../../core/services/files.service';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-content-file-readonly',
  templateUrl: './card-content-file-readonly.component.html',
  styleUrls: ['./card-content-file-readonly.component.scss']
})
export class CardContentFileReadonlyComponent {
  @Input() value: string;

  constructor(private filesService: FilesService) { }

  public getFileName(url: string): string {
    return this.filesService.getName(url);
  }
}
