import { IPageRequestBuilder } from './page-request-builder.service';
import { ConfigurationsPageRequest } from './../models/configurations-page-request.model';

export class ConfigurationsPageRequestBuilder implements IPageRequestBuilder<ConfigurationsPageRequestBuilder, ConfigurationsPageRequest> {

  private request: ConfigurationsPageRequest = new ConfigurationsPageRequest();

  constructor() {
  }

  public setGlobalProductId(globalProductId: number): ConfigurationsPageRequestBuilder {
    this.request.globalProductId = globalProductId;
    return this;
  }

  public setPageNumber(pageNumber: number): ConfigurationsPageRequestBuilder {
    this.request.pageNumber = pageNumber;
    return this;
  }

  public setPageSize(pageSize: number): ConfigurationsPageRequestBuilder {
    this.request.pageSize = pageSize;
    return this;
  }

  public setOrderBy(orderBy: string): ConfigurationsPageRequestBuilder {
    this.request.orderBy = orderBy;
    return this;
  }

  public setSortOrder(sortOrder: string): ConfigurationsPageRequestBuilder {
    this.request.sortOrder = sortOrder;
    return this;
  }

  public setSearchTerm(searchTerm: string): ConfigurationsPageRequestBuilder {
    this.request.searchTerm = searchTerm;
    return this;
  }

  public setIntegrationIds(integrationIds: Array<number>): ConfigurationsPageRequestBuilder {
    this.request.integrationIds = [...integrationIds];
    return this;
  }

  public setLocaleIds(localeIds: Array<number>): ConfigurationsPageRequestBuilder {
    this.request.localeIds = [...localeIds];
    return this;
  }

  public setExcludedGlobalConfigurationIds(excludedGlobalConfigurationIds: Array<number>): ConfigurationsPageRequestBuilder {
    this.request.excludedGlobalConfigurationIds = [...excludedGlobalConfigurationIds];
    return this;
  }

  public build(): ConfigurationsPageRequest {
    return this.request;
  }
}
