import { ContactPost } from '../../../core/models/contact-post.model';
import { Action } from '@ngrx/store';
import { ContactPut } from 'src/app/core/models/contact-put.model';

export enum ContactActionTypes {
  LoadContact = '[Contact] Load Contact',
  CreateContact = '[Contact] Create Contact',
  UpdateContact = '[Contact] Update Contact',
  RenameContact = '[Contact] Rename Contact',
  DeleteContact = '[Contact] Delete Contact',
  ClearMessages = '[Contact] Clear Messages',
}

export class LoadContact implements Action {
  readonly type = ContactActionTypes.LoadContact;

  constructor(public payload: number) { }
}

export class CreateContact implements Action {
  readonly type = ContactActionTypes.CreateContact;

  constructor(public payload: ContactPost) { }
}

export class UpdateContact implements Action {
  readonly type = ContactActionTypes.UpdateContact;

  constructor(public payload: { contact: ContactPut, contactName: string }) { }
}

export class RenameContact implements Action {
  readonly type = ContactActionTypes.RenameContact;

  constructor(public payload: { contactId: number, contactName: string }) { }
}

export class DeleteContact implements Action {
  readonly type = ContactActionTypes.DeleteContact;

  constructor(public payload: { contactId: number, contactName: string }) { }
}

export class ClearMessages implements Action {
  readonly type = ContactActionTypes.ClearMessages;

  constructor() { }
}

export type ContactActionsUnion =
  LoadContact
  | CreateContact
  | UpdateContact
  | RenameContact
  | DeleteContact
  | ClearMessages;
