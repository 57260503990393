import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import {
  TemplatesApiActions,
  TemplatesActions
} from '../actions';

import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { switchMap, map, tap, catchError } from 'rxjs/operators';
import { TemplatesService } from 'src/app/core/services/templates.service';


@Injectable()
export class TemplatesEffects {
  @Effect()
  loadIntegrationTemplateCollection$: Observable<Action> = this.actions$.pipe(
    ofType<TemplatesActions.LoadTemplates>(TemplatesActions.TemplatesActionTypes.LoadTemplates),
    switchMap(() =>
      this.templatesService.getIntegrationTemplateSummaries$().pipe(
        map((templates) => new TemplatesApiActions.LoadSuccess(templates)
        ),
        catchError(error =>
          of(new TemplatesApiActions.LoadFailure(error))
        )
      )
    )
  );

  @Effect()
  createTemplate$: Observable<Action> = this.actions$.pipe(
    ofType<TemplatesActions.CreateTemplate>(TemplatesActions.TemplatesActionTypes.CreateTemplate),
    switchMap((request) =>
      this.templatesService.createTemplate$(request.payload).pipe(
        map((createdTemplate) => new TemplatesApiActions.CreateSuccess(createdTemplate)
        ),
        catchError(error =>
          of(new TemplatesApiActions.CreateFailure(error))
        )
      )
    )
  );

  @Effect()
  renameTemplate$: Observable<Action> = this.actions$.pipe(
    ofType<TemplatesActions.RenameTemplate>(TemplatesActions.TemplatesActionTypes.RenameTemplate),
    switchMap((request) =>
      this.templatesService.renameTemplate$({
          templateId: request.payload.templateId,
          templateName: request.payload.templateName
        }).pipe(
        map(_ => new TemplatesApiActions.RenameSuccess({
          integrationId: request.payload.integrationId,
          templateId: request.payload.templateId,
          templateName: request.payload.templateName
          })
        ),
        catchError(error =>
          of(new TemplatesApiActions.RenameFailure(error)),
        )
      )
    )
  );

  @Effect()
  duplicateTemplate$: Observable<Action> = this.actions$.pipe(
    ofType<TemplatesActions.DuplicateTemplate>(TemplatesActions.TemplatesActionTypes.DuplicateTemplate),
    switchMap((request) =>
      this.templatesService.duplicateTemplate$(request.payload).pipe(
        map((duplicatedTemplate) => new TemplatesApiActions.DuplicateSuccess(duplicatedTemplate)
        ),
        catchError(error =>
          of(new TemplatesApiActions.DuplicateFailure(error))
        )
      )
    )
  );

  constructor(private actions$: Actions,
              private templatesService: TemplatesService) {}

}
