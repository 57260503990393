import { Store } from '@ngrx/store';
import { SelectOptions } from './../../../core/models/select-options.model';
import { Observable } from 'rxjs';
import { Locale } from './../../../core/models/locale.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentAsset, DocumentAssetType } from './../../../core/models/document-asset.model';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import * as fromStore from '../../../app-store/reducers';

@Component({
  selector: 'app-add-product-certifications-modal',
  templateUrl: './add-product-certifications-modal.component.html',
  styleUrls: ['./add-product-certifications-modal.component.scss']
})
export class AddProductCertificationsModalComponent implements OnInit {

  selectOptions$: Observable<SelectOptions>;
  result: AddProductCertificationResult = new AddProductCertificationResult(false, []);
  preSelectedDocumentUrls: Array<string> = [];
  customEntryFormGroup: FormGroup = new FormGroup({
    type: new FormControl('', [Validators.required]),
    url: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    locales: new FormControl('', [Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<AddProductCertificationsModalComponent>,
    private store: Store<fromStore.State>,
    @Inject(MAT_DIALOG_DATA) public data: {
      preSelectedDocumentUrls: Array<string>
    }) {
      this.selectOptions$ = this.store.select(fromStore.getSelectOptions);
    }

  ngOnInit() {
    if (this.data.preSelectedDocumentUrls) {
      this.preSelectedDocumentUrls = this.data.preSelectedDocumentUrls;
    }
  }

  confirmEntry() {
    this.result.isValid = true;
    this.result.documents.push(this.getCustomEntry());

    this.customEntryFormGroup.reset();
    this.dialogRef.close(this.result);
  }

  isPreSelected() {
    if (!this.preSelectedDocumentUrls) {
      return false;
    }

    const document = this.getCustomEntry();
    if (!document || !document.url) {
      return false;
    }

    return this.preSelectedDocumentUrls.some(u => u === document.url);
  }

  private getCustomEntry(): DocumentAsset {
    const document: DocumentAsset = new DocumentAsset();
    document.title = this.customEntryFormGroup.controls.name.value;
    document.url = this.customEntryFormGroup.controls.url.value;
    document.documentType = (this.customEntryFormGroup.controls.type.value as DocumentAssetType);
    document.locales = (this.customEntryFormGroup.controls.locales.value as Array<Locale>);

    return document;
  }

}

export class AddProductCertificationResult {
  constructor(public isValid: boolean, public documents: Array<DocumentAsset>) {  }
}
