import { Action } from '@ngrx/store';

export enum LocaleSpecifierActionTypes {
  LoadLocaleSpecifiers = '[LocaleSpecifier] Load Locale Specifiers',
}

export class LoadLocaleSpecifiers implements Action {
  readonly type = LocaleSpecifierActionTypes.LoadLocaleSpecifiers;

  constructor() { }
}

export type LocaleSpecifierActionsUnion =
    LoadLocaleSpecifiers;
