import { ImagesService } from './../../../core/services/images.service';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-image-url-modal',
  templateUrl: './add-image-url-modal.component.html',
  styleUrls: ['./add-image-url-modal.component.scss']
})
export class AddImageUrlModalComponent implements OnInit {
  formGroup: FormGroup;

  result: { fileName: string, imageUrl: string, isValid: boolean };

  constructor(
    public dialogRef: MatDialogRef<AddImageUrlModalComponent>,
    private imagesService: ImagesService,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      fieldName: string,
      placeholder: string,
      buttonText: string,
      imageUrl: string
    }) { }

  ngOnInit() {
    this.formGroup = new FormGroup({
      imageUrl: new FormControl(this.data.imageUrl ? this.data.imageUrl : '', [Validators.required])
    });

    this.result = { fileName: '', imageUrl: '', isValid: false };
  }

  addImage() {
    this.result.fileName = this.getFileName(this.formGroup.value.imageUrl);
    this.result.imageUrl = this.formGroup.value.imageUrl;
    this.result.isValid = true;

    this.dialogRef.close(this.result);
  }

  private getFileName(imageUrl: string): string {
    return this.imagesService.getName(imageUrl);
  }
}
