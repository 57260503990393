import { Action } from '@ngrx/store';
import { Technology } from 'src/app/core/models/technology.model';

export enum TechnologiesApiActionTypes {
  FetchSuccess = '[Technologies/API] Fetch Success',
  FetchFailure = '[Technologies/API] Fetch Failure',
}

export class FetchSuccess implements Action {
  readonly type = TechnologiesApiActionTypes.FetchSuccess;

  constructor(public payload: Technology[]) { }
}

export class FetchFailure implements Action {
  readonly type = TechnologiesApiActionTypes.FetchFailure;

  constructor(public payload: any) { }
}

export type TechnologiesApiActionsUnion = FetchSuccess | FetchFailure;
