import { LocaleSpecifier, ILocaleSpecifier } from '../models/locale-specifier.model';
import { Locale } from 'src/app/core/models/locale.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Region, IRegion } from '../models/region.model';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { cloneDeep as _cloneDeep } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class RegionsService {
  basePath = 'regions';
  constructor(private http: HttpClient) {
  }

  getRegions$(): Observable<Array<Region>> {
    return this.http
      .get(environment.apiUrl + `${this.basePath}`)
      .pipe(map(res => res)
      , catchError((err: Response) => {
        const obj = err;
        return observableThrowError(obj);
      })
      , map((list: Array<IRegion>) => list.map(r => new Region(r)))
      );
  }

  getRegion$(id: string): Observable<Region> {
     console.log(`getRegion$(${id})`);
     return this.http
            .get(environment.apiUrl + `${this.basePath}/${id}`)
            .pipe(map(response => response)
            , map((r: IRegion) => new Region(r)));
  }

  getLocaleSpecifiers$(): Observable<Array<LocaleSpecifier>> {
    return this.http
      .get(`${environment.apiUrl}localization/specifiers`)
      .pipe(
          map(response => response),
          catchError((error: Response) => {
            return observableThrowError(error);
          }),
          map((list: Array<ILocaleSpecifier>) => list.map(x => new LocaleSpecifier(x)))
      );
  }

  getAllLocalesFromRegions(regions: Array<Region>): Array<Locale> {
    const locales: Array<Locale> = [];
    if (!regions) { return locales; }
    [...regions.map(r => locales.push(...r.locales))];

    return locales;
  }

  // Returns only the regions AND locales for the locales specified.
  getFilteredRegionsAndLocales(regions: Array<Region>, locales: Array<Locale>): Array<Region> {
    const results: Array<Region> = [];
    if (!regions) { return []; }

    locales.forEach(l => {

      // Try to find the region already present in our result set.
      let region: Region = results.find(r => r.id === l.regionId);

      // If the region wasn't already in our result set, find it in our master set, clone it, and add it to our result set with no locales.
      if (!region) {
        region = regions.find(r => r.id === l.regionId);
        if (!region) { return; } // Still could not find region.  Invalid region for locale.

        region = _cloneDeep(region);
        region.locales = [];
        results.push(region);
      }

      region.locales.push(l);
    });

    return results;
  }
}
