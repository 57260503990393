import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

// Object Purpose: Form Control Retrieval from the product detail form.
@Injectable({
  providedIn: 'root'
})
export class ProductDetailFormSchemaService {

  constructor() { }

  // Form Groups
  public brandForm(form: FormGroup): FormGroup {
    return (form.controls.brand as FormGroup);
  }

  public organizationForm(form: FormGroup): FormGroup {
    return (form.controls.organization as FormGroup);
  }

  public productStatusForm(form: FormGroup): FormGroup {
    return (form.controls.productStatusId as FormGroup);
  }

  public categoriesForm(form: FormGroup): FormGroup {
    return (form.controls.categories as FormGroup);
  }

  public globalCategoriesForm(form: FormGroup): FormGroup {
    return (form.controls.globalCategories as FormGroup);
  }

  public localesForm(form: FormGroup): FormGroup {
    return (form.controls.locale as FormGroup);
  }

  public productTagForm(form: FormGroup): FormGroup {
    return (form.controls.productTag as FormGroup);
  }

  public attributesForm(form: FormGroup): FormGroup {
    return (form.controls.attributes as FormGroup);
  }

  public webLinksForm(form: FormGroup): FormGroup {
    return (form.controls.webLinks as FormGroup);
  }

  public thumbnailForm(form: FormGroup): FormGroup {
    return (form.controls.thumbnail as FormGroup);
  }

  public literatureForm(form: FormGroup): FormGroup {
    return (form.controls.literature as FormGroup);
  }

  public certificationsForm(form: FormGroup): FormGroup {
    return (form.controls.certifications as FormGroup);
  }

  public attributeOptionsForm(form: FormGroup): FormGroup {
    return (form.controls.attributeOptions as FormGroup);
  }

  public modelFilesProfilesForm(form: FormGroup) {
    return (form.controls.modelFilesProfiles as FormGroup)
  }

  public contactsForm(form: FormGroup) {
    return (form.controls.contacts as FormGroup)
  }

  public globalProductMediaLinksForm(form: FormGroup) {
    return (form.controls.globalProductMediaLinks as FormGroup)
  }

  public globalProductAttributeOptionsOfferingExclusionsForm(form: FormGroup) {
    return (form.controls.globalProductAttributeOptionOfferingExclusions as FormGroup)
  }

  // Form Arrays
  public localesFormArray(form: FormGroup): FormArray {
    return (this.localesForm(form).controls.locales as FormArray);
  }

  public productTagsFormArray(form: FormGroup): FormArray {
    return (this.productTagForm(form).controls.productTags as FormArray);
  }

  public productLinksFormArray(form: FormGroup): FormArray {
    return (this.webLinksForm(form).controls.productLinks as FormArray);
  }

  public storeLinksFormArray(form: FormGroup): FormArray {
    return (this.webLinksForm(form).controls.storeLinks as FormArray);
  }

  public literatureFormArray(form: FormGroup): FormArray {
    return (this.literatureForm(form).controls.literature as FormArray);
  }

  public certificationsFormArray(form: FormGroup): FormArray {
    return (this.certificationsForm(form).controls.certifications as FormArray);
  }

  public attributeOptionsFormArray(form: FormGroup): FormArray {
    return (this.attributeOptionsForm(form).controls.attributeOptions as FormArray);
  }

  public modelFilesProfilesFormArray(form: FormGroup): FormArray {
    return (this.modelFilesProfilesForm(form).controls.modelFilesProfiles as FormArray);
  }

  public globalProductMediaLinksFormArray(form: FormGroup): FormArray {
    return (this.globalProductMediaLinksForm(form).controls.globalProductMediaLinks as FormArray);
  }

  public globalProductAttributeOptionsOfferingExclusionsFormArray(form: FormGroup): FormArray {
    return (this.globalProductAttributeOptionsOfferingExclusionsForm(form).controls.globalProductAttributeOptionOfferingExclusions as FormArray);
  }

  public setModelFilesProfilesFormArray(form: FormGroup, udpatedFormControls: FormArray) {
    this.modelFilesProfilesForm(form).controls.modelFilesProfiles = udpatedFormControls;
  }

  public contactsFormArray(form: FormGroup): FormArray {
    return (this.contactsForm(form).controls.contacts as FormArray);
  }

  // Form Controls
  public brandIdFormControl(form: FormGroup): FormControl {
    return (this.brandForm(form).controls.brandId as FormControl);
  }

  public subBrandIdFormControl(form: FormGroup): FormControl {
    return (this.brandForm(form).controls.subBrandId as FormControl);
  }

  public includeInCoBrandingFormControl(form: FormGroup): FormControl {
    return (this.brandForm(form).controls.dealerCoBranding as FormControl);
  }

  public designersFormControl(form: FormGroup): FormControl {
    return (this.organizationForm(form).controls.designers as FormControl);
  }

  public familiesFormControl(form: FormGroup): FormControl {
    return (this.organizationForm(form).controls.families as FormControl);
  }

  public portfoliosFormControl(form: FormGroup): FormControl {
    return (this.organizationForm(form).controls.portfolios as FormControl);
  }

  public productStatusIdFormControl(form: FormGroup): FormControl {
    return (this.productStatusForm(form).controls.productStatusId as FormControl);
  }

  public subCategoriesFormControl(form: FormGroup): FormControl {
    return (this.categoriesForm(form).controls.subCategories as FormControl);
  }

  public primarySubcategoryIdFormControl(form: FormGroup): FormControl {
    return (this.categoriesForm(form).controls.primarySubcategoryId as FormControl);
  }

  public pageTitleKeywordFormControl(form: FormGroup): FormControl {
    return (this.categoriesForm(form).controls.pageTitleKeyword as FormControl);
  }

  public globalSubcategoriesFormControl(form: FormGroup): FormControl {
    return (this.globalCategoriesForm(form).controls.globalSubcategories as FormControl);
  }

  public primaryGlobalSubcategoryIdFormControl(form: FormGroup): FormControl {
    return (this.globalCategoriesForm(form).controls.primaryGlobalSubcategoryId as FormControl);
  }

  public primaryLocaleIdFormControl(form: FormGroup): FormControl {
    return (this.localesForm(form).controls.primaryLocaleId as FormControl);
  }

  public warrantyIdFormControl(form: FormGroup): FormControl {
    return (this.attributesForm(form).controls.warrantyId as FormControl);
  }

  public isSpecialDeliveryFormControl(form: FormGroup): FormControl {
    return (this.attributesForm(form).controls.isSpecialDelivery as FormControl);
  }

  public isEssentialsFormControl(form: FormGroup): FormControl {
    return (this.attributesForm(form).controls.isEssentials as FormControl);
  }

  public configuratorFormControl(form: FormGroup): FormControl {
    return (this.attributesForm(form).controls.configurator as FormControl);
  }

  public brandSiteProductLinkFormControl(form: FormGroup): FormControl {
    return (this.webLinksForm(form).controls.brandSiteProductLink as FormControl);
  }

  public materialsLinkFormControl(form: FormGroup): FormControl {
    return (this.webLinksForm(form).controls.materialsLink as FormControl);
  }

  public scoutLinkFormControl(form: FormGroup): FormControl {
    return (this.webLinksForm(form).controls.scoutLink as FormControl);
  }

  public omniLinkFormControl(form: FormGroup): FormControl {
    return (this.webLinksForm(form).controls.omniLink as FormControl);
  }
}
