import * as _ from 'lodash';

export interface IApplicationTagRenamePut {
  tag: string;
}

export class ApplicationTagRenamePut implements IApplicationTagRenamePut {
  tag = '';

  constructor(data?: IApplicationTagRenamePut) {
    if (data) {
      _.assign(this, data);
    }
  }
}
