import { SharedModule } from 'src/app/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LoginComponent } from './login/login.component';
import { ErrorComponent } from './error/error.component';

import { CoreModule } from './core/core.module';
import { AppStoreModule } from './app-store/app-store.module';
import { GlobalProductsService } from './core/services/global-products.service';
import { ApplicationsService } from './core/services/applications.service';
import { DatePipe, CurrencyPipe, PercentPipe } from '@angular/common';
import { UserPermissionsService } from './core/services/user-permissions.service';

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    LoginComponent
  ],
  imports: [
    CoreModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppStoreModule,
    SharedModule
  ],
  exports: [],
  providers: [
    GlobalProductsService,
    ApplicationsService,
    UserPermissionsService,
    DatePipe,
    CurrencyPipe,
    PercentPipe
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
