import { ProductNumberPriceCalculationService } from './product-number-price-calculation.service';
import { IGlobalConfiguration } from './../models/global-configuration.model';
import { ProductNumbersService } from './product-numbers.service';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class GlobalConfigurationsCalculationService {

  constructor(
    private productNumbersService: ProductNumbersService,
    private productNumberPriceCalculationService: ProductNumberPriceCalculationService) { }

  public getListPrice(globalConfiguration: IGlobalConfiguration, currencyId: number) {
    if (!globalConfiguration) {
      return 0;
    }

    const prices = this.productNumbersService.getPricesByProductNumbers(globalConfiguration.productNumbers?.map(x => x.productNumber), currencyId);
    return this.productNumberPriceCalculationService.sumOfAllListPrices(prices);
  }

  public getContractListPrice(globalConfiguration: IGlobalConfiguration, currencyId: number) {
    if (!globalConfiguration) {
      return 0;
    }

    const prices = this.productNumbersService.getPricesByProductNumbers(globalConfiguration.productNumbers?.map(x => x.productNumber), currencyId);
    return this.productNumberPriceCalculationService.sumOfAllContractListPrices(prices);
  }

  public getIsConfigurationContainsInvalidProductNumber(globalConfiguration: IGlobalConfiguration, currencyId: number): boolean {
    if (!globalConfiguration) {
      return false;
    }

    const isInValid = this.productNumbersService.getIsAnyProductNumberInvalid(globalConfiguration.productNumbers?.map(x => x.productNumber), currencyId);
    return isInValid;
  }

  public getLowestListPrice(globalConfigurations: Array<IGlobalConfiguration>, currencyId: number): number {
    if (!globalConfigurations || globalConfigurations.length <= 0) {
      return null;
    }

    globalConfigurations = globalConfigurations.filter(c => !c.excludeFromLowListPriceCalc);
    if (globalConfigurations.length <= 0) {
      return null;
    }

    const listPrices: Array<number> = [];
    globalConfigurations.forEach(configuration => {
      const listPrice = this.getListPrice(configuration, currencyId);
      if (listPrice && listPrice > 0) {
        listPrices.push(listPrice);
      }
    });

    return _.min(listPrices);
  }
}
