import {
  BrandApiActions,
  BrandActions
} from '../actions';

import { SelectOption } from 'src/app/core/models/select-option.model';
import { Brand } from 'src/app/core/models/brand.model';
import { BrandStatus } from 'src/app/shared/constants';

export interface State {
  brandOptions: SelectOption[];
  brand: Brand;
  loading: boolean;
  loaded: boolean;
  status: BrandStatus;
  error: string;
  success: string;
}

export const initialState: State = {
  brandOptions: [],
  brand: null,
  loading: false,
  loaded: false,
  status: BrandStatus.None,
  error: null,
  success: null
};

export function reducer(state = initialState, action: BrandActions.BrandActionsUnion | BrandApiActions.BrandApiActionsUnion): State {
  switch (action.type) {

    case BrandActions.BrandActionTypes.LoadBrands: {
      return {
        ...state,
        loading: true
      };
    }

    case BrandApiActions.BrandApiActionTypes.FetchSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        brandOptions: action.payload
      };
    }

    case BrandApiActions.BrandApiActionTypes.FetchFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        brandOptions: [],
        error: action.payload
      };
    }
    case BrandActions.BrandActionTypes.LoadBrand: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case BrandApiActions.BrandApiActionTypes.LoadSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        brand: action.payload
      };
    }

    case BrandApiActions.BrandApiActionTypes.LoadFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        brand: null,
        error: action.payload
      };
    }

    case BrandActions.BrandActionTypes.ClearMessages: {
      return {
        ...state,
        status: BrandStatus.None,
        error: null,
        success: null
      };
    }

    case BrandActions.BrandActionTypes.CreateBrand: {
      return {
        ...state,
      };
    }

    case BrandApiActions.BrandApiActionTypes.CreateSuccess: {
      return {
        ...state,
        brand: action.payload,
        status: BrandStatus.CreateSuccess,
      };
    }

    case BrandApiActions.BrandApiActionTypes.CreateFailure: {
      return {
        ...state,
        brand: null,
        status: BrandStatus.CreateFailure,
        error: 'Failed to Create Brand',
        success: null
      };
    }

    case BrandActions.BrandActionTypes.UpdateBrand: {
      return {
        ...state,
        loading: true,
        loaded: false,
        status: BrandStatus.None
      };
    }

    case BrandApiActions.BrandApiActionTypes.UpdateSuccess: {
      return {
        ...state,
        status: BrandStatus.UpdateSuccess,
        error: null,
        success: `Successfully Updated Brand: ${action.payload.brandName}`
      };
    }

    case BrandApiActions.BrandApiActionTypes.UpdateFailure: {
      return {
        ...state,
        loading: false,
        loaded: true,
        status: BrandStatus.UpdateFailure,
        error: 'Failed to Update Brand',
        success: null
      };
    }

    case BrandActions.BrandActionTypes.RenameBrand: {
      return {
        ...state
      };
    }

    case BrandApiActions.BrandApiActionTypes.RenameSuccess: {
      const brand = { ...state.brand };
      brand.name = action.payload.brandName;

      return {
        ...state,
        status: BrandStatus.RenameSuccess,
        error: null,
        success: `Successfully renamed Brand: ${action.payload.brandName}`
      };
    }

    case BrandApiActions.BrandApiActionTypes.RenameFailure: {
      return {
        ...state,
        brand: null,
        status: BrandStatus.RenameFailure,
        error: 'Failed to rename Brand',
        success: null
      };
    }

    case BrandActions.BrandActionTypes.DeleteBrand: {
      return {
        ...state,
      };
    }

    case BrandApiActions.BrandApiActionTypes.DeleteSuccess: {
      return {
        ...state,
        status: BrandStatus.DeleteSuccess,
        error: null,
        success: `Successfully Deleted Brand: ${action.payload.brandName}`
      };
    }

    case BrandApiActions.BrandApiActionTypes.DeleteFailure: {
      return {
        ...state,
        brand: null,
        status: BrandStatus.DeleteFailure,
        error: action.payload?.error?.message ? `${action.payload?.error?.message}` : 'Failed to Delete Brand',
        success: null
      };
    }

    default:
      return state;
  }
}

export const getBrands = (state: State) => state.brandOptions;
export const getBrand = (state: State) => state.brand;
export const getErrorMessage = (state: State) => state.error;
export const getSuccessMessage = (state: State) => state.success;
export const getStatus = (state: State) => state.status;
export const isLoading = (state: State) => state.loading;
export const isLoaded = (state: State) => state.loaded;
