import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import { assign as _assign } from 'lodash-es';

export interface ISubBrandPost extends IBaseAuditModel {
  name: string;
  brandId: number;
}

export class SubBrandPost extends BaseAuditModel {
  name: string;
  brandId: number;

  constructor(data?: ISubBrandPost) {
    super(data);

    if (!data) {
      return;
    }
    _assign(this, data);
  }
}
