import { Action } from '@ngrx/store';
import { AttributeTypePost } from 'src/app/core/models/attribute-type-post.model';
import { AttributeTypePut } from 'src/app/core/models/attribute-type-put.model';


export enum AttributeTypeActionTypes {
  CreateAttributeType = '[AttributeType] Create AttributeType',
  RenameAttributeType = '[AttributeType] Rename AttributeType',
  DeleteAttributeType = '[AttributeType] Delete AttributeType',
  ClearMessages = '[AttributeType] Clear Messages',
}

export class CreateAttributeType implements Action {
  readonly type = AttributeTypeActionTypes.CreateAttributeType;

  constructor(public payload: AttributeTypePost) { }
}

export class RenameAttributeType implements Action {
  readonly type = AttributeTypeActionTypes.RenameAttributeType;

  constructor(public payload: { attributeTypeId: number, attributeTypeName: string }) { }
}

export class DeleteAttributeType implements Action {
  readonly type = AttributeTypeActionTypes.DeleteAttributeType;

  constructor(public payload: { attributeTypeId: number, attributeTypeName: string }) { }
}

export class ClearMessages implements Action {
  readonly type = AttributeTypeActionTypes.ClearMessages;

  constructor() { }
}

export type AttributeTypeActionsUnion =
  | CreateAttributeType
  | RenameAttributeType
  | DeleteAttributeType
  | ClearMessages;
