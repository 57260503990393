import { Action } from '@ngrx/store';
import { Integration } from 'src/app/core/models/integration.model';

export enum IntegrationApiActionTypes {
  LoadSuccess = '[Integration/API] Load Success',
  LoadFailure = '[Integration/API] Load Failure',
  UpdateSuccess = '[Integration/API] Update Success',
  UpdateFailure = '[Integration/API] Update Failure',
  RenameSuccess = '[Integration/API] Rename Success',
  RenameFailure = '[Integration/API] Rename Failure',
}

export class LoadSuccess implements Action {
  readonly type = IntegrationApiActionTypes.LoadSuccess;

  constructor(public payload: Integration) { }
}

export class LoadFailure implements Action {
  readonly type = IntegrationApiActionTypes.LoadFailure;

  constructor(public payload: any) { }
}

export class UpdateSuccess implements Action {
  readonly type = IntegrationApiActionTypes.UpdateSuccess;

  constructor(public payload: Integration) { }
}

export class UpdateFailure implements Action {
  readonly type = IntegrationApiActionTypes.UpdateFailure;

  constructor(public payload: any, public integration: Integration) { }
}

export class RenameSuccess implements Action {
  readonly type = IntegrationApiActionTypes.RenameSuccess;

  constructor(public payload: {integrationId: number, integrationName: string}) { }
}

export class RenameFailure implements Action {
  readonly type = IntegrationApiActionTypes.RenameFailure;

  constructor(public payload: any) { }
}

export type IntegrationApiActionsUnion = LoadSuccess | LoadFailure | UpdateSuccess | UpdateFailure | RenameSuccess | RenameFailure;
