import { GlobalProduct } from 'src/app/core/models/global-product.model';

import { ProductActions, ProductApiActions } from '../actions';
import { ProductActionTypes } from '../actions/product.actions';
import { ProductApiActionTypes } from '../actions/product-api.actions';
import { GlobalProductStatus } from 'src/app/shared/constants';

export interface State {
  loading: boolean;
  loaded: boolean;
  product: GlobalProduct;
  status: GlobalProductStatus;
  error: string;
  success: string;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  product: null,
  status: GlobalProductStatus.None,
  error: null,
  success: null,
};

export function reducer(state = initialState, action: ProductActions.ProductActionsUnion | ProductApiActions.ProductApiActionsUnion): State {
  switch (action.type) {

    case ProductActions.ProductActionTypes.LoadProduct: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case ProductApiActions.ProductApiActionTypes.LoadSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        product: action.payload,
      };
    }

    case ProductApiActions.ProductApiActionTypes.LoadFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        product: null,
        error: action.payload
      };
    }

    case ProductActionTypes.UpdateProduct: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case ProductApiActionTypes.UpdateSuccess: {
      return {
        ...state,
        error: null,
        success: `${state.product.name} was saved successfully.`,
        status: GlobalProductStatus.UpdateSuccess
      };
    }

    case ProductApiActionTypes.UpdateFailure: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: `Failed to Update Product: ${state.product.name}`,
        success: null,
        status: GlobalProductStatus.UpdateFailure
      };
    }

    case ProductActionTypes.DeleteProduct: {
      return {
        ...state,
      };
    }

    case ProductApiActions.ProductApiActionTypes.DeleteSuccess: {
      return {
        ...state,
        loading: false,
        loaded: false,
        product: null,
        error: null,
        success: `Successfully Deleted Product: ${state.product.name}`,
        status: GlobalProductStatus.DeleteSuccess
      };
    }

    case ProductApiActionTypes.DeleteFailure: {
      return {
        ...state,
        error: 'Failed to Delete Product.',
        success: null,
        status: GlobalProductStatus.DeleteFailure,
      };
    }

    case ProductActions.ProductActionTypes.CreateProduct: {
      return {
        ...state,
        product: { ...state.product, ...action.payload.product }
      };
    }

    case ProductApiActions.ProductApiActionTypes.CreateSuccess: {
      return {
        ...state,
        product: action.payload,
        loading: false,
        loaded: true,
        status: GlobalProductStatus.CreateSuccess
      };
    }

    case ProductApiActions.ProductApiActionTypes.CreateFailure: {
      return {
        ...state,
        product: null,
        loading: false,
        loaded: false,
        error: action.payload,
        success: null,
        status: GlobalProductStatus.CreateFailure
      };
    }

    case ProductActionTypes.RenameProduct: {
      return {
        ...state,
      };
    }

    case ProductApiActions.ProductApiActionTypes.RenameProductSuccess: {
      return {
        ...state,
        error: null,
        success: `Product successfully renamed to ${action.payload.newName}`,
        status: GlobalProductStatus.RenameSuccess,
      };
    }

    case ProductApiActions.ProductApiActionTypes.RenameProductFailure: {
      return {
        ...state,
        error: `Failed to rename product ${state.product.name}`,
        success: null,
        status: GlobalProductStatus.RenameFailure
      };
    }

    case ProductActionTypes.RenameConfiguration: {
      return {
        ...state,
      };
    }

    case ProductApiActions.ProductApiActionTypes.RenameConfigurationSuccess: {
      return {
        ...state,
        error: null,
        success: `Configuration successfully renamed to ${action.payload.newName}`,
        status: GlobalProductStatus.RenameConfigurationSuccess
      };
    }

    case ProductApiActions.ProductApiActionTypes.RenameConfigurationFailure: {
      return {
        ...state,
        error: `Failed to rename configuration ${action.payload.configuration.name}`,
        success: null,
        status: GlobalProductStatus.RenameConfigurationFailure,
      };
    }

    case ProductActionTypes.DeleteConfiguration: {
      return {
        ...state,
      };
    }

    case ProductApiActions.ProductApiActionTypes.DeleteConfigurationSuccess: {
      return {
        ...state,
        error: null,
        success: `Configuration ${action.payload.configuration.name} successfully deleted.`,
        status: GlobalProductStatus.DeleteConfigurationSuccess,
      };
    }

    case ProductApiActions.ProductApiActionTypes.DeleteConfigurationFailure: {
      return {
        ...state,
        error: `Failed to delete configuration ${action.payload.configuration.name}.`,
        success: null,
        status: GlobalProductStatus.DeleteConfigurationFailure,
      };
    }

    case ProductActionTypes.DuplicateConfiguration: {
      return {
        ...state,
      };
    }

    case ProductApiActions.ProductApiActionTypes.DuplicateConfigurationSuccess: {
      return {
        ...state,
        error: null,
        success: `Configuration successfully duplicated: ${action.payload.newName}`,
        status: GlobalProductStatus.DuplicateConfigurationSuccess
      };
    }

    case ProductApiActions.ProductApiActionTypes.DuplicateConfigurationFailure: {
      return {
        ...state,
        error: `Failed to duplicate configuration ${action.payload.configuration.name}`,
        success: null,
        status: GlobalProductStatus.DuplicateConfigurationFailure,
      };
    }

    case ProductActionTypes.ClearMessages: {
      return {
        ...state,
        error: null,
        success: null,
        status: GlobalProductStatus.None,
      };
    }

    case ProductActionTypes.ClearProduct: {
      return {
        ...state,
        product: null,
        loading: false,
        loaded: false,
        error: null,
        success: null,
        status: GlobalProductStatus.None,
      };
    }

    default:
      return state;
  }
}

export const getIsLoading = (state: State) => state.loading;
export const getIsLoaded = (state: State) => state.loaded;
export const getLoadedProduct = (state: State) => state.product;
export const getErrorMessage = (state: State) => state.error;
export const getSuccessMessage = (state: State) => state.success;
export const getStatus = (state: State) => state.status;
