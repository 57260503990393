import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-content-boolean-readonly',
  templateUrl: './card-content-boolean-readonly.component.html',
  styleUrls: ['./card-content-boolean-readonly.component.scss']
})
export class CardContentBooleanReadonlyComponent {
  @Input() value: boolean;

}
