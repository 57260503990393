import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as fromStore from '../../reducers';

import {
  ApplicationTagApiActions,  
  ApplicationTagActions
} from '../actions';

import { switchMap, map, catchError, tap, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ApplicationTagService } from 'src/app/core/services/application-tag.service';

@Injectable()
export class ApplicationTagEffects {

  @Effect()
  loadApplicationTag$ = this.actions$.pipe(
    ofType(ApplicationTagActions.ApplicationTagActionTypes.LoadApplicationTag),
    map((action: ApplicationTagActions.LoadApplicationTag) => action.payload),
    switchMap(applicationTagId =>
      this.applicationTagService.getApplicationTag$(applicationTagId).pipe(
        map(
          (applicationTag) => new ApplicationTagApiActions.LoadSuccess(applicationTag)
        ),
        catchError(error =>
          of(new ApplicationTagApiActions.LoadFailure(error))
        )
      )
    )
  );

  @Effect()
  createApplicationTag$ = this.actions$.pipe(
    ofType<ApplicationTagActions.CreateApplicationTag>(ApplicationTagActions.ApplicationTagActionTypes.CreateApplicationTag),
    switchMap((request) =>
      this.applicationTagService.createApplicationTag$(request.payload).pipe(
        map((objApplicationTag) =>
          new ApplicationTagApiActions.CreateSuccess(objApplicationTag)
        ),
        catchError(error =>
          of(new ApplicationTagApiActions.CreateFailure(error))
        )
      )
    )
  );

  @Effect()
  deleteApplicationTag$ = this.actions$.pipe(
    ofType<ApplicationTagActions.DeleteApplicationTag>(ApplicationTagActions.ApplicationTagActionTypes.DeleteApplicationTag),
    switchMap((request) =>
      this.applicationTagService.deleteApplicationTag$(request.payload.applicationTagId).pipe(
        map((response) =>
          new ApplicationTagApiActions.DeleteSuccess({ applicationTagId: request.payload.applicationTagId, applicationTagName: request.payload.applicationTagName })
        ),
        catchError(error =>
          of(new ApplicationTagApiActions.DeleteFailure(error))
        )
      )
    )
  );

  @Effect()
  renameApplicationTag$: Observable<Action> = this.actions$.pipe(
    ofType<ApplicationTagActions.RenameApplicationTag>(ApplicationTagActions.ApplicationTagActionTypes.RenameApplicationTag),
    switchMap((request) =>
      this.applicationTagService.renameApplicationTag$({
        applicationTagId: request.payload.applicationTagId,
        applicationTagName: request.payload.applicationTagName
      }).pipe(
        map(_ => new ApplicationTagApiActions.RenameSuccess({
          applicationTagId: request.payload.applicationTagId,
          applicationTagName: request.payload.applicationTagName
        })
        ),
        tap(res => this.store.dispatch(new ApplicationTagActions.LoadApplicationTag(request.payload.applicationTagId)),
          catchError(error =>
            of(new ApplicationTagApiActions.RenameFailure(error)),
          )
        )
      )
    )
  );

  

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<fromStore.State>,
    private applicationTagService: ApplicationTagService) { }

}
