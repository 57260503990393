import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import { assign as _assign } from 'lodash-es';

export interface IApplicationTagPost extends IBaseAuditModel {
  tag: string;
}

export class ApplicationTagPost extends BaseAuditModel {
  tag: string;

  constructor(data?: IApplicationTagPost) {
    super(data);

    if (!data) {
      return;
    }
    _assign(this, data);
  }
}
