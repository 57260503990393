import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import * as _ from 'lodash';

export interface IAttributeTypePost extends IBaseAuditModel {
  name: string;
}

export class AttributeTypePost extends BaseAuditModel {
  name: string;

  constructor(data?: IAttributeTypePost) {
    super(data);

    if (!data) {
      return;
    }
    _.assign(this, data);
  }
}
