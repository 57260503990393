<div>
  <div mat-dialog-title>
    <h6 class="h6">
      <strong>
        {{ data.title }}
      </strong>
    </h6>
  </div>

  <form [formGroup]="formGroup" autocomplete="off" novalidate (ngSubmit)="addImage()">
    <div mat-dialog-content>
      <mat-form-field appearance="outline">
        <mat-label>{{ data.fieldName }}</mat-label>
        <input autocomplete="off"
          matInput
          (focus)="$event.target.select();"
          id="name"
          formControlName="imageUrl"
          [placeholder]="data.placeholder">
      </mat-form-field>
    </div>

    <div mat-dialog-actions>
      <button class="mat-raised-button button-spacing" [mat-dialog-close]="result">Cancel</button>
      <button class="mat-raised-button primary-button" [disabled]="!formGroup.valid">{{ data.buttonText }}</button>
    </div>
  </form>
</div>
