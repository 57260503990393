import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import * as _ from 'lodash';
import { IImageLink, ImageLink } from './image-link.model';

export interface ISubBrand extends IBaseAuditModel {
  name: string;
  description: string;
  brandId: number;
  brandName: string;
  subBrandImages: Array<IImageLink>;
}

export class SubBrand extends BaseAuditModel implements ISubBrand {
  name: string;
  description: string;
  brandId: number;
  brandName: string;
  subBrandImages: Array<ImageLink> = [];

  constructor(data: ISubBrand) {
    super(data);

    if (!data) {
      return;
    }

    _.assign(this, data);
  }
}
