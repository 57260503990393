import {
  IntegrationsApiActions,
  IntegrationsActions
} from '../actions';

import { IntegrationSummary } from 'src/app/core/models/integration-summary.model';

export interface State {
  integrationSummaryList: IntegrationSummary[];
  loading: boolean;
  loaded: boolean;
  error: string;
}

export const initialState: State = {
  integrationSummaryList: [],
  loading: false,
  loaded: false,
  error: ''
};

export function reducer(state = initialState, action: IntegrationsActions.IntegrationsActionsUnion | IntegrationsApiActions.IntegrationsApiActionsUnion): State {
  switch (action.type) {

    case IntegrationsActions.IntegrationsActionTypes.LoadIntegrations: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case IntegrationsApiActions.IntegrationsApiActionTypes.LoadSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        integrationSummaryList: action.payload
      };
    }

    case IntegrationsApiActions.IntegrationsApiActionTypes.LoadFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        integrationSummaryList: [],
        error: action.payload
      };
    }

    default:
      return state;
  }
}

export const getIntegrations = (state: State) => state.integrationSummaryList;
export const isLoading = (state: State) => state.loading;
export const isLoaded = (state: State) => state.loaded;
export const error = (state: State) => state.error;
