import {
  IntegrationApiActions,
  IntegrationActions
} from '../actions';
import { Integration } from 'src/app/core/models/integration.model';
import { IntegrationStatus } from 'src/app/shared/constants';

export interface State {
  integration: Integration;
  loading: boolean;
  loaded: boolean;
  status: IntegrationStatus;
  error: string;
  success: string;
}

export const initialState: State = {
  integration: null,
  loading: false,
  loaded: false,
  status: IntegrationStatus.None,
  error: null,
  success: null
};

export function reducer(state = initialState, action: IntegrationActions.IntegrationActionsUnion | IntegrationApiActions.IntegrationApiActionsUnion): State {
  switch (action.type) {

    case IntegrationActions.IntegrationActionTypes.LoadIntegration: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case IntegrationApiActions.IntegrationApiActionTypes.LoadSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        integration: action.payload
      };
    }

    case IntegrationApiActions.IntegrationApiActionTypes.LoadFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        integration: null,
        error: action.payload
      };
    }

    case IntegrationActions.IntegrationActionTypes.ClearMessages: {
      return {
        ...state,
        status: IntegrationStatus.None,
        error: null,
        success: null
      };
    }

    case IntegrationActions.IntegrationActionTypes.UpdateIntegration: {
      return {
        ...state,
        loading: true,
        loaded: false,
        status: IntegrationStatus.None
      };
    }

    case IntegrationApiActions.IntegrationApiActionTypes.UpdateSuccess: {
      return {
        ...state,
        status: IntegrationStatus.UpdateSuccess,
        error: null,
        success: `Successfully Updated Integration: ${action.payload.name}`
      };
    }

    case IntegrationApiActions.IntegrationApiActionTypes.UpdateFailure: {
      return {
        ...state,
        loading: false,
        loaded: true,
        status: IntegrationStatus.UpdateFailure,
        error: 'Failed to Update Integration',
        success: null
      };
    }

    case IntegrationActions.IntegrationActionTypes.RenameIntegration: {
      return {
        ...state
      };
    }

    case IntegrationApiActions.IntegrationApiActionTypes.RenameSuccess: {
      const integration = { ...state.integration };
      integration.name = action.payload.integrationName;

      return {
        ...state,
        status: IntegrationStatus.RenameSuccess,
        error: null,
        success: `Successfully renamed Integration: ${action.payload.integrationName}`
      };
    }

    case IntegrationApiActions.IntegrationApiActionTypes.RenameFailure: {
      return {
        ...state,
        integration: null,
        status: IntegrationStatus.RenameFailure,
        error: 'Failed to rename Integration',
        success: null
      };
    }

    default:
      return state;
  }
}

export const getIntegration = (state: State) => state.integration;
export const getErrorMessage = (state: State) => state.error;
export const getSuccessMessage = (state: State) => state.success;
export const getStatus = (state: State) => state.status;
export const isLoading = (state: State) => state.loading;
export const isLoaded = (state: State) => state.loaded;
