import * as _ from 'lodash';
import { IBaseAuditModel, BaseAuditModel } from './base-audit.model';

export interface IEndpointSummary extends IBaseAuditModel {
  name: string;
  integrationId: number;
  globalProductOfferingsCount: number;
  localeName: string;
  languageName: string;
}

export class EndpointSummary extends BaseAuditModel {
  name: string;
  integrationId: number;
  globalProductOfferingsCount: number;
  localeName: string;
  languageName: string;

  constructor(data?: IEndpointSummary) {
    if (!data) {
      return;
    }

    super(data);
    _.assign(this, data);
  }
}
