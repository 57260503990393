import { GlobalProductOfferingConfiguration } from 'src/app/core/models/global-product-offering-configuration.model';
import { CurrencyIds } from './../../constants';
import { GlobalConfigurationsCalculationService } from './../../../core/services/global-configurations-calculation.service';
import { GlobalConfiguration } from 'src/app/core/models/global-configuration.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-global-configuration-list-item',
  templateUrl: './global-configuration-list-item.component.html',
  styleUrls: ['./global-configuration-list-item.component.scss']
})
export class GlobalConfigurationListItemComponent implements OnInit {

  // Required: Necessary for displaying configuration information.
  @Input() globalConfiguration: GlobalConfiguration;

  // Optional: Allows for product offering configuration customization.
  @Input() globalProductOfferingConfiguration: GlobalProductOfferingConfiguration;

  // Optional: Allows for overriding of configuration name value.  Used for things like translated names.
  @Input() nameTranslation: string;

  // If false, the list price will be hidden.
  @Input() showListPrice = true;

  constructor(
    private globalConfigurationsCalculationService: GlobalConfigurationsCalculationService) { }

  ngOnInit(): void {
    // If the globalConfiguration on the globalProductOfferingConfiguration input is provided, and the globalConfiguration input is not, use the
    // globalConfiguration on the globalProductOfferingConfiguration input.
    if (this.globalProductOfferingConfiguration?.globalConfiguration && !this.globalConfiguration) {
      this.globalConfiguration = this.globalProductOfferingConfiguration.globalConfiguration;
    }
  }

  public getBestName(): string {
    if (this.globalProductOfferingConfiguration?.displayName?.length > 0) {
      return this.globalProductOfferingConfiguration.displayName;
    }

    if (this.nameTranslation?.length > 0) {
      return this.nameTranslation;
    }

    return this.globalConfiguration?.name;
  }

  public getListPrice(globalConfiguration: GlobalConfiguration): number {
    // TODO: Update to allow the selected currency when localization is implemented.
    return this.globalConfigurationsCalculationService.getListPrice(globalConfiguration, CurrencyIds.USD);
  }

  public getIsConfigurationContainsInvalidProductNumber(globalConfiguration: GlobalConfiguration): boolean {
    // TODO: Update to allow the selected currency when localization is implemented.
    return this.globalConfigurationsCalculationService.getIsConfigurationContainsInvalidProductNumber(globalConfiguration, CurrencyIds.USD);
  }
}
