import { GlobalProduct } from 'src/app/core/models/global-product.model';
import { Router } from '@angular/router';
import { map, debounceTime, distinctUntilChanged, tap, catchError, finalize } from 'rxjs/operators';
import { BehaviorSubject, of, Observable, fromEvent } from 'rxjs';
import { GlobalProductSummary } from 'src/app/core/models/global-product-summary.model';
import { GlobalProductsService } from 'src/app/core/services/global-products.service';
import { SubSink } from 'subsink';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-sidebar-product-search',
  templateUrl: './sidebar-product-search.component.html',
  styleUrls: ['./sidebar-product-search.component.scss']
})
export class SidebarProductSearchComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() globalProductId: number;
  @Output() onGlobalProductLoaded: EventEmitter<GlobalProduct> = new EventEmitter();
  @ViewChild('input') input: ElementRef;

  public globalProduct$: Observable<GlobalProduct>;
  public globalProduct: GlobalProduct;
  public globalProductLoading: boolean;
  public globalProductLoaded: boolean;
  thumbnailFrontImage: string;

  public search$ = new BehaviorSubject<Array<GlobalProductSummary>>([]);
  public searchLoading$ = new BehaviorSubject<boolean>(false);

  private subscriptions: SubSink = new SubSink();

  constructor(
    private router: Router,
    private globalProductsService: GlobalProductsService) { }

  ngOnInit(): void {
    this.subscribeToGlobalProduct();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    this.subscribeToSearchKeyPress();
  }

  public focusOnSearch(): void {
    this.input.nativeElement.focus();
    this.input.nativeElement.select();
  }

  public navigateToProductDetail(productId: number): void {
    this.router.navigate([`products/product-detail/${productId}`]);
  }

  private search(searchTerm: string): void {
    if (searchTerm === null || searchTerm.length === 0) {
      this.search$.next([]); // Clear results if no search term is provided.
      return;
    }

    this.searchLoading$.next(true);
    this.globalProductsService.getGlobalProductsByName$(searchTerm).pipe(
      catchError(() => of([])),
      finalize(() => this.searchLoading$.next(false))
    ).subscribe((response: Array<GlobalProductSummary>) => {
      this.search$.next(response);
    });
  }

  private subscribeToSearchKeyPress(): void {
    const keypress$ = fromEvent(this.input.nativeElement, 'keyup').pipe(
      map(x => this.input.nativeElement.value),
      debounceTime(500),
      // Prevents keys like 'enter' and arrow keys from re-running the query.
      distinctUntilChanged((x, y) => x === y),
      tap(() => {
        this.search(this.input.nativeElement.value);
      })
    ).subscribe();

    this.subscriptions.add(keypress$);
  }

  private subscribeToGlobalProduct(): void {
    this.globalProductLoading = true;
    this.globalProductLoaded = false;
    const globalProduct$ = this.globalProductsService.getGlobalProduct$(this.globalProductId)
    .pipe(
      tap((globalProduct: GlobalProduct) => {
        if (globalProduct) {
          this.globalProductLoaded = true;
          this.thumbnailFrontImage = globalProduct.globalProductMediaLinks?.find(x => x.mediaTypeId == 1)?.mediaLink;
        }
      }),
      finalize(() => {
        this.globalProductLoading = false;
      })
    )
    .subscribe((globalProduct: GlobalProduct) => {
      this.globalProduct = globalProduct;
      this.onGlobalProductLoaded.emit(this.globalProduct);
    });

    this.subscriptions.add(globalProduct$);
  }
}
