import { Role } from 'src/app/core/models/role.model';
import {
  RolesApiActions,
  RolesActions
} from '../actions';

export interface State {
  roles: Role[];
  loading: boolean;
  loaded: boolean;
  error: string;
}

export const initialState: State = {
  roles: [],
  loading: false,
  loaded: false,
  error: ''
};

export function reducer(state = initialState, action: RolesActions.RolesActionsUnion | RolesApiActions.RolesApiActionsUnion): State {
  switch (action.type) {

    case RolesActions.RolesActionTypes.LoadRoles: {
      return {
        ...state,
        loading: true
      };
    }

    case RolesApiActions.RolesApiActionTypes.LoadSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        roles: action.payload
      };
    }

    case RolesApiActions.RolesApiActionTypes.LoadFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        roles: [],
        error: action.payload
      };
    }

    default:
      return state;
  }
}

export const getRoles = (state: State) => state.roles;
export const isLoading = (state: State) => state.loading;
export const isLoaded = (state: State) => state.loaded;
