<div fxFlex>
  <mat-card class="app-card">
    <mat-card-content>

      <ng-container *ngFor="let row of getRows()">
        <mat-accordion>
          <mat-expansion-panel disabled class="dropdown-checkbox">

            <mat-expansion-panel-header>
              <div fxFlex="90%" class="lines align-center-vertical"></div>
            </mat-expansion-panel-header>

          </mat-expansion-panel>
        </mat-accordion>
      </ng-container>

    </mat-card-content>
  </mat-card>
</div>
