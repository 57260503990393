import { Warranty } from 'src/app/core/models/warranty.model';
import { WarrantyStatus } from 'src/app/shared/constants';

import {
  WarrantyApiActions,
  WarrantyActions
} from '../actions';


export interface State {
  warranty: Warranty;
  loading: boolean;
  loaded: boolean;
  status: WarrantyStatus;
  error: string;
  success: string;
}

export const initialState: State = {
  warranty: null,
  loading: false,
  loaded: false,
  status: WarrantyStatus.None,
  error: null,
  success: null
};

export function reducer(state = initialState, action: WarrantyActions.WarrantyActionsUnion | WarrantyApiActions.WarrantyApiActionsUnion): State {
  switch (action.type) {

    case WarrantyActions.WarrantyActionTypes.LoadWarranty: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case WarrantyApiActions.WarrantyApiActionTypes.LoadSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        warranty: action.payload
      };
    }

    case WarrantyApiActions.WarrantyApiActionTypes.LoadFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        warranty: null,
        error: action.payload
      };
    }

    case WarrantyActions.WarrantyActionTypes.ClearMessages: {
      return {
        ...state,
        status: WarrantyStatus.None,
        error: null,
        success: null
      };
    }

    case WarrantyActions.WarrantyActionTypes.CreateWarranty: {
      return {
        ...state,
      };
    }

    case WarrantyApiActions.WarrantyApiActionTypes.CreateSuccess: {
      return {
        ...state,
        warranty: action.payload,
        status: WarrantyStatus.CreateSuccess,
        error: null,
        success: `Successfully Created Warranty: ${action.payload.name}`
      };
    }

    case WarrantyApiActions.WarrantyApiActionTypes.CreateFailure: {
      return {
        ...state,
        warranty: null,
        status: WarrantyStatus.CreateFailure,
        error: 'Failed to Create Warranty',
        success: null
      };
    }

    case WarrantyActions.WarrantyActionTypes.UpdateWarranty: {
      return {
        ...state,
        loading: true,
        loaded: false,
        status: WarrantyStatus.None
      };
    }

    case WarrantyApiActions.WarrantyApiActionTypes.UpdateSuccess: {
      return {
        ...state,
        status: WarrantyStatus.UpdateSuccess,
        error: null,
        success: `Successfully Updated Warranty: ${action.payload.warrantyName}`
      };
    }

    case WarrantyApiActions.WarrantyApiActionTypes.UpdateFailure: {
      return {
        ...state,
        loading: false,
        loaded: true,
        status: WarrantyStatus.UpdateFailure,
        error: 'Failed to Update Warranty',
        success: null
      };
    }

    case WarrantyActions.WarrantyActionTypes.RenameWarranty: {
      return {
        ...state
      };
    }

    case WarrantyApiActions.WarrantyApiActionTypes.RenameSuccess: {
      const warranty = { ...state.warranty };
      warranty.name = action.payload.warrantyName;

      return {
        ...state,
        status: WarrantyStatus.RenameSuccess,
        error: null,
        success: `Successfully renamed Warranty: ${action.payload.warrantyName}`
      };
    }

    case WarrantyApiActions.WarrantyApiActionTypes.RenameFailure: {
      return {
        ...state,
        warranty: null,
        status: WarrantyStatus.RenameFailure,
        error: 'Failed to rename Warranty',
        success: null
      };
    }

    case WarrantyActions.WarrantyActionTypes.DeleteWarranty: {
      return {
        ...state,
      };
    }

    case WarrantyApiActions.WarrantyApiActionTypes.DeleteSuccess: {
      return {
        ...state,
        status: WarrantyStatus.DeleteSuccess,
        error: null,
        success: `Successfully Deleted Warranty: ${action.payload.warrantyName}`
      };
    }

    case WarrantyApiActions.WarrantyApiActionTypes.DeleteFailure: {
      return {
        ...state,
        warranty: null,
        status: WarrantyStatus.DeleteFailure,
        error: 'Failed to Delete Warranty',
        success: null
      };
    }

    default:
      return state;
   }
}

export const getWarranty = (state: State) => state.warranty;
export const getErrorMessage = (state: State) => state.error;
export const getSuccessMessage = (state: State) => state.success;
export const getStatus = (state: State) => state.status;
export const isLoading = (state: State) => state.loading;
export const isLoaded = (state: State) => state.loaded;
