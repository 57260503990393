import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import {
  RolesApiActions,
  RolesActions
} from '../actions';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { switchMap, map, catchError } from 'rxjs/operators';
import { UserRolesService } from 'src/app/core/services/user-roles.service';

@Injectable()
export class RolesEffects {
  @Effect()
  loadRoles$: Observable<Action> = this.actions$.pipe(
    ofType<RolesActions.LoadRoles>(RolesActions.RolesActionTypes.LoadRoles),
    switchMap(() =>
      this.rolesService.getRoles$().pipe(
        map((roles) => new RolesApiActions.LoadSuccess(roles)
        ),
        catchError(error =>
          of(new RolesApiActions.LoadFailure(error))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private rolesService: UserRolesService) { }

}
