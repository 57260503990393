import { Category, ICategory } from './../models/category.model';
import { IEndpointSummary } from './../models/endpoint-summary.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { EndpointsSummary, IEndpointsSummary } from '../models/endpoints-summary.model';
import { Endpoint, IEndpoint } from '../models/endpoint.model';
import { EndpointSummary } from '../models/endpoint-summary.model';
import { IGlobalProductOfferingSummary } from '../models/global-product-offering-summary.model';
import { PagedResults } from '../models/page-results.model';
import { IRemaps } from '../models/remaps.model';
import { EndpointProductOfferingQuery } from '../models/endpoint-product-offering-query.model';
import { RenamePut } from '../models/rename-put.model';

@Injectable({
  providedIn: 'root'
})
export class EndpointsService {
  basePath = 'endpoints';

  constructor(private http: HttpClient) { }

  getEndpoint$(endpointId: number): Observable<Endpoint> {
    return this.http.get(environment.apiUrl + `${this.basePath}/${endpointId}`)
      .pipe(
        map(res => res),
        catchError((err: Response) => {
          const obj = err;
          return throwError(obj);
        }),
        map((endpoint: IEndpoint) => new Endpoint(endpoint)),
      );
  }

  getEndpointsSummaries$(): Observable<Array<EndpointsSummary>> {
    return this.http.get(environment.apiUrl + `${this.basePath}`)
      .pipe(
        map(res => res),
        catchError((err: Response) => {
          const obj = err;
          return throwError(obj);
        }),
        map((list: IEndpointsSummary[]) => list.map(m => new EndpointsSummary(m))),
      );
  }

  getEndpointAvailableProductOfferings$(
    query: EndpointProductOfferingQuery): Observable<PagedResults<IGlobalProductOfferingSummary>> {

    if (query.sortOrder === '') {
      query.orderBy = 'name';
      query.sortOrder = 'asc';
    }

    return this.http.post(`${environment.apiUrl}${this.basePath}/availableproductofferings`, query).pipe(
      map((response: PagedResults<IGlobalProductOfferingSummary>) => response)
    );
  }

  getCategories$(endpointId: number): Observable<Category[]> {
    return this.http.get(`${environment.apiUrl}${this.basePath}/${endpointId}/categories`).pipe(
      map((response: ICategory[]) => response)
    );
  }

  getRemaps$(endpointId: number): Observable<IRemaps> {
    return this.http.get(`${environment.apiUrl}${this.basePath}/${endpointId}/remaps`).pipe(
      map((response: IRemaps) => response)
    );
  }

  updateRemaps$(request: { endpointId: number, remaps: IRemaps }): Observable<any> {
    return this.http.put(
      environment.apiUrl + `${this.basePath}/${request.endpointId}/remaps`,
      request.remaps,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
  }

  createEndpoint$(endpoint: Endpoint): Observable<Endpoint> {
    return this.http.post(environment.apiUrl + `${this.basePath}`, endpoint)
      .pipe(
        map(res => res),
        catchError((err: Response) => {
          const obj = err;
          return throwError(obj);
        }),
        map((createdEndpoint: IEndpoint) => new Endpoint(createdEndpoint))
      );
  }

  renameEndpoint$(request: { endpointId: number, endpointName: string }): Observable<any> {
    const renamePut: RenamePut = new RenamePut({ name: request.endpointName });
    return this.http.put(
      environment.apiUrl + `${this.basePath}/${request.endpointId}/rename`,
      renamePut,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
    );
  }

  duplicateEndpoint$(request: { endpointId: number, endpointName: string }): Observable<EndpointSummary> {
    return this.http.post(
      environment.apiUrl + `${this.basePath}/${request.endpointId}/duplicate`,
      `\"${request.endpointName}\"`, // this notation is required for sending raw strings in the body.
      { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
      .pipe(
        map(res => res),
        catchError((err: Response) => {
          const obj = err;
          return throwError(obj);
        }),
        map((createdEndpoint: IEndpointSummary) => new EndpointSummary(createdEndpoint))
      );
  }

  deleteEndpoint$(request: { endpointId: number }): Observable<any> {
    return this.http.delete(
      environment.apiUrl + `${this.basePath}/${request.endpointId}`,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
  }
}
