import { RegularExpressions } from './../../constants';
import { tap } from 'rxjs/operators';
import { CustomValidatorsService } from './../../../core/services/custom-validators.service';
import { FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-file-url-modal',
  templateUrl: './file-url-modal.component.html',
  styleUrls: ['./file-url-modal.component.scss']
})
export class FileUrlModalComponent implements OnInit, OnDestroy {
  subSink = new SubSink();
  result: FileUrlModalResult = new FileUrlModalResult();

  modalForm: FormGroup = new FormGroup({
    url: new FormControl('')
  });

  constructor(
    public dialogRef: MatDialogRef<FileUrlModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FileUrlModalInput,
    private customValidatorsService: CustomValidatorsService) { }

  ngOnInit() {
    this.result.url = this.data.url;

    if (!this.data) {
      return;
    }

    // Sets extension validators.
    const urlValidators: Array<ValidatorFn> = [Validators.required, Validators.pattern(RegularExpressions.VALID_URL)];
    if (this.data.validExtensions.length > 0) {
      urlValidators.push(Validators.pattern(this.customValidatorsService.getFileExtensionRegEx(this.data.validExtensions)));
    }

    this.modalForm.controls.url.setAsyncValidators(this.customValidatorsService.validateFileUrlExists);
    this.modalForm.controls.url.setValidators(urlValidators);
    this.modalForm.controls.url.setValue(this.data.url);
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.modalForm.controls[controlName].hasError(errorName);
  }

  public getValidExtensions(): string {
    return this.data.validExtensions.toString();
  }

  public submitForm(): void {
    this.result.url = this.modalForm.value.url;
    this.result.isValid = true;

    this.dialogRef.close(this.result);
  }

}

export class FileUrlModalInput {
  url: string = null;
  validExtensions: Array<string> = [];
  modalTitle = 'Set File';
  modalLabel = 'File';
  modalInputPlaceholder = 'Enter a URL';
  modalActionButtonText = 'Add';
}

export class FileUrlModalResult {
  url: string;
  isValid = false;
}
