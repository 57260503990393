import { Template } from '../../../core/models/template';
import { Action } from '@ngrx/store';

export enum TemplatesActionTypes {
  LoadTemplates = '[Templates] Load Templates',
  CreateTemplate = '[Templates] Create Template',
  RenameTemplate = '[Templates] Rename Template',
  DuplicateTemplate = '[Templates] Duplicate Template',
  ClearMessages = '[Application] Clear Messages',
}

export class LoadTemplates implements Action {
  readonly type = TemplatesActionTypes.LoadTemplates;
}

export class CreateTemplate implements Action {
  readonly type = TemplatesActionTypes.CreateTemplate;

  constructor(public payload: Template) { }
}

export class RenameTemplate implements Action {
  readonly type = TemplatesActionTypes.RenameTemplate;

  constructor(public payload: {integrationId: number, templateId: number, templateName: string}) { }
}

export class DuplicateTemplate implements Action {
  readonly type = TemplatesActionTypes.DuplicateTemplate;

  constructor(public payload: {templateId: number, templateName: string}) { }
}

export class ClearMessages implements Action {
  readonly type = TemplatesActionTypes.ClearMessages;

  constructor() { }
}

export type TemplatesActionsUnion =
    LoadTemplates
  | CreateTemplate
  | RenameTemplate
  | DuplicateTemplate
  | ClearMessages
  ;
