import { Action } from '@ngrx/store';
import { Category } from 'src/app/core/models/category.model';
import { CategoryPut } from 'src/app/core/models/category-put.model';

export enum CategoryApiActionTypes {
  LoadSuccess = '[Category/API] Load Success',
  LoadFailure = '[Category/API] Load Failure',
  CreateSuccess = '[Category/API] Create Success',
  CreateFailure = '[Category/API] Create Failure',
  UpdateSuccess = '[Category/API] Update Success',
  UpdateFailure = '[Category/API] Update Failure',
  RenameSuccess = '[Category/API] Rename Success',
  RenameFailure = '[Category/API] Rename Failure',
  DeleteSuccess = '[Category/API] Delete Success',
  DeleteFailure = '[Category/API] Delete Failure'
}

export class LoadSuccess implements Action {
  readonly type = CategoryApiActionTypes.LoadSuccess;

  constructor(public payload: Category) { }
}

export class LoadFailure implements Action {
  readonly type = CategoryApiActionTypes.LoadFailure;

  constructor(public payload: any) { }
}

export class CreateSuccess implements Action {
  readonly type = CategoryApiActionTypes.CreateSuccess;

  constructor(public payload: Category) { }
}

export class CreateFailure implements Action {
  readonly type = CategoryApiActionTypes.CreateFailure;

  constructor(public payload: any) { }
}


export class UpdateSuccess implements Action {
  readonly type = CategoryApiActionTypes.UpdateSuccess;

  constructor(public payload: { category: CategoryPut, categoryName: string }) { }
}

export class UpdateFailure implements Action {
  readonly type = CategoryApiActionTypes.UpdateFailure;

  constructor(public payload: any) { }
}

export class RenameSuccess implements Action {
  readonly type = CategoryApiActionTypes.RenameSuccess;

  constructor(public payload: { categoryId: number, categoryName: string }) { }
}

export class RenameFailure implements Action {
  readonly type = CategoryApiActionTypes.RenameFailure;

  constructor(public payload: any) { }
}

export class DeleteSuccess implements Action {
  readonly type = CategoryApiActionTypes.DeleteSuccess;

  constructor(public payload: { categoryId: number, categoryName: string }) { }
}

export class DeleteFailure implements Action {
  readonly type = CategoryApiActionTypes.DeleteFailure;

  constructor(public payload: any) { }
}

export type CategoryApiActionsUnion =
  LoadSuccess
  | LoadFailure
  | CreateSuccess
  | CreateFailure
  | UpdateSuccess
  | UpdateFailure
  | RenameSuccess
  | RenameFailure
  | DeleteSuccess
  | DeleteFailure;
