import { ConfirmationDialogComponent, ButtonStyle } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { EditSnackbarService, EditSnackbarResult } from 'src/app/core/services/edit-snackbar.service';

@Component({
  selector: 'app-edit-snackbar',
  templateUrl: './edit-snackbar.component.html',
  styleUrls: ['./edit-snackbar.component.scss']
})
export class EditSnackBarComponent implements OnInit {

  get disableSave(): boolean {
    if (this.data === null || this.data.form === undefined) {
      return false;
    }
    return !this.data.form.valid;
  }

  constructor(
    private editSnackbarService: EditSnackbarService,
    private matSnackBarRef: MatSnackBarRef<EditSnackBarComponent>,
    private matDialog: MatDialog,
    @Inject(MAT_SNACK_BAR_DATA) public data: { name: string, form?: FormGroup }) {
    this.editSnackbarService.result = EditSnackbarResult.Default;
  }

  ngOnInit() {
  }

  discardChanges() {
    this.showDiscardChangesModel();
  }

  saveChanges() {
    this.editSnackbarService.result = EditSnackbarResult.Save;
    this.matSnackBarRef.dismissWithAction();
  }

  showDiscardChangesModel() {
    const discardDialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Discard Changes',
        message: `Are you sure you want to discard changes?`,
        buttonText: 'Discard',
        buttonStyle: ButtonStyle.Danger
      },
      restoreFocus: false
    });
​
    discardDialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.editSnackbarService.result = EditSnackbarResult.Discard;
        this.matSnackBarRef.dismiss();
      }
    });
  }
}
