import {
  DesignerApiActions,
  DesignerActions
} from '../actions';

import { Designer } from 'src/app/core/models/designer.model';
import { DesignerStatus } from 'src/app/shared/constants';

export interface State {
  designer: Designer;
  loading: boolean;
  loaded: boolean;
  status: DesignerStatus;
  error: string;
  success: string;
}

export const initialState: State = {
  designer: null,
  loading: false,
  loaded: false,
  status: DesignerStatus.None,
  error: null,
  success: null
};

export function reducer(state = initialState, action: DesignerActions.DesignerActionsUnion | DesignerApiActions.DesignerApiActionsUnion): State {
  switch (action.type) {

    case DesignerActions.DesignerActionTypes.LoadDesigner: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case DesignerApiActions.DesignerApiActionTypes.LoadSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        designer: action.payload
      };
    }

    case DesignerApiActions.DesignerApiActionTypes.LoadFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        designer: null,
        error: action.payload
      };
    }

    case DesignerActions.DesignerActionTypes.ClearMessages: {
      return {
        ...state,
        status: DesignerStatus.None,
        error: null,
        success: null
      };
    }

    case DesignerActions.DesignerActionTypes.CreateDesigner: {
      return {
        ...state,
      };
    }

    case DesignerApiActions.DesignerApiActionTypes.CreateSuccess: {
      return {
        ...state,
        designer: action.payload,
        status: DesignerStatus.CreateSuccess,
      };
    }

    case DesignerApiActions.DesignerApiActionTypes.CreateFailure: {
      return {
        ...state,
        designer: null,
        status: DesignerStatus.CreateFailure,
        error: 'Failed to Create Designer',
        success: null
      };
    }

    case DesignerActions.DesignerActionTypes.UpdateDesigner: {
      return {
        ...state,
        loading: true,
        loaded: false,
        status: DesignerStatus.None
      };
    }

    case DesignerApiActions.DesignerApiActionTypes.UpdateSuccess: {
      return {
        ...state,
        status: DesignerStatus.UpdateSuccess,
        error: null,
        success: `Successfully Updated Designer: ${action.payload.designerName}`
      };
    }

    case DesignerApiActions.DesignerApiActionTypes.UpdateFailure: {
      return {
        ...state,
        loading: false,
        loaded: true,
        status: DesignerStatus.UpdateFailure,
        error: 'Failed to Update Designer',
        success: null
      };
    }

    case DesignerActions.DesignerActionTypes.RenameDesigner: {
      return {
        ...state
      };
    }

    case DesignerApiActions.DesignerApiActionTypes.RenameSuccess: {
      const designer = { ...state.designer };
      designer.name = action.payload.designerName;

      return {
        ...state,
        status: DesignerStatus.RenameSuccess,
        error: null,
        success: `Successfully renamed Designer: ${action.payload.designerName}`
      };
    }

    case DesignerApiActions.DesignerApiActionTypes.RenameFailure: {
      return {
        ...state,
        designer: null,
        status: DesignerStatus.RenameFailure,
        error: 'Failed to rename Designer',
        success: null
      };
    }

    case DesignerActions.DesignerActionTypes.DeleteDesigner: {
      return {
        ...state,
      };
    }

    case DesignerApiActions.DesignerApiActionTypes.DeleteSuccess: {
      return {
        ...state,
        status: DesignerStatus.DeleteSuccess,
        error: null,
        success: `Successfully Deleted Designer: ${action.payload.designerName}`
      };
    }

    case DesignerApiActions.DesignerApiActionTypes.DeleteFailure: {
      return {
        ...state,
        designer: null,
        status: DesignerStatus.DeleteFailure,
        error: 'Failed to Delete Designer',
        success: null
      };
    }

    default:
      return state;
  }
}

export const getDesigner = (state: State) => state.designer;
export const getErrorMessage = (state: State) => state.error;
export const getSuccessMessage = (state: State) => state.success;
export const getStatus = (state: State) => state.status;
export const isLoading = (state: State) => state.loading;
export const isLoaded = (state: State) => state.loaded;
