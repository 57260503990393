import { ProductGuidanceAttribute } from '../../../core/models/product-guidance-attribute.model';

import {
  ProductGuidanceAttributesActions,
  ProductGuidanceAttributesApiActions
} from '../actions';

export interface State {
  productGuidanceAttributesLoading: boolean;
  productGuidanceAttributesLoaded: boolean;
  productGuidanceAttributes: Array<ProductGuidanceAttribute>;
  error: string;
}

export const initialState: State = {
  productGuidanceAttributesLoading: false,
  productGuidanceAttributesLoaded: false,
  productGuidanceAttributes: [],
  error: ''
};

export function reducer(state = initialState, action: ProductGuidanceAttributesActions.ProductGuidanceAttributesActionsUnion | ProductGuidanceAttributesApiActions.ProductGuidanceAttributesApiActionsUnion): State {
  switch (action.type) {

    case ProductGuidanceAttributesActions.ProductGuidanceAttributesActionTypes.LoadProductGuidanceAttributes: {
      return {
        ...state,
        productGuidanceAttributesLoading: true,
        productGuidanceAttributesLoaded: false,
        error: null
      };
    }

    case ProductGuidanceAttributesApiActions.ProductGuidanceAttributesApiActionTypes.LoadSuccess: {
      return {
        ...state,
        productGuidanceAttributes: action.payload,
        productGuidanceAttributesLoading: false,
        productGuidanceAttributesLoaded: true
      };
    }

    case ProductGuidanceAttributesApiActions.ProductGuidanceAttributesApiActionTypes.LoadFailure: {
      return {
        ...state,
        productGuidanceAttributes: null,
        productGuidanceAttributesLoading: false,
        productGuidanceAttributesLoaded: false,
        error: action.payload
      };
    }

    default:
      return state;
  }
}

export const getProductGuidanceAttributesIsLoading = (state: State) => state.productGuidanceAttributesLoading;
export const getProductGuidanceAttributesIsLoaded = (state: State) => state.productGuidanceAttributesLoaded;
export const getProductGuidanceAttributes = (state: State) => state.productGuidanceAttributes;
export const getErrorMessage = (state: State) => state.error;
