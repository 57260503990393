import { GlobalProductOfferingStatus } from './../../../shared/constants';
import { IntegrationProductOfferingSummary } from './../../../core/models/integration-product-offering-summary.model';
import { GlobalProductOffering } from 'src/app/core/models/global-product-offering.model';
import {
  ProductOfferingApiActions,
  ProductOfferingActions
} from '../actions';

export interface State {
  summariesLoading: boolean;
  summariesLoaded: boolean;
  summaries: IntegrationProductOfferingSummary[];

  productOfferingLoading: boolean;
  productOfferingLoaded: boolean;
  productOffering: GlobalProductOffering;

  status: GlobalProductOfferingStatus;
  error: string;
  success: string;
}

export const initialState: State = {
  summariesLoading: false,
  summariesLoaded: false,
  summaries: [],

  productOfferingLoading: false,
  productOfferingLoaded: false,
  productOffering: null,

  status: GlobalProductOfferingStatus.None,
  error: null,
  success: null,
};

export function reducer(state = initialState, action: ProductOfferingActions.ProductOfferingActionsUnion | ProductOfferingApiActions.ProductOfferingApiActionsUnion): State {
  switch (action.type) {

    case ProductOfferingActions.ProductOfferingActionTypes.LoadProductOffering: {
      return {
        ...state,
        productOfferingLoading: true,
        productOfferingLoaded: false,
      };
    }

    case ProductOfferingApiActions.ProductOfferingApiActionTypes.LoadSuccess: {
      return {
        ...state,
        productOffering: action.payload,
        productOfferingLoading: false,
        productOfferingLoaded: true,
      };
    }

    case ProductOfferingApiActions.ProductOfferingApiActionTypes.LoadFailure: {
      return {
        ...state,
        productOffering: null,
        productOfferingLoading: false,
        productOfferingLoaded: false,
        error: action.payload,
      };
    }

    case ProductOfferingActions.ProductOfferingActionTypes.LoadProductOfferingByProductId: {
      return {
        ...state,
        summaries: [],
        summariesLoading: true,
        summariesLoaded: false,
      };
    }

    case ProductOfferingApiActions.ProductOfferingApiActionTypes.LoadForProductOfferingSuccess: {
      return {
        ...state,
        summaries: action.payload,
        summariesLoading: false,
        summariesLoaded: true,
      };
    }

    case ProductOfferingApiActions.ProductOfferingApiActionTypes.LoadForProductOfferingFailure: {
      return {
        ...state,
        summaries: [],
        summariesLoading: false,
        summariesLoaded: false,
        error: action.payload
      };
    }

    case ProductOfferingActions.ProductOfferingActionTypes.CreateProductOffering: {
      return {
        ...state,
      };
    }

    case ProductOfferingApiActions.ProductOfferingApiActionTypes.CreateSuccess: {
      return {
        ...state,
        productOffering: action.payload,
        summariesLoading: false,
        summariesLoaded: true,
        status: GlobalProductOfferingStatus.CreateSuccess
      };
    }

    case ProductOfferingApiActions.ProductOfferingApiActionTypes.CreateFailure: {
      return {
        ...state,
        productOffering: null,
        summariesLoading: false,
        summariesLoaded: false,
        error: action.payload,
        success: null,
        status: GlobalProductOfferingStatus.CreateFailure,
      };
    }

    case ProductOfferingActions.ProductOfferingActionTypes.UpdateProductOffering: {
      return {
        ...state,
      };
    }

    case ProductOfferingApiActions.ProductOfferingApiActionTypes.UpdateSuccess: {
      return {
        ...state,
        error: null,
        success: `${state.productOffering.name} product offering has been saved successfully`,
        status: GlobalProductOfferingStatus.UpdateSuccess,
      };
    }

    case ProductOfferingApiActions.ProductOfferingApiActionTypes.UpdateFailure: {
      return {
        ...state,
        error: `Failed to update product offering ${state.productOffering.name}.`,
        success: null,
        status: GlobalProductOfferingStatus.UpdateFailure,
      };
    }

    case ProductOfferingActions.ProductOfferingActionTypes.DeleteProductOffering: {
      return {
        ...state,
      };
    }

    case ProductOfferingApiActions.ProductOfferingApiActionTypes.DeleteSuccess: {
      return {
        ...state,
        productOffering: action.payload,
        error: null,
        success: `Successfully Deleted Product Offering: ${state.productOffering.name}.`,
        status: GlobalProductOfferingStatus.DeleteSuccess,
      };
    }

    case ProductOfferingApiActions.ProductOfferingApiActionTypes.DeleteFailure: {
      return {
        ...state,
        productOffering: action.payload,
        error: 'Failed to Delete Product Offering.',
        success: null,
        status: GlobalProductOfferingStatus.DeleteFailure,
      };
    }

    case ProductOfferingActions.ProductOfferingActionTypes.RenameProductOffering: {
      return {
        ...state,
      };
    }

    case ProductOfferingApiActions.ProductOfferingApiActionTypes.RenameSuccess: {
      return {
        ...state,
        productOfferingLoading: false,
        productOfferingLoaded: true,
        error: null,
        success: `Product offering successfully renamed to ${action.payload.offeringName}.`,
        status: GlobalProductOfferingStatus.RenameSuccess,
      };
    }

    case ProductOfferingApiActions.ProductOfferingApiActionTypes.RenameFailure: {
      return {
        ...state,
        productOfferingLoading: false,
        productOfferingLoaded: false,
        error: `Failed to rename product offering ${state.productOffering.name}.`,
        success: null,
        status: GlobalProductOfferingStatus.RenameFailure,
      };
    }

    case ProductOfferingActions.ProductOfferingActionTypes.ClearMessages: {
      return {
        ...state,
        error: null,
        success: null,
        status: GlobalProductOfferingStatus.None,
      };
    }

    case ProductOfferingActions.ProductOfferingActionTypes.ClearProductOffering: {
      return {
        ...state,
        productOffering: null,
        productOfferingLoading: false,
        productOfferingLoaded: false
      };
    }

    case ProductOfferingActions.ProductOfferingActionTypes.ClearProductOfferingSummaries: {
      return {
        ...state,
        summaries: null,
        summariesLoading: false,
        summariesLoaded: false
      };
    }

    default:
      return state;
  }
}

export const getSummariesIsLoading = (state: State) => state.summariesLoading;
export const getSummariesIsLoaded = (state: State) => state.summariesLoaded;
export const getLoadedProductOfferings = (state: State) => state.summaries;

export const getSelectedIsLoading = (state: State) => state.productOfferingLoading;
export const getSelectedIsLoaded = (state: State) => state.productOfferingLoaded;
export const getSelectedProductOffering = (state: State) => state.productOffering;

export const getErrorMessage = (state: State) => state.error;
export const getSuccessMessage = (state: State) => state.success;
export const getStatus = (state: State) => state.status;
