import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import { assign as _assign } from 'lodash-es';

export interface IAttributeType extends IBaseAuditModel {
  name: string;
}

export class AttributeType extends BaseAuditModel implements IAttributeType {
  name: string;

  constructor(data: IAttributeType) {
    super(data);

    if (!data) {
      return;
    }

    _assign(this, data);
  }
}
