import { GlobalConfigurationTranslation } from './../models/global-configuration-translation.model';
import { GlobalProductTranslation } from './../models/global-product-translation.model';
import {
  GlobalTranslations,
  IGlobalTranslations,
  SubcategoryTranslation,
  BrandTranslation,
  SubBrandTranslation,
  CategoryTranslation,
  DesignerTranslation,
  PortfolioTranslation,
  FamilyTranslation,
  WarrantyTranslation,
} from './../models/global-translations.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TranslationsService {
  basePath = 'localization/translations';
  constructor(private http: HttpClient) {
  }

  getTranslations$(): Observable<GlobalTranslations> {
    return this.http
      .get(environment.apiUrl + `${this.basePath}`).pipe(
        map(res => res),
        catchError((error: Response) => {
          return observableThrowError(error);
        }),
        map((response: IGlobalTranslations) => new GlobalTranslations(response))
      );
  }

  translateProductOfferingName(
    value: string,
    globalProductId: number,
    languageId: number,
    translations: Array<GlobalProductTranslation>
  ): string {
    if (!value) { return null; }
    const translation = this.getGlobalProductTranslation(globalProductId, languageId, translations);
    return translation && translation.name ? translation.name : value;
  }

  translateBrandName(
    value: string,
    brandId: number,
    languageId: number,
    translations: Array<BrandTranslation>
  ): string {
    const translation = this.getBrandTranslation(brandId, languageId, translations);
    return translation && translation.name ? translation.name : value;
  }

  translateDesignerName(
    value: string,
    designerId: number,
    languageId: number,
    translations: Array<DesignerTranslation>
  ): string {
    const translation = this.getDesignerTranslation(designerId, languageId, translations);
    return translation && translation.name ? translation.name : value;
  }

  translateFamilyName(
    value: string,
    familyId: number,
    languageId: number,
    translations: Array<FamilyTranslation>
  ): string {
    const translation = this.getFamilyTranslation(familyId, languageId, translations);
    return translation && translation.name ? translation.name : value;
  }

  translatePortfolioName(
    value: string,
    portfolioId: number,
    languageId: number,
    translations: Array<PortfolioTranslation>
  ): string {
    const translation = this.getPortfolioTranslation(portfolioId, languageId, translations);
    return translation && translation.name ? translation.name : value;
  }

  translateCategoryName(
    value: string,
    categoryId: number,
    languageId: number,
    translations: Array<CategoryTranslation>
  ): string {
    const translation = this.getCategoryTranslation(categoryId, languageId, translations);
    return translation && translation.name ? translation.name : value;
  }

  translateSubcategoryName(
    value: string,
    subCategoryId: number,
    languageId: number,
    translations: Array<SubcategoryTranslation>): string {
    const translation = this.getSubcategoryTranslation(subCategoryId, languageId, translations);
    return translation && translation.name ? translation.name : value;
  }

  translatePageTitleKeyword(
    value: string,
    globalProductId: number,
    languageId: number,
    translations: Array<GlobalProductTranslation>
  ): string {
    if (!value) { return null; }
    const translation = this.getGlobalProductTranslation(globalProductId, languageId, translations);
    return translation && translation.pageTitleKeyword ? translation.pageTitleKeyword : value;
  }

  translateShortDescription(
    value: string,
    globalProductId: number,
    languageId: number,
    translations: Array<GlobalProductTranslation>
  ): string {
    if (!value) { return null; }
    const translation = this.getGlobalProductTranslation(globalProductId, languageId, translations);
    return translation && translation.shortDescription ? translation.shortDescription : value;
  }

  translateLongDescription(
    value: string,
    globalProductId: number,
    languageId: number,
    translations: Array<GlobalProductTranslation>
  ): string {
    if (!value) { return null; }
    const translation = this.getGlobalProductTranslation(globalProductId, languageId, translations);
    return translation && translation.longDescription ? translation.longDescription : value;
  }

  translateOverviewMetaDescription(
    value: string,
    globalProductId: number,
    languageId: number,
    translations: Array<GlobalProductTranslation>
  ): string {
    if (!value) { return null; }
    const translation = this.getGlobalProductTranslation(globalProductId, languageId, translations);
    return translation && translation.overviewMetaDescription ? translation.overviewMetaDescription : value;
  }

  translateWarrantyName(
    value: string,
    warrantyId: number,
    languageId: number,
    translations: Array<WarrantyTranslation>): string {
    const translation = this.getWarrantyTranslation(warrantyId, languageId, translations);
    return translation ? translation.name : value;
  }

  translateGlobalConfigurationName(
    value: string,
    globalConfigurationTranslationId: number,
    languageId: number,
    translations: Array<GlobalConfigurationTranslation>
  ): string {
    const translation = this.getGlobalConfigurationTranslation(globalConfigurationTranslationId, languageId, translations);
    return translation && translation.name ? translation.name : value;
  }

  getGlobalProductTranslation(globalProductId: number, languageId: number, translations: Array<GlobalProductTranslation>): GlobalProductTranslation {
    if (!this.isTranslatable(globalProductId, languageId, translations)) {
      return null;
    }

    return translations.find(x => x.languageId === languageId);
  }

  getGlobalConfigurationTranslation(globalConfigurationId: number, languageId: number, translations: Array<GlobalConfigurationTranslation>): GlobalConfigurationTranslation {
    if (!this.isTranslatable(globalConfigurationId, languageId, translations)) {
      return null;
    }

    return translations.find(x => x.globalConfigurationId === globalConfigurationId && x.languageId === languageId);
  }

  getBrandTranslation(brandId: number, languageId: number, translations: Array<BrandTranslation>): BrandTranslation {
    if (!this.isTranslatable(brandId, languageId, translations)) {
      return null;
    }

    return translations.find(x => x.brandId === brandId && x.languageId === languageId);
  }

  getSubBrandTranslation(subBrandId: number, languageId: number, translations: Array<SubBrandTranslation>): SubBrandTranslation {
    if (!this.isTranslatable(subBrandId, languageId, translations)) {
      return null;
    }

    return translations.find(x => x.subBrandId === subBrandId && x.languageId === languageId);
  }

  getDesignerTranslation(designerId: number, languageId: number, translations: Array<DesignerTranslation>): DesignerTranslation {
    if (!this.isTranslatable(designerId, languageId, translations)) {
      return null;
    }

    return translations.find(x => x.designerId === designerId && x.languageId === languageId);
  }

  getFamilyTranslation(familyId: number, languageId: number, translations: Array<FamilyTranslation>): FamilyTranslation {
    if (!this.isTranslatable(familyId, languageId, translations)) {
      return null;
    }

    return translations.find(x => x.familyId === familyId && x.languageId === languageId);
  }

  getPortfolioTranslation(portfolioId: number, languageId: number, translations: Array<PortfolioTranslation>): PortfolioTranslation {
    if (!this.isTranslatable(portfolioId, languageId, translations)) {
      return null;
    }

    return translations.find(x => x.portfolioId === portfolioId && x.languageId === languageId);
  }

  getCategoryTranslation(categoryId: number, languageId: number, translations: Array<CategoryTranslation>): CategoryTranslation {
    if (!this.isTranslatable(categoryId, languageId, translations)) {
      return null;
    }

    return translations.find(x => x.categoryId === categoryId && x.languageId === languageId);
  }

  getSubcategoryTranslation(subCategoryId: number, languageId: number, translations: Array<SubcategoryTranslation>): SubcategoryTranslation {
    if (!this.isTranslatable(subCategoryId, languageId, translations)) {
      return null;
    }

    return translations.find(x => x.subcategoryId === subCategoryId && x.languageId === languageId);
  }

  getWarrantyTranslation(warrantyId: number, languageId: number, translations: Array<WarrantyTranslation>): WarrantyTranslation {
    if (!this.isTranslatable(warrantyId, languageId, translations)) {
      return null;
    }

    return translations.find(x => x.warrantyId === warrantyId && x.languageId === languageId);
  }

  private isTranslatable(modelId: number, languageId: number, translations: Array<any>) {
    return !(modelId === 0 || languageId === 0 || !translations || translations.length <= 0);
  }
}
