import { SelectOptions } from './../../../core/models/select-options.model';
import { Action } from '@ngrx/store';

export enum SelectOptionsApiActionTypes {
  LoadSuccess           = '[SelectOptions/API] Fetch Success',
  LoadFailure           = '[SelectOptions/API] Fetch Failure',
}

export class LoadSuccess implements Action {
  readonly type = SelectOptionsApiActionTypes.LoadSuccess;

  constructor(public payload: SelectOptions) { }
}

export class LoadFailure implements Action {
  readonly type = SelectOptionsApiActionTypes.LoadFailure;

  constructor(public payload: any) { }
}

export type SelectOptionsApiActionsUnion =
    LoadSuccess
  | LoadFailure;
