import { IDamMediaLink } from '../../../../core/models/dam-media-link.model';
import { DigitalAssetManagerModalDataSource, Views } from '../digital-asset-manager-modal.datasource';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-digital-asset-library',
  templateUrl: './digital-asset-library.component.html',
  styleUrls: ['./digital-asset-library.component.scss']
})
export class DigitalAssetLibraryComponent {
  Views = Views;
  @Input() datasource: DigitalAssetManagerModalDataSource;

  get mediaLink(): IDamMediaLink { return this.mediaLinkDetail; }
  set mediaLink(value: IDamMediaLink) { this.mediaLinkDetail = value; }
  private mediaLinkDetail: IDamMediaLink = null;

  public viewMediaDetails(mediaLink: IDamMediaLink): void {
    this.mediaLinkDetail = mediaLink;
    this.datasource.visibleView = Views.Image;
  }
}
