import {
  TemplatesReducer
} from './reducers';

import {
  TemplatesApiActions,
  TemplatesActions
} from './actions';

export {
  TemplatesReducer,
  TemplatesApiActions,
  TemplatesActions
};

