import { IDamMediaLink } from '../../../core/models/dam-media-link.model';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DamProviderIds } from '../../constants';

@Component({
  selector: 'app-view-image-details-digital-asset-manager-modal',
  templateUrl: './view-image-details-digital-asset-manager-modal.component.html',
  styleUrls: ['./view-image-details-digital-asset-manager-modal.component.scss']
})
export class ViewImageDetailsDigitalAssetManagerModalComponent {

  public mediaLink: IDamMediaLink;

  constructor(
    public dialogRef: MatDialogRef<ViewImageDetailsDigitalAssetManagerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      mediaLink: IDamMediaLink
    }
  ) {
    this.mediaLink = this.data.mediaLink;
  }

  public isManualEntryImage(): boolean {
    return this.mediaLink?.damProviderId === DamProviderIds.UserEnteredMedia;
  }

  public isAemImage(): boolean {
    return this.mediaLink?.damProviderId === DamProviderIds.Aem;
  }

  public isBynderImage(): boolean {
    return this.mediaLink?.damProviderId === DamProviderIds.Bynder;
  }
}

