import { IBaseAuditModel } from './../models/base-audit.model';
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class BaseAuditModelService {

  constructor(private datePipe: DatePipe) { }

  public getAuditableTooltip(model: IBaseAuditModel): string {
    if (!model) {
      return null;
    }

    let tooltip = '';
    tooltip = `${tooltip} \nId: ${model.id}`;
    tooltip = `${tooltip}${this.getLastModifiedByTooltip(model)}`;

    return tooltip;
  }

  public getLastModifiedByTooltip(model: IBaseAuditModel): string {
    if (!model) {
      return null;
    }

    let tooltip = '';
    const dateString = this.datePipe.transform(model.lastModifiedDate, 'MMM d, y h:mm:ss a');
    tooltip = `${tooltip} \nUpdated: ${dateString}`;
    tooltip = `${tooltip} \nBy: ${model.lastModifiedByUserName}`;

    return tooltip;
  }

}
