import { Locale } from './../../../core/models/locale.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-available-in',
  templateUrl: './available-in.component.html',
  styleUrls: ['./available-in.component.scss']
})
export class AvailableInComponent implements OnInit {
  @Input() locales: Array<Locale> = [];

  constructor() { }

  ngOnInit() {
  }

}
