import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImageAsset } from 'src/app/core/models/image-asset.model';
import { DigitalAssetManagerService } from 'src/app/core/services/digital-asset-manager.service';
import { DamImageAssetResponse } from 'src/app/core/models/dam-image-asset-response.model';
import { Dimensions } from '../../constants';
import { ButtonStyle, ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { LocaleSelectorModalComponent } from '../locale-selector-modal/locale-selector-modal.component';
import { RegionsService } from 'src/app/core/services/regions.service';
import { Locale } from 'src/app/core/models/locale.model';
import { SubSink } from 'subsink';
import { Region } from 'src/app/core/models/region.model';
import { ImageAssetLocale } from 'src/app/core/models/image-asset-locale.model';
import { IBynderImageRefresh } from 'src/app/core/models/bynder-image-refresh.model';

@Component({
  selector: 'app-bynder-image-refresh',
  templateUrl: './bynder-image-refresh.component.html',
  styleUrls: ['./bynder-image-refresh.component.scss']
})
export class BynderImageRefreshComponent implements OnInit {
  @Input() damMediaLink: IBynderImageRefresh[];
  @Input() mediaTypeId: number[];
  @Input() regions: Array<Region> = [];
  @Input() locales: boolean;
  @Input() imageType: string;
  @Input() mediaType: string;
  @Output() showBynderImage = new EventEmitter<boolean>();
  @Output() imageAsset = new EventEmitter<ImageAssetLocale>();

  showImage: boolean = false;
  numberOfMediaToBeUpdated: number;
  assets: Array<ImageAsset> = [];
  private subscriptions = new SubSink();
  private imageAssetLocale: ImageAssetLocale;
  private bynderIds: string[] = [];
  private mediaLinksToLoop: IBynderImageRefresh[] = [];

  constructor(private digitalAssetManagerService: DigitalAssetManagerService,
    private matDialog: MatDialog,
    private regionsService: RegionsService) {
    }


  ngOnInit(): void {
    this.countofBynderIdMedia();
  }

  private countofBynderIdMedia(): void {
    this.numberOfMediaToBeUpdated = this.damMediaLink.filter(mld => mld.bynderId !== null).length;
  }

  public updateBynderDetailsDialog(): void {
    this.showBynderImage.emit(true);
    if (this.locales) {
      this.updateBynderImagesWithLocales();
      return;
    }
    this.updateBynderImagesNoLocales();
  }


  private updateBynderImagesWithLocales() {
    let mediaLinksBynder = this.damMediaLink.filter(x => x.bynderId !== null);
    const localesAll = Array.from([...mediaLinksBynder.map(x => x.locale)].reduce((m, t) => m.set(t.name, t), new Map()).values());
    const selectableRegions = this.regionsService.getFilteredRegionsAndLocales(this.regions, localesAll);
    const dialog = this.matDialog.open(LocaleSelectorModalComponent, {
      width: Dimensions.MODAL_STANDARD_WIDTH,
      restoreFocus: false,
      data: {
        regions: selectableRegions,
        selectedLocales: localesAll,
        modalTitle: 'Update bynder details for'
      }
    });


    this.subscriptions.add(
      dialog.afterClosed().subscribe((locales: Array<Locale>) => {
        if (!locales || locales.length <= 0) {
          this.showBynderImage.emit(false);
          return;
        }
        this.totalImageToProcess(locales);
        this.updateMediaLinksWithLatestBynderValues(this.mediaLinksToLoop);
      })
    );
  }

  applyMediaLinkAsset(imageAsset: ImageAsset, localeId?: number, mediatypeId?: number) {
    this.imageAssetLocale = new ImageAssetLocale();
    this.imageAssetLocale.imageAsset = [];
    this.imageAssetLocale.imageAsset.push(imageAsset);
    this.imageAssetLocale.mediaType = mediatypeId;
    this.imageAssetLocale.localeId = localeId ? localeId : null;

    this.imageAsset.emit(this.imageAssetLocale);
  }

  public totalImageToProcess(locales: Locale[]): void {
    var localesUser = [];
    locales.forEach(x => {
      localesUser.push(x.id)
    });
    var localesMediaLink = this.damMediaLink.filter(x => this.mediaTypeId.includes(x.mediaTypeId));

    localesMediaLink.forEach(x => {
      if (localesUser.includes(x.localeId) && x.bynderId !== null) {
        this.bynderIds.push(x.bynderId);
      }
    });

     this.mediaLinksToLoop = [];
     this.mediaLinksToLoop = this.damMediaLink.filter(x => this.bynderIds.includes(x.bynderId));
     this.numberOfMediaToBeUpdated = this.mediaLinksToLoop.length;
  }


  private updateBynderImagesNoLocales() {
    this.numberOfMediaToBeUpdated = this.damMediaLink.filter(mld => mld.bynderId !== null).length;
    this.updateMediaLinksWithLatestBynderValues(this.damMediaLink);
  }

  public updateMediaLinksWithLatestBynderValues(mediaLinks: IBynderImageRefresh[]) {
    let mediaCurrentCount = 0;
    mediaLinks.filter(x => this.mediaTypeId.includes(x.mediaTypeId)).forEach((mediaLink: any) => {
      if (mediaLink.bynderId) {
        this.digitalAssetManagerService.getImageAssets$(mediaLink.bynderId?.toString(), null, null, 1, 30, 1, 1, true)
          .subscribe((response: DamImageAssetResponse) => {
            if (response?.imageAssets?.results) {
              mediaCurrentCount++;
              this.assets = [];
              this.assets.push(...response.imageAssets.results);

              if (this.assets.length > 0) {
                  this.applyMediaLinkAsset(this.assets[0], mediaLink.localeId? mediaLink.localeId : null, mediaLink.mediaTypeId);
              }

              if (mediaCurrentCount === this.numberOfMediaToBeUpdated) {
                this.showBynderImage.emit(false);
                this.openBynderDetailsUpdatedSuccessDialog();
              }
            }
          });
      }
    });
  }

  private openBynderDetailsUpdatedSuccessDialog(): void {
    const dialog = this.matDialog.open(ConfirmationDialogComponent, {
      width: Dimensions.MODAL_WIDE_WIDTH,
      data: {
        id: 0,
        title: `Bynder Details Applied`,
        message: `The bynder details were applied to the ${this.imageType}. The ${this.mediaType} will need to be saved to commit the changes.`,
        buttonText: 'OK',
        buttonStyle: ButtonStyle.Success,
        enableCancelButton: false
      },
      maxWidth: Dimensions.MODAL_STANDARD_WIDTH,
      restoreFocus: false
    });

    dialog.afterClosed().subscribe(result => {
      // do nothing on close.
    });
  }


}
