import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import * as fromStore from '../../reducers';

import {
  WarrantyApiActions,  
  WarrantyActions
} from '../actions';

import { switchMap, map, catchError, tap, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { WarrantyService } from 'src/app/core/services/warranty.service';

@Injectable()
export class WarrantyEffects {

  @Effect()
  loadWarranty$ = this.actions$.pipe(
    ofType(WarrantyActions.WarrantyActionTypes.LoadWarranty),
    map((action: WarrantyActions.LoadWarranty) => action.payload),
    switchMap(warrantyId =>
      this.warrantyService.getWarranty$(warrantyId).pipe(
        map(
          (warranty) => new WarrantyApiActions.LoadSuccess(warranty)
        ),
        catchError(error =>
          of(new WarrantyApiActions.LoadFailure(error))
        )
      )
    )
  );

  @Effect()
  createWarranty$ = this.actions$.pipe(
    ofType<WarrantyActions.CreateWarranty>(WarrantyActions.WarrantyActionTypes.CreateWarranty),
    switchMap((request) =>
      this.warrantyService.createWarranty$(request.payload).pipe(
        map((objWarranty) =>
          new WarrantyApiActions.CreateSuccess(objWarranty)
        ),
        tap((response) => {
          // Navigate to the page in edit mode.
          this.router.navigate([`products/organizational-data/warranties`]);
        }),
        catchError(error =>
          of(new WarrantyApiActions.CreateFailure(error))
        )
      )
    )
  );

  @Effect()
  deleteWarranty$ = this.actions$.pipe(
    ofType<WarrantyActions.DeleteWarranty>(WarrantyActions.WarrantyActionTypes.DeleteWarranty),
    switchMap((request) =>
      this.warrantyService.deleteWarranty$(request.payload.warrantyId).pipe(
        map((response) =>
          new WarrantyApiActions.DeleteSuccess({ warrantyId: request.payload.warrantyId, warrantyName: request.payload.warrantyName })
        ),
        tap((response) => {
          // Navigate to all warranties page
          this.router.navigate([`products/organizational-data/warranties`]);
        }),
        catchError(error =>
          of(new WarrantyApiActions.DeleteFailure(error))
        )
      )
    )
  );

  @Effect()
  renameWarranty$: Observable<Action> = this.actions$.pipe(
    ofType<WarrantyActions.RenameWarranty>(WarrantyActions.WarrantyActionTypes.RenameWarranty),
    switchMap((request) =>
      this.warrantyService.renameWarranty$({
        warrantyId: request.payload.warrantyId,
        warrantyName: request.payload.warrantyName
      }).pipe(
        map(_ => new WarrantyApiActions.RenameSuccess({
          warrantyId: request.payload.warrantyId,
          warrantyName: request.payload.warrantyName
        })
        ),
        tap(res => this.store.dispatch(new WarrantyActions.LoadWarranty(request.payload.warrantyId)),
          catchError(error =>
            of(new WarrantyApiActions.RenameFailure(error)),
          )
        )
      )
    )
  );

  

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<fromStore.State>,
    private warrantyService: WarrantyService) { }

}
