import { GlobalTranslations } from './../../../core/models/global-translations.model';

import {
  TranslationsApiActions,
  TranslationsActions
} from '../actions';

export interface State {
  translationsLoading: boolean;
  translationsLoaded: boolean;
  translations: GlobalTranslations;
  error: string;
}

export const initialState: State = {
  translationsLoading: false,
  translationsLoaded: false,
  translations: new GlobalTranslations(),
  error: ''
};

export function reducer(state = initialState, action: TranslationsActions.TranslationsActionsUnion | TranslationsApiActions.TranslationsApiActionsUnion): State {
  switch (action.type) {

    case TranslationsActions.TranslationsActionTypes.LoadTranslations: {
      return {
        ...state,
        translationsLoading: true,
        translationsLoaded: false,
        error: null
      };
    }

    case TranslationsApiActions.TranslationsApiActionTypes.LoadSuccess: {
      return {
        ...state,
        translations: action.payload,
        translationsLoading: false,
        translationsLoaded: true
      };
    }

    case TranslationsApiActions.TranslationsApiActionTypes.LoadFailure: {
      return {
        ...state,
        translations: null,
        translationsLoading: false,
        translationsLoaded: false,
        error: action.payload
      };
    }

    default:
      return state;
  }
}

export const getTranslationsIsLoading = (state: State) => state.translationsLoading;
export const getTranslationsIsLoaded = (state: State) => state.translationsLoaded;
export const getTranslations = (state: State) => state.translations;
