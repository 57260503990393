import { BaseAuditModel, IBaseAuditModel } from './base-audit.model';
import * as _ from 'lodash';

export interface IContactGlobalProductLocalePut extends IBaseAuditModel {
  contactId: number;
  globalProductId: number;
  localeId: number;
}

export class ContactGlobalProductLocalePut extends BaseAuditModel implements IContactGlobalProductLocalePut {
  contactId: number;
  globalProductId: number;
  localeId: number;

  constructor(data?: IContactGlobalProductLocalePut) {
    super(data);

    if (!data) {
      return;
    }

    _.assign(this, data);
  }
}
