import { GlobalConfigurationDimensionsPut } from './../../../core/models/global-configurations-dimensions-put.model';
import { GlobalConfigurationPost } from './../../../core/models/global-configuration-post.model';
import { GlobalConfiguration } from 'src/app/core/models/global-configuration.model';
import { Action } from '@ngrx/store';
import { GlobalConfigurationPut, ProductNumberReprocessRequestPut } from 'src/app/core/models/global-configuration-put.model';
import { IConfigurationsPageRequest } from 'src/app/core/models/configurations-page-request.model';
import { GlobalConfigurationModelFilesProfilesPut } from 'src/app/core/models/global-configurations-model-files-profile-put.model';
import { GlobalConfigurationLeadtimesPut } from 'src/app/core/models/global-configurations-leadtimes-put.model';

export enum ConfigurationsActionTypes {
  ClearMessages = '[Configurations] Clear Messages',
  CreateConfiguration = '[Configurations] Create',
  DeleteConfiguration = '[Configurations] Delete',
  DuplicateConfiguration = '[Configurations] Duplicate',
  LoadConfiguration = '[Configurations] Load Configuration',
  UpdateConfiguration = '[Configurations] Update',
  UpdateSKUs = '[SKUs] Update',
  UpdateConfigurationDimensions = '[Configurations] Update Dimensions',
  UpdateModelFilesProfiles = '[Configurations] Update Model Files Profiles',
  UpdateConfigurationLeadTimes = '[Configurations] Update LeadTimes',
  RenameConfiguration = '[Configurations] Rename',
  LoadConfigurationsByProductId = '[Configurations] Load Configurations By Product Id',
  ClearConfigurations = '[Configurations] Clear Configurations',
  LoadConfigurationAssociations = '[Configurations] Load Configuration Associations',
}

export class ClearMessages implements Action {
  readonly type = ConfigurationsActionTypes.ClearMessages;
  constructor() { }
}

export class CreateConfiguration implements Action {
  readonly type = ConfigurationsActionTypes.CreateConfiguration;
  constructor(public payload: { globalConfiguration: GlobalConfigurationPost }) { }
}

export class DeleteConfiguration implements Action {
  readonly type = ConfigurationsActionTypes.DeleteConfiguration;
  constructor(public payload: { globalConfiguration: GlobalConfiguration }) { }
}

export class DuplicateConfiguration implements Action {
  readonly type = ConfigurationsActionTypes.DuplicateConfiguration;
  constructor(public payload: { globalConfiguration: GlobalConfiguration, newName: string }) { }
}

export class LoadConfiguration implements Action {
  readonly type = ConfigurationsActionTypes.LoadConfiguration;
  constructor(public payload: number) { }
}

export class UpdateConfiguration implements Action {
  readonly type = ConfigurationsActionTypes.UpdateConfiguration;
  constructor(public payload: { globalConfiguration: GlobalConfigurationPut }) { }
}

export class UpdateSKUs implements Action {
  readonly type = ConfigurationsActionTypes.UpdateSKUs;
  constructor(public payload: { productNumberReprocessRequest: ProductNumberReprocessRequestPut }) { }
}

export class UpdateConfigurationDimensions implements Action {
  readonly type = ConfigurationsActionTypes.UpdateConfigurationDimensions;
  constructor(public payload: GlobalConfigurationDimensionsPut) { }
}

export class UpdateConfigurationModelFilesProfiles implements Action {
  readonly type = ConfigurationsActionTypes.UpdateModelFilesProfiles;
  constructor(public payload: GlobalConfigurationModelFilesProfilesPut) { }
}

export class UpdateConfigurationLeadTimes implements Action {
  readonly type = ConfigurationsActionTypes.UpdateConfigurationLeadTimes;
  constructor(public payload: GlobalConfigurationLeadtimesPut) { }
}

export class RenameConfiguration implements Action {
  readonly type = ConfigurationsActionTypes.RenameConfiguration;
  constructor(public payload: { globalConfiguration: GlobalConfiguration, newName: string }) { }
}

export class LoadConfigurationsByProductId implements Action {
  readonly type = ConfigurationsActionTypes.LoadConfigurationsByProductId;
  constructor(public payload: IConfigurationsPageRequest) { }
}

export class ClearConfigurations implements Action {
  readonly type = ConfigurationsActionTypes.ClearConfigurations;
  constructor() {
  }
}

export class LoadConfigurationAssociations implements Action {
  readonly type = ConfigurationsActionTypes.LoadConfigurationAssociations;
  constructor(public payload: number) { }
}

export type ConfigurationsActionsUnion =
    ClearMessages
  | CreateConfiguration
  | DeleteConfiguration
  | DuplicateConfiguration
  | LoadConfiguration
  | UpdateConfiguration
  | UpdateSKUs
  | UpdateConfigurationDimensions
  | UpdateConfigurationModelFilesProfiles
  | UpdateConfigurationLeadTimes
  | RenameConfiguration
  | LoadConfigurationsByProductId
  | ClearConfigurations
  | LoadConfigurationAssociations;
