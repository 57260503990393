import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

    constructor(
        private activatedRoute: ActivatedRoute) {
    }
    error: Error;

    errors: Error[] = [
        {
          error: 'unexpected',
          icon: 'error',
          title: 'Unexpected Error',
          text: 'Encountered an unexpected error. If the error persists, please contact your administrator.'
        },
        {
          error: 'noAccess',
          icon: 'not_interested',
          title: 'No Access',
          text: 'You do not have access. Please contact your administrator to request access.'
        },
        {
          error: 'accessDenied',
          icon: 'thumb_down',
          title: 'Access Denied',
          text: 'You are not authorized to access the requested page.'
        },
        {
          error: 'notFound',
          icon: 'help',
          title: 'Resource Not Found',
          text: 'The resource you are trying to reach does not exist.'
      },
    ];

    ngOnInit() {
        this.activatedRoute.queryParamMap.subscribe(pmap => {
            const errorType = pmap.get('type');
            this.error = this.errors.find(x => x.error === errorType);
            if (!this.error) {
                // if matching error not found, default to unexpected error
                this.error = this.errors.find(x => x.error === 'unexpected');
            }
        });
    }
}

class Error {
    error: string;
    icon: string;
    title: string;
    text: string;
}

