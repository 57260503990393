<div fxLayout="row">
  <div style="flex: 1 1 auto" class="snack-bar-text vertical-align-text">
    Editing <strong>{{data.name}}</strong>
  </div>

  <div>
    <button
      mat-raised-button
      class="edit-cancel-button button-spacing"
      (click)="discardChanges()">
      Discard Edits
    </button>

    <span
      matTooltip="The form is invalid.  Please provide valid input to save the data."
      [matTooltipDisabled]="!disableSave">
      <button
      mat-raised-button
      (click)="saveChanges()"
      [disabled]="disableSave">
      Save
    </button>
    </span>
  </div>
</div>
