import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import * as fromStore from '../../reducers';
import { ContactApiActions, ContactActions } from '../actions';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { ContactsService } from 'src/app/core/services/contacts.service';
import { of, Observable } from 'rxjs';


@Injectable()
export class ContactEffects {

  @Effect()
  loadContact$ = this.actions$.pipe(
    ofType(ContactActions.ContactActionTypes.LoadContact),
    map((action: ContactActions.LoadContact) => action.payload),
    switchMap(contactId =>
      this.contactsService.getContact$(contactId).pipe(
        map(
          (contact) => new ContactApiActions.LoadSuccess(contact)
        ),
        catchError(error =>
          of(new ContactApiActions.LoadFailure(error))
        )
      )
    )
  );

  @Effect()
  createContact$ = this.actions$.pipe(
    ofType<ContactActions.CreateContact>(ContactActions.ContactActionTypes.CreateContact),
    switchMap((request) =>
      this.contactsService.createContact$(request.payload).pipe(
        map((contact) =>
          new ContactApiActions.CreateSuccess(contact)
        ),
        tap((response) => {
          // Navigate to the page in edit mode.
          this.router.navigate([`products/organizational-data/contact-detail/${response.payload.id}`], { queryParams: { edit: true } });
        }),
        catchError(error =>
          of(new ContactApiActions.CreateFailure(error))
        )
      )
    )
  );

  @Effect()
  updateContact$: Observable<Action> = this.actions$.pipe(
    ofType<ContactActions.UpdateContact>(ContactActions.ContactActionTypes.UpdateContact),
    switchMap(request =>
      this.contactsService.updateContact$(request.payload.contact).pipe(
        map((response) => new ContactApiActions.UpdateSuccess({ contact: request.payload.contact, contactName: request.payload.contactName })),
        catchError(error => of(new ContactApiActions.UpdateFailure(error)))
      )
    )
  );

  @Effect()
  updateContactnSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(ContactApiActions.ContactApiActionTypes.UpdateSuccess),
    map((action: ContactApiActions.UpdateSuccess) => action.payload),
    map(payload =>
      // Reload the contact from the API if the save was successful.
      new ContactActions.LoadContact(payload.contact.id)
    )
  );

  @Effect()
  renameContact$: Observable<Action> = this.actions$.pipe(
    ofType<ContactActions.RenameContact>(ContactActions.ContactActionTypes.RenameContact),
    switchMap((request) =>
      this.contactsService.renameContact$({
        contactId: request.payload.contactId,
        contactName: request.payload.contactName
      }).pipe(
        map(_ => new ContactApiActions.RenameSuccess({
          contactId: request.payload.contactId,
          contactName: request.payload.contactName
        })
        ),
        tap(res => this.store.dispatch(new ContactActions.LoadContact(request.payload.contactId)),
          catchError(error =>
            of(new ContactApiActions.RenameFailure(error)),
          )
        )
      )
    )
  );

  @Effect()
  deleteContact$ = this.actions$.pipe(
    ofType<ContactActions.DeleteContact>(ContactActions.ContactActionTypes.DeleteContact),
    switchMap((request) =>
      this.contactsService.deleteContact$(request.payload.contactId).pipe(
        map((response) =>
          new ContactApiActions.DeleteSuccess({ contactId: request.payload.contactId, contactName: request.payload.contactName })
        ),
        tap((response) => {
          // Navigate to all contacts page
          this.router.navigate([`products/organizational-data/contacts`]);
        }),
        catchError(error =>
          of(new ContactApiActions.DeleteFailure(error))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<fromStore.State>,
    private contactsService: ContactsService) { }

}
